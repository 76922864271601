@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Muli:300,300i,400,400i|Noto+Sans:400,400i,700,700i}|Roboto:300,400,500,700");
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(../../fonts/MaterialIcons-Regular.eot);
  /* For IE6-8 */
  src: local("Material Icons"), local("MaterialIcons-Regular"), url(../../fonts/material-design-icons/MaterialIcons-Regular.woff2) format("woff2"), url(../../fonts/material-design-icons/MaterialIcons-Regular.woff) format("woff"), url(../../fonts/material-design-icons/MaterialIcons-Regular.ttf) format("truetype");
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
  body {
    font-size: 150%;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2) {
  body {
    font-size: 200%;
  }
}

html, body {
  min-height: 100%;
  font-size: 13px;
  font-family: "Roboto", sans-serif;
}

#cookies {
  background-color: rgba(40, 247, 177, 0.9);
  padding: 35px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2001;
}

#cookies.active {
  display: none;
}

#cookies .content {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

#cookies .content a {
  text-decoration: none;
  font-size: 14px;
  color: #ffffff;
  background-color: #18293e;
  padding: 10px 35px;
  margin-left: 40px;
  cursor: pointer;
}

.title-border {
  position: relative;
}

.title-border:after {
  content: '';
  display: block;
  width: 110px;
  margin: 10px auto 0;
  background-color: #02cd8d;
  height: 3px;
}

.nanobar {
  width: 100%;
  height: 3px;
  z-index: 9999;
  top: 0;
}

.nanobar .bar {
  width: 0;
  height: 100%;
  transition: height .3s;
  background: #02cd8d;
}

.notification-box {
  background-color: #cffff0;
  padding: 20px 35px;
  border-radius: 4px;
  color: #585858;
  position: relative;
  margin-bottom: 20px;
}

.notification-box .icon-chart {
  position: absolute;
  top: 44px;
  height: 50px;
  width: 50px;
  left: 35px;
  background: url("../../images/root/all.png") no-repeat -324px -23px;
}

.notification-box .icon-chart + .body {
  padding-left: 90px;
}

.notification-box .icon-times {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  height: 32px;
  width: 32px;
  left: 45px;
  background: url("../../images/root/all.png") no-repeat -614px -1112px;
}

.notification-box .icon-times + .body {
  padding-left: 90px;
}

.notification-box .body h5 {
  font-size: 22px;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  letter-spacing: 1px;
  line-height: 1;
  margin: 0 0 12px;
}

.notification-box .body .description {
  font-size: 16px;
  font-weight: normal;
  font-family: "Roboto", sans-serif;
  line-height: 1;
  margin-bottom: 15px;
}

.notification-box .body .btn-type-1 {
  width: 200px;
  border-radius: 4px;
  text-align: center;
}

.notification-box .btn-close {
  position: absolute;
  top: 15px;
  right: 25px;
  color: #01ce8e;
  font-size: 26px;
  cursor: pointer;
  opacity: 0.65;
  transition: opacity .3s;
}

.notification-box .btn-close:hover {
  opacity: 1;
}

.notification-box.notification-error {
  background-color: #f2dede;
  border-color: #ebcccc;
  color: #a94442;
}

.notification-box.notification-error .body {
  color: #a94442;
}

.notification-box.notification-error .body h5 {
  color: #a94442;
}

.notification-box.notification-error .btn-close {
  color: #e42e2c;
}

.insight {
  background-color: #f5fbfc;
  padding: 20px 30px;
  color: #484848;
  font-size: 14px;
  border: 1px solid #02cd8d;
  border-radius: 4px;
}

.insight .title {
  margin-bottom: 15px;
}

.insight .title img {
  display: inline-block;
  margin-right: 15px;
  height: 20px;
}

.insight .title b {
  display: inline-block;
  color: #5a5a5a;
  font-size: 16px;
  line-height: 20px;
}

.position-relative {
  position: relative;
}

body.dragging, body.dragging * {
  cursor: move !important;
}

.dragged {
  position: absolute;
  opacity: 0.5;
  z-index: 2000;
}

.paragraph {
  line-height: 1.5;
  font-family: "Roboto", sans-serif;
  color: #555555;
  font-size: 13px;
  padding-bottom: 30px;
  position: relative;
  word-break: normal;
}

.paragraph p {
  margin-bottom: 10px;
}

.paragraph .showMore,
.paragraph .showLess {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #029470;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  cursor: pointer;
  transition: all .3s;
}

.paragraph .showMore i,
.paragraph .showLess i {
  display: inline-block;
  margin-right: 5px;
  font-style: normal;
  font-size: 13px;
}

.paragraph .showMore:hover,
.paragraph .showLess:hover {
  font-weight: bold;
  font-size: 13px;
}

.paragraph .showMore:hover i,
.paragraph .showLess:hover i {
  transition: all .3s;
  font-weight: bold;
  font-size: 16px;
}

.paragraph.collapsed {
  display: block;
}

.paragraph.to_extend {
  display: none;
}

.paragraph.to_extend.extended {
  display: block;
}

select {
  background: #FFFFFF;
}

.popup-type-1 {
  background-color: #fff;
  border-radius: 4px;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.popup-type-1 .content-header {
  background: #02cd8d;
  padding: 15px 20px;
  position: relative;
}

.popup-type-1 .content-header .title {
  font-size: 14px;
  color: #fffffc;
  line-height: 1;
  text-align: center;
}

.popup-type-1 .content-header .cross {
  width: 20px;
  height: 20px;
  cursor: pointer;
  background: url("../../images/root/client/reference/popup-cross.png") center center no-repeat #02cd8d;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translate(0, -50%);
}

.popup-type-1 .content-body {
  padding: 20px 80px;
  text-align: center;
}

.popup-type-1 .content-body span {
  color: #28f1b1;
}

.popup-type-1 .content-body .left {
  text-align: left;
}

.popup-type-1 .content-body .form-group {
  margin-bottom: 15px;
}

.popup-type-1 .content-body .form-group .red {
  color: red;
}

.popup-type-1 .content-body .form-group .error {
  border-color: red;
}

.popup-type-1 .content-body .form-group label.radio {
  font-weight: normal;
  padding: 0 0 0 30px;
  position: relative;
  margin-top: 10px;
  line-height: 30px;
  cursor: pointer;
}

.popup-type-1 .content-body .form-group label.radio [type=radio] {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 5px;
  width: 20px;
  height: 20px;
  z-index: 1;
  margin: 0;
}

.popup-type-1 .content-body .form-group label.radio span:not(.text-error) {
  position: absolute;
  left: 0;
  top: 5px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #dddddd;
}

.popup-type-1 .content-body .form-group label.radio [type=radio]:checked + span:after {
  content: '';
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 16px;
  height: 16px;
  background-color: #02cd8d;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.popup-type-1 .content-body .form-group label.checkbox {
  font-weight: normal;
  padding: 0 0 0 30px;
  position: relative;
  margin-top: 10px;
  line-height: 30px;
  cursor: pointer;
}

.popup-type-1 .content-body .form-group label.checkbox [type=checkbox] {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 5px;
  width: 20px;
  height: 20px;
  z-index: 1;
  margin: 0;
}

.popup-type-1 .content-body .form-group label.checkbox span:not(.text-error) {
  z-index: 2;
  position: absolute;
  left: 0;
  top: 5px;
  width: 20px;
  height: 20px;
  border: 1px solid #d4f0e3;
  background-color: #d8f1e6;
  border-radius: 2px;
  margin: 0 10px 0 0;
  transition: background-color .3s;
}

.popup-type-1 .content-body .form-group label.checkbox span:not(.text-error).error {
  border-color: red;
}

.popup-type-1 .content-body .form-group label.checkbox [type=checkbox]:checked + span:after {
  top: 50%;
  left: 50%;
  content: '';
  position: absolute;
  height: 6px;
  width: 15px;
  border-left: 2px solid #02cd8d;
  border-bottom: 2px solid #02cd8d;
  display: block;
  margin: 0 auto;
  transform: translate(-50%, -55%) rotate(-45deg);
  -webkit-transform: translate(-50%, -55%) rotate(-45deg);
  -moz-transform: translate(-50%, -55%) rotate(-45deg);
  -ms-transform: translate(-50%, -55%) rotate(-45deg);
  -o-transform: translate(-50%, -55%) rotate(-45deg);
}

.popup-type-1 .content-body .form-group textarea {
  border: 1px solid #e1e3e3;
  border-radius: 4px;
  padding: 10px 20px;
  line-height: 1.4;
  outline: 0;
  font-size: 14px;
  height: auto;
  box-shadow: none;
  font-family: "Roboto", sans-serif;
  background-color: #FFFFFF;
  display: block;
  width: 100%;
}

.popup-type-1 .content-body .form-group textarea:focus {
  box-shadow: none;
  border: 1px solid #e1e3e3;
  outline: 0;
}

.popup-type-1 .content-body .form-group textarea:disabled {
  background-color: #e1e3e3;
}

.popup-type-1 .content-body .form-group .text-error {
  color: red;
  font-size: 11px;
  display: block;
}

.popup-type-1 .content-footer {
  padding-bottom: 15px;
}

.popup-type-1 .content-footer button,
.popup-type-1 .content-footer a {
  padding: 15px 20px;
  border: none;
  display: block;
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  transition: background-color .3s;
  text-align: center;
}

.popup-type-1 .content-footer button.grey,
.popup-type-1 .content-footer a.grey {
  background-color: #ebebeb;
  color: #02cd8d;
}

.popup-type-1 .content-footer button.grey:hover,
.popup-type-1 .content-footer a.grey:hover {
  background-color: #f0f0f0;
}

.popup-type-1 .content-footer button.green,
.popup-type-1 .content-footer a.green {
  color: #ebebeb;
  background-color: #02cd8d;
}

.popup-type-1 .content-footer button.green:hover,
.popup-type-1 .content-footer a.green:hover {
  background-color: #02d795;
}

.popup-type-1 .content-footer button:hover,
.popup-type-1 .content-footer a:hover {
  text-decoration: none;
}

.popup-type-1 .content-footer button + a,
.popup-type-1 .content-footer button + button,
.popup-type-1 .content-footer a + a,
.popup-type-1 .content-footer a + button {
  margin-top: 10px;
}

.popup-type-1 .mfp-close {
  display: none;
}

.new-popup-type {
  margin: 0 auto;
  background-color: #fff;
  max-width: 750px;
  text-align: center;
  padding: 60px;
  color: #4a596e;
}

.new-popup-type .popup-status .svg-menu {
  padding-bottom: 20px;
}

.new-popup-type .popup-status .svg-menu img {
  width: 80px;
  height: 80px;
}

.new-popup-type .popup-content {
  font-weight: bold;
  font-size: 18px;
  padding-bottom: 20px;
}

.new-popup-type .popup-button {
  text-align: center;
  display: inline-block;
}

.new-popup-type .popup-button a {
  float: left;
  cursor: pointer;
  padding: 10px 0;
  margin: 0 10px;
  text-decoration: none;
  display: inline-block;
  color: #4a596e;
  transition: 0.3s ease-in;
  width: 180px;
}

.new-popup-type .popup-button a.btn-1 {
  background-color: #28f1b1;
  border: 2px solid #28f1b1;
}

.new-popup-type .popup-button a.btn-2 {
  background-color: #ffffff;
  border: 2px solid #28f1b1;
}

.new-popup-type .popup-button a:hover.btn-1 {
  background-color: #4a596e;
  border: 2px solid #4a596e;
  color: #28f1b1;
}

.new-popup-type .popup-button a:hover.btn-2 {
  color: #28f1b1;
  background-color: #ffffff;
  border: 2px solid #28f1b1;
}

#leaving-popup {
  width: 850px;
  color: #18293e;
  margin: auto;
}

#leaving-popup .top-image {
  height: 250px;
  background-image: url("../../images/root/leaving-popup/leaving-popup-banner.jpg");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

#leaving-popup .bottom-content {
  padding: 50px 120px;
  background-color: #ffffff;
}

#leaving-popup .bottom-content .title {
  line-height: 1.2;
  font-size: 24px;
  padding-bottom: 30px;
  font-weight: bold;
}

#leaving-popup .bottom-content .description {
  font-size: 16px;
  padding-bottom: 15px;
}

#leaving-popup .bottom-content form {
  padding: 0 50px;
}

#leaving-popup .bottom-content form .form-group {
  position: relative;
  font-size: 16px;
}

#leaving-popup .bottom-content form .form-group .fa {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  color: #6d849f;
}

#leaving-popup .bottom-content form .form-group input[type=email] {
  height: 40px;
  padding-left: 50px;
  border-radius: 25px;
}

#leaving-popup .bottom-content form .btn-group {
  display: block;
  padding: 5px 0px 30px;
  color: #18293e;
}

#leaving-popup .bottom-content form .btn-group .submit {
  font-size: 14px;
  background-color: #02cd8d;
  text-align: center;
  width: 100%;
  color: #18293e;
  text-decoration: none;
  padding: 10px 0;
  display: block;
  cursor: pointer;
  border-radius: 2px;
  border: none;
}

#leaving-popup .bottom-content .exit {
  color: #6d849f;
  font-size: 14px;
  font-weight: bold;
}

#leaving-popup .bottom-content .exit:hover {
  color: #18293e;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
  animation-duration: 0.3s;
}

.word-wrap-break-word {
  word-break: break-all;
  -ms-word-break: break-all;
  word-break: break-all;
  /* Non standard for WebKit */
  word-break: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.content-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.content-ul li {
  padding-left: 10px;
  position: relative;
}

.content-ul li:before {
  content: '-';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.col-1-10 {
  padding-left: 15px;
  padding-right: 15px;
  width: 10%;
  float: left;
}

.col-2-10 {
  padding-left: 15px;
  padding-right: 15px;
  width: 20%;
  float: left;
}

.col-3-10 {
  padding-left: 15px;
  padding-right: 15px;
  width: 30%;
  float: left;
}

.col-4-10 {
  padding-left: 15px;
  padding-right: 15px;
  width: 40%;
  float: left;
}

.col-5-10 {
  padding-left: 15px;
  padding-right: 15px;
  width: 50%;
  float: left;
}

.col-6-10 {
  padding-left: 15px;
  padding-right: 15px;
  width: 60%;
  float: left;
}

.col-7-10 {
  padding-left: 15px;
  padding-right: 15px;
  width: 70%;
  float: left;
}

.col-8-10 {
  padding-left: 15px;
  padding-right: 15px;
  width: 80%;
  float: left;
}

.col-9-10 {
  padding-left: 15px;
  padding-right: 15px;
  width: 900%;
  float: left;
}

.col-1-10 {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  float: left;
}

.mfp-content iframe {
  display: block;
  width: 100%;
}

.policy_reminder {
  font-size: 12px;
}

.policy_reminder span {
  color: red;
}

@media only screen and (max-width: 767px) {
  .popup-type-1 .content-header .cross {
    right: 10px;
  }
  .popup-type-1 .content-body {
    padding: 20px 30px;
  }
  .popup-type-1 .mfp-close {
    display: none;
  }
}

.sk-folding-cube {
  margin: 20px auto;
  width: 40px;
  height: 40px;
  position: relative;
  transform: rotateZ(45deg);
}

.sk-folding-cube .sk-cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  transform: scale(1.1);
}

.sk-folding-cube .sk-cube:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #27f2b2;
  animation: sk-foldCubeAngle 2.4s infinite linear both;
  transform-origin: 100% 100%;
}

.sk-folding-cube .sk-cube2 {
  transform: scale(1.1) rotateZ(90deg);
}

.sk-folding-cube .sk-cube3 {
  transform: scale(1.1) rotateZ(180deg);
}

.sk-folding-cube .sk-cube4 {
  transform: scale(1.1) rotateZ(270deg);
}

.sk-folding-cube .sk-cube2:before {
  animation-delay: 0.3s;
}

.sk-folding-cube .sk-cube3:before {
  animation-delay: 0.6s;
}

.sk-folding-cube .sk-cube4:before {
  animation-delay: 0.9s;
}

@keyframes sk-foldCubeAngle {
  0%, 10% {
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%, 75% {
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%, 100% {
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}

.btn-effect-1 {
  display: block;
  background-color: #02cd8d;
  position: relative;
  padding: 10px 25px 13px;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  color: #ffffe7;
  cursor: pointer;
  white-space: nowrap;
  text-decoration: none;
  border: 0;
  border-radius: 3px;
  overflow: hidden;
  vertical-align: top;
  text-align: center;
}

.btn-effect-1 span {
  position: relative;
  z-index: 2;
}

.btn-effect-1:not(.btn-disabled):hover {
  text-decoration: none;
  color: #ffffe7;
}

.btn-effect-1:not(.btn-disabled):hover:after {
  height: 100%;
}

.btn-effect-1:not(.btn-disabled):after {
  transition: height .3s;
  height: 5px;
  width: 100%;
  position: absolute;
  bottom: 0;
  background-color: #28ac78;
  left: 0;
  content: '';
  z-index: 1;
}

.btn-effect-1.btn-disabled {
  padding: 10px 25px;
  background-color: #ebebeb;
  color: #696969;
}

.btn-effect-1.btn-disabled:hover {
  text-decoration: none;
}

.btn-effect-2 {
  display: block;
  background-color: #dddddd;
  position: relative;
  padding: 10px 25px 13px;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  color: #585858;
  cursor: pointer;
  white-space: nowrap;
  text-decoration: none;
  border: 0;
  border-radius: 3px;
  overflow: hidden;
  vertical-align: top;
  text-align: center;
}

.btn-effect-2 span {
  position: relative;
  z-index: 2;
}

.btn-effect-2:after {
  transition: height .3s;
  height: 5px;
  width: 100%;
  position: absolute;
  bottom: 0;
  background-color: #8f8f8f;
  left: 0;
  content: '';
  z-index: 1;
}

.btn-effect-2:not(.btn-disabled):hover {
  text-decoration: none;
  color: #ffffe7;
}

.btn-effect-2:not(.btn-disabled):hover:after {
  height: 100%;
}

.btn-effect-2.btn-disabled:hover {
  text-decoration: none;
  cursor: default;
}

.btn-type-1 {
  display: inline-block;
  padding: 10px 25px;
  background-color: #02cd8d;
  border: 1px solid #02cd8d;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  color: #ffffe7;
  transition: background-color .3s;
  text-decoration: none;
  outline: 0;
  border: 0;
  vertical-align: top;
  cursor: pointer;
}

.btn-type-1:hover, .btn-type-1:focus {
  background-color: rgba(2, 205, 141, 0.75);
  color: #ffffe7;
  text-decoration: none;
}

.btn-type-2 {
  display: inline-block;
  padding: 10px 25px;
  background-color: #3f92f1;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  color: #fff;
  transition: background-color .3s;
  text-decoration: none;
  outline: 0;
  vertical-align: top;
  border: 0;
}

.btn-type-2:hover, .btn-type-2:focus {
  background-color: #61abf1;
  color: #FFFFFF;
  text-decoration: none;
}

.btn-type-grey {
  display: inline-block;
  padding: 10px 25px;
  background-color: transparent;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  color: #333;
  transition: background-color .3s;
  text-decoration: none;
  outline: 0;
  vertical-align: top;
  border: 1px solid #333;
}

.btn-type-grey:hover, .btn-type-grey:focus {
  background-color: #ccc;
  text-decoration: none;
}

#site-container .logo-wrapper.login {
  margin-top: 12px;
}

#site-container header {
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 3px solid #28F2B2;
  position: relative;
  z-index: 4;
}

#site-container header .logo-wrapper a {
  display: block;
}

#site-container header .logo-wrapper a .svg-menu img {
  width: 200px;
}

#site-container header .for-client,
#site-container header .for-vendor,
#site-container header .quick-wrapper {
  display: none;
}

#site-container header .mobile-menu-wrapper .material-icons {
  color: #333;
  cursor: pointer;
  font-size: 30px;
  line-height: 50px;
  height: 50px;
}

#site-container #mobile-menu {
  display: none;
}

#site-container:before {
  content: '';
  display: block;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.5s ease-in-out;
}

#site-container.mobile-menu-opened:before {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
}

@media only screen and (min-width: 992px) {
  #site-container header .logo-wrapper a img {
    max-width: 250px;
    width: 100%;
  }
  #site-container header .for-client,
  #site-container header .for-vendor {
    display: block;
    margin: 16px 0 16px 10px;
    padding: 4px 4px;
    font-size: 13px;
    position: relative;
  }
  #site-container header .for-client:hover > span,
  #site-container header .for-vendor:hover > span {
    color: #02cd8d;
  }
  #site-container header .for-client > span,
  #site-container header .for-vendor > span {
    color: #333;
    font-weight: bold;
    cursor: pointer;
    transition: color .3s;
  }
  #site-container header .for-client > span i.fa,
  #site-container header .for-vendor > span i.fa {
    color: #02cd8d;
    margin-left: 1px;
  }
  #site-container header .for-client ul.dropdown,
  #site-container header .for-vendor ul.dropdown {
    display: none;
    opacity: 0;
    transition: opacity .4s;
    position: absolute;
    top: 100%;
    left: 50%;
    width: 180px;
    transform: translateX(-50%);
    list-style: none;
    padding: 8px 0;
    margin: 0;
    background-color: #FFFFFF;
    box-shadow: 0 0 1px 1px #aaaaaa;
  }
  #site-container header .for-client ul.dropdown li a,
  #site-container header .for-vendor ul.dropdown li a {
    color: #333;
    font-weight: bold;
    display: block;
    padding: 3px 0;
    text-align: center;
    text-decoration: none;
    transition: color .3s;
  }
  #site-container header .for-client ul.dropdown li a:hover,
  #site-container header .for-vendor ul.dropdown li a:hover {
    color: #02cd8d;
  }
  #site-container header .for-client:hover ul.dropdown,
  #site-container header .for-vendor:hover ul.dropdown {
    display: block;
    opacity: 1;
  }
  #site-container header .quick-wrapper {
    display: block;
    color: #02cd8d;
    font-family: "Roboto", sans-serif;
    font-size: 13px;
    margin-top: 19px;
  }
  #site-container header .quick-wrapper > ul {
    display: block;
    list-style: none;
    padding: 0;
    margin-left: -5px;
    margin-right: -5px;
  }
  #site-container header .quick-wrapper > ul > li {
    display: block;
    float: left;
    padding-left: 5px;
    padding-right: 5px;
  }
  #site-container header .quick-wrapper > ul > li > a {
    padding: 4px 8px;
    font-size: 13px;
    display: block;
    text-decoration: none;
    color: #333;
    font-weight: bold;
    transition: color .3s, border .3s;
  }
  #site-container header .quick-wrapper > ul > li > a:not(.login) {
    color: #333;
    font-weight: bold;
    border-radius: 3px;
    border: 1px solid #333;
  }
  #site-container header .quick-wrapper > ul > li > a.login {
    padding: 4px;
  }
  #site-container header .quick-wrapper > ul > li > a:hover {
    color: #02cd8d;
  }
  #site-container header .quick-wrapper > ul > li > a:hover:not(.login) {
    border: 1px solid #02cd8d;
  }
  #site-container header .quick-wrapper > ul > li > a.link-box {
    border: 1px solid #484848;
    color: #484848;
    font-size: 12px;
    padding: 12px 9px;
    line-height: 1;
    border-radius: 2px;
  }
  #site-container header .quick-wrapper > ul > li > a.link-box:hover {
    color: #02cd8d;
    border: 1px solid #02cd8d;
  }
  #site-container header .quick-wrapper > ul > li > a img {
    height: 40px;
  }
  #site-container header .quick-wrapper > ul > li > a .image {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
    margin-top: -5px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  #site-container header .quick-wrapper > ul > li > .languages {
    padding-left: 10px;
    display: block;
    text-decoration: none;
    color: #333;
    font-weight: bold;
    position: relative;
  }
  #site-container header .quick-wrapper > ul > li > .languages > span {
    transition: color .3s;
    outline: none;
    cursor: pointer;
    display: block;
  }
  #site-container header .quick-wrapper > ul > li > .languages > span .fa {
    margin-right: 4px;
    font-size: 15px;
    display: inline-block;
  }
  #site-container header .quick-wrapper > ul > li > .languages > span > .fa[class$=down] {
    color: #02cd8d;
    margin-left: 8px;
    margin-right: 0;
    transition: transform .3s;
  }
  #site-container header .quick-wrapper > ul > li > .languages > .languages-dropdown {
    display: none;
    visibility: hidden;
    opacity: 0;
    font-size: 13px;
    background-color: #fff;
    zoom: 1;
    z-index: 7000;
    -webkit-font-smoothing: antialiased;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    width: 180px;
    padding: 8px 0;
    border-radius: 8px;
    box-shadow: 0 0 0 1px #e0e0e0;
  }
  #site-container header .quick-wrapper > ul > li > .languages > .languages-dropdown ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  #site-container header .quick-wrapper > ul > li > .languages > .languages-dropdown ul li {
    display: block;
  }
  #site-container header .quick-wrapper > ul > li > .languages > .languages-dropdown ul li a {
    color: #333;
    font-weight: bold;
    display: block;
    padding: 3px 0;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    transition: color .3s;
  }
  #site-container header .quick-wrapper > ul > li > .languages > .languages-dropdown ul li a:hover {
    text-decoration: none;
    color: #02cd8d;
  }
  #site-container header .quick-wrapper > ul > li > .languages:hover > span {
    color: #02cd8d;
  }
  #site-container header .quick-wrapper > ul > li > .languages:hover > .languages-dropdown {
    display: block;
    visibility: visible;
    opacity: 1;
  }
  #site-container header .quick-wrapper > ul > li.user {
    cursor: default;
    position: relative;
  }
  #site-container header .quick-wrapper > ul > li.user > a {
    border: 1px solid transparent;
    line-height: 1;
    color: #484848;
    font-size: 12px;
    cursor: pointer;
  }
  #site-container header .quick-wrapper > ul > li.user > a img {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
    margin-top: -5px;
  }
  #site-container header .quick-wrapper > ul > li.user > a:hover {
    border: 1px solid transparent;
  }
  #site-container header .quick-wrapper > ul > li.user .dropdown {
    z-index: 3;
    width: 300px;
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #FFFFFF;
    border-radius: 8px;
    font-size: 13px;
    box-shadow: 0 0 0 1px #e0e0e0;
  }
  #site-container header .quick-wrapper > ul > li.user .dropdown .dropdown-header {
    padding: 20px;
  }
  #site-container header .quick-wrapper > ul > li.user .dropdown .dropdown-header .label {
    font-size: 12px;
    display: block;
    font-weight: normal;
    margin-bottom: 10px;
    color: #333;
    text-align: left;
    padding: 0;
  }
  #site-container header .quick-wrapper > ul > li.user .dropdown .dropdown-header .tab-header {
    border-radius: 3px;
  }
  #site-container header .quick-wrapper > ul > li.user .dropdown .dropdown-header .tab-header .link {
    text-decoration: none;
    cursor: pointer;
    font-size: 14px;
    border: 1px solid #989898;
    float: left;
    color: #333;
    font-weight: normal;
    width: 50%;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
  }
  #site-container header .quick-wrapper > ul > li.user .dropdown .dropdown-header .tab-header .link:not(:first-child) {
    border-left: none;
  }
  #site-container header .quick-wrapper > ul > li.user .dropdown .dropdown-header .tab-header .link.active {
    background-color: #02cd8d;
    color: #FFFFFF;
  }
  #site-container header .quick-wrapper > ul > li.user .dropdown .dropdown-header .tab-header:after {
    content: '';
    display: table;
    clear: both;
  }
  #site-container header .quick-wrapper > ul > li.user .dropdown .dropdown-body {
    display: none;
  }
  #site-container header .quick-wrapper > ul > li.user .dropdown .dropdown-body .links .link {
    display: block;
    color: #585858;
    text-align: left;
    text-decoration: none;
    padding: 5px 20px;
    border-right: 3px solid transparent;
    transition: border-right .3s;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
  #site-container header .quick-wrapper > ul > li.user .dropdown .dropdown-body .links .link:hover {
    border-right: 3px solid #02cd8d;
  }
  #site-container header .quick-wrapper > ul > li.user .dropdown .dropdown-body .links .link img {
    display: inline-block;
    margin-right: 10px;
    width: 16px;
    height: 16px;
  }
  #site-container header .quick-wrapper > ul > li.user .dropdown .dropdown-body .links + .divider {
    margin-top: 5px;
  }
  #site-container header .quick-wrapper > ul > li.user .dropdown .dropdown-body.active {
    display: block;
  }
  #site-container header .quick-wrapper > ul > li.user .dropdown .dropdown-body .divider {
    width: 100%;
    height: 1px;
    background-color: #989898;
  }
  #site-container header .quick-wrapper > ul > li.user .dropdown .dropdown-body .divider + .links {
    margin-top: 5px;
  }
  #site-container header .quick-wrapper > ul > li.user:hover .dropdown {
    display: block;
  }
}

@media only screen and (max-width: 991px) {
  #site-container header {
    position: relative;
  }
  #site-container header .logo-wrapper {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  #site-container header .logo-wrapper a {
    display: block;
  }
  #site-container header .logo-wrapper a img {
    max-height: 50px;
  }
  #site-container .post-request {
    background-color: #02cd8d;
    font-size: 13px;
    color: #ffffff;
    position: relative;
  }
  #site-container .post-request .find-agency {
    padding: 20px 200px 20px 30px;
  }
  #site-container .post-request .button-link {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    padding-right: 30px;
  }
  #site-container .post-request .button-link a {
    color: #ffffff;
    border: 1px solid #ffffff;
    padding: 10px 20px;
    border-radius: 2px;
    text-decoration: none;
  }
  #site-container .post-request .button-link a:hover {
    text-decoration: none;
  }
}

@media only screen and (max-width: 767px) {
  #site-container header {
    position: relative;
  }
  #site-container header .logo-wrapper {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  #site-container header .logo-wrapper a img {
    max-height: 35px;
  }
  #site-container header .mobile-menu-wrapper .material-icons {
    font-size: 25px;
    line-height: 35px;
    height: 35px;
  }
}

@media only screen and (max-width: 400px) {
  #site-container .post-request .find-agency {
    padding: 20px;
  }
  #site-container .post-request .button-link {
    position: relative;
    transform: initial;
    padding: 0 20px;
    top: initial;
    right: initial;
    display: block;
    padding-bottom: 20px;
    text-align: center;
  }
  #site-container .post-request .button-link a {
    display: inline-block;
  }
}

footer .footer-top {
  background-color: #1e2d49;
  padding: 40px 0;
}

footer .footer-top .logo-address {
  position: relative;
}

footer .footer-top .logo-address a {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

footer .footer-top .logo-address a .svg-menu img {
  width: 200px;
}

footer .footer-top .address {
  padding-right: 100px;
  color: #a4adb8;
}

footer .footer-top .address .fa {
  color: #02cd8d;
  font-size: 18px;
  padding-right: 5px;
}

footer .footer-top .address span {
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
}

footer .footer-bottom {
  background-color: #18293e;
  padding: 40px 0;
}

footer .footer-bottom .copyright {
  padding-right: 10px;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  display: inline-block;
  color: #a4adb8;
}

footer .footer-bottom h5 {
  padding: 0;
  margin: 0 0 20px;
  color: #02cd8d;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 18px;
}

footer .footer-bottom .links {
  padding: 0;
  margin: 0;
  list-style: none;
}

footer .footer-bottom .links li {
  display: block;
  margin-bottom: 0;
}

footer .footer-bottom .links li a {
  color: #ffffff;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  cursor: pointer;
  transition: all 0.3s ease-in;
}

footer .footer-bottom .links li a:hover {
  opacity: 0.5;
  text-decoration: none;
}

footer .footer-bottom .bottom-links {
  padding-top: 40px;
}

footer .footer-bottom .bottom-links .terms a {
  color: #ffffff;
}

footer .footer-bottom .bottom-links .terms a:hover {
  opacity: 0.5;
  text-decoration: none;
}

footer .footer-bottom .bottom-links .terms span {
  color: #02cd8d;
  padding: 0 10px;
}

footer .footer-bottom .bottom-links .socials a {
  float: left;
}

footer .footer-bottom .bottom-links .socials a .svg-menu {
  width: 25px;
  height: 25px;
  margin-right: 10px;
  cursor: pointer;
  fill: #ffffff;
}

footer .footer-bottom .bottom-links .socials a:hover .svg-menu {
  fill: #02cd8d;
}

@media only screen and (max-width: 991px) {
  footer .footer-top .address {
    padding-right: 0;
  }
  footer .footer-top .medium-size {
    padding-bottom: 20px;
  }
  footer .footer-top .medium-size .svg-menu {
    width: 200px;
    margin: 0 auto;
  }
  footer .footer-bottom .links {
    padding-bottom: 15px;
  }
  footer .footer-bottom .links li {
    display: block;
    margin-bottom: 0;
  }
  footer .footer-bottom .links li a {
    color: #ffffff;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    font-weight: normal;
    cursor: pointer;
    transition: all 0.3s ease-in;
  }
  footer .footer-bottom .links li a:hover {
    opacity: 0.5;
    text-decoration: none;
  }
}

@media only screen and (max-width: 767px) {
  footer .footer-top {
    padding: 40px 0 20px;
  }
  footer .footer-top .address {
    padding-bottom: 20px;
    text-align: center;
  }
  footer .footer-top .medium-size .svg-menu {
    width: 150px;
  }
  footer .footer-bottom .mobile-link {
    cursor: pointer;
    transition: all 0.3s ease-in;
  }
  footer .footer-bottom .mobile-link h5 {
    text-align: center;
    margin: 0 0 10px;
  }
  footer .footer-bottom .mobile-link .links {
    padding-bottom: 35px;
    display: none;
  }
  footer .footer-bottom .mobile-link .links li {
    text-align: center;
  }
  footer .footer-bottom .mobile-link.active .links {
    display: block;
  }
  footer .footer-bottom .bottom-links {
    padding-top: 20px;
  }
  footer .footer-bottom .bottom-links .terms {
    width: 100%;
    text-align: center;
  }
  footer .footer-bottom .bottom-links .terms span {
    padding: 0;
  }
  footer .footer-bottom .bottom-links .socials {
    padding-top: 10px;
    width: 100%;
    text-align: center;
  }
  footer .footer-bottom .bottom-links .socials a {
    float: none;
  }
}

nav.type-landing {
  border-top: 3px solid #02cd8d;
  background-color: #f3f3f3;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  position: relative;
  z-index: 2;
}

nav.type-landing > div .container.portfolio_service-container > ul {
  margin: 0 auto;
  width: 50%;
}

nav.type-landing > div .container.portfolio_service-container > ul > li {
  width: 25%;
}

nav.type-landing > div .container.portfolio_service-container > ul > li.business_service-navigation {
  display: none;
}

nav.type-landing > div .container.portfolio_service-container > ul > li.portfolio_service-navigation {
  display: block;
}

nav.type-landing > div .container > ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: block;
  width: 100%;
}

nav.type-landing > div .container > ul > li {
  float: left;
  width: 14.285%;
  position: relative;
}

nav.type-landing > div .container > ul > li.business_service-navigation {
  display: block;
}

nav.type-landing > div .container > ul > li.portfolio_service-navigation {
  display: none;
}

nav.type-landing > div .container > ul > li > a {
  position: relative;
  cursor: pointer;
  text-decoration: none;
  color: #18293e;
  display: block;
  background-color: transparent;
  text-align: center;
  padding: 15px 5px 15px;
  transition: color .3s, background-color .2s ease-in-out;
}

nav.type-landing > div .container > ul > li > a .image-wrapper {
  position: relative;
  display: block;
  height: 40px;
  margin-bottom: 10px;
}

nav.type-landing > div .container > ul > li > a .image-wrapper img {
  max-height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

nav.type-landing > div .container > ul > li > a .image-wrapper img.default {
  display: block;
}

nav.type-landing > div .container > ul > li > a .image-wrapper img.hover {
  display: none;
}

nav.type-landing > div .container > ul > li > a .vertical-middle {
  height: 36px;
  display: block;
  position: relative;
}

nav.type-landing > div .container > ul > li > a .vertical-middle span {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  font-size: 13px;
}

nav.type-landing > div .container > ul > li .services {
  visibility: hidden;
  display: none;
  opacity: 0;
  transition: opacity .3s;
  position: absolute;
  top: 100%;
  left: 0;
  box-shadow: rgba(148, 154, 158, 0.5) 0 2px 3px;
  background-color: #FFFFFF;
  padding: 15px 10px;
}

nav.type-landing > div .container > ul > li .services.right {
  right: 0;
  left: initial;
}

nav.type-landing > div .container > ul > li .services.column-to-show-1 {
  width: 271px;
}

nav.type-landing > div .container > ul > li .services.column-to-show-2 {
  width: 522px;
}

nav.type-landing > div .container > ul > li .services.column-to-show-3 {
  width: 773px;
}

nav.type-landing > div .container > ul > li .services.column-to-show-4 {
  width: 1024px;
}

nav.type-landing > div .container > ul > li .services .services-column {
  float: left;
  width: 250px;
}

nav.type-landing > div .container > ul > li .services .services-column:not(:first-child) {
  border-left: 1px solid rgba(148, 154, 158, 0.5);
}

nav.type-landing > div .container > ul > li .services .services-column a {
  display: block;
  padding: 8px 15px;
  cursor: pointer;
  text-decoration: none;
  color: #888888;
  font-size: 13px;
  line-height: 18px;
  transition: color .3s;
}

nav.type-landing > div .container > ul > li .services .services-column a:hover {
  color: #0F0F0F;
  font-weight: bold;
  font-size: 13px;
}

nav.type-landing > div .container > ul > li:hover > a, nav.type-landing > div .container > ul > li.open > a {
  background-color: #FFFFFF;
  color: #02cd8d;
  text-decoration: none;
}

nav.type-landing > div .container > ul > li:hover > a .image-wrapper img.default, nav.type-landing > div .container > ul > li.open > a .image-wrapper img.default {
  display: none;
}

nav.type-landing > div .container > ul > li:hover > a .image-wrapper img.hover, nav.type-landing > div .container > ul > li.open > a .image-wrapper img.hover {
  display: block;
}

nav.type-landing > div .container > ul > li:hover .services, nav.type-landing > div .container > ul > li.open .services {
  visibility: visible;
  display: block;
  opacity: 1;
}

nav.type-landing.is-sticky {
  position: fixed;
  top: 0;
  bottom: auto;
  left: 0;
  right: 0;
  z-index: 4;
  border-top: 3px solid #02cd8d;
  box-shadow: #d5d7d9 0 0 5px;
}

nav.type-client {
  background-color: #373f48;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  position: relative;
  z-index: 2;
  box-shadow: #d5d7d9 0 0 5px;
}

nav.type-client .container > ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: block;
  width: 100%;
}

nav.type-client .container > ul > li {
  float: left;
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
}

nav.type-client .container > ul > li > a {
  position: relative;
  cursor: pointer;
  text-decoration: none;
  color: #FFFFFF;
  display: block;
  background-color: transparent;
  text-align: center;
  padding: 10px 0 6px;
  border-bottom: 4px solid transparent;
  transition: border-bottom .2s ease-in-out;
}

nav.type-client .container > ul > li > a span {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(100%, 30%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: block;
  font-size: 0;
  background-color: #02cd8d;
}

nav.type-client .container > ul > li:hover a, nav.type-client .container > ul > li.active a {
  text-decoration: none;
  border-bottom: 4px solid #FFFFFF;
}

nav.type-client .overlay {
  height: 0%;
  width: 40%;
  position: fixed;
  top: 124px;
  left: 0;
  background-color: rgba(55, 63, 72, 0.9);
  transition: 0.5s;
  display: none;
}

nav.type-client .overlay .overlay-content {
  position: relative;
  top: 25%;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}

nav.type-client .overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 20px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

nav.type-client .overlay a:hover, nav.type-client .overlay a:focus {
  color: #f1f1f1;
}

nav.type-client .overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 30px;
}

nav.type-listing {
  background-color: #18283e;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  position: relative;
  z-index: 3;
}

nav.type-listing > div .container > ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: block;
  width: 100%;
}

nav.type-listing > div .container > ul > li {
  float: left;
  position: relative;
}

nav.type-listing > div .container > ul > li > a {
  position: relative;
  cursor: pointer;
  text-decoration: none;
  color: #FFFFFF;
  display: block;
  background-color: transparent;
  text-align: center;
  padding: 10px 15px;
  transition: color .3s;
}

nav.type-listing > div .container > ul > li > a > img {
  display: block;
  margin: 0 auto 20px;
}

nav.type-listing > div .container > ul > li:hover a, nav.type-listing > div .container > ul > li:focus a {
  color: #02cd8d;
  text-decoration: none;
}

nav.type-listing > div .container > ul > li .services {
  visibility: hidden;
  display: none;
  opacity: 0;
  transition: opacity .3s;
  position: absolute;
  top: 100%;
  left: 0;
  box-shadow: rgba(148, 154, 158, 0.5) 0 2px 3px;
  background-color: #FFFFFF;
  padding: 15px 10px;
}

nav.type-listing > div .container > ul > li .services.right {
  right: 0;
  left: initial;
}

nav.type-listing > div .container > ul > li .services.column-to-show-1 {
  width: 271px;
}

nav.type-listing > div .container > ul > li .services.column-to-show-2 {
  width: 522px;
}

nav.type-listing > div .container > ul > li .services.column-to-show-3 {
  width: 773px;
}

nav.type-listing > div .container > ul > li .services.column-to-show-4 {
  width: 1024px;
}

nav.type-listing > div .container > ul > li .services .services-column {
  float: left;
  width: 250px;
}

nav.type-listing > div .container > ul > li .services .services-column:not(:first-child) {
  border-left: 1px solid rgba(148, 154, 158, 0.5);
}

nav.type-listing > div .container > ul > li .services .services-column a {
  display: block;
  padding: 8px 20px;
  cursor: pointer;
  text-decoration: none;
  color: #888888;
  font-size: 13px;
  transition: color .3s;
}

nav.type-listing > div .container > ul > li .services .services-column a:hover {
  color: #0F0F0F;
  font-weight: bold;
}

nav.type-listing > div .container > ul > li:hover .services, nav.type-listing > div .container > ul > li.open .services {
  visibility: visible;
  display: block;
  opacity: 1;
}

nav.type-listing.is-sticky {
  position: fixed;
  top: 0;
  bottom: auto;
  left: 0;
  right: 0;
  border-top: 3px solid #02cd8d;
  z-index: 3;
}

nav.type-listing.is-sticky > div .container > ul > li > a > img {
  display: none;
}

nav.type-guest-vendor {
  background-color: #373f48;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  position: relative;
  z-index: 2;
  box-shadow: #d5d7d9 0 0 5px;
  border-top: 3px solid #02cd8d;
}

nav.type-guest-vendor .container > ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: block;
  width: auto;
  float: left;
}

nav.type-guest-vendor .container > ul > li {
  float: left;
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
}

nav.type-guest-vendor .container > ul > li > a {
  position: relative;
  cursor: pointer;
  text-decoration: none;
  color: #FFFFFF;
  display: block;
  background-color: transparent;
  text-align: center;
  padding: 10px 0 6px;
  border-bottom: 3px solid transparent;
  transition: border-bottom .2s ease-in-out;
}

nav.type-guest-vendor .container > ul > li:hover > a, nav.type-guest-vendor .container > ul > li.active > a {
  color: #02cd8d;
  text-decoration: none;
  border-bottom: 3px solid #02cd8d;
}

nav.type-guest-vendor .container .link {
  display: block;
  float: right;
  padding: 3px 15px;
  margin: 6px 0;
  font-size: 14px;
}

nav.type-pages-faqs {
  background-color: #ffffff;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  position: relative;
  z-index: 2;
  box-shadow: #d5d7d9 0 0 5px;
  border-bottom: 1px solid #28f2b2;
}

nav.type-pages-faqs .container > ul {
  list-style: none;
  padding: 0;
  margin: 0px auto;
  display: block;
  width: auto;
  text-align: center;
}

nav.type-pages-faqs .container > ul > li {
  display: inline-block;
  position: relative;
  padding-left: 70px;
  padding-right: 70px;
}

nav.type-pages-faqs .container > ul > li > a {
  position: relative;
  cursor: pointer;
  text-decoration: none;
  font-size: 24px;
  color: #1b2b41;
  display: block;
  background-color: transparent;
  text-align: center;
  padding: 36px 0 37px;
  border-bottom: 3px solid transparent;
  transition: border-bottom .2s ease-in-out;
}

nav.type-pages-faqs .container > ul > li:hover > a, nav.type-pages-faqs .container > ul > li.active > a {
  color: #28f2b2;
  text-decoration: none;
  position: relative;
}

nav.type-pages-faqs .container > ul > li:hover > a:after, nav.type-pages-faqs .container > ul > li.active > a:after {
  position: absolute;
  display: block;
  content: '';
  width: 290px;
  height: 3px;
  bottom: 0;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #28f2b2;
}

nav.type-pages-faqs .container .link {
  display: block;
  float: right;
  padding: 3px 15px;
  margin: 6px 0;
  font-size: 14px;
}

.sticky-wrapper.is-sticky nav.type-landing {
  border-top: 3px solid #02cd8d;
  border-bottom: 1px solid rgba(2, 205, 141, 0.5);
}

.sticky-wrapper.is-sticky nav.type-landing > div .container > ul > li > a > img {
  display: none;
}

@media only screen and (max-width: 991px) {
  nav.type-client {
    display: none;
  }
  nav.type-client .container > ul > li:first-child {
    padding-left: 0;
  }
  nav.type-client .container > ul > li .mobile-menu-wrapper .material-icons {
    color: #ffffff;
    cursor: pointer;
    font-size: 30px;
    padding-top: 5px;
  }
}

@media only screen and (max-width: 767px) {
  nav.type-landing .container {
    padding-left: 0;
    right: 0;
  }
  nav.type-landing > div .container.portfolio_service-container > ul {
    width: 100%;
  }
  nav.type-landing > div .container > ul > li {
    width: 25%;
  }
  nav.type-landing > div .container > ul > li:hover .services, nav.type-landing > div .container > ul > li.open .services {
    visibility: hidden;
    display: none;
    opacity: 0;
  }
  nav.type-listing {
    display: none;
  }
  nav.type-pages-faqs .container {
    padding-left: 0;
    padding-right: 0;
  }
  nav.type-pages-faqs .container > ul {
    display: table;
    width: 100%;
  }
  nav.type-pages-faqs .container > ul > li {
    display: table-cell;
    width: auto;
    padding-left: 10px;
    padding-right: 10px;
  }
  nav.type-pages-faqs .container > ul > li a {
    display: block;
    font-size: 16px;
    padding: 15px 0 16px;
    position: relative;
  }
  nav.type-pages-faqs .container > ul > li.active a::after {
    width: 100%;
    height: 3px;
    left: 0;
    right: 0;
    transform: initial;
  }
  nav.type-guest-vendor .container {
    padding-left: 0;
    padding-right: 0;
  }
  nav.type-guest-vendor .container ul {
    float: none;
    width: 100%;
    display: table;
  }
  nav.type-guest-vendor .container ul li {
    float: none;
    display: table-cell;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@keyframes logoMove {
  0% {
    margin-left: 100%;
  }
  100% {
    margin-left: -150%;
  }
}

#landing-site-container header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 4;
  padding-top: 20px;
}

#landing-site-container header .logo-wrapper a {
  display: block;
}

#landing-site-container header .logo-wrapper a .svg-menu img {
  width: 200px;
  margin-top: 5px;
}

#landing-site-container header .for-client,
#landing-site-container header .for-vendor,
#landing-site-container header .for-language,
#landing-site-container header .link,
#landing-site-container header .link-with-border,
#landing-site-container header .quick-wrapper {
  display: none;
}

#landing-site-container header .mobile-menu-wrapper .material-icons {
  color: #fff;
  cursor: pointer;
  font-size: 30px;
  line-height: 50px;
  height: 50px;
}

#landing-site-container #search-wrapper {
  height: 545px;
  position: relative;
  z-index: 3;
  background: url("../../images/root/home/banner.jpg") center center;
  background-size: cover;
}

#landing-site-container #search-wrapper form {
  position: absolute;
  width: 860px;
  max-width: 100%;
  top: 180px;
  left: 50%;
  transform: translateX(-50%);
}

#landing-site-container #search-wrapper form .legend {
  font-size: 26px;
  color: #02cd8d;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  letter-spacing: 1px;
}

#landing-site-container #search-wrapper form .description {
  letter-spacing: 1px;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 30px;
  font-size: 16px;
  text-align: center;
}

#landing-site-container #search-wrapper form .form-row {
  display: table;
  width: 100%;
  border-radius: 3px;
  box-shadow: #02cd8d 0px 2px 2px 1px;
}

#landing-site-container #search-wrapper form .form-row [class^=col] {
  display: table-cell;
}

#landing-site-container #search-wrapper form .form-row .col-service {
  width: 350px;
  padding: 15px 15px 10px;
  background-color: #FFFFFF;
}

#landing-site-container #search-wrapper form .form-row .col-service label {
  display: block;
  color: #4b4b4b;
  font-size: 13px;
  margin: 0;
  font-weight: bold;
}

#landing-site-container #search-wrapper form .form-row .col-service input {
  display: block;
  padding: 5px 0;
  color: #02cd8d;
  border: none;
  outline: 0;
  box-shadow: none;
  font-weight: bold;
}

#landing-site-container #search-wrapper form .form-row .col-service input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-weight: normal;
}

#landing-site-container #search-wrapper form .form-row .col-service input::-moz-placeholder {
  /* Firefox 19+ */
  font-weight: normal;
}

#landing-site-container #search-wrapper form .form-row .col-service input:-ms-input-placeholder {
  /* IE 10+ */
  font-weight: normal;
}

#landing-site-container #search-wrapper form .form-row .col-service input:-moz-placeholder {
  /* Firefox 18- */
  font-weight: normal;
}

#landing-site-container #search-wrapper form .form-row .col-location {
  width: 350px;
  padding: 15px 15px 10px;
  background-color: #FFFFFF;
  border-left: 1px solid #808080;
}

#landing-site-container #search-wrapper form .form-row .col-location label {
  display: block;
  color: #4b4b4b;
  font-size: 13px;
  margin: 0;
  font-weight: bold;
}

#landing-site-container #search-wrapper form .form-row .col-location input {
  display: block;
  padding: 5px 0;
  color: #02cd8d;
  border: none;
  outline: 0;
  box-shadow: none;
  font-weight: bold;
}

#landing-site-container #search-wrapper form .form-row .col-location input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-weight: normal;
}

#landing-site-container #search-wrapper form .form-row .col-location input::-moz-placeholder {
  /* Firefox 19+ */
  font-weight: normal;
}

#landing-site-container #search-wrapper form .form-row .col-location input:-ms-input-placeholder {
  /* IE 10+ */
  font-weight: normal;
}

#landing-site-container #search-wrapper form .form-row .col-location input:-moz-placeholder {
  /* Firefox 18- */
  font-weight: normal;
}

#landing-site-container #search-wrapper form .form-row .col-button {
  border-left: 1px solid #808080;
  position: relative;
  background-color: #FFFFFF;
  min-width: 72px;
}

#landing-site-container #search-wrapper form .form-row .col-button button {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: transparent;
  border: none;
  outline: 0;
}

#landing-site-container #search-wrapper form .form-row .col-button button .fa {
  color: #18d197;
  font-size: 30px;
  text-align: center;
}

#landing-site-container #search-wrapper form .form-type-row {
  margin-bottom: 12px;
}

#landing-site-container #search-wrapper form .form-type-row .types .type {
  cursor: pointer;
  height: 60px;
  position: relative;
  padding-left: 75px;
  padding-right: 20px;
  line-height: 60px;
  border-radius: 5px;
  float: left;
  background-color: #e5edf2;
}

#landing-site-container #search-wrapper form .form-type-row .types .type .checkbox {
  position: absolute;
  left: 9px;
  top: 50%;
  margin: 0;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #fff;
}

#landing-site-container #search-wrapper form .form-type-row .types .type .checkbox:after {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  content: "";
  display: none;
  width: 12px;
  height: 12px;
  background-color: #18293e;
  border-radius: 50%;
}

#landing-site-container #search-wrapper form .form-type-row .types .type .icon {
  position: absolute;
  left: 40px;
  top: 50%;
  transform: translateY(-50%);
  width: 22px;
  height: 30px;
  background-size: cover;
}

#landing-site-container #search-wrapper form .form-type-row .types .type .text {
  display: block;
  font-size: 14px;
  font-weight: 700;
  color: #38485a;
}

#landing-site-container #search-wrapper form .form-type-row .types .type.type-service .icon {
  background-image: url("../../images/root/search/serach bar by service-grey.png");
}

#landing-site-container #search-wrapper form .form-type-row .types .type.type-portfolio {
  padding-left: 86px;
}

#landing-site-container #search-wrapper form .form-type-row .types .type.type-portfolio .icon {
  width: 30px;
  left: 41px;
  background-image: url("../../images/root/search/serach bar by portfolio-grey.png");
}

#landing-site-container #search-wrapper form .form-type-row .types .type:not(:first-child) {
  margin-left: 10px;
}

#landing-site-container #search-wrapper form .form-type-row .types .type.checked {
  background-color: #28f3b2;
}

#landing-site-container #search-wrapper form .form-type-row .types .type.checked .text {
  color: #fff;
}

#landing-site-container #search-wrapper form .form-type-row .types .type.checked .checkbox:after {
  display: block;
}

#landing-site-container #search-wrapper form .form-type-row .types .type.checked.type-service .icon {
  background-image: url("../../images/root/search/serach bar by service-white.png");
}

#landing-site-container #search-wrapper form .form-type-row .types .type.checked.type-portfolio .icon {
  background-image: url("../../images/root/search/serach bar by portfolio-white.png");
}

#landing-site-container #search-wrapper form .form-type-row .types:after {
  content: "";
  display: table;
  clear: both;
}

#landing-site-container #search-wrapper form .form-type-content {
  display: none;
}

#landing-site-container #search-wrapper form .form-type-content.show {
  display: block;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-service .option-box {
  width: 860px;
  max-width: 100%;
  padding: 18px 30px 15px;
  background-color: #FFFFFF;
  position: absolute;
  display: none;
  opacity: 0;
  visibility: hidden;
  top: 100%;
  margin-top: 1px;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, 0);
  transition: opacity .3s;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-service .option-box .service-category,
#landing-site-container #search-wrapper form .form-type-content.form-type-content-service .option-box .location-group {
  font-size: 13px;
  color: #000000;
  font-family: "Roboto", sans-serif;
  display: block;
  font-weight: bold;
  margin-top: 10px;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-service .option-box .service-category:hover,
#landing-site-container #search-wrapper form .form-type-content.form-type-content-service .option-box .location-group:hover {
  text-decoration: none;
  cursor: pointer;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-service .option-box .services a,
#landing-site-container #search-wrapper form .form-type-content.form-type-content-service .option-box .locations a {
  display: block;
  padding: 5px 10px 5px 0;
  line-height: 18px;
  font-size: 13px;
  color: #585858;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-service .option-box .services a:hover,
#landing-site-container #search-wrapper form .form-type-content.form-type-content-service .option-box .locations a:hover {
  text-decoration: none;
  font-weight: bold;
  padding-right: 0;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-service .option-box .services.with-border,
#landing-site-container #search-wrapper form .form-type-content.form-type-content-service .option-box .locations.with-border {
  border-left: 1px solid #585858;
  cursor: pointer;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-service .option-box.active {
  display: block;
  opacity: 1;
  visibility: visible;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-service .option-box.active .search-location {
  padding-bottom: 20px;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-service .option-box .col-options .row {
  margin-left: -10px;
  margin-right: -10px;
  overflow: hidden;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-service .option-box .col-options .row [class^=col] {
  padding-left: 10px;
  padding-right: 10px;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-service .option-box .col-next-button {
  position: relative;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-service .option-box .col-next-button .for-slick-next {
  font-size: 0;
  text-align: center;
  border-radius: 50%;
  height: 60px;
  width: 60px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  color: #02cd8d;
  cursor: pointer;
  border: 1px solid #02cd8d;
  transform: translate(-50%, -50%);
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-service .option-box .col-next-button .for-slick-next .fa {
  font-size: 50px;
  line-height: 60px;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-service .option-box .col-next-button:before {
  content: '';
  display: block;
  width: 1px;
  background-color: rgba(148, 154, 158, 0.5);
  position: absolute;
  left: -1px;
  top: 33px;
  bottom: 5px;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-service .option-box .col-services {
  height: 330px;
  position: relative;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-service .option-box .col-services:not(:first-child):before {
  content: '';
  display: block;
  width: 1px;
  background-color: rgba(148, 154, 158, 0.5);
  position: absolute;
  left: -1px;
  top: 33px;
  bottom: 5px;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-service .option-box.option-service {
  min-height: 283px;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-service .option-box.option-service .col-options {
  overflow: hidden;
  min-height: 250px;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-service .box-input {
  height: 50px;
  padding-right: 150px;
  position: relative;
  border-radius: 4px;
  background-color: #fff;
  border: 2px solid transparent;
  transition: border .3s;
  text-align: center;
  font-size: 24pt;
  font-weight: 300;
  color: #fff;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-service .box-input input {
  width: 100%;
  display: block;
  height: 46px;
  padding: 15px 15px 10px 15px;
  font-size: 16px;
  font-weight: 300;
  border: none;
  outline: none;
  color: #18293E;
  line-height: 1;
  border-radius: 4px 0 0 4px;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-service .box-input input ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #949A9E;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-service .box-input input :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #969ca0;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-service .box-input input ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #969ca0;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-service .box-input button {
  padding: 3px;
  display: block;
  top: -2px;
  right: -2px;
  width: 150px;
  position: absolute;
  box-shadow: none;
  background-color: #28f3b2;
  max-height: 50px;
  border-radius: 4px;
  border: none;
  text-align: center;
  transition: background-color .3s;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-service .box-input button:hover {
  background-color: #18e0a5;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-service .box-input.focus {
  border: 2px solid #28f3b2;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-service .box-options {
  z-index: 200;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  display: none;
  background-color: #FFFFFF;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-service .box-options.active {
  display: block;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-service .box-options .all-options {
  display: none;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-service .box-options .all-options.active {
  display: block;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-service .box-options .all-options .main-level {
  flex: 0 0 200px;
  width: 200px;
  border-right: 1px solid #f0f4f7;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-service .box-options .all-options .main-level .first-level-option {
  position: relative;
  padding: 15px 25px;
  cursor: pointer;
  font-size: 14px;
  line-height: 1.3;
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
  color: #000000;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-service .box-options .all-options .main-level .first-level-option:after {
  content: "";
  display: none;
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  border-left: 12px solid #28f3b2;
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-service .box-options .all-options .main-level .first-level-option.selected {
  background-color: #28f3b2;
  color: #FFFFFF;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-service .box-options .all-options .main-level .first-level-option.selected:after {
  display: block;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-service .box-options .all-options .sub-level {
  display: none;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-service .box-options .all-options .sub-level.active {
  display: block;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-service .box-options .all-options .sub-level .sub-level-options {
  position: absolute;
  top: 0;
  left: 220px;
  width: calc(100% - 220px);
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-service .box-options .all-options .sub-level .sub-level-options .third-level-option a {
  text-decoration: none;
  cursor: pointer;
  display: block;
  float: left;
  color: #797979;
  padding: 15px 10px;
  font-size: 13px;
  font-weight: 300;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-service .box-options .all-options .sub-level .sub-level-options .third-level-option a:hover {
  color: #18293E;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-service .box-options .search-options {
  display: none;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-service .box-options .search-options.active {
  display: block;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-service .box-options .search-options .option-row {
  position: relative;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-service .box-options .search-options .option-row .text {
  padding: 15px 120px 15px 15px;
  display: block;
  text-align: left;
  font-size: 12px;
  color: #222222;
  text-decoration: none;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-service .box-options .search-options .option-row .text-type {
  color: #a9b1be;
  font-size: 12px;
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: -1;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-service .box-options .search-options .option-row:hover, #landing-site-container #search-wrapper form .form-type-content.form-type-content-service .box-options .search-options .option-row.hover {
  background-color: #28f3b2;
  color: #FFFFFF;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-service .box-options .search-options .option-row:hover .text,
#landing-site-container #search-wrapper form .form-type-content.form-type-content-service .box-options .search-options .option-row:hover .text-type, #landing-site-container #search-wrapper form .form-type-content.form-type-content-service .box-options .search-options .option-row.hover .text,
#landing-site-container #search-wrapper form .form-type-content.form-type-content-service .box-options .search-options .option-row.hover .text-type {
  color: #FFFFFF;
  z-index: 2;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-input {
  height: 50px;
  padding-right: 150px;
  position: relative;
  border-radius: 4px;
  background-color: #fff;
  border: 2px solid transparent;
  transition: border .3s;
  text-align: center;
  font-size: 24pt;
  font-weight: 300;
  color: #fff;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-input input {
  width: 100%;
  display: block;
  padding: 15px;
  font-size: 16px;
  font-weight: 300;
  border: none;
  outline: none;
  color: #18293E;
  line-height: 1;
  border-radius: 4px 0 0 4px;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-input input ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #949A9E;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-input input :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #969ca0;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-input input ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #969ca0;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-input button {
  padding: 3px;
  display: block;
  top: -2px;
  right: -2px;
  width: 150px;
  position: absolute;
  box-shadow: none;
  background-color: #28f3b2;
  max-height: 50px;
  border-radius: 4px;
  border: none;
  text-align: center;
  transition: background-color .3s;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-input button:hover {
  background-color: #18e0a5;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-input.focus {
  border: 2px solid #28f3b2;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-options {
  z-index: 200;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  display: none;
  background-color: #FFFFFF;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-options.active {
  display: block;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-options .all-options {
  display: none;
  x-justify-content: space-around;
  x-align-items: stretch;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-options .all-options.active {
  display: flex;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-options .all-options .main-level {
  flex: 0 0 200px;
  width: 200px;
  border-right: 1px solid #f0f4f7;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-options .all-options .main-level .first-level-option {
  text-align: left;
  position: relative;
  padding: 15px 25px;
  cursor: pointer;
  font-size: 14px;
  line-height: 1.3;
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
  color: #000;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-options .all-options .main-level .first-level-option:after {
  content: "";
  display: none;
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  border-left: 12px solid #28f3b2;
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-options .all-options .main-level .first-level-option.selected {
  background-color: #28f3b2;
  color: #FFFFFF;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-options .all-options .main-level .first-level-option.selected:after {
  display: block;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-options .all-options .sub-level {
  display: none;
  flex: 1;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-options .all-options .sub-level.active {
  display: block;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-options .all-options .sub-level .sub-level-options {
  display: flex;
  padding: 10px;
  x-justify-content: space-around;
  x-align-items: stretch;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-options .all-options .sub-level .sub-level-options .second-level-option {
  width: 200px;
  flex: 0 0 200px;
  color: #18293E;
  padding: 10px;
  font-size: 13px;
  font-weight: 700;
  cursor: default;
  text-align: left;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-options .all-options .sub-level .sub-level-options .third-level-option {
  flex: 1;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-options .all-options .sub-level .sub-level-options .third-level-option:after {
  content: "";
  display: table;
  clear: both;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-options .all-options .sub-level .sub-level-options .third-level-option a {
  text-decoration: none;
  cursor: pointer;
  display: block;
  float: left;
  color: #797979;
  padding: 10px;
  font-size: 13px;
  font-weight: 300;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-options .all-options .sub-level .sub-level-options .third-level-option a:hover {
  color: #18293E;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-options .search-options {
  display: none;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-options .search-options.active {
  display: block;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-options .search-options .option-row {
  position: relative;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-options .search-options .option-row .text {
  padding: 15px 120px 15px 15px;
  display: block;
  text-align: left;
  font-size: 12px;
  color: #222222;
  text-decoration: none;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-options .search-options .option-row .text-type {
  color: #a9b1be;
  font-size: 12px;
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: -1;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-options .search-options .option-row:hover, #landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-options .search-options .option-row.hover {
  background-color: #28f3b2;
  color: #FFFFFF;
}

#landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-options .search-options .option-row:hover .text,
#landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-options .search-options .option-row:hover .text-type, #landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-options .search-options .option-row.hover .text,
#landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-options .search-options .option-row.hover .text-type {
  color: #FFFFFF;
  z-index: 2;
}

#landing-site-container #content #how-it-works {
  background-color: #ffffff;
  padding: 60px 0 45px;
}

#landing-site-container #content #how-it-works .title {
  text-align: center;
  color: #18293e;
  font-size: 32px;
  position: relative;
  padding-bottom: 30px;
  margin-bottom: 70px;
  font-weight: bold;
}

#landing-site-container #content #how-it-works .title:after {
  position: absolute;
  display: block;
  content: '';
  width: 110px;
  height: 3px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #02cd8d;
}

#landing-site-container #content #how-it-works .works-row {
  max-width: 1100px;
  width: 100%;
  margin: 0 auto 50px;
}

#landing-site-container #content #how-it-works .works-row [class^=col] {
  position: relative;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  color: #18293e;
}

#landing-site-container #content #how-it-works .works-row [class^=col] .image-wrapper {
  margin-bottom: 30px;
  position: relative;
  height: 100px;
  width: 100%;
}

#landing-site-container #content #how-it-works .works-row [class^=col] .image-wrapper img {
  display: block;
  max-height: 100%;
  max-width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#landing-site-container #content #how-it-works .works-row [class^=col] .legend {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

#landing-site-container #content #how-it-works .works-row [class^=col] .description {
  font-size: 13px;
  line-height: 2;
}

#landing-site-container #content #how-it-works .works-row [class^=col]:not(:first-child):before {
  color: #d1dde5;
  font: normal normal normal 50px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f105";
  position: absolute;
  right: 100%;
  top: 30px;
}

#landing-site-container #content #how-it-works .link {
  text-align: center;
}

#landing-site-container #content #how-it-works .link a {
  padding: 15px 35px;
  font-size: 16px;
  border-radius: 2px;
  border: 1px solid #02cd8d;
  background-color: #FFFFFF;
  text-decoration: none;
  color: #02cd8d;
  display: inline-block;
  cursor: pointer;
  transition: background-color .3s, border .3s, color .3s;
}

#landing-site-container #content #how-it-works .link a:hover {
  background-color: #F5F5F5;
  border: 1px solid #02C384;
  color: #02C384;
}

#landing-site-container #content #video-backdrop {
  position: relative;
}

#landing-site-container #content #video-backdrop iframe {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -45%);
  width: 60%;
  height: 70%;
}

#landing-site-container #content #video-backdrop img {
  margin: 0 auto;
}

#landing-site-container #content #portfolio-references {
  padding: 50px 0;
  color: #18293e;
}

#landing-site-container #content #portfolio-references .section-title {
  text-align: center;
  font-size: 26px;
  position: relative;
  padding-bottom: 23px;
  margin-bottom: 30px;
  font-weight: bold;
}

#landing-site-container #content #portfolio-references .section-title:after {
  position: absolute;
  display: block;
  content: '';
  width: 100px;
  height: 3px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #02cd8d;
}

#landing-site-container #content #portfolio-references .section-body {
  position: relative;
}

#landing-site-container #content #portfolio-references .section-body .portfolio-service-categories {
  background-color: #f3f3f3;
}

#landing-site-container #content #portfolio-references .section-body .portfolio-service-categories ul {
  list-style: none;
  padding: 0;
  margin: 0 auto;
  display: block;
  width: 50%;
}

#landing-site-container #content #portfolio-references .section-body .portfolio-service-categories ul:after {
  content: "";
  display: table;
  clear: both;
}

#landing-site-container #content #portfolio-references .section-body .portfolio-service-categories ul > li {
  float: left;
  width: 25%;
  position: relative;
}

#landing-site-container #content #portfolio-references .section-body .portfolio-service-categories ul > li > a {
  position: relative;
  cursor: pointer;
  text-decoration: none;
  display: block;
  background-color: transparent;
  text-align: center;
  padding: 15px 5px 15px;
  color: #18293e;
  transition: color .3s, background-color .2s ease-in-out;
}

#landing-site-container #content #portfolio-references .section-body .portfolio-service-categories ul > li > a .image-wrapper {
  position: relative;
  display: block;
  height: 40px;
  margin-bottom: 10px;
}

#landing-site-container #content #portfolio-references .section-body .portfolio-service-categories ul > li > a .image-wrapper img {
  max-height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#landing-site-container #content #portfolio-references .section-body .portfolio-service-categories ul > li > a .image-wrapper img.default {
  display: block;
}

#landing-site-container #content #portfolio-references .section-body .portfolio-service-categories ul > li > a .image-wrapper img.hover {
  display: none;
}

#landing-site-container #content #portfolio-references .section-body .portfolio-service-categories ul > li > a .vertical-middle {
  height: 36px;
  display: block;
  position: relative;
}

#landing-site-container #content #portfolio-references .section-body .portfolio-service-categories ul > li > a .vertical-middle span {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  font-size: 13px;
}

#landing-site-container #content #portfolio-references .section-body .portfolio-service-categories ul > li .services {
  visibility: hidden;
  display: none;
  opacity: 0;
  transition: opacity .3s;
  position: absolute;
  top: 100%;
  left: 0;
  box-shadow: rgba(148, 154, 158, 0.5) 0 2px 3px;
  background-color: #FFFFFF;
  padding: 15px 10px;
}

#landing-site-container #content #portfolio-references .section-body .portfolio-service-categories ul > li .services.right {
  right: 0;
  left: initial;
}

#landing-site-container #content #portfolio-references .section-body .portfolio-service-categories ul > li .services.column-to-show-1 {
  width: 271px;
}

#landing-site-container #content #portfolio-references .section-body .portfolio-service-categories ul > li .services.column-to-show-2 {
  width: 522px;
}

#landing-site-container #content #portfolio-references .section-body .portfolio-service-categories ul > li .services.column-to-show-3 {
  width: 773px;
}

#landing-site-container #content #portfolio-references .section-body .portfolio-service-categories ul > li .services.column-to-show-4 {
  width: 1024px;
}

#landing-site-container #content #portfolio-references .section-body .portfolio-service-categories ul > li .services .services-column {
  float: left;
  width: 250px;
}

#landing-site-container #content #portfolio-references .section-body .portfolio-service-categories ul > li .services .services-column:not(:first-child) {
  border-left: 1px solid rgba(148, 154, 158, 0.5);
}

#landing-site-container #content #portfolio-references .section-body .portfolio-service-categories ul > li .services .services-column a {
  display: block;
  padding: 8px 15px;
  cursor: pointer;
  text-decoration: none;
  color: #888888;
  font-size: 13px;
  line-height: 18px;
  transition: color .3s;
}

#landing-site-container #content #portfolio-references .section-body .portfolio-service-categories ul > li .services .services-column a:hover {
  color: #0F0F0F;
  font-weight: bold;
  font-size: 13px;
}

#landing-site-container #content #portfolio-references .section-body .portfolio-service-categories ul > li:hover > a, #landing-site-container #content #portfolio-references .section-body .portfolio-service-categories ul > li.open > a {
  color: #02cd8d;
  text-decoration: none;
}

#landing-site-container #content #portfolio-references .section-body .portfolio-service-categories ul > li:hover > a .image-wrapper img.default, #landing-site-container #content #portfolio-references .section-body .portfolio-service-categories ul > li.open > a .image-wrapper img.default {
  display: none;
}

#landing-site-container #content #portfolio-references .section-body .portfolio-service-categories ul > li:hover > a .image-wrapper img.hover, #landing-site-container #content #portfolio-references .section-body .portfolio-service-categories ul > li.open > a .image-wrapper img.hover {
  display: block;
}

#landing-site-container #content #portfolio-references .section-body .portfolio-service-categories ul > li:hover .services, #landing-site-container #content #portfolio-references .section-body .portfolio-service-categories ul > li.open .services {
  visibility: visible;
  display: block;
  opacity: 1;
}

#landing-site-container #content #portfolio-references .section-body .portfolio-services {
  padding: 50px 0;
  text-align: center;
}

#landing-site-container #content #portfolio-references .section-body .portfolio-services .portfolio-service-row {
  display: none;
  margin-left: -10px;
  margin-right: -10px;
}

#landing-site-container #content #portfolio-references .section-body .portfolio-services .portfolio-service-row .portfolio-service {
  display: inline-block;
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
  border-right: 1px solid #455465;
  margin-bottom: 15px;
  padding-left: 10px;
  padding-right: 10px;
  color: #455465;
}

#landing-site-container #content #portfolio-references .section-body .portfolio-services .portfolio-service-row .portfolio-service.selected, #landing-site-container #content #portfolio-references .section-body .portfolio-services .portfolio-service-row .portfolio-service:hover {
  color: #01d693;
}

#landing-site-container #content #portfolio-references .section-body .portfolio-services .portfolio-service-row .portfolio-service:last-child {
  border-right: 1px solid transparent;
}

#landing-site-container #content #portfolio-references .section-body .works-listing-results {
  margin-left: -10px;
  margin-right: -10px;
}

#landing-site-container #content #portfolio-references .section-body .works-listing-results .portfolio-wrapper {
  float: left;
  width: 20%;
  padding: 0 10px 20px 10px;
}

#landing-site-container #content #portfolio-references .section-body .works-listing-results .portfolio-wrapper .portfolio {
  font-family: "roboto";
  text-decoration: none;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
  margin-bottom: 20px;
  border-radius: 5px;
}

#landing-site-container #content #portfolio-references .section-body .works-listing-results .portfolio-wrapper .portfolio .portfolio-ajax-link {
  cursor: pointer;
}

#landing-site-container #content #portfolio-references .section-body .works-listing-results .portfolio-wrapper .portfolio .portfolio-wrapper-heading {
  padding-bottom: 75%;
  background-size: cover;
  background-position: 0 0;
  position: relative;
}

#landing-site-container #content #portfolio-references .section-body .works-listing-results .portfolio-wrapper .portfolio .portfolio-wrapper-heading .portfolio-wrapper-heading-title {
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  z-index: 10;
  color: #fff;
  font-size: 18px;
  line-height: 26px;
  width: 75%;
}

#landing-site-container #content #portfolio-references .section-body .works-listing-results .portfolio-wrapper .portfolio .lazy {
  position: relative;
  border-radius: 4px;
}

#landing-site-container #content #portfolio-references .section-body .works-listing-results .portfolio-wrapper .portfolio .lazy::after {
  content: '';
  display: block;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("../../images/root/work/grid-grey-layer.png");
  background-repeat: repeat;
  background-size: 1px 190px;
}

#landing-site-container #content #portfolio-references .section-body .works-listing-results .portfolio-wrapper .portfolio .lazy .location {
  z-index: 3;
  position: absolute;
  bottom: 8px;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
}

#landing-site-container #content #portfolio-references .section-body .works-listing-results .portfolio-wrapper .portfolio .lazy .location .fa {
  color: #ffffff;
  padding-top: 5px;
  font-size: 24px;
}

#landing-site-container #content #portfolio-references .section-body .works-listing-results .portfolio-wrapper .portfolio .lazy .location span {
  color: white;
  padding-left: 5px;
  font-size: 14px;
}

#landing-site-container #content #portfolio-references .section-body .works-listing-results .portfolio-wrapper .portfolio .bookmark {
  top: 0px;
}

#landing-site-container #content #portfolio-references .section-body .works-listing-results .portfolio-wrapper .portfolio .bookmark .review .fa-bookmark {
  z-index: 3;
  color: #f5c343;
  position: absolute;
  font-size: 50px;
  transform: scaleX(0.8);
  top: -2%;
  right: 5%;
}

#landing-site-container #content #portfolio-references .section-body .works-listing-results .portfolio-wrapper .portfolio .bookmark .review .fa-star {
  z-index: 3;
  color: #ffffff;
  position: absolute;
  font-size: 20px;
  right: 8.5%;
  top: 2%;
}

#landing-site-container #content #portfolio-references .section-body .works-listing-results .portfolio-wrapper .portfolio .bookmark .feature {
  position: absolute;
  top: -5px;
  right: 10%;
  z-index: 3;
}

#landing-site-container #content #portfolio-references .section-body .works-listing-results .portfolio-wrapper .portfolio .content {
  padding: 0 10px 15px 15px;
}

#landing-site-container #content #portfolio-references .section-body .works-listing-results .portfolio-wrapper .portfolio .content .title {
  color: #18293E;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
  line-height: 1.3;
  letter-spacing: 0;
  padding-bottom: 10px;
}

#landing-site-container #content #portfolio-references .section-body .works-listing-results .portfolio-wrapper .portfolio .content .rate .fa {
  color: #f5c343;
}

#landing-site-container #content #portfolio-references .section-body .works-listing-results .portfolio-wrapper .portfolio .content .services {
  margin-left: -4px;
  margin-top: 4px;
}

#landing-site-container #content #portfolio-references .section-body .works-listing-results .portfolio-wrapper .portfolio .content .services .service {
  margin-left: 4px;
  margin-bottom: 4px;
  background-color: #505961;
  display: inline-block;
  padding: 3px 8px;
  border-radius: 2px;
  color: rgba(255, 255, 255, 0.75);
}

#landing-site-container #content #portfolio-references .section-body .works-listing-results .portfolio-wrapper .portfolio .content .services .service.hidden {
  display: none;
}

#landing-site-container #content #portfolio-references .section-body .works-listing-results .portfolio-wrapper .portfolio .content .divider {
  height: 1px;
  background-color: #edf1f3;
  margin-top: 10px;
  margin-bottom: 10px;
}

#landing-site-container #content #portfolio-references .section-body .works-listing-results .portfolio-wrapper .portfolio .content .divider-short {
  height: 1px;
  width: 10px;
  background-color: #868686;
  margin-top: 10px;
  margin-bottom: 10px;
}

#landing-site-container #content #portfolio-references .section-body .works-listing-results .portfolio-wrapper .portfolio .content .description {
  color: #737373;
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  line-height: 1.3;
}

#landing-site-container #content #portfolio-references .section-body .works-listing-results .portfolio-wrapper .portfolio .content .details .tags {
  color: #8b8b8b;
  font-size: 14px;
  padding-top: 10px;
  font-weight: 300;
}

#landing-site-container #content #portfolio-references .section-body .works-listing-results .portfolio-wrapper .portfolio .content .details .item-client-name {
  width: 65%;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
}

#landing-site-container #content #portfolio-references .section-body .works-listing-results .portfolio-wrapper .portfolio .content .details .premium {
  width: 35%;
  text-align: right;
}

#landing-site-container #content #portfolio-references .section-body .works-listing-results .portfolio-wrapper .portfolio .content .details .premium span {
  color: #ffffff;
  background-color: #ee7781;
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 2px;
}

#landing-site-container #content #portfolio-references .section-body .works-listing-results .portfolio-wrapper .portfolio .content .details .pro {
  width: 35%;
  text-align: right;
}

#landing-site-container #content #portfolio-references .section-body .works-listing-results .portfolio-wrapper .portfolio .content .details .pro span {
  color: #ffffff;
  background-color: #071c2f;
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 2px;
}

#landing-site-container #content #portfolio-references .section-body .works-listing-results .portfolio-wrapper .portfolio .footer {
  background-color: #f9fafb;
  padding: 10px 50px 10px 15px;
  min-height: 46px;
  position: relative;
  border-radius: 4px;
}

#landing-site-container #content #portfolio-references .section-body .works-listing-results .portfolio-wrapper .portfolio .footer .services {
  display: block;
}

#landing-site-container #content #portfolio-references .section-body .works-listing-results .portfolio-wrapper .portfolio .footer .services .service {
  margin-right: 4px;
  margin-bottom: 4px;
  background-color: #97a1b0;
  display: inline-block;
  padding: 3px 8px;
  border-radius: 2px;
  color: rgba(255, 255, 255, 0.75);
}

#landing-site-container #content #portfolio-references .section-body .works-listing-results .portfolio-wrapper .portfolio .footer .services .service.hidden {
  display: none;
}

#landing-site-container #content #portfolio-references .section-body .works-listing-results .portfolio-wrapper .portfolio .footer .col-favourite {
  position: absolute;
  right: 10px;
  top: 10px;
  display: block;
  text-decoration: none;
  cursor: pointer;
}

#landing-site-container #content #portfolio-references .section-body .works-listing-results .portfolio-wrapper .portfolio .footer .col-favourite .fa {
  color: #28f2b2;
}

#landing-site-container #content #portfolio-references .section-body .works-listing-results .portfolio-wrapper .portfolio .footer .col-favourite .fa-heart-o {
  display: block;
}

#landing-site-container #content #portfolio-references .section-body .works-listing-results .portfolio-wrapper .portfolio .footer .col-favourite .fa-heart {
  display: none;
}

#landing-site-container #content #portfolio-references .section-body .works-listing-results .portfolio-wrapper .portfolio .footer .col-favourite.un-favourite .fa-heart-o {
  display: none;
}

#landing-site-container #content #portfolio-references .section-body .works-listing-results .portfolio-wrapper .portfolio .footer .col-favourite.un-favourite .fa-heart {
  display: block;
}

#landing-site-container #content #portfolio-references .section-body .works-listing-results:after {
  content: '';
  display: table;
  clear: both;
}

#landing-site-container #content #portfolio-references .section-body .link {
  text-align: center;
}

#landing-site-container #content #portfolio-references .section-body .link a {
  padding: 15px 35px;
  font-size: 16px;
  border-radius: 2px;
  border: 1px solid #02cd8d;
  background-color: #FFFFFF;
  text-decoration: none;
  color: #02cd8d;
  display: inline-block;
  cursor: pointer;
  transition: background-color .3s, border .3s, color .3s;
}

#landing-site-container #content #portfolio-references .section-body .link a:hover {
  background-color: #F5F5F5;
  border: 1px solid #02C384;
  color: #02C384;
}

#landing-site-container #content #strengths {
  background-color: #18293e;
  padding: 50px 0;
}

#landing-site-container #content #strengths .title {
  text-align: center;
  color: #ffffff;
  font-size: 32px;
  position: relative;
  padding-bottom: 23px;
  margin-bottom: 80px;
  font-weight: bold;
}

#landing-site-container #content #strengths .title:after {
  position: absolute;
  display: block;
  content: '';
  width: 110px;
  height: 3px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #02cd8d;
}

#landing-site-container #content #strengths .strengths-row {
  max-width: 1100px;
  width: 100%;
  margin: 0 auto 50px;
}

#landing-site-container #content #strengths .strengths-row [class^=col] {
  position: relative;
  text-align: center;
}

#landing-site-container #content #strengths .strengths-row [class^=col] .image-wrapper {
  position: relative;
  height: 100px;
  width: 100%;
  margin-bottom: 30px;
}

#landing-site-container #content #strengths .strengths-row [class^=col] .image-wrapper img {
  display: block;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#landing-site-container #content #strengths .strengths-row [class^=col] .legend {
  font-size: 24px;
  font-weight: bold;
  color: #FFFFFF;
  margin-bottom: 10px;
}

#landing-site-container #content #strengths .strengths-row [class^=col] .description {
  font-size: 16px;
  color: #eeeeee;
  line-height: 1.6;
  font-weight: 300;
}

#landing-site-container #content #strengths .link {
  text-align: center;
}

#landing-site-container #content #strengths .link a {
  font-size: 16px;
  padding: 15px 35px;
  border-radius: 2px;
  text-decoration: none;
  border: 1px solid #02cd8d;
  background-color: #18293e;
  color: #02cd8d;
  display: inline-block;
  transition: background-color .3s, color .3s, border .3s;
}

#landing-site-container #content #strengths .link a:hover {
  background-color: #182237;
  border: 1px solid #02C384;
  color: #02C384;
}

#landing-site-container #content #recommended-by-clients {
  font-family: 'Roboto', sans-serif;
  background-color: #f9f9f9;
  padding: 100px 0 110px;
}

#landing-site-container #content #recommended-by-clients .title {
  text-align: center;
  color: #18293e;
  font-size: 32px;
  position: relative;
  padding-bottom: 25px;
  margin-bottom: 50px;
  font-weight: bold;
}

#landing-site-container #content #recommended-by-clients .title:after {
  position: absolute;
  display: block;
  content: '';
  width: 110px;
  height: 3px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #02cd8d;
}

#landing-site-container #content #recommended-by-clients .slider .item {
  font-size: 18px;
  padding-bottom: 90px;
}

#landing-site-container #content #recommended-by-clients .slider .item .image {
  background-image: url("../../images/root/home/About-2Easy-Digital-Project-Discussion.jpg");
  background-position: top;
  background-size: cover;
  height: 380px;
  margin-top: 20px;
}

#landing-site-container #content #recommended-by-clients .slider .item .icon:before {
  display: block;
  margin-bottom: 20px;
  content: '';
  height: 60px;
  width: 54px;
  background: url("../../images/root/home/recommendation.png") center center;
}

#landing-site-container #content #recommended-by-clients .slider .item .description {
  padding-top: 20px;
  padding-left: 20px;
  color: #18293e;
  font-weight: normal;
  margin-bottom: 20px;
  position: relative;
}

#landing-site-container #content #recommended-by-clients .slider .item .name {
  color: #18293e;
  font-weight: bold;
  padding-left: 20px;
}

#landing-site-container #content #recommended-by-clients .slider .item .green {
  color: #02cd8d;
  font-style: italic;
  font-weight: bold;
  padding-left: 20px;
}

#landing-site-container #content #recommended-by-clients .slider .item .word {
  position: relative;
}

#landing-site-container #content #recommended-by-clients .link {
  text-align: center;
}

#landing-site-container #content #recommended-by-clients .link a {
  font-size: 16px;
  padding: 15px 35px;
  border-radius: 2px;
  border: 1px solid #02cd8d;
  background-color: #FFFFFF;
  text-decoration: none;
  color: #02cd8d;
  display: inline-block;
  cursor: pointer;
  transition: background-color .3s, border .3s, color .3s;
}

#landing-site-container #content #recommended-by-clients .link a:hover {
  background-color: #F5F5F5;
  border: 1px solid #02C384;
  color: #02C384;
}

#landing-site-container #content #leading-service-provider {
  font-family: 'Roboto', sans-serif;
  background-color: #f9f9f9;
  background-image: url("../../images/root/home/Get-Listed-On-2Easy-As-A-Service-Provider.jpg");
  background-position: center;
  background-size: cover;
  padding: 80px 0 95px;
  position: relative;
}

#landing-site-container #content #leading-service-provider:after {
  content: '';
  display: block;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
}

#landing-site-container #content #leading-service-provider .title {
  text-align: center;
  color: #FFFFFF;
  font-size: 32px;
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 30px;
  font-weight: bold;
  z-index: 2;
}

#landing-site-container #content #leading-service-provider .title:after {
  position: absolute;
  display: block;
  content: '';
  width: 110px;
  height: 3px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #02cd8d;
}

#landing-site-container #content #leading-service-provider #leading-service-provider-wrapper {
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  z-index: 2;
}

#landing-site-container #content #leading-service-provider #leading-service-provider-wrapper .has-z-index {
  z-index: 2;
}

#landing-site-container #content #leading-service-provider #leading-service-provider-wrapper .green-bg {
  background-color: #02cd8d;
  padding: 20px;
}

#landing-site-container #content #leading-service-provider #leading-service-provider-wrapper .green-bg .legend {
  font-size: 16px;
  color: #FFFFFF;
  font-weight: bold;
}

#landing-site-container #content #leading-service-provider #leading-service-provider-wrapper .green-bg .content {
  color: #FFFFFF;
  font-size: 16px;
  font-weight: normal;
}

#landing-site-container #content #leading-service-provider #leading-service-provider-wrapper .green-bg .links {
  text-align: center;
  margin-top: 15px;
}

#landing-site-container #content #leading-service-provider #leading-service-provider-wrapper .green-bg .links .link {
  display: inline-block;
  padding: 10px 20px;
  background-color: #FFFFFF;
  color: #02cd8d;
  font-weight: bold;
  cursor: pointer;
  font-size: 16px;
}

#landing-site-container #content #leading-service-provider #leading-service-provider-wrapper .green-bg .links .link:hover {
  text-decoration: none;
}

#landing-site-container #content #leading-service-provider #leading-service-provider-wrapper img {
  padding-left: 60px;
  padding-bottom: 25px;
}

#landing-site-container #content #leading-service-provider #leading-service-provider-wrapper .description {
  padding-left: 60px;
  font-size: 16px;
  font-style: italic;
  color: rgba(255, 255, 255, 0.8);
  font-weight: normal;
  margin-bottom: 0px;
  position: relative;
  letter-spacing: 1px;
  line-height: 2;
}

#landing-site-container #content #leading-service-provider #leading-service-provider-wrapper .from {
  font-size: 16px;
  padding-left: 60px;
}

#landing-site-container #content #leading-service-provider #leading-service-provider-wrapper .from .name {
  color: #FFFFFF;
  font-weight: bold;
  line-height: 2;
  margin-bottom: 4px;
  font-size: 15px;
}

#landing-site-container #content #leading-service-provider #leading-service-provider-wrapper .from .job-title {
  color: rgba(255, 255, 255, 0.8);
  font-weight: normal;
  font-size: 15px;
}

#landing-site-container #content #leading-service-provider #leading-service-provider-wrapper .from .green {
  color: #02cd8d;
  font-style: italic;
  font-weight: bold;
}

#landing-site-container #content #top-clients {
  font-family: 'Roboto', sans-serif;
  background-color: #f9f9f9;
  padding: 95px 0;
}

#landing-site-container #content #top-clients .title {
  text-align: center;
  color: #18293e;
  font-size: 32px;
  position: relative;
  padding-bottom: 30px;
  margin-bottom: 10px;
  font-weight: bold;
}

#landing-site-container #content #top-clients .title:after {
  position: absolute;
  display: block;
  content: '';
  width: 110px;
  height: 3px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #02cd8d;
}

#landing-site-container #content #top-clients .clients-logo .logos [class^=col-] {
  float: left;
  width: 16.666666667%;
}

#landing-site-container #content #top-clients .clients-logo .logos [class^=col-] .logo {
  padding: 20px;
  text-align: center;
}

#landing-site-container #content #top-clients .clients-logo .logos [class^=col-] .logo .image img {
  width: 80%;
}

#landing-site-container #content #supporting-organization {
  background-image: url("../../images/root/home/cyberport_dotted_banner_2Easy_member_Incubation_Programme.jpg");
  background-position: center;
  background-size: cover;
  padding: 80px 0;
  text-align: center;
}

#landing-site-container #content #supporting-organization .title {
  color: #ffffff;
  font-size: 32px;
  position: relative;
  padding-bottom: 23px;
  margin-bottom: 10px;
  font-weight: bold;
}

#landing-site-container #content #supporting-organization .title:after {
  position: absolute;
  display: block;
  content: '';
  width: 100px;
  height: 3px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #02cd8d;
}

#landing-site-container #content #supporting-organization .cyberport-logo {
  width: 30%;
  margin: 0 auto;
}

#landing-site-container #content #supporting-organization .cyberport-logo img {
  width: 50%;
}

#landing-site-container #content #supporting-organization .content {
  padding-bottom: 30px;
}

#landing-site-container #content #supporting-organization .content span {
  color: #28f2b2;
  font-size: 24px;
  font-weight: bold;
}

#landing-site-container #content #supporting-organization .description {
  width: 1000px;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 15px;
  font-size: 16px;
  margin: 0 auto;
  text-align: left;
}

#landing-site-container #content #supporting-organization .description .content-1 {
  font-weight: 300;
  font-style: italic;
  color: #ffffff;
  line-height: 1.5;
}

#landing-site-container #content #supporting-organization .description .name {
  font-weight: bold;
  color: #ffffff;
  padding-top: 5px;
}

#landing-site-container #content #supporting-organization .description .name span {
  color: #28f2b2;
  font-style: italic;
}

#landing-site-container #content #bottom-search-wrapper {
  height: 500px;
  z-index: 1;
  background-color: #e5edf2;
  position: relative;
}

#landing-site-container #content #bottom-search-wrapper form {
  position: absolute;
  width: 860px;
  max-width: 100%;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
}

#landing-site-container #content #bottom-search-wrapper form .legend {
  text-align: center;
  color: #18293e;
  font-size: 26px;
  position: relative;
  padding-bottom: 30px;
  margin-bottom: 30px;
  font-weight: bold;
}

#landing-site-container #content #bottom-search-wrapper form .legend:after {
  position: absolute;
  display: block;
  content: '';
  width: 100px;
  height: 3px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #02cd8d;
}

#landing-site-container #content #bottom-search-wrapper form .description {
  letter-spacing: 1px;
  color: #f3f3f3;
  margin-bottom: 30px;
  font-size: 17px;
  text-align: center;
}

#landing-site-container #content #bottom-search-wrapper form .form-row {
  display: table;
  width: 100%;
  border-radius: 3px;
  box-shadow: #02cd8d 0px 2px 2px 1px;
}

#landing-site-container #content #bottom-search-wrapper form .form-row [class^=col] {
  display: table-cell;
}

#landing-site-container #content #bottom-search-wrapper form .form-row .col-service {
  width: 350px;
  padding: 15px 15px 10px;
  background-color: #FFFFFF;
}

#landing-site-container #content #bottom-search-wrapper form .form-row .col-service label {
  display: block;
  color: #4b4b4b;
  font-size: 13px;
  margin: 0;
  font-weight: normal;
  font-weight: bold;
}

#landing-site-container #content #bottom-search-wrapper form .form-row .col-service input {
  display: block;
  padding: 5px 0;
  color: #02cd8d;
  border: none;
  outline: 0;
  box-shadow: none;
  font-weight: bold;
}

#landing-site-container #content #bottom-search-wrapper form .form-row .col-service input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-weight: normal;
}

#landing-site-container #content #bottom-search-wrapper form .form-row .col-service input::-moz-placeholder {
  /* Firefox 19+ */
  font-weight: normal;
}

#landing-site-container #content #bottom-search-wrapper form .form-row .col-service input:-ms-input-placeholder {
  /* IE 10+ */
  font-weight: normal;
}

#landing-site-container #content #bottom-search-wrapper form .form-row .col-service input:-moz-placeholder {
  /* Firefox 18- */
  font-weight: normal;
}

#landing-site-container #content #bottom-search-wrapper form .form-row .col-location {
  width: 350px;
  padding: 15px 15px 10px;
  background-color: #FFFFFF;
  border-left: 1px solid #808080;
}

#landing-site-container #content #bottom-search-wrapper form .form-row .col-location label {
  display: block;
  color: #4b4b4b;
  font-size: 13px;
  margin: 0;
  font-weight: bold;
}

#landing-site-container #content #bottom-search-wrapper form .form-row .col-location input {
  font-weight: bold;
  display: block;
  padding: 5px 0;
  color: #02cd8d;
  border: none;
  outline: 0;
  box-shadow: none;
}

#landing-site-container #content #bottom-search-wrapper form .form-row .col-location input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-weight: normal;
}

#landing-site-container #content #bottom-search-wrapper form .form-row .col-location input::-moz-placeholder {
  /* Firefox 19+ */
  font-weight: normal;
}

#landing-site-container #content #bottom-search-wrapper form .form-row .col-location input:-ms-input-placeholder {
  /* IE 10+ */
  font-weight: normal;
}

#landing-site-container #content #bottom-search-wrapper form .form-row .col-location input:-moz-placeholder {
  /* Firefox 18- */
  font-weight: normal;
}

#landing-site-container #content #bottom-search-wrapper form .form-row .col-button {
  border-left: 1px solid #808080;
  position: relative;
  min-width: 72px;
  background-color: #FFFFFF;
}

#landing-site-container #content #bottom-search-wrapper form .form-row .col-button button {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: transparent;
  border: none;
  outline: 0;
}

#landing-site-container #content #bottom-search-wrapper form .form-row .col-button button .fa {
  color: #28f3b2;
  font-size: 30px;
  text-align: center;
}

#landing-site-container #content #bottom-search-wrapper form .option-box {
  margin-top: 1px;
  width: 860px;
  max-width: 100%;
  padding: 8px 30px 15px;
  background-color: #FFFFFF;
  position: absolute;
  display: none;
  opacity: 0;
  visibility: hidden;
  top: 100%;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 1;
  transition: opacity .3s;
}

#landing-site-container #content #bottom-search-wrapper form .option-box .service-category,
#landing-site-container #content #bottom-search-wrapper form .option-box .location-group {
  font-size: 13px;
  color: #000000;
  font-family: "Roboto", sans-serif;
  display: block;
  font-weight: bold;
  margin-top: 10px;
}

#landing-site-container #content #bottom-search-wrapper form .option-box .service-category:hover,
#landing-site-container #content #bottom-search-wrapper form .option-box .location-group:hover {
  text-decoration: none;
  cursor: pointer;
}

#landing-site-container #content #bottom-search-wrapper form .option-box .services a,
#landing-site-container #content #bottom-search-wrapper form .option-box .locations a {
  display: block;
  padding: 5px 10px 5px 0;
  font-size: 13px;
  color: #585858;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
}

#landing-site-container #content #bottom-search-wrapper form .option-box .services a:hover,
#landing-site-container #content #bottom-search-wrapper form .option-box .locations a:hover {
  text-decoration: none;
  font-weight: bold;
  padding-right: 0;
}

#landing-site-container #content #bottom-search-wrapper form .option-box .services.with-border,
#landing-site-container #content #bottom-search-wrapper form .option-box .locations.with-border {
  border-left: 1px solid #585858;
  cursor: pointer;
}

#landing-site-container #content #bottom-search-wrapper form .option-box.active {
  display: block;
  opacity: 1;
  visibility: visible;
}

#landing-site-container #content #bottom-search-wrapper form .option-box .col-options {
  overflow: hidden;
}

#landing-site-container #content #bottom-search-wrapper form .option-box .col-options .row {
  margin-left: -10px;
  margin-right: -10px;
}

#landing-site-container #content #bottom-search-wrapper form .option-box .col-options .row [class^=col] {
  padding-left: 10px;
  padding-right: 10px;
}

#landing-site-container #content #bottom-search-wrapper form .option-box .col-next-button {
  position: relative;
}

#landing-site-container #content #bottom-search-wrapper form .option-box .col-next-button .for-slick-next {
  font-size: 0;
  text-align: center;
  border-radius: 50%;
  height: 60px;
  width: 60px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  color: #02cd8d;
  cursor: pointer;
  border: 1px solid #02cd8d;
  transform: translate(-50%, -50%);
}

#landing-site-container #content #bottom-search-wrapper form .option-box .col-next-button .for-slick-next .fa {
  font-size: 50px;
  line-height: 60px;
}

#landing-site-container #content #bottom-search-wrapper form .option-box .col-next-button:before {
  content: '';
  display: block;
  width: 1px;
  background-color: rgba(148, 154, 158, 0.5);
  position: absolute;
  left: -1px;
  top: 33px;
  bottom: 5px;
}

#landing-site-container #content #bottom-search-wrapper form .option-box .col-services {
  position: relative;
  height: 250px;
}

#landing-site-container #content #bottom-search-wrapper form .option-box .col-services:not(:first-child):before {
  content: '';
  display: block;
  width: 1px;
  background-color: rgba(148, 154, 158, 0.5);
  position: absolute;
  left: -1px;
  top: 33px;
  bottom: 5px;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-row {
  margin-bottom: 12px;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-row .types .type {
  cursor: pointer;
  height: 60px;
  position: relative;
  padding-left: 75px;
  padding-right: 20px;
  line-height: 60px;
  border-radius: 5px;
  float: left;
  background-color: #e5edf2;
  border: 1px solid #ffffff;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-row .types .type .checkbox {
  position: absolute;
  left: 9px;
  top: 50%;
  margin: 0;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #fff;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-row .types .type .checkbox:after {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  content: "";
  display: none;
  width: 12px;
  height: 12px;
  background-color: #18293e;
  border-radius: 50%;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-row .types .type .icon {
  position: absolute;
  left: 40px;
  top: 50%;
  transform: translateY(-50%);
  width: 22px;
  height: 30px;
  background-size: cover;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-row .types .type .text {
  display: block;
  font-size: 14px;
  font-weight: 700;
  color: #38485a;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-row .types .type.type-service .icon {
  background-image: url("../../images/root/search/serach bar by service-grey.png");
}

#landing-site-container #content #bottom-search-wrapper form .form-type-row .types .type.type-portfolio {
  padding-left: 86px;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-row .types .type.type-portfolio .icon {
  width: 30px;
  left: 41px;
  background-image: url("../../images/root/search/serach bar by portfolio-grey.png");
}

#landing-site-container #content #bottom-search-wrapper form .form-type-row .types .type:not(:first-child) {
  margin-left: 10px;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-row .types .type.checked {
  background-color: #28f3b2;
  border: 1px solid #28f3b2;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-row .types .type.checked .text {
  color: #fff;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-row .types .type.checked .checkbox:after {
  display: block;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-row .types .type.checked.type-service .icon {
  background-image: url("../../images/root/search/serach bar by service-white.png");
}

#landing-site-container #content #bottom-search-wrapper form .form-type-row .types .type.checked.type-portfolio .icon {
  background-image: url("../../images/root/search/serach bar by portfolio-white.png");
}

#landing-site-container #content #bottom-search-wrapper form .form-type-row .types:after {
  content: "";
  display: table;
  clear: both;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-content {
  display: none;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-content.show {
  display: block;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-input {
  height: 50px;
  padding-right: 150px;
  position: relative;
  border-radius: 4px;
  background-color: #fff;
  border: 2px solid transparent;
  transition: border .3s;
  text-align: center;
  font-size: 24pt;
  font-weight: 300;
  color: #fff;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-input input {
  width: 100%;
  display: block;
  height: 46px;
  padding: 15px 15px 10px 15px;
  font-size: 16px;
  font-weight: 300;
  border: none;
  outline: none;
  color: #18293E;
  line-height: 1;
  border-radius: 4px 0 0 4px;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-input input ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #949A9E;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-input input :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #969ca0;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-input input ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #969ca0;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-input button {
  padding: 3px;
  display: block;
  top: -2px;
  right: -2px;
  width: 150px;
  position: absolute;
  box-shadow: none;
  background-color: #28f3b2;
  max-height: 50px;
  border-radius: 4px;
  border: none;
  text-align: center;
  transition: background-color .3s;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-input button:hover {
  background-color: #18e0a5;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-input.focus {
  border: 2px solid #28f3b2;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options {
  z-index: 200;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  display: none;
  background-color: #FFFFFF;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options.active {
  display: block;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .all-options {
  display: none;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .all-options.active {
  display: block;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .all-options .main-level {
  flex: 0 0 200px;
  width: 200px;
  border-right: 1px solid #f0f4f7;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .all-options .main-level .first-level-option {
  position: relative;
  padding: 15px 25px;
  cursor: pointer;
  font-size: 14px;
  line-height: 1.3;
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
  color: #000000;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .all-options .main-level .first-level-option:after {
  content: "";
  display: none;
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  border-left: 12px solid #28f3b2;
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .all-options .main-level .first-level-option.selected {
  background-color: #28f3b2;
  color: #FFFFFF;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .all-options .main-level .first-level-option.selected:after {
  display: block;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .all-options .sub-level {
  display: none;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .all-options .sub-level.active {
  display: block;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .all-options .sub-level .sub-level-options {
  position: absolute;
  top: 0;
  left: 220px;
  width: calc(100% - 220px);
}

#landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .all-options .sub-level .sub-level-options .third-level-option a {
  text-decoration: none;
  cursor: pointer;
  display: block;
  float: left;
  color: #797979;
  padding: 15px 10px;
  font-size: 13px;
  font-weight: 300;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .all-options .sub-level .sub-level-options .third-level-option a:hover {
  color: #18293E;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .search-options {
  display: none;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .search-options.active {
  display: block;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .search-options .option-row {
  position: relative;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .search-options .option-row .text {
  padding: 15px 120px 15px 15px;
  display: block;
  text-align: left;
  font-size: 12px;
  color: #222222;
  text-decoration: none;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .search-options .option-row .text-type {
  color: #a9b1be;
  font-size: 12px;
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: -1;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .search-options .option-row:hover, #landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .search-options .option-row.hover {
  background-color: #28f3b2;
  color: #FFFFFF;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .search-options .option-row:hover .text,
#landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .search-options .option-row:hover .text-type, #landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .search-options .option-row.hover .text,
#landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .search-options .option-row.hover .text-type {
  color: #FFFFFF;
  z-index: 2;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-input {
  height: 50px;
  padding-right: 150px;
  position: relative;
  border-radius: 4px;
  background-color: #fff;
  border: 2px solid transparent;
  transition: border .3s;
  text-align: center;
  font-size: 24pt;
  font-weight: 300;
  color: #fff;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-input input {
  width: 100%;
  display: block;
  padding: 15px;
  font-size: 16px;
  font-weight: 300;
  border: none;
  outline: none;
  color: #18293E;
  line-height: 1;
  border-radius: 4px 0 0 4px;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-input input ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #949A9E;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-input input :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #969ca0;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-input input ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #969ca0;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-input button {
  padding: 3px;
  display: block;
  top: -2px;
  right: -2px;
  width: 150px;
  position: absolute;
  box-shadow: none;
  background-color: #28f3b2;
  max-height: 50px;
  border-radius: 4px;
  border: none;
  text-align: center;
  transition: background-color .3s;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-input button:hover {
  background-color: #18e0a5;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-input.focus {
  border: 2px solid #28f3b2;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options {
  z-index: 200;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  display: none;
  background-color: #FFFFFF;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options.active {
  display: block;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .all-options {
  display: none;
  x-justify-content: space-around;
  x-align-items: stretch;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .all-options.active {
  display: flex;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .all-options .main-level {
  flex: 0 0 200px;
  width: 200px;
  border-right: 1px solid #f0f4f7;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .all-options .main-level .first-level-option {
  text-align: left;
  position: relative;
  padding: 15px 25px;
  cursor: pointer;
  font-size: 14px;
  line-height: 1.3;
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
  color: #000;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .all-options .main-level .first-level-option:after {
  content: "";
  display: none;
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  border-left: 12px solid #28f3b2;
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .all-options .main-level .first-level-option.selected {
  background-color: #28f3b2;
  color: #FFFFFF;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .all-options .main-level .first-level-option.selected:after {
  display: block;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .all-options .sub-level {
  display: none;
  flex: 1;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .all-options .sub-level.active {
  display: block;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .all-options .sub-level .sub-level-options {
  display: flex;
  padding: 10px;
  x-justify-content: space-around;
  x-align-items: stretch;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .all-options .sub-level .sub-level-options .second-level-option {
  width: 200px;
  flex: 0 0 200px;
  color: #18293E;
  padding: 10px;
  font-size: 13px;
  font-weight: 700;
  cursor: default;
  text-align: left;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .all-options .sub-level .sub-level-options .third-level-option {
  flex: 1;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .all-options .sub-level .sub-level-options .third-level-option:after {
  content: "";
  display: table;
  clear: both;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .all-options .sub-level .sub-level-options .third-level-option a {
  text-decoration: none;
  cursor: pointer;
  display: block;
  float: left;
  color: #797979;
  padding: 10px;
  font-size: 13px;
  font-weight: 300;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .all-options .sub-level .sub-level-options .third-level-option a:hover {
  color: #18293E;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .search-options {
  display: none;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .search-options.active {
  display: block;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .search-options .option-row {
  position: relative;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .search-options .option-row .text {
  padding: 15px 120px 15px 15px;
  display: block;
  text-align: left;
  font-size: 12px;
  color: #222222;
  text-decoration: none;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .search-options .option-row .text-type {
  color: #a9b1be;
  font-size: 12px;
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: -1;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .search-options .option-row:hover, #landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .search-options .option-row.hover {
  background-color: #28f3b2;
  color: #FFFFFF;
}

#landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .search-options .option-row:hover .text,
#landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .search-options .option-row:hover .text-type, #landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .search-options .option-row.hover .text,
#landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .search-options .option-row.hover .text-type {
  color: #FFFFFF;
  z-index: 2;
}

#landing-site-container .is-sticky + #content {
  padding-top: 109px;
}

#landing-site-container #mobile-menu {
  display: none;
}

#landing-site-container:before {
  content: '';
  display: block;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.5s ease-in-out;
}

#landing-site-container.mobile-menu-opened:before {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
}

@media only screen and (min-width: 992px) {
  #landing-site-container header .logo-wrapper a img {
    max-width: 250px;
    width: 100%;
  }
  #landing-site-container header .for-client,
  #landing-site-container header .for-vendor {
    margin: 18px 0 16px 20px;
    padding: 4px 4px;
    font-size: 13px;
    position: relative;
    display: block;
  }
  #landing-site-container header .for-client:hover > span,
  #landing-site-container header .for-vendor:hover > span {
    color: #02cd8d;
  }
  #landing-site-container header .for-client > span,
  #landing-site-container header .for-vendor > span {
    color: rgba(255, 255, 255, 0.8);
    cursor: pointer;
    transition: color .3s;
  }
  #landing-site-container header .for-client > span i.fa,
  #landing-site-container header .for-vendor > span i.fa {
    color: #02cd8d;
    margin-left: 1px;
  }
  #landing-site-container header .for-client ul.dropdown,
  #landing-site-container header .for-vendor ul.dropdown {
    display: none;
    opacity: 0;
    transition: opacity .4s;
    position: absolute;
    top: 100%;
    left: 50%;
    width: 180px;
    transform: translateX(-50%);
    list-style: none;
    padding: 8px 0;
    margin: 0;
    background-color: #FFFFFF;
  }
  #landing-site-container header .for-client ul.dropdown li a,
  #landing-site-container header .for-vendor ul.dropdown li a {
    color: #585858;
    display: block;
    padding: 3px 0;
    text-align: center;
    text-decoration: none;
    transition: color .3s;
  }
  #landing-site-container header .for-client ul.dropdown li a:hover,
  #landing-site-container header .for-vendor ul.dropdown li a:hover {
    color: #02cd8d;
  }
  #landing-site-container header .for-client:hover ul.dropdown,
  #landing-site-container header .for-vendor:hover ul.dropdown {
    display: block;
    opacity: 1;
  }
  #landing-site-container header .for-language {
    margin: 16px 10px 16px 0;
    padding: 4px 4px;
    font-size: 13px;
    position: relative;
    display: block;
  }
  #landing-site-container header .for-language:hover > span {
    color: #02cd8d;
  }
  #landing-site-container header .for-language > span {
    color: rgba(255, 255, 255, 0.8);
    cursor: pointer;
    transition: color .3s;
  }
  #landing-site-container header .for-language > span i.fa {
    color: #02cd8d;
    margin-left: 5px;
  }
  #landing-site-container header .for-language ul.dropdown {
    display: none;
    opacity: 0;
    transition: opacity .4s;
    position: absolute;
    top: 100%;
    left: 50%;
    width: 180px;
    transform: translateX(-50%);
    list-style: none;
    padding: 8px 0;
    margin: 0;
    background-color: #FFFFFF;
  }
  #landing-site-container header .for-language ul.dropdown li a {
    color: #585858;
    display: block;
    padding: 3px 0;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    transition: color .3s;
  }
  #landing-site-container header .for-language ul.dropdown li a:hover {
    color: #02cd8d;
  }
  #landing-site-container header .for-language:hover ul.dropdown {
    display: block;
    opacity: 1;
  }
  #landing-site-container header .link {
    margin: 16px 10px 16px 0;
    display: block;
  }
  #landing-site-container header .link a {
    padding: 4px 4px;
    font-size: 13px;
    color: rgba(255, 255, 255, 0.8);
    cursor: pointer;
    display: block;
    text-decoration: none;
    transition: color .3s;
  }
  #landing-site-container header .link a:hover {
    color: #02cd8d;
  }
  #landing-site-container header .link-with-border {
    margin: 16px 10px 16px 0;
    display: block;
  }
  #landing-site-container header .link-with-border.back_dashboard {
    margin: 8px 10px;
  }
  #landing-site-container header .link-with-border a {
    border-radius: 3px;
    border: 1px solid rgba(255, 255, 255, 0.8);
    padding: 4px 8px;
    font-size: 13px;
    color: rgba(255, 255, 255, 0.8);
    cursor: pointer;
    display: block;
    text-decoration: none;
    transition: color .3s, border .3s;
  }
  #landing-site-container header .link-with-border a:hover {
    color: #02cd8d;
    border: 1px solid #02cd8d;
  }
  #landing-site-container header .quick-wrapper {
    display: block;
    color: #02cd8d;
    font-family: "Roboto", sans-serif;
    font-size: 13px;
    margin-top: 15px;
  }
  #landing-site-container header .quick-wrapper .link {
    margin: 0;
  }
  #landing-site-container header .quick-wrapper > ul {
    display: block;
    list-style: none;
    padding: 0;
    margin-left: -5px;
    margin-right: -5px;
  }
  #landing-site-container header .quick-wrapper > ul > li {
    display: block;
    float: left;
    padding-left: 5px;
    padding-right: 5px;
  }
  #landing-site-container header .quick-wrapper > ul > li > a {
    padding: 4px 8px;
    font-size: 13px;
    display: block;
    text-decoration: none;
    color: rgba(255, 255, 255, 0.8);
    font-weight: bold;
    transition: color .3s, border .3s;
  }
  #landing-site-container header .quick-wrapper > ul > li > a:not(.login) {
    color: #333;
    font-weight: bold;
    border-radius: 3px;
    border: 1px solid #333;
  }
  #landing-site-container header .quick-wrapper > ul > li > a.login {
    padding: 4px;
  }
  #landing-site-container header .quick-wrapper > ul > li > a:hover {
    color: #02cd8d;
  }
  #landing-site-container header .quick-wrapper > ul > li > a:hover:not(.login) {
    border: 1px solid #02cd8d;
  }
  #landing-site-container header .quick-wrapper > ul > li > a.link-box {
    border: 1px solid #fff;
    color: #fff;
    font-size: 12px;
    padding: 12px 9px;
    line-height: 1;
    border-radius: 2px;
  }
  #landing-site-container header .quick-wrapper > ul > li > a.link-box:hover {
    color: #02cd8d;
    border: 1px solid #02cd8d;
  }
  #landing-site-container header .quick-wrapper > ul > li > .languages {
    padding-left: 10px;
    display: block;
    text-decoration: none;
    color: rgba(255, 255, 255, 0.8);
    font-weight: bold;
    position: relative;
  }
  #landing-site-container header .quick-wrapper > ul > li > .languages > span {
    transition: color .3s;
    outline: none;
    cursor: pointer;
    display: block;
  }
  #landing-site-container header .quick-wrapper > ul > li > .languages > span > .fa {
    color: #02cd8d;
    display: inline-block;
    margin-left: 8px;
    font-size: 15px;
    transition: transform .3s;
  }
  #landing-site-container header .quick-wrapper > ul > li > .languages > .languages-dropdown {
    display: none;
    visibility: hidden;
    opacity: 0;
    font-size: 13px;
    background-color: #fff;
    zoom: 1;
    z-index: 7000;
    -webkit-font-smoothing: antialiased;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    width: 180px;
    padding: 8px 0;
    border-radius: 8px;
    box-shadow: 0 0 0 1px #e0e0e0;
  }
  #landing-site-container header .quick-wrapper > ul > li > .languages > .languages-dropdown ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  #landing-site-container header .quick-wrapper > ul > li > .languages > .languages-dropdown ul li {
    display: block;
  }
  #landing-site-container header .quick-wrapper > ul > li > .languages > .languages-dropdown ul li a {
    color: #333;
    font-weight: bold;
    display: block;
    padding: 3px 0;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    transition: color .3s;
  }
  #landing-site-container header .quick-wrapper > ul > li > .languages > .languages-dropdown ul li a:hover {
    text-decoration: none;
    color: #02cd8d;
  }
  #landing-site-container header .quick-wrapper > ul > li > .languages:hover > span {
    color: #02cd8d;
  }
  #landing-site-container header .quick-wrapper > ul > li > .languages:hover > .languages-dropdown {
    display: block;
    visibility: visible;
    opacity: 1;
  }
  #landing-site-container header .quick-wrapper > ul > li.user {
    cursor: default;
    position: relative;
  }
  #landing-site-container header .quick-wrapper > ul > li.user > a {
    border: 1px solid transparent;
    line-height: 1;
    color: rgba(255, 255, 255, 0.8);
    font-size: 12px;
    cursor: pointer;
  }
  #landing-site-container header .quick-wrapper > ul > li.user > a img {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
    margin-top: -5px;
  }
  #landing-site-container header .quick-wrapper > ul > li.user > a:hover {
    border: 1px solid transparent;
  }
  #landing-site-container header .quick-wrapper > ul > li.user .dropdown {
    z-index: 3;
    width: 300px;
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #FFFFFF;
    border-radius: 8px;
    font-size: 13px;
    box-shadow: 0 0 0 1px #e0e0e0;
  }
  #landing-site-container header .quick-wrapper > ul > li.user .dropdown .dropdown-header {
    padding: 20px;
  }
  #landing-site-container header .quick-wrapper > ul > li.user .dropdown .dropdown-header .label {
    font-size: 12px;
    display: block;
    font-weight: normal;
    margin-bottom: 10px;
    color: #333;
    text-align: left;
    padding: 0;
  }
  #landing-site-container header .quick-wrapper > ul > li.user .dropdown .dropdown-header .tab-header {
    border-radius: 3px;
  }
  #landing-site-container header .quick-wrapper > ul > li.user .dropdown .dropdown-header .tab-header .link {
    cursor: pointer;
    font-size: 14px;
    border: 1px solid #989898;
    float: left;
    color: #333;
    font-weight: normal;
    width: 50%;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
  }
  #landing-site-container header .quick-wrapper > ul > li.user .dropdown .dropdown-header .tab-header .link:not(:first-child) {
    border-left: none;
  }
  #landing-site-container header .quick-wrapper > ul > li.user .dropdown .dropdown-header .tab-header .link.active {
    background-color: #02cd8d;
    color: #FFFFFF;
  }
  #landing-site-container header .quick-wrapper > ul > li.user .dropdown .dropdown-header .tab-header:after {
    content: '';
    display: table;
    clear: both;
  }
  #landing-site-container header .quick-wrapper > ul > li.user .dropdown .dropdown-body {
    display: none;
  }
  #landing-site-container header .quick-wrapper > ul > li.user .dropdown .dropdown-body .links .link {
    display: block;
    color: #585858;
    text-align: left;
    text-decoration: none;
    padding: 5px 20px;
    border-right: 3px solid transparent;
    transition: border-right .3s;
    cursor: pointer;
  }
  #landing-site-container header .quick-wrapper > ul > li.user .dropdown .dropdown-body .links .link:hover {
    border-right: 3px solid #02cd8d;
  }
  #landing-site-container header .quick-wrapper > ul > li.user .dropdown .dropdown-body .links .link img {
    display: inline-block;
    margin-right: 10px;
  }
  #landing-site-container header .quick-wrapper > ul > li.user .dropdown .dropdown-body .links + .divider {
    margin-top: 5px;
  }
  #landing-site-container header .quick-wrapper > ul > li.user .dropdown .dropdown-body.active {
    display: block;
  }
  #landing-site-container header .quick-wrapper > ul > li.user .dropdown .dropdown-body .divider {
    width: 100%;
    height: 1px;
    background-color: #989898;
  }
  #landing-site-container header .quick-wrapper > ul > li.user .dropdown .dropdown-body .divider + .links {
    margin-top: 5px;
  }
  #landing-site-container header .quick-wrapper > ul > li.user:hover .dropdown {
    display: block;
  }
}

@media only screen and (max-width: 991px) {
  #landing-site-container header .logo-wrapper a {
    display: block;
  }
  #landing-site-container header .logo-wrapper a img {
    max-height: 50px;
  }
  #landing-site-container #search-wrapper form {
    width: 90%;
  }
  #landing-site-container #content #supporting-organization .cyberport-logo {
    width: 100%;
  }
  #landing-site-container #content #portfolio-references .section-body .works-listing-results .portfolio-wrapper {
    width: 33.333333%;
  }
  #landing-site-container #content #leading-service-provider .row {
    padding-left: 20px;
    padding-right: 20px;
  }
  #landing-site-container #content #leading-service-provider #leading-service-provider-wrapper .description {
    padding-left: 10px;
  }
  #landing-site-container #content #supporting-organization .cyberport-logo img {
    width: 250px;
  }
  #landing-site-container #content #supporting-organization .description {
    width: 100%;
  }
  #landing-site-container #content #video-backdrop iframe {
    position: absolute;
    width: 600px;
    height: 300px;
  }
  #landing-site-container #bottom-search-wrapper form {
    width: 90%;
  }
}

@media only screen and (max-width: 767px) {
  #landing-site-container header .logo-wrapper {
    float: none !important;
    width: 49px;
    margin: 0 auto;
  }
  #landing-site-container header .logo-wrapper a img {
    max-height: 35px;
  }
  #landing-site-container header .mobile-menu-wrapper {
    float: none !important;
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translate(0, -50%);
    margin-top: 10px;
  }
  #landing-site-container header .mobile-menu-wrapper .material-icons {
    font-size: 25px;
    line-height: 35px;
    height: 35px;
  }
  #landing-site-container #search-wrapper {
    min-height: 700px;
    height: 100vh;
  }
  #landing-site-container #search-wrapper form {
    top: 100px;
  }
  #landing-site-container #search-wrapper form .legend {
    font-size: 20px;
    margin-bottom: 12px;
  }
  #landing-site-container #search-wrapper form .description {
    margin-bottom: 30px;
    font-size: 14px;
    white-space: normal;
  }
  #landing-site-container #search-wrapper form .form-row .col-service,
  #landing-site-container #search-wrapper form .form-row .col-location,
  #landing-site-container #search-wrapper form .form-row .col-button {
    display: block;
    width: 100%;
  }
  #landing-site-container #search-wrapper form .form-row .col-location {
    border-left: none;
    border-top: 1px solid #808080;
  }
  #landing-site-container #search-wrapper form .form-row .col-button {
    border-left: none;
    border-top: 1px solid #808080;
  }
  #landing-site-container #search-wrapper form .form-row .col-button button {
    padding: 20px 0;
    font-size: 18px;
    color: #02cd8d;
    position: static;
  }
  #landing-site-container #search-wrapper form .form-row .col-button button .fa {
    display: none;
  }
  #landing-site-container #search-wrapper form .option-box.option-service .option-box-col-right {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(-50%, 50%);
    width: 60px;
  }
  #landing-site-container #search-wrapper form .option-box .col-options .row [class^=col] {
    padding-left: 40px;
  }
  #landing-site-container #search-wrapper form .form-type-row .types .type {
    padding-left: 60px;
    padding-right: 10px;
  }
  #landing-site-container #search-wrapper form .form-type-row .types .type:first-child {
    margin-right: 10px;
  }
  #landing-site-container #search-wrapper form .form-type-row .types .type .icon {
    left: 33px;
  }
  #landing-site-container #search-wrapper form .form-type-row .types .type .text {
    font-size: 12px;
  }
  #landing-site-container #search-wrapper form .form-type-row .types .type.type-portfolio {
    padding-left: 70px;
  }
  #landing-site-container #search-wrapper form .form-type-row .types .type.type-portfolio .icon {
    width: 28px;
    left: 34px;
  }
  #landing-site-container #search-wrapper form .form-type-row .types .type:last-child {
    margin-left: 0;
  }
  #landing-site-container #search-wrapper form .form-type-content.form-type-content-service .box-input {
    font-size: 24px;
    padding-right: 55px;
  }
  #landing-site-container #search-wrapper form .form-type-content.form-type-content-service .box-input button {
    padding: 8px;
    width: 20%;
    color: #18d197;
    background-color: transparent;
  }
  #landing-site-container #search-wrapper form .form-type-content.form-type-content-service .box-input button:hover {
    background-color: transparent;
  }
  #landing-site-container #search-wrapper form .form-type-content.form-type-content-service .box-input button .fa {
    color: #18d197;
  }
  #landing-site-container #search-wrapper form .form-type-content.form-type-content-service .box-input input {
    font-size: 14px;
    padding: 15px 5px 10px 10px;
  }
  #landing-site-container #search-wrapper form .form-type-content.form-type-content-service .box-options .all-options .main-level {
    flex: none;
    width: 40%;
  }
  #landing-site-container #search-wrapper form .form-type-content.form-type-content-service .box-options .all-options .main-level .first-level-option.selected:after {
    display: none;
  }
  #landing-site-container #search-wrapper form .form-type-content.form-type-content-service .box-options .all-options .sub-level.active {
    width: 50%;
  }
  #landing-site-container #search-wrapper form .form-type-content.form-type-content-service .box-options .all-options .sub-level .sub-level-options {
    display: initial;
  }
  #landing-site-container #search-wrapper form .form-type-content.form-type-content-service .box-options .all-options .sub-level .sub-level-options .second-level-option {
    width: 100%;
  }
  #landing-site-container #search-wrapper form .form-type-content.form-type-content-service .box-options .responsive-all-options {
    display: none;
  }
  #landing-site-container #search-wrapper form .form-type-content.form-type-content-service .box-options .responsive-all-options.active {
    display: block;
  }
  #landing-site-container #search-wrapper form .form-type-content.form-type-content-service .box-options .responsive-all-options .main-level {
    font-size: 14px;
    color: #18293e;
    font-weight: bold;
    text-align: left;
  }
  #landing-site-container #search-wrapper form .form-type-content.form-type-content-service .box-options .responsive-all-options .main-level .first-level-option {
    background-color: #f5f5f5;
    padding: 20px 0 0 0;
    cursor: pointer;
  }
  #landing-site-container #search-wrapper form .form-type-content.form-type-content-service .box-options .responsive-all-options .main-level .first-level-option .title {
    padding-bottom: 20px;
    padding-left: 10px;
    position: relative;
  }
  #landing-site-container #search-wrapper form .form-type-content.form-type-content-service .box-options .responsive-all-options .main-level .first-level-option .title i.up, #landing-site-container #search-wrapper form .form-type-content.form-type-content-service .box-options .responsive-all-options .main-level .first-level-option .title i.down {
    position: absolute;
    right: 10%;
    top: 0;
  }
  #landing-site-container #search-wrapper form .form-type-content.form-type-content-service .box-options .responsive-all-options .main-level .first-level-option .title i.up {
    display: none;
  }
  #landing-site-container #search-wrapper form .form-type-content.form-type-content-service .box-options .responsive-all-options .main-level .first-level-option .title i.down {
    display: block;
  }
  #landing-site-container #search-wrapper form .form-type-content.form-type-content-service .box-options .responsive-all-options .main-level .first-level-option .third-level-option {
    display: none;
    padding-left: 10px;
    font-size: 14px;
    font-weight: 300;
  }
  #landing-site-container #search-wrapper form .form-type-content.form-type-content-service .box-options .responsive-all-options .main-level .first-level-option .third-level-option.active {
    display: block;
  }
  #landing-site-container #search-wrapper form .form-type-content.form-type-content-service .box-options .responsive-all-options .main-level .first-level-option .third-level-option .third-level-option-items {
    padding-top: 20px;
    padding-left: 10px;
    padding-bottom: 20px;
    border-bottom: 1px solid #dbdbdb;
  }
  #landing-site-container #search-wrapper form .form-type-content.form-type-content-service .box-options .responsive-all-options .main-level .first-level-option .third-level-option .third-level-option-items a {
    color: #18293e;
    text-decoration: none;
  }
  #landing-site-container #search-wrapper form .form-type-content.form-type-content-service .box-options .responsive-all-options .main-level .first-level-option.active .title i.up {
    display: block;
  }
  #landing-site-container #search-wrapper form .form-type-content.form-type-content-service .box-options .responsive-all-options .main-level .first-level-option.active .title i.down {
    display: none;
  }
  #landing-site-container #search-wrapper form .form-type-content.form-type-content-service .box-options .responsive-all-options .main-level .first-level-option.active .sub-level-options {
    display: block;
  }
  #landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-input {
    font-size: 24px;
    padding-right: 55px;
  }
  #landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-input button {
    padding: 8px;
    width: 20%;
    color: #18d197;
    background-color: transparent;
  }
  #landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-input button:hover {
    background-color: transparent;
  }
  #landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-input button .fa {
    color: #18d197;
  }
  #landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-input input {
    font-size: 14px;
    padding: 15px 5px 10px 10px;
  }
  #landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-options .all-options .main-level {
    flex: none;
    width: 40%;
  }
  #landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-options .all-options .main-level .first-level-option.selected:after {
    display: none;
  }
  #landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-options .all-options .sub-level.active {
    width: 50%;
  }
  #landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-options .all-options .sub-level .sub-level-options {
    display: initial;
  }
  #landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-options .all-options .sub-level .sub-level-options .second-level-option {
    width: 100%;
  }
  #landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options {
    display: none;
  }
  #landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options.active {
    display: block;
  }
  #landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options .main-level {
    font-size: 14px;
    color: #18293e;
    font-weight: bold;
    text-align: left;
  }
  #landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options .main-level .first-level-option {
    background-color: #f5f5f5;
    padding: 20px 0 0 0;
    cursor: pointer;
  }
  #landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options .main-level .first-level-option .title {
    padding-bottom: 20px;
    padding-left: 10px;
    position: relative;
  }
  #landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options .main-level .first-level-option .title i.up, #landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options .main-level .first-level-option .title i.down {
    position: absolute;
    right: 10%;
    top: 0;
  }
  #landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options .main-level .first-level-option .title i.up {
    display: none;
  }
  #landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options .main-level .first-level-option .title i.down {
    display: block;
  }
  #landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options .main-level .first-level-option .sub-level-options {
    display: none;
    padding-left: 20px;
    background-color: #ffffff;
  }
  #landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options .main-level .first-level-option .sub-level-options.active .third-level-option {
    display: block;
  }
  #landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options .main-level .first-level-option .sub-level-options .second-level-option {
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #dbdbdb;
    cursor: pointer;
    position: relative;
  }
  #landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options .main-level .first-level-option .sub-level-options .second-level-option i.up, #landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options .main-level .first-level-option .sub-level-options .second-level-option i.down {
    position: absolute;
    right: 10%;
    top: 50%;
    transform: translateY(-50%);
  }
  #landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options .main-level .first-level-option .sub-level-options .second-level-option i.up {
    display: none;
  }
  #landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options .main-level .first-level-option .sub-level-options .second-level-option i.down {
    display: block;
  }
  #landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options .main-level .first-level-option .sub-level-options .second-level-option.active i.up {
    display: none;
  }
  #landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options .main-level .first-level-option .sub-level-options .second-level-option.active i.down {
    display: block;
  }
  #landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options .main-level .first-level-option .sub-level-options .third-level-option {
    display: none;
    padding-left: 10px;
    font-size: 14px;
    font-weight: 300;
  }
  #landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options .main-level .first-level-option .sub-level-options .third-level-option .third-level-option-items {
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #dbdbdb;
  }
  #landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options .main-level .first-level-option .sub-level-options .third-level-option .third-level-option-items a {
    color: #18293e;
    text-decoration: none;
  }
  #landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options .main-level .first-level-option.active .title i.up {
    display: block;
  }
  #landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options .main-level .first-level-option.active .title i.down {
    display: none;
  }
  #landing-site-container #search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options .main-level .first-level-option.active .sub-level-options {
    display: block;
  }
  #landing-site-container #content #video-backdrop {
    min-height: 200px;
  }
  #landing-site-container #content #video-backdrop iframe {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  #landing-site-container #content #portfolio-references .section-body .portfolio-service-categories {
    background-color: #e5edf2;
  }
  #landing-site-container #content #portfolio-references .section-body .portfolio-service-categories ul {
    width: 100%;
  }
  #landing-site-container #content #portfolio-references .section-body .portfolio-service-categories ul li {
    position: relative;
  }
  #landing-site-container #content #portfolio-references .section-body .portfolio-service-categories ul li.open:after {
    content: '';
    position: absolute;
    display: block;
    opacity: 2000;
    top: 100%;
    left: 50%;
    width: 0;
    height: 0;
    transform: translateX(-50%);
    border-top: 30px solid #e5edf2;
    border-left: 30px solid transparent;
    border-right: 30px solid transparent;
  }
  #landing-site-container #content #portfolio-references .section-body .portfolio-services {
    padding: 10px 0;
  }
  #landing-site-container #content #portfolio-references .section-body .portfolio-services .portfolio-service-row {
    padding: 50px 0 10px 0;
  }
  #landing-site-container #content #portfolio-references .section-body .portfolio-services .portfolio-service-row .portfolio-service {
    margin-bottom: 5px;
    font-size: 12px;
  }
  #landing-site-container #content #portfolio-references .section-body .works-listing-results .portfolio-wrapper {
    width: 100%;
    padding: 15px 20px 20px;
    border-bottom: 1px solid #dbdbdb;
  }
  #landing-site-container #content #portfolio-references .section-body .works-listing-results .portfolio-wrapper .portfolio {
    box-shadow: none;
  }
  #landing-site-container #content #portfolio-references .section-body .works-listing-results .portfolio-wrapper .portfolio .portfolio-wrapper-heading {
    padding-bottom: 50%;
  }
  #landing-site-container #content #portfolio-references .section-body .works-listing-results .portfolio-wrapper .portfolio .portfolio-wrapper-heading .portfolio-wrapper-heading-title {
    width: 65%;
    font-size: 14px;
    line-height: 14px;
  }
  #landing-site-container #content #portfolio-references .section-body .works-listing-results .portfolio-wrapper .portfolio .lazy {
    width: 45%;
    display: block;
    float: left;
  }
  #landing-site-container #content #portfolio-references .section-body .works-listing-results .portfolio-wrapper .portfolio .lazy .location {
    padding-left: 5px;
    padding-right: 0px;
  }
  #landing-site-container #content #portfolio-references .section-body .works-listing-results .portfolio-wrapper .portfolio .lazy .location .pull-left {
    width: 65%;
  }
  #landing-site-container #content #portfolio-references .section-body .works-listing-results .portfolio-wrapper .portfolio .lazy .location .fa {
    padding-top: 0;
  }
  #landing-site-container #content #portfolio-references .section-body .works-listing-results .portfolio-wrapper .portfolio .lazy .location .fa.fa-map-marker {
    display: none;
  }
  #landing-site-container #content #portfolio-references .section-body .works-listing-results .portfolio-wrapper .portfolio .lazy .location .fa.fa-youtube-play {
    padding-right: 5px;
  }
  #landing-site-container #content #portfolio-references .section-body .works-listing-results .portfolio-wrapper .portfolio .lazy .location span {
    padding-left: 0px;
    font-size: 12px;
  }
  #landing-site-container #content #portfolio-references .section-body .works-listing-results .portfolio-wrapper .portfolio .bookmark .feature {
    right: 3%;
  }
  #landing-site-container #content #portfolio-references .section-body .works-listing-results .portfolio-wrapper .portfolio .bookmark .feature img {
    width: 20px;
  }
  #landing-site-container #content #portfolio-references .section-body .works-listing-results .portfolio-wrapper .portfolio .content {
    width: 55%;
    display: block;
    float: left;
    font-size: 12px;
    padding: 0 0 0 10px;
  }
  #landing-site-container #content #portfolio-references .section-body .works-listing-results .portfolio-wrapper .portfolio .content .title {
    margin-top: 0;
    font-size: 12px;
    line-height: 1.2;
  }
  #landing-site-container #content #portfolio-references .section-body .works-listing-results .portfolio-wrapper .portfolio .content .details .item-client-name {
    font-size: 12px;
    line-height: 1.2;
  }
  #landing-site-container #content #portfolio-references .section-body .works-listing-results .portfolio-wrapper .portfolio .content .details .tags {
    font-size: 12px;
    line-height: 1.2;
  }
  #landing-site-container #content #portfolio-references .section-body .works-listing-results .portfolio-wrapper .portfolio .footer {
    padding: 10px 50px 10px 0px;
    background-color: initial;
    display: block;
    float: left;
    width: 100%;
  }
  #landing-site-container #content #portfolio-references .section-body .link {
    padding-top: 10px;
  }
  #landing-site-container #content #portfolio-references .section-body .link a {
    font-size: 14px;
  }
  #landing-site-container #content #how-it-works {
    padding: 30px 0;
  }
  #landing-site-container #content #how-it-works .container {
    padding-left: 0px;
    padding-right: 0px;
  }
  #landing-site-container #content #how-it-works .title {
    font-size: 22px;
    padding-bottom: 12px;
    margin-bottom: 30px;
  }
  #landing-site-container #content #how-it-works .title:after {
    height: 2px;
  }
  #landing-site-container #content #how-it-works .works-row {
    margin-bottom: 10px;
  }
  #landing-site-container #content #how-it-works .works-row [class^=col] .image-wrapper {
    height: 60px;
    margin-bottom: 15px;
  }
  #landing-site-container #content #how-it-works .works-row [class^=col] .legend {
    font-size: 18px;
    margin-bottom: 10px;
    line-height: 1;
  }
  #landing-site-container #content #how-it-works .works-row [class^=col] .description {
    font-size: 14px;
    padding-bottom: 0;
    line-height: 1.5;
    margin-bottom: 30px;
  }
  #landing-site-container #content #how-it-works .link a {
    padding: 10px 28px;
    font-size: 12px;
  }
  #landing-site-container #content #strengths {
    padding: 30px 0;
  }
  #landing-site-container #content #strengths .container {
    padding-left: 0px;
    padding-right: 0px;
  }
  #landing-site-container #content #strengths .title {
    font-size: 22px;
    padding-bottom: 12px;
    margin-bottom: 30px;
  }
  #landing-site-container #content #strengths .title:after {
    height: 2px;
  }
  #landing-site-container #content #strengths .strengths-row {
    margin-bottom: 10px;
  }
  #landing-site-container #content #strengths .strengths-row [class^=col] .image-wrapper {
    height: 60px;
    margin-bottom: 15px;
  }
  #landing-site-container #content #strengths .strengths-row [class^=col] .legend {
    font-size: 20px;
    margin-bottom: 10px;
    line-height: 1;
  }
  #landing-site-container #content #strengths .strengths-row [class^=col] .description {
    font-size: 14px;
    padding-bottom: 0;
    line-height: 1.5;
    margin-bottom: 30px;
  }
  #landing-site-container #content #strengths .link a {
    padding: 10px 28px;
    font-size: 12px;
  }
  #landing-site-container #content #leading-service-provider {
    padding: 30px 0 0;
    background-position: 68%;
  }
  #landing-site-container #content #leading-service-provider .title {
    font-size: 22px;
    padding-bottom: 12px;
    margin-bottom: 30px;
  }
  #landing-site-container #content #leading-service-provider .title:after {
    height: 2px;
  }
  #landing-site-container #content #leading-service-provider #leading-service-provider-wrapper .row {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
  #landing-site-container #content #leading-service-provider #leading-service-provider-wrapper .row .col-sm-4.col-sm-pull-8 {
    padding-left: 0px;
    padding-right: 0px;
  }
  #landing-site-container #content #leading-service-provider #leading-service-provider-wrapper .col-sm-pull-6 {
    padding-left: 0px;
    padding-right: 0px;
  }
  #landing-site-container #content #leading-service-provider #leading-service-provider-wrapper .green-bg {
    margin-bottom: 50px;
    background-color: #02cd8d;
    padding: 20px;
    border-radius: 3px;
  }
  #landing-site-container #content #leading-service-provider #leading-service-provider-wrapper .green-bg .legend {
    margin-bottom: 15px;
  }
  #landing-site-container #content #leading-service-provider #leading-service-provider-wrapper .green-bg .links {
    margin-top: 15px;
  }
  #landing-site-container #content #leading-service-provider #leading-service-provider-wrapper img {
    padding-left: 0px;
  }
  #landing-site-container #content #leading-service-provider #leading-service-provider-wrapper .description {
    margin-top: -85px;
    padding: 0 0px 20px 70px;
    font-size: 16px;
    line-height: 20px;
  }
  #landing-site-container #content #leading-service-provider #leading-service-provider-wrapper .from {
    padding: 0 0px 20px 70px;
  }
  #landing-site-container #content #top-clients {
    padding: 40px 0;
  }
  #landing-site-container #content #top-clients .title {
    font-size: 22px;
    padding-bottom: 12px;
    margin-bottom: 30px;
  }
  #landing-site-container #content #top-clients .clients-logo .logos [class^=col-] {
    float: left;
    width: 33.333333%;
  }
  #landing-site-container #content #top-clients .clients-logo .logos [class^=col-] .logo {
    padding: 0px;
  }
  #landing-site-container #content #supporting-organization {
    padding: 50px 0;
  }
  #landing-site-container #content #supporting-organization .title {
    font-size: 20px;
  }
  #landing-site-container #content #supporting-organization .cyberport-logo {
    width: 100%;
  }
  #landing-site-container #content #supporting-organization .content span {
    font-size: 18px;
  }
  #landing-site-container #content #supporting-organization .description span {
    display: block;
  }
  #landing-site-container #content #recommended-by-clients {
    padding: 60px 0 85px 0;
  }
  #landing-site-container #content #recommended-by-clients .title {
    margin-bottom: 30px;
  }
  #landing-site-container #content #recommended-by-clients .slider .item {
    padding-bottom: 35px;
  }
  #landing-site-container #content #recommended-by-clients .slider .item .col-sm-6.about-image {
    padding-left: 0;
    padding-right: 0;
  }
  #landing-site-container #content #recommended-by-clients .slider .item .image {
    height: 200px;
    margin-bottom: 20px;
  }
  #landing-site-container #content #recommended-by-clients .slider .item .description {
    padding-top: 0px;
  }
  #landing-site-container #content #recommended-by-clients .link a {
    font-size: 14px;
  }
  #landing-site-container #content #bottom-search-wrapper {
    min-height: 600px;
    height: 120vh;
  }
  #landing-site-container #content #bottom-search-wrapper form {
    width: 92%;
  }
  #landing-site-container #content #bottom-search-wrapper form .legend {
    font-size: 20px;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
  #landing-site-container #content #bottom-search-wrapper form .description {
    margin-bottom: 30px;
    font-size: 14px;
    white-space: normal;
  }
  #landing-site-container #content #bottom-search-wrapper form .form-row .col-service,
  #landing-site-container #content #bottom-search-wrapper form .form-row .col-location,
  #landing-site-container #content #bottom-search-wrapper form .form-row .col-button {
    display: block;
    width: 100%;
  }
  #landing-site-container #content #bottom-search-wrapper form .form-row .col-location {
    border-left: none;
    border-top: 1px solid #808080;
  }
  #landing-site-container #content #bottom-search-wrapper form .form-row .col-button {
    border-left: none;
    border-top: 1px solid #808080;
  }
  #landing-site-container #content #bottom-search-wrapper form .form-row .col-button button {
    padding: 20px 0;
    font-size: 18px;
    color: #02cd8d;
    position: static;
  }
  #landing-site-container #content #bottom-search-wrapper form .form-row .col-button button .fa {
    display: none;
  }
  #landing-site-container #content #bottom-search-wrapper form .option-box.option-service .option-box-col-right {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(-50%, 50%);
    width: 60px;
  }
  #landing-site-container #content #bottom-search-wrapper form .option-box .col-options .row [class^=col] {
    padding-left: 40px;
  }
  #landing-site-container #content #bottom-search-wrapper form .form-type-row .types .type {
    padding-left: 60px;
    padding-right: 10px;
  }
  #landing-site-container #content #bottom-search-wrapper form .form-type-row .types .type:first-child {
    margin-right: 10px;
  }
  #landing-site-container #content #bottom-search-wrapper form .form-type-row .types .type .icon {
    left: 33px;
  }
  #landing-site-container #content #bottom-search-wrapper form .form-type-row .types .type .text {
    font-size: 12px;
  }
  #landing-site-container #content #bottom-search-wrapper form .form-type-row .types .type.type-portfolio {
    padding-left: 70px;
  }
  #landing-site-container #content #bottom-search-wrapper form .form-type-row .types .type.type-portfolio .icon {
    width: 28px;
    left: 34px;
  }
  #landing-site-container #content #bottom-search-wrapper form .form-type-row .types .type:last-child {
    margin-left: 0;
  }
  #landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-input {
    font-size: 24px;
    padding-right: 55px;
  }
  #landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-input button {
    padding: 8px;
    width: 20%;
    color: #18d197;
    background-color: transparent;
  }
  #landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-input button:hover {
    background-color: transparent;
  }
  #landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-input button .fa {
    color: #18d197;
  }
  #landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-input input {
    font-size: 14px;
    padding: 15px 5px 10px 10px;
  }
  #landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .all-options .main-level {
    flex: none;
    width: 40%;
  }
  #landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .all-options .main-level .first-level-option.selected:after {
    display: none;
  }
  #landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .all-options .sub-level.active {
    width: 50%;
  }
  #landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .all-options .sub-level .sub-level-options {
    display: initial;
  }
  #landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .all-options .sub-level .sub-level-options .second-level-option {
    width: 100%;
  }
  #landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .responsive-all-options {
    display: none;
  }
  #landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .responsive-all-options.active {
    display: block;
  }
  #landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .responsive-all-options .main-level {
    font-size: 14px;
    color: #18293e;
    font-weight: bold;
    text-align: left;
  }
  #landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .responsive-all-options .main-level .first-level-option {
    background-color: #f5f5f5;
    padding: 20px 0 0 0;
    cursor: pointer;
  }
  #landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .responsive-all-options .main-level .first-level-option .title {
    padding-bottom: 20px;
    padding-left: 10px;
    position: relative;
  }
  #landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .responsive-all-options .main-level .first-level-option .title i.up, #landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .responsive-all-options .main-level .first-level-option .title i.down {
    position: absolute;
    right: 10%;
    top: 0;
  }
  #landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .responsive-all-options .main-level .first-level-option .title i.up {
    display: none;
  }
  #landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .responsive-all-options .main-level .first-level-option .title i.down {
    display: block;
  }
  #landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .responsive-all-options .main-level .first-level-option .third-level-option {
    display: none;
    padding-left: 10px;
    font-size: 14px;
    font-weight: 300;
  }
  #landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .responsive-all-options .main-level .first-level-option .third-level-option.active {
    display: block;
  }
  #landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .responsive-all-options .main-level .first-level-option .third-level-option .third-level-option-items {
    padding-top: 20px;
    padding-left: 10px;
    padding-bottom: 20px;
    border-bottom: 1px solid #dbdbdb;
  }
  #landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .responsive-all-options .main-level .first-level-option .third-level-option .third-level-option-items a {
    color: #18293e;
    text-decoration: none;
  }
  #landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .responsive-all-options .main-level .first-level-option.active .title i.up {
    display: block;
  }
  #landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .responsive-all-options .main-level .first-level-option.active .title i.down {
    display: none;
  }
  #landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .responsive-all-options .main-level .first-level-option.active .sub-level-options {
    display: block;
  }
  #landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-input {
    font-size: 24px;
    padding-right: 55px;
  }
  #landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-input button {
    padding: 8px;
    width: 20%;
    color: #18d197;
    background-color: transparent;
  }
  #landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-input button:hover {
    background-color: transparent;
  }
  #landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-input button .fa {
    color: #18d197;
  }
  #landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-input input {
    font-size: 14px;
    padding: 15px 5px 10px 10px;
  }
  #landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .all-options .main-level {
    flex: none;
    width: 40%;
  }
  #landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .all-options .main-level .first-level-option.selected:after {
    display: none;
  }
  #landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .all-options .sub-level.active {
    width: 50%;
  }
  #landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .all-options .sub-level .sub-level-options {
    display: initial;
  }
  #landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .all-options .sub-level .sub-level-options .second-level-option {
    width: 100%;
  }
  #landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options {
    display: none;
  }
  #landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options.active {
    display: block;
  }
  #landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options .main-level {
    font-size: 14px;
    color: #18293e;
    font-weight: bold;
    text-align: left;
  }
  #landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options .main-level .first-level-option {
    background-color: #f5f5f5;
    padding: 20px 0 0 0;
    cursor: pointer;
  }
  #landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options .main-level .first-level-option .title {
    padding-bottom: 20px;
    padding-left: 10px;
    position: relative;
  }
  #landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options .main-level .first-level-option .title i.up, #landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options .main-level .first-level-option .title i.down {
    position: absolute;
    right: 10%;
    top: 0;
  }
  #landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options .main-level .first-level-option .title i.up {
    display: none;
  }
  #landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options .main-level .first-level-option .title i.down {
    display: block;
  }
  #landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options .main-level .first-level-option .sub-level-options {
    display: none;
    padding-left: 20px;
    background-color: #ffffff;
  }
  #landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options .main-level .first-level-option .sub-level-options.active .third-level-option {
    display: block;
  }
  #landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options .main-level .first-level-option .sub-level-options .second-level-option {
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #dbdbdb;
    cursor: pointer;
    position: relative;
  }
  #landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options .main-level .first-level-option .sub-level-options .second-level-option i.up, #landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options .main-level .first-level-option .sub-level-options .second-level-option i.down {
    position: absolute;
    right: 10%;
    top: 50%;
    transform: translateY(-50%);
  }
  #landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options .main-level .first-level-option .sub-level-options .second-level-option i.up {
    display: none;
  }
  #landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options .main-level .first-level-option .sub-level-options .second-level-option i.down {
    display: block;
  }
  #landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options .main-level .first-level-option .sub-level-options .second-level-option.active i.up {
    display: none;
  }
  #landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options .main-level .first-level-option .sub-level-options .second-level-option.active i.down {
    display: block;
  }
  #landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options .main-level .first-level-option .sub-level-options .third-level-option {
    display: none;
    padding-left: 10px;
    font-size: 14px;
    font-weight: 300;
  }
  #landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options .main-level .first-level-option .sub-level-options .third-level-option .third-level-option-items {
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #dbdbdb;
  }
  #landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options .main-level .first-level-option .sub-level-options .third-level-option .third-level-option-items a {
    color: #18293e;
    text-decoration: none;
  }
  #landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options .main-level .first-level-option.active .title i.up {
    display: block;
  }
  #landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options .main-level .first-level-option.active .title i.down {
    display: none;
  }
  #landing-site-container #content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options .main-level .first-level-option.active .sub-level-options {
    display: block;
  }
}

body {
  width: 100%;
  height: 100%;
}

#mobile-menu:not(.slideout-menu) {
  display: none;
}

.slideout-menu {
  background-color: #fff;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 300px;
  min-height: 100vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  z-index: 0;
  display: none;
}

.slideout-menu-left {
  left: 0;
}

.slideout-menu-right {
  right: 0;
}

.slideout-panel {
  position: relative;
  z-index: 1;
  will-change: transform;
  background-color: #FFF;
  /* A background-color is required */
}

.slideout-open,
.slideout-open body,
.slideout-open .slideout-panel {
  overflow: hidden;
}

.slideout-open .slideout-menu {
  display: block;
}

#mobile-menu .menu-header {
  padding: 40px 0;
  text-align: center;
  border-bottom: 1px solid #e0e0e0;
}

#mobile-menu .menu-header span {
  display: inline-block;
  padding: 10px;
  line-height: 20px;
  font-size: 20px;
  background-color: #02cd8d;
  color: #fff;
}

#mobile-menu .menu-header img {
  max-height: 40px;
}

#mobile-menu .menu-body .link-header {
  text-align: left;
  background-color: #f3f6fb;
  line-height: 48px;
  height: 48px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.9);
  padding: 0 32px;
  display: block;
  position: relative;
}

#mobile-menu .menu-body .link-header .toggle-group {
  cursor: pointer;
  position: absolute;
  right: 70px;
  top: 50%;
  transform: translate(100%, -50%);
}

#mobile-menu .menu-body .link-header.switch {
  background-color: #fff;
}

#mobile-menu .menu-body .link-header.switch a {
  display: block;
  line-height: 48px;
  height: 48px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.9);
  text-decoration: none;
}

#mobile-menu .menu-body .link-header.switch a:hover {
  text-decoration: none;
}

#mobile-menu .menu-body .link-group {
  overflow-y: hidden;
  max-height: 0;
  transition-property: all;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

#mobile-menu .menu-body .link-group.opened.has-1-item {
  max-height: 49px;
}

#mobile-menu .menu-body .link-group.opened.has-2-item {
  max-height: 98px;
}

#mobile-menu .menu-body .link-group.opened.has-3-item {
  max-height: 147px;
}

#mobile-menu .menu-body .link-group.opened.has-4-item {
  max-height: 196px;
}

#mobile-menu .menu-body .link-group.opened.has-5-item {
  max-height: 245px;
}

#mobile-menu .menu-body .link-group.opened.has-6-item {
  max-height: 294px;
}

#mobile-menu .menu-body .link-group.opened.has-7-item {
  max-height: 343px;
}

#mobile-menu .menu-body .link-group.opened.has-8-item {
  max-height: 392px;
}

#mobile-menu .menu-body .link-group.opened.has-9-item {
  max-height: 441px;
}

#mobile-menu .menu-body a.link {
  display: block;
  padding: 0 32px 0 50px;
  line-height: 48px;
  height: 48px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.9);
  text-decoration: none;
  transition: background-color .3s ease;
  background-color: #fff;
  position: relative;
}

#mobile-menu .menu-body a.link img {
  display: inline-block;
  margin-right: 10px;
}

#mobile-menu .menu-body a.link:hover {
  background-color: #f3f6fb;
}

#mobile-menu .menu-body a.link span {
  position: absolute;
  top: 10%;
  right: 40%;
  transform: translate(100%, 30%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: block;
  font-size: 0;
  background-color: #02cd8d;
}

#mobile-menu .menu-body a.link.logout {
  margin-bottom: 100px;
}

#mobile-menu .menu-body a.sub-link {
  display: block;
  padding: 0 32px 0 70px;
  line-height: 48px;
  height: 48px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.9);
  text-decoration: none;
  transition: background-color .3s ease;
  background-color: #fff;
}

#mobile-menu .menu-body a.sub-link img {
  display: inline-block;
  margin-right: 10px;
}

#mobile-menu .menu-body a.sub-link:hover, #mobile-menu .menu-body a.sub-link.active {
  background-color: #f3f6fb;
}

#mobile-menu .menu-body a.sub-link.with-dot {
  position: relative;
}

#mobile-menu .menu-body a.sub-link.client-with-dot {
  position: relative;
}

#mobile-menu .menu-body a.sub-link .notification-dot {
  position: absolute;
  right: 50px;
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-left: 5px;
  text-align: center;
  line-height: 25px;
  font-size: 12px;
  color: #fff;
  background-color: #02cd8d;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
}

#mobile-menu .menu-body a.sub-link .client-notification-dot {
  position: absolute;
  right: 50px;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-left: 5px;
  text-align: center;
  line-height: 20px;
  font-size: 10px;
  color: #fff;
  background-color: #02cd8d;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
}

#mobile-menu .menu-body a.button {
  margin: 16px;
  border-radius: 20px;
  display: block;
  width: 75%;
  padding: 10px 25px;
  background-color: #02cd8d;
  border: 1px solid #02cd8d;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  color: #ffffe7;
  text-decoration: none;
  outline: 0;
  vertical-align: top;
  cursor: pointer;
  text-align: center;
  transition: background-color .3s ease, margin-top .4s ease;
}

#mobile-menu .menu-body a.button:hover {
  background-color: rgba(2, 205, 141, 0.8);
  margin-top: 16px;
}

#mobile-menu .menu-body .divider {
  background-color: #e0e0e0;
  height: 1px;
  display: block;
  width: 100%;
}

.js-izzi-sticky.is-sticky + #banner-wrapper {
  margin-top: 54px;
}

#banner-wrapper {
  height: 400px;
  position: relative;
  z-index: 1;
}

#banner-wrapper .wrapper-body {
  z-index: 2;
  position: absolute;
  width: 100%;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

#banner-wrapper .wrapper-body .wrapper-heading {
  color: #02cd8d;
  margin-bottom: 20px;
  font-size: 42px;
  text-align: center;
}

#banner-wrapper .wrapper-body .wrapper-description {
  font-size: 24px;
  color: rgba(255, 255, 255, 0.85);
  text-align: center;
  margin-bottom: 30px;
}

#banner-wrapper:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000;
  opacity: 0.65;
  z-index: 1;
}

#listing-options-wrapper {
  background-color: #18283e;
  border: 2px solid #ffffff;
  position: relative;
  z-index: 2;
}

#listing-options-wrapper .wrapper-col-left {
  padding-right: 50px;
  padding-top: 50px;
  padding-bottom: 50px;
  font-size: 16px;
  line-height: 1.3;
  position: relative;
  z-index: 3;
  color: #ffffff;
}

#listing-options-wrapper .wrapper-col-left .fields {
  display: block;
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
}

#listing-options-wrapper .wrapper-col-left .fields .selected {
  display: inline-block;
  color: #000;
  font-weight: bold;
  font-size: 20px;
  cursor: pointer;
  white-space: nowrap;
  transition: color .3s;
}

#listing-options-wrapper .wrapper-col-left .fields .selected .fa {
  border-radius: 50%;
  border: 1px solid #27edaf;
  color: #27edaf;
  margin-left: 7px;
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 20px;
  transition: color .3s, border .3s;
  font-size: 20px;
  text-align: center;
}

#listing-options-wrapper .wrapper-col-left .fields .selected .text {
  display: inline-block;
  padding: 10px 0;
  border-bottom: 1px solid #27edaf;
  color: #27edaf;
  transition: border-bottom .3s;
}

#listing-options-wrapper .wrapper-col-left .fields .selected:hover {
  color: #02cd8d;
}

#listing-options-wrapper .wrapper-col-left .fields .selected:hover .fa {
  color: #02cd8d;
  border: 1px solid #02cd8d;
}

#listing-options-wrapper .wrapper-col-left .fields .selected:hover .text {
  border-bottom: 1px solid #02cd8d;
}

#listing-options-wrapper .wrapper-col-left .fields .selected:hover + .options {
  display: block;
}

#listing-options-wrapper .wrapper-col-left .fields > .options {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 2;
}

#listing-options-wrapper .wrapper-col-left .fields > .options.with-parent .parent-wrapper {
  background-color: #FFFFFF;
  text-align: left;
  position: relative;
  width: 250px;
  z-index: 2;
  padding: 15px 10px;
  border-radius: 4px 0 0 4px;
  box-shadow: 0px 1px 6px 0px rgba(1, 1, 1, 0.3);
}

#listing-options-wrapper .wrapper-col-left .fields > .options.with-parent .parent-wrapper .parent {
  cursor: pointer;
  transition: color .3s;
  padding: 5px 10px;
  color: #656565;
  display: block;
  text-decoration: none;
  position: relative;
  font-size: 13px;
}

#listing-options-wrapper .wrapper-col-left .fields > .options.with-parent .parent-wrapper .parent a {
  color: #656565;
  text-decoration: none;
}

#listing-options-wrapper .wrapper-col-left .fields > .options.with-parent .parent-wrapper .parent a:hover {
  text-decoration: none;
}

#listing-options-wrapper .wrapper-col-left .fields > .options.with-parent .parent-wrapper .parent.hover .triangle {
  opacity: 1;
}

#listing-options-wrapper .wrapper-col-left .fields > .options.with-parent .parent-wrapper .parent.hover a {
  color: #02cd8d;
}

#listing-options-wrapper .wrapper-col-left .fields > .options.with-parent .parent-wrapper .parent .triangle {
  opacity: 0;
  left: 100%;
  position: absolute;
  margin-left: 10px;
  top: 50%;
  transform: translate(0, -50%);
  color: #ffffff;
  font-size: 30px;
  z-index: -1;
}

#listing-options-wrapper .wrapper-col-left .fields > .options.with-parent .option-wrapper {
  font-size: 12px;
  text-align: left;
  top: 0px;
  position: absolute;
  left: 250px;
  min-width: 250px;
  background-color: #fafafa;
  padding: 15px 10px 15px 30px;
  border-radius: 0 4px 4px 0;
  box-shadow: 0px 1px 6px 0px rgba(1, 1, 1, 0.3);
}

#listing-options-wrapper .wrapper-col-left .fields > .options.with-parent .option-wrapper .options {
  background-color: #fafafa;
  display: none;
  position: relative;
  padding: 0;
}

#listing-options-wrapper .wrapper-col-left .fields > .options.with-parent .option-wrapper .options.two-column {
  min-width: 500px;
}

#listing-options-wrapper .wrapper-col-left .fields > .options.with-parent .option-wrapper .options .option {
  background-color: #fafafa;
  cursor: pointer;
  color: #656565;
  transition: color .3s;
  padding: 5px 10px;
  display: block;
  margin: 0;
  text-decoration: none;
  white-space: nowrap;
}

#listing-options-wrapper .wrapper-col-left .fields > .options.with-parent .option-wrapper .options .option:hover, #listing-options-wrapper .wrapper-col-left .fields > .options.with-parent .option-wrapper .options .option.hover {
  color: #02cd8d;
  text-decoration: none;
}

#listing-options-wrapper .wrapper-col-left .fields > .options.with-parent .option-wrapper .options.extended {
  display: block;
}

#listing-options-wrapper .wrapper-col-left .fields > .options:not(.with-parent) {
  padding-top: 40px;
}

#listing-options-wrapper .wrapper-col-left .fields > .options:not(.with-parent) .option-wrapper {
  background-color: #FFFFFF;
  min-width: 200px;
  box-shadow: 0px 0px 1px #888888;
  z-index: 2;
  text-align: left;
  padding: 15px 10px;
}

#listing-options-wrapper .wrapper-col-left .fields > .options:not(.with-parent) .option-wrapper .option {
  display: block;
  cursor: pointer;
  transition: color .3s;
  padding: 5px 10px;
  color: #656565;
}

#listing-options-wrapper .wrapper-col-left .fields > .options:not(.with-parent) .option-wrapper .option:hover {
  color: #02cd8d;
  text-decoration: none;
}

#listing-options-wrapper .wrapper-col-left .fields > .options .triangle-top {
  position: absolute;
  top: 3px;
  left: 15px;
  z-index: 3;
}

#listing-options-wrapper .wrapper-col-left .fields > .options:hover {
  display: block;
}

#listing-options-wrapper .wrapper-col-left .fields > .options .range_plugin {
  width: 350px;
}

#listing-options-wrapper .wrapper-col-left .fields.active .selected {
  color: #02cd8d;
}

#listing-options-wrapper .wrapper-col-left .fields.active .selected .fa {
  color: #02cd8d;
  border: 1px solid #02cd8d;
}

#listing-options-wrapper .wrapper-col-left .fields.active .selected .text {
  border-bottom: 1px solid #02cd8d;
}

#listing-options-wrapper .wrapper-col-left .fields.active .options {
  display: block;
}

#listing-options-wrapper .wrapper-col-left .fields.service .option-wrapper {
  height: 238px;
}

#listing-options-wrapper .wrapper-col-left .fields.location .option-wrapper {
  min-height: 108px;
}

#listing-options-wrapper .wrapper-col-right {
  border-left: 2px solid #f0f4f7;
  padding-left: 50px;
  padding-top: 50px;
  padding-bottom: 50px;
  position: relative;
  z-index: 2;
}

#listing-options-wrapper .wrapper-col-right p {
  line-height: 1.3;
  font-size: 16px;
  margin-bottom: 25px;
  color: #ffffff;
}

#listing-options-wrapper .wrapper-col-right p strong {
  margin-bottom: 6px;
  font-size: 15px;
  display: block;
  color: #27edaf;
}

#filter-bar {
  padding-top: 20px;
  background-color: #FFFFFF;
  padding-bottom: 20px;
  position: relative;
  z-index: 2;
}

#filter-bar .fields {
  display: inline-block;
  padding: 0 10px;
  min-width: 200px;
  width: auto;
  position: relative;
}

#filter-bar .fields .selected {
  padding: 10px 10px;
  color: #333333;
  font-size: 20px;
  cursor: pointer;
  white-space: nowrap;
  transition: color .3s;
}

#filter-bar .fields .selected .fa {
  border-radius: 50%;
  border: 1px solid #7c7c7c;
  color: #7c7c7c;
  margin-left: 7px;
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 20px;
  transition: color .3s, border .3s;
}

#filter-bar .fields .selected .value {
  display: inline;
}

#filter-bar .fields > .options {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  padding-top: 40px;
  z-index: 2;
}

#filter-bar .fields > .options.with-parent .parent-wrapper {
  background-color: #FFFFFF;
  text-align: left;
  position: relative;
  width: 250px;
  z-index: 3;
  padding: 15px 10px;
  border-radius: 4px 0 0 4px;
  box-shadow: 0px 1px 6px 0px rgba(1, 1, 1, 0.3);
}

#filter-bar .fields > .options.with-parent .parent-wrapper .parent {
  cursor: pointer;
  transition: color .3s;
  padding: 5px 10px;
  color: #656565;
  display: block;
  text-decoration: none;
  position: relative;
  font-size: 13px;
}

#filter-bar .fields > .options.with-parent .parent-wrapper .parent a {
  color: #656565;
  text-decoration: none;
}

#filter-bar .fields > .options.with-parent .parent-wrapper .parent a:hover {
  text-decoration: none;
}

#filter-bar .fields > .options.with-parent .parent-wrapper .parent.hover .triangle {
  opacity: 1;
}

#filter-bar .fields > .options.with-parent .parent-wrapper .parent.hover a {
  color: #02cd8d;
}

#filter-bar .fields > .options.with-parent .parent-wrapper .parent .triangle {
  opacity: 0;
  left: 100%;
  position: absolute;
  margin-left: 10px;
  top: 50%;
  transform: translate(0, -50%);
  color: #ffffff;
  font-size: 30px;
  z-index: -1;
}

#filter-bar .fields > .options.with-parent .option-wrapper {
  text-align: left;
  top: 40px;
  position: absolute;
  left: 250px;
  min-width: 250px;
  background-color: #fafafa;
  padding: 15px 10px 15px 30px;
  border-radius: 0 4px 4px 0;
  box-shadow: 0px 1px 6px 0px rgba(1, 1, 1, 0.3);
  z-index: 2;
}

#filter-bar .fields > .options.with-parent .option-wrapper .options {
  background-color: #fafafa;
  display: none;
  position: relative;
  padding: 0;
}

#filter-bar .fields > .options.with-parent .option-wrapper .options.two-column {
  min-width: 500px;
}

#filter-bar .fields > .options.with-parent .option-wrapper .options .option {
  background-color: #fafafa;
  cursor: pointer;
  color: #656565;
  transition: color .3s;
  padding: 5px 10px;
  display: block;
  margin: 0;
  text-decoration: none;
  white-space: nowrap;
}

#filter-bar .fields > .options.with-parent .option-wrapper .options .option:hover, #filter-bar .fields > .options.with-parent .option-wrapper .options .option.hover {
  color: #02cd8d;
  text-decoration: none;
}

#filter-bar .fields > .options.with-parent .option-wrapper .options.extended {
  display: block;
}

#filter-bar .fields > .options:not(.with-parent) {
  padding-top: 40px;
}

#filter-bar .fields > .options:not(.with-parent) .option-wrapper {
  background-color: #FFFFFF;
  min-width: 200px;
  box-shadow: 0px 0px 1px #888888;
  z-index: 2;
  text-align: left;
  padding: 15px 10px;
}

#filter-bar .fields > .options:not(.with-parent) .option-wrapper .option {
  display: block;
  cursor: pointer;
  transition: color .3s;
  padding: 5px 10px;
  color: #656565;
}

#filter-bar .fields > .options:not(.with-parent) .option-wrapper .option:hover {
  color: #02cd8d;
  text-decoration: none;
}

#filter-bar .fields > .options .triangle-top {
  position: absolute;
  top: 10px;
  left: 70px;
  z-index: 3;
}

#filter-bar .fields > .options .range_plugin {
  width: 350px;
}

#filter-bar .fields:not(:first-child) {
  border-left: 1px solid #333333;
}

#filter-bar .fields:not(:first-child) .option {
  z-index: 2;
}

#filter-bar .fields:hover .selected {
  color: #02cd8d;
}

#filter-bar .fields:hover .selected .fa {
  color: #02cd8d;
  border: 1px solid #02cd8d;
}

#filter-bar .fields:hover .options {
  display: block;
}

#filter-bar .fields.service .option-wrapper {
  min-height: 282px;
}

#sorting-content {
  background-color: #f0f4f7;
  padding-top: 15px;
}

#sorting-content select {
  float: right;
  margin: 0;
  border: 1px solid #d4f0e3;
  padding: 10px;
  color: #333333;
  display: block;
  width: 200px;
  outline: 0;
  cursor: pointer;
  background-color: #FFFFFF;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, #02cd8d 50%), linear-gradient(135deg, #02cd8d 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px);
  background-size: 5px 5px, 5px 5px;
  background-repeat: no-repeat;
}

#sorting-content select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

#listing-content {
  position: relative;
  z-index: 1;
  background-color: #f0f4f7;
  padding-top: 15px;
  padding-bottom: 15px;
}

#listing-content .container > .row {
  margin-left: -20px;
  margin-right: -20px;
}

#listing-content .container > .row > .col {
  padding-left: 20px;
  padding-right: 20px;
  float: left;
}

#listing-content #col-filter {
  width: 22%;
}

#listing-content #col-filter .box {
  padding: 20px 30px 20px 20px;
  background-color: #FFFFFF;
  border: 1px solid #d8f1e6;
}

#listing-content #col-filter .box .form-legend {
  color: #4b4b4b;
  font-size: 16px;
  line-height: normal;
  font-weight: bold;
  letter-spacing: 1px;
  left: 1px;
}

#listing-content #col-filter .box .divider {
  margin: 10px -30px 10px -20px;
  border-top: 1px solid rgba(200, 200, 200, 0.3);
}

#listing-content #col-filter .box label {
  color: #4b4b4b;
  font-size: 15px;
  line-height: normal;
  font-weight: normal;
  left: 1px;
}

#listing-content #col-filter .box select {
  margin: 10px 0 20px;
  border: 1px solid #d4f0e3;
  padding: 10px;
  color: #333333;
  display: block;
  width: 100%;
  outline: 0;
  cursor: pointer;
  background-color: #FFFFFF;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, #02cd8d 50%), linear-gradient(135deg, #02cd8d 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px);
  background-size: 5px 5px, 5px 5px;
  background-repeat: no-repeat;
}

#listing-content #col-filter .box select.filter_check {
  margin: 10px 0 20px;
  border: 1px solid #d4f0e3;
  padding: 10px;
  color: #333333;
  display: block;
  width: 100%;
  outline: 0;
  cursor: pointer;
  background-color: #FFFFFF;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, #02cd8d 50%), linear-gradient(135deg, #02cd8d 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px);
  background-size: 5px 5px, 5px 5px;
  background-repeat: no-repeat;
}

#listing-content #col-filter .box select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

#listing-content #col-filter .box .checkboxes {
  margin: 10px 0 20px;
}

#listing-content #col-filter .box .checkboxes label {
  display: block;
  margin: 0;
  font-size: 12px;
  cursor: pointer;
}

#listing-content #col-filter .box .checkboxes label:not(:first-child) {
  margin-top: 10px;
}

#listing-content #col-filter .box .checkboxes label span {
  display: inline-block;
  vertical-align: middle;
}

#listing-content #col-filter .box .checkboxes label span.checkbox {
  border: 1px solid #d4f0e3;
  background-color: #d8f1e6;
  border-radius: 2px;
  margin: 0 10px 0 0;
  width: 20px;
  height: 20px;
  position: relative;
  transition: background-color .3s;
}

#listing-content #col-filter .box .checkboxes label span.checkbox input {
  visibility: hidden;
  opacity: 0;
}

#listing-content #col-filter .box .checkboxes label.checked span.checkbox {
  background-color: #02cd8d;
}

#listing-content #col-filter .box .checkboxes label.checked span.checkbox:before {
  top: 50%;
  left: 50%;
  content: '';
  position: absolute;
  height: 6px;
  width: 15px;
  border-left: 2px solid #d8f1e6;
  border-bottom: 2px solid #d8f1e6;
  display: block;
  margin: 0 auto;
  transform: translate(-50%, -55%) rotate(-45deg);
  -webkit-transform: translate(-50%, -55%) rotate(-45deg);
  -moz-transform: translate(-50%, -55%) rotate(-45deg);
  -ms-transform: translate(-50%, -55%) rotate(-45deg);
  -o-transform: translate(-50%, -55%) rotate(-45deg);
}

#listing-content #col-filter .box .checkboxes label:hover:not(.checked) span.checkbox {
  background-color: #d4f0e3;
}

#listing-content #col-filter .box .checkboxes label:hover.checked span.checkbox {
  background-color: #d8f1e6;
}

#listing-content #col-filter .box .checkboxes label:hover.checked span.checkbox:before {
  border-left: 2px solid #02cd8d;
  border-bottom: 2px solid #02cd8d;
}

#listing-content #col-filter .box button {
  background-color: #02cd8d;
  color: #FFFFFF;
  display: block;
  width: 100%;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  font-size: 13px;
  letter-spacing: 1px;
}

#listing-content #col-filter .box + .box {
  margin-top: 20px;
}

#listing-content #col-filter .box.colorful {
  position: relative;
  background-image: linear-gradient(to bottom right, #03cd8e, #7b93ba, #c86dd7);
  padding: 15px;
}

#listing-content #col-filter .box.colorful .bg-white {
  background-color: #FFFFFF;
  padding: 20px;
}

#listing-content #col-filter .box.colorful .bg-white span {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  letter-spacing: 1px;
  color: #02cd8d;
  margin-bottom: 10px;
  display: block;
  font-weight: bold;
}

#listing-content #col-filter .box.colorful .bg-white ul {
  display: block;
  padding: 0;
  margin: 0 0 15px;
  list-style: none;
}

#listing-content #col-filter .box.colorful .bg-white ul li {
  padding-top: 5px;
  padding-bottom: 5px;
  color: #888888;
  font-weight: 400;
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  letter-spacing: 1px;
}

#listing-content #col-filter .box.colorful .bg-white ul li img {
  vertical-align: middle;
  display: inline-block;
  margin-right: 10px;
}

#listing-content #col-result {
  width: 78%;
}

#listing-content #col-result .type-title {
  color: #333333;
  font-size: 18px;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  margin-bottom: 15px;
}

#listing-content #col-result .type-title:not(:first-child) {
  margin-top: 20px;
}

#listing-content #col-result .results .result {
  margin-bottom: 30px;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
}

#listing-content #col-result .results .result .content-header {
  height: 250px;
  background: #333;
  position: relative;
}

#listing-content #col-result .results .result .content-header .header-bar {
  background: rgba(0, 0, 0, 0.5);
  color: #FFFFFF;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 13px 0 13px 105px;
  font-size: 12px;
  z-index: 2;
}

#listing-content #col-result .results .result .content-header .header-bar .header-logo {
  position: absolute;
  left: 20px;
  bottom: 13px;
  width: 75px;
  height: 75px;
  display: block;
  background-color: #FFFFFF;
  padding: 3px;
  border-radius: 50%;
}

#listing-content #col-result .results .result .content-header .header-bar .header-logo .image {
  border-radius: 50%;
  width: 69px;
  height: 69px;
  background-size: cover;
  background-position: center;
}

#listing-content #col-result .results .result .content-header .header-bar .tag {
  display: inline-block;
  min-width: 100px;
}

#listing-content #col-result .results .result .content-header .header-bar .tag:not(:first-child) {
  margin-left: 20px;
}

#listing-content #col-result .results .result .content-header .header-bar .tag .fa, #listing-content #col-result .results .result .content-header .header-bar .tag img {
  display: inline-block;
  margin-right: 10px;
}

#listing-content #col-result .results .result .content-header .header-bar .tag.tag-reviews {
  color: #28f2b2;
  float: right;
  padding-right: 20px;
}

#listing-content #col-result .results .result .content-header .header-bar .green-tags {
  position: absolute;
  bottom: 100%;
  margin-bottom: 10px;
}

#listing-content #col-result .results .result .content-header .header-bar .green-tags .green-tag {
  display: inline-block;
  background-color: #02cd8d;
  color: #FFFFFF;
  font-size: 13px;
  padding: 5px 10px;
  margin-left: 20px;
  position: relative;
  border-radius: 4px 0 0 4px;
}

#listing-content #col-result .results .result .content-header .header-bar .green-tags .green-tag:not(:first-child) {
  margin-left: 30px;
}

#listing-content #col-result .results .result .content-header .header-bar .green-tags .green-tag:before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 15px 0 0;
  border-color: #02cd8d transparent transparent transparent;
  position: absolute;
  left: 100%;
  top: 0;
}

#listing-content #col-result .results .result .content-header .header-bar .green-tags .green-tag:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 0 0 15px;
  border-color: transparent transparent transparent #02cd8d;
  position: absolute;
  left: 100%;
  bottom: 0;
}

#listing-content #col-result .results .result .content-header .header-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
}

#listing-content #col-result .results .result .content-header .header-iframe iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 250px;
}

#listing-content #col-result .results .result .content {
  border: 1px solid #eeeeee;
  background-color: #FFFFFF;
  padding: 10px 20px;
  border-radius: 2px;
}

#listing-content #col-result .results .result .content h5 {
  color: #02cd8d;
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 0;
  margin-top: 0;
}

#listing-content #col-result .results .result .content h5 a {
  color: #02cd8d;
}

#listing-content #col-result .results .result .content .legend {
  color: #545454;
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 7px;
  margin-top: 3px;
  width: 100%;
  display: block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

#listing-content #col-result .results .result .content .services {
  margin: 0 -5px;
}

#listing-content #col-result .results .result .content .services .service {
  background-color: #ccd9e0;
  border-radius: 2px;
  border: 1px solid #ccd9e0;
  color: #000000;
  font-size: 12px;
  padding: 5px 5px;
  float: left;
  margin: 0 5px 10px;
}

#listing-content #col-result .results .result .content .services .service.more {
  background-color: #FFFFFF;
  color: #505961;
  border: 1px solid #505961;
  cursor: pointer;
  transition: background-color .3s;
}

#listing-content #col-result .results .result .content .services .service.more img {
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
}

#listing-content #col-result .results .result .content .services .service.more:hover {
  background-color: #ebebeb;
}

#listing-content #col-result .results .result .content .services:after {
  display: block;
  content: '';
  clear: both;
}

#listing-content #col-result .results .result .content .focus .label {
  font-size: 13px;
  font-weight: bold;
  color: #333;
  margin-top: 10px;
  padding: 0;
  display: block;
  margin-bottom: 5px;
  text-align: left;
}

#listing-content #col-result .results .result .content .focus .service-focus .focus-point {
  height: 28px;
  color: #FFFFFF;
  margin-bottom: 5px;
  padding: 5px 0;
  text-align: center;
  font-size: 12px;
  float: left;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

#listing-content #col-result .results .result .content .focus .service-focus .focus-point:nth-child(5n+1) {
  background-color: #00bde7;
}

#listing-content #col-result .results .result .content .focus .service-focus .focus-point:nth-child(5n+2) {
  background-color: #00d8d4;
}

#listing-content #col-result .results .result .content .focus .service-focus .focus-point:nth-child(5n+3) {
  background-color: #008ba0;
}

#listing-content #col-result .results .result .content .focus .service-focus .focus-point:nth-child(5n+4) {
  background-color: #00d6b2;
}

#listing-content #col-result .results .result .content .focus .service-focus .focus-point:nth-child(5n+5) {
  background-color: #00afa3;
}

#listing-content #col-result .results .result .content .focus .service-focus .focus-point span {
  display: block;
  padding: 0 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

#listing-content #col-result .results .result .content .focus .service-focus:after {
  content: '';
  display: table;
  clear: both;
}

#listing-content #col-result .results .result > .hover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(103, 117, 138, 0.85);
  opacity: 0;
  transition: opacity .3s;
  display: block;
  z-index: 5;
  height: 250px;
}

#listing-content #col-result .results .result > .hover span {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  color: #FFFFFF;
  font-size: 50px;
  white-space: nowrap;
  transform: translate(-50%, -50%);
  text-shadow: 2px 2px #333;
}

#listing-content #col-result .results .result:hover > .hover {
  opacity: 1;
}

#listing-content #col-result ul.pagination > li.active > span,
#listing-content #col-result ul.pagination > li.active > a {
  background-color: #02cd8d;
  border-color: #02cd8d;
  color: #FFFFFF;
}

#listing-content #col-result ul.pagination > li:last-child > span,
#listing-content #col-result ul.pagination > li:last-child > a {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

#listing-content #col-result ul.pagination > li:first-child > span,
#listing-content #col-result ul.pagination > li:first-child > a {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

#listing-content #col-result ul.pagination > li > span, #listing-content #col-result ul.pagination > li > a {
  width: 32px;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
  color: #333;
}

#listing-content #col-result .description {
  padding: 30px 25px;
  background-color: #ffffff;
  margin-bottom: 70px;
}

#listing-content #col-result .description .description-title {
  padding-bottom: 10px;
  color: #28f2b2;
  font-size: 18px;
}

#listing-content #col-result .description .description-info {
  color: #878787;
}

.SumoSelect {
  width: 100%;
}

.SumoSelect > .CaptionCont {
  margin: 10px 0 20px;
  border: 1px solid #d4f0e3;
  -moz-appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, #02cd8d 50%), linear-gradient(135deg, #02cd8d 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px);
  background-size: 5px 5px, 5px 5px;
  background-repeat: no-repeat;
}

.SumoSelect > .CaptionCont > label > i {
  display: none;
}

.SumoSelect > .CaptionCont span.placeholder {
  color: #000;
}

.SumoSelect > .optWrapper.multiple.isFloating {
  padding-bottom: 0px;
}

.SumoSelect > .optWrapper.isFloating {
  position: fixed;
  top: 320px;
  left: inherit;
  right: inherit;
  width: 100%;
  bottom: inherit;
  margin: inherit;
  max-height: inherit;
}

.SumoSelect > .optWrapper.multiple.isFloating > .MultiControls {
  display: none;
}

.SumoSelect .select-all.partial > span i, .SumoSelect .select-all.selected > span i, .SumoSelect > .optWrapper.multiple > .options li.opt.selected span i {
  background-color: #02cd8d;
  border: 1px solid #d4f0e3;
}

@media only screen and (max-width: 991px) {
  .js-izzi-sticky.is-sticky + #banner-wrapper {
    margin-top: 0px;
  }
  #listing-content {
    padding-left: 15px;
    padding-right: 15px;
  }
  #listing-content #col-result {
    width: 100%;
  }
  #listing-content #col-result .results .result .content-header .header-bar {
    padding: 30px 0 10px 70px;
  }
  #listing-content #col-result .results .result .content-header .header-bar .header-logo {
    width: 55px;
    height: 55px;
  }
  #listing-content #col-result .results .result .content-header .header-bar .header-logo .image {
    width: 49px;
    height: 49px;
  }
  #listing-content #col-result .results .result .content-header .header-bar .tag-location {
    margin-left: 10px;
    margin-bottom: 5px;
  }
  #listing-content #col-result .results .result .content-header .header-bar .tag-employee {
    margin-left: 10px;
    margin-bottom: 5px;
  }
  .container {
    width: 100%;
  }
  #filter-bar .fields > .options.with-parent .option-wrapper {
    min-width: 200px;
  }
  #filter-bar .fields > .options.with-parent .option-wrapper .options.two-column {
    min-width: 200px;
  }
  #filter-bar .fields.active .options {
    display: block;
  }
  #filter-bar .fields .selected {
    display: inline-block;
    width: 200px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
  #col-filter {
    width: 100%;
  }
  #col-filter .box {
    padding: 20px 30px 20px 20px;
    background-color: #FFFFFF;
    border: 1px solid #d8f1e6;
    font-size: 13px;
  }
  #col-filter .box .col-xs-6 {
    padding-right: 0px;
  }
  #col-filter .box .form-legend {
    color: #4b4b4b;
    font-size: 16px;
    line-height: normal;
    font-weight: bold;
    letter-spacing: 1px;
    left: 1px;
  }
  #col-filter .box .divider {
    margin: 10px -30px 10px -20px;
    border-top: 1px solid rgba(200, 200, 200, 0.3);
  }
  #col-filter .box label {
    color: #4b4b4b;
    font-size: 15px;
    line-height: normal;
    font-weight: normal;
    left: 1px;
  }
  #col-filter .box select {
    margin: 10px 0 20px;
    border: 1px solid #d4f0e3;
    padding: 5px 10px;
    color: #333333;
    display: block;
    width: 100%;
    outline: 0;
    cursor: pointer;
    background-color: #FFFFFF;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: linear-gradient(45deg, transparent 50%, #02cd8d 50%), linear-gradient(135deg, #02cd8d 50%, transparent 50%);
    background-position: calc(100% - 10px) calc(1em + 2px), calc(100% - 5px) calc(1em + 2px);
    background-size: 5px 5px, 5px 5px;
    background-repeat: no-repeat;
  }
  #col-filter .box select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }
  #col-filter .box .checkboxes {
    margin: 10px 0 20px;
  }
  #col-filter .box .checkboxes label {
    display: block;
    margin: 0;
    font-size: 12px;
    cursor: pointer;
  }
  #col-filter .box .checkboxes label:not(:first-child) {
    margin-top: 10px;
  }
  #col-filter .box .checkboxes label span {
    display: inline-block;
    vertical-align: middle;
  }
  #col-filter .box .checkboxes label span.checkbox {
    border: 1px solid #d4f0e3;
    background-color: #d8f1e6;
    border-radius: 2px;
    margin: 0 10px 0 0;
    width: 20px;
    height: 20px;
    position: relative;
    transition: background-color .3s;
  }
  #col-filter .box .checkboxes label span.checkbox input {
    visibility: hidden;
    opacity: 0;
  }
  #col-filter .box .checkboxes label.checked span.checkbox {
    background-color: #02cd8d;
  }
  #col-filter .box .checkboxes label.checked span.checkbox:before {
    top: 50%;
    left: 50%;
    content: '';
    position: absolute;
    height: 6px;
    width: 15px;
    border-left: 2px solid #d8f1e6;
    border-bottom: 2px solid #d8f1e6;
    display: block;
    margin: 0 auto;
    transform: translate(-50%, -55%) rotate(-45deg);
    -webkit-transform: translate(-50%, -55%) rotate(-45deg);
    -moz-transform: translate(-50%, -55%) rotate(-45deg);
    -ms-transform: translate(-50%, -55%) rotate(-45deg);
    -o-transform: translate(-50%, -55%) rotate(-45deg);
  }
  #col-filter .box .checkboxes label:hover:not(.checked) span.checkbox {
    background-color: #d4f0e3;
  }
  #col-filter .box .checkboxes label:hover.checked span.checkbox {
    background-color: #d8f1e6;
  }
  #col-filter .box .checkboxes label:hover.checked span.checkbox:before {
    border-left: 2px solid #02cd8d;
    border-bottom: 2px solid #02cd8d;
  }
  #col-filter .box button {
    color: #02cd8d;
    background-color: #ffffff;
    border: 1px solid #02cd8d;
    display: block;
    border-radius: 2px;
    cursor: pointer;
    text-decoration: none;
    font-size: 16px;
    padding: 3px 15px;
    width: 100%;
  }
  #col-filter .box button span {
    float: left;
  }
  #col-filter .box + .box {
    margin-top: 20px;
  }
  #col-filter .box.colorful {
    position: relative;
    background-image: linear-gradient(to bottom right, #03cd8e, #7b93ba, #c86dd7);
    padding: 15px;
  }
  #col-filter .box.colorful .bg-white {
    background-color: #FFFFFF;
    padding: 20px;
  }
  #col-filter .box.colorful .bg-white span {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    letter-spacing: 1px;
    color: #02cd8d;
    margin-bottom: 10px;
    display: block;
    font-weight: bold;
  }
  #col-filter .box.colorful .bg-white ul {
    display: block;
    padding: 0;
    margin: 0 0 15px;
    list-style: none;
  }
  #col-filter .box.colorful .bg-white ul li {
    padding-top: 5px;
    padding-bottom: 5px;
    color: #888888;
    font-weight: 400;
    font-size: 13px;
    font-family: "Roboto", sans-serif;
    letter-spacing: 1px;
  }
  #col-filter .box.colorful .bg-white ul li img {
    vertical-align: middle;
    display: inline-block;
    margin-right: 10px;
  }
  #banner-wrapper .wrapper-body .wrapper-heading {
    font-size: 30px;
  }
  #banner-wrapper .wrapper-body .wrapper-description {
    font-size: 20px;
  }
  #listing-options-wrapper {
    border: none;
  }
  #listing-options-wrapper .wrapper-col-left {
    padding: 30px;
    text-align: center;
  }
  #listing-options-wrapper .wrapper-col-left .fields > .options.with-parent {
    right: 50%;
  }
  #listing-options-wrapper .wrapper-col-left .fields > .options.with-parent .parent-wrapper {
    width: 100%;
    z-index: 3;
  }
  #listing-options-wrapper .wrapper-col-left .fields > .options.with-parent .option-wrapper {
    width: 100%;
    left: 97%;
    min-width: 0;
    font-size: 10px;
    padding: 15px 10px 15px 15px;
    z-index: 2;
  }
  #listing-options-wrapper .wrapper-col-left .fields > .options.with-parent .option-wrapper .options .option {
    width: 20%;
  }
  #listing-options-wrapper .wrapper-col-left .fields > .options.with-parent .option-wrapper .options.two-column {
    min-width: 100%;
  }
  #listing-options-wrapper .wrapper-col-left .fields > .options .triangle-top {
    left: 95%;
    z-index: 4;
  }
  #listing-options-wrapper .wrapper-col-right {
    border-top: 2px solid #f0f4f7;
    border-left: none;
    padding: 30px;
  }
  #sorting-content .branding-description {
    padding: 30px 25px;
    background-color: #ffffff;
    margin-bottom: 15px;
  }
  #sorting-content .branding-description .description-title {
    padding-bottom: 10px;
    color: #28f2b2;
    font-size: 18px;
  }
  #sorting-content .branding-description .description-info {
    color: #878787;
  }
}

@media only screen and (max-width: 767px) {
  #banner-wrapper {
    height: 100vh;
    max-height: 525px;
  }
  #banner-wrapper .wrapper-body .wrapper-heading {
    font-size: 22px;
    margin-bottom: 15px;
  }
  #banner-wrapper .wrapper-body .wrapper-description {
    font-size: 16px;
  }
  #filter-bar {
    padding: 10px 0;
  }
  #filter-bar .container {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    display: table;
  }
  #filter-bar .fields {
    float: none;
    display: table-cell;
    min-width: initial;
    position: static;
    width: 33.33333333%;
    padding: 0px;
  }
  #filter-bar .fields .selected {
    font-size: 16px;
    display: inline-block;
    width: 100px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
  #filter-bar .fields > .options {
    transform: translateY(-20px);
    width: 100%;
    top: 27%;
  }
  #filter-bar .fields > .options.with-parent .parent-wrapper {
    width: 50%;
    min-width: initial;
  }
  #filter-bar .fields > .options.with-parent .option-wrapper {
    width: 50%;
    min-width: initial;
    left: 50%;
  }
  #filter-bar .fields.type .options .triangle-top {
    left: 66.66%;
  }
  #filter-bar .fields.location .options .triangle-top {
    left: 33.33%;
  }
  #col-filter .box {
    font-size: 10px;
  }
  #col-filter .box select {
    padding: 5px 5px 5px 0px;
  }
  #col-filter .box button {
    font-size: 13px;
    padding: 3px;
  }
  #col-filter .box label {
    font-size: 10px;
  }
  #listing-content .container {
    padding-left: 0;
    padding-right: 0;
  }
  #listing-content .container > .row {
    margin-left: 0;
    margin-right: 0;
  }
  #listing-content #col-result {
    padding-left: 0;
    padding-right: 0;
  }
  #listing-content #col-result .results > .row {
    margin-left: 0;
    margin-right: 0;
  }
  #listing-content #col-result .results > .row > [class^=col] {
    padding-left: 0;
    padding-right: 0;
  }
  #listing-content #col-result .results .result {
    border-radius: 0;
  }
  #listing-content #col-result .results .result .content-header .header-bar .green-tags .green-tag {
    margin-bottom: 5px;
  }
  #listing-content #col-result .results .result .content-header .header-bar .green-tags .green-tag:not(:first-child) {
    margin-left: 20px;
  }
  #listing-content #col-result .results .result > .hover span {
    font-size: 40px;
  }
  #listing-options-wrapper .wrapper-col-left {
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    padding-right: 0;
  }
  #listing-options-wrapper .wrapper-col-left .fields > .options .triangle-top {
    left: 80%;
  }
  #listing-options-wrapper .wrapper-col-left .fields.location > .options.with-parent .option-wrapper {
    min-height: 289px;
  }
  #listing-options-wrapper .wrapper-col-right {
    padding: 10px 10px 20px;
  }
  #listing-options-wrapper .wrapper-col-right p {
    font-size: 13px;
  }
  #listing-options-wrapper .wrapper-col-right button.btn-type-1 {
    border-radius: 3px;
    font-size: 14px;
  }
}

#professional-profile {
  padding-top: 60px;
  padding-bottom: 30px;
  background-color: #f0f4f7;
}

#professional-profile .container-row {
  display: table;
  width: 100%;
}

#professional-profile .container-row > div {
  display: table-cell;
  vertical-align: top;
}

#professional-profile #professional-profile-left {
  display: table-cell;
  width: 30%;
  border-radius: 4px 0 0 4px;
  background-color: #fafafa;
  box-shadow: 0px 3px 7px 1px #c3c3c3;
}

#professional-profile #professional-profile-left .box {
  padding: 20px 27px;
}

#professional-profile #professional-profile-left .box:not(:first-child) {
  border-top: 1px solid #d9dddf;
}

#professional-profile #professional-profile-left .box.box-header .title {
  color: #444444;
  font-size: 20px;
  line-height: 1.3;
  font-weight: bold;
}

#professional-profile #professional-profile-left .box.box-header .tagline {
  color: #545454;
  font-size: 13px;
  font-weight: bold;
  display: inline-block;
  width: 100%;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

#professional-profile #professional-profile-left .box.box-header .ratings {
  padding-top: 15px;
  padding-bottom: 15px;
  display: table;
}

#professional-profile #professional-profile-left .box.box-header .ratings .col-circle {
  display: table-cell;
  width: 85px;
  padding-left: 20px;
}

#professional-profile #professional-profile-left .box.box-header .ratings .col-circle .circles-text {
  font-size: 16px !important;
  color: #888888 !important;
}

#professional-profile #professional-profile-left .box.box-header .ratings .col-circle .circles-text b {
  color: #444444 !important;
}

#professional-profile #professional-profile-left .box.box-header .ratings .col-review {
  display: table-cell;
  vertical-align: middle;
  padding-left: 10px;
  color: #02cd8d;
  font-size: 12px;
}

#professional-profile #professional-profile-left .box.box-header .ratings .col-review .fa {
  color: #373f48;
  font-size: 13px;
  display: inline-block;
  margin-left: 5px;
}

#professional-profile #professional-profile-left .box.box-header .btn-effect-1 {
  text-align: center;
  line-height: 47px;
  padding-top: 0px;
  padding-bottom: 3px;
}

#professional-profile #professional-profile-left .box .type {
  font-family: "Roboto", sans-serif;
  padding-left: 35px;
  color: #444444;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
}

#professional-profile #professional-profile-left .box .type .icon {
  width: 18px;
  height: 18px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  left: 0;
  top: 0;
}

#professional-profile #professional-profile-left .box .type span {
  font-weight: normal;
  text-transform: none;
}

#professional-profile #professional-profile-left .box .type span a {
  color: #02cd8d;
}

#professional-profile #professional-profile-left .box .type:not(:first-child) {
  margin-top: 20px;
}

#professional-profile #professional-profile-left .box .type.type-location {
  width: 400px;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#professional-profile #professional-profile-left .box .offices .office .type:not(:first-child) {
  margin-top: 10px;
}

#professional-profile #professional-profile-left .box .offices .office:not(:first-child) {
  margin-top: 15px;
}

#professional-profile #professional-profile-left .box .services {
  padding-left: 35px;
  margin-left: -8px;
  margin-top: 8px;
}

#professional-profile #professional-profile-left .box .services .service {
  margin-left: 8px;
  margin-bottom: 8px;
  background-color: #505961;
  display: inline-block;
  padding: 3px 8px;
  border-radius: 2px;
  color: rgba(255, 255, 255, 0.75);
}

#professional-profile #professional-profile-left .box .top-description {
  background-color: #e8fff8;
  padding: 20px;
  border: 2px solid #00e7ad;
  text-align: center;
  margin-bottom: 20px;
  position: relative;
}

#professional-profile #professional-profile-left .box .top-description:before {
  content: "";
  position: absolute;
  bottom: -10px;
  left: calc(50% - 9px);
  width: 18px;
  height: 18px;
  border-bottom: 2px solid #00e7ad;
  transform: rotate(45deg);
  background: #e8fff8;
  border-right: 2px solid #00e7ad;
}

#professional-profile #professional-profile-left .box .top-description .title {
  display: inline-flex;
}

#professional-profile #professional-profile-left .box .top-description .title .svg-menu {
  width: 25px;
  height: 25px;
  margin-right: 5px;
}

#professional-profile #professional-profile-left .box .top-description .title span {
  font-size: 18px;
  line-height: 25px;
}

#professional-profile #professional-profile-left .box .btn-1 {
  text-align: left;
  color: #18293e;
  font-weight: bold;
  background-color: #00e7ad;
  width: 100%;
  padding: 15px 20px;
  display: block;
  font-size: 18px;
  margin-bottom: 20px;
  text-decoration: none;
}

#professional-profile #professional-profile-left .box .btn-1.disabled {
  pointer-events: none;
  opacity: 0.4;
}

#professional-profile #professional-profile-left .box .btn-1:hover {
  background-color: #02cd8d;
}

#professional-profile #professional-profile-left .box .btn-1 span .fa {
  padding-right: 20px;
}

#professional-profile #professional-profile-left .box .other-description span {
  font-weight: bold;
  font-size: 18px;
}

#professional-profile #professional-profile-left .box .other-description span .fa {
  color: #00e7ad;
  padding-right: 10px;
}

#professional-profile #professional-profile-right {
  display: table-cell;
  width: 70%;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 8px 1px #c3c3c3;
}

#professional-profile #professional-profile-right #professional-profile-header {
  background-color: #f7f7f9;
  position: relative;
}

#professional-profile #professional-profile-right #professional-profile-header .box {
  height: 280px;
  padding-bottom: 20px;
  background-size: cover;
  background-position: bottom center;
  position: relative;
}

#professional-profile #professional-profile-right #professional-profile-header .box .table-row {
  display: block;
  width: 100%;
  position: absolute;
  bottom: 0;
}

#professional-profile #professional-profile-right #professional-profile-header .box .table-row [class^=col] {
  padding-left: 25px;
  padding-right: 25px;
}

#professional-profile #professional-profile-right #professional-profile-header .box .table-row .col-left {
  width: 125px;
  position: absolute;
  left: 0;
  bottom: 20px;
}

#professional-profile #professional-profile-right #professional-profile-header .box .table-row .col-left .img-wrapper {
  width: 80px;
  height: 80px;
  padding: 5px;
  border-radius: 5px;
  background-color: #FFFFFF;
}

#professional-profile #professional-profile-right #professional-profile-header .box .table-row .col-left .img-wrapper .image {
  width: 70px;
  height: 70px;
  background-position: center;
  background-size: cover;
}

#professional-profile #professional-profile-right #professional-profile-header .box .table-row .col-right {
  padding-bottom: 20px;
  padding-top: 20px;
  padding-left: 125px;
  vertical-align: middle;
  background: rgba(0, 0, 0, 0.5);
}

#professional-profile #professional-profile-right #professional-profile-header .box .table-row .col-right .name {
  font-size: 24px;
  font-family: "Roboto", sans-serif;
  color: #FFFFFF;
  font-weight: bold;
  margin: 0;
  letter-spacing: 1px;
  display: inline-block;
}

#professional-profile #professional-profile-right #professional-profile-header .box .table-row .col-right .name .fa {
  color: #28f1b1;
  padding-left: 5px;
}

#professional-profile #professional-profile-right #professional-profile-header .box .table-row .col-right .info {
  display: inline-block;
}

#professional-profile #professional-profile-right #professional-profile-header .box .table-row .col-right .info .tag {
  display: inline-block;
  background-color: #02cd8d;
  color: #FFFFFF;
  font-size: 13px;
  padding: 5px 10px;
  margin-left: 20px;
  position: relative;
  border-radius: 4px 0 0 4px;
}

#professional-profile #professional-profile-right #professional-profile-header .box .table-row .col-right .info .tag:not(:first-child) {
  margin-left: 30px;
}

#professional-profile #professional-profile-right #professional-profile-header .box .table-row .col-right .info .tag:before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 15px 0 0;
  border-color: #02cd8d transparent transparent transparent;
  position: absolute;
  left: 100%;
  top: 0;
}

#professional-profile #professional-profile-right #professional-profile-header .box .table-row .col-right .info .tag:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 0 0 15px;
  border-color: transparent transparent transparent #02cd8d;
  position: absolute;
  left: 100%;
  bottom: 0;
}

#professional-profile #professional-profile-right #professional-profile-header .tab-header {
  background-color: #373f48;
  padding: 0 25px 1px;
  text-align: left;
  font-size: 0;
}

#professional-profile #professional-profile-right #professional-profile-header .tab-header .tab {
  text-decoration: none;
  padding: 15px 0 14px;
  color: #FFFFFF;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  border-bottom: 2px solid transparent;
  display: inline-block;
  margin: 0 10px;
  letter-spacing: 2px;
  cursor: pointer;
  background-color: #373f48;
  transition: border-bottom .3s, color .3s;
  position: relative;
}

#professional-profile #professional-profile-right #professional-profile-header .tab-header .tab.opened {
  color: #02cd8d;
  border-bottom: 2px solid #02cd8d;
}

#professional-profile #professional-profile-right #professional-profile-header .tab-header .tab.opened:after {
  content: '';
  display: block;
  position: absolute;
  top: 100%;
  margin-top: 2px;
  height: 1px;
  left: 0;
  right: 0;
  background-color: #FFFFFF;
}

#professional-profile #professional-profile-right #professional-profile-header .tab-header .tab:hover:not(.opened) {
  border-bottom: 2px solid #FFFFFF;
}

#professional-profile #professional-profile-right #professional-profile-header .tab-header.is-sticky {
  position: fixed;
  top: 0;
  bottom: auto;
  left: 0;
  right: 0;
  border-top: 3px solid #02cd8d;
  z-index: 3;
}

#professional-profile #professional-profile-right #professional-profile-header .tab-header.is-sticky > div .container > ul > li > a > img {
  display: none;
}

#professional-profile #professional-profile-right #professional-profile-header .badges {
  position: absolute;
  top: -5px;
  right: 20px;
}

#professional-profile #professional-profile-right #professional-profile-header .badges .svg-menu {
  float: left;
  display: inline;
  margin: 0 3px;
}

#professional-profile #professional-profile-right #professional-profile-header .badges .svg-menu img {
  width: 40px;
}

#professional-profile #professional-profile-right #professional-profile-header:not(.is-sticky) + #profile-content {
  padding-top: 25px;
}

#professional-profile #professional-profile-right #professional-profile-content {
  padding: 30px 50px;
}

#professional-profile #professional-profile-right #professional-profile-content .review-page-title {
  padding: 12px 0;
}

#professional-profile #professional-profile-right #professional-profile-content .review-page-title .title {
  line-height: 42px;
  float: left;
  color: #333333;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
}

#professional-profile #professional-profile-right #professional-profile-content .review-page-title .case {
  line-height: 42px;
  margin-left: 15px;
  float: left;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 15px;
}

#professional-profile #professional-profile-right #professional-profile-content .review-page-title .case span {
  border-radius: 4px;
  padding: 8px 8px;
  line-height: 1;
  border: 1px solid #333;
  display: inline-block;
  margin-right: 5px;
}

#professional-profile #professional-profile-right #professional-profile-content .review-page-title .btn-wrapper {
  float: right;
}

#professional-profile #professional-profile-right #professional-profile-content .review-page-title .btn-wrapper .btn-effect-1:focus {
  text-decoration: none;
  color: #ffffe7;
}

#professional-profile #professional-profile-right #professional-profile-content .container {
  display: table;
}

#professional-profile #professional-profile-right #professional-profile-content .container > .row {
  margin: 0;
  display: table-row;
}

#professional-profile #professional-profile-right #professional-profile-content .container > .row > [class^=col] {
  display: table-cell;
  vertical-align: top;
}

#professional-profile #professional-profile-right #professional-profile-content .container .col-left {
  padding-right: 25px;
}

#professional-profile #professional-profile-right #professional-profile-content .container .col-right {
  padding-left: 25px;
  width: 295px;
}

#professional-profile #professional-profile-right #professional-profile-content .header-container {
  display: table;
  margin-bottom: 10px;
}

#professional-profile #professional-profile-right #professional-profile-content .header-container .row {
  margin: 0;
  display: table-row;
  color: #333333;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: bold;
}

#professional-profile #professional-profile-right #professional-profile-content .header-container .row > [class^=col] {
  display: table-cell;
  vertical-align: top;
}

#professional-profile #professional-profile-right #professional-profile-content .header-container .col-left {
  padding-right: 25px;
}

#professional-profile #professional-profile-right #professional-profile-content .header-container .col-right {
  padding-left: 25px;
  width: 295px;
}

#professional-profile #professional-profile-right #professional-profile-content .header-container .name {
  display: inline-block;
}

#professional-profile #professional-profile-right #professional-profile-content .header-container .cases {
  margin-left: 15px;
  display: inline-block;
  font-weight: normal;
}

#professional-profile #professional-profile-right #professional-profile-content .header-container .cases span {
  display: inline-block;
  color: #444444;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  padding: 2px 6px;
  border: 1px solid #444444;
  border-radius: 2px;
  position: relative;
  top: -2px;
  margin-right: 5px;
}

#professional-profile #professional-profile-right #professional-profile-content .header-container select {
  margin: 0 15px;
  display: inline-block;
  border: 1px solid #d4f0e3;
  background-color: #FFFFFF;
  padding: 4px 60px 4px 10px;
  position: relative;
  top: -4px;
  font-weight: normal;
  outline: 0;
  cursor: pointer;
}

#professional-profile #professional-profile-right #professional-profile-content .header-container button {
  letter-spacing: 0;
  color: #FFFFFF;
  border-bottom: 3px solid #0d513b;
  padding: 8px 15px;
  position: relative;
  top: -6px;
  font-size: 13px;
  line-height: normal;
}

#professional-profile #professional-profile-right #professional-profile-content .box {
  background-color: #FFFFFF;
  border: 1px solid #f0f4f7;
  border-radius: 5px;
  margin-bottom: 20px;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-heading {
  padding: 20px 25px;
  background-color: #f0f4f7;
  border-bottom: 1px solid #f0f4f7;
  color: #333333;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: bold;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-heading img {
  display: inline-block;
  margin-left: 10px;
  height: 20px;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-heading .right {
  color: #aaaaaa;
  font-size: 12px;
  float: right;
  font-weight: normal;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body {
  padding: 20px 25px;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .info-row {
  margin-bottom: 15px;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .info-row .info-label {
  display: inline-block;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  color: #737373;
  font-size: 13px;
  line-height: normal;
  width: 80px;
  text-align: right;
  padding-right: 15px;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .info-row .info-value {
  display: inline-block;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  color: #737373;
  font-size: 13px;
  line-height: normal;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .paragraph {
  line-height: 1.5;
  font-family: "Roboto", sans-serif;
  color: #555555;
  font-size: 13px;
  padding-bottom: 30px;
  position: relative;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .paragraph p {
  margin-bottom: 10px;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .paragraph .showMore {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #029470;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  cursor: pointer;
  transition: all .3s;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .paragraph .showMore i {
  display: inline-block;
  margin-right: 5px;
  font-style: normal;
  font-size: 13px;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .paragraph .showMore:hover {
  font-weight: bold;
  font-size: 13px;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .paragraph .showMore:hover i {
  transition: all .3s;
  font-weight: bold;
  font-size: 16px;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .paragraph.collapsed {
  display: block;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .paragraph.to_extend {
  display: none;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .paragraph.to_extend.extended {
  display: block;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .col-item {
  border-radius: 5px;
  border: 1px solid #c7ffe4;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .col-item .name {
  border-bottom: 1px solid #c7ffe4;
  color: #444444;
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  padding: 15px 10px;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .col-item .paragraph {
  padding: 15px 10px 55px 10px;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .col-item .paragraph .showMore {
  left: 10px;
  bottom: 25px;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .col-member {
  border: 1px solid #f0f4f7;
  padding: 15px 0;
  border-radius: 2px;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .col-member .member-row {
  display: table;
  width: 100%;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .col-member .member-row .col-img {
  display: table-cell;
  width: 123px;
  padding-left: 50px;
  padding-right: 23px;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .col-member .member-row .col-img img {
  max-width: 50px;
  max-height: 50px;
  border-radius: 50%;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .col-member .member-row .col-info {
  display: table-cell;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .col-member .member-row .col-info .name {
  color: #414141;
  font-family: "Roboto", sans-serif;
  margin-bottom: 5px;
  font-size: 16px;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .col-member .member-row .col-info .position {
  font-size: 13px;
  color: #313131;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .col-member .histories {
  margin-top: 20px;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .col-member .histories .history {
  padding-left: 50px;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .col-member .histories .history .img {
  width: 16px;
  display: inline-block;
  margin-right: 10px;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .col-member .histories .history .img img {
  max-height: 16px;
  max-width: 16px;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .col-member .histories .history .history-name {
  display: inline-block;
  color: #868686;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .tabs .tab-header {
  margin-right: -10px;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .tabs .tab-header .tab {
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
  background-color: #505961;
  border: 1px solid #505961;
  color: #FFFFFF;
  border-radius: 2px;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color .3s, border .3s, color .3s;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .tabs .tab-header .tab.opened {
  background-color: #FFFFFF;
  color: #02cd8d;
  border: 1px solid #02cd8d;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .tabs .tab-header .tab:hover:not(.opened) {
  background-color: #737373;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .tabs .tab-body {
  padding-top: 15px;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .tabs .tab-body .tab {
  display: none;
  visibility: hidden;
  opacity: 0;
  transition: opacity .3s;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .tabs .tab-body .tab .title {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  color: #737373;
  font-size: 13px;
  line-height: normal;
  margin-bottom: 15px;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .tabs .tab-body .tab .paragraph {
  padding-bottom: 0;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .tabs .tab-body .tab.opened {
  opacity: 1;
  visibility: visible;
  display: block;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .client-info {
  padding: 0 0 20px;
  color: #333333;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: normal;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .client-info strong {
  font-weight: bold;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .table-info {
  padding: 25px 0;
  margin-bottom: 25px;
  border: 1px solid #d8f1e6;
  display: table;
  width: 100%;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .table-info .table-row {
  display: table-row;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .table-info .table-row > [class^=col] {
  display: table-cell;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  color: #333333;
  vertical-align: top;
  width: 308px;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .table-info .table-row > [class^=col].col-left .row {
  display: block;
  margin-left: -15px;
  margin-right: -15px;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .table-info .table-row > [class^=col].col-left .black {
  margin-bottom: 20px;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .table-info .table-row > [class^=col].col-left .with-icon {
  padding-top: 5px;
  padding-bottom: 5px;
  min-height: 30px;
  padding-left: 25px;
  position: relative;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .table-info .table-row > [class^=col].col-left .with-icon .icon {
  position: absolute;
  left: 0;
  top: 5px;
  width: 20px;
  height: 20px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .table-info .table-row > [class^=col].col-left .with-icon:not(:first-child) {
  margin-top: 5px;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .table-info .table-row > [class^=col].col-left .with-icon:not(:first-child) .more {
  float: right;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .table-info .table-row > [class^=col].col-left .with-icon:not(:first-child) .tooltip_content {
  display: none;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .table-info .table-row > [class^=col].col-left .tag {
  border-radius: 2px;
  background-color: #444444;
  padding: 7px 14px 7px 35px;
  color: #FFFFFF;
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
  display: inline-block;
  position: relative;
  cursor: context-menu;
  margin-top: 10px;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .table-info .table-row > [class^=col].col-left .tag:before {
  content: '';
  width: 14px;
  height: 8px;
  border-left: 2px solid #FFFFFF;
  border-bottom: 2px solid #FFFFFF;
  transform: rotate(-45deg);
  position: absolute;
  left: 10px;
  bottom: 16px;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .table-info .table-row > [class^=col].col-middle {
  width: 262px;
  border-left: 1px solid #d4f0e3;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .table-info .table-row > [class^=col].col-middle .info-row:after {
  content: '';
  display: block;
  clear: both;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .table-info .table-row > [class^=col].col-middle .info-row .info-label {
  float: left;
  width: 121px;
  font-size: 11px;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  color: #333333;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .table-info .table-row > [class^=col].col-middle .info-row .info-value {
  float: left;
  position: relative;
  background-color: #dbdbdb;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .table-info .table-row > [class^=col].col-middle .info-row .info-value:after {
  content: '';
  display: block;
  clear: both;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .table-info .table-row > [class^=col].col-middle .info-row .info-value .value-column {
  width: 18px;
  height: 17px;
  background-color: transparent;
  display: inline-block;
  float: left;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .table-info .table-row > [class^=col].col-middle .info-row .info-value .value-column:not(:first-child) {
  border-left: 1px solid #FFFFFF;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .table-info .table-row > [class^=col].col-middle .info-row .info-value .value {
  height: 17px;
  background-color: #02cd8d;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .table-info .table-row > [class^=col].col-right {
  width: 150px;
  border-left: 1px solid #d4f0e3;
  text-align: center;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .table-info .table-row > [class^=col].col-right .circles-text {
  font-size: 24px !important;
  color: #888888 !important;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .table-info .table-row > [class^=col].col-right .circles-text b {
  color: #444444 !important;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .table-info .table-row > [class^=col] .black {
  font-size: 12px;
  font-weight: bold;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .table-info.table-2-column {
  margin-bottom: 0;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .table-info.table-2-column .table-row > [class^=col].col-middle {
  width: 50%;
  border-left: 0;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .table-info.table-2-column .table-row > [class^=col].col-right {
  width: 50%;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .summary {
  word-break: break-all;
  -ms-word-break: break-all;
  word-break: break-all;
  /* Non standard for WebKit */
  word-break: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  /*.title {
              margin-bottom: 15px;
            }
            .blue {
              color: $color-22;
              font-weight: 700;
              font-family: $font-1;
              font-size: 13px;
            }
            .black {
              color: $color-19;
              font-weight: 700;
              font-family: $font-1;
              font-size: 13px;
            }
            .paragraph {
              font-size: 13px;
              font-family: $font-1;
              color: $color-20;
              line-height: 1.5;
              font-weight: 700;
              padding-bottom: 20px;
            }
            .feedback {
              margin: 0 0 20px;
              border: 1px solid $color-16;
              -webkit-border-radius: 2px;
              -moz-border-radius: 2px;
              border-radius: 2px;
              padding: 15px 50px;
              background-color: $color-27;
              position: relative;
              &:before {
                content: '“';
                position: absolute;
                top: -10px;
                left: 10px;
                font-size: 70px;
                color: $color-29;
                font-weight: bold;
              }
              &:after {
                content: '”';
                position: absolute;
                bottom: -40px;
                right: 10px;
                font-size: 70px;
                color: $color-29;
                font-weight: bold;
              }
              .title {
                color: $color-28;
                margin-bottom: 15px;
                font-weight: 700;
                font-family: $font-1;
                font-size: 13px;
                font-style: italic;
              }
              .paragraph {
                color: $color-2;
                line-height: 1.5;
                font-size: 13px;
                font-family: $font-1;
                font-weight: 700;
                font-style: italic;
              }
            }*/
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .summary.active .content.content-hidden {
  display: block;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .summary .showMore {
  margin: 10px auto;
  padding: 10px;
  cursor: pointer;
  color: #02cd8d;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  letter-spacing: 1px;
  display: block;
  width: 220px;
  text-align: center;
  position: relative;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .summary .showMore:after {
  content: '';
  background-color: #02cd8d;
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  transition: width .3s;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .summary .showMore:hover:after {
  width: 100%;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .review-content .content {
  margin-bottom: 20px;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .review-content .content > h4 {
  font-weight: bold;
  color: #3f92f1;
  font-size: 16px;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .review-content .content b {
  font-size: 14px;
  color: #000;
  font-weight: bold;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .review-content .content blockquote {
  margin: 0 0 20px;
  border: 1px solid #d4f0e3;
  border-radius: 2px;
  padding: 15px 50px;
  background-color: #f0f4f7;
  position: relative;
  color: #888888;
  line-height: 1.5;
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: italic;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .review-content .content blockquote:before {
  content: '“';
  position: absolute;
  top: -10px;
  left: 10px;
  font-size: 70px;
  color: #d1d8de;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .review-content .content blockquote:after {
  font-family: "Roboto", sans-serif;
  content: '”';
  position: absolute;
  bottom: -40px;
  right: 10px;
  font-size: 70px;
  color: #d1d8de;
  font-weight: bold;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .review-content .content blockquote h4 {
  color: #5a5a5a;
  margin-bottom: 15px;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-style: italic;
}

#professional-profile #professional-profile-right #professional-profile-content .box .box-body .review-content .content.content-hidden {
  display: none;
}

#professional-profile #professional-profile-right #professional-profile-content .box.about .col {
  display: inline-block;
  margin-top: 15px;
  padding-left: 30px;
  padding-right: 30px;
}

#professional-profile #professional-profile-right #professional-profile-content .box.about .col .value {
  color: #02cd8d;
  font-size: 36px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
}

#professional-profile #professional-profile-right #professional-profile-content .box.about .col .label {
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  color: #494949;
}

#professional-profile #professional-profile-right #professional-profile-content .box.about .col .label .fa {
  font-size: 15px;
  display: inline-block;
  margin-left: 3px;
  color: #d9d9d9;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-green {
  border: 1px solid #c7ffe4;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-green .box-heading {
  background-color: #c7ffe4;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-positioning .positions .position {
  position: relative;
  padding-top: 20px;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-positioning .positions .position .label {
  color: #3f3f3f;
  position: absolute;
  font-size: 13px;
  padding: 0;
  top: 0;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-positioning .positions .position .label.label-left {
  left: 0;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-positioning .positions .position .label.label-right {
  right: 0;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-positioning .positions .position .bar {
  position: relative;
  width: 100%;
  height: 10px;
  margin-bottom: 13px;
  background-color: #dddddd;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-positioning .positions .position .bar .pointer {
  width: 20px;
  height: 20px;
  position: absolute;
  top: -5px;
  background-color: #02cd8d;
  border-radius: 50%;
  transform: translateX(-50%);
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-positioning .positions .position:not(:first-child) {
  margin-top: 20px;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-qualification .data:not(:first-child) {
  margin-top: 5px;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-qualification .data a {
  border-radius: 2px;
  background: #505961;
  display: inline-block;
  padding: 5px 10px;
  margin-bottom: 10px;
  color: #d9dbdc;
  font-size: 12px;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-qualification .data a:hover {
  text-decoration: none;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-qualification .data .collapse {
  padding-bottom: 15px;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-portfolio .results {
  margin-left: -10px;
  margin-right: -10px;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper {
  float: left;
  width: 33.33333%;
  padding: 0 10px 20px 10px;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio {
  font-family: "roboto";
  text-decoration: none;
  box-shadow: 0 2px 2px 0 #D8D8D8;
  background-color: #fff;
  margin-bottom: 20px;
  border-radius: 5px;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .portfolio-ajax-link {
  cursor: pointer;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .portfolio-wrapper-heading {
  padding-bottom: 75%;
  background-size: cover;
  background-position: 0 0;
  position: relative;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .portfolio-wrapper-heading .portfolio-wrapper-heading-title {
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  z-index: 10;
  color: #fff;
  font-size: 18px;
  line-height: 22px;
  width: 70%;
  padding-bottom: 15px;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .lazy {
  position: relative;
  border-radius: 4px;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .lazy::after {
  content: '';
  display: block;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("../../images/root/work/grid-grey-layer.png");
  background-repeat: repeat;
  background-size: 1px 190px;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .lazy .location {
  z-index: 3;
  position: absolute;
  bottom: 8px;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .lazy .location .fa {
  color: #ffffff;
  padding-top: 0px;
  font-size: 18px;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .lazy .location span {
  color: white;
  padding-left: 5px;
  font-size: 14px;
  text-transform: capitalize;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .bookmark {
  top: 0px;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .bookmark .review .fa-bookmark {
  z-index: 3;
  color: #f5c343;
  position: absolute;
  font-size: 50px;
  transform: scaleX(0.8);
  top: -2%;
  right: 5%;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .bookmark .review .fa-star {
  z-index: 3;
  color: #ffffff;
  position: absolute;
  font-size: 20px;
  right: 8.5%;
  top: 2%;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .bookmark .feature {
  position: absolute;
  top: -5px;
  right: 5%;
  z-index: 3;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .content {
  padding: 0 10px 15px 15px;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .content .title {
  color: #18293E;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
  line-height: 1.3;
  letter-spacing: 0;
  padding-bottom: 10px;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .content .rate .fa {
  color: #f5c343;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .content .services {
  margin-left: -4px;
  margin-top: 4px;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .content .services .service {
  margin-left: 4px;
  margin-bottom: 4px;
  background-color: #505961;
  display: inline-block;
  padding: 3px 8px;
  border-radius: 2px;
  color: rgba(255, 255, 255, 0.75);
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .content .services .service.hidden {
  display: none;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .content .divider {
  height: 1px;
  background-color: #edf1f3;
  margin-top: 10px;
  margin-bottom: 10px;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .content .divider-short {
  height: 1px;
  width: 10px;
  background-color: #868686;
  margin-top: 10px;
  margin-bottom: 10px;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .content .description {
  color: #737373;
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  line-height: 1.3;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .content .details .tags {
  color: #8b8b8b;
  font-size: 14px;
  padding-top: 10px;
  font-weight: 300;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .content .details .item-client-name {
  width: 65%;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .content .details .premium {
  width: 35%;
  text-align: right;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .content .details .premium span {
  color: #ffffff;
  background-color: #ee7781;
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 2px;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .content .details .pro {
  width: 35%;
  text-align: right;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .content .details .pro span {
  color: #ffffff;
  background-color: #071c2f;
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 2px;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .footer {
  background-color: #f9fafb;
  padding: 10px 50px 10px 15px;
  min-height: 46px;
  position: relative;
  border-radius: 5px;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .footer .services {
  display: block;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .footer .services .service {
  margin-right: 4px;
  margin-bottom: 4px;
  background-color: #97a1b0;
  display: inline-block;
  padding: 3px 8px;
  border-radius: 2px;
  color: rgba(255, 255, 255, 0.75);
  font-weight: 300;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .footer .services .service.hidden {
  display: none;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .footer .col-favourite {
  position: absolute;
  right: 10px;
  top: 10px;
  display: block;
  text-decoration: none;
  cursor: pointer;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .footer .col-favourite .fa {
  color: #28f2b2;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .footer .col-favourite .fa-heart-o {
  display: block;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .footer .col-favourite .fa-heart {
  display: none;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .footer .col-favourite.un-favourite .fa-heart-o {
  display: none;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .footer .col-favourite.un-favourite .fa-heart {
  display: block;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-portfolio .results:after {
  content: '';
  display: table;
  clear: both;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-portfolio .wrapper-btn-load-result {
  padding: 50px 0;
  display: none;
  text-align: center;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-portfolio .wrapper-btn-load-result .btn-load-result {
  padding: 15px 35px;
  display: inline-block;
  color: #FFFFFF;
  background-color: #18d197;
  line-height: 25px;
  border-radius: 28px;
  cursor: pointer;
  text-decoration: none;
  font-size: 18px;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-portfolio .wrapper-btn-load-result .btn-load-result:hover {
  background-color: #18e5aa;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-portfolio .wrapper-btn-load-result.show {
  display: block;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-service .tab-header {
  margin-left: -4px;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-service .tab-header a {
  margin-left: 4px;
  margin-bottom: 4px;
  background-color: #505961;
  border: 1px solid #505961;
  display: inline-block;
  padding: 3px 8px;
  border-radius: 2px;
  color: rgba(255, 255, 255, 0.75);
  text-decoration: none;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-service .tab-header a.opened {
  background-color: #fff;
  border: 1px solid #02cd8d;
  color: #02cd8d;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-service .tab-content {
  min-height: 420px;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-service .tab-content .service-content {
  display: none;
  padding-top: 20px;
  position: relative;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-service .tab-content .service-content .col-paragraph {
  position: relative;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-service .tab-content .service-content.opened {
  display: block;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-service .tab-content .chart-wrapper {
  position: relative;
  right: 0;
  top: 20px;
  padding-bottom: 400px;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-service .tab-content .chart-wrapper .chart-legends {
  margin: 0 auto -50px;
  position: relative;
  z-index: 1;
  max-width: 380px;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-service .tab-content .chart-wrapper .chart-legends .chart-legend {
  padding-left: 5px;
  padding-right: 5px;
  float: left;
  color: #565656;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-service .tab-content .chart-wrapper .chart-legends .chart-legend span {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin-right: 10px;
  display: inline-block;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-service .tab-content .chart-wrapper #serviceChart {
  position: absolute;
  bottom: 0;
  left: 0;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-skill .skills {
  margin-left: -4px;
  margin-top: 4px;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-skill .skills .skill {
  margin-left: 4px;
  margin-bottom: 4px;
  background-color: #505961;
  display: inline-block;
  padding: 3px 8px;
  border-radius: 2px;
  color: rgba(255, 255, 255, 0.75);
}

#professional-profile #professional-profile-right #professional-profile-content .box.green {
  background-color: #d8f1e6;
  border: 1px solid #FFFFFF;
}

#professional-profile #professional-profile-right #professional-profile-content .box.green .box-heading {
  border-bottom: 1px solid #FFFFFF;
}

#professional-profile #professional-profile-right #professional-profile-content .box.green .box-body {
  padding-left: 50px;
  padding-right: 50px;
}

#professional-profile #professional-profile-right #professional-profile-content .box.green .box-body .paragraph {
  padding-bottom: 0;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-focus {
  margin-bottom: 20px;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-focus .chart-title {
  color: #737373;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  text-align: center;
  font-weight: bold;
  z-index: 2;
  position: relative;
  margin-bottom: -40px;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-focus .chart-legends {
  margin: 0 auto 0;
  position: relative;
  z-index: 1;
  max-width: 380px;
  text-align: center;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-focus .chart-legends .chart-legend {
  padding-left: 5px;
  padding-right: 5px;
  display: inline-block;
  color: #565656;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-focus .chart-legends .chart-legend span {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin-right: 10px;
  display: inline-block;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-faq .panel-group {
  margin: 0;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-faq .panel-group .panel {
  box-shadow: none;
  border-radius: 0;
  border: none;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-faq .panel-group .panel .panel-heading {
  background: none;
  border: none;
  color: #02cd8d;
  padding: 0;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-faq .panel-group .panel .panel-heading h4 {
  font-size: 16px;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-faq .panel-group .panel .panel-heading h4 a {
  padding-top: 10px;
  padding-bottom: 10px;
  display: inline-block;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-faq .panel-group .panel .panel-heading h4 a:hover, #professional-profile #professional-profile-right #professional-profile-content .box.box-faq .panel-group .panel .panel-heading h4 a:focus {
  text-decoration: none;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-faq .panel-group .panel .panel-heading + .panel-collapse > .panel-body {
  border-top: 0;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-faq .panel-group .panel .panel-body {
  padding: 10px 0 10px;
  line-height: 1.5;
  font-family: "Roboto", sans-serif;
  color: #737373;
  font-size: 13px;
  position: relative;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-faq .panel-group .panel .panel-body p {
  margin-bottom: 10px;
}

#professional-profile #professional-profile-right #professional-profile-content .box.box-faq .panel-group .panel:not(:first-child) {
  border-top: 1px solid #d8f1e6;
}

#professional-profile #professional-profile-right #professional-profile-content .box button {
  width: 100%;
  text-transform: uppercase;
  border-bottom: 6px solid #235a9a;
  letter-spacing: 1px;
}

#professional-profile #professional-profile-right #professional-profile-content .box.colorful {
  position: relative;
  background-image: linear-gradient(to bottom right, #03cd8e, #7b93ba, #c86dd7);
  padding: 15px;
}

#professional-profile #professional-profile-right #professional-profile-content .box.colorful .bg-white {
  background-color: #FFFFFF;
  padding: 20px;
}

#professional-profile #professional-profile-right #professional-profile-content .box.colorful .bg-white span {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  letter-spacing: 1px;
  color: #02cd8d;
  margin-bottom: 10px;
  display: block;
  font-weight: bold;
}

#professional-profile #professional-profile-right #professional-profile-content .box.colorful .bg-white ul {
  display: block;
  padding: 0;
  margin: 0 0 15px;
  list-style: none;
}

#professional-profile #professional-profile-right #professional-profile-content .box.colorful .bg-white ul li {
  padding-top: 5px;
  padding-bottom: 5px;
  color: #888888;
  font-weight: 400;
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  letter-spacing: 1px;
}

#professional-profile #professional-profile-right #professional-profile-content .box.colorful .bg-white ul li img {
  vertical-align: middle;
  display: inline-block;
  margin-right: 10px;
}

#professional-profile #professional-profile-right #professional-profile-content .box.colorful button {
  width: 100%;
  text-transform: uppercase;
  border-bottom: 6px solid #178462;
  letter-spacing: 1px;
}

#professional-profile #professional-profile-right #professional-profile-content .box:not(:first-child) {
  margin-top: 20px;
}

.portfolio-popup-wrapper {
  width: 1270px;
  max-width: 100%;
  margin: 30px auto 0;
  background-color: #FFFFFF;
  border-radius: 8px;
  overflow: hidden;
  display: table;
}

.portfolio-popup-wrapper .portfolio-popup-left {
  display: table-cell;
  vertical-align: top;
  width: 33.3333%;
  background-color: #fafafa;
  border-right: 1px solid #cccccc;
  padding: 10px 30px;
  position: relative;
}

.portfolio-popup-wrapper .portfolio-popup-left .header-logo {
  position: absolute;
  right: 10px;
  top: 20px;
  width: 75px;
  height: 75px;
  display: block;
  background-color: #FFFFFF;
  padding: 3px;
  border-radius: 50%;
}

.portfolio-popup-wrapper .portfolio-popup-left .header-logo .image {
  border-radius: 50%;
  width: 69px;
  height: 69px;
  background-size: cover;
  background-position: center;
}

.portfolio-popup-wrapper .portfolio-popup-left .btn-group {
  margin-bottom: 25px;
  display: inline;
}

.portfolio-popup-wrapper .portfolio-popup-left .btn-group .btn-green:hover {
  text-decoration: none;
}

.portfolio-popup-wrapper .portfolio-popup-left .btn-group .btn-green span {
  padding: 10px;
  color: #02cd8d;
  background-color: #ffffff;
  border: 2px solid #02cd8d;
  text-decoration: none;
  border-radius: 2px;
}

.portfolio-popup-wrapper .portfolio-popup-left .box {
  padding: 50px 0 20px;
}

.portfolio-popup-wrapper .portfolio-popup-left .box .top-description {
  background-color: #e8fff8;
  padding: 20px;
  border: 2px solid #00e7ad;
  text-align: center;
  margin-bottom: 20px;
  position: relative;
}

.portfolio-popup-wrapper .portfolio-popup-left .box .top-description:before {
  content: "";
  position: absolute;
  bottom: -10px;
  left: calc(50% - 9px);
  width: 18px;
  height: 18px;
  border-bottom: 2px solid #00e7ad;
  transform: rotate(45deg);
  background: #e8fff8;
  border-right: 2px solid #00e7ad;
}

.portfolio-popup-wrapper .portfolio-popup-left .box .top-description .top-title {
  display: inline-flex;
}

.portfolio-popup-wrapper .portfolio-popup-left .box .top-description .top-title .svg-menu {
  width: 25px;
  height: 25px;
  margin-right: 5px;
}

.portfolio-popup-wrapper .portfolio-popup-left .box .top-description .top-title span {
  font-size: 18px;
  line-height: 25px;
}

.portfolio-popup-wrapper .portfolio-popup-left .box .btn-1 {
  text-align: left;
  color: #18293e;
  font-weight: bold;
  background-color: #00e7ad;
  width: 100%;
  padding: 15px 20px;
  display: block;
  font-size: 18px;
  margin-bottom: 20px;
  text-decoration: none;
}

.portfolio-popup-wrapper .portfolio-popup-left .box .btn-1.disabled {
  pointer-events: none;
  opacity: 0.4;
}

.portfolio-popup-wrapper .portfolio-popup-left .box .btn-1:hover {
  background-color: #02cd8d;
}

.portfolio-popup-wrapper .portfolio-popup-left .box .btn-1 span .fa {
  padding-right: 20px;
}

.portfolio-popup-wrapper .portfolio-popup-left .box .other-description span {
  font-weight: bold;
  font-size: 18px;
}

.portfolio-popup-wrapper .portfolio-popup-left .box .other-description span .fa {
  color: #00e7ad;
  padding-right: 10px;
}

.portfolio-popup-wrapper .portfolio-popup-left .vendor-info {
  padding-top: 30px;
}

.portfolio-popup-wrapper .portfolio-popup-left .vendor-info strong {
  margin-bottom: 20px;
  display: block;
  font-size: 18px;
  color: #444444;
}

.portfolio-popup-wrapper .portfolio-popup-left .vendor-info a {
  font-size: 14px;
  overflow: hidden;
  border-radius: 3px;
}

.portfolio-popup-wrapper .portfolio-popup-left .title {
  margin-top: 20px;
  color: #18293e;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
  letter-spacing: 1px;
}

.portfolio-popup-wrapper .portfolio-popup-left .title.profile {
  width: 60%;
  font-size: 20px;
  margin-top: 0px;
}

.portfolio-popup-wrapper .portfolio-popup-left .title.portfolio {
  display: inline;
  padding-right: 10%;
}

.portfolio-popup-wrapper .portfolio-popup-left .favorite {
  float: right;
  text-decoration: none;
  cursor: pointer;
}

.portfolio-popup-wrapper .portfolio-popup-left .favorite .fa {
  color: #28f2b2;
}

.portfolio-popup-wrapper .portfolio-popup-left .favorite .fa-heart-o {
  display: block;
}

.portfolio-popup-wrapper .portfolio-popup-left .favorite .fa-heart {
  display: none;
}

.portfolio-popup-wrapper .portfolio-popup-left .favorite.un-favourite .fa-heart-o {
  display: none;
}

.portfolio-popup-wrapper .portfolio-popup-left .favorite.un-favourite .fa-heart {
  display: block;
}

.portfolio-popup-wrapper .portfolio-popup-left .services {
  margin-left: -4px;
  margin-top: 4px;
  margin-bottom: 20px;
}

.portfolio-popup-wrapper .portfolio-popup-left .services .service {
  margin-left: 4px;
  margin-bottom: 4px;
  background-color: #505961;
  display: inline-block;
  padding: 3px 8px;
  border-radius: 2px;
  color: rgba(255, 255, 255, 0.75);
}

.portfolio-popup-wrapper .portfolio-popup-left .divider {
  height: 1.5px;
  background-color: #d9dddf;
  margin: 20px -30px;
}

.portfolio-popup-wrapper .portfolio-popup-left .description {
  color: #18293e;
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  line-height: 1.3;
  margin-bottom: 30px;
}

.portfolio-popup-wrapper .portfolio-popup-left .description .paragraph {
  color: #18293e;
}

.portfolio-popup-wrapper .portfolio-popup-left .description.with-icon {
  margin-bottom: 0px;
  padding-left: 30px;
  padding-bottom: 20px;
  position: relative;
}

.portfolio-popup-wrapper .portfolio-popup-left .description.with-icon strong {
  color: #444444;
}

.portfolio-popup-wrapper .portfolio-popup-left .description.with-icon img {
  position: absolute;
  left: 0;
  top: 0px;
  width: 16px;
}

.portfolio-popup-wrapper .portfolio-popup-right {
  display: table-cell;
  vertical-align: top;
  width: 66.6666%;
  border-right: 1px solid #e3e3e3;
  padding-top: 30px;
  padding-bottom: 30px;
}

.portfolio-popup-wrapper .portfolio-popup-right .cards {
  padding-top: 30px;
  padding-left: 50px;
  padding-right: 50px;
}

.portfolio-popup-wrapper .portfolio-popup-right .description {
  padding: 0 30px 10px;
  color: #18293e;
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  line-height: 1.3;
}

.portfolio-popup-wrapper .portfolio-popup-right .videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;
  margin-bottom: 30px;
}

.portfolio-popup-wrapper .portfolio-popup-right .videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.portfolio-popup-wrapper .portfolio-popup-right .imageWrapper {
  margin-bottom: 30px;
}

.portfolio-popup-wrapper .portfolio-popup-right .imageWrapper img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
}

.portfolio-popup-wrapper .portfolio-popup-right .buttonWrapper a {
  font-size: 14px;
  overflow: hidden;
  border-radius: 3px;
}

.portfolio-popup-wrapper .portfolio-popup-right .btn-group {
  text-align: center;
  margin-bottom: 25px;
  display: block;
}

.portfolio-popup-wrapper .portfolio-popup-right .btn-group .btn-green:hover {
  text-decoration: none;
}

.portfolio-popup-wrapper .portfolio-popup-right .btn-group .btn-green span {
  padding: 10px 30px;
  font-size: 15px;
  color: #ffffff;
  background-color: #02cd8d;
  text-decoration: none;
  border-radius: 2px;
}

.portfolio-popup-wrapper .card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
  padding: 20px;
  margin-bottom: 20px;
}

.portfolio-popup-wrapper .card .card-title {
  font-weight: bold;
  color: #333333;
  font-size: 14px;
}

.portfolio-popup-wrapper .card .card-body {
  padding-top: 20px;
}

.portfolio-popup-wrapper .card .card-body .tags {
  margin-left: -3px;
  margin-right: -3px;
}

.portfolio-popup-wrapper .card .card-body .tags .tag {
  float: left;
  padding-left: 3px;
  padding-right: 3px;
  margin-bottom: 6px;
}

.portfolio-popup-wrapper .card .card-body .tags .tag span {
  padding: 8px 15px;
  line-height: 1.2;
  background: #f9f9f9;
  background: -webkit-gradient(left top, left bottom, color-stop(0%, #f9f9f9), color-stop(100%, #e1e3e3));
  background: linear-gradient(to bottom, #f9f9f9 0%, #e1e3e3 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f9f9f9', endColorstr='#e1e3e3', GradientType=0);
  border: 1px solid #e1e3e3;
  display: block;
  color: #6c737a;
  font-size: 12px;
}

.portfolio-popup-wrapper .card .card-body .tags:after {
  content: "";
  display: table;
  clear: both;
}

#popup-login-to-review {
  background-color: #fff;
  border-radius: 4px;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

#popup-login-to-review .content-header {
  background: #02cd8d;
  padding: 15px 20px;
  position: relative;
}

#popup-login-to-review .content-header .title {
  font-size: 14px;
  color: #fffffc;
  line-height: 1;
  text-align: center;
}

#popup-login-to-review .content-header .cross {
  width: 20px;
  height: 20px;
  cursor: pointer;
  background: url("../../images/root/client/reference/popup-cross.png") center center no-repeat #02cd8d;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translate(0, -50%);
}

#popup-login-to-review .content-body {
  padding: 20px 35px;
}

#popup-login-to-review .content-footer {
  padding-bottom: 15px;
}

#popup-login-to-review .content-footer a {
  background-color: #ebebeb;
  padding: 15px 20px;
  color: #02cd8d;
  border: none;
  display: block;
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  transition: background-color .3s;
  text-align: center;
  text-decoration: none;
}

#popup-login-to-review .content-footer a:hover {
  text-decoration: none;
  background-color: #f0f0f0;
}

#popup-login-to-review .mfp-close {
  display: none;
}

@media only screen and (max-width: 991px) {
  #professional-profile {
    padding-top: 30px;
  }
  #professional-profile .container-row {
    display: block;
  }
  #professional-profile .container-row > div {
    display: block;
  }
  #professional-profile #professional-profile-left {
    width: 100%;
    float: none;
    margin-bottom: 30px;
    border-radius: 0;
    display: block;
  }
  #professional-profile #professional-profile-left .box:not(.box-header) {
    padding: 20px 27px;
    float: left;
    width: 50%;
  }
  #professional-profile #professional-profile-left .box .type.type-location {
    width: 100%;
  }
  #professional-profile #professional-profile-left:after {
    content: '';
    display: block;
    clear: both;
  }
  #professional-profile #professional-profile-right {
    width: 100%;
    float: none;
  }
  #professional-profile #professional-profile-right #professional-profile-header .tab-header {
    padding: 0 20px;
    width: 100% !important;
  }
  #professional-profile #professional-profile-right #professional-profile-content {
    padding: 20px;
  }
  #professional-profile #professional-profile-right #professional-profile-content .review-page-title {
    padding: 12px 10px;
    width: 100%;
  }
  #professional-profile #professional-profile-right #professional-profile-content .box.box-service .tab-content .chart-wrapper #serviceChart {
    width: 100% !important;
    height: 260px !important;
  }
  #professional-profile #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper {
    width: 50%;
  }
  .portfolio-popup-wrapper {
    width: 80%;
  }
  .portfolio-popup-wrapper .portfolio-popup-left {
    float: none;
    display: block;
    width: 100%;
  }
  .portfolio-popup-wrapper .portfolio-popup-left .description {
    margin-bottom: 10px;
  }
  .portfolio-popup-wrapper .portfolio-popup-right {
    display: block;
    float: none;
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  #professional-profile .container {
    padding-left: 0;
    padding-right: 0;
  }
  #professional-profile #professional-profile-left .box {
    padding: 10px 10px;
  }
  #professional-profile #professional-profile-left .box:not(.box-header) {
    float: none;
    width: 100%;
  }
  #professional-profile #professional-profile-left .box.box-header .btn-effect-1 {
    line-height: 35px;
    height: 38px;
    font-size: 14px;
  }
  #professional-profile #professional-profile-left .box .type.type-location {
    width: 100%;
  }
  #professional-profile #professional-profile-right #professional-profile-header .tab-header {
    width: 100% !important;
    padding: 0;
    display: block;
  }
  #professional-profile #professional-profile-right #professional-profile-header .tab-header .tab {
    padding: 10px 0;
    margin: 0;
    font-size: 12px;
    letter-spacing: normal;
    display: block;
    text-align: center;
    float: left;
    width: 50%;
  }
  #professional-profile #professional-profile-right #professional-profile-header .box .table-row .col-right .name {
    padding-right: 25px;
  }
  #professional-profile #professional-profile-right #professional-profile-header .box .table-row .col-right .info .tag {
    margin-left: 0px;
  }
  #professional-profile #professional-profile-right #professional-profile-header .box .table-row .col-right .info .tag:first-child {
    margin-right: 20px;
    margin-bottom: 5px;
  }
  #professional-profile #professional-profile-right #professional-profile-header .box .table-row .col-right .info .tag:not(:first-child) {
    margin-left: 0;
  }
  #professional-profile #professional-profile-right #professional-profile-header .badges {
    right: 0;
    left: 85%;
    transform: translateX(-85%);
    display: inline-flex;
  }
  #professional-profile #professional-profile-right #professional-profile-header .badges .svg-menu img {
    width: 30px;
  }
  #professional-profile #professional-profile-right #professional-profile-content {
    padding: 0;
  }
  #professional-profile #professional-profile-right #professional-profile-content .box {
    margin-bottom: 0;
    border: none;
  }
  #professional-profile #professional-profile-right #professional-profile-content .box .box-heading {
    padding: 15px 20px;
  }
  #professional-profile #professional-profile-right #professional-profile-content .box .box-body .table-info {
    padding: 0;
  }
  #professional-profile #professional-profile-right #professional-profile-content .box .box-body .table-info .table-row {
    display: block;
    width: 100%;
  }
  #professional-profile #professional-profile-right #professional-profile-content .box .box-body .table-info .table-row [class^=col] {
    display: block;
    width: 100% !important;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  #professional-profile #professional-profile-right #professional-profile-content .box .box-body .table-info .table-row [class^=col].col-right {
    border-left: none;
    border-top: 1px solid #d4f0e3;
  }
  #professional-profile #professional-profile-right #professional-profile-content .box .box-body .table-info .table-row [class^=col].col-middle .info-row .info-label {
    width: 50%;
    display: block;
  }
  #professional-profile #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper {
    width: 100%;
    padding: 15px 20px 20px;
    border-bottom: 1px solid #dbdbdb;
  }
  #professional-profile #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio {
    box-shadow: none;
  }
  #professional-profile #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .portfolio-wrapper-heading {
    padding-bottom: 50%;
  }
  #professional-profile #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .portfolio-wrapper-heading .portfolio-wrapper-heading-title {
    width: 65%;
    font-size: 14px;
    line-height: 14px;
  }
  #professional-profile #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .lazy {
    width: 45%;
    display: block;
    float: left;
  }
  #professional-profile #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .lazy .location {
    padding-left: 5px;
    padding-right: 0px;
  }
  #professional-profile #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .lazy .location .pull-left {
    width: 65%;
  }
  #professional-profile #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .lazy .location .fa {
    padding-top: 0;
  }
  #professional-profile #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .lazy .location .fa.fa-map-marker {
    display: none;
  }
  #professional-profile #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .lazy .location .fa.fa-youtube-play {
    padding-right: 5px;
  }
  #professional-profile #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .lazy .location span {
    padding-left: 0px;
    font-size: 12px;
  }
  #professional-profile #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .bookmark .feature {
    right: 3%;
  }
  #professional-profile #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .bookmark .feature img {
    width: 20px;
  }
  #professional-profile #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .content {
    width: 55%;
    display: block;
    float: left;
    font-size: 12px;
    padding: 0 0 0 10px;
  }
  #professional-profile #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .content .title {
    margin-top: 0;
    font-size: 12px;
    line-height: 1.2;
  }
  #professional-profile #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .content .details .item-client-name {
    font-size: 12px;
    line-height: 1.2;
  }
  #professional-profile #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .content .details .tags {
    font-size: 12px;
    line-height: 1.2;
  }
  #professional-profile #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .footer {
    padding: 10px 50px 10px 0px;
    background-color: initial;
    display: block;
    float: left;
    width: 100%;
  }
  #professional-profile #professional-profile-right #professional-profile-content > .row {
    margin: 0;
  }
  #professional-profile #professional-profile-right #professional-profile-content .review-page-title {
    padding: 12px 10px;
    line-height: 25px;
    padding-top: 10px;
  }
  #professional-profile #professional-profile-right #professional-profile-content .review-page-title .title {
    line-height: 25px;
    margin-bottom: 10px;
    font-size: 10px;
    padding-top: 10px;
  }
  #professional-profile #professional-profile-right #professional-profile-content .review-page-title .case {
    font-size: 10px;
    line-height: 30px;
    margin-left: 7px;
    padding-top: 10px;
  }
  #professional-profile #professional-profile-right #professional-profile-content .review-page-title .btn-wrapper .btn-effect-1 {
    padding: 10px 10px 13px;
    font-size: 12px;
  }
  .portfolio-popup-wrapper .portfolio-popup-left {
    padding: 10px 15px;
    position: relative;
  }
  .portfolio-popup-wrapper .portfolio-popup-left .header-logo {
    position: absolute;
    left: 5%;
    top: 15px;
  }
  .portfolio-popup-wrapper .portfolio-popup-left .title {
    font-size: 12px;
    margin-bottom: 15px;
  }
  .portfolio-popup-wrapper .portfolio-popup-left .title.profile {
    width: 100%;
    font-size: 14px;
    padding-left: 90px;
    margin-top: 15px;
  }
  .portfolio-popup-wrapper .portfolio-popup-left .title.portfolio {
    padding-right: 20%;
  }
  .portfolio-popup-wrapper .portfolio-popup-left .btn-group {
    padding-left: 90px;
    width: 100%;
    display: inline-block;
    margin-bottom: 10px;
  }
  .portfolio-popup-wrapper .portfolio-popup-left .btn-group .btn-green span {
    padding: 5px 12px;
    font-size: 10px;
  }
  .portfolio-popup-wrapper .portfolio-popup-left .box {
    padding: 30px 0 15px;
  }
  .portfolio-popup-wrapper .portfolio-popup-left .box .top-description .top-title span {
    font-size: 14px;
  }
  .portfolio-popup-wrapper .portfolio-popup-left .box .top-description .content {
    font-size: 12px;
  }
  .portfolio-popup-wrapper .portfolio-popup-left .box .btn-1 {
    font-size: 14px;
  }
  .portfolio-popup-wrapper .portfolio-popup-left .box .other-description span {
    font-size: 14px;
  }
  .portfolio-popup-wrapper .portfolio-popup-left .description {
    font-size: 10px;
  }
  .portfolio-popup-wrapper .portfolio-popup-left .description .paragraph {
    font-size: 11px;
  }
  .portfolio-popup-wrapper .portfolio-popup-left .description .paragraph .showMore {
    font-size: 10px;
    padding-bottom: 10px;
  }
  .portfolio-popup-wrapper .portfolio-popup-left .description.with-icon {
    margin-bottom: 10px;
  }
  .portfolio-popup-wrapper .portfolio-popup-left .description.with-icon strong {
    color: #444444;
  }
  .portfolio-popup-wrapper .portfolio-popup-left .description.with-icon img {
    display: inline-block;
    margin-right: 5px;
    vertical-align: middle;
  }
  .portfolio-popup-wrapper .portfolio-popup-right .cards {
    padding-left: 10px;
    padding-right: 10px;
  }
  .portfolio-popup-wrapper .portfolio-popup-right .btn-group .btn-green span {
    padding: 10px;
    font-size: 13px;
  }
  .portfolio-popup-wrapper .card .card-body .tags .tag span {
    padding: 8px 10px;
    font-size: 12px;
  }
}

@media only screen and (max-width: 370px) {
  #professional-profile #professional-profile-right #professional-profile-content .review-page-title .btn-wrapper {
    width: 100%;
  }
}

#project-breadcrumb {
  background-color: #18283e;
  color: #FFFFFF;
  padding: 5px 0;
  text-align: center;
}

#project-progress-wrapper {
  background-color: #f0f4f7;
  color: #585858;
  padding-top: 40px;
  padding-bottom: 40px;
}

#project-progress-wrapper .wrapper-heading {
  font-size: 24px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  line-height: 1;
  font-weight: normal;
}

#project-progress-wrapper .wrapper-heading span {
  font-weight: bold;
  display: block;
  font-size: 22px;
  margin-top: 10px;
}

#project-progress-wrapper .wrapper-body {
  text-align: center;
}

#project-progress-wrapper .wrapper-body h5 {
  font-size: 26px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  line-height: 1;
  font-weight: bold;
}

#project-progress-wrapper .wrapper-body .progresses {
  position: relative;
  width: 700px;
  height: 25px;
  margin: 40px auto;
}

#project-progress-wrapper .wrapper-body .progresses [class^=progress] {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #FFFFFF;
}

#project-progress-wrapper .wrapper-body .progresses .progress-1 {
  left: 0;
}

#project-progress-wrapper .wrapper-body .progresses .progress-2 {
  left: 33.33%;
}

#project-progress-wrapper .wrapper-body .progresses .progress-3 {
  left: 66.66%;
}

#project-progress-wrapper .wrapper-body .progresses .progress-4 {
  left: 100%;
}

#project-progress-wrapper .wrapper-body .progresses:before {
  height: 3px;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  background-color: #FFFFFF;
  content: '';
  display: block;
  transition: width .5s;
}

#project-progress-wrapper .wrapper-body .progresses:after {
  height: 3px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  background-color: #02cd8d;
  content: '';
  display: block;
  transition: width .5s;
}

#project-progress-wrapper .wrapper-body .progresses.current-1:after {
  width: 0;
}

#project-progress-wrapper .wrapper-body .progresses.current-1 .progress-1 {
  background-color: #02cd8d;
}

#project-progress-wrapper .wrapper-body .progresses.current-2:after {
  width: 33.33%;
}

#project-progress-wrapper .wrapper-body .progresses.current-2 .progress-1,
#project-progress-wrapper .wrapper-body .progresses.current-2 .progress-2 {
  background-color: #02cd8d;
}

#project-progress-wrapper .wrapper-body .progresses.current-3:after {
  width: 66.66%;
}

#project-progress-wrapper .wrapper-body .progresses.current-3 .progress-1,
#project-progress-wrapper .wrapper-body .progresses.current-3 .progress-2,
#project-progress-wrapper .wrapper-body .progresses.current-3 .progress-3 {
  background-color: #02cd8d;
}

#project-progress-wrapper .wrapper-body .progresses.current-4:after {
  width: 100%;
}

#project-progress-wrapper .wrapper-body .progresses.current-4 .progress-1,
#project-progress-wrapper .wrapper-body .progresses.current-4 .progress-2,
#project-progress-wrapper .wrapper-body .progresses.current-4 .progress-3,
#project-progress-wrapper .wrapper-body .progresses.current-4 .progress-4 {
  background-color: #02cd8d;
}

#project-form-wrapper {
  background-color: #fafafa;
  padding-top: 40px;
  padding-bottom: 40px;
}

#project-form-wrapper .container {
  max-width: 990px;
}

#project-form-wrapper .box {
  padding: 0;
  background-color: #FFFFFF;
  border: 1px solid #d8f1e6;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s .5s, opacity .5s linear;
  height: 0;
  overflow: hidden;
}

#project-form-wrapper .box.active {
  overflow: visible;
  height: auto;
  visibility: visible;
  opacity: 1;
  transition: opacity .5s linear;
}

#project-form-wrapper .box .box-heading {
  padding: 20px 25px;
  border-bottom: 1px solid #d8f1e6;
  color: #333333;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: normal;
}

#project-form-wrapper .box .box-heading .bullet {
  display: inline-block;
  margin-right: 10px;
  color: #FFFFFF;
  background-color: #02cd8d;
  height: 34px;
  width: 34px;
  text-align: center;
  line-height: 34px;
  font-size: 15px;
  border-radius: 50%;
}

#project-form-wrapper .box .box-body .select2-dropdown.select2-dropdown--below {
  width: 180px;
}

#project-form-wrapper .box .box-body .form-group {
  padding: 20px 25px;
  color: #333333;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

#project-form-wrapper .box .box-body .form-group:after {
  content: "";
  display: table;
  clear: both;
}

#project-form-wrapper .box .box-body .form-group > label {
  color: #333333;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}

#project-form-wrapper .box .box-body .form-group label.addMore {
  color: #02cd8d;
  line-height: 20px;
  font-size: 14px !important;
  margin-top: 12px !important;
}

#project-form-wrapper .box .box-body .form-group label.addMore i {
  display: inline-block;
  margin-right: 5px;
  font-style: normal;
}

#project-form-wrapper .box .box-body .form-group .checkboxes input[type=checkbox] {
  visibility: hidden;
  opacity: 0;
}

#project-form-wrapper .box .box-body .form-group .checkboxes label {
  display: block;
  margin: 0 0 10px;
  font-size: 12px;
  cursor: pointer;
  padding-left: 30px;
  position: relative;
}

#project-form-wrapper .box .box-body .form-group .checkboxes label span {
  display: inline-block;
  vertical-align: middle;
}

#project-form-wrapper .box .box-body .form-group .checkboxes label span.checkbox {
  position: absolute;
  left: 0;
  top: 0;
  border: 1px solid #d4f0e3;
  background-color: #d8f1e6;
  border-radius: 2px;
  margin: 0 10px 0 0;
  width: 20px;
  height: 20px;
  transition: background-color .3s;
}

#project-form-wrapper .box .box-body .form-group .checkboxes label span.checkbox input {
  visibility: hidden;
  opacity: 0;
}

#project-form-wrapper .box .box-body .form-group .checkboxes label.checked span.checkbox {
  background-color: #FFFFFF;
  border: 1px solid #02cd8d;
}

#project-form-wrapper .box .box-body .form-group .checkboxes label.checked span.checkbox:before {
  top: 50%;
  left: 50%;
  content: '';
  position: absolute;
  height: 6px;
  width: 15px;
  border-left: 2px solid #02cd8d;
  border-bottom: 2px solid #02cd8d;
  display: block;
  margin: 0 auto;
  transform: translate(-50%, -55%) rotate(-45deg);
  -webkit-transform: translate(-50%, -55%) rotate(-45deg);
  -moz-transform: translate(-50%, -55%) rotate(-45deg);
  -ms-transform: translate(-50%, -55%) rotate(-45deg);
  -o-transform: translate(-50%, -55%) rotate(-45deg);
}

#project-form-wrapper .box .box-body .form-group .checkboxes label.checked.disabled span.checkbox {
  background-color: #eeeeee;
  border: 1px solid #dadada;
}

#project-form-wrapper .box .box-body .form-group .checkboxes label.checked.disabled span.checkbox:before {
  top: 50%;
  left: 50%;
  content: '';
  position: absolute;
  height: 6px;
  width: 15px;
  border-left: 2px solid #02CD8D;
  border-bottom: 2px solid #02CD8D;
  display: block;
  margin: 0 auto;
  transform: translate(-50%, -55%) rotate(-45deg);
  -webkit-transform: translate(-50%, -55%) rotate(-45deg);
  -moz-transform: translate(-50%, -55%) rotate(-45deg);
  -ms-transform: translate(-50%, -55%) rotate(-45deg);
  -o-transform: translate(-50%, -55%) rotate(-45deg);
}

#project-form-wrapper .box .box-body .form-group .checkboxes label:hover:not(.disabled):not(.checked) span.checkbox {
  background-color: #d4f0e3;
}

#project-form-wrapper .box .box-body .form-group .checkboxes label:hover:not(.disabled).checked span.checkbox {
  background-color: #d8f1e6;
}

#project-form-wrapper .box .box-body .form-group .checkboxes label:hover:not(.disabled).checked span.checkbox:before {
  border-left: 2px solid #02cd8d;
  border-bottom: 2px solid #02cd8d;
}

#project-form-wrapper .box .box-body .form-group .checkboxes input[type=text] {
  display: inline-block;
  width: auto;
}

#project-form-wrapper .box .box-body .form-group .checkboxes .checkbox-group {
  margin-bottom: 10px;
}

#project-form-wrapper .box .box-body .form-group .checkboxes .checkbox-group .checkbox-group {
  padding-left: 35px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s .5s, opacity .5s linear;
  height: 0;
  overflow: hidden;
  margin-bottom: 0;
}

#project-form-wrapper .box .box-body .form-group .checkboxes .checkbox-group .checkbox-group.active {
  margin-bottom: 10px;
  overflow: visible;
  height: auto;
  visibility: visible;
  opacity: 1;
  transition: opacity .5s linear;
}

#project-form-wrapper .box .box-body .form-group .checkboxes .checkbox-group .extend {
  display: inline-block;
  margin-left: 10px;
  color: #02cd8d;
  font-size: 14px;
  font-weight: bold;
}

#project-form-wrapper .box .box-body .form-group .radios input[type=radio] {
  visibility: hidden;
  opacity: 0;
}

#project-form-wrapper .box .box-body .form-group .radios label {
  display: block;
  padding-left: 35px;
  position: relative;
  margin: 0 0 10px;
  font-size: 12px;
  cursor: pointer;
}

#project-form-wrapper .box .box-body .form-group .radios label span {
  display: inline-block;
  vertical-align: middle;
}

#project-form-wrapper .box .box-body .form-group .radios label span.radio {
  position: absolute;
  left: 0;
  top: 0;
  border: 2px solid #d4f0e3;
  background-color: #d8f1e6;
  border-radius: 50%;
  margin: 0 10px 0 0;
  width: 20px;
  height: 20px;
  transition: background-color .3s;
}

#project-form-wrapper .box .box-body .form-group .radios label span.radio input {
  visibility: hidden;
  opacity: 0;
}

#project-form-wrapper .box .box-body .form-group .radios label.checked span.radio {
  background-color: #02cd8d;
}

#project-form-wrapper .box .box-body .form-group .radios input[type=text] {
  display: inline-block;
  width: auto;
}

#project-form-wrapper .box .box-body .form-group textarea {
  width: 100%;
  outline: none;
  font-size: 12px;
  padding: 15px;
  border: 1px solid #e1e3e3;
  border-radius: 4px;
}

#project-form-wrapper .box .box-body .form-group .label-file label {
  font-size: 14px;
  padding: 5px 15px 11px;
  margin-right: 20px;
  position: relative;
  border-radius: 3px;
  background-color: #02cd8d;
  cursor: pointer;
  overflow: hidden;
}

#project-form-wrapper .box .box-body .form-group .label-file label:after {
  position: absolute;
  bottom: 0;
  height: 6px;
  left: 0;
  right: 0;
  background-color: #0d513b;
  transition: height .3s;
  content: '';
  display: block;
  z-index: 1;
}

#project-form-wrapper .box .box-body .form-group .label-file label span {
  position: relative;
  z-index: 2;
  color: #FFFFFF;
}

#project-form-wrapper .box .box-body .form-group .label-file label:hover:after {
  height: 100%;
}

#project-form-wrapper .box .box-body .form-group .label-file input[type=file] {
  display: none;
}

#project-form-wrapper .box .box-body .form-group .label-file input[type=text] {
  border: none;
  padding: 0;
}

#project-form-wrapper .box .box-body .form-group select,
#project-form-wrapper .box .box-body .form-group input[type=text],
#project-form-wrapper .box .box-body .form-group input[type=email],
#project-form-wrapper .box .box-body .form-group input[type=password] {
  outline: 0;
  padding: 7px 15px;
  border: 1px solid #e1e3e3;
  border-radius: 2px;
  display: block;
  width: 100%;
  height: 33px;
  transition: border .3s;
}

#project-form-wrapper .box .box-body .form-group select:focus,
#project-form-wrapper .box .box-body .form-group input[type=text]:focus,
#project-form-wrapper .box .box-body .form-group input[type=email]:focus,
#project-form-wrapper .box .box-body .form-group input[type=password]:focus {
  border: 1px solid #8d989e;
}

#project-form-wrapper .box .box-body .form-group .phone {
  margin-top: -10px;
}

#project-form-wrapper .box .box-body .form-group .col-sm-4 {
  padding-left: 15px;
  padding-right: 15px;
}

#project-form-wrapper .box .box-body .form-group .col-md-4 {
  padding-left: 0px;
  padding-right: 0px;
}

#project-form-wrapper .box .box-body .form-group:not(:first-child) {
  border-top: 1px solid #d8f1e6;
}

#project-form-wrapper .box .box-body .form-group .positioning .slider-row {
  padding-top: 15px;
}

#project-form-wrapper .box .box-body .form-group .positioning .slider-row:first-child {
  padding-top: 0;
}

#project-form-wrapper .box .box-body .form-group .positioning .irs-single {
  visibility: hidden !important;
}

#project-form-wrapper .box .box-body .form-group .positioning .irs-min,
#project-form-wrapper .box .box-body .form-group .positioning .irs-max {
  font-weight: bold;
  color: #000000;
  bottom: 21px;
  position: absolute;
  background: transparent;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  letter-spacing: 1px;
  top: initial;
  margin-top: -10px;
  line-height: 1.3;
  visibility: visible !important;
}

#project-form-wrapper .box .box-body .form-group .positioning .irs-bar,
#project-form-wrapper .box .box-body .form-group .positioning .irs-bar-edge {
  background-color: transparent !important;
}

#project-form-wrapper .box .box-body .form-group .positioning .irs-slider {
  top: 21px;
  border: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #02cd8d;
}

#project-form-wrapper .box .box-body .form-group .positioning .irs-slider:before {
  display: none;
}

#project-form-wrapper .box .box-body .form-group .positioning .irs-slider:after {
  display: none;
}

#project-form-wrapper .box .box-body .form-group.has-error > label {
  color: #D8000C;
}

#project-form-wrapper .box .box-body .form-group.package-questions {
  transition: opacity .4s;
  opacity: 0;
  display: none;
  visibility: hidden;
}

#project-form-wrapper .box .box-body .form-group.package-questions.active {
  opacity: 1;
  display: block;
  visibility: visible;
}

#project-form-wrapper .box .box-body .form-group.form-group-disabled {
  display: none;
}

#project-form-wrapper .box .box-body .button-group {
  padding: 20px 25px;
  text-align: right;
  margin-top: 20px;
}

#project-form-wrapper .box .box-body .button-group button {
  min-width: 150px;
  border-radius: 2px;
}

#project-form-wrapper .box .box-body .button-group .pull-left.btn-back {
  color: #02cd8d;
  cursor: pointer;
  display: inline-block;
  position: relative;
  padding: 0 5px 5px;
}

#project-form-wrapper .box .box-body .tabs {
  width: 760px;
  margin: 40px auto 0;
  background-color: #FFFFFF;
  border: 1px solid #8d989e;
}

#project-form-wrapper .box .box-body .tabs .heading a {
  width: 50%;
  float: left;
  display: block;
  padding: 10px;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: normal;
  color: #333333;
  cursor: pointer;
  background-color: #8d989e;
  text-align: center;
}

#project-form-wrapper .box .box-body .tabs .heading a.active {
  background-color: #FFFFFF;
}

#project-form-wrapper .box .box-body .tabs .heading a:hover {
  text-decoration: none;
}

#project-form-wrapper .box .box-body .tabs .body {
  padding: 30px 75px;
}

#project-form-wrapper .box .box-body .tabs .body .form-group {
  margin-bottom: 20px;
  padding: 0;
}

#project-form-wrapper .box .box-body .tabs .body .form-group label {
  margin-bottom: 10px;
  display: block;
}

#project-form-wrapper .box .box-body .tabs .body .form-group input {
  margin-bottom: 5px;
}

#project-form-wrapper .box .box-body .tabs .body .form-group a.link {
  color: #8d989e;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
}

#project-form-wrapper .box .box-body .tabs .body .button-group {
  margin-bottom: 20px;
  padding: 0;
  text-align: center;
}

#project-form-wrapper .box .box-body .tabs .body .button-group .btn-type-1 {
  width: 100%;
}

#project-form-wrapper .box .box-body .tabs .body .button-group .btn-linkedin {
  border: none;
  display: inline-block;
  border-radius: 5px;
  background-color: #017ab6;
  padding: 10px 20px;
  color: #FFFFFF;
  font-size: 16px;
  transition: background-color .3s;
}

#project-form-wrapper .box .box-body .tabs .body .button-group .btn-linkedin .fa {
  font-size: 20px;
  display: inline-block;
  margin-right: 15px;
}

#project-form-wrapper .box .box-body .tabs .body .button-group .btn-linkedin:hover {
  background-color: #01649b;
}

#project-form-wrapper .box .box-body .tabs .body .login-body,
#project-form-wrapper .box .box-body .tabs .body .register-body {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s .5s, opacity .5s linear;
  height: 0;
  overflow: hidden;
}

#project-form-wrapper .box .box-body .tabs .body .login-body.active,
#project-form-wrapper .box .box-body .tabs .body .register-body.active {
  overflow: visible;
  height: auto;
  visibility: visible;
  opacity: 1;
  transition: opacity .5s linear;
}

#project-form-wrapper .box .box-body .tabs .body .divider-or {
  text-align: center;
  position: relative;
  margin-bottom: 20px;
}

#project-form-wrapper .box .box-body .tabs .body .divider-or span {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #FFFFFF;
  position: relative;
  z-index: 2;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  color: #8d989e;
}

#project-form-wrapper .box .box-body .tabs .body .divider-or:after {
  content: '';
  width: 100%;
  height: 1px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, 0);
  background-color: #d8f1e6;
}

#project-form-wrapper .notification {
  padding: 15px;
  display: block;
  max-width: 720px;
  width: 100%;
  margin: 0 auto 15px;
}

#popup-submit-client-info, #popup-login-info {
  padding: 60px;
  background-color: #ffffff;
  width: 550px;
  margin: 0 auto;
}

#popup-submit-client-info .title, #popup-login-info .title {
  font-size: 18px;
  font-weight: bold;
}

#popup-submit-client-info .description, #popup-login-info .description {
  font-size: 16px;
}

#popup-submit-client-info .description .notification, #popup-login-info .description .notification {
  margin-top: 10px;
  background-color: #FF5D54;
}

#popup-submit-client-info .body, #popup-login-info .body {
  padding: 35px 0 20px 0;
}

#popup-submit-client-info .body .email-row .col-sm-7, #popup-submit-client-info .body .phone-row .col-sm-7, #popup-login-info .body .email-row .col-sm-7, #popup-login-info .body .phone-row .col-sm-7 {
  padding-left: 0px;
}

#popup-submit-client-info .body .phone-row .col-sm-5, #popup-login-info .body .phone-row .col-sm-5 {
  position: relative;
}

#popup-submit-client-info .body .phone-row .col-sm-5 select, #popup-login-info .body .phone-row .col-sm-5 select {
  -webkit-appearance: none;
  appearance: none;
  padding-right: 30px;
}

#popup-submit-client-info .body .phone-row .col-sm-5 .fa-angle-down, #popup-login-info .body .phone-row .col-sm-5 .fa-angle-down {
  color: #02cd8d;
  position: absolute;
  right: 25px;
  top: 50%;
  font-size: 20px;
  color: #28f2b2;
  transform: translateY(-50%);
}

#popup-submit-client-info .body .helper, #popup-login-info .body .helper {
  padding-bottom: 15px;
  text-align: right;
}

#popup-submit-client-info .body .helper span, #popup-login-info .body .helper span {
  color: #02cd8d;
}

#popup-submit-client-info .body .terms, #popup-login-info .body .terms {
  padding: 0 10px;
  font-size: 14px;
}

#popup-submit-client-info .body .terms a, #popup-login-info .body .terms a {
  color: #00cd8d;
  text-decoration: none;
}

#popup-submit-client-info .btn-group, #popup-login-info .btn-group {
  display: block;
  padding-bottom: 15px;
}

#popup-submit-client-info .btn-group .submit, #popup-login-info .btn-group .submit {
  font-size: 14px;
  background-color: #02cd8d;
  text-align: center;
  width: 100%;
  color: #ffffff;
  text-decoration: none;
  padding: 15px 0;
  display: block;
  cursor: pointer;
  border-radius: 2px;
  border: none;
}

#popup-submit-client-info .forgot-password, #popup-login-info .forgot-password {
  text-align: right;
}

#popup-submit-client-info .forgot-password a, #popup-login-info .forgot-password a {
  text-decoration: none;
  color: #28f1b1;
}

#popup-submit-client-info .other_description, #popup-login-info .other_description {
  padding-top: 20px;
}

#popup-submit-client-info .other_description a, #popup-login-info .other_description a {
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  color: #00cd8d;
}

@media only screen and (max-width: 992px) {
  #project-form-wrapper .tabs {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }
  #project-form-wrapper .box .box-body .form-group .irs-with-grid .irs-grid .irs-grid-text {
    font-size: 10px;
  }
  #project-form-wrapper .box .box-body .form-group .irs-with-grid .irs-grid .js-grid-text-0 {
    margin-left: -10.8164%;
  }
}

@media only screen and (max-width: 767px) {
  #project-form-wrapper .box .box-heading {
    padding: 20px 15px;
    font-size: 16px;
  }
  #project-form-wrapper .box .box-body .form-group.quality .irs-with-grid .irs-grid .irs-grid-text.js-grid-text-0 {
    left: 8% !important;
  }
  #project-form-wrapper .box .box-body .form-group.quality .irs-with-grid .irs-grid .irs-grid-text.js-grid-text-1 {
    display: none;
  }
  #project-form-wrapper .box .box-body .tabs {
    width: 100%;
  }
  #project-form-wrapper .box .box-body .tabs .body {
    padding: 50px 0;
  }
  #project-form-wrapper .box .box-body .tabs .body .g-recaptcha {
    transform: scale(0.7);
    transform-origin: 0 0;
  }
  #project-progress-wrapper .wrapper-body .progresses {
    width: 100%;
  }
  #popup-submit-client-info, #popup-login-info {
    padding: 30px 10px;
    width: 80%;
    margin: 30px auto;
  }
  #popup-submit-client-info .body .options .email_option, #popup-submit-client-info .body .options .phone_option, #popup-login-info .body .options .email_option, #popup-login-info .body .options .phone_option {
    padding: 3px 10px;
  }
  #popup-submit-client-info .body .options .helper, #popup-login-info .body .options .helper {
    text-align: center;
  }
  #popup-submit-client-info .body .g-recaptcha, #popup-login-info .body .g-recaptcha {
    transform: scale(0.75);
    transform-origin: 0 0;
  }
}

#project-breadcrumb {
  background-color: #18283e;
  color: #FFFFFF;
  padding: 5px 0;
  text-align: center;
}

#content-project-choice-wrapper {
  background-color: #fafafa;
  padding-top: 40px;
  padding-bottom: 40px;
}

#content-project-choice-wrapper .container {
  max-width: 510px;
  min-width: initial;
}

#content-project-choice-wrapper .vendor-logo-wrapper {
  display: block;
  padding-top: 25px;
  padding-bottom: 25px;
}

#content-project-choice-wrapper .vendor-logo-wrapper img {
  display: block;
  max-width: 75px;
  max-height: 75px;
  margin: 0 auto;
}

#content-project-choice-wrapper .vendor-logo-wrapper strong {
  text-align: center;
  display: block;
  padding-top: 25px;
  padding-bottom: 25px;
  color: #545454;
  font-size: 15px;
}

#content-project-choice-wrapper .options-wrapper .radios input[type=radio] {
  visibility: hidden;
  opacity: 0;
}

#content-project-choice-wrapper .options-wrapper .radios label {
  display: block;
  padding-left: 35px;
  position: relative;
  margin: 0 0 20px;
  font-size: 14px;
  cursor: pointer;
}

#content-project-choice-wrapper .options-wrapper .radios label span {
  display: inline-block;
  vertical-align: middle;
}

#content-project-choice-wrapper .options-wrapper .radios label span.radio {
  position: absolute;
  left: 0;
  top: 0;
  border: 2px solid #d4f0e3;
  background-color: #d8f1e6;
  border-radius: 50%;
  margin: 0 10px 0 0;
  width: 20px;
  height: 20px;
  transition: background-color .3s;
}

#content-project-choice-wrapper .options-wrapper .radios label span.radio input {
  visibility: hidden;
  opacity: 0;
}

#content-project-choice-wrapper .options-wrapper .radios label.checked span.radio {
  background-color: #02cd8d;
}

#content-project-choice-wrapper .options-wrapper .radios input[type=text] {
  display: inline-block;
  width: auto;
}

#content-project-choice-wrapper .button-wrapper {
  padding-top: 40px;
  text-align: center;
}

#content-project-choice-wrapper .button-wrapper .btn-type-1 {
  font-size: 16px;
  letter-spacing: 1px;
  padding: 10px 35px;
}

#guest-index-content {
  background-color: #f0f4f7;
  padding-top: 80px;
  padding-bottom: 100px;
}

#guest-index-content .container {
  max-width: 818px;
  padding-left: 25px;
  padding-right: 25px;
}

#guest-index-content .content-wrapper .heading {
  margin-bottom: 80px;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 34px;
  font-weight: 700;
  padding-top: 20px;
}

#guest-index-content .content-wrapper .heading .black {
  color: #484848;
  line-height: 50px;
  margin: 0;
  font-size: 24px;
}

#guest-index-content .content-wrapper .heading .green {
  color: #02cd8d;
}

#guest-index-content .content-wrapper .body .row {
  margin-left: -25px;
  margin-right: -25px;
}

#guest-index-content .content-wrapper .body .row [class^=col] {
  padding-left: 25px;
  padding-right: 25px;
}

#guest-index-content .content-wrapper .body .row [class^=col] .box {
  padding: 25px;
}

#guest-index-content .content-wrapper .body .row [class^=col] .box .box-heading {
  margin-bottom: 20px;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 1px;
}

#guest-index-content .content-wrapper .body .row [class^=col] .box .box-body .description {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  margin-bottom: 40px;
  letter-spacing: 1px;
}

#guest-index-content .content-wrapper .body .row [class^=col] .box .box-body .btn-group {
  text-align: center;
  display: block;
}

#guest-index-content .content-wrapper .body .row [class^=col] .box .box-body .btn-group .btn {
  float: none;
  display: inline-block;
  padding: 10px 20px;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  letter-spacing: 1px;
  text-decoration: none;
  cursor: pointer;
  transition: background-color .3s;
}

#guest-index-content .content-wrapper .body .row [class^=col] .box.box-1 {
  background-color: #02cd8d;
  color: #FFFFFF;
}

#guest-index-content .content-wrapper .body .row [class^=col] .box.box-1 .box-body .btn-group .btn {
  color: #02cd8d;
  background-color: #FFFFFF;
}

#guest-index-content .content-wrapper .body .row [class^=col] .box.box-1 .box-body .btn-group .btn:hover {
  background-color: #f3f3f3;
  text-decoration: none;
}

#guest-index-content .content-wrapper .body .row [class^=col] .box.box-2 {
  background-color: #585858;
  color: #02cd8d;
}

#guest-index-content .content-wrapper .body .row [class^=col] .box.box-2 .box-body .btn-group .btn {
  color: #585858;
  background-color: #02cd8d;
}

#guest-index-content .content-wrapper .body .row [class^=col] .box.box-2 .box-body .btn-group .btn:hover {
  background-color: rgba(2, 205, 141, 0.75);
  text-decoration: none;
}

#guest-index-content .content-wrapper .footer {
  padding-top: 60px;
  text-align: center;
  color: #888888;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}

#guest-index-content .content-wrapper .footer a {
  display: inline-block;
  margin-left: 5px;
  color: #02cd8d;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}

#guest-index-content .content-wrapper .footer a:hover {
  text-decoration: none;
}

#guest-create-content {
  background-color: #f0f4f7;
  padding-top: 40px;
  padding-bottom: 100px;
}

#guest-create-content .content-wrapper-login {
  max-width: 480px;
  padding-top: 70px;
  padding-left: 25px;
  padding-right: 25px;
  margin: 0 auto;
}

#guest-create-content .content-wrapper-login .heading-login {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 700;
  padding-top: 20px;
}

#guest-create-content .content-wrapper-login .heading-login .black {
  color: #484848;
  display: inline-block;
}

#guest-create-content .content-wrapper-login .body .row {
  margin-left: -25px;
  margin-right: -25px;
}

#guest-create-content .content-wrapper-login .body .row [class^=col] {
  padding-left: 25px;
  padding-right: 25px;
}

#guest-create-content .content-wrapper-login .body .row [class^=col] .box-iframe {
  height: 450px;
  background-color: #d8d8d8;
}

#guest-create-content .content-wrapper-login .body .row [class^=col] .box-form .form-vertical {
  margin-top: 10px;
}

#guest-create-content .content-wrapper-login .body .row [class^=col] .box-form .form-vertical .form-control {
  margin-bottom: 15px;
}

#guest-create-content .content-wrapper-login .body .row [class^=col] .box-form .control-label {
  color: #949a9e;
  font-weight: bold;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
}

#guest-create-content .content-wrapper-login .body .row [class^=col] .box-form .control-label sup {
  color: #d70000;
}

#guest-create-content .content-wrapper-login .body .row [class^=col] .box-form .row {
  margin-left: -10px;
  margin-right: -10px;
}

#guest-create-content .content-wrapper-login .body .row [class^=col] .box-form .row [class^=col] {
  padding-left: 10px;
  padding-right: 10px;
}

#guest-create-content .content-wrapper-login .body .row [class^=col] .box-form input[type=text].form-control,
#guest-create-content .content-wrapper-login .body .row [class^=col] .box-form input[type=password].form-control,
#guest-create-content .content-wrapper-login .body .row [class^=col] .box-form input[type=email].form-control {
  border: 1px solid #eaeaea;
  background-color: #FFFFFF;
  transition: background-color .3s;
  border-radius: 2px;
  outline: 0;
  box-shadow: none;
  font-style: italic;
  font-weight: normal;
}

#guest-create-content .content-wrapper-login .body .row [class^=col] .box-form input[type=text].form-control:focus,
#guest-create-content .content-wrapper-login .body .row [class^=col] .box-form input[type=password].form-control:focus,
#guest-create-content .content-wrapper-login .body .row [class^=col] .box-form input[type=email].form-control:focus {
  background-color: #cffff0;
  border: 1px solid #cffff0;
}

#guest-create-content .content-wrapper-login .body .row [class^=col] .box-form .terms {
  color: #949a9e;
  font-weight: bold;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  text-align: left;
}

#guest-create-content .content-wrapper-login .body .row [class^=col] .box-form .terms a {
  color: #3f92f1;
}

#guest-create-content .content-wrapper-login .body .row [class^=col] .box-form .btn-group {
  display: block;
}

#guest-create-content .content-wrapper-login .body .row [class^=col] .box-form .btn-group button {
  width: 100%;
}

#guest-create-content .content-wrapper-login .body .row [class^=col] .box-form .other_description {
  padding-top: 10px;
  color: #949a9e;
  font-weight: bold;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  line-height: 30px;
  text-align: left;
}

#guest-create-content .content-wrapper-login .body .row [class^=col] .box-form .other_description a {
  line-height: 1;
  display: block;
  float: right;
  padding: 10px 20px;
  border: 1px solid #02cd8d;
  color: #02cd8d;
  border-radius: 2px;
  transition: background-color .3s;
}

#guest-create-content .content-wrapper-login .body .row [class^=col] .box-form .other_description a:hover {
  text-decoration: none;
  background-color: rgba(2, 205, 141, 0.75);
  color: #FFFFFF;
}

#guest-create-content .content-wrapper {
  max-width: 960px;
  padding-left: 25px;
  padding-right: 25px;
  margin: 0 auto;
}

#guest-create-content .content-wrapper .heading-client, #guest-create-content .content-wrapper .heading-vendor {
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-weight: 700;
  padding-top: 20px;
}

#guest-create-content .content-wrapper .heading-client .black, #guest-create-content .content-wrapper .heading-vendor .black {
  margin: 0;
  font-size: 24px;
  line-height: 50px;
  color: #484848;
  display: inline-block;
}

#guest-create-content .content-wrapper .heading-division {
  margin-bottom: 60px;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 30px;
  padding-top: 20px;
}

#guest-create-content .content-wrapper .heading-division .black {
  color: #484848;
  display: inline-block;
}

#guest-create-content .content-wrapper .heading-division .green {
  color: #02cd8d;
  display: inline-block;
}

#guest-create-content .content-wrapper .body-division .box {
  width: 830px;
  margin: 0 auto;
}

#guest-create-content .content-wrapper .body-division .box .row {
  margin-left: -55px;
  margin-right: -55px;
}

#guest-create-content .content-wrapper .body-division .box .row .col-sm-6 {
  padding-left: 55px;
  padding-right: 55px;
}

#guest-create-content .content-wrapper .body-division .box .box-1 {
  color: white;
  background-color: #02cd8d;
  padding: 30px 40px 40px;
}

#guest-create-content .content-wrapper .body-division .box .box-1 .box-heading {
  font-size: 20px;
  font-weight: bolder;
  margin-bottom: 20px;
}

#guest-create-content .content-wrapper .body-division .box .box-1 .box-description {
  font-size: 16px;
  margin-bottom: 30px;
}

#guest-create-content .content-wrapper .body-division .box .box-1 .text-center {
  padding-top: 50px;
}

#guest-create-content .content-wrapper .body-division .box .box-1 .btn {
  background-color: white;
  color: #02cd8d;
  font-size: 16px;
  border-radius: 0px;
}

#guest-create-content .content-wrapper .body-division .box .box-2 {
  color: #02cd8d;
  background-color: #133159;
  padding: 30px 40px 40px;
}

#guest-create-content .content-wrapper .body-division .box .box-2 .box-heading {
  font-size: 20px;
  font-weight: bolder;
  margin-bottom: 20px;
}

#guest-create-content .content-wrapper .body-division .box .box-2 .box-description {
  font-size: 16px;
  margin-bottom: 30px;
}

#guest-create-content .content-wrapper .body-division .box .box-2 .text-center {
  padding-top: 50px;
}

#guest-create-content .content-wrapper .body-division .box .box-2 .btn {
  background-color: #02cd8d;
  color: #133159;
  font-size: 16px;
  border-radius: 0px;
}

#guest-create-content .content-wrapper .division-other_description {
  padding-top: 95px;
  color: #949a9e;
  font-weight: bold;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  line-height: 30px;
  text-align: center;
}

#guest-create-content .content-wrapper .division-other_description a {
  line-height: 1;
  color: #02cd8d;
}

#guest-create-content .content-wrapper .division-other_description a:hover {
  text-decoration: none;
}

#guest-create-content .content-wrapper .body .row {
  margin-left: -25px;
  margin-right: -25px;
}

#guest-create-content .content-wrapper .body .row [class^=col] {
  padding-left: 25px;
  padding-right: 25px;
}

#guest-create-content .content-wrapper .body .row [class^=col] .box-iframe {
  height: 450px;
  background-color: #d8d8d8;
}

#guest-create-content .content-wrapper .body .row [class^=col] .box-form .form-vertical {
  margin-top: 20px;
}

#guest-create-content .content-wrapper .body .row [class^=col] .box-form .form-vertical .form-control {
  margin-bottom: 15px;
}

#guest-create-content .content-wrapper .body .row [class^=col] .box-form .control-label {
  color: #949a9e;
  font-weight: bold;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
}

#guest-create-content .content-wrapper .body .row [class^=col] .box-form .control-label sup {
  color: #d70000;
}

#guest-create-content .content-wrapper .body .row [class^=col] .box-form .row {
  margin-left: -10px;
  margin-right: -10px;
}

#guest-create-content .content-wrapper .body .row [class^=col] .box-form .row [class^=col] {
  padding-left: 10px;
  padding-right: 10px;
}

#guest-create-content .content-wrapper .body .row [class^=col] .box-form input[type=text].form-control,
#guest-create-content .content-wrapper .body .row [class^=col] .box-form input[type=password].form-control,
#guest-create-content .content-wrapper .body .row [class^=col] .box-form input[type=phone].form-control,
#guest-create-content .content-wrapper .body .row [class^=col] .box-form input[type=location].form-control,
#guest-create-content .content-wrapper .body .row [class^=col] .box-form input[type=email].form-control {
  border: 1px solid #eaeaea;
  background-color: #FFFFFF;
  transition: background-color .3s;
  border-radius: 2px;
  outline: 0;
  box-shadow: none;
  font-style: italic;
  font-weight: normal;
}

#guest-create-content .content-wrapper .body .row [class^=col] .box-form input[type=text].form-control:focus,
#guest-create-content .content-wrapper .body .row [class^=col] .box-form input[type=password].form-control:focus,
#guest-create-content .content-wrapper .body .row [class^=col] .box-form input[type=phone].form-control:focus,
#guest-create-content .content-wrapper .body .row [class^=col] .box-form input[type=location].form-control:focus,
#guest-create-content .content-wrapper .body .row [class^=col] .box-form input[type=email].form-control:focus {
  background-color: #cffff0;
  border: 1px solid #cffff0;
}

#guest-create-content .content-wrapper .body .row [class^=col] .box-form .select2-selection__rendered {
  border: 1px solid #eaeaea;
  background-color: #FFFFFF;
  transition: background-color .3s;
  border-radius: 2px;
  outline: 0;
  box-shadow: none;
  font-style: italic;
  font-weight: normal;
  font-size: 12px;
}

#guest-create-content .content-wrapper .body .row [class^=col] .box-form .select2-selection__rendered:focus {
  background-color: #cffff0;
  border: 1px solid #cffff0;
}

#guest-create-content .content-wrapper .body .row [class^=col] .box-form .terms {
  color: #949a9e;
  font-weight: bold;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  text-align: left;
}

#guest-create-content .content-wrapper .body .row [class^=col] .box-form .terms a {
  color: #3f92f1;
}

#guest-create-content .content-wrapper .body .row [class^=col] .box-form .btn-group {
  padding-top: 20px;
  display: block;
}

#guest-create-content .content-wrapper .body .row [class^=col] .box-form .btn-group button {
  width: 100%;
}

#guest-create-content .content-wrapper .body .row [class^=col] .box-form .other_description {
  padding-top: 10px;
  color: #949a9e;
  font-weight: bold;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  line-height: 30px;
  text-align: left;
}

#guest-create-content .content-wrapper .body .row [class^=col] .box-form .other_description a {
  line-height: 1;
  display: block;
  float: right;
  padding: 10px 20px;
  border: 1px solid #02cd8d;
  color: #02cd8d;
  border-radius: 2px;
  transition: background-color .3s;
}

#guest-create-content .content-wrapper .body .row [class^=col] .box-form .other_description a:hover {
  text-decoration: none;
  background-color: rgba(2, 205, 141, 0.75);
  color: #FFFFFF;
}

@media only screen and (max-width: 991px) {
  #guest-create-content {
    padding-bottom: 20px;
  }
  #guest-create-content .content-wrapper .heading-division {
    margin-bottom: 30px;
  }
  #guest-create-content .content-wrapper .body-division .box {
    width: 100%;
  }
  #guest-create-content .content-wrapper .body-division .box .box-1 {
    text-align: center;
  }
  #guest-create-content .content-wrapper .body-division .box .box-2 {
    text-align: center;
  }
  #guest-create-content .content-wrapper .body-division .box .box-2 .text-center {
    padding-top: 0px;
  }
  #guest-create-content .content-wrapper .division-other_description {
    padding-top: 15px;
  }
}

@media only screen and (max-width: 767px) {
  #guest-create-content .content-wrapper {
    padding-left: 5px;
    padding-right: 5px;
  }
  #guest-create-content .content-wrapper .heading-client, #guest-create-content .content-wrapper .heading-vendor {
    font-size: 20px;
  }
  #guest-create-content .content-wrapper .body-division .container-fluid {
    padding-left: 0px;
    padding-right: 0px;
  }
  #guest-create-content .content-wrapper .body-division .container-fluid .box .row {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
  #guest-create-content .content-wrapper .body-division .container-fluid .box .row .col-md-6 {
    padding-left: 0px;
    padding-right: 0px;
  }
  #guest-create-content .content-wrapper .body-division .container-fluid .box .box-1 {
    text-align: center;
  }
  #guest-create-content .content-wrapper .body-division .container-fluid .box .box-2 {
    text-align: center;
  }
  #guest-create-content .content-wrapper .body-division .container-fluid .box .box-2 .text-center {
    padding-top: 0px;
  }
}

#content-guest-login {
  background-color: #f0f4f7;
  padding-top: 40px;
  padding-bottom: 100px;
}

#content-guest-login .content-wrapper-login {
  max-width: 480px;
  padding-top: 70px;
  padding-left: 25px;
  padding-right: 25px;
  margin: 0 auto;
}

#content-guest-login .content-wrapper-login .wrapper-heading {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 700;
  padding-top: 20px;
}

#content-guest-login .content-wrapper-login .wrapper-heading .black {
  color: #484848;
  display: inline-block;
}

#content-guest-login .content-wrapper-login .wrapper-body .box-form .form-vertical {
  margin-top: 10px;
}

#content-guest-login .content-wrapper-login .wrapper-body .box-form .form-vertical .form-control {
  margin-bottom: 15px;
}

#content-guest-login .content-wrapper-login .wrapper-body .box-form .control-label {
  color: #949a9e;
  font-weight: bold;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
}

#content-guest-login .content-wrapper-login .wrapper-body .box-form .control-label sup {
  color: #d70000;
}

#content-guest-login .content-wrapper-login .wrapper-body .box-form .row {
  margin-left: -10px;
  margin-right: -10px;
}

#content-guest-login .content-wrapper-login .wrapper-body .box-form .row [class^=col] {
  padding-left: 10px;
  padding-right: 10px;
}

#content-guest-login .content-wrapper-login .wrapper-body .box-form input[type=text].form-control,
#content-guest-login .content-wrapper-login .wrapper-body .box-form input[type=password].form-control,
#content-guest-login .content-wrapper-login .wrapper-body .box-form input[type=email].form-control {
  border: 1px solid #eaeaea;
  background-color: #FFFFFF;
  transition: background-color .3s;
  border-radius: 2px;
  outline: 0;
  box-shadow: none;
  font-style: italic;
  font-weight: normal;
}

#content-guest-login .content-wrapper-login .wrapper-body .box-form input[type=text].form-control:focus,
#content-guest-login .content-wrapper-login .wrapper-body .box-form input[type=password].form-control:focus,
#content-guest-login .content-wrapper-login .wrapper-body .box-form input[type=email].form-control:focus {
  background-color: #cffff0;
  border: 1px solid #cffff0;
}

#content-guest-login .content-wrapper-login .wrapper-body .box-form .terms {
  color: #949a9e;
  font-weight: bold;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  text-align: left;
}

#content-guest-login .content-wrapper-login .wrapper-body .box-form .terms a {
  color: #3f92f1;
}

#content-guest-login .content-wrapper-login .wrapper-body .box-form .btn-group {
  display: block;
}

#content-guest-login .content-wrapper-login .wrapper-body .box-form .btn-group button {
  width: 100%;
}

#content-guest-login .content-wrapper-login .wrapper-body .box-form .other_description {
  padding-top: 10px;
  color: #949a9e;
  font-weight: bold;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  line-height: 30px;
  text-align: left;
}

#content-guest-login .content-wrapper-login .wrapper-body .box-form .other_description a {
  line-height: 1;
  display: block;
  float: right;
  padding: 10px 20px;
  border: 1px solid #02cd8d;
  color: #02cd8d;
  border-radius: 2px;
  transition: background-color .3s;
}

#content-guest-login .content-wrapper-login .wrapper-body .box-form .other_description a:hover {
  text-decoration: none;
  background-color: rgba(2, 205, 141, 0.75);
  color: #FFFFFF;
}

#content-guest-reset {
  background-color: #f0f4f7;
  padding-top: 40px;
  padding-bottom: 100px;
}

#content-guest-reset .content-wrapper-reset {
  max-width: 480px;
  padding-top: 70px;
  padding-left: 25px;
  padding-right: 25px;
  margin: 0 auto;
}

#content-guest-reset .content-wrapper-reset .wrapper-heading {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 700;
  padding-top: 20px;
}

#content-guest-reset .content-wrapper-reset .wrapper-heading .black {
  color: #484848;
  display: inline-block;
}

#content-guest-reset .content-wrapper-reset .wrapper-body .box-form .form-vertical {
  margin-top: 10px;
}

#content-guest-reset .content-wrapper-reset .wrapper-body .box-form .form-vertical .form-control {
  margin-bottom: 15px;
}

#content-guest-reset .content-wrapper-reset .wrapper-body .box-form .control-label {
  color: #949a9e;
  font-weight: bold;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
}

#content-guest-reset .content-wrapper-reset .wrapper-body .box-form .control-label sup {
  color: #d70000;
}

#content-guest-reset .content-wrapper-reset .wrapper-body .box-form .row {
  margin-left: -10px;
  margin-right: -10px;
}

#content-guest-reset .content-wrapper-reset .wrapper-body .box-form .row [class^=col] {
  padding-left: 10px;
  padding-right: 10px;
}

#content-guest-reset .content-wrapper-reset .wrapper-body .box-form input[type=text].form-control,
#content-guest-reset .content-wrapper-reset .wrapper-body .box-form input[type=password].form-control,
#content-guest-reset .content-wrapper-reset .wrapper-body .box-form input[type=email].form-control {
  border: 1px solid #eaeaea;
  background-color: #FFFFFF;
  transition: background-color .3s;
  border-radius: 2px;
  outline: 0;
  box-shadow: none;
  font-style: italic;
  font-weight: normal;
}

#content-guest-reset .content-wrapper-reset .wrapper-body .box-form input[type=text].form-control:focus,
#content-guest-reset .content-wrapper-reset .wrapper-body .box-form input[type=password].form-control:focus,
#content-guest-reset .content-wrapper-reset .wrapper-body .box-form input[type=email].form-control:focus {
  background-color: #cffff0;
  border: 1px solid #cffff0;
}

#content-guest-reset .content-wrapper-reset .wrapper-body .box-form .terms {
  color: #949a9e;
  font-weight: bold;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  text-align: left;
}

#content-guest-reset .content-wrapper-reset .wrapper-body .box-form .terms a {
  color: #3f92f1;
}

#content-guest-reset .content-wrapper-reset .wrapper-body .box-form .btn-group {
  display: block;
}

#content-guest-reset .content-wrapper-reset .wrapper-body .box-form .btn-group button {
  width: 100%;
}

#content-guest-reset .content-wrapper-reset .wrapper-body .box-form .other_description {
  padding-top: 10px;
  color: #949a9e;
  font-weight: bold;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  line-height: 30px;
  text-align: left;
}

#content-guest-reset .content-wrapper-reset .wrapper-body .box-form .other_description a {
  line-height: 1;
  display: block;
  float: right;
  padding: 10px 20px;
  border: 1px solid #02cd8d;
  color: #02cd8d;
  border-radius: 2px;
  transition: background-color .3s;
}

#content-guest-reset .content-wrapper-reset .wrapper-body .box-form .other_description a:hover {
  text-decoration: none;
  background-color: rgba(2, 205, 141, 0.75);
  color: #FFFFFF;
}

#content-page-about-us-banner {
  height: 595px;
  position: relative;
  z-index: 3;
  background: url("../../images/root/about/2Easy-about-us.jpg") center center;
  background-size: cover;
}

#content-page-about-us-banner::after {
  content: '';
  display: block;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
}

#content-page-about-us-banner .inner {
  position: absolute;
  margin-top: 0px;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#content-page-about-us-banner .inner .icon.icon-monitor {
  width: 160px;
  height: 150px;
  background-position: -794px -878px;
  background-image: url(../../images/root/all.png);
  margin: 0 auto 35px;
}

#content-page-about-us-banner .inner .title {
  color: white;
  font-size: 35px;
  text-align: center;
  width: 605px;
  font-weight: bolder;
  margin: 50px auto;
  line-height: 50px;
}

#content-page-about-us-banner .inner .description {
  white-space: normal;
  font-size: 21px;
  color: #FFFFFF;
  text-align: center;
  width: 1050px;
  margin: 25px auto;
  word-spacing: 5px;
}

#content-page-about-us-banner .inner .text-center .btn-effect-1 {
  width: 200px;
  text-align: center;
  margin: 0 auto;
}

#content-page-about-us-body {
  display: block;
  position: relative;
}

#content-page-about-us-body .container {
  min-width: initial;
  width: 100%;
  max-width: 1160px;
}

#content-page-about-us-body .center-box {
  background-color: #01d693;
  position: absolute;
  z-index: 3;
  padding: 100px 0;
  width: 1130px;
  top: -100px;
  left: 50%;
  transform: translate(-50%, 0);
}

#content-page-about-us-body .center-box .inner-box {
  width: 768px;
  color: white;
  text-align: center;
  font-size: 20px;
  margin: 0 auto;
  line-height: 30px;
}

#content-page-about-us-body .content-about-tab {
  margin-bottom: 50px;
  padding-top: 250px;
}

#content-page-about-us-body .content-about-tab .title {
  padding: 0;
  position: relative;
  margin-bottom: 30px;
}

#content-page-about-us-body .content-about-tab .title:after {
  display: block;
  content: '';
  width: 100%;
  height: 3px;
  position: absolute;
  bottom: 0;
  webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  background-color: #27f2b2;
  z-index: 2;
}

#content-page-about-us-body .content-about-tab .title li.tab-link {
  display: inline-block;
  font-size: 20px;
  margin-right: 30px;
  cursor: pointer;
  padding-bottom: 13px;
  position: relative;
}

#content-page-about-us-body .content-about-tab .title li.tab-link.active {
  font-weight: bold;
}

#content-page-about-us-body .content-about-tab .title li.tab-link.active:after {
  z-index: 3;
  content: '';
  display: block;
  position: absolute;
  width: 22px;
  height: 14px;
  background: url("../../images/root/pages/about-us/arrow-up.png") no-repeat center;
  background-size: cover;
  bottom: 0;
  right: -5px;
}

#content-page-about-us-body .content-about-tab .tab-body {
  display: none;
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 0.3s;
}

#content-page-about-us-body .content-about-tab .tab-body .row .row-title {
  font-size: 30px;
}

#content-page-about-us-body .content-about-tab .tab-body .row .row-description {
  font-size: 14px;
  padding-left: 0px;
  padding-right: 0px;
  line-height: 30px;
}

#content-page-about-us-body .content-about-tab .tab-body .row .row-description img {
  margin-top: 15px;
  margin-bottom: 40px;
}

#content-page-about-us-body .content-about-tab .tab-body .row .row-description .content-ul .diff-li {
  margin-left: 40px;
}

#content-page-about-us-body .content-about-tab .tab-body.active {
  display: block;
  opacity: 1;
}

#content-page-about-us-body .vision .inner-box {
  background-color: #18293e;
  color: white;
  text-align: center;
  padding: 100px 0;
  line-height: 30px;
}

#content-page-about-us-body .vision .inner-box .title {
  font-size: 30px;
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 18px;
  font-weight: bold;
  line-height: 1;
}

#content-page-about-us-body .vision .inner-box .title:after {
  position: absolute;
  display: block;
  content: '';
  width: 135px;
  height: 3px;
  bottom: 0;
  left: 50%;
  margin-top: 10px;
  transform: translateX(-50%);
  background-color: #27f2b2;
}

#content-page-about-us-body .vision .inner-box .description {
  width: 728px;
  font-size: 20px;
  margin: 0 auto;
}

#content-page-about-us-body .vision.in {
  transform: rotate(0deg);
  transition: transform 0.5s ease-in-out;
}

#content-page-about-us-body .content-about-believe {
  margin-top: 70px;
  margin-bottom: 50px;
}

#content-page-about-us-body .content-about-believe .row .row-title {
  font-size: 30px;
  padding-left: 0px;
  padding-right: 0px;
}

#content-page-about-us-body .content-about-believe .row .row-description {
  font-size: 14px;
  padding-left: 0px;
  padding-right: 0px;
  line-height: 30px;
}

#content-page-about-us-body .map {
  background-color: #c9c9c9;
  padding: 40px;
  position: relative;
}

#content-page-about-us-body .map .content-box {
  position: relative;
  z-index: 2;
  background: white;
  padding: 20px 30px;
}

#content-page-about-us-body .map .content-box .title {
  font-size: 22px;
  padding-bottom: 10px;
  font-weight: bold;
  line-height: 1;
  border-bottom: 3px solid #27f2b2;
}

#content-page-about-us-body .map .content-box .header {
  font-weight: bolder;
  font-size: 16px;
  margin-bottom: 10px;
  margin-top: 15px;
}

#content-page-about-us-body .map .content-box .body {
  font-size: 16px;
  margin-bottom: 10px;
}

#content-page-about-us-body .map #map-canvas {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}

#new-about-us-banner {
  height: 654px;
  position: relative;
  z-index: 3;
  background: url("../../images/root/about/banner/building.png") no-repeat 680px 147px #0e1d2e;
  overflow: hidden;
}

#new-about-us-banner .inner .banner {
  height: 654px;
  position: relative;
}

#new-about-us-banner .inner .banner .font {
  left: 7%;
  top: 30%;
  position: absolute;
  padding-right: 50%;
}

#new-about-us-banner .inner .banner .font .title {
  color: white;
  font-size: 40px;
  font-weight: bold;
  line-height: 50px;
}

#new-about-us-banner .inner .banner .font .description {
  white-space: normal;
  font-size: 40px;
  font-weight: bold;
  color: #28f1b1;
}

#new-about-us-banner .inner .banner .image {
  position: absolute;
  right: -7%;
  top: 0%;
  width: 65%;
  padding-bottom: 90%;
  background: url("../../images/root/about/banner/founders.png") no-repeat;
  background-position: 50% 50%;
}

#new-about-us-body {
  color: #18293e;
}

#new-about-us-body #on-2easy {
  padding: 80px 0;
  background-color: #f2f2f2;
}

#new-about-us-body #on-2easy .title {
  font-size: 30px;
  font-weight: bold;
  padding-bottom: 30px;
  margin-top: 0px;
}

#new-about-us-body #on-2easy .title span {
  color: #18d197;
}

#new-about-us-body #on-2easy .content .image-wrapper {
  position: relative;
  height: 170px;
  width: 100%;
}

#new-about-us-body #on-2easy .content .image-wrapper img {
  display: block;
  max-height: 100%;
  max-width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#new-about-us-body #on-2easy .content .description {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

#new-about-us-body #on-2easy .bottom {
  padding-top: 30px;
  font-size: 16px;
}

#new-about-us-body #our-story {
  padding: 100px 0;
}

#new-about-us-body #our-story .title {
  padding-bottom: 30px;
  font-size: 30px;
  font-weight: bold;
  margin-top: 0px;
}

#new-about-us-body #our-story .next {
  padding: 60px 0;
}

#new-about-us-body #our-story .step-1 {
  padding: 0 360px;
  position: relative;
}

#new-about-us-body #our-story .step-1 img {
  width: 150px;
}

#new-about-us-body #our-story .step-1 .content {
  padding: 25px 70px 0;
  font-size: 18px;
}

#new-about-us-body #our-story .step-2 {
  position: relative;
  padding: 0 200px;
}

#new-about-us-body #our-story .step-2 img.story-1 {
  width: 140px;
}

#new-about-us-body #our-story .step-2 .info {
  position: relative;
  padding: 20px 100px 0;
}

#new-about-us-body #our-story .step-2 .info .title {
  font-size: 24px;
  font-weight: bold;
  color: #18d197;
  padding-bottom: 0;
}

#new-about-us-body #our-story .step-2 .info .content {
  font-size: 24px;
  font-weight: bold;
}

#new-about-us-body #our-story .step-2 .info .open-icon {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

#new-about-us-body #our-story .step-2 .info .close-icon {
  position: absolute;
  right: 0;
  top: 78%;
  transform: translateY(-78%);
}

#new-about-us-body #our-story .step-2 .description {
  font-size: 18px;
  font-weight: bold;
  padding: 10px 210px 0;
}

#new-about-us-body #our-story .step-3 {
  padding: 0 200px;
  position: relative;
}

#new-about-us-body #our-story .step-3 img.story-2 {
  width: 150px;
}

#new-about-us-body #our-story .step-3 .content {
  font-size: 24px;
  font-weight: bold;
  padding-top: 15px;
}

#new-about-us-body #our-story .step-3 .description {
  font-size: 18px;
  font-weight: bold;
  padding: 15px 0 25px;
}

#new-about-us-body #our-story .step-3 .image-wrapper img.head {
  width: 280px;
  padding: 0 55px;
}

#new-about-us-body #our-story .step-3 .position {
  font-size: 18px;
  padding: 20px 80px 0;
}

#new-about-us-body #our-story .step-3 .position .col-left {
  float: left;
  width: 50%;
  padding: 0 15px;
}

#new-about-us-body #our-story .step-3 .position .col-right {
  float: right;
  width: 50%;
  padding: 0 15px;
}

#new-about-us-body #our-story .step-4 {
  padding: 0 300px;
  position: relative;
}

#new-about-us-body #our-story .step-4 img.story-3 {
  width: 150px;
}

#new-about-us-body #our-story .step-4 .info {
  position: relative;
  padding: 20px 100px 0;
}

#new-about-us-body #our-story .step-4 .info .content {
  font-size: 24px;
  font-weight: bold;
}

#new-about-us-body #our-story .step-4 .info .content span {
  color: #28f1b1;
}

#new-about-us-body #our-story .step-4 .info .open-icon {
  position: absolute;
  left: 0;
  top: 20%;
  transform: translateY(-20%);
}

#new-about-us-body #our-story .step-4 .info .close-icon {
  position: absolute;
  right: 0;
  top: 80%;
  transform: translateY(-80%);
}

#new-about-us-body #our-story .step-5 {
  position: relative;
}

#new-about-us-body #our-story .step-5 .content {
  font-size: 24px;
  font-weight: bold;
  padding-top: 15px;
}

#new-about-us-body #our-story .step-5 .content span {
  color: #28f1b1;
}

#new-about-us-body #our-story .step-5 .image-wrapper img.logo {
  padding: 0 55px;
  width: 365px;
}

#new-about-us-body #our-story .step-6 {
  position: relative;
}

#new-about-us-body #our-story .step-6 img.story-4 {
  width: 340px;
}

#new-about-us-body #our-story .step-6 .content {
  font-size: 24px;
  font-weight: bold;
  padding-top: 15px;
}

#new-about-us-body #our-story .step-6 .content span {
  color: #28f1b1;
}

#new-about-us-body #our-story .step-6 .image-wrapper {
  padding-top: 15px;
}

#new-about-us-body #our-story .step-6 .image-wrapper img.icon {
  padding: 0 55px;
}

#new-about-us-body #our-story .step-6 .image-wrapper img.country {
  padding: 0 20px;
}

#new-about-us-body #our-story .step-6 .image-wrapper img.airplane {
  width: 130px;
}

#new-about-us-body #our-story .step-7 {
  position: relative;
}

#new-about-us-body #our-story .step-7 img.story-5 {
  width: 150px;
}

#new-about-us-body #our-story .step-7 .title {
  font-size: 24px;
  font-weight: bold;
  color: #18d197;
  padding-bottom: 0px;
  padding-top: 20px;
}

#new-about-us-body #our-story .step-7 .content {
  font-size: 24px;
  font-weight: bold;
}

#new-about-us-body #our-story .step-8 {
  padding: 0 300px;
  position: relative;
}

#new-about-us-body #our-story .step-8 img.story-6 {
  width: 150px;
}

#new-about-us-body #our-story .step-8 .info {
  position: relative;
  padding: 20px 100px 0;
}

#new-about-us-body #our-story .step-8 .info .title {
  font-size: 24px;
  font-weight: bold;
  padding-bottom: 0;
  color: #18d197;
}

#new-about-us-body #our-story .step-8 .info .content {
  font-size: 24px;
  font-weight: bold;
}

#new-about-us-body #our-story .step-8 .info .open-icon {
  position: absolute;
  left: 0;
  top: 20%;
  transform: translateY(-20%);
}

#new-about-us-body #our-story .step-8 .info .close-icon {
  position: absolute;
  right: 0;
  top: 80%;
  transform: translateY(-80%);
}

#new-about-us-body #staff {
  padding-bottom: 100px;
}

#new-about-us-body #staff .title {
  font-size: 30px;
  font-weight: bold;
  padding-bottom: 40px;
  margin-top: 0;
}

#new-about-us-body #staff .profiles .profile {
  cursor: pointer;
  position: relative;
  width: 23%;
  float: left;
  margin: 10px;
}

#new-about-us-body #staff .profiles .profile img {
  width: 100%;
  position: relative;
}

#new-about-us-body #staff .profiles .profile img.default {
  z-index: 1;
  opacity: 1;
}

#new-about-us-body #staff .profiles .profile img.hover {
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity .3s;
  z-index: 2;
  opacity: 0;
}

#new-about-us-body #staff .profiles .profile:hover img.default {
  opacity: 0;
}

#new-about-us-body #staff .profiles .profile:hover img.hover {
  opacity: 1;
}

#new-about-us-body #staff .profiles .profile:hover .info {
  display: block;
}

#new-about-us-body #staff .profiles .profile:hover::after {
  content: '';
  display: block;
  z-index: 4;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
}

#new-about-us-body #staff .profiles .profile .info {
  display: none;
  position: absolute;
  top: 90%;
  left: 10%;
  transform: translate(-10%, -90%);
  z-index: 5;
  padding: 0 10% 0 10%;
  width: 100%;
}

#new-about-us-body #staff .profiles .profile .info .position {
  font-size: 14px;
  color: #28f1b1;
  display: inline-block;
}

#new-about-us-body #staff .profiles .profile .info .position .name {
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
}

#new-about-us-body #staff .profiles .profile .info img.linkedin {
  width: 50px;
  float: right;
  padding: 0;
}

#new-about-us-body #find-agency {
  padding: 100px 0;
  background-color: #28f1b1;
}

#new-about-us-body #find-agency .content {
  position: relative;
  padding-left: 260px;
}

#new-about-us-body #find-agency .content img {
  position: absolute;
  width: 100px;
  height: 100px;
  left: 130px;
  top: 0;
}

#new-about-us-body #find-agency .content .title {
  font-size: 30px;
  font-weight: bold;
}

#new-about-us-body #find-agency .content .description_1 {
  font-size: 18px;
  font-weight: bold;
}

#new-about-us-body #find-agency .content .description_2 {
  font-size: 14px;
}

#new-about-us-body #find-agency .find {
  padding-top: 45px;
}

#new-about-us-body #find-agency .find a {
  background-color: #18293e;
  border-radius: 2px;
  cursor: pointer;
  padding: 18px 60px;
  font-size: 16px;
  color: #ffffff;
  text-decoration: none;
}

#new-about-us-body #find-agency .find a:hover {
  transition: all 0.5s ease-out;
  background-color: #ffffff;
  color: #11344c;
}

.slick-arrow {
  height: 46px;
  width: 46px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  line-height: 46px;
  text-align: center;
}

.slick-arrow.slick-prev {
  left: 10px;
}

.slick-arrow.slick-prev::before {
  right: 2px;
}

.slick-arrow.slick-next {
  right: 10px;
}

.slick-arrow.slick-next::before {
  left: 2px;
}

.slick-arrow::before {
  border-radius: 50%;
  font-size: 40px;
  color: #ffffff;
  opacity: 1;
  line-height: 42px;
  position: relative;
}

@media only screen and (max-width: 991px) {
  #content-page-about-us-banner .inner .title {
    width: 100%;
  }
  #content-page-about-us-banner .inner .description {
    width: 100%;
  }
  #content-page-about-us-body .center-box {
    position: static;
    width: auto;
    transform: initial;
    margin-left: -15px;
    margin-right: -15px;
    padding: 60px 0;
  }
  #content-page-about-us-body .center-box .inner-box {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    line-height: 1.5;
  }
  #content-page-about-us-body .content-about-tab {
    padding-top: 0;
    margin-bottom: 30px;
  }
  #content-page-about-us-body .content-about-tab .title {
    padding-top: 20px;
  }
  #content-page-about-us-body .content-about-tab .title:after {
    width: 100%;
  }
  #content-page-about-us-body .content-about-tab .tab-body {
    padding-left: 0;
    padding-right: 0;
  }
  #content-page-about-us-body .content-about-tab .tab-body .row {
    margin-left: 0;
    margin-right: 0;
  }
  #content-page-about-us-body .content-about-tab .tab-body .row .row-title {
    font-size: 24px;
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 20px;
  }
  #content-page-about-us-body .content-about-tab .tab-body .row .row-description {
    font-size: 14px;
    line-height: 1.5;
  }
  #content-page-about-us-body .content-about-tab .tab-body .row .row-description .content-ul li:not(:first-child) {
    margin-top: 10px;
  }
  #content-page-about-us-body .content-about-believe {
    margin-top: 30px;
    padding-left: 5px;
    padding-right: 5px;
  }
  #content-page-about-us-body .content-about-believe .row {
    margin-left: 0;
    margin-right: 0;
  }
  #content-page-about-us-body .content-about-believe .row .row-title {
    text-align: center;
    font-size: 24px;
    padding-bottom: 10px;
  }
  #content-page-about-us-body .content-about-believe .row .row-description {
    text-align: center;
    font-size: 16px;
    line-height: 1.5;
  }
  #content-page-about-us-body .vision {
    margin-left: -15px;
    margin-right: -15px;
  }
  #content-page-about-us-body .vision .inner-box {
    padding: 50px 20px;
  }
  #content-page-about-us-body .vision .inner-box .title {
    font-size: 24px;
    padding-bottom: 13px;
  }
  #content-page-about-us-body .vision .inner-box .description {
    width: 100%;
    font-size: 16px;
  }
  #content-page-about-us-body .map {
    padding: 70px 40px;
  }
  #content-page-about-us-body .map .content-box .title {
    font-size: 24px;
  }
  #new-about-us-banner {
    height: 500px;
    background: url("../../images/root/about/banner/building.png") no-repeat 80px 70px #0e1d2e;
  }
  #new-about-us-banner .inner .banner {
    height: 500px;
    position: relative;
  }
  #new-about-us-banner .inner .banner .font .title {
    font-size: 26px;
    line-height: 40px;
  }
  #new-about-us-banner .inner .banner .font .description {
    font-size: 26px;
  }
  #new-about-us-banner .inner .banner .image {
    background-size: 90% 90%;
    height: 100%;
    right: 0%;
    top: 20%;
    width: 50%;
  }
  #new-about-us-body #on-2easy {
    padding: 50px 0;
  }
  #new-about-us-body #on-2easy .title {
    font-size: 26px;
  }
  #new-about-us-body #on-2easy .content .description {
    font-size: 18px;
  }
  #new-about-us-body #on-2easy .bottom {
    font-size: 14px;
  }
  #new-about-us-body #our-story {
    padding: 50px 0;
  }
  #new-about-us-body #our-story .title {
    padding-bottom: 26px;
  }
  #new-about-us-body #our-story .next {
    padding: 30px 0;
  }
  #new-about-us-body #our-story .step-1 {
    padding: 0 130px;
  }
  #new-about-us-body #our-story .step-1 img {
    width: 130px;
  }
  #new-about-us-body #our-story .step-1 .content {
    padding: 25px 0 0;
    font-size: 16px;
  }
  #new-about-us-body #our-story .step-2 {
    padding: 0 40px;
  }
  #new-about-us-body #our-story .step-2 img.story-1 {
    width: 130px;
  }
  #new-about-us-body #our-story .step-2 .info {
    padding: 20px 100px 0;
  }
  #new-about-us-body #our-story .step-2 .info .title {
    font-size: 18px;
  }
  #new-about-us-body #our-story .step-2 .info .content {
    font-size: 18px;
  }
  #new-about-us-body #our-story .step-2 .info .open-icon {
    width: 40px;
  }
  #new-about-us-body #our-story .step-2 .info .close-icon {
    width: 40px;
  }
  #new-about-us-body #our-story .step-2 .description {
    font-size: 14px;
    padding: 10px 100px 0;
  }
  #new-about-us-body #our-story .step-3 {
    padding: 0 30px;
  }
  #new-about-us-body #our-story .step-3 img.story-2 {
    width: 130px;
  }
  #new-about-us-body #our-story .step-3 .content {
    font-size: 18px;
  }
  #new-about-us-body #our-story .step-3 .description {
    font-size: 14px;
  }
  #new-about-us-body #our-story .step-3 .image-wrapper img.head {
    width: 230px;
    padding: 0 50px;
  }
  #new-about-us-body #our-story .step-3 .position {
    font-size: 14px;
    padding: 20px 20px 0;
  }
  #new-about-us-body #our-story .step-3 .position .col-left {
    padding: 0 20px;
  }
  #new-about-us-body #our-story .step-3 .position .col-right {
    padding: 0 20px;
  }
  #new-about-us-body #our-story .step-4 {
    padding: 0 40px;
  }
  #new-about-us-body #our-story .step-4 img.story-3 {
    width: 130px;
  }
  #new-about-us-body #our-story .step-4 .info {
    position: relative;
    padding: 20px 100px 0;
  }
  #new-about-us-body #our-story .step-4 .info .content {
    font-size: 18px;
  }
  #new-about-us-body #our-story .step-4 .info .open-icon {
    width: 40px;
  }
  #new-about-us-body #our-story .step-4 .info .close-icon {
    width: 40px;
  }
  #new-about-us-body #our-story .step-5 .content {
    font-size: 18px;
  }
  #new-about-us-body #our-story .step-5 .content span {
    color: #28f1b1;
  }
  #new-about-us-body #our-story .step-5 .image-wrapper img.logo {
    padding: 0px 35px;
    width: 285px;
  }
  #new-about-us-body #our-story .step-6 img.story-4 {
    width: 260px;
  }
  #new-about-us-body #our-story .step-6 .content {
    font-size: 18px;
  }
  #new-about-us-body #our-story .step-6 .image-wrapper {
    padding-top: 15px;
  }
  #new-about-us-body #our-story .step-6 .image-wrapper img.icon {
    padding: 0 35px;
    width: 170px;
  }
  #new-about-us-body #our-story .step-6 .image-wrapper img.airplane {
    width: 100px;
  }
  #new-about-us-body #our-story .step-7 img.story-5 {
    width: 130px;
  }
  #new-about-us-body #our-story .step-7 .title {
    font-size: 18px;
  }
  #new-about-us-body #our-story .step-7 .content {
    font-size: 18px;
  }
  #new-about-us-body #our-story .step-8 {
    padding: 0 40px;
  }
  #new-about-us-body #our-story .step-8 img.story-6 {
    width: 130px;
  }
  #new-about-us-body #our-story .step-8 .info {
    position: relative;
    padding: 20px 100px 0;
  }
  #new-about-us-body #our-story .step-8 .info .title {
    font-size: 18px;
  }
  #new-about-us-body #our-story .step-8 .info .content {
    font-size: 18px;
  }
  #new-about-us-body #our-story .step-8 .info .open-icon {
    width: 40px;
  }
  #new-about-us-body #our-story .step-8 .info .close-icon {
    width: 40px;
  }
  #new-about-us-body #staff {
    padding-bottom: 50px;
  }
  #new-about-us-body #staff .title {
    font-size: 24px;
    padding-bottom: 20px;
  }
  #new-about-us-body #staff .profiles .profile {
    margin: 0;
    width: 50%;
  }
  #new-about-us-body #find-agency {
    padding: 60px 0;
    background-color: #28f1b1;
  }
  #new-about-us-body #find-agency .content {
    padding-left: 120px;
  }
  #new-about-us-body #find-agency .content img {
    width: 80px;
    height: 80px;
    left: 20px;
  }
  #new-about-us-body #find-agency .content .title {
    font-size: 24px;
  }
  #new-about-us-body #find-agency .content .description_1 {
    font-size: 18px;
  }
  #new-about-us-body #find-agency .content .description_2 {
    font-size: 14px;
  }
  #new-about-us-body #find-agency .find {
    padding-top: 45px;
  }
  #new-about-us-body #find-agency .find a {
    padding: 12px 40px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  #content-page-about-us-banner {
    height: 100vh;
    max-height: 550px;
  }
  #content-page-about-us-banner .inner {
    width: 100%;
  }
  #content-page-about-us-banner .inner .title {
    font-size: 20px;
    margin: 0 auto 20px;
  }
  #content-page-about-us-banner .inner .description {
    font-size: 18px;
    padding: 0;
    margin: 0;
  }
  #content-page-about-us-banner .inner .text-center .btn-effect-1 {
    width: 100%;
    text-align: center;
    margin: 0 auto;
  }
  #content-page-about-us-body .center-box {
    padding: 30px 0;
  }
  #content-page-about-us-body .center-box .inner-box {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 16px;
  }
  #content-page-about-us-body .content-about-tab .title {
    padding-top: 20px;
    margin-bottom: 10px;
  }
  #content-page-about-us-body .content-about-tab .tab-body .row .row-title {
    font-size: 22px;
    margin-bottom: 15px;
  }
  #content-page-about-us-body .content-about-tab .tab-body .row .row-description {
    font-size: 14px;
    line-height: 1.5;
  }
  #content-page-about-us-body .content-about-tab .tab-body .row .row-description .content-ul li:not(:first-child) {
    margin-top: 8px;
  }
  #content-page-about-us-body .vision .inner-box {
    padding: 30px 0;
  }
  #content-page-about-us-body .vision .inner-box .title {
    font-size: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 12px;
  }
  #content-page-about-us-body .vision .inner-box .title:after {
    height: 2px;
  }
  #content-page-about-us-body .vision .inner-box .description {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 16px;
    line-height: 1.5;
  }
  #content-page-about-us-body .content-about-believe {
    margin-top: 30px;
    margin-bottom: 30px;
    padding-left: 0;
    padding-right: 0;
  }
  #content-page-about-us-body .map {
    background-color: #ffffff;
    padding: 0;
  }
  #content-page-about-us-body .map .content-box {
    width: 100%;
    background: white;
    padding: 20px 0px;
  }
  #content-page-about-us-body .map .content-box .title {
    font-size: 20px;
    border-bottom: 2px solid #02cd8d;
  }
  #content-page-about-us-body .map .content-box .body {
    font-size: 15px;
  }
  #new-about-us-banner {
    height: 654px;
    background-image: none;
    background-color: #0e1d2e;
  }
  #new-about-us-banner .inner .banner {
    height: 654px;
    position: relative;
  }
  #new-about-us-banner .inner .banner .font {
    padding-right: 0;
    top: 20%;
  }
  #new-about-us-banner .inner .banner .font .description {
    font-size: 26px;
  }
  #new-about-us-banner .inner .banner .image {
    background-size: 75% 75%;
    height: 100%;
    top: 50%;
    width: 100%;
    right: 5%;
  }
  #new-about-us-body #on-2easy .content {
    padding-bottom: 15px;
  }
  #new-about-us-body #on-2easy .content .image-wrapper img {
    width: 150px;
  }
  #new-about-us-body #our-story {
    padding: 50px 0;
  }
  #new-about-us-body #our-story .title {
    padding-bottom: 26px;
  }
  #new-about-us-body #our-story .next {
    padding: 30px 0;
  }
  #new-about-us-body #our-story .step-1 {
    padding: 0 10px;
  }
  #new-about-us-body #our-story .step-1 img {
    width: 100px;
  }
  #new-about-us-body #our-story .step-2 {
    padding: 0 10px;
  }
  #new-about-us-body #our-story .step-2 img.story-1 {
    width: 100px;
  }
  #new-about-us-body #our-story .step-2 .info {
    padding: 20px 30px 0;
  }
  #new-about-us-body #our-story .step-2 .info .title {
    font-size: 16px;
  }
  #new-about-us-body #our-story .step-2 .info .content {
    font-size: 16px;
  }
  #new-about-us-body #our-story .step-2 .info .open-icon {
    width: 20px;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  #new-about-us-body #our-story .step-2 .info .close-icon {
    width: 20px;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  #new-about-us-body #our-story .step-2 .description {
    font-size: 14px;
    padding: 10px 30px 0;
  }
  #new-about-us-body #our-story .step-3 {
    padding: 0;
  }
  #new-about-us-body #our-story .step-3 img.story-2 {
    width: 100px;
  }
  #new-about-us-body #our-story .step-3 .content {
    font-size: 16px;
  }
  #new-about-us-body #our-story .step-3 .description {
    font-size: 14px;
  }
  #new-about-us-body #our-story .step-3 .image-wrapper img {
    width: 50px;
  }
  #new-about-us-body #our-story .step-3 .image-wrapper img.head {
    width: 80px;
    padding: 0;
  }
  #new-about-us-body #our-story .step-3 .position {
    font-size: 14px;
    padding: 20px 0 0;
  }
  #new-about-us-body #our-story .step-3 .position .col-left {
    padding: 0 10px;
  }
  #new-about-us-body #our-story .step-3 .position .col-right {
    padding: 0 10px;
  }
  #new-about-us-body #our-story .step-4 {
    padding: 0 10px;
  }
  #new-about-us-body #our-story .step-4 img.story-3 {
    width: 130px;
  }
  #new-about-us-body #our-story .step-4 .info {
    padding: 20px 30px 0;
  }
  #new-about-us-body #our-story .step-4 .info .content {
    font-size: 16px;
  }
  #new-about-us-body #our-story .step-4 .info .open-icon {
    width: 20px;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  #new-about-us-body #our-story .step-4 .info .close-icon {
    width: 20px;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  #new-about-us-body #our-story .step-5 .content {
    font-size: 16px;
  }
  #new-about-us-body #our-story .step-5 .image-wrapper img {
    width: 20px;
  }
  #new-about-us-body #our-story .step-5 .image-wrapper img.logo {
    padding: 5px;
    width: 40%;
  }
  #new-about-us-body #our-story .step-6 img.story-4 {
    width: 200px;
  }
  #new-about-us-body #our-story .step-6 .content {
    font-size: 16px;
  }
  #new-about-us-body #our-story .step-6 .image-wrapper {
    padding-top: 15px;
  }
  #new-about-us-body #our-story .step-6 .image-wrapper img.icon {
    padding: 0 15px;
    width: 100px;
  }
  #new-about-us-body #our-story .step-6 .image-wrapper img.airplane {
    width: 50px;
  }
  #new-about-us-body #our-story .step-7 img.story-5 {
    width: 100px;
  }
  #new-about-us-body #our-story .step-7 .title {
    font-size: 16px;
  }
  #new-about-us-body #our-story .step-7 .content {
    font-size: 16px;
  }
  #new-about-us-body #our-story .step-8 {
    padding: 0 40px;
  }
  #new-about-us-body #our-story .step-8 img.story-6 {
    width: 100px;
  }
  #new-about-us-body #our-story .step-8 .info {
    position: relative;
    padding: 20px 30px 0;
  }
  #new-about-us-body #our-story .step-8 .info .title {
    font-size: 16px;
  }
  #new-about-us-body #our-story .step-8 .info .content {
    font-size: 16px;
  }
  #new-about-us-body #our-story .step-8 .info .open-icon {
    width: 20px;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  #new-about-us-body #our-story .step-8 .info .close-icon {
    width: 20px;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  #new-about-us-body #staff .profiles .profile {
    width: 100%;
  }
  #new-about-us-body #find-agency {
    padding: 40px 0;
  }
  #new-about-us-body #find-agency .container {
    padding: 0 10px;
  }
  #new-about-us-body #find-agency .content {
    padding-left: 90px;
  }
  #new-about-us-body #find-agency .content img {
    width: 50px;
    height: 50px;
    left: 20px;
  }
  #new-about-us-body #find-agency .content .title {
    font-size: 20px;
  }
}

#career-banner {
  height: 540px;
  position: relative;
  z-index: 3;
  background-size: cover;
}

#career-banner::after {
  content: '';
  display: block;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #18283e;
}

#career-banner .inner {
  position: absolute;
  margin-top: 0px;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#career-banner .inner .title {
  color: white;
  font-size: 32px;
  text-align: center;
  width: 780px;
  font-weight: bolder;
  margin: 40px auto;
  line-height: 50px;
}

#career-banner .inner .description {
  white-space: normal;
  font-size: 22px;
  color: #FFFFFF;
  text-align: center;
  width: 780px;
  margin: 25px auto;
}

#career-body .container {
  min-width: initial;
  width: 100%;
  max-width: 1160px;
  color: #18293e;
}

#career-body .container .row-1 {
  padding-top: 60px;
}

#career-body .container .row-2 {
  padding-top: 35px;
}

#career-body .container .row-3 {
  padding-top: 35px;
}

#career-body .container .row-3 .spec-header {
  padding-top: 30px;
  font-weight: 900;
  font-size: 14px;
}

#career-body .container .row-3 .spec-content {
  padding-top: 50px;
  font-size: 14px;
}

#career-body .container .title {
  font-size: 30px;
}

#career-body .container .description {
  background-color: #f7f5f1;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
}

#career-body .container .description .header {
  padding-top: 50px;
  font-weight: bolder;
  font-size: 14px;
}

#career-body .container .description .content {
  padding-top: 30px;
  font-size: 14px;
}

#career-body .container .center-box {
  background-color: #01d693;
  padding: 100px;
  width: 1130px;
  border-radius: 5px;
  margin-top: 60px;
  margin-bottom: 60px;
}

#career-body .container .center-box .inner-box {
  width: 725px;
  color: white;
  text-align: center;
  font-size: 22px;
  margin: 0 auto;
  line-height: 30px;
}

#career-body .container .center-box .inner-box a {
  text-decoration: none;
}

@media only screen and (max-width: 992px) {
  #career-banner .inner .title {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }
  #career-banner .inner .description {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }
  #career-body .container .title {
    text-align: center;
    padding-bottm: 15px;
  }
  #career-body .container .center-box {
    width: 100%;
  }
  #career-body .container .center-box .inner-box {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media only screen and (max-width: 767px) {
  #career-banner .inner {
    position: relative;
    width: 100%;
    transform: initial;
    top: initial;
    left: initial;
    padding-top: 70px;
  }
  #career-banner .inner .title {
    font-size: 26px;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }
  #career-banner .inner .description {
    font-size: 20px;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }
  #career-body .container {
    padding-right: 0;
    padding-left: 0;
  }
  #career-body .container .title {
    text-align: center;
    padding-bottm: 15px;
    font-size: 26px;
  }
  #career-body .container .center-box {
    width: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 50px;
  }
  #career-body .container .center-box .inner-box {
    line-height: 25px;
    font-size: 20px;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }
  #career-body .map {
    padding: 0px;
  }
  #career-body .map .content-box {
    width: 100%;
    background: white;
    padding: 20px 0px;
  }
  #career-body .map .content-box .body {
    font-size: 15px;
  }
  #career-body .row-3 {
    padding: 35px 0 35px 0;
  }
}

#contact-us-banner {
  height: 540px;
  position: relative;
  z-index: 3;
  background-size: cover;
}

#contact-us-banner::after {
  content: '';
  display: block;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #18283e;
}

#contact-us-banner .inner {
  position: absolute;
  margin-top: 0px;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#contact-us-banner .inner .title {
  color: white;
  font-size: 32px;
  text-align: center;
  width: 780px;
  font-weight: bolder;
  margin: 40px auto;
  line-height: 50px;
}

#contact-us-banner .inner .description {
  white-space: normal;
  font-size: 21px;
  color: #FFFFFF;
  text-align: center;
  width: 780px;
  margin: 25px auto;
}

#contact-us-body {
  display: block;
  position: relative;
  background-color: #e5edf2;
}

#contact-us-body #contact-box {
  min-width: initial;
  width: 100%;
  max-width: 1160px;
  height: 650px;
  margin-bottom: -15px;
  position: relative;
  top: -80px;
  z-index: 3;
}

#contact-us-body #contact-box .left-content {
  float: left;
  padding-top: 50px;
  width: 50%;
  font-size: 32px;
  font-weight: bolder;
  color: white;
  text-align: center;
  background: url(../../images/root/contact/cyberport-building.png) #01d693 no-repeat bottom;
  background-size: contain;
  border-radius: 4px 0 0 4px;
}

#contact-us-body #contact-box .right-content {
  float: right;
  width: 50%;
  background-color: white;
  border-radius: 0 4px 4px 0;
}

#contact-us-body #contact-box .right-content #form-contact {
  padding: 100px 75px 50px 75px;
}

#contact-us-body #contact-box .right-content #form-contact .form-group textarea.form-control {
  min-height: 240px;
  line-height: 1.3;
}

#contact-us-body #contact-box .right-content #form-contact .form-group .form-control {
  line-height: 1;
  font-style: italic;
  padding: 21px 13px;
  font-size: 14px;
  transition: background-color .5s, border .5s;
  padding: 5px;
}

#contact-us-body #contact-box .right-content #form-contact .form-group .form-control:focus {
  background-color: #cffff0;
}

#contact-us-body #contact-box .right-content #form-contact .form-group ::-webkit-input-placeholder {
  /* Chrome */
  color: #cccccc;
  font-style: italic;
}

#contact-us-body #contact-box .right-content #form-contact .form-group :-ms-input-placeholder {
  /* IE 10+ */
  color: #cccccc;
  font-style: italic;
}

#contact-us-body #contact-box .right-content #form-contact .form-group ::-moz-placeholder {
  /* Firefox 19+ */
  color: #cccccc;
  font-style: italic;
  opacity: 1;
}

#contact-us-body #contact-box .right-content #form-contact .form-group :-moz-placeholder {
  /* Firefox 4 - 18 */
  color: #cccccc;
  font-style: italic;
  opacity: 1;
}

#contact-us-body #contact-box .right-content #form-contact .btn-group {
  display: block;
}

#contact-us-body #contact-box .right-content #form-contact .btn-group .btn-effect-1 {
  width: 100%;
}

#contact-us-body .map {
  background-color: #c9c9c9;
  padding: 40px;
  position: relative;
  color: #18293e;
  min-height: 400px;
}

#contact-us-body .map .content-box {
  position: relative;
  z-index: 2;
  background: white;
  padding: 30px;
}

#contact-us-body .map .content-box .title {
  font-size: 24px;
  padding-bottom: 10px;
  font-weight: bold;
  line-height: 1;
  color: #060060;
}

#contact-us-body .map .content-box .tabs {
  padding-bottom: 10px;
  font-weight: bold;
}

#contact-us-body .map .content-box .tabs .tab {
  font-size: 14px;
  cursor: pointer;
  float: left;
  padding: 0 10px 5px 10px;
  color: #9ba8af;
  border-bottom: 2px solid #9ba8af;
}

#contact-us-body .map .content-box .tabs .tab.active {
  color: #02cd8d;
  border-color: #02cd8d;
}

#contact-us-body .map .content-box .info {
  display: none;
}

#contact-us-body .map .content-box .info.active {
  display: block;
}

#contact-us-body .map .content-box .info .body {
  font-size: 16px;
  margin-bottom: 10px;
}

#contact-us-body .map .content-box .info .body .svg-menu {
  width: 16px;
  display: inline-block;
  margin-right: 5px;
  vertical-align: text-bottom;
}

#contact-us-body .map .content-box .info .body a {
  text-decoration: none;
}

#contact-us-body .map .content-box .info .body span {
  font-size: 12px;
}

#contact-us-body .map [id^=map-canvas-] {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}

@media only screen and (max-width: 991px) {
  #contact-us-banner .inner {
    width: 100%;
  }
  #contact-us-banner .inner .title {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
  #contact-us-banner .inner .description {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
  #contact-us-body #contact-box {
    height: 500px;
  }
  #contact-us-body #contact-box .right-content #form-contact {
    padding: 30px;
  }
  #contact-us-body .map .tabs .tab {
    width: 33.3333%;
  }
}

@media only screen and (max-width: 767px) {
  #contact-us-banner .inner .container {
    padding-left: 20px;
    padding-right: 20px;
  }
  #contact-us-banner .inner .container .title {
    padding-left: 0;
    padding-right: 0;
  }
  #contact-us-banner .inner .container .description {
    padding-left: 0;
    padding-right: 0;
    font-size: 18px;
  }
  #contact-us-body #contact-box {
    height: auto;
    margin-bottom: 0;
    top: 0;
    padding: 30px 0;
    background: #fff;
  }
  #contact-us-body #contact-box .left-content {
    width: 100%;
    background: none;
    font-size: 20px;
    padding-bottom: 12px;
    margin-bottom: 30px;
    text-align: center;
    position: relative;
    color: #585858;
  }
  #contact-us-body #contact-box .left-content:after {
    position: absolute;
    display: block;
    content: '';
    width: 100px;
    height: 2px;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: #02cd8d;
  }
  #contact-us-body #contact-box .right-content {
    width: 100%;
  }
  #contact-us-body #contact-box .right-content #form-contact {
    padding: 0 30px 30px;
  }
  #contact-us-body .map {
    background-color: #e5edf2;
    padding: 0;
  }
  #contact-us-body .map .content-box {
    width: 100%;
    background: #e5edf2;
    padding: 20px 0px;
  }
  #contact-us-body .map .content-box .title {
    font-size: 20px;
  }
  #contact-us-body .map .content-box .body {
    font-size: 15px;
  }
}

#client-agreement-content {
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: #FFFFFF;
}

#client-agreement-content .title {
  text-align: center;
  display: block;
  font-size: 18px;
  font-weight: bold;
  color: #000;
  margin-bottom: 30px;
}

#client-agreement-content .label-checkbox {
  padding-left: 35px;
  line-height: 20px;
  position: relative;
  cursor: pointer;
}

#client-agreement-content .label-checkbox input[type=checkbox] {
  position: absolute;
  opacity: 0;
  width: 20px;
  height: 20px;
  left: 0;
  top: 0;
  margin: 0;
}

#client-agreement-content .label-checkbox input[type=checkbox]:checked + .checkbox {
  border-color: #02CD8D;
}

#client-agreement-content .label-checkbox input[type=checkbox]:checked + .checkbox:after {
  opacity: 1 !important;
  border-color: #02CD8D;
}

#client-agreement-content .label-checkbox input[type=checkbox]:hover + .checkbox {
  border-color: #02CD8D;
}

#client-agreement-content .label-checkbox input[type=checkbox]:hover + .checkbox:after {
  opacity: 0.5;
}

#client-agreement-content .label-checkbox .checkbox {
  border: 2px solid #bbb;
  margin: 0;
  width: 20px;
  height: 20px;
  position: absolute;
  left: 0;
  top: 0;
  transition: border-color 0.3s;
}

#client-agreement-content .label-checkbox .checkbox.error {
  border-color: red;
}

#client-agreement-content .label-checkbox .checkbox:after {
  display: block;
  opacity: 0;
  content: '';
  height: 5px;
  width: 15px;
  position: absolute;
  border-bottom: 2px solid #bbb;
  border-left: 2px solid #bbb;
  /* IE 9 */
  /* Chrome, Safari, Opera */
  transform: rotate(-45deg) translate(-50%, -50%);
  left: 50%;
  transition: opacity 0.3s, border-color 0.3s;
}

#client-agreement-content ol.number {
  list-style-type: decimal;
}

#client-agreement-content ol.alphabet {
  list-style: lower-alpha;
}

#client-agreement-content ol.roman {
  list-style: lower-roman;
}

#client-agreement-content ol li {
  padding-top: 3px;
  padding-bottom: 3px;
}

#client-dashboard-content {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #f0f4f7;
}

#client-dashboard-content .box {
  margin-bottom: 20px;
}

#client-dashboard-content .box .box-pre-heading {
  font-size: 0;
}

#client-dashboard-content .box .box-pre-heading span {
  display: inline-block;
  padding: 8px 0;
  border-bottom: 4px solid #02cd8d;
  color: #333333;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: bold;
}

#client-dashboard-content .box .box-heading {
  padding: 25px 25px;
  border: 1px solid #d4f0e3;
  border-top: 0;
  background-color: #ccd9e0;
  color: #585858;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: bold;
}

#client-dashboard-content .box .box-body {
  border: 1px solid #d4f0e3;
  border-top: 0;
  padding: 15px 25px;
  background-color: #FFFFFF;
  font-family: "Roboto", sans-serif;
}

#client-dashboard-content .box.box-notification .box-body ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#client-dashboard-content .box.box-notification .box-body ul li {
  display: block;
  padding: 10px 0;
  position: relative;
}

#client-dashboard-content .box.box-notification .box-body ul li span {
  color: #008758;
}

#client-dashboard-content .box.box-notification .box-body ul li:not(:first-child) {
  border-top: 2px solid #e9f7f1;
}

#client-dashboard-content .box.box-notification .box-body ul li .btn-close {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  transition: color .3s;
  font-size: 20px;
  cursor: pointer;
  color: #edefef;
}

#client-dashboard-content .box.box-notification .box-body ul li .btn-close:hover {
  color: #0F0F0F;
}

#client-dashboard-content .box.box-message .box-body .messages {
  display: table;
  width: 100%;
}

#client-dashboard-content .box.box-message .box-body .messages .message {
  display: table-row;
}

#client-dashboard-content .box.box-message .box-body .messages .message > [class^=col] {
  display: table-cell;
  padding: 17px 17px;
  vertical-align: top;
}

#client-dashboard-content .box.box-message .box-body .messages .message > [class^=col]:first-child {
  padding-left: 0;
}

#client-dashboard-content .box.box-message .box-body .messages .message > [class^=col]:last-child {
  padding-right: 0;
}

#client-dashboard-content .box.box-message .box-body .messages .message .col-logo {
  width: 82px;
}

#client-dashboard-content .box.box-message .box-body .messages .message .col-logo img {
  max-width: 100%;
}

#client-dashboard-content .box.box-message .box-body .messages .message .col-info {
  width: 150px;
  color: #585858;
}

#client-dashboard-content .box.box-message .box-body .messages .message .col-info .name {
  font-weight: bold;
  margin-bottom: 5px;
}

#client-dashboard-content .box.box-message .box-body .messages .message .col-details .title {
  font-weight: bold;
  margin-bottom: 5px;
}

#client-dashboard-content .box.box-message .box-body .messages .message .col-action {
  vertical-align: middle;
  width: 130px;
}

#client-dashboard-content .box.box-message .box-body .messages .message .col-action a {
  border: 1px solid #585858;
  border-radius: 4px;
  color: #585858;
  display: inline-block;
  width: 100%;
  padding: 9px 0;
  font-size: 12px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}

#client-dashboard-content .box.box-message .box-body .messages .message .col-action a:hover {
  text-decoration: none;
}

#client-dashboard-content .box.box-message .box-body .messages .message:not(:first-child) > [class^=col] {
  border-top: 2px solid #e9f7f1;
}

#client-dashboard-content .box.box-profile .box-body {
  position: relative;
  padding: 22px;
  min-height: 144px;
}

#client-dashboard-content .box.box-profile .box-body .profile-image {
  position: absolute;
  top: 22px;
  left: 22px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #d8d8d8;
}

#client-dashboard-content .box.box-profile .box-body .profile-image span {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  color: #585858;
  transform: translate(-50%, -50%);
  font-family: "Roboto", sans-serif;
  font-size: 26px;
  font-weight: bold;
}

#client-dashboard-content .box.box-profile .box-body .links {
  padding-left: 128px;
}

#client-dashboard-content .box.box-profile .box-body .links a {
  display: block;
  color: #008758;
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  padding: 7px 0;
}

#client-dashboard-content .box.box-profile .box-body .links a:hover {
  text-decoration: none;
}

#client-dashboard-content .box.box-profile .box-body .links a:not(:first-child) {
  border-top: 2px solid #e9f7f1;
}

#client-dashboard-content .box.box-verify .box-body ul {
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: left;
}

#client-dashboard-content .box.box-verify .box-body ul li {
  display: block;
  padding: 15px 42px 15px 0;
  margin: 0;
  position: relative;
}

#client-dashboard-content .box.box-verify .box-body ul li .label {
  color: #585858;
  font-size: 14px;
  font-weight: normal;
  font-family: "Roboto", sans-serif;
  margin: 0;
  float: none;
  text-align: left;
  padding: 0;
  display: block;
}

#client-dashboard-content .box.box-verify .box-body ul li .status {
  position: absolute;
  top: 0;
  right: 0;
}

#client-dashboard-content .box.box-verify .box-body ul li .status .tick {
  display: block;
  width: 32px;
  height: 32px;
  background: url("../../images/root/all.png") no-repeat -425px -32px;
}

#client-dashboard-content .box.box-verify .box-body ul li a {
  color: #008758;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: normal;
  font-family: "Roboto", sans-serif;
}

#client-dashboard-content .box.box-verify .box-body ul li:not(:first-child) > [class^=col] {
  border-top: 2px solid #e9f7f1;
}

#client-dashboard-content .box.box-colorful {
  position: relative;
  background-image: linear-gradient(to bottom right, #03cd8e, #7b93ba, #c86dd7);
  padding: 15px;
}

#client-dashboard-content .box.box-colorful .bg-white {
  background-color: #FFFFFF;
  padding: 20px;
}

#client-dashboard-content .box.box-colorful .bg-white span {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  letter-spacing: 1px;
  color: #02cd8d;
  margin-bottom: 10px;
  display: block;
  font-weight: bold;
}

#client-dashboard-content .box.box-colorful .bg-white ul {
  display: block;
  padding: 0;
  margin: 0 0 15px;
  list-style: none;
}

#client-dashboard-content .box.box-colorful .bg-white ul li {
  padding-top: 5px;
  padding-bottom: 5px;
  color: #888888;
  font-weight: 400;
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  letter-spacing: 1px;
}

#client-dashboard-content .box.box-colorful .bg-white ul li img {
  vertical-align: middle;
  display: inline-block;
  margin-right: 10px;
}

#client-dashboard-content .box.box-colorful button {
  width: 100%;
  letter-spacing: 1px;
}

#client-account-content {
  padding-top: 25px;
  padding-bottom: 25px;
  background-color: #f0f4f7;
}

#client-account-content .wrapper .wrapper-title {
  color: #333333;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  letter-spacing: 1px;
}

#client-account-content .wrapper .tab-links ul {
  padding: 10px 0;
  margin: 0;
  list-style: none;
}

#client-account-content .wrapper .tab-links ul li {
  display: block;
  border-bottom: 2px solid #e2f2ed;
}

#client-account-content .wrapper .tab-links ul li a {
  padding: 12px 19px;
  display: block;
  color: #888888;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  background-color: transparent;
  transition: background-color .3s, color .3s;
  cursor: pointer;
}

#client-account-content .wrapper .tab-links ul li a.active {
  background-color: #FFFFFF;
  color: #585858;
}

#client-account-content .wrapper .tab-links ul li a:hover {
  text-decoration: none;
}

#client-account-content .wrapper .tab-links > a {
  margin-left: 19px;
  font-size: 13px;
}

#client-account-content .tab-body {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s .5s, opacity .5s linear;
  height: 0;
  overflow: hidden;
}

#client-account-content .tab-body.active {
  overflow: visible;
  height: auto;
  visibility: visible;
  opacity: 1;
  transition: opacity .5s linear;
}

#client-account-content .tab-body.setting .box.box-account .box-body {
  padding: 15px 100px 15px 25px;
}

#client-account-content .tab-body.setting .box.box-account .box-body .form-group {
  margin-bottom: 25px;
}

#client-account-content .tab-body.setting .box.box-account .box-body .form-group .fa {
  font-size: 25px;
  margin-right: 10px;
  color: #6b6b6b;
}

#client-account-content .tab-body.setting .box.box-account .box-body .form-group .label-control {
  color: #585858;
  font-size: 14px;
  font-weight: normal;
  font-family: "Roboto", sans-serif;
  line-height: 1.5;
  padding: 10px 0 10px 85px;
}

#client-account-content .tab-body.setting .box.box-account .box-body .form-group input.form-control,
#client-account-content .tab-body.setting .box.box-account .box-body .form-group select.form-control {
  border: 1px solid #e1e3e3;
  border-radius: 4px;
  padding: 10px 20px;
  line-height: 1;
  outline: 0;
  font-size: 14px;
  color: #585858;
  box-shadow: none;
  font-family: "Roboto", sans-serif;
  background-color: #FFFFFF;
  font-weight: normal;
  height: 43px;
}

#client-account-content .tab-body.setting .box.box-account .box-body .form-group input.form-control:focus,
#client-account-content .tab-body.setting .box.box-account .box-body .form-group select.form-control:focus {
  box-shadow: none;
  border: 1px solid #e1e3e3;
  outline: 0;
}

#client-account-content .tab-body.setting .box.box-account .box-body .form-group input.form-control:disabled,
#client-account-content .tab-body.setting .box.box-account .box-body .form-group select.form-control:disabled {
  background-color: #e1e3e3;
}

#client-account-content .tab-body.setting .box.box-account .box-body .form-group .input-check-status {
  color: #585858;
  font-size: 14px;
  font-weight: normal;
  font-family: "Roboto", sans-serif;
  line-height: 1.5;
  padding: 10px 0;
  white-space: nowrap;
}

#client-account-content .tab-body.setting .box.box-account .box-body .form-group .input-check-status a {
  color: #02cd8d;
  cursor: pointer;
}

#client-account-content .tab-body.setting .box.box-account .box-body .form-group .helper {
  color: #999999;
  padding-top: 8px;
  font-size: 12px;
  font-weight: normal;
  font-family: "Roboto", sans-serif;
}

#client-account-content .tab-body.setting .box.box-account .box-body .form-group:after {
  content: '';
  display: table;
  clear: both;
}

#client-account-content .tab-body.setting .box.box-account .box-body .content-group {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  color: #999999;
}

#client-account-content .tab-body.setting .box.box-account .box-body .content-group .black {
  font-weight: bold;
  color: #585858;
  padding-top: 20px;
}

#client-account-content .tab-body.setting .box.box-account .box-body .content-group:after {
  content: '';
  display: table;
  clear: both;
}

#client-account-content .tab-body.setting .tab-body-legend:after {
  width: 35px;
}

#client-account-content .tab-body.membership .box.box-membership-status .box-body table td, #client-account-content .tab-body.membership .box.box-membership-status .box-body table th {
  padding: 13px 40px;
  color: #585858;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  min-width: 110px;
  box-sizing: border-box;
}

#client-account-content .tab-body.membership .box.box-membership-status .box-body table td:first-child, #client-account-content .tab-body.membership .box.box-membership-status .box-body table th:first-child {
  padding-left: 0;
}

#client-account-content .tab-body.membership .box.box-membership-status .box-body table td:last-child, #client-account-content .tab-body.membership .box.box-membership-status .box-body table th:last-child {
  padding-right: 0;
}

#client-account-content .tab-body.membership .box.box-membership-status .box-body table td {
  border-top: 1px solid #d4f0e3;
}

#client-account-content .tab-body.membership .box.box-membership-status .box-body table .green {
  color: #02cd8d;
  font-weight: bold;
}

#client-account-content .tab-body.membership .box.box-membership-status .box-body table a {
  cursor: pointer;
}

#client-account-content .tab-body.membership .box.box-history .box-body table {
  width: 100%;
}

#client-account-content .tab-body.membership .box.box-history .box-body table td, #client-account-content .tab-body.membership .box.box-history .box-body table th {
  padding: 13px 30px;
  color: #585858;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  box-sizing: border-box;
}

#client-account-content .tab-body.membership .box.box-history .box-body table td:first-child, #client-account-content .tab-body.membership .box.box-history .box-body table th:first-child {
  padding-left: 0;
}

#client-account-content .tab-body.membership .box.box-history .box-body table td:last-child, #client-account-content .tab-body.membership .box.box-history .box-body table th:last-child {
  padding-right: 0;
}

#client-account-content .tab-body.membership .box.box-history .box-body table td {
  border-top: 1px solid #d4f0e3;
}

#client-account-content .tab-body.membership .box.box-history .box-body table .green {
  color: #02cd8d;
  font-weight: bold;
}

#client-account-content .tab-body.membership .box.box-history .box-body table a {
  cursor: pointer;
}

#client-account-content .tab-body.security .box.box-security .box-body {
  padding: 15px 100px 15px 25px;
}

#client-account-content .tab-body.security .box.box-security .box-body .form-group {
  margin-bottom: 25px;
}

#client-account-content .tab-body.security .box.box-security .box-body .form-group .label-control {
  color: #585858;
  font-size: 14px;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  line-height: 1.5;
  padding: 10px 0 10px 85px;
}

#client-account-content .tab-body.security .box.box-security .box-body .form-group input.form-control {
  border: 1px solid #e1e3e3;
  border-radius: 4px;
  padding: 10px 20px;
  line-height: 1;
  outline: 0;
  font-size: 14px;
  height: auto;
  color: #585858;
  box-shadow: none;
  font-family: "Roboto", sans-serif;
  background-color: #FFFFFF;
  font-weight: bold;
  width: 100%;
  max-width: 250px;
}

#client-account-content .tab-body.security .box.box-security .box-body .form-group input.form-control:focus {
  box-shadow: none;
  border: 1px solid #e1e3e3;
  outline: 0;
}

#client-account-content .tab-body.security .box.box-security .box-body .form-group input.form-control:disabled {
  background-color: #e1e3e3;
}

#client-account-content .tab-body.security .box.box-security .box-body .form-group:after {
  content: '';
  display: table;
  clear: both;
}

#client-account-content .tab-body .box {
  border: 1px solid #d4f0e3;
  margin-bottom: 20px;
}

#client-account-content .tab-body .box .box-heading {
  padding: 25px 25px;
  background-color: #ccd9e0;
  color: #585858;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: bold;
}

#client-account-content .tab-body .box .box-body {
  padding: 15px 25px;
  background-color: #FFFFFF;
  font-family: "Roboto", sans-serif;
}

#client-account-content .tab-body .box .box-footer {
  border-top: 1px solid #d4f0e3;
  padding: 15px 25px;
  background-color: #FFFFFF;
}

#client-account-content .tab-body .checkboxes input[type=checkbox] {
  visibility: hidden;
  opacity: 0;
}

#client-account-content .tab-body .checkboxes label {
  display: block;
  margin: 0 0 10px;
  padding-left: 30px;
  position: relative;
  font-size: 12px;
  cursor: pointer;
  font-weight: normal;
}

#client-account-content .tab-body .checkboxes label span {
  display: inline-block;
  vertical-align: middle;
}

#client-account-content .tab-body .checkboxes label span.checkbox {
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid #d4f0e3;
  background-color: #d8f1e6;
  border-radius: 2px;
  margin: 0 10px 0 0;
  width: 20px;
  height: 20px;
  transition: background-color .3s;
}

#client-account-content .tab-body .checkboxes label span.checkbox input {
  visibility: hidden;
  opacity: 0;
}

#client-account-content .tab-body .checkboxes label.checked span.checkbox {
  background-color: #FFFFFF;
  border: 1px solid #02cd8d;
}

#client-account-content .tab-body .checkboxes label.checked span.checkbox:before {
  top: 50%;
  left: 50%;
  content: '';
  position: absolute;
  height: 6px;
  width: 15px;
  border-left: 2px solid #02cd8d;
  border-bottom: 2px solid #02cd8d;
  display: block;
  margin: 0 auto;
  transform: translate(-50%, -55%) rotate(-45deg);
  -webkit-transform: translate(-50%, -55%) rotate(-45deg);
  -moz-transform: translate(-50%, -55%) rotate(-45deg);
  -ms-transform: translate(-50%, -55%) rotate(-45deg);
  -o-transform: translate(-50%, -55%) rotate(-45deg);
}

#client-account-content .tab-body .checkboxes label.disabled span.checkbox {
  background-color: #dddddd;
  border: 1px solid #5f5f5f;
}

#client-account-content .tab-body .checkboxes label.disabled span.checkbox:before {
  border-left: 2px solid #5f5f5f;
  border-bottom: 2px solid #5f5f5f;
}

#client-account-content .tab-body .checkboxes label:hover:not(.checked) span.checkbox, #client-account-content .tab-body .checkboxes label:hover:not(.disabled) span.checkbox {
  background-color: #d4f0e3;
}

#client-account-content .tab-body .checkboxes label:hover.checked:not(.disabled) span.checkbox {
  background-color: #d8f1e6;
}

#client-account-content .tab-body .checkboxes label:hover.checked:not(.disabled) span.checkbox:before {
  border-left: 2px solid #02cd8d;
  border-bottom: 2px solid #02cd8d;
}

#client-account-content .tab-body .checkboxes input[type=text] {
  display: inline-block;
  width: auto;
}

#client-account-content .tab-body .checkboxes .checkbox-group {
  margin-bottom: 10px;
}

#client-account-content .tab-body .checkboxes .checkbox-group .checkbox-group {
  padding-left: 35px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s .5s, opacity .5s linear;
  height: 0;
  overflow: hidden;
  margin-bottom: 0;
}

#client-account-content .tab-body .checkboxes .checkbox-group .checkbox-group.active {
  margin-bottom: 10px;
  overflow: visible;
  height: auto;
  visibility: visible;
  opacity: 1;
  transition: opacity .5s linear;
}

#client-account-content .tab-body .checkboxes .checkbox-group label {
  margin-bottom: 0;
}

#client-account-content .tab-body .checkboxes .checkbox-group .extend {
  display: inline-block;
  margin-left: 10px;
  color: #02cd8d;
  font-size: 24px;
  font-weight: bold;
}

#client-account-content .tab-body .btn {
  display: inline-block;
  background-color: #02cd8d;
  position: relative;
  padding: 10px 25px 13px;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  color: #ffffe7;
  cursor: pointer;
  white-space: nowrap;
  text-decoration: none;
}

#client-account-content .tab-body .btn span {
  position: relative;
  z-index: 2;
}

#client-account-content .tab-body .btn:after {
  transition: height .3s;
  height: 5px;
  width: 100%;
  position: absolute;
  bottom: 0;
  background-color: #28ac78;
  left: 0;
  content: '';
  z-index: 1;
}

#client-account-content .tab-body .btn:hover:after {
  height: 100%;
}

#client-account-content .tab-body .tab-body-legend {
  font-size: 20px;
  color: #585858;
  line-height: 1;
  font-weight: bolder;
  margin-bottom: 20px;
  position: relative;
  padding-bottom: 12px;
  float: left;
}

#client-account-content .tab-body .tab-body-legend:after {
  content: '';
  display: block;
  height: 2px;
  position: absolute;
  bottom: 0;
  width: 100px;
  max-width: 100%;
  left: 50%;
  background-color: #02cd8d;
  transform: translateX(-50%);
}

@media only screen and (max-width: 992px) {
  #client-account-content .tab-body.setting .box.box-account .box-body {
    padding-left: 0px;
  }
  #client-account-content .tab-body.setting .box.box-account .box-body .form-group .fa {
    margin-right: 5px;
  }
  #client-account-content .tab-body.setting .box.box-account .box-body .form-group .label-control {
    padding: 10px 0 10px 20px;
  }
  #client-account-content .tab-body.security .box.box-security .box-body {
    padding: 20px 0;
  }
}

@media only screen and (max-width: 767px) {
  #client-account-content .wrapper .container {
    padding-left: 0px;
    padding-right: 0px;
  }
  #client-account-content .tab-body .tab-body-legend {
    padding-left: 20px;
    padding-right: 20px;
  }
  #client-account-content .tab-body.setting .box.box-account .box-heading {
    padding: 15px 20px;
  }
  #client-account-content .tab-body.setting .box.box-account .box-body {
    padding: 20px 0;
  }
  #client-account-content .tab-body.setting .box.box-account .box-body .form-group {
    margin-bottom: 20px;
  }
  #client-account-content .tab-body.setting .box.box-account .box-body .form-group.position select.form-control {
    max-width: 100%;
  }
  #client-account-content .tab-body.security .box.box-security .box-heading {
    padding: 15px 20px;
  }
  #client-account-content .tab-body.security .box.box-security .box-body {
    padding: 20px 0;
  }
  #client-account-content .tab-body.security .box.box-security .box-body .form-group {
    margin-bottom: 20px;
  }
  #client-account-content .tab-body.security .box.box-security .box-body .form-group.position select.form-control {
    max-width: 100%;
  }
  #client-account-content .tab-body.security .box.box-security .box-body .form-group .label-control {
    padding-left: 20px;
  }
  #client-account-content .tab-body.security .box.box-security .box-body .form-group input.form-control {
    max-width: 100%;
  }
  #client-account-content .tab-body .box .box-heading {
    padding: 15px 20px;
  }
  #client-account-content .tab-body .button-group {
    padding-left: 20px;
    padding-right: 20px;
  }
}

#client-profile-content {
  padding-top: 25px;
  padding-bottom: 25px;
  background-color: #f0f4f7;
}

#client-profile-content .wrapper .wrapper-title {
  color: #333333;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  letter-spacing: 1px;
}

#client-profile-content .wrapper .tab-links ul {
  padding: 10px 0;
  margin: 0;
  list-style: none;
}

#client-profile-content .wrapper .tab-links ul li {
  display: block;
  border-bottom: 2px solid #e2f2ed;
}

#client-profile-content .wrapper .tab-links ul li a {
  padding: 12px 19px;
  display: block;
  color: #888888;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  background-color: transparent;
  transition: background-color .3s, color .3s;
  cursor: pointer;
}

#client-profile-content .wrapper .tab-links ul li a.active {
  background-color: #FFFFFF;
  color: #585858;
}

#client-profile-content .wrapper .tab-links ul li a:hover {
  text-decoration: none;
}

#client-profile-content .wrapper .tab-links > a {
  margin-left: 19px;
  font-size: 13px;
}

#client-profile-content .tab-body {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s .5s, opacity .5s linear;
  height: 0;
  overflow: hidden;
}

#client-profile-content .tab-body.active {
  overflow: visible;
  height: auto;
  visibility: visible;
  opacity: 1;
  transition: opacity .5s linear;
}

#client-profile-content .tab-body.client-profile .box .box-body {
  padding: 15px 100px 15px 25px;
}

#client-profile-content .tab-body.client-profile .box .box-body .form-group {
  margin-bottom: 25px;
}

#client-profile-content .tab-body.client-profile .box .box-body .form-group .label-control {
  color: #585858;
  font-size: 14px;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  line-height: 1.5;
  padding: 10px 0 10px 85px;
}

#client-profile-content .tab-body.client-profile .box .box-body .form-group input.form-control,
#client-profile-content .tab-body.client-profile .box .box-body .form-group textarea.form-control,
#client-profile-content .tab-body.client-profile .box .box-body .form-group select.form-control {
  border: 1px solid #e1e3e3;
  border-radius: 4px;
  padding: 10px 20px;
  line-height: 1;
  outline: 0;
  font-size: 14px;
  height: auto;
  color: #585858;
  box-shadow: none;
  font-family: "Roboto", sans-serif;
  background-color: #FFFFFF;
  font-weight: bold;
  height: 43px;
}

#client-profile-content .tab-body.client-profile .box .box-body .form-group input.form-control:focus,
#client-profile-content .tab-body.client-profile .box .box-body .form-group textarea.form-control:focus,
#client-profile-content .tab-body.client-profile .box .box-body .form-group select.form-control:focus {
  box-shadow: none;
  border: 1px solid #e1e3e3;
  outline: 0;
}

#client-profile-content .tab-body.client-profile .box .box-body .form-group input.form-control:disabled,
#client-profile-content .tab-body.client-profile .box .box-body .form-group textarea.form-control:disabled,
#client-profile-content .tab-body.client-profile .box .box-body .form-group select.form-control:disabled {
  background-color: #e1e3e3;
}

#client-profile-content .tab-body.client-profile .box .box-body .form-group textarea.form-control {
  height: 180px;
}

#client-profile-content .tab-body.client-profile .box .box-body .form-group .helper {
  color: #999999;
  padding-top: 8px;
  font-size: 12px;
  font-weight: normal;
  font-family: "Roboto", sans-serif;
}

#client-profile-content .tab-body.client-profile .box .box-body .form-group:after {
  content: '';
  display: table;
  clear: both;
}

#client-profile-content .tab-body.client-profile .box .box-body .form-group.position select.form-control {
  width: 100%;
  max-width: 190px;
}

#client-profile-content .tab-body.client-profile .box .box-body .upload-wrapper {
  position: relative;
}

#client-profile-content .tab-body.client-profile .box .box-body .upload-wrapper .upload-preview {
  width: 169px;
  height: 169px;
  position: absolute;
  border: 3px solid #e1e3e3;
  left: 0;
  top: 0;
}

#client-profile-content .tab-body.client-profile .box .box-body .upload-wrapper .upload-preview img:not(.remove-file) {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
}

#client-profile-content .tab-body.client-profile .box .box-body .upload-wrapper .upload-preview img.remove-file {
  cursor: pointer;
  position: absolute;
  left: 100%;
  bottom: 100%;
  transform: translate(-50%, 50%);
}

#client-profile-content .tab-body.client-profile .box .box-body .upload-wrapper .upload-preview + .upload-description {
  padding-left: 200px;
  min-height: 175px;
}

#client-profile-content .tab-body.client-profile .box .box-body .upload-wrapper .upload-description .helper + .label-file {
  margin-top: 15px;
}

#client-profile-content .tab-body.client-profile .box .box-body .upload-wrapper .upload-description .label-file label {
  font-size: 14px;
  padding: 5px 15px 11px;
  margin-right: 20px;
  position: relative;
  border-radius: 3px;
  background-color: #02cd8d;
  cursor: pointer;
  overflow: hidden;
}

#client-profile-content .tab-body.client-profile .box .box-body .upload-wrapper .upload-description .label-file label:after {
  position: absolute;
  bottom: 0;
  height: 6px;
  left: 0;
  right: 0;
  background-color: #28ac78;
  transition: height .3s;
  content: '';
  display: block;
  z-index: 1;
}

#client-profile-content .tab-body.client-profile .box .box-body .upload-wrapper .upload-description .label-file label span {
  position: relative;
  z-index: 2;
  color: #FFFFFF;
}

#client-profile-content .tab-body.client-profile .box .box-body .upload-wrapper .upload-description .label-file label:hover:after {
  height: 100%;
}

#client-profile-content .tab-body.client-profile .box .box-body .upload-wrapper .upload-description .label-file input[type=file] {
  display: none;
}

#client-profile-content .tab-body.client-profile .box .box-body .upload-wrapper .upload-description .label-file input[type=text] {
  border: none;
  padding: 0;
  display: block;
}

#client-profile-content .tab-body.business-profile .preview {
  display: inline-block;
  padding: 15px 20px;
  border: 1px solid #585858;
  color: #585858;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  border-radius: 2px;
}

#client-profile-content .tab-body.business-profile .preview:hover {
  text-decoration: none;
}

#client-profile-content .tab-body.business-profile .box-top {
  background-color: #f3f3f3;
  border: 1px solid #e2e5e7;
  padding: 3px 0;
  margin-bottom: 30px;
  margin-top: 30px;
}

#client-profile-content .tab-body.business-profile .box-top .box-row {
  display: table;
  width: 100%;
}

#client-profile-content .tab-body.business-profile .box-top .box-row .box-col {
  display: table-cell;
  vertical-align: middle;
}

#client-profile-content .tab-body.business-profile .box-top .box-row .box-col:not(:first-child) {
  border-left: 1px solid #e2e5e7;
}

#client-profile-content .tab-body.business-profile .box-top .box-row .box-col.col-progress {
  width: 1px;
  padding: 10px 33px;
  white-space: nowrap;
  text-align: center;
}

#client-profile-content .tab-body.business-profile .box-top .box-row .box-col.col-progress #business-progress-circle {
  margin: 0 auto;
  width: 98px;
}

#client-profile-content .tab-body.business-profile .box-top .box-row .box-col.col-type .types {
  text-align: center;
}

#client-profile-content .tab-body.business-profile .box-top .box-row .box-col.col-type .types .type {
  cursor: pointer;
  display: inline-block;
  width: 130px;
}

#client-profile-content .tab-body.business-profile .box-top .box-row .box-col.col-type .types .type:before {
  content: '';
  display: block;
  width: 50px;
  height: 50px;
  background-image: url("../../images/root/all.png");
  margin: 0 auto 15px;
}

#client-profile-content .tab-body.business-profile .box-top .box-row .box-col.col-type .types .type.with-tick {
  position: relative;
}

#client-profile-content .tab-body.business-profile .box-top .box-row .box-col.col-type .types .type.with-tick:after {
  content: '';
  width: 21px;
  height: 21px;
  background: url(../../images/root/all.png) white;
  background-position-y: -555px;
  background-position-x: -767px;
  position: absolute;
  top: -5px;
  right: 35px;
  border-radius: 50%;
  border: 1px solid white;
}

#client-profile-content .tab-body.business-profile .box-top .box-row .box-col.col-type .types .type.type-about:before {
  background-position-y: -738px;
  background-position-x: -321px;
}

#client-profile-content .tab-body.business-profile .box-top .box-row .box-col.col-type .types .type.type-service:before {
  background-position-y: -739px;
  background-position-x: -191px;
}

#client-profile-content .tab-body.business-profile .box-top .box-row .box-col.col-type .types .type.type-detail:before {
  background-position-y: -736px;
  background-position-x: -74px;
}

#client-profile-content .tab-body.business-profile .box-top .box-row .box-col.col-type .types .type.type-portfolio:before {
  background-position-y: -630px;
  background-position-x: -881px;
}

#client-profile-content .tab-body.business-profile .box-top .box-row .box-col.col-type .types .type span {
  display: inline-block;
  line-height: 1;
  padding-bottom: 3px;
  font-weight: bold;
}

#client-profile-content .tab-body.business-profile .box-top .box-row .box-col.col-type .types .type.current span {
  border-bottom: 3px solid #02cd8d;
}

#client-profile-content .tab-body.business-profile .box-top .box-row .box-col.col-status {
  width: 160px;
  padding: 0 30px;
  font-size: 14px;
  color: #585858;
  text-align: center;
  line-height: 1.8;
}

#client-profile-content .tab-body.business-profile .box-top .box-row .box-col.col-status .label {
  line-height: 1;
  font-weight: bold;
  color: #585858;
  display: block;
  margin-bottom: 5px;
  font-size: 16px;
}

#client-profile-content .tab-body.business-profile .box .box-body {
  padding: 15px 100px 15px 25px;
}

#client-profile-content .tab-body.business-profile .box .box-body .form-group {
  margin-bottom: 25px;
}

#client-profile-content .tab-body.business-profile .box .box-body .form-group input.form-control,
#client-profile-content .tab-body.business-profile .box .box-body .form-group textarea.form-control,
#client-profile-content .tab-body.business-profile .box .box-body .form-group select.form-control {
  border: 1px solid #e1e3e3;
  border-radius: 4px;
  padding: 10px 20px;
  line-height: 1;
  outline: 0;
  font-size: 14px;
  height: auto;
  color: #585858;
  box-shadow: none;
  font-family: "Roboto", sans-serif;
  background-color: #FFFFFF;
  font-weight: bold;
}

#client-profile-content .tab-body.business-profile .box .box-body .form-group input.form-control:focus,
#client-profile-content .tab-body.business-profile .box .box-body .form-group textarea.form-control:focus,
#client-profile-content .tab-body.business-profile .box .box-body .form-group select.form-control:focus {
  box-shadow: none;
  border: 1px solid #e1e3e3;
  outline: 0;
}

#client-profile-content .tab-body.business-profile .box .box-body .form-group input.form-control:disabled,
#client-profile-content .tab-body.business-profile .box .box-body .form-group textarea.form-control:disabled,
#client-profile-content .tab-body.business-profile .box .box-body .form-group select.form-control:disabled {
  background-color: #e1e3e3;
}

#client-profile-content .tab-body.business-profile .box .box-body .form-group textarea.form-control {
  height: 180px;
}

#client-profile-content .tab-body.business-profile .box .box-body .form-group .helper {
  color: #999999;
  padding-top: 8px;
  font-size: 12px;
  font-weight: normal;
  font-family: "Roboto", sans-serif;
}

#client-profile-content .tab-body.business-profile .box .box-body .form-group:after {
  content: '';
  display: table;
  clear: both;
}

#client-profile-content .tab-body.business-profile .box .box-body .form-group.position select.form-control {
  width: 100%;
  max-width: 190px;
}

#client-profile-content .tab-body.business-profile .box .box-body .form-group .group-header a {
  float: left;
  text-align: center;
  display: block;
  padding: 5px;
  width: 55px;
  color: #585858;
  border-top: 1px solid #eaeaea;
  border-left: 1px solid #eaeaea;
}

#client-profile-content .tab-body.business-profile .box .box-body .form-group .group-header a:last-child {
  border-right: 1px solid #eaeaea;
}

#client-profile-content .tab-body.business-profile .box .box-body .form-group .group-header a.active {
  background-color: #eaeaea;
}

#client-profile-content .tab-body.business-profile .box .box-body .form-group .group-header a:hover {
  text-decoration: none;
}

#client-profile-content .tab-body.business-profile .box .box-body .form-group .group-header a:hover:not(.active) {
  cursor: pointer;
}

#client-profile-content .tab-body.business-profile .box .box-body .form-group .group-body {
  border: 1px solid #eaeaea;
}

#client-profile-content .tab-body.business-profile .box .box-body .form-group .group-body .language {
  display: none;
}

#client-profile-content .tab-body.business-profile .box .box-body .form-group .group-body .language input {
  padding: 10px 15px;
  width: 100%;
  border: none;
  background-color: #FFFFFF;
  outline: 0;
}

#client-profile-content .tab-body.business-profile .box .box-body .form-group .group-body .language.active {
  display: block;
}

#client-profile-content .tab-body.business-profile .box .box-body .form-group.with-group .label-control {
  padding-top: 38px;
}

#client-profile-content .tab-body.business-profile .box .box-body .form-group .label-control {
  color: #585858;
  font-size: 14px;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  line-height: 1.5;
  padding-top: 10px;
}

#client-profile-content .tab-body.business-profile .box .box-body .upload-wrapper {
  position: relative;
}

#client-profile-content .tab-body.business-profile .box .box-body .upload-wrapper .upload-preview {
  width: 169px;
  height: 169px;
  position: absolute;
  border: 3px solid #e1e3e3;
  left: 0;
  top: 0;
}

#client-profile-content .tab-body.business-profile .box .box-body .upload-wrapper .upload-preview img:not(.remove-file) {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
}

#client-profile-content .tab-body.business-profile .box .box-body .upload-wrapper .upload-preview img.remove-file {
  cursor: pointer;
  position: absolute;
  left: 100%;
  bottom: 100%;
  transform: translate(-50%, 50%);
}

#client-profile-content .tab-body.business-profile .box .box-body .upload-wrapper .upload-preview + .upload-description {
  padding-left: 200px;
  min-height: 175px;
}

#client-profile-content .tab-body.business-profile .box .box-body .upload-wrapper .upload-description .helper + .label-file {
  margin-top: 15px;
}

#client-profile-content .tab-body.business-profile .box .box-body .upload-wrapper .upload-description .label-file label {
  font-size: 14px;
  padding: 5px 15px 11px;
  margin-right: 20px;
  position: relative;
  border-radius: 3px;
  background-color: #02cd8d;
  cursor: pointer;
  overflow: hidden;
}

#client-profile-content .tab-body.business-profile .box .box-body .upload-wrapper .upload-description .label-file label:after {
  position: absolute;
  bottom: 0;
  height: 6px;
  left: 0;
  right: 0;
  background-color: #0d513b;
  transition: height .3s;
  content: '';
  display: block;
  z-index: 1;
}

#client-profile-content .tab-body.business-profile .box .box-body .upload-wrapper .upload-description .label-file label span {
  position: relative;
  z-index: 2;
  color: #FFFFFF;
}

#client-profile-content .tab-body.business-profile .box .box-body .upload-wrapper .upload-description .label-file label:hover:after {
  height: 100%;
}

#client-profile-content .tab-body.business-profile .box .box-body .upload-wrapper .upload-description .label-file input[type=file] {
  display: none;
}

#client-profile-content .tab-body.business-profile .box .box-body .upload-wrapper .upload-description .label-file input[type=text] {
  border: none !important;
  padding: 0 !important;
  display: block !important;
}

#client-profile-content .tab-body.business-profile .box-tab {
  display: none;
}

#client-profile-content .tab-body.business-profile .box-tab.active {
  display: block;
}

#client-profile-content .tab-body.business-profile .box-tab.service .box-body {
  padding-right: 25px;
}

#client-profile-content .tab-body.business-profile .box-tab.service .box-body .service-input-group {
  margin-bottom: 30px;
}

#client-profile-content .tab-body.business-profile .box-tab.service .box-body .service-input-group label {
  border: 1px solid #eaeaea;
  display: block;
  font-weight: normal;
  width: 100%;
  margin: 0;
  padding-left: 50px;
  position: relative;
  background-color: #FFFFFF;
}

#client-profile-content .tab-body.business-profile .box-tab.service .box-body .service-input-group label .fa {
  position: absolute;
  left: 15px;
  top: 10px;
}

#client-profile-content .tab-body.business-profile .box-tab.service .box-body .service-input-group label input {
  padding: 10px 15px;
  width: 100%;
  border: none;
  background-color: #FFFFFF;
  outline: 0;
}

#client-profile-content .tab-body.business-profile .box-tab.service .box-body .service-group .row-option {
  margin-bottom: 30px;
}

#client-profile-content .tab-body.business-profile .box-tab.service .box-body .service-group .row-option .box-service {
  width: 25%;
  padding-left: 10px;
  padding-right: 10px;
  float: left;
}

#client-profile-content .tab-body.business-profile .box-tab.service .box-body .service-group .row-option .box-service .box-header {
  cursor: pointer;
  transition: border .3s;
  border: 1px solid #eaeaea;
  background-color: #FFFFFF;
  text-align: center;
  padding: 30px;
}

#client-profile-content .tab-body.business-profile .box-tab.service .box-body .service-group .row-option .box-service .box-header .icon {
  margin: 0 auto;
  width: 50px;
  height: 50px;
  background: url("../../images/root/all.png");
}

#client-profile-content .tab-body.business-profile .box-tab.service .box-body .service-group .row-option .box-service .box-header .icon.web {
  background-position-x: -499px;
  background-position-y: -348px;
}

#client-profile-content .tab-body.business-profile .box-tab.service .box-body .service-group .row-option .box-service .box-header .icon.accounting {
  background-position-x: -377px;
  background-position-y: -344px;
}

#client-profile-content .tab-body.business-profile .box-tab.service .box-body .service-group .row-option .box-service .box-header .icon.writing {
  background-position-x: -837px;
  background-position-y: -234px;
}

#client-profile-content .tab-body.business-profile .box-tab.service .box-body .service-group .row-option .box-service .box-header .icon.creative {
  background-position-x: -709px;
  background-position-y: -224px;
}

#client-profile-content .tab-body.business-profile .box-tab.service .box-body .service-group .row-option .box-service .box-header h5 {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  line-height: 1.2;
}

#client-profile-content .tab-body.business-profile .box-tab.service .box-body .service-group .row-option .box-service .box-header .back {
  display: none;
  float: right;
  padding: 10px 15px;
  border: 1px solid #585858;
  color: #585858;
  font-family: "Roboto", sans-serif;
}

#client-profile-content .tab-body.business-profile .box-tab.service .box-body .service-group .row-option .box-service .box-header .back .fa {
  display: inline-block;
  padding-right: 15px;
}

#client-profile-content .tab-body.business-profile .box-tab.service .box-body .service-group .row-option .box-service .box-body {
  display: none;
  border-left: 1px solid #eaeaea;
  border-right: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
  background-color: #FFFFFF;
}

#client-profile-content .tab-body.business-profile .box-tab.service .box-body .service-group .row-option .box-service .box-body .col {
  float: left;
  width: 50%;
  padding: 10px;
}

#client-profile-content .tab-body.business-profile .box-tab.service .box-body .service-group .row-option .box-service .box-body .col .item {
  padding: 10px 15px;
  text-align: left;
  color: #585858;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  cursor: pointer;
}

#client-profile-content .tab-body.business-profile .box-tab.service .box-body .service-group .row-option .box-service .box-body .col .item .add {
  display: none;
  float: right;
}

#client-profile-content .tab-body.business-profile .box-tab.service .box-body .service-group .row-option .box-service .box-body .col .item .tick {
  display: none;
  float: right;
}

#client-profile-content .tab-body.business-profile .box-tab.service .box-body .service-group .row-option .box-service .box-body .col .item:not(.selected):hover {
  color: #FFFFFF;
  background-color: #373f48;
}

#client-profile-content .tab-body.business-profile .box-tab.service .box-body .service-group .row-option .box-service .box-body .col .item:not(.selected):hover .add {
  display: block;
  color: #FFFFFF;
}

#client-profile-content .tab-body.business-profile .box-tab.service .box-body .service-group .row-option .box-service .box-body .col .item.selected {
  background-color: #c7ffe4;
}

#client-profile-content .tab-body.business-profile .box-tab.service .box-body .service-group .row-option .box-service .box-body .col .item.selected .tick {
  display: block;
  color: #FFFFFF;
}

#client-profile-content .tab-body.business-profile .box-tab.service .box-body .service-group .row-option .box-service .box-body .col .item:after {
  content: '';
  display: table;
  clear: both;
}

#client-profile-content .tab-body.business-profile .box-tab.service .box-body .service-group .row-option .box-service:hover .box-header {
  border: 1px solid #02cd8d;
}

#client-profile-content .tab-body.business-profile .box-tab.service .box-body .service-group .row-option.extended .box-service {
  display: none;
}

#client-profile-content .tab-body.business-profile .box-tab.service .box-body .service-group .row-option.extended .box-service.active {
  display: block;
  width: 100%;
}

#client-profile-content .tab-body.business-profile .box-tab.service .box-body .service-group .row-option.extended .box-service.active .box-header {
  padding: 20px 30px;
}

#client-profile-content .tab-body.business-profile .box-tab.service .box-body .service-group .row-option.extended .box-service.active .box-header .icon {
  display: none;
}

#client-profile-content .tab-body.business-profile .box-tab.service .box-body .service-group .row-option.extended .box-service.active .box-header h5 {
  float: left;
  text-align: left;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  line-height: 1.3;
}

#client-profile-content .tab-body.business-profile .box-tab.service .box-body .service-group .row-option.extended .box-service.active .box-header .back {
  display: block;
}

#client-profile-content .tab-body.business-profile .box-tab.service .box-body .service-group .row-option.extended .box-service.active .box-body {
  display: block;
}

#client-profile-content .tab-body.business-profile .box-tab.service .box-body .service-group .row-option.extended .box-service.active:hover .box-header {
  border: 1px solid #eaeaea;
}

#client-profile-content .tab-body.business-profile .box-tab.service .box-body .service-group .box-selected {
  margin-bottom: 30px;
}

#client-profile-content .tab-body.business-profile .box-tab.service .box-body .service-group .box-selected .box-heading {
  border: 1px solid #eaeaea;
  background-color: #FFFFFF;
  text-align: center;
  padding: 20px;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  line-height: 1.3;
  font-weight: normal;
}

#client-profile-content .tab-body.business-profile .box-tab.service .box-body .service-group .box-selected .box-body {
  display: block;
  border-left: 1px solid #eaeaea;
  border-right: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
  background-color: #FFFFFF;
}

#client-profile-content .tab-body.business-profile .box-tab.service .box-body .service-group .box-selected .box-body .col {
  float: left;
  width: 50%;
  padding: 10px;
}

#client-profile-content .tab-body.business-profile .box-tab.service .box-body .service-group .box-selected .box-body .col .item {
  padding: 10px 15px;
  text-align: left;
  color: #585858;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  cursor: pointer;
}

#client-profile-content .tab-body.business-profile .box-tab.service .box-body .service-group .box-selected .box-body .col .item .cancel {
  display: none;
  float: right;
}

#client-profile-content .tab-body.business-profile .box-tab.service .box-body .service-group .box-selected .box-body .col .item:hover {
  color: #FFFFFF;
  background-color: #373f48;
}

#client-profile-content .tab-body.business-profile .box-tab.service .box-body .service-group .box-selected .box-body .col .item:hover .cancel {
  display: block;
  color: #FFFFFF;
}

#client-profile-content .tab-body.business-profile .box-tab.service .box-body .service-group .box-selected .box-body .col .item:after {
  content: '';
  display: table;
  clear: both;
}

#client-profile-content .tab-body.business-profile .box-tab .multi-input-group {
  margin-bottom: 30px;
}

#client-profile-content .tab-body.business-profile .box-tab .multi-input-group .multi-input-control {
  margin-bottom: 15px;
  border: 1px solid #979797;
  position: relative;
  padding: 5px 65px 5px 10px;
  background-color: #FFFFFF;
}

#client-profile-content .tab-body.business-profile .box-tab .multi-input-group .multi-input-control label {
  font-size: 14px;
  line-height: 1;
  font-weight: normal;
}

#client-profile-content .tab-body.business-profile .box-tab .multi-input-group .multi-input-control .value-box {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 64px;
  border-left: 1px solid #979797;
  padding: 5px 10px;
  font-size: 14px;
  background-color: #eeeeee;
}

#client-profile-content .tab-body.business-profile .box-tab .multi-input-group .multi-input-control .value-box input {
  width: 100%;
  padding-right: 15px;
  text-align: right;
  border: 0;
  background: transparent;
  outline: 0;
}

#client-profile-content .tab-body.business-profile .box-tab .multi-input-group .multi-input-control .value-box span {
  position: absolute;
  right: 10px;
  top: 5px;
}

#client-profile-content .tab-body.business-profile .box-tab .form-group-multi-language {
  margin-bottom: 25px;
}

#client-profile-content .tab-body.business-profile .box-tab .form-group-multi-language .label-control {
  color: #585858;
  font-size: 14px;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  line-height: 1.5;
  padding-top: 10px;
}

#client-profile-content .tab-body.business-profile .box-tab .form-group-multi-language:after {
  content: '';
  display: table;
  clear: both;
}

#client-profile-content .tab-body.business-profile .box-tab .form-group-multi-language .group-header a {
  float: left;
  cursor: pointer;
  text-align: center;
  display: block;
  padding: 5px;
  width: 55px;
  color: #585858;
  border-top: 1px solid #eaeaea;
  border-left: 1px solid #eaeaea;
}

#client-profile-content .tab-body.business-profile .box-tab .form-group-multi-language .group-header a:last-child {
  border-right: 1px solid #eaeaea;
}

#client-profile-content .tab-body.business-profile .box-tab .form-group-multi-language .group-header a.active {
  background-color: #eaeaea;
}

#client-profile-content .tab-body.business-profile .box-tab .form-group-multi-language .group-header a:hover {
  text-decoration: none;
}

#client-profile-content .tab-body.business-profile .box-tab .form-group-multi-language .group-body {
  border: 1px solid #eaeaea;
}

#client-profile-content .tab-body.business-profile .box-tab .form-group-multi-language .group-body .language {
  display: none;
}

#client-profile-content .tab-body.business-profile .box-tab .form-group-multi-language .group-body .language .row {
  margin-top: 12px;
}

#client-profile-content .tab-body.business-profile .box-tab .form-group-multi-language .group-body .language .row .label-control + div input, #client-profile-content .tab-body.business-profile .box-tab .form-group-multi-language .group-body .language .row .label-control + div textarea {
  border: 1px solid #e1e3e3;
  border-radius: 4px;
  padding: 10px 20px;
  line-height: 1;
  outline: 0;
  font-size: 14px;
  height: auto;
  color: #585858;
  box-shadow: none;
  font-family: "Roboto", sans-serif;
  background-color: #FFFFFF;
  font-weight: bold;
}

#client-profile-content .tab-body.business-profile .box-tab .form-group-multi-language .group-body .language .row .label-control + div input:focus, #client-profile-content .tab-body.business-profile .box-tab .form-group-multi-language .group-body .language .row .label-control + div textarea:focus {
  box-shadow: none;
  border: 1px solid #e1e3e3;
  outline: 0;
}

#client-profile-content .tab-body.business-profile .box-tab .form-group-multi-language .group-body .language .row .label-control + div input:disabled, #client-profile-content .tab-body.business-profile .box-tab .form-group-multi-language .group-body .language .row .label-control + div textarea:disabled {
  background-color: #e1e3e3;
}

#client-profile-content .tab-body.business-profile .box-tab .form-group-multi-language .group-body .language input, #client-profile-content .tab-body.business-profile .box-tab .form-group-multi-language .group-body .language textarea {
  padding: 10px 15px;
  width: 100%;
  border: none;
  background-color: #FFFFFF;
  outline: 0;
}

#client-profile-content .tab-body.business-profile .box-tab .form-group-multi-language .group-body .language.active {
  display: block;
}

#client-profile-content .tab-body.business-profile .box-tab .legend {
  color: #a9a9a9;
}

#client-profile-content .tab-body.business-profile .box-tab .legend b {
  color: #585858;
  font-weight: bold;
}

#client-profile-content .tab-body.trust-verification .box .box-body {
  padding: 0;
}

#client-profile-content .tab-body.trust-verification .box .box-body .content-bar {
  padding: 15px 25px;
}

#client-profile-content .tab-body.trust-verification .box .box-body .content-bar:not(:first-child) {
  border-top: 1px solid #d4f0e3;
}

#client-profile-content .tab-body.trust-verification .box .box-body .content-bar .title {
  color: #585858;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  line-height: 1.4;
  margin-bottom: 10px;
}

#client-profile-content .tab-body.trust-verification .box .box-body .content-bar .description {
  color: #999999;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  line-height: 1.4;
}

#client-profile-content .tab-body.trust-verification .box .box-body .content-bar.with-button {
  padding-right: 190px;
  position: relative;
}

#client-profile-content .tab-body.trust-verification .box .box-body .content-bar.with-button .button-right {
  width: 150px;
  position: absolute;
  right: 25px;
  top: 15px;
  white-space: nowrap;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
  font-size: 14px;
}

#client-profile-content .tab-body.client-review-form .box .box-heading {
  padding-top: 25px;
  font-weight: normal;
  background-color: white;
}

#client-profile-content .tab-body.client-review-form .box .box-heading .box-title {
  font-size: 25px;
  padding-left: 75px;
  line-height: 17px;
  margin-top: 10px;
  position: relative;
  margin-bottom: 15px;
}

#client-profile-content .tab-body.client-review-form .box .box-heading .box-title img {
  display: inline-block;
  padding-left: 15px;
  margin-bottom: 10px;
  position: absolute;
  top: -5px;
  left: 0;
}

#client-profile-content .tab-body.client-review-form .box .box-heading .inner-tab-header {
  padding: 0 0 17px;
  float: left;
  color: #999999;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: bold;
  border-bottom: 3px solid transparent;
  transition: border-bottom .3s;
}

#client-profile-content .tab-body.client-review-form .box .box-heading .inner-tab-header:not(:first-child) {
  margin-left: 25px;
}

#client-profile-content .tab-body.client-review-form .box .box-heading .inner-tab-header.active {
  border-bottom: 3px solid #02cd8d;
  color: #585858;
}

#client-profile-content .tab-body.client-review-form .box .box-body .form-group .label-control {
  font-weight: bolder;
  font-size: 13px;
}

#client-profile-content .tab-body.client-review-form .box .box-body .form-group .label-control .red {
  color: red;
}

#client-profile-content .tab-body.client-review-form .box .box-body .form-group .helper {
  color: #9C9C9C;
  font-size: 10px;
  margin-bottom: 10px;
}

#client-profile-content .tab-body.client-review-form .box .box-body .form-group .label-control-header {
  color: #29f2b2;
  font-weight: bolder;
  font-size: 15px;
}

#client-profile-content .tab-body.client-review-form .box .box-body .form-group .label-file label {
  overflow: hidden;
  font-size: 14px;
  padding: 5px 15px 11px;
  margin-right: 20px;
  position: relative;
  border-radius: 3px;
  cursor: pointer;
}

#client-profile-content .tab-body.client-review-form .box .box-body .form-group .label-file label:after {
  position: absolute;
  bottom: 0;
  height: 6px;
  left: 0;
  right: 0;
  background-color: #28ac78;
  transition: height .3s;
  content: '';
  display: block;
  z-index: 1;
}

#client-profile-content .tab-body.client-review-form .box .box-body .form-group .label-file label span {
  position: relative;
  z-index: 2;
  color: #FFFFFF;
}

#client-profile-content .tab-body.client-review-form .box .box-body .form-group .label-file label:hover:after {
  height: 100%;
}

#client-profile-content .tab-body.client-review-form .box .box-body .form-group .label-file input[type=file] {
  display: none;
}

#client-profile-content .tab-body.client-review-form .box .box-body .form-group .label-file input[type=text] {
  border: none;
  padding: 0;
  display: inline-block;
  line-height: 36px;
}

#client-profile-content .tab-body.client-review-form .box .box-body .form-group .label-file .uploaded {
  font-size: 13px;
  line-height: 1.428571429;
  color: #333333;
  margin-bottom: 15px;
}

#client-profile-content .tab-body.client-review-form .box .box-body .form-group .label-file .uploaded a {
  color: #02cd8d;
}

#client-profile-content .tab-body.client-review-form .box .box-body .form-group .checkboxes input[type=checkbox] {
  visibility: hidden;
  opacity: 0;
}

#client-profile-content .tab-body.client-review-form .box .box-body .form-group .checkboxes label {
  display: block;
  margin: 10px 0 10px;
  font-size: 12px;
  cursor: pointer;
}

#client-profile-content .tab-body.client-review-form .box .box-body .form-group .checkboxes label span {
  display: inline-block;
  vertical-align: middle;
  font-size: 12px;
  font-weight: normal;
}

#client-profile-content .tab-body.client-review-form .box .box-body .form-group .checkboxes label span.checkbox {
  border: 1px solid #d4f0e3;
  background-color: #d8f1e6;
  border-radius: 2px;
  margin: 0 10px 0 0;
  width: 20px;
  height: 20px;
  position: relative;
  transition: background-color .3s;
}

#client-profile-content .tab-body.client-review-form .box .box-body .form-group .checkboxes label span.checkbox input {
  visibility: hidden;
  opacity: 0;
}

#client-profile-content .tab-body.client-review-form .box .box-body .form-group .checkboxes label.checked span.checkbox {
  background-color: #FFFFFF;
  border: 1px solid #02cd8d;
}

#client-profile-content .tab-body.client-review-form .box .box-body .form-group .checkboxes label.checked span.checkbox:before {
  top: 50%;
  left: 50%;
  content: '';
  position: absolute;
  height: 6px;
  width: 15px;
  border-left: 2px solid #02cd8d;
  border-bottom: 2px solid #02cd8d;
  display: block;
  margin: 0 auto;
  transform: translate(-50%, -55%) rotate(-45deg);
  -webkit-transform: translate(-50%, -55%) rotate(-45deg);
  -moz-transform: translate(-50%, -55%) rotate(-45deg);
  -ms-transform: translate(-50%, -55%) rotate(-45deg);
  -o-transform: translate(-50%, -55%) rotate(-45deg);
}

#client-profile-content .tab-body.client-review-form .box .box-body .form-group .checkboxes label:hover:not(.checked) span.checkbox {
  background-color: #d4f0e3;
}

#client-profile-content .tab-body.client-review-form .box .box-body .form-group .checkboxes label:hover.checked span.checkbox {
  background-color: #d8f1e6;
}

#client-profile-content .tab-body.client-review-form .box .box-body .form-group .checkboxes label:hover.checked span.checkbox:before {
  border-left: 2px solid #02cd8d;
  border-bottom: 2px solid #02cd8d;
}

#client-profile-content .tab-body.client-review-form .box .box-body .form-group .checkboxes input[type=text] {
  display: inline-block;
  width: auto;
}

#client-profile-content .tab-body.client-review-form .box .box-body .form-group .checkboxes .checkbox-group {
  margin-bottom: 10px;
}

#client-profile-content .tab-body.client-review-form .box .box-body .form-group .checkboxes .checkbox-group .checkbox-group {
  padding-left: 35px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s .5s, opacity .5s linear;
  height: 0;
  overflow: hidden;
  margin-bottom: 0;
}

#client-profile-content .tab-body.client-review-form .box .box-body .form-group .checkboxes .checkbox-group .checkbox-group.active {
  margin-bottom: 10px;
  overflow: visible;
  height: auto;
  visibility: visible;
  opacity: 1;
  transition: opacity .5s linear;
}

#client-profile-content .tab-body.client-review-form .box .box-body .form-group .checkboxes .checkbox-group .extend {
  display: inline-block;
  margin-left: 10px;
  color: #02cd8d;
  font-size: 12px;
}

#client-profile-content .tab-body.client-review-form .box .box-body .form-group .input-radios-list .radio-list-row {
  margin-bottom: 15px;
  font-size: 13px;
  color: #585858;
  font-weight: normal;
  position: relative;
}

#client-profile-content .tab-body.client-review-form .box .box-body .form-group .input-radios-list .radio-list-row .radio-input {
  cursor: pointer;
  border-radius: 50%;
  border: 4px solid #dddddd;
  background-color: #dddddd;
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 15px;
  position: absolute;
  top: 0;
  left: 0;
}

#client-profile-content .tab-body.client-review-form .box .box-body .form-group .input-radios-list .radio-list-row .radio-input.checked {
  background-color: #02cd8d;
}

#client-profile-content .tab-body.client-review-form .box .box-body .form-group .input-radios-list .radio-list-row .radio-input.checked + span + .date-time {
  display: block;
}

#client-profile-content .tab-body.client-review-form .box .box-body .form-group .input-radios-list .radio-list-row span {
  padding-left: 40px;
  display: inline-block;
}

#client-profile-content .tab-body.client-review-form .box .box-body .form-group .input-radios-list .radio-list-row .date-time {
  display: none;
}

#client-profile-content .tab-body.client-review-form .box .box-body .form-group .input-radios-list .radio-list-row .date-time .date-control {
  display: inline-block;
  width: 200px;
  margin-left: 50px;
  position: relative;
}

#client-profile-content .tab-body.client-review-form .box .box-body .form-group .input-radios-list .radio-list-row .date-time .date-control .fa {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translate(0, -50%);
  color: #03cd8e;
}

#client-profile-content .tab-body.client-review-form .box .box-body .inner-tab-body {
  border: 1px solid #d4f0e3;
  background-color: #FFFFFF;
  font-family: "Roboto", sans-serif;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s .5s, opacity .5s linear;
  height: 0;
  overflow: hidden;
}

#client-profile-content .tab-body.client-review-form .box .box-body .inner-tab-body.active {
  overflow: visible;
  height: auto;
  visibility: visible;
  opacity: 1;
  transition: opacity .5s linear;
  padding: 0 25px 15px;
}

#client-profile-content .tab-body.client-review-form .box .box-body .inner-tab-body .box-review {
  cursor: pointer;
}

#client-profile-content .tab-body.client-review-form .box .box-body .inner-tab-body .box-review .box-body {
  padding-top: 30px;
  padding-bottom: 30px;
  min-height: 110px;
}

#client-profile-content .tab-body.client-review-form .box .box-body .inner-tab-body .box-review .box-body .info-table {
  display: table;
  width: 100%;
}

#client-profile-content .tab-body.client-review-form .box .box-body .inner-tab-body .box-review .box-body .info-table .info-row {
  display: table-row;
}

#client-profile-content .tab-body.client-review-form .box .box-body .inner-tab-body .box-review .box-body .info-table .info-row > [class^=col] {
  display: table-cell;
  padding-left: 15px;
  padding-right: 15px;
  vertical-align: top;
}

#client-profile-content .tab-body.client-review-form .box .box-body .inner-tab-body .box-review .box-body .info-table .info-row > [class^=col]:first-child {
  padding-left: 0;
}

#client-profile-content .tab-body.client-review-form .box .box-body .inner-tab-body .box-review .box-body .info-table .info-row > [class^=col]:last-child {
  padding-right: 0;
}

#client-profile-content .tab-body.client-review-form .box .box-body .inner-tab-body .box-review .box-body .info-table .info-row .col-logo {
  width: 80px;
}

#client-profile-content .tab-body.client-review-form .box .box-body .inner-tab-body .box-review .box-body .info-table .info-row .col-logo img {
  max-width: 65px;
  max-height: 65px;
}

#client-profile-content .tab-body.client-review-form .box .box-body .inner-tab-body .box-review .box-body .info-table .info-row .col-detail {
  width: 120px;
}

#client-profile-content .tab-body.client-review-form .box .box-body .inner-tab-body .box-review .box-body .info-table .info-row .col-detail .title {
  color: #585858;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  margin-bottom: 10px;
  line-height: 1.6;
}

#client-profile-content .tab-body.client-review-form .box .box-body .inner-tab-body .box-review .box-body .info-table .info-row .col-detail .date {
  color: #585858;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
}

#client-profile-content .tab-body.client-review-form .box .box-body .inner-tab-body .box-review .box-body .info-table .info-row .col-main .title {
  color: #585858;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  margin-bottom: 10px;
  line-height: 1;
}

#client-profile-content .tab-body.client-review-form .box .box-body .inner-tab-body .box-review .box-body .info-table .info-row .col-main .content {
  color: #999999;
}

#client-profile-content .tab-body.client-review-form .box .box-body .inner-tab-body .box-review .box-body .info-table .info-row .col-action {
  vertical-align: middle;
  width: 145px;
}

#client-profile-content .tab-body.client-review-form .box .box-body .inner-tab-body .box-review .box-body .info-table .info-row .col-action .view {
  border: 1px solid #585858;
  border-radius: 4px;
  color: #585858;
  display: inline-block;
  width: 100%;
  padding: 9px 0;
  font-size: 12px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}

#client-profile-content .tab-body.client-review-form .box .box-body .inner-tab-body .box-review .box-body .info-table .info-row .col-action .view:hover {
  text-decoration: none;
}

#client-profile-content .tab-body.client-review-form .box .box-body .inner-tab-body .box-review .box-body .info-table .info-row .col-action .write {
  display: inline-block;
  width: 100%;
  padding: 9px 0;
  font-size: 12px;
  text-align: center;
  text-decoration: none;
}

#client-profile-content .tab-body.client-review-form .box .box-body .inner-tab-body .box-review:not(:first-child) {
  border-top: 1px solid #d4f0e3;
}

#client-profile-content .tab-body.client-review-form .box .box-footer .pull-left.btn-back {
  color: #02cd8d;
  cursor: pointer;
  display: inline-block;
  position: relative;
  padding: 0 5px 5px;
}

#client-profile-content .tab-body.client-review-form .box .box-footer #btn-save {
  margin-right: 15px;
}

#client-profile-content .tab-body.client-review-index .main-title {
  color: #000;
  font-weight: bold;
  font-size: 14px;
  display: inline-block;
  padding: 0 0 5px;
  border-bottom: 3px solid #02cd8d;
}

#client-profile-content .tab-body.client-review-index .box {
  padding: 30px;
  background-color: #FFFFFF;
}

#client-profile-content .tab-body.client-review-index .box .info-table {
  display: table;
  width: 100%;
}

#client-profile-content .tab-body.client-review-index .box .info-table:not(:first-child) {
  margin-top: 30px;
}

#client-profile-content .tab-body.client-review-index .box .info-table .info-row {
  display: table-row;
}

#client-profile-content .tab-body.client-review-index .box .info-table .info-row > [class^=col] {
  display: table-cell;
  padding-left: 15px;
  padding-right: 15px;
  vertical-align: top;
  word-break: break-all;
  -ms-word-break: break-all;
  word-break: break-all;
  /* Non standard for WebKit */
  word-break: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

#client-profile-content .tab-body.client-review-index .box .info-table .info-row > [class^=col]:first-child {
  padding-left: 0;
}

#client-profile-content .tab-body.client-review-index .box .info-table .info-row > [class^=col]:last-child {
  padding-right: 0;
}

#client-profile-content .tab-body.client-review-index .box .info-table .info-row .col-logo {
  width: 80px;
}

#client-profile-content .tab-body.client-review-index .box .info-table .info-row .col-logo img {
  max-width: 65px;
  max-height: 65px;
}

#client-profile-content .tab-body.client-review-index .box .info-table .info-row .col-detail {
  width: 120px;
}

#client-profile-content .tab-body.client-review-index .box .info-table .info-row .col-detail .title {
  color: #585858;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  margin-bottom: 10px;
  line-height: 22px;
}

#client-profile-content .tab-body.client-review-index .box .info-table .info-row .col-detail a {
  color: black;
  text-decoration: none;
}

#client-profile-content .tab-body.client-review-index .box .info-table .info-row .col-detail .date {
  color: #585858;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
}

#client-profile-content .tab-body.client-review-index .box .info-table .info-row .col-main .title {
  color: #585858;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  margin-bottom: 10px;
  line-height: 22px;
}

#client-profile-content .tab-body.client-review-index .box .info-table .info-row .col-main .content {
  color: #999999;
}

#client-profile-content .tab-body.client-review-index .box .info-table .info-row .col-action {
  vertical-align: middle;
  width: 145px;
  vertical-align: middle;
}

#client-profile-content .tab-body.client-review-index .box .info-table .info-row .col-action a.border-box {
  border: 1px solid #585858;
  border-radius: 4px;
  color: #585858;
  display: inline-block;
  width: 100%;
  padding: 9px 0;
  font-size: 12px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}

#client-profile-content .tab-body.client-review-index .box .info-table .info-row .col-action a.border-box:hover {
  text-decoration: none;
}

#client-profile-content .tab-body.client-review-show .wrapper-title {
  margin-bottom: 25px;
  font-size: 18px;
  color: #333;
  font-weight: bold;
}

#client-profile-content .tab-body.client-review-show .box {
  background-color: #FFFFFF;
  border: 1px solid #d4f0e3;
  margin-bottom: 20px;
}

#client-profile-content .tab-body.client-review-show .box .box-header {
  padding: 20px;
  border-bottom: 1px solid #d4f0e3;
}

#client-profile-content .tab-body.client-review-show .box .box-header .box-title strong {
  font-size: 18px;
  font-weight: bold;
}

#client-profile-content .tab-body.client-review-show .box .box-header .box-title .helper {
  color: #b5b5b5;
  display: block;
  font-size: 12px;
  font-weight: normal;
}

#client-profile-content .tab-body.client-review-show .box .box-header .box-title.with-icon-references {
  padding-left: 55px;
  position: relative;
  min-height: 33px;
  padding-top: 5px;
}

#client-profile-content .tab-body.client-review-show .box .box-header .box-title.with-icon-references:before {
  position: absolute;
  background: url("../../images/root/client/reference/icon-reference.png") no-repeat;
  background-size: cover;
  width: 40px;
  height: 33px;
  content: '';
  display: block;
  top: 0;
  left: 0;
}

#client-profile-content .tab-body.client-review-show .box .box-body {
  padding: 20px;
}

#client-profile-content .tab-body.client-review-show .box .box-body .legend {
  color: #02cd8d;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 20px;
  margin-top: 20px;
}

#client-profile-content .tab-body.client-review-show .box .box-body .form-group {
  margin-bottom: 10px;
  font-weight: bold;
  margin-left: -15px;
  margin-right: -15px;
}

#client-profile-content .tab-body.client-review-show .box .box-body .form-group:after {
  content: '';
  display: table;
  clear: both;
}

#client-profile-content .tab-body.client-review-show .box .box-body .form-group input.form-control,
#client-profile-content .tab-body.client-review-show .box .box-body .form-group select.form-control,
#client-profile-content .tab-body.client-review-show .box .box-body .form-group textarea.form-control {
  outline: 0;
  box-shadow: none;
  font-weight: normal;
  background-color: #FFFFFF;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  padding: 4px 12px;
  color: #000;
  transition: all .3s;
}

#client-profile-content .tab-body.client-review-show .box .box-body .form-group input.form-control:focus,
#client-profile-content .tab-body.client-review-show .box .box-body .form-group select.form-control:focus,
#client-profile-content .tab-body.client-review-show .box .box-body .form-group textarea.form-control:focus {
  background-color: #cffff0;
  border: #cffff0;
  box-shadow: none;
}

#client-profile-content .tab-body.client-review-show .box .box-body .form-group input.form-control:disabled,
#client-profile-content .tab-body.client-review-show .box .box-body .form-group select.form-control:disabled,
#client-profile-content .tab-body.client-review-show .box .box-body .form-group textarea.form-control:disabled {
  background-color: #eaeaea;
}

#client-profile-content .tab-body.client-review-show .box .box-body .form-group textarea.form-control {
  min-height: 120px;
}

#client-profile-content .tab-body.client-review-show .box .box-body .form-group .control-label {
  font-weight: bold;
  font-size: 14px;
  color: #333;
}

#client-profile-content .tab-body.client-review-show .box .box-body .form-group .helper {
  margin-top: 5px;
  color: #b5b5b5;
  display: block;
  font-size: 12px;
  font-weight: normal;
}

#client-profile-content .tab-body.client-review-show .box .box-body .form-group.required .control-label::after {
  margin-left: 5px;
  font-size: 16px;
  content: '*';
  color: #ff0303;
  display: inline-block;
}

#client-profile-content .tab-body.client-review-show .box .box-body .review_info_row {
  display: table;
  width: 100%;
  margin-bottom: 30px;
  background-color: #f0f4f7;
  border: 1px solid #d4f0e3;
  padding: 15px 0;
}

#client-profile-content .tab-body.client-review-show .box .box-body .review_info_row .review_info_col {
  display: table-cell;
  padding: 0 15px;
  font-weight: normal;
  font-size: 14px;
  color: #5e5e5e;
}

#client-profile-content .tab-body.client-review-show .box .box-body .review_info_row .review_info_col:not(:first-child) {
  border-left: 1px solid #d4f0e3;
}

#client-profile-content .tab-body.client-review-show .box .box-body .review_info_row .review_info_col.col-1 {
  width: 35%;
}

#client-profile-content .tab-body.client-review-show .box .box-body .review_info_row .review_info_col.col-2 {
  width: 35%;
  padding-top: 20px;
  text-align: right;
}

#client-profile-content .tab-body.client-review-show .box .box-body .review_info_row .review_info_col.col-2 .row {
  margin-bottom: 15px;
}

#client-profile-content .tab-body.client-review-show .box .box-body .review_info_row .review_info_col.col-2 .row .rating-bar {
  position: relative;
  background-color: #dbdbdb;
}

#client-profile-content .tab-body.client-review-show .box .box-body .review_info_row .review_info_col.col-2 .row .rating-bar .rating-col {
  width: 20%;
  height: 17px;
  background-color: transparent;
  display: inline-block;
  float: left;
}

#client-profile-content .tab-body.client-review-show .box .box-body .review_info_row .review_info_col.col-2 .row .rating-bar .rating-col + .rating-col {
  border-left: 1px solid #ffffff;
}

#client-profile-content .tab-body.client-review-show .box .box-body .review_info_row .review_info_col.col-2 .row .rating-bar .rating-value {
  height: 17px;
  background-color: #02cd8d;
}

#client-profile-content .tab-body.client-review-show .box .box-body .review_info_row .review_info_col.col-2 .row .rating-bar:after {
  clear: both;
  content: '';
  display: table;
}

#client-profile-content .tab-body.client-review-show .box .box-body .review_info_row .review_info_col.col-3 {
  width: 30%;
  text-align: center;
}

#client-profile-content .tab-body.client-review-show .box .box-body .review_info_row .review_info_col .col-legend {
  font-weight: bold;
  font-size: 15px;
  margin-bottom: 15px;
  color: #333;
}

#client-profile-content .tab-body.client-review-show .box .box-body .review_info_row .review_info_col .with-icon {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 25px;
  position: relative;
}

#client-profile-content .tab-body.client-review-show .box .box-body .review_info_row .review_info_col .with-icon .fa {
  position: absolute;
  top: 5px;
  left: 2px;
  width: 20px;
  display: inline-block;
}

#client-profile-content .tab-body.client-review-show .box .box-body .review_info_row .review_info_col .with-icon .more {
  float: right;
}

#client-profile-content .tab-body.client-review-show .box .box-body .review_info_row .review_info_col .with-icon .tooltip_content {
  display: none;
}

#client-profile-content .tab-body.client-review-show .box .box-body .review_info_row .review_info_col .verified {
  margin-top: 15px;
  border-radius: 3px;
  background: #444;
  color: #FFFFFF;
  padding: 5px 10px 5px 30px;
  position: relative;
  display: inline-block;
}

#client-profile-content .tab-body.client-review-show .box .box-body .review_info_row .review_info_col .verified .fa {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translate(0, -50%);
}

#client-profile-content .tab-body.client-review-show .box .box-body .review-content .content {
  margin-bottom: 20px;
}

#client-profile-content .tab-body.client-review-show .box .box-body .review-content .content > h4 {
  font-weight: bold;
  color: #3f92f1;
  font-size: 16px;
}

#client-profile-content .tab-body.client-review-show .box .box-body .review-content .content b {
  font-size: 14px;
  color: #000;
  font-weight: bold;
}

#client-profile-content .tab-body.client-review-show .box .box-body .review-content .content blockquote {
  margin: 0 0 20px;
  border: 1px solid #d4f0e3;
  border-radius: 2px;
  padding: 15px 50px;
  background-color: #f0f4f7;
  position: relative;
  color: #888888;
  line-height: 1.5;
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: italic;
}

#client-profile-content .tab-body.client-review-show .box .box-body .review-content .content blockquote:before {
  content: '“';
  position: absolute;
  top: -10px;
  left: 10px;
  font-size: 70px;
  color: #d1d8de;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
}

#client-profile-content .tab-body.client-review-show .box .box-body .review-content .content blockquote:after {
  font-family: "Roboto", sans-serif;
  content: '”';
  position: absolute;
  bottom: -40px;
  right: 10px;
  font-size: 70px;
  color: #d1d8de;
  font-weight: bold;
}

#client-profile-content .tab-body.client-review-show .box .box-body .review-content .content blockquote h4 {
  color: #5a5a5a;
  margin-bottom: 15px;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: italic;
}

#client-profile-content .tab-body.client-review-show .button-group {
  padding-top: 25px;
}

#client-profile-content .tab-body.client-review-show .pull-right .btn-effect-1.btn-approve {
  display: inline-block;
  padding: 10px 25px;
  background-color: #02cd8d;
  border: 1px solid #02cd8d;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  color: #ffffe7;
  transition: background-color .3s;
  text-decoration: none;
  outline: 0;
  border: 0;
  cursor: pointer;
}

#client-profile-content .tab-body.client-review-show .item {
  margin-bottom: 10px;
}

#client-profile-content .tab-body.client-review-show .item .item-label {
  font-weight: bold;
  font-size: 14px;
  color: #333;
}

#client-profile-content .tab-body.client-review-show .item .item-value {
  color: #57656e;
}

#client-profile-content .tab-body.client-review-show .item::after {
  content: '';
  display: table;
  clear: both;
}

#client-profile-content .tab-body .box {
  border: 1px solid #d4f0e3;
  margin-bottom: 20px;
}

#client-profile-content .tab-body .box .box-heading {
  padding: 25px 25px;
  background-color: #ccd9e0;
  color: #585858;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: bold;
}

#client-profile-content .tab-body .box .box-body {
  padding: 15px 25px;
  background-color: #FFFFFF;
  font-family: "Roboto", sans-serif;
}

#client-profile-content .tab-body .box .box-body:not(:first-child) {
  border-top: 1px solid #d4f0e3;
}

#client-profile-content .tab-body .box .box-body:not(:first-child) .error {
  border-color: red;
}

#client-profile-content .tab-body .box .box-body:not(:first-child) .text-error {
  font-size: 11px;
  color: red;
}

#client-profile-content .tab-body .box .box-footer {
  border-top: 1px solid #d4f0e3;
  padding: 15px 25px;
  background-color: #FFFFFF;
}

#client-profile-content .tab-body .box .box-footer .btn-effect-1 {
  display: inline-block;
}

#client-profile-content .tab-body .box #client-review-box-footer.box-footer .pull-right {
  margin-right: 15px;
}

#client-profile-content .tab-body .radios input[type=radio] {
  visibility: hidden;
  opacity: 0;
}

#client-profile-content .tab-body .radios label {
  display: block;
  margin: 0;
  font-size: 12px;
  cursor: pointer;
}

#client-profile-content .tab-body .radios label:not(:first-child) {
  margin-top: 10px;
}

#client-profile-content .tab-body .radios label span {
  display: inline-block;
  vertical-align: middle;
}

#client-profile-content .tab-body .radios label span.radio {
  border: 2px solid #d4f0e3;
  background-color: #d8f1e6;
  border-radius: 50%;
  margin: 0 10px 0 0;
  width: 20px;
  height: 20px;
  position: relative;
  transition: background-color .3s;
}

#client-profile-content .tab-body .radios label span.radio input {
  visibility: hidden;
  opacity: 0;
}

#client-profile-content .tab-body .radios label.checked span.radio {
  background-color: #02cd8d;
}

#client-profile-content .tab-body .radios input[type=text] {
  display: inline-block;
  width: auto;
}

#client-profile-content .tab-body .checkboxes input[type=checkbox] {
  visibility: hidden;
  opacity: 0;
}

#client-profile-content .tab-body .checkboxes label {
  display: block;
  margin: 0 0 10px;
  font-size: 12px;
  cursor: pointer;
}

#client-profile-content .tab-body .checkboxes label span {
  display: inline-block;
  vertical-align: middle;
}

#client-profile-content .tab-body .checkboxes label span.checkbox {
  border: 1px solid #d4f0e3;
  background-color: #d8f1e6;
  border-radius: 2px;
  margin: 0 10px 0 0;
  width: 20px;
  height: 20px;
  position: relative;
  transition: background-color .3s;
}

#client-profile-content .tab-body .checkboxes label span.checkbox input {
  visibility: hidden;
  opacity: 0;
}

#client-profile-content .tab-body .checkboxes label.checked span.checkbox {
  background-color: #FFFFFF;
  border: 1px solid #02cd8d;
}

#client-profile-content .tab-body .checkboxes label.checked span.checkbox:before {
  top: 50%;
  left: 50%;
  content: '';
  position: absolute;
  height: 6px;
  width: 15px;
  border-left: 2px solid #02cd8d;
  border-bottom: 2px solid #02cd8d;
  display: block;
  margin: 0 auto;
  transform: translate(-50%, -55%) rotate(-45deg);
  -webkit-transform: translate(-50%, -55%) rotate(-45deg);
  -moz-transform: translate(-50%, -55%) rotate(-45deg);
  -ms-transform: translate(-50%, -55%) rotate(-45deg);
  -o-transform: translate(-50%, -55%) rotate(-45deg);
}

#client-profile-content .tab-body .checkboxes label:hover:not(.checked) span.checkbox {
  background-color: #d4f0e3;
}

#client-profile-content .tab-body .checkboxes label:hover.checked span.checkbox {
  background-color: #d8f1e6;
}

#client-profile-content .tab-body .checkboxes label:hover.checked span.checkbox:before {
  border-left: 2px solid #02cd8d;
  border-bottom: 2px solid #02cd8d;
}

#client-profile-content .tab-body .checkboxes input[type=text] {
  display: inline-block;
  width: auto;
}

#client-profile-content .tab-body .checkboxes .checkbox-group {
  margin-bottom: 10px;
}

#client-profile-content .tab-body .checkboxes .checkbox-group .checkbox-group {
  padding-left: 35px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s .5s, opacity .5s linear;
  height: 0;
  overflow: hidden;
  margin-bottom: 0;
}

#client-profile-content .tab-body .checkboxes .checkbox-group .checkbox-group.active {
  margin-bottom: 10px;
  overflow: visible;
  height: auto;
  visibility: visible;
  opacity: 1;
  transition: opacity .5s linear;
}

#client-profile-content .tab-body .checkboxes .checkbox-group label {
  margin-bottom: 0;
}

#client-profile-content .tab-body .checkboxes .checkbox-group .extend {
  display: inline-block;
  margin-left: 10px;
  color: #02cd8d;
  font-size: 24px;
  font-weight: bold;
}

#client-profile-content .tab-body .form-location {
  background-color: #d4f0e3;
  padding: 20px 20px 10px;
  margin-right: -25px;
  margin-bottom: 20px;
}

#client-profile-content .tab-body .form-location .location-group {
  margin-bottom: 10px;
}

#client-profile-content .tab-body .form-location .location-group .label-control {
  color: #585858;
  font-size: 14px;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  line-height: 1.5;
  padding: 10px 0;
}

#client-profile-content .tab-body .form-location .location-group input.form-control,
#client-profile-content .tab-body .form-location .location-group textarea.form-control,
#client-profile-content .tab-body .form-location .location-group select.form-control {
  border: 1px solid #e1e3e3;
  border-radius: 4px;
  padding: 10px 20px;
  line-height: 1;
  outline: 0;
  font-size: 14px;
  height: auto;
  color: #585858;
  box-shadow: none;
  font-family: "Roboto", sans-serif;
  background-color: #FFFFFF;
  font-weight: bold;
}

#client-profile-content .tab-body .form-location .location-group input.form-control:focus,
#client-profile-content .tab-body .form-location .location-group textarea.form-control:focus,
#client-profile-content .tab-body .form-location .location-group select.form-control:focus {
  box-shadow: none;
  border: 1px solid #e1e3e3;
  outline: 0;
}

#client-profile-content .tab-body .form-location .location-group input.form-control:disabled,
#client-profile-content .tab-body .form-location .location-group textarea.form-control:disabled,
#client-profile-content .tab-body .form-location .location-group select.form-control:disabled {
  background-color: #e1e3e3;
}

#client-profile-content .tab-body .form-location .location-group textarea.form-control {
  height: 180px;
}

#client-profile-content .tab-body .button_add {
  color: #02cd8d;
  cursor: pointer;
  display: inline-block;
}

#client-profile-content .tab-body .tab-body-legend {
  font-size: 20px;
  color: #585858;
  line-height: 1;
  font-weight: bolder;
  margin-bottom: 20px;
  position: relative;
  padding-bottom: 12px;
  float: left;
}

#client-profile-content .tab-body .tab-body-legend:after {
  content: '';
  display: block;
  height: 2px;
  position: absolute;
  bottom: 0;
  width: 100px;
  left: 50%;
  background-color: #02cd8d;
  transform: translateX(-50%);
}

#client-review-input-show-when-input-changed {
  box-shadow: 0px -2px 3px rgba(50, 50, 50, 0.15);
  background-color: #fff;
  padding: 15px 0;
  display: none;
  opacity: 0;
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  right: 0;
  z-index: 99;
  animation-fill-mode: both;
}

#client-review-input-show-when-input-changed .btn-back {
  color: #02cd8d;
  cursor: pointer;
  display: inline-block;
  position: relative;
  padding: 0 5px;
  line-height: 45px;
}

#client-review-input-show-when-input-changed .btn-back i {
  margin-right: 5px;
  display: inline-block;
}

@media only screen and (max-width: 991px) {
  #client-profile-content .wrapper .row .col-sm-9 {
    width: 100%;
  }
  #client-profile-content .tab-body.client-profile .box .box-body .form-group .label-control {
    padding: 10px 0 10px 20px;
  }
  #client-profile-content .tab-body.client-review-form .box .box-body .form-group .label-control-header {
    width: 50%;
  }
  #client-profile-content .tab-body.client-review-form .box .box-body .form-group .col-sm-9 .row .col-sm-8 {
    width: 100%;
  }
  #client-profile-content .tab-body.client-review-form .box .box-body .form-group .col-sm-9 .row .col-sm-8 .row .col-sm-9 {
    width: 75%;
  }
  #client-profile-content .tab-body.client-review-form .box .box-body .form-group .label-control.col-sm-3 {
    width: 100%;
    padding-bottom: 5px;
  }
}

@media only screen and (max-width: 767px) {
  #client-profile-content .wrapper .container {
    padding-left: 0px;
    padding-right: 0px;
  }
  #client-profile-content .wrapper .tab-body.active {
    padding-top: 10px;
    width: 100%;
  }
  #client-profile-content .wrapper .tab-body .tab-body-legend {
    padding-left: 20px;
    padding-right: 20px;
  }
  #client-profile-content .wrapper .tab-body.client-profile .box .box-heading {
    padding: 15px 20px;
  }
  #client-profile-content .wrapper .tab-body.client-profile .box .box-body {
    padding: 20px 0;
  }
  #client-profile-content .wrapper .tab-body.client-profile .box .box-body .form-group {
    margin-bottom: 20px;
  }
  #client-profile-content .wrapper .tab-body.client-profile .box .box-body .form-group.position select.form-control {
    max-width: 100%;
  }
  #client-profile-content .wrapper .tab-body.client-profile .box .box-body .upload-wrapper .upload-preview {
    width: 80px;
    height: 80px;
    position: relative;
    margin: 20px auto;
  }
  #client-profile-content .wrapper .tab-body.client-profile .box .box-body .upload-wrapper .upload-description {
    padding-left: 0;
  }
  #client-profile-content .wrapper .tab-body.trust-verification .box .box-heading {
    padding: 15px 20px;
  }
  #client-profile-content .wrapper .tab-body.trust-verification .box .box-body .content-bar {
    padding-left: 20px;
    padding-right: 20px;
  }
  #client-profile-content .wrapper .tab-body.client-review-index .box {
    background: none;
    padding: 0;
  }
  #client-profile-content .wrapper .tab-body.client-review-index .box .info-table {
    padding: 20px;
    background-color: #fff;
  }
  #client-profile-content .wrapper .tab-body.client-review-index .box .info-table:not(:first-child) {
    margin-top: 20px;
  }
  #client-profile-content .wrapper .tab-body.client-review-index .box .info-table .info-row > [class^=col] {
    display: initial;
  }
  #client-profile-content .wrapper .tab-body.client-review-index .box .info-table .info-row .col-logo {
    width: 100%;
  }
  #client-profile-content .wrapper .tab-body.client-review-index .box .info-table .info-row .col-detail {
    width: 100%;
  }
  #client-profile-content .wrapper .tab-body.client-review-index .box .info-table .info-row .col-detail .title {
    margin-bottom: 0px;
  }
  #client-profile-content .wrapper .tab-body .box {
    margin-bottom: 0px;
  }
  #client-profile-content .wrapper .tab-body .box #submit-review.box-body {
    padding: 15px 0;
  }
  #client-profile-content .wrapper .tab-body .box #submit-review.box-body .form-group:last-child {
    margin-bottom: 0;
  }
  #client-profile-content .wrapper .tab-body .box #about-you.box-body {
    padding: 15px 0;
  }
  #client-profile-content .wrapper .tab-body .box #client-review-box-footer {
    width: 100%;
  }
  #client-profile-content .wrapper .tab-body .box #client-review-box-footer.box-footer {
    padding: 15px 10px;
  }
  #client-profile-content .wrapper .tab-body .box #client-review-box-footer.box-footer .btn-effect-1 {
    font-size: 12px;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 10px 15px;
  }
  #client-profile-content .wrapper .tab-body .box #client-review-box-footer.box-footer .pull-left.btn-back {
    padding: 0 5px 60px;
  }
  #client-profile-content .wrapper .tab-body.client-review-form .box .box-body .form-group .label-control-header {
    width: 100%;
  }
  #client-profile-content .wrapper .tab-body.client-review-form .box .box-body .form-group .col-sm-9 .row .col-sm-8 {
    width: 100%;
  }
  #client-profile-content .wrapper .tab-body.client-review-form .box .box-body .form-group .col-sm-9 .row .col-sm-8 .row .col-sm-3 {
    width: 25%;
    padding-right: 0;
    float: left;
    dispay: block;
  }
  #client-profile-content .wrapper .tab-body.client-review-form .box .box-body .form-group .col-sm-9 .row .col-sm-8 .row .col-sm-9 {
    width: 75%;
    float: left;
    dispay: block;
  }
  #client-profile-content .tab-body.client-review-show .wrapper-title {
    font-size: 14px;
    padding-left: 15px;
    margin-bottom: 10px;
  }
  #client-profile-content .tab-body.client-review-show .box .box-header .box-title {
    font-size: 12px;
  }
  #client-profile-content .tab-body.client-review-show .box .box-header .box-title strong {
    font-size: 14px;
  }
  #client-profile-content .tab-body.client-review-show .box .box-header .box-title .helper {
    font-size: 12px;
    line-height: 20px;
  }
  #client-profile-content .tab-body.client-review-show .box .box-body {
    padding: 20px;
  }
  #client-profile-content .tab-body.client-review-show .box .box-body .review_info_row {
    display: table;
    width: 100%;
    margin-bottom: 30px;
    background-color: #f0f4f7;
    border: 1px solid #d4f0e3;
    padding: 15px 0;
  }
  #client-profile-content .tab-body.client-review-show .box .box-body .review_info_row .review_info_col {
    display: block;
    width: 100%;
    font-size: 12px;
  }
  #client-profile-content .tab-body.client-review-show .box .box-body .review_info_row .review_info_col:not(:first-child) {
    border-left: none;
  }
  #client-profile-content .tab-body.client-review-show .box .box-body .review_info_row .review_info_col.col-1 {
    width: 100%;
  }
  #client-profile-content .tab-body.client-review-show .box .box-body .review_info_row .review_info_col.col-2 {
    width: 100%;
  }
  #client-profile-content .tab-body.client-review-show .box .box-body .review_info_row .review_info_col.col-3 {
    width: 100%;
  }
  #client-profile-content .tab-body.client-review-show .box .box-body .review_info_row .review_info_col.col-3 .circles-wrp .circles-text {
    font-size: 24px !important;
  }
}

#client-newprofile-content {
  padding-top: 25px;
  padding-bottom: 25px;
  background-color: #f0f4f7;
}

#client-newprofile-content .wrapper .circle {
  border-radius: 50%;
  width: 100%;
  padding-top: 100%;
  background-color: #d8d8d8;
  font-size: 50px;
  font-weight: bolder;
  color: black;
  position: relative;
  margin-bottom: 20px;
}

#client-newprofile-content .wrapper .circle .circle-inside {
  font-size: 78px;
  letter-spacing: 5px;
  font-weight: bolder;
  text-align: center;
  line-hieght: 1;
  color: #585858;
  text-transform: uppercase;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#client-newprofile-content .wrapper .wrapper-title {
  color: #333333;
  font-size: 14px;
  font-weight: bolder;
  background-color: #ccd9e0;
  padding: 10px 10px 10px 30px;
}

#client-newprofile-content .wrapper img {
  width: 250px;
  margin: auto;
  padding-bottom: 25px;
}

#client-newprofile-content .wrapper .tab {
  color: #8c969c;
  padding: 10px 10px 10px 30px;
  background-color: #ffffff;
}

#client-newprofile-content .wrapper .tab .tab-left-body {
  padding-bottom: 15px;
  width: 180px;
}

#client-newprofile-content .wrapper .tab .fa {
  margin-right: 10px;
  color: #8c969c;
  float: right;
  font-size: 20px;
}

#client-newprofile-content .wrapper .tab-body .box-name {
  padding: 15px 100px 15px 25px;
  font-weight: bolder;
  font-size: 20px;
}

#client-newprofile-content .wrapper .tab-body .box.box-information {
  padding: 15px 100px 15px 25px;
}

#client-newprofile-content .wrapper .tab-body .box.box-information .box-heading {
  background-color: #ccd9e0;
  padding: 25px 25px;
  font-size: 14px;
  font-weight: bold;
}

#client-newprofile-content .wrapper .tab-body .box.box-information .box-body {
  padding: 60px 50px 30px 25px;
  background-color: #ffffff;
}

#client-newprofile-content .wrapper .tab-body .box.box-information .box-body .form-group {
  margin-bottom: 30px;
}

#client-newprofile-content .wrapper .tab-body .box.box-information .box-body .form-group .label-control {
  color: #585858;
  font-size: 12px;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  line-height: 1.5;
  text-align: right;
}

#client-newprofile-content .wrapper .tab-body .box.box-information .box-body .form-group .info {
  font-size: 12px;
  color: #8c969c;
  text-align: left;
}

#client-business-profile-content {
  padding-top: 25px;
  padding-bottom: 25px;
  background-color: #f0f4f7;
}

#client-business-profile-content .wrapper .wrapper-title {
  color: #333333;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  letter-spacing: 1px;
}

#client-business-profile-content .business-profile .preview {
  display: inline-block;
  padding: 15px 20px;
  border: 1px solid #585858;
  color: #585858;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  border-radius: 2px;
}

#client-business-profile-content .business-profile .preview:hover {
  text-decoration: none;
}

#client-business-profile-content .business-profile .box-top {
  background-color: #f3f3f3;
  border: 1px solid #e2e5e7;
  padding: 3px 0;
  margin-bottom: 30px;
  margin-top: 30px;
}

#client-business-profile-content .business-profile .box-top .box-row {
  display: table;
  width: 100%;
  position: relative;
}

#client-business-profile-content .business-profile .box-top .box-row .box-col {
  display: table-cell;
  vertical-align: middle;
  position: relative;
}

#client-business-profile-content .business-profile .box-top .box-row .box-col:not(:first-child) {
  border-left: 1px solid #e2e5e7;
}

#client-business-profile-content .business-profile .box-top .box-row .box-col.col-progress {
  width: 1px;
  padding: 10px 33px;
  white-space: nowrap;
  text-align: center;
}

#client-business-profile-content .business-profile .box-top .box-row .box-col.col-progress #business-progress-circle {
  margin: 0 auto;
  width: 98px;
}

#client-business-profile-content .business-profile .box-top .box-row .box-col.col-type .types {
  text-align: center;
}

#client-business-profile-content .business-profile .box-top .box-row .box-col.col-type .types .type {
  cursor: pointer;
  display: inline-block;
  width: 130px;
}

#client-business-profile-content .business-profile .box-top .box-row .box-col.col-type .types .type:before {
  content: '';
  display: block;
  width: 50px;
  height: 50px;
  background-image: url("../../images/root/all.png");
  margin: 0 auto 15px;
}

#client-business-profile-content .business-profile .box-top .box-row .box-col.col-type .types .type.with-tick {
  position: relative;
}

#client-business-profile-content .business-profile .box-top .box-row .box-col.col-type .types .type.with-tick:after {
  content: '';
  width: 21px;
  height: 21px;
  background: url(../../images/root/all.png) white;
  background-position-y: -555px;
  background-position-x: -767px;
  position: absolute;
  top: -5px;
  right: 35px;
  border-radius: 50%;
  border: 1px solid white;
}

#client-business-profile-content .business-profile .box-top .box-row .box-col.col-type .types .type.type-about:before {
  background-position-y: -738px;
  background-position-x: -321px;
}

#client-business-profile-content .business-profile .box-top .box-row .box-col.col-type .types .type.type-service:before {
  background-position-y: -739px;
  background-position-x: -191px;
}

#client-business-profile-content .business-profile .box-top .box-row .box-col.col-type .types .type.type-detail:before {
  background-position-y: -736px;
  background-position-x: -74px;
}

#client-business-profile-content .business-profile .box-top .box-row .box-col.col-type .types .type.type-portfolio:before {
  background-position-y: -630px;
  background-position-x: -881px;
}

#client-business-profile-content .business-profile .box-top .box-row .box-col.col-type .types .type span {
  display: inline-block;
  line-height: 1;
  padding-bottom: 3px;
  font-weight: bold;
  color: #585858;
}

#client-business-profile-content .business-profile .box-top .box-row .box-col.col-type .types .type.current span {
  border-bottom: 3px solid #02cd8d;
}

#client-business-profile-content .business-profile .box-top .box-row .box-col.col-type .arrow-next {
  position: absolute;
  bottom: 20px;
  transform: translate(-50%, 0);
}

#client-business-profile-content .business-profile .box-top .box-row .box-col.col-type .arrow-next.arrow-1 {
  left: 130px;
}

@media (min-width: 1400px) {
  #client-business-profile-content .business-profile .box-top .box-row .box-col.col-type .arrow-next.arrow-1 {
    left: 212px;
  }
}

#client-business-profile-content .business-profile .box-top .box-row .box-col.col-type .arrow-next.arrow-2 {
  left: 260px;
}

@media (min-width: 1400px) {
  #client-business-profile-content .business-profile .box-top .box-row .box-col.col-type .arrow-next.arrow-2 {
    left: 342px;
  }
}

#client-business-profile-content .business-profile .box-top .box-row .box-col.col-type .arrow-next.arrow-3 {
  left: 390px;
}

@media (min-width: 1400px) {
  #client-business-profile-content .business-profile .box-top .box-row .box-col.col-type .arrow-next.arrow-3 {
    left: 472px;
  }
}

#client-business-profile-content .business-profile .box-top .box-row .box-col.col-status {
  width: 160px;
  padding: 0 30px;
  font-size: 14px;
  color: #585858;
  text-align: center;
  line-height: 1.8;
}

#client-business-profile-content .business-profile .box-top .box-row .box-col.col-status .label {
  line-height: 1;
  font-weight: bold;
  color: #585858;
  display: block;
  margin-bottom: 5px;
  font-size: 16px;
}

#client-business-profile-content .business-profile .box-top .box-row .box-col.col-status .status-toggle {
  margin: 10px auto 0;
  width: 90px;
  height: 20px;
  border-radius: 10px;
  overflow: hidden;
  background-color: #e3e3e3;
}

#client-business-profile-content .business-profile .box-top .box-row .box-col.col-status .status-toggle a {
  float: left;
  width: 50%;
  display: block;
  padding: 0;
  text-align: center;
  line-height: 20px;
  border-radius: 10px;
  background: transparent;
  color: #666666;
  font-size: 11px;
  cursor: pointer;
  text-decoration: none;
}

#client-business-profile-content .business-profile .box-top .box-row .box-col.col-status .status-toggle a.selected {
  background-color: #02cd8d;
  color: rgba(255, 255, 255, 0.75);
}

#client-business-profile-content .business-profile .box-top .box-row .box-col.col-status .status-toggle a:hover {
  text-decoration: none;
}

#client-business-profile-content .business-profile .box-top .box-row .box-col.col-status .status-toggle.off a.selected {
  background-color: #949a9e;
  color: rgba(255, 255, 255, 0.75);
}

#client-business-profile-content .business-profile .box .box-body {
  padding: 15px 100px 15px 25px;
}

#client-business-profile-content .business-profile .box .box-body .form-group {
  margin-bottom: 25px;
}

#client-business-profile-content .business-profile .box .box-body .form-group input.form-control,
#client-business-profile-content .business-profile .box .box-body .form-group textarea.form-control,
#client-business-profile-content .business-profile .box .box-body .form-group select.form-control {
  border: 1px solid #e1e3e3;
  border-radius: 4px;
  padding: 9px 20px;
  line-height: 1.2;
  outline: 0;
  font-size: 14px;
  height: auto;
  color: #585858;
  box-shadow: none;
  font-family: "Roboto", sans-serif;
  background-color: #FFFFFF;
  font-weight: normal;
}

#client-business-profile-content .business-profile .box .box-body .form-group input.form-control:focus,
#client-business-profile-content .business-profile .box .box-body .form-group textarea.form-control:focus,
#client-business-profile-content .business-profile .box .box-body .form-group select.form-control:focus {
  box-shadow: none;
  border: 1px solid #e1e3e3;
  outline: 0;
}

#client-business-profile-content .business-profile .box .box-body .form-group input.form-control:disabled,
#client-business-profile-content .business-profile .box .box-body .form-group textarea.form-control:disabled,
#client-business-profile-content .business-profile .box .box-body .form-group select.form-control:disabled {
  background-color: #e1e3e3;
}

#client-business-profile-content .business-profile .box .box-body .form-group textarea.form-control {
  min-height: 180px;
}

#client-business-profile-content .business-profile .box .box-body .form-group .helper {
  color: #999999;
  padding-top: 8px;
  font-size: 12px;
  font-weight: normal;
  font-family: "Roboto", sans-serif;
}

#client-business-profile-content .business-profile .box .box-body .form-group:after {
  content: '';
  display: table;
  clear: both;
}

#client-business-profile-content .business-profile .box .box-body .form-group.position select.form-control {
  width: 100%;
  max-width: 190px;
}

#client-business-profile-content .business-profile .box .box-body .form-group .group-header a {
  float: left;
  text-align: center;
  display: block;
  padding: 5px;
  width: 55px;
  color: #585858;
  border-top: 1px solid #eaeaea;
  border-left: 1px solid #eaeaea;
  background-color: #eaeaea;
}

#client-business-profile-content .business-profile .box .box-body .form-group .group-header a:last-child {
  border-right: 1px solid #eaeaea;
}

#client-business-profile-content .business-profile .box .box-body .form-group .group-header a.active {
  background-color: transparent;
}

#client-business-profile-content .business-profile .box .box-body .form-group .group-header a:hover {
  text-decoration: none;
}

#client-business-profile-content .business-profile .box .box-body .form-group .group-header a:hover:not(.active) {
  cursor: pointer;
}

#client-business-profile-content .business-profile .box .box-body .form-group .group-body {
  border: 1px solid #eaeaea;
}

#client-business-profile-content .business-profile .box .box-body .form-group .group-body .language {
  display: none;
}

#client-business-profile-content .business-profile .box .box-body .form-group .group-body .language input {
  padding: 10px 15px;
  width: 100%;
  border: none;
  background-color: #FFFFFF;
  outline: 0;
}

#client-business-profile-content .business-profile .box .box-body .form-group .group-body .language.active {
  display: block;
}

#client-business-profile-content .business-profile .box .box-body .form-group.with-group .label-control {
  padding-top: 38px;
}

#client-business-profile-content .business-profile .box .box-body .form-group .label-control {
  color: #585858;
  font-size: 14px;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  line-height: 1.5;
  padding-top: 10px;
  margin-bottom: 10px;
}

#client-business-profile-content .business-profile .box .box-body .form-group .label-control .red {
  color: red;
}

#client-business-profile-content .business-profile .box .box-body .form-group.industries .industries-container .industry-column {
  margin-bottom: 30px;
}

#client-business-profile-content .business-profile .box .box-body .form-group.industries .add_industry {
  background: transparent;
  border: 1px dashed #02cd8d;
  color: #02cd8d;
  font-size: 16px;
  border-radius: 4px;
  padding: 8px 15px;
  cursor: pointer;
}

#client-business-profile-content .business-profile .box .box-body .form-group.industries .add_industry:hover {
  background-color: #f8f8f8;
}

#client-business-profile-content .business-profile .box .box-body .form-group.form-target_market {
  padding-bottom: 30px;
}

#client-business-profile-content .business-profile .box .box-body .form-group.form-target_market .irs-grid .js-grid-text-0 {
  margin-left: -10px !important;
  text-align: left !important;
}

#client-business-profile-content .business-profile .box .box-body .form-group.form-target_market .irs-grid .js-grid-text-1 {
  margin-right: -10px !important;
  text-align: right !important;
}

#client-business-profile-content .business-profile .box .box-body .form-group.form-minimum_project_value {
  padding-bottom: 30px;
}

#client-business-profile-content .business-profile .box .box-body .form-group.form-minimum_project_value .irs-min,
#client-business-profile-content .business-profile .box .box-body .form-group.form-minimum_project_value .irs-max {
  top: 60px;
  visibility: visible !important;
  position: absolute;
  background: transparent;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  letter-spacing: 1px;
  color: #585858;
  bottom: initial;
  margin-top: -10px;
  line-height: 1.3;
  font-weight: bold;
  color: #000000;
}

#client-business-profile-content .business-profile .box .box-body .form-group.form-minimum_project_value .irs-bar,
#client-business-profile-content .business-profile .box .box-body .form-group.form-minimum_project_value .irs-bar-edge {
  background-color: transparent !important;
}

#client-business-profile-content .business-profile .box .box-body .form-group.form-minimum_project_value .irs-slider {
  top: 21px;
  border: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #02cd8d;
}

#client-business-profile-content .business-profile .box .box-body .form-group.form-minimum_project_value .irs-slider:before {
  display: none;
}

#client-business-profile-content .business-profile .box .box-body .form-group.form-minimum_project_value .irs-slider:after {
  display: none;
}

#client-business-profile-content .business-profile .box .box-body .form-group.form-minimum_project_value .irs-single {
  background-color: #02cd8d;
  font-size: 12px;
  padding: 3px 5px;
  top: -5px;
}

#client-business-profile-content .business-profile .box .box-body .form-group.form-minimum_project_value .irs-single:after {
  display: none !important;
}

#client-business-profile-content .business-profile .box .box-body .form-group.form-positioning label {
  margin-bottom: 0;
}

#client-business-profile-content .business-profile .box .box-body .form-group.form-positioning .slider-row {
  padding-top: 15px;
}

#client-business-profile-content .business-profile .box .box-body .form-group.form-positioning .slider-row:first-child {
  padding-top: 0;
}

#client-business-profile-content .business-profile .box .box-body .form-group.form-positioning .irs-single {
  visibility: hidden !important;
}

#client-business-profile-content .business-profile .box .box-body .form-group.form-positioning .irs-min,
#client-business-profile-content .business-profile .box .box-body .form-group.form-positioning .irs-max {
  font-weight: bold;
  color: #000000;
  bottom: 21px;
  position: absolute;
  background: transparent;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  letter-spacing: 1px;
  top: initial;
  margin-top: -10px;
  line-height: 1.3;
  visibility: visible !important;
}

#client-business-profile-content .business-profile .box .box-body .form-group.form-positioning .irs-bar,
#client-business-profile-content .business-profile .box .box-body .form-group.form-positioning .irs-bar-edge {
  background-color: transparent !important;
}

#client-business-profile-content .business-profile .box .box-body .form-group.form-positioning .irs-slider {
  top: 21px;
  border: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #02cd8d;
}

#client-business-profile-content .business-profile .box .box-body .form-group.form-positioning .irs-slider:before {
  display: none;
}

#client-business-profile-content .business-profile .box .box-body .form-group.form-positioning .irs-slider:after {
  display: none;
}

#client-business-profile-content .business-profile .box .box-body .select2-selection--multiple {
  border: 1px solid #e1e3e3 !important;
}

#client-business-profile-content .business-profile .box .box-body .select2-search__field {
  padding: 7px 20px;
  margin-top: 0;
}

#client-business-profile-content .business-profile .box .box-body .upload-wrapper {
  position: relative;
}

#client-business-profile-content .business-profile .box .box-body .upload-wrapper .upload-preview {
  width: 169px;
  height: 169px;
  position: absolute;
  border: 3px solid #e1e3e3;
  left: 0;
  top: 0;
}

#client-business-profile-content .business-profile .box .box-body .upload-wrapper .upload-preview img:not(.remove-file) {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
}

#client-business-profile-content .business-profile .box .box-body .upload-wrapper .upload-preview img.remove-file {
  cursor: pointer;
  position: absolute;
  left: 100%;
  bottom: 100%;
  transform: translate(-50%, 50%);
}

#client-business-profile-content .business-profile .box .box-body .upload-wrapper .upload-preview + .upload-description {
  padding-left: 200px;
  min-height: 175px;
}

#client-business-profile-content .business-profile .box .box-body .upload-wrapper .upload-description .helper + .label-file {
  margin-top: 15px;
}

#client-business-profile-content .business-profile .box .box-body .upload-wrapper .upload-description .label-file label {
  font-size: 14px;
  padding: 5px 15px 11px;
  margin-right: 20px;
  position: relative;
  border-radius: 3px;
  background-color: #02cd8d;
  cursor: pointer;
  overflow: hidden;
}

#client-business-profile-content .business-profile .box .box-body .upload-wrapper .upload-description .label-file label:after {
  position: absolute;
  bottom: 0;
  height: 6px;
  left: 0;
  right: 0;
  background-color: #28ac78;
  transition: height .3s;
  content: '';
  display: block;
  z-index: 1;
}

#client-business-profile-content .business-profile .box .box-body .upload-wrapper .upload-description .label-file label span {
  position: relative;
  z-index: 2;
  color: #FFFFFF;
}

#client-business-profile-content .business-profile .box .box-body .upload-wrapper .upload-description .label-file label:hover:after {
  height: 100%;
}

#client-business-profile-content .business-profile .box .box-body .upload-wrapper .upload-description .label-file input[type=file] {
  display: none;
}

#client-business-profile-content .business-profile .box .box-body .upload-wrapper .upload-description .label-file input[type=text] {
  border: none !important;
  padding: 0 !important;
  display: block !important;
}

#client-business-profile-content .business-profile .box .box-body .upload-wrapper.text-left {
  position: relative;
  padding-right: 115px;
}

#client-business-profile-content .business-profile .box .box-body .upload-wrapper.text-left .upload-description .btn-file {
  width: 109px;
  right: 0;
  top: 0;
  position: absolute;
  margin-right: 0;
  height: 41px;
}

#client-business-profile-content .business-profile .box .box-body .offices .offices-container {
  padding: 0;
  margin: 0;
  list-style: none;
}

#client-business-profile-content .business-profile .box .box-body .offices .offices-container .office-row {
  margin-bottom: 10px;
}

#client-business-profile-content .business-profile .box .box-body .offices .offices-container .office-row .icon-sort {
  position: relative;
  height: 41px;
  margin-left: -15px;
}

#client-business-profile-content .business-profile .box .box-body .offices .offices-container .office-row .icon-sort img {
  position: absolute;
  height: 24px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

#client-business-profile-content .business-profile .box .box-body .offices .offices-container li.placeholder {
  position: relative;
  margin: 0;
  padding: 0;
  border: none;
}

#client-business-profile-content .business-profile .box .box-body .offices .offices-container li.placeholder:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  margin-top: -5px;
  left: -5px;
  top: -4px;
  border: 5px solid transparent;
  border-left-color: #FF0000;
  border-right: none;
}

#client-business-profile-content .business-profile .box .box-body .offices .add_office {
  background: #FFFFFF;
  border: 1px dashed #02cd8d;
  color: #02cd8d;
  font-size: 16px;
  border-radius: 4px;
  padding: 8px 15px;
  margin-top: 15px;
  margin-bottom: 15px;
  cursor: pointer;
}

#client-business-profile-content .business-profile .box .box-body .offices .add_office:hover {
  background-color: #f8f8f8;
}

#client-business-profile-content .business-profile .box .box-body .type-radios {
  margin-left: -4px;
}

#client-business-profile-content .business-profile .box .box-body .type-radios .radio {
  margin-left: 4px;
  font-weight: normal;
  display: inline-block;
  padding: 5px 12px;
  border-radius: 15px;
  background-color: #e1e3e3;
  border: 2px solid #e1e3e3;
  cursor: pointer;
}

#client-business-profile-content .business-profile .box .box-body .type-radios .radio.checked {
  border: 2px solid #02cd8d;
}

#client-business-profile-content .business-profile .box .box-body .type-radios .radio input {
  display: none;
}

#client-business-profile-content .business-profile .box .box-body .type-content {
  display: none;
}

#client-business-profile-content .business-profile .box .box-body .type-content .helper {
  color: #9C9C9C;
  font-size: 10px;
  margin-bottom: 10px;
}

#client-business-profile-content .business-profile .box .box-body .type-content .btn-add-past-work-image {
  cursor: pointer;
  background-color: #02cd8d;
  border-radius: 3px;
  padding: 10px 20px;
  font-size: 14px;
  display: inline-block;
  color: #FFFFFF;
}

#client-business-profile-content .business-profile .box .box-body .type-content.image .image-lists {
  list-style: none;
  padding: 0;
  margin: 0;
}

#client-business-profile-content .business-profile .box .box-body .type-content.image .image-lists .image-row {
  margin-bottom: 15px;
  position: relative;
}

#client-business-profile-content .business-profile .box .box-body .type-content.image .image-lists .image-row .image-row-inner {
  position: relative;
  padding-left: 100px;
  padding-right: 60px;
}

#client-business-profile-content .business-profile .box .box-body .type-content.image .image-lists .image-row .image-row-inner .image-wrapper {
  position: absolute;
  left: 0;
  width: 95px;
  height: 100%;
  overflow: hidden;
  border-radius: 4px;
}

#client-business-profile-content .business-profile .box .box-body .type-content.image .image-lists .image-row .image-row-inner .image-wrapper img {
  max-width: 100%;
}

#client-business-profile-content .business-profile .box .box-body .type-content.image .image-lists .image-row .image-row-inner .input-wrapper {
  width: 100%;
}

#client-business-profile-content .business-profile .box .box-body .type-content.image .image-lists .image-row .image-row-inner .remove-wrapper {
  position: absolute;
  top: 0;
  height: 100%;
  right: 10px;
  width: 40px;
}

#client-business-profile-content .business-profile .box .box-body .type-content.image .image-lists .image-row .image-row-inner .remove-wrapper .btn-remove {
  background-image: url("../../images/root/client/business-profile/portfolio/remove.png");
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
  position: absolute;
  height: 80%;
  width: 20px;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
}

#client-business-profile-content .business-profile .box .box-body .type-content.image .image-lists .image-row .image-row-inner:after {
  content: '';
  display: block;
  clear: both;
}

#client-business-profile-content .business-profile .box .box-body .type-content.image .image-lists li.placeholder {
  position: relative;
  margin: 0;
  padding: 0;
  border: none;
}

#client-business-profile-content .business-profile .box .box-body .type-content.image .image-lists li.placeholder:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  margin-top: -5px;
  left: -5px;
  top: -4px;
  border: 5px solid transparent;
  border-left-color: #FF0000;
  border-right: none;
}

#client-business-profile-content .business-profile .box .box-body .type-content.youtube .input-with-append {
  padding-right: 68px;
  position: relative;
}

#client-business-profile-content .business-profile .box .box-body .type-content.youtube .input-with-append .btn-add-past-work-youtube {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  background-color: #02cd8d;
  border-radius: 3px;
  padding: 10px 20px;
  font-size: 14px;
  display: inline-block;
  color: #FFFFFF;
}

#client-business-profile-content .business-profile .box .box-body .type-content.youtube .youtube-lists {
  list-style: none;
  padding: 0;
  margin: 0;
}

#client-business-profile-content .business-profile .box .box-body .type-content.youtube .youtube-lists li.placeholder {
  position: relative;
  margin: 0;
  padding: 0;
  border: none;
}

#client-business-profile-content .business-profile .box .box-body .type-content.youtube .youtube-lists li.placeholder:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  margin-top: -5px;
  left: -5px;
  top: -4px;
  border: 5px solid transparent;
  border-left-color: #FF0000;
  border-right: none;
}

#client-business-profile-content .business-profile .box .box-body .type-content.youtube .youtube-lists .youtube-row {
  margin-top: 15px;
  position: relative;
  padding-left: 100px;
  padding-right: 60px;
}

#client-business-profile-content .business-profile .box .box-body .type-content.youtube .youtube-lists .youtube-row .image-wrapper {
  position: absolute;
  left: 0;
  width: 95px;
  height: 100%;
  overflow: hidden;
  border-radius: 4px;
}

#client-business-profile-content .business-profile .box .box-body .type-content.youtube .youtube-lists .youtube-row .image-wrapper img {
  max-width: 100%;
}

#client-business-profile-content .business-profile .box .box-body .type-content.youtube .youtube-lists .youtube-row .input-wrapper {
  width: 100%;
}

#client-business-profile-content .business-profile .box .box-body .type-content.youtube .youtube-lists .youtube-row .remove-wrapper {
  position: absolute;
  top: 0;
  height: 100%;
  right: 10px;
  width: 40px;
}

#client-business-profile-content .business-profile .box .box-body .type-content.youtube .youtube-lists .youtube-row .remove-wrapper .btn-remove {
  background-image: url("../../images/root/client/business-profile/portfolio/remove.png");
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
  position: absolute;
  height: 80%;
  width: 20px;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
}

#client-business-profile-content .business-profile .box .box-body .type-content.youtube .youtube-lists .youtube-row:after {
  content: '';
  display: block;
  clear: both;
}

#client-business-profile-content .business-profile .box .box-body .type-content.vimeo .input-with-append {
  padding-right: 68px;
  position: relative;
}

#client-business-profile-content .business-profile .box .box-body .type-content.vimeo .input-with-append .btn-add-past-work-vimeo {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  background-color: #02cd8d;
  border-radius: 3px;
  padding: 10px 20px;
  font-size: 14px;
  display: inline-block;
  color: #FFFFFF;
}

#client-business-profile-content .business-profile .box .box-body .type-content.vimeo .vimeo-lists {
  list-style: none;
  padding: 0;
  margin: 0;
}

#client-business-profile-content .business-profile .box .box-body .type-content.vimeo .vimeo-lists li.placeholder {
  position: relative;
  margin: 0;
  padding: 0;
  border: none;
}

#client-business-profile-content .business-profile .box .box-body .type-content.vimeo .vimeo-lists li.placeholder:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  margin-top: -5px;
  left: -5px;
  top: -4px;
  border: 5px solid transparent;
  border-left-color: #FF0000;
  border-right: none;
}

#client-business-profile-content .business-profile .box .box-body .type-content.vimeo .vimeo-lists .vimeo-row {
  margin-top: 15px;
  position: relative;
  padding-left: 100px;
  padding-right: 60px;
}

#client-business-profile-content .business-profile .box .box-body .type-content.vimeo .vimeo-lists .vimeo-row .image-wrapper {
  position: absolute;
  left: 0;
  width: 95px;
  height: 100%;
  overflow: hidden;
  border-radius: 4px;
}

#client-business-profile-content .business-profile .box .box-body .type-content.vimeo .vimeo-lists .vimeo-row .image-wrapper img {
  max-width: 100%;
}

#client-business-profile-content .business-profile .box .box-body .type-content.vimeo .vimeo-lists .vimeo-row .input-wrapper {
  width: 100%;
}

#client-business-profile-content .business-profile .box .box-body .type-content.vimeo .vimeo-lists .vimeo-row .remove-wrapper {
  position: absolute;
  top: 0;
  height: 100%;
  right: 10px;
  width: 40px;
}

#client-business-profile-content .business-profile .box .box-body .type-content.vimeo .vimeo-lists .vimeo-row .remove-wrapper .btn-remove {
  background-image: url("../../images/root/client/business-profile/portfolio/remove.png");
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
  position: absolute;
  height: 80%;
  width: 20px;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
}

#client-business-profile-content .business-profile .box .box-body .type-content.vimeo .vimeo-lists .vimeo-row:after {
  content: '';
  display: block;
  clear: both;
}

#client-business-profile-content .business-profile .box .box-body .type-content.opened {
  display: block;
}

#client-business-profile-content .business-profile .box .box-body .SumoSelect > .CaptionCont {
  margin: 0;
  padding: 10px 20px;
}

#client-business-profile-content .business-profile .box .box-body .red {
  color: red;
}

#client-business-profile-content .business-profile .box .box-body .error-text {
  display: none;
  color: red;
}

#client-business-profile-content .business-profile .box .box-body .has-error-row {
  border: none;
}

#client-business-profile-content .business-profile .box .box-body .has-error-row input[type=text] {
  border-color: red !important;
}

#client-business-profile-content .business-profile .box .box-body .has-error-row .error-text {
  display: block;
}

#client-business-profile-content .business-profile .box-tab.service .box-body {
  padding-right: 25px;
}

#client-business-profile-content .business-profile .box-tab.service .box-body .service-input-group {
  margin-bottom: 30px;
}

#client-business-profile-content .business-profile .box-tab.service .box-body .service-input-group label {
  border: 1px solid #eaeaea;
  display: block;
  font-weight: normal;
  width: 100%;
  margin: 0;
  padding-left: 50px;
  position: relative;
  background-color: #FFFFFF;
}

#client-business-profile-content .business-profile .box-tab.service .box-body .service-input-group label .fa {
  position: absolute;
  left: 15px;
  top: 10px;
}

#client-business-profile-content .business-profile .box-tab.service .box-body .service-input-group label input {
  padding: 10px 15px;
  width: 100%;
  border: none;
  background-color: #FFFFFF;
  outline: 0;
}

#client-business-profile-content .business-profile .box-tab.service .box-body .service-group .row-option {
  margin-bottom: 30px;
}

#client-business-profile-content .business-profile .box-tab.service .box-body .service-group .row-option .box-service {
  width: 25%;
  padding-left: 10px;
  padding-right: 10px;
  float: left;
}

#client-business-profile-content .business-profile .box-tab.service .box-body .service-group .row-option .box-service .box-header {
  cursor: pointer;
  transition: border .3s;
  border: 1px solid #eaeaea;
  background-color: #FFFFFF;
  text-align: center;
  padding: 30px;
}

#client-business-profile-content .business-profile .box-tab.service .box-body .service-group .row-option .box-service .box-header .icon {
  margin: 0 auto;
  width: 50px;
  height: 50px;
  background: url("../../images/root/all.png");
}

#client-business-profile-content .business-profile .box-tab.service .box-body .service-group .row-option .box-service .box-header .icon.web {
  background-position-x: -499px;
  background-position-y: -348px;
}

#client-business-profile-content .business-profile .box-tab.service .box-body .service-group .row-option .box-service .box-header .icon.accounting {
  background-position-x: -377px;
  background-position-y: -344px;
}

#client-business-profile-content .business-profile .box-tab.service .box-body .service-group .row-option .box-service .box-header .icon.writing {
  background-position-x: -837px;
  background-position-y: -234px;
}

#client-business-profile-content .business-profile .box-tab.service .box-body .service-group .row-option .box-service .box-header .icon.creative {
  background-position-x: -709px;
  background-position-y: -224px;
}

#client-business-profile-content .business-profile .box-tab.service .box-body .service-group .row-option .box-service .box-header h5 {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  line-height: 1.2;
}

#client-business-profile-content .business-profile .box-tab.service .box-body .service-group .row-option .box-service .box-header .back {
  display: none;
  float: right;
  padding: 10px 15px;
  border: 1px solid #585858;
  color: #585858;
  font-family: "Roboto", sans-serif;
}

#client-business-profile-content .business-profile .box-tab.service .box-body .service-group .row-option .box-service .box-header .back .fa {
  display: inline-block;
  padding-right: 15px;
}

#client-business-profile-content .business-profile .box-tab.service .box-body .service-group .row-option .box-service .box-body {
  display: none;
  border-left: 1px solid #eaeaea;
  border-right: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
  background-color: #FFFFFF;
}

#client-business-profile-content .business-profile .box-tab.service .box-body .service-group .row-option .box-service .box-body .col {
  float: left;
  width: 50%;
  padding: 10px;
}

#client-business-profile-content .business-profile .box-tab.service .box-body .service-group .row-option .box-service .box-body .col .item {
  padding: 10px 15px;
  text-align: left;
  color: #585858;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  cursor: pointer;
}

#client-business-profile-content .business-profile .box-tab.service .box-body .service-group .row-option .box-service .box-body .col .item .add {
  display: none;
  float: right;
}

#client-business-profile-content .business-profile .box-tab.service .box-body .service-group .row-option .box-service .box-body .col .item .tick {
  display: none;
  float: right;
}

#client-business-profile-content .business-profile .box-tab.service .box-body .service-group .row-option .box-service .box-body .col .item:not(.selected):hover {
  color: #FFFFFF;
  background-color: #373f48;
}

#client-business-profile-content .business-profile .box-tab.service .box-body .service-group .row-option .box-service .box-body .col .item:not(.selected):hover .add {
  display: block;
  color: #FFFFFF;
}

#client-business-profile-content .business-profile .box-tab.service .box-body .service-group .row-option .box-service .box-body .col .item.selected {
  background-color: #c7ffe4;
}

#client-business-profile-content .business-profile .box-tab.service .box-body .service-group .row-option .box-service .box-body .col .item.selected .tick {
  display: block;
  color: #FFFFFF;
}

#client-business-profile-content .business-profile .box-tab.service .box-body .service-group .row-option .box-service .box-body .col .item:after {
  content: '';
  display: table;
  clear: both;
}

#client-business-profile-content .business-profile .box-tab.service .box-body .service-group .row-option .box-service:hover .box-header {
  border: 1px solid #02cd8d;
}

#client-business-profile-content .business-profile .box-tab.service .box-body .service-group .row-option .box-all-service {
  display: none;
  width: 100%;
  float: left;
  padding-left: 10px;
  padding-right: 10px;
}

#client-business-profile-content .business-profile .box-tab.service .box-body .service-group .row-option .box-all-service .box-header {
  transition: border .3s;
  border: 1px solid #eaeaea;
  background-color: #FFFFFF;
  text-align: left;
  padding: 30px;
}

#client-business-profile-content .business-profile .box-tab.service .box-body .service-group .row-option .box-all-service .box-header h5 {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  line-height: 1.2;
  float: left;
}

#client-business-profile-content .business-profile .box-tab.service .box-body .service-group .row-option .box-all-service .box-header .back {
  display: block;
  float: right;
  padding: 10px 15px;
  border: 1px solid #585858;
  color: #585858;
  font-family: "Roboto", sans-serif;
  cursor: pointer;
}

#client-business-profile-content .business-profile .box-tab.service .box-body .service-group .row-option .box-all-service .box-header .back .fa {
  display: inline-block;
  padding-right: 15px;
}

#client-business-profile-content .business-profile .box-tab.service .box-body .service-group .row-option .box-all-service .box-header .back:hover {
  text-decoration: none;
}

#client-business-profile-content .business-profile .box-tab.service .box-body .service-group .row-option .box-all-service .box-body {
  border-left: 1px solid #eaeaea;
  border-right: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
  background-color: #FFFFFF;
}

#client-business-profile-content .business-profile .box-tab.service .box-body .service-group .row-option .box-all-service .box-body .col {
  float: left;
  width: 50%;
  padding: 10px;
}

#client-business-profile-content .business-profile .box-tab.service .box-body .service-group .row-option .box-all-service .box-body .col .item {
  padding: 10px 15px;
  text-align: left;
  color: #585858;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  cursor: pointer;
}

#client-business-profile-content .business-profile .box-tab.service .box-body .service-group .row-option .box-all-service .box-body .col .item .add {
  display: none;
  float: right;
}

#client-business-profile-content .business-profile .box-tab.service .box-body .service-group .row-option .box-all-service .box-body .col .item .tick {
  display: none;
  float: right;
}

#client-business-profile-content .business-profile .box-tab.service .box-body .service-group .row-option .box-all-service .box-body .col .item:not(.selected):hover {
  color: #FFFFFF;
  background-color: #373f48;
}

#client-business-profile-content .business-profile .box-tab.service .box-body .service-group .row-option .box-all-service .box-body .col .item:not(.selected):hover .add {
  display: block;
  color: #FFFFFF;
}

#client-business-profile-content .business-profile .box-tab.service .box-body .service-group .row-option .box-all-service .box-body .col .item.selected {
  background-color: #c7ffe4;
}

#client-business-profile-content .business-profile .box-tab.service .box-body .service-group .row-option .box-all-service .box-body .col .item.selected .tick {
  display: block;
  color: #FFFFFF;
}

#client-business-profile-content .business-profile .box-tab.service .box-body .service-group .row-option .box-all-service .box-body .col .item:after {
  content: '';
  display: table;
  clear: both;
}

#client-business-profile-content .business-profile .box-tab.service .box-body .service-group .row-option .box-all-service.active {
  display: block;
}

#client-business-profile-content .business-profile .box-tab.service .box-body .service-group .row-option.extended .box-service {
  display: none;
}

#client-business-profile-content .business-profile .box-tab.service .box-body .service-group .row-option.extended .box-service.active {
  display: block;
  width: 100%;
}

#client-business-profile-content .business-profile .box-tab.service .box-body .service-group .row-option.extended .box-service.active .box-header {
  padding: 20px 30px;
}

#client-business-profile-content .business-profile .box-tab.service .box-body .service-group .row-option.extended .box-service.active .box-header img {
  float: left;
  margin-right: 10px;
}

#client-business-profile-content .business-profile .box-tab.service .box-body .service-group .row-option.extended .box-service.active .box-header h5 {
  float: left;
  text-align: left;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  line-height: 1.3;
}

#client-business-profile-content .business-profile .box-tab.service .box-body .service-group .row-option.extended .box-service.active .box-header .back {
  display: block;
}

#client-business-profile-content .business-profile .box-tab.service .box-body .service-group .row-option.extended .box-service.active .box-body {
  display: block;
}

#client-business-profile-content .business-profile .box-tab.service .box-body .service-group .row-option.extended .box-service.active:hover .box-header {
  border: 1px solid #eaeaea;
}

#client-business-profile-content .business-profile .box-tab.service .box-body .service-group .box-selected {
  margin-bottom: 30px;
}

#client-business-profile-content .business-profile .box-tab.service .box-body .service-group .box-selected .box-heading {
  border: 1px solid #eaeaea;
  background-color: #FFFFFF;
  text-align: center;
  padding: 20px;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  line-height: 1.3;
  font-weight: normal;
}

#client-business-profile-content .business-profile .box-tab.service .box-body .service-group .box-selected .box-body {
  display: block;
  border-left: 1px solid #eaeaea;
  border-right: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
  background-color: #FFFFFF;
}

#client-business-profile-content .business-profile .box-tab.service .box-body .service-group .box-selected .box-body .col {
  float: left;
  width: 50%;
  padding: 10px;
}

#client-business-profile-content .business-profile .box-tab.service .box-body .service-group .box-selected .box-body .col .item {
  padding: 10px 15px;
  text-align: left;
  color: #585858;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  cursor: pointer;
}

#client-business-profile-content .business-profile .box-tab.service .box-body .service-group .box-selected .box-body .col .item .cancel {
  display: none;
  float: right;
}

#client-business-profile-content .business-profile .box-tab.service .box-body .service-group .box-selected .box-body .col .item:hover {
  color: #FFFFFF;
  background-color: #373f48;
}

#client-business-profile-content .business-profile .box-tab.service .box-body .service-group .box-selected .box-body .col .item:hover .cancel {
  display: block;
  color: #FFFFFF;
}

#client-business-profile-content .business-profile .box-tab.service .box-body .service-group .box-selected .box-body .col .item:after {
  content: '';
  display: table;
  clear: both;
}

#client-business-profile-content .business-profile .box-tab.detail .service-description-tab .service-description-links {
  margin-left: -4px;
  margin-right: -4px;
  padding-bottom: 20px;
}

#client-business-profile-content .business-profile .box-tab.detail .service-description-tab .service-description-links .service-description-link {
  cursor: pointer;
  display: block;
  float: left;
  margin: 0 4px 8px;
  background-color: #505961;
  border: 1px solid #505961;
  padding: 3px 8px;
  border-radius: 2px;
  color: rgba(255, 255, 255, 0.75);
  transition: color .3s;
}

#client-business-profile-content .business-profile .box-tab.detail .service-description-tab .service-description-links .service-description-link.opened {
  background-color: #FFFFFF;
  border: 1px solid #04cc8d;
  color: #04cc8d;
}

#client-business-profile-content .business-profile .box-tab.detail .service-description-tab .service-description-links .service-description-link:not(.opened):hover {
  color: #FFFFFF;
}

#client-business-profile-content .business-profile .box-tab.detail .service-description-tab .service-description-contents .service-description-content {
  display: none;
  opacity: 0;
  visibility: hidden;
  transition: opacity .3s;
}

#client-business-profile-content .business-profile .box-tab.detail .service-description-tab .service-description-contents .service-description-content.opened {
  display: block;
  visibility: visible;
  opacity: 1;
}

#client-business-profile-content .business-profile .box-tab.portfolio .box-body {
  padding: 15px 25px;
}

#client-business-profile-content .business-profile .box-tab.portfolio .box-body .col-sm-4 {
  padding-top: 25px;
}

#client-business-profile-content .business-profile .box-tab.portfolio .box-body .card {
  min-height: 300px;
  cursor: pointer;
  border: 1px solid transparent;
  transition: border .3s;
}

#client-business-profile-content .business-profile .box-tab.portfolio .box-body .card:hover {
  border: 1px solid #02cd8d;
  cursor: pointer;
}

#client-business-profile-content .business-profile .box-tab.portfolio .box-body .card.card_add_past_work {
  position: relative;
  background-color: #e1e3e3;
}

#client-business-profile-content .business-profile .box-tab.portfolio .box-body .card.card_add_past_work .add_past_work {
  text-align: center;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

#client-business-profile-content .business-profile .box-tab.portfolio .box-body .card.card_add_past_work .add_past_work .icon {
  color: #02cd8d;
  font-size: 35px;
  padding-bottom: 30px;
}

#client-business-profile-content .business-profile .box-tab.portfolio .box-body .card.card_past_work {
  box-shadow: 0 0px 2px 1px #edf1f3;
  border-radius: 5px;
  overflow: hidden;
}

#client-business-profile-content .business-profile .box-tab.portfolio .box-body .card.card_past_work .card-heading {
  position: relative;
  padding-bottom: 75%;
  background-size: cover;
  background-position: 0 0;
  overflow: hidden;
  background-color: #dddddd;
  background-repeat: no-repeat;
}

#client-business-profile-content .business-profile .box-tab.portfolio .box-body .card.card_past_work .card-heading .card-heading-title {
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  z-index: 1;
  color: #fff;
  font-size: 18px;
  line-height: 26px;
}

#client-business-profile-content .business-profile .box-tab.portfolio .box-body .card.card_past_work .content {
  padding: 0 15px 15px;
}

#client-business-profile-content .business-profile .box-tab.portfolio .box-body .card.card_past_work .content .title {
  color: #444444;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
  line-height: 18px;
  letter-spacing: 0;
}

#client-business-profile-content .business-profile .box-tab.portfolio .box-body .card.card_past_work .content .services {
  margin-left: -4px;
  margin-top: 4px;
}

@media (max-width: 1024px) {
  #client-business-profile-content .business-profile .box-tab.portfolio .box-body .card.card_past_work .content .services {
    display: none;
  }
}

#client-business-profile-content .business-profile .box-tab.portfolio .box-body .card.card_past_work .content .services .service {
  margin-left: 4px;
  margin-bottom: 4px;
  background-color: #505961;
  display: inline-block;
  padding: 3px 8px;
  border-radius: 2px;
  color: rgba(255, 255, 255, 0.75);
}

#client-business-profile-content .business-profile .box-tab.portfolio .box-body .card.card_past_work .content .divider {
  height: 1px;
  background-color: #edf1f3;
  margin-top: 10px;
  margin-bottom: 10px;
}

#client-business-profile-content .business-profile .box-tab.portfolio .box-body .card.card_past_work .content .tags {
  color: #8b8b8b;
  font-size: 12px;
}

#client-business-profile-content .business-profile .box-tab.portfolio .box-body .card.card_past_work .content .description {
  color: #737373;
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  line-height: 1.3;
}

#client-business-profile-content .business-profile .box-tab .multi-input-group {
  margin-bottom: 30px;
}

#client-business-profile-content .business-profile .box-tab .multi-input-group .multi-input-control {
  margin-bottom: 15px;
  border: 1px solid #979797;
  position: relative;
  padding: 5px 65px 5px 10px;
  background-color: #FFFFFF;
}

#client-business-profile-content .business-profile .box-tab .multi-input-group .multi-input-control label {
  font-size: 14px;
  line-height: 1;
  font-weight: normal;
}

#client-business-profile-content .business-profile .box-tab .multi-input-group .multi-input-control .value-box {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 64px;
  border-left: 1px solid #979797;
  padding: 5px 10px;
  font-size: 14px;
  background-color: #eeeeee;
}

#client-business-profile-content .business-profile .box-tab .multi-input-group .multi-input-control .value-box input {
  width: 100%;
  padding-right: 15px;
  text-align: right;
  border: 0;
  background: transparent;
  outline: 0;
}

#client-business-profile-content .business-profile .box-tab .multi-input-group .multi-input-control .value-box span {
  position: absolute;
  right: 10px;
  top: 5px;
}

#client-business-profile-content .business-profile .box-tab .form-group-multi-language {
  margin-bottom: 25px;
}

#client-business-profile-content .business-profile .box-tab .form-group-multi-language .label-control {
  color: #585858;
  font-size: 14px;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  line-height: 1.5;
  padding-top: 10px;
}

#client-business-profile-content .business-profile .box-tab .form-group-multi-language:after {
  content: '';
  display: table;
  clear: both;
}

#client-business-profile-content .business-profile .box-tab .form-group-multi-language .group-header a {
  float: left;
  cursor: pointer;
  text-align: center;
  display: block;
  padding: 5px;
  width: 55px;
  color: #585858;
  border-top: 1px solid #eaeaea;
  border-left: 1px solid #eaeaea;
  background-color: #eaeaea;
}

#client-business-profile-content .business-profile .box-tab .form-group-multi-language .group-header a:last-child {
  border-right: 1px solid #eaeaea;
}

#client-business-profile-content .business-profile .box-tab .form-group-multi-language .group-header a.active {
  background-color: transparent;
}

#client-business-profile-content .business-profile .box-tab .form-group-multi-language .group-header a:hover {
  text-decoration: none;
}

#client-business-profile-content .business-profile .box-tab .form-group-multi-language .group-body {
  border: 1px solid #eaeaea;
}

#client-business-profile-content .business-profile .box-tab .form-group-multi-language .group-body .language {
  padding: 0 20px 20px;
  display: none;
}

#client-business-profile-content .business-profile .box-tab .form-group-multi-language .group-body .language .row {
  margin-top: 12px;
}

#client-business-profile-content .business-profile .box-tab .form-group-multi-language .group-body .language .row .label-control + div input.form-control, #client-business-profile-content .business-profile .box-tab .form-group-multi-language .group-body .language .row .label-control + div textarea.form-control {
  border: 1px solid #e1e3e3;
  border-radius: 4px;
  padding: 10px 20px;
  line-height: 1;
  outline: 0;
  font-size: 14px;
  height: auto;
  color: #585858;
  box-shadow: none;
  font-family: "Roboto", sans-serif;
  background-color: #FFFFFF;
  font-weight: normal;
}

#client-business-profile-content .business-profile .box-tab .form-group-multi-language .group-body .language .row .label-control + div input.form-control:focus, #client-business-profile-content .business-profile .box-tab .form-group-multi-language .group-body .language .row .label-control + div textarea.form-control:focus {
  box-shadow: none;
  border: 1px solid #e1e3e3;
  outline: 0;
}

#client-business-profile-content .business-profile .box-tab .form-group-multi-language .group-body .language .row .label-control + div input.form-control:disabled, #client-business-profile-content .business-profile .box-tab .form-group-multi-language .group-body .language .row .label-control + div textarea.form-control:disabled {
  background-color: #e1e3e3;
}

#client-business-profile-content .business-profile .box-tab .form-group-multi-language .group-body .language .row .label-control + div textarea.form-control {
  min-height: 180px;
}

#client-business-profile-content .business-profile .box-tab .form-group-multi-language .group-body .language input.form-control, #client-business-profile-content .business-profile .box-tab .form-group-multi-language .group-body .language textarea.form-control {
  padding: 10px 15px;
  width: 100%;
  border: none;
  background-color: #FFFFFF;
  outline: 0;
}

#client-business-profile-content .business-profile .box-tab .form-group-multi-language .group-body .language textarea.form-control {
  min-height: 180px;
}

#client-business-profile-content .business-profile .box-tab .form-group-multi-language .group-body .language.active {
  display: block;
}

#client-business-profile-content .business-profile .box-tab .form-group-multi-language.form-skill .group-body .language {
  padding: 0;
}

#client-business-profile-content .business-profile .box-tab .legend {
  color: #a9a9a9;
}

#client-business-profile-content .business-profile .box-tab .legend b {
  color: #585858;
  font-weight: bold;
}

#client-business-profile-content .business-profile .box-button {
  position: relative;
  margin-bottom: 20px;
}

#client-business-profile-content .business-profile .box-button button {
  border: none;
  position: relative;
  display: block;
  width: 148px;
  height: 45px;
  padding-bottom: 3px;
  border-radius: 2px;
  font-size: 14px;
  line-height: 42px;
  text-align: center;
  box-shadow: none;
  overflow: hidden;
}

#client-business-profile-content .business-profile .box-button button span {
  position: relative;
  z-index: 2;
}

#client-business-profile-content .business-profile .box-button button.green {
  background-color: #02cd8d;
  color: #FFFFFF;
}

#client-business-profile-content .business-profile .box-button button.green:after {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 3px;
  background-color: #1a785b;
  transition: height .3s;
  z-index: 1;
}

#client-business-profile-content .business-profile .box-button button.green:hover:after {
  height: 100%;
}

#client-business-profile-content .business-profile .box-button button.blue {
  background-color: #3f92f1;
  color: #FFFFFF;
}

#client-business-profile-content .business-profile .box-button button.blue:after {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 3px;
  background-color: #1a4474;
  transition: height .3s;
  z-index: 1;
}

#client-business-profile-content .business-profile .box-button button.blue:hover:after {
  height: 100%;
}

#client-business-profile-content .business-profile .box-button button:not(:first-child) {
  margin-top: 20px;
}

#client-business-profile-content .business-profile .box-button .helper {
  text-align: right;
  color: #a5a5a5;
  font-size: 11px;
  padding: 15px 0;
}

#client-business-profile-content .business-profile .box-button .back {
  color: #02cd8d;
  position: absolute;
  bottom: 45px;
  line-height: 45px;
  left: 0;
  text-decoration: none;
}

#client-business-profile-content .business-profile .box-button .back:hover {
  text-decoration: none;
}

#client-business-profile-content .business-profile .box-button.portfolio {
  position: inherit;
}

#client-business-profile-content .business-profile .box-button.portfolio .back {
  position: inherit;
}

#client-business-profile-content .box-left {
  border: 1px solid #000000;
  border-radius: 3px;
}

#client-business-profile-content .box-left .box-body {
  padding: 10px 15px;
}

#client-business-profile-content .box-left .box-body .legend {
  color: #000;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0;
}

#client-business-profile-content .box-left .box-body ul.checks {
  list-style: none;
  padding: 0;
  margin: 10px 0 25px 0;
}

#client-business-profile-content .box-left .box-body ul.checks li {
  padding-left: 35px;
  position: relative;
  color: #000;
  font-size: 14px;
  font-weight: bold;
}

#client-business-profile-content .box-left .box-body ul.checks li:before {
  content: '';
  display: block;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 2px;
  background-color: #dddddd;
}

#client-business-profile-content .box-left .box-body ul.checks li:not(:first-child) {
  margin-top: 10px;
}

#client-business-profile-content .box-left .box-body ul.links {
  list-style: none;
  margiN: 10px 0 0 0;
  padding: 0;
}

#client-business-profile-content .box-left .box-body ul.links li a {
  display: block;
  margin-bottom: 3px;
  color: #02cd8d;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
}

#client-business-profile-content .box {
  border: 1px solid #d4f0e3;
  margin-bottom: 20px;
}

#client-business-profile-content .box .box-heading {
  padding: 25px 25px;
  background-color: #ccd9e0;
  color: #585858;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: bold;
}

#client-business-profile-content .box .box-body {
  padding: 15px 25px;
  background-color: #FFFFFF;
  font-family: "Roboto", sans-serif;
}

#client-business-profile-content .box .box-body:not(:first-child) {
  border-top: 1px solid #d4f0e3;
}

#client-business-profile-content .box .box-footer {
  border-top: 1px solid #d4f0e3;
  padding: 15px 25px;
  background-color: #FFFFFF;
}

#client-business-profile-content .radios input[type=radio] {
  visibility: hidden;
  opacity: 0;
}

#client-business-profile-content .radios label {
  display: block;
  margin: 0;
  font-size: 12px;
  cursor: pointer;
}

#client-business-profile-content .radios label:not(:first-child) {
  margin-top: 10px;
}

#client-business-profile-content .radios label span {
  display: inline-block;
  vertical-align: middle;
}

#client-business-profile-content .radios label span.radio {
  border: 2px solid #d4f0e3;
  background-color: #d8f1e6;
  border-radius: 50%;
  margin: 0 10px 0 0;
  width: 20px;
  height: 20px;
  position: relative;
  transition: background-color .3s;
}

#client-business-profile-content .radios label span.radio input {
  visibility: hidden;
  opacity: 0;
}

#client-business-profile-content .radios label.checked span.radio {
  background-color: #02cd8d;
}

#client-business-profile-content .radios input[type=text] {
  display: inline-block;
  width: auto;
}

#client-business-profile-content .checkboxes input[type=checkbox] {
  visibility: hidden;
  opacity: 0;
}

#client-business-profile-content .checkboxes label {
  display: block;
  margin: 0 0 10px;
  font-size: 12px;
  cursor: pointer;
}

#client-business-profile-content .checkboxes label span {
  display: inline-block;
  vertical-align: middle;
}

#client-business-profile-content .checkboxes label span.checkbox {
  border: 1px solid #d4f0e3;
  background-color: #d8f1e6;
  border-radius: 2px;
  margin: 0 10px 0 0;
  width: 20px;
  height: 20px;
  position: relative;
  transition: background-color .3s;
}

#client-business-profile-content .checkboxes label span.checkbox input {
  visibility: hidden;
  opacity: 0;
}

#client-business-profile-content .checkboxes label.checked span.checkbox {
  background-color: #FFFFFF;
  border: 1px solid #02cd8d;
}

#client-business-profile-content .checkboxes label.checked span.checkbox:before {
  top: 50%;
  left: 50%;
  content: '';
  position: absolute;
  height: 6px;
  width: 15px;
  border-left: 2px solid #02cd8d;
  border-bottom: 2px solid #02cd8d;
  display: block;
  margin: 0 auto;
  transform: translate(-50%, -55%) rotate(-45deg);
  -webkit-transform: translate(-50%, -55%) rotate(-45deg);
  -moz-transform: translate(-50%, -55%) rotate(-45deg);
  -ms-transform: translate(-50%, -55%) rotate(-45deg);
  -o-transform: translate(-50%, -55%) rotate(-45deg);
}

#client-business-profile-content .checkboxes label:hover:not(.checked) span.checkbox {
  background-color: #d4f0e3;
}

#client-business-profile-content .checkboxes label:hover.checked span.checkbox {
  background-color: #d8f1e6;
}

#client-business-profile-content .checkboxes label:hover.checked span.checkbox:before {
  border-left: 2px solid #02cd8d;
  border-bottom: 2px solid #02cd8d;
}

#client-business-profile-content .checkboxes input[type=text] {
  display: inline-block;
  width: auto;
}

#client-business-profile-content .checkboxes .checkbox-group {
  margin-bottom: 10px;
}

#client-business-profile-content .checkboxes .checkbox-group .checkbox-group {
  padding-left: 35px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s .5s, opacity .5s linear;
  height: 0;
  overflow: hidden;
  margin-bottom: 0;
}

#client-business-profile-content .checkboxes .checkbox-group .checkbox-group.active {
  margin-bottom: 10px;
  overflow: visible;
  height: auto;
  visibility: visible;
  opacity: 1;
  transition: opacity .5s linear;
}

#client-business-profile-content .checkboxes .checkbox-group label {
  margin-bottom: 0;
}

#client-business-profile-content .checkboxes .checkbox-group .extend {
  display: inline-block;
  margin-left: 10px;
  color: #02cd8d;
  font-size: 24px;
  font-weight: bold;
}

#client-business-profile-content .form-location {
  background-color: #d4f0e3;
  padding: 20px 20px 10px;
  margin-right: -25px;
  margin-bottom: 20px;
}

#client-business-profile-content .form-location .location-group {
  margin-bottom: 10px;
}

#client-business-profile-content .form-location .location-group .label-control {
  color: #585858;
  font-size: 14px;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  line-height: 1.5;
  padding: 10px 0;
}

#client-business-profile-content .form-location .location-group input.form-control,
#client-business-profile-content .form-location .location-group textarea.form-control,
#client-business-profile-content .form-location .location-group select.form-control {
  border: 1px solid #e1e3e3;
  border-radius: 4px;
  padding: 10px 20px;
  line-height: 1;
  outline: 0;
  font-size: 14px;
  height: auto;
  color: #585858;
  box-shadow: none;
  font-family: "Roboto", sans-serif;
  background-color: #FFFFFF;
  font-weight: normal;
}

#client-business-profile-content .form-location .location-group input.form-control:focus,
#client-business-profile-content .form-location .location-group textarea.form-control:focus,
#client-business-profile-content .form-location .location-group select.form-control:focus {
  box-shadow: none;
  border: 1px solid #e1e3e3;
  outline: 0;
}

#client-business-profile-content .form-location .location-group input.form-control:disabled,
#client-business-profile-content .form-location .location-group textarea.form-control:disabled,
#client-business-profile-content .form-location .location-group select.form-control:disabled {
  background-color: #e1e3e3;
}

#client-business-profile-content .form-location .location-group select.form-control,
#client-business-profile-content .form-location .location-group input.form-control {
  height: 41px;
}

#client-business-profile-content .form-location .location-group textarea.form-control {
  min-height: 180px;
}

#client-business-profile-content .button_add {
  color: #02cd8d;
  cursor: pointer;
  display: inline-block;
}

#client-business-profile-content .has-error {
  border: 1px solid red !important;
}

#client-business-profile-content .portfolio-auto-upload-step-1 {
  background-color: #ffffff;
  font-family: Roboto;
  padding: 15px 25px;
  margin-bottom: 25px;
}

#client-business-profile-content .portfolio-auto-upload-step-1 .title {
  font-size: 16px;
}

#client-business-profile-content .portfolio-auto-upload-step-1 .description {
  font-size: 14px;
  font-style: italic;
  padding-bottom: 15px;
}

#client-business-profile-content .portfolio-auto-upload-step-1 .url input[type=text] {
  padding: 15px 20px;
  font-size: 14px;
  background-color: #f0f4f7;
  margin-right: 10px;
  border: none;
  border-radius: 2px;
  width: 80%;
}

#client-business-profile-content .portfolio-auto-upload-step-1 .url .btn-type-1 {
  background-color: #02cd8d;
  padding: 15px 55px;
  width: 18%;
  border-radius: 2px;
}

#client-business-profile-content .portfolio-auto-upload-step-1 .note {
  padding: 10px;
  color: #02cd8d;
  font-size: 14px;
  font-weight: bold;
}

#client-business-profile-content .portfolio-auto-upload-step-2 {
  background-color: #ffffff;
  font-family: Roboto;
  padding: 15px 25px;
  margin-bottom: 25px;
}

#client-business-profile-content .portfolio-auto-upload-step-2 .title {
  font-size: 16px;
  padding-bottom: 20px;
}

#client-business-profile-content .portfolio-auto-upload-step-2 .description {
  font-size: 14px;
  padding-bottom: 15px;
}

#client-business-profile-content .portfolio-auto-upload-step-2 .link {
  margin-bottom: 10px;
  position: relative;
}

#client-business-profile-content .portfolio-auto-upload-step-2 .link a {
  background-color: #f0f4f7;
  padding: 15px 20px;
  display: block;
  width: 80%;
  font-size: 14px;
  text-decoration: none;
  color: #18293e;
  word-break: break-all;
}

#client-business-profile-content .portfolio-auto-upload-step-2 .link a:hover {
  text-decoration: underline;
}

#client-business-profile-content .portfolio-auto-upload-step-2 .link label {
  position: absolute;
  font-size: 16px;
  top: 10px;
  left: 85%;
  transform: translateX(-85%);
  display: block;
  margin: 0 0 10px;
  padding-left: 30px;
  cursor: pointer;
  font-weight: normal;
}

#client-business-profile-content .portfolio-auto-upload-step-2 .link label span {
  display: inline-block;
  vertical-align: middle;
}

#client-business-profile-content .portfolio-auto-upload-step-2 .link label span.checkbox {
  background-color: #f0f4f7;
  border-radius: 2px;
  margin: 0 10px 0 0;
  width: 30px;
  height: 30px;
  transition: background-color .3s;
}

#client-business-profile-content .portfolio-auto-upload-step-2 .link label span.checkbox input {
  visibility: hidden;
  opacity: 0;
}

#client-business-profile-content .portfolio-auto-upload-step-2 .link label.checked span.checkbox {
  background-color: #f0f4f7;
  border: 1px solid #f0f4f7;
}

#client-business-profile-content .portfolio-auto-upload-step-2 .link label.checked span.checkbox:before {
  top: 50%;
  left: 50%;
  content: '';
  position: absolute;
  height: 9px;
  width: 18px;
  border-left: 2px solid #02cd8d;
  border-bottom: 2px solid #02cd8d;
  display: block;
  margin: 0 auto;
  transform: translate(-50%, -55%) rotate(-45deg);
  -webkit-transform: translate(-50%, -55%) rotate(-45deg);
  -moz-transform: translate(-50%, -55%) rotate(-45deg);
  -ms-transform: translate(-50%, -55%) rotate(-45deg);
  -o-transform: translate(-50%, -55%) rotate(-45deg);
}

#client-business-profile-content .portfolio-auto-upload-step-2 .link label:hover:not(.checked) span.checkbox, #client-business-profile-content .portfolio-auto-upload-step-2 .link label:hover:not(.disabled) span.checkbox {
  background-color: #d4f0e3;
}

#client-business-profile-content .portfolio-auto-upload-step-2 .link label:hover.checked:not(.disabled) span.checkbox {
  background-color: #d8f1e6;
}

#client-business-profile-content .portfolio-auto-upload-step-2 .link label:hover.checked:not(.disabled) span.checkbox:before {
  border-left: 2px solid #02cd8d;
  border-bottom: 2px solid #02cd8d;
}

#client-business-profile-content .portfolio-auto-upload-step-2 .button {
  padding-right: 20%;
  padding-top: 10px;
}

#client-business-profile-content .portfolio-auto-upload-step-2 .button button {
  border: none;
}

#client-business-profile-content .portfolio-auto-upload-step-2 .button button.cancel {
  background-color: #f0f4f7;
  color: #02cd8d;
  padding: 15px 55px;
  font-size: 16px;
  border-radius: 2px;
  cursor: pointer;
}

#client-business-profile-content .portfolio-auto-upload-step-2 .button button.confirm {
  background-color: #02cd8d;
  color: #ffffff;
  padding: 15px 55px;
  font-size: 16px;
  border-radius: 2px;
  cursor: pointer;
}

#client-business-profile-preview {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #f4f4f4;
}

#client-business-profile-preview .preview-bar {
  text-align: right;
  padding-bottom: 30px;
}

#client-business-profile-preview .preview-bar > * {
  display: inline-block;
}

#client-business-profile-preview .preview-bar > a {
  color: #585858;
  border: 1px solid #585858;
  border-radius: 2px;
  background-color: transparent;
  font-size: 12px;
  line-height: 40px;
  height: 40px;
  padding: 0 20px;
  margin-left: 15px;
  text-decoration: none;
}

#client-business-profile-preview .preview-bar > a:hover {
  text-decoration: none;
}

#client-business-profile-preview .preview-bar > .status {
  color: #585858;
  line-height: 42px;
  font-size: 12px;
}

#client-business-profile-preview .preview-bar > .status strong {
  font-weight: bold;
  display: inline-block;
  margin-right: 10px;
}

#client-business-profile-preview .container-row {
  display: table;
}

#client-business-profile-preview .container-row > div {
  display: table-cell;
  vertical-align: top;
}

#client-business-profile-preview #professional-profile-left {
  width: 30%;
  border-radius: 4px 0 0 4px;
  background-color: #fafafa;
  box-shadow: 0px 3px 7px 1px #c3c3c3;
}

#client-business-profile-preview #professional-profile-left .box {
  padding: 20px 27px;
}

#client-business-profile-preview #professional-profile-left .box:not(:first-child) {
  border-top: 1px solid #d9dddf;
}

#client-business-profile-preview #professional-profile-left .box.box-header .title {
  color: #444444;
  font-size: 20px;
  line-height: 1.3;
  font-weight: bold;
}

#client-business-profile-preview #professional-profile-left .box.box-header .tagline {
  color: #545454;
  font-size: 13px;
  font-weight: bold;
  display: inline-block;
  width: 100%;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

#client-business-profile-preview #professional-profile-left .box.box-header .ratings {
  padding-top: 15px;
  padding-bottom: 15px;
  display: table;
}

#client-business-profile-preview #professional-profile-left .box.box-header .ratings .col-circle {
  display: table-cell;
  width: 85px;
  padding-left: 20px;
}

#client-business-profile-preview #professional-profile-left .box.box-header .ratings .col-circle .circles-text {
  font-size: 16px !important;
  color: #888888 !important;
}

#client-business-profile-preview #professional-profile-left .box.box-header .ratings .col-circle .circles-text b {
  color: #444444 !important;
}

#client-business-profile-preview #professional-profile-left .box.box-header .ratings .col-review {
  display: table-cell;
  vertical-align: middle;
  padding-left: 10px;
  color: #02cd8d;
  font-size: 12px;
}

#client-business-profile-preview #professional-profile-left .box.box-header .ratings .col-review .fa {
  color: #373f48;
  font-size: 13px;
  display: inline-block;
  margin-left: 5px;
}

#client-business-profile-preview #professional-profile-left .box.box-header .btn {
  background-color: #02cd8d;
  color: #FFFFFF;
  display: block;
  width: 100%;
  max-width: 320px;
  border-radius: 4px;
  border-bottom: 3px solid #056648;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  font-size: 16px;
  padding: 10px;
}

#client-business-profile-preview #professional-profile-left .box .type {
  font-family: "Roboto", sans-serif;
  padding-left: 35px;
  color: #444444;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
}

#client-business-profile-preview #professional-profile-left .box .type .icon {
  width: 18px;
  height: 18px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  left: 0;
  top: 0;
}

#client-business-profile-preview #professional-profile-left .box .type span {
  font-weight: normal;
  text-transform: none;
}

#client-business-profile-preview #professional-profile-left .box .type span a {
  color: #02cd8d;
}

#client-business-profile-preview #professional-profile-left .box .type:not(:first-child) {
  margin-top: 20px;
}

#client-business-profile-preview #professional-profile-left .box .type.type-location {
  width: 400px;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#client-business-profile-preview #professional-profile-left .box .offices .office .type:not(:first-child) {
  margin-top: 10px;
}

#client-business-profile-preview #professional-profile-left .box .offices .office:not(:first-child) {
  margin-top: 15px;
}

#client-business-profile-preview #professional-profile-left .box .services {
  padding-left: 35px;
  margin-left: -8px;
  margin-top: 8px;
}

#client-business-profile-preview #professional-profile-left .box .services .service {
  margin-left: 8px;
  margin-bottom: 8px;
  background-color: #505961;
  display: inline-block;
  padding: 3px 8px;
  border-radius: 2px;
  color: rgba(255, 255, 255, 0.75);
}

#client-business-profile-preview #professional-profile-left .box .top-description {
  background-color: #e8fff8;
  padding: 20px;
  border: 2px solid #00e7ad;
  text-align: center;
  margin-bottom: 20px;
  position: relative;
}

#client-business-profile-preview #professional-profile-left .box .top-description:before {
  content: "";
  position: absolute;
  bottom: -10px;
  left: calc(50% - 9px);
  width: 18px;
  height: 18px;
  border-bottom: 2px solid #00e7ad;
  transform: rotate(45deg);
  background: #e8fff8;
  border-right: 2px solid #00e7ad;
}

#client-business-profile-preview #professional-profile-left .box .top-description .title {
  display: inline-flex;
}

#client-business-profile-preview #professional-profile-left .box .top-description .title .svg-menu {
  width: 25px;
  height: 25px;
  margin-right: 5px;
}

#client-business-profile-preview #professional-profile-left .box .top-description .title span {
  font-size: 18px;
  line-height: 25px;
}

#client-business-profile-preview #professional-profile-left .box .btn-1 {
  text-align: left;
  color: #18293e;
  font-weight: bold;
  background-color: #00e7ad;
  width: 100%;
  padding: 15px 20px;
  display: block;
  font-size: 18px;
  margin-bottom: 20px;
  text-decoration: none;
}

#client-business-profile-preview #professional-profile-left .box .btn-1.disabled {
  pointer-events: none;
  opacity: 0.4;
}

#client-business-profile-preview #professional-profile-left .box .btn-1:hover {
  background-color: #02cd8d;
}

#client-business-profile-preview #professional-profile-left .box .btn-1 span .fa {
  padding-right: 20px;
}

#client-business-profile-preview #professional-profile-left .box .other-description span {
  font-weight: bold;
  font-size: 18px;
}

#client-business-profile-preview #professional-profile-left .box .other-description span .fa {
  color: #00e7ad;
  padding-right: 10px;
}

#client-business-profile-preview #professional-profile-right {
  width: 70%;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 8px 1px #c3c3c3;
}

#client-business-profile-preview #professional-profile-right #professional-profile-header {
  background-color: #f7f7f9;
  position: relative;
}

#client-business-profile-preview #professional-profile-right #professional-profile-header .box {
  height: 280px;
  padding-bottom: 20px;
  background-size: cover;
  background-position: bottom center;
  position: relative;
}

#client-business-profile-preview #professional-profile-right #professional-profile-header .box .table-row {
  display: block;
  width: 100%;
  position: absolute;
  bottom: 0;
}

#client-business-profile-preview #professional-profile-right #professional-profile-header .box .table-row [class^=col] {
  padding-left: 25px;
  padding-right: 25px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-header .box .table-row .col-left {
  width: 125px;
  position: absolute;
  left: 0;
  bottom: 20px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-header .box .table-row .col-left .img-wrapper {
  width: 80px;
  height: 80px;
  padding: 5px;
  border-radius: 5px;
  background-color: #FFFFFF;
}

#client-business-profile-preview #professional-profile-right #professional-profile-header .box .table-row .col-left .img-wrapper .image {
  width: 70px;
  height: 70px;
  background-position: center;
  background-size: cover;
}

#client-business-profile-preview #professional-profile-right #professional-profile-header .box .table-row .col-right {
  padding-bottom: 20px;
  padding-top: 20px;
  padding-left: 125px;
  vertical-align: middle;
  background: rgba(0, 0, 0, 0.5);
}

#client-business-profile-preview #professional-profile-right #professional-profile-header .box .table-row .col-right .name {
  font-size: 24px;
  font-family: "Roboto", sans-serif;
  color: #FFFFFF;
  font-weight: bold;
  margin: 0;
  letter-spacing: 1px;
  display: inline-block;
}

#client-business-profile-preview #professional-profile-right #professional-profile-header .box .table-row .col-right .name .fa {
  color: #28f1b1;
  padding-left: 5px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-header .box .table-row .col-right .info {
  display: inline-block;
}

#client-business-profile-preview #professional-profile-right #professional-profile-header .box .table-row .col-right .info .tag {
  display: inline-block;
  background-color: #02cd8d;
  color: #FFFFFF;
  font-size: 13px;
  padding: 5px 10px;
  margin-left: 20px;
  position: relative;
  border-radius: 4px 0 0 4px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-header .box .table-row .col-right .info .tag:not(:first-child) {
  margin-left: 30px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-header .box .table-row .col-right .info .tag:before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 15px 0 0;
  border-color: #02cd8d transparent transparent transparent;
  position: absolute;
  left: 100%;
  top: 0;
}

#client-business-profile-preview #professional-profile-right #professional-profile-header .box .table-row .col-right .info .tag:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 0 0 15px;
  border-color: transparent transparent transparent #02cd8d;
  position: absolute;
  left: 100%;
  bottom: 0;
}

#client-business-profile-preview #professional-profile-right #professional-profile-header .tab-header {
  background-color: #373f48;
  padding: 0 25px 1px;
  text-align: left;
  font-size: 0;
}

#client-business-profile-preview #professional-profile-right #professional-profile-header .tab-header .tab {
  text-decoration: none;
  padding: 15px 0 14px;
  color: #FFFFFF;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  border-bottom: 2px solid transparent;
  display: inline-block;
  margin: 0 10px;
  letter-spacing: 2px;
  cursor: pointer;
  background-color: #373f48;
  transition: border-bottom .3s, color .3s;
  position: relative;
}

#client-business-profile-preview #professional-profile-right #professional-profile-header .tab-header .tab.opened {
  color: #02cd8d;
  border-bottom: 2px solid #02cd8d;
}

#client-business-profile-preview #professional-profile-right #professional-profile-header .tab-header .tab.opened:after {
  content: '';
  display: block;
  position: absolute;
  top: 100%;
  margin-top: 2px;
  height: 1px;
  left: 0;
  right: 0;
  background-color: #FFFFFF;
}

#client-business-profile-preview #professional-profile-right #professional-profile-header .tab-header .tab:hover:not(.opened) {
  border-bottom: 2px solid #FFFFFF;
}

#client-business-profile-preview #professional-profile-right #professional-profile-header.is-sticky {
  position: fixed;
  top: 44px;
  bottom: auto;
  left: 0;
  right: 0;
  padding: 0;
  z-index: 1;
}

#client-business-profile-preview #professional-profile-right #professional-profile-header.is-sticky .box {
  padding: 10px 30px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-header.is-sticky .box .table-row .col-left {
  width: 80px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-header.is-sticky .box .table-row .col .name {
  margin-bottom: 0;
}

#client-business-profile-preview #professional-profile-right #professional-profile-header.is-sticky .box .table-row .col > div:not(.name) {
  display: none;
}

#client-business-profile-preview #professional-profile-right #professional-profile-header.is-sticky .box .table-row .col-right {
  display: none;
}

#client-business-profile-preview #professional-profile-right #professional-profile-header.is-sticky .tab-header {
  box-shadow: #d5d7d9 0 0 5px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-header:not(.is-sticky) + #profile-content {
  padding-top: 25px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-header .badges {
  position: absolute;
  top: -5px;
  right: 20px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-header .badges .svg-menu {
  float: left;
  display: inline;
  margin: 0 3px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-header .badges .svg-menu img {
  width: 40px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content {
  padding: 30px 50px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .review-page-title {
  padding: 12px 0;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .review-page-title .title {
  line-height: 42px;
  float: left;
  color: #333333;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .review-page-title .case {
  line-height: 42px;
  margin-left: 15px;
  float: left;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 15px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .review-page-title .case span {
  border-radius: 4px;
  padding: 8px 8px;
  line-height: 1;
  border: 1px solid #333;
  display: inline-block;
  margin-right: 5px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .review-page-title .btn-wrapper {
  float: right;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .review-page-title .btn-wrapper .btn {
  text-transform: uppercase;
  background-color: #02cd8d;
  color: #FFFFFF;
  display: block;
  width: 100%;
  max-width: 320px;
  border-radius: 2px;
  border-bottom: 3px solid #056648;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  font-size: 14px;
  padding: 5px 10px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .container {
  display: table;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .container > .row {
  margin: 0;
  display: table-row;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .container > .row > [class^=col] {
  display: table-cell;
  vertical-align: top;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .container .col-left {
  padding-right: 25px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .container .col-right {
  padding-left: 25px;
  width: 295px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .header-container {
  display: table;
  margin-bottom: 10px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .header-container .row {
  margin: 0;
  display: table-row;
  color: #333333;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: bold;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .header-container .row > [class^=col] {
  display: table-cell;
  vertical-align: top;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .header-container .col-left {
  padding-right: 25px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .header-container .col-right {
  padding-left: 25px;
  width: 295px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .header-container .name {
  display: inline-block;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .header-container .cases {
  margin-left: 15px;
  display: inline-block;
  font-weight: normal;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .header-container .cases span {
  display: inline-block;
  color: #444444;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  padding: 2px 6px;
  border: 1px solid #444444;
  border-radius: 2px;
  position: relative;
  top: -2px;
  margin-right: 5px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .header-container select {
  margin: 0 15px;
  display: inline-block;
  border: 1px solid #d4f0e3;
  background-color: #FFFFFF;
  padding: 4px 60px 4px 10px;
  position: relative;
  top: -4px;
  font-weight: normal;
  outline: 0;
  cursor: pointer;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .header-container button {
  letter-spacing: 0;
  color: #FFFFFF;
  border-bottom: 3px solid #0d513b;
  padding: 8px 15px;
  position: relative;
  top: -6px;
  font-size: 13px;
  line-height: normal;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box {
  background-color: #FFFFFF;
  border: 1px solid #f0f4f7;
  border-radius: 5px;
  margin-bottom: 20px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-heading {
  padding: 20px 25px;
  background-color: #f0f4f7;
  border-bottom: 1px solid #f0f4f7;
  color: #333333;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: bold;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-heading img {
  display: inline-block;
  margin-left: 10px;
  height: 20px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-heading .right {
  color: #aaaaaa;
  font-size: 12px;
  float: right;
  font-weight: normal;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body {
  padding: 20px 25px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .info-row {
  margin-bottom: 15px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .info-row .info-label {
  display: inline-block;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  color: #737373;
  font-size: 13px;
  line-height: normal;
  width: 80px;
  text-align: right;
  padding-right: 15px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .info-row .info-value {
  display: inline-block;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  color: #737373;
  font-size: 13px;
  line-height: normal;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .paragraph {
  line-height: 1.5;
  font-family: "Roboto", sans-serif;
  color: #555555;
  font-size: 13px;
  padding-bottom: 30px;
  position: relative;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .paragraph p {
  margin-bottom: 10px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .paragraph .showMore {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #029470;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  cursor: pointer;
  transition: all .3s;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .paragraph .showMore i {
  display: inline-block;
  margin-right: 5px;
  font-style: normal;
  font-size: 13px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .paragraph .showMore:hover {
  font-weight: bold;
  font-size: 13px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .paragraph .showMore:hover i {
  transition: all .3s;
  font-weight: bold;
  font-size: 16px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .col-item {
  border-radius: 5px;
  border: 1px solid #c7ffe4;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .col-item .name {
  border-bottom: 1px solid #c7ffe4;
  color: #444444;
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  padding: 15px 10px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .col-item .paragraph {
  padding: 15px 10px 55px 10px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .col-item .paragraph .showMore {
  left: 10px;
  bottom: 25px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .col-member {
  border: 1px solid #f0f4f7;
  padding: 15px 0;
  border-radius: 2px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .col-member .member-row {
  display: table;
  width: 100%;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .col-member .member-row .col-img {
  display: table-cell;
  width: 123px;
  padding-left: 50px;
  padding-right: 23px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .col-member .member-row .col-img img {
  max-width: 50px;
  max-height: 50px;
  border-radius: 50%;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .col-member .member-row .col-info {
  display: table-cell;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .col-member .member-row .col-info .name {
  color: #414141;
  font-family: "Roboto", sans-serif;
  margin-bottom: 5px;
  font-size: 16px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .col-member .member-row .col-info .position {
  font-size: 13px;
  color: #313131;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .col-member .histories {
  margin-top: 20px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .col-member .histories .history {
  padding-left: 50px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .col-member .histories .history .img {
  width: 16px;
  display: inline-block;
  margin-right: 10px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .col-member .histories .history .img img {
  max-height: 16px;
  max-width: 16px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .col-member .histories .history .history-name {
  display: inline-block;
  color: #868686;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .tabs .tab-header {
  margin-right: -10px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .tabs .tab-header .tab {
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
  background-color: #505961;
  border: 1px solid #505961;
  color: #FFFFFF;
  border-radius: 2px;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color .3s, border .3s, color .3s;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .tabs .tab-header .tab.opened {
  background-color: #FFFFFF;
  color: #02cd8d;
  border: 1px solid #02cd8d;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .tabs .tab-header .tab:hover:not(.opened) {
  background-color: #737373;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .tabs .tab-body {
  padding-top: 15px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .tabs .tab-body .tab {
  display: none;
  visibility: hidden;
  opacity: 0;
  transition: opacity .3s;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .tabs .tab-body .tab .title {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  color: #737373;
  font-size: 13px;
  line-height: normal;
  margin-bottom: 15px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .tabs .tab-body .tab .paragraph {
  padding-bottom: 0;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .tabs .tab-body .tab.opened {
  opacity: 1;
  visibility: visible;
  display: block;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .client-info {
  padding: 0 0 20px;
  color: #333333;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: normal;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .client-info strong {
  font-weight: bold;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .table-info {
  padding: 25px 0;
  margin-bottom: 25px;
  border: 1px solid #d8f1e6;
  display: table;
  width: 100%;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .table-info .table-row {
  display: table-row;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .table-info .table-row > [class^=col] {
  display: table-cell;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  color: #333333;
  vertical-align: top;
  width: 308px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .table-info .table-row > [class^=col].col-left .row {
  display: block;
  margin-left: -15px;
  margin-right: -15px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .table-info .table-row > [class^=col].col-left .black {
  margin-bottom: 20px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .table-info .table-row > [class^=col].col-left .with-icon {
  padding-top: 5px;
  padding-bottom: 5px;
  min-height: 30px;
  padding-left: 25px;
  position: relative;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .table-info .table-row > [class^=col].col-left .with-icon .icon {
  position: absolute;
  left: 0;
  top: 5px;
  width: 20px;
  height: 20px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .table-info .table-row > [class^=col].col-left .with-icon:not(:first-child) {
  margin-top: 5px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .table-info .table-row > [class^=col].col-left .with-icon:not(:first-child) .more {
  float: right;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .table-info .table-row > [class^=col].col-left .with-icon:not(:first-child) .tooltip_content {
  display: none;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .table-info .table-row > [class^=col].col-left .tag {
  border-radius: 2px;
  background-color: #444444;
  padding: 7px 14px 7px 35px;
  color: #FFFFFF;
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
  display: inline-block;
  position: relative;
  cursor: context-menu;
  margin-top: 10px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .table-info .table-row > [class^=col].col-left .tag:before {
  content: '';
  width: 14px;
  height: 8px;
  border-left: 2px solid #FFFFFF;
  border-bottom: 2px solid #FFFFFF;
  transform: rotate(-45deg);
  position: absolute;
  left: 10px;
  bottom: 16px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .table-info .table-row > [class^=col].col-middle {
  width: 270px;
  width: 270px;
  border-left: 1px solid #d4f0e3;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .table-info .table-row > [class^=col].col-middle .info-row:after {
  content: '';
  display: block;
  clear: both;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .table-info .table-row > [class^=col].col-middle .info-row .info-label {
  float: left;
  width: 122px;
  font-size: 11px;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  color: #333333;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .table-info .table-row > [class^=col].col-middle .info-row .info-value {
  float: left;
  position: relative;
  background-color: #dbdbdb;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .table-info .table-row > [class^=col].col-middle .info-row .info-value:after {
  content: '';
  display: block;
  clear: both;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .table-info .table-row > [class^=col].col-middle .info-row .info-value .value-column {
  width: 18px;
  height: 17px;
  background-color: transparent;
  display: inline-block;
  float: left;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .table-info .table-row > [class^=col].col-middle .info-row .info-value .value-column:not(:first-child) {
  border-left: 1px solid #FFFFFF;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .table-info .table-row > [class^=col].col-middle .info-row .info-value .value {
  height: 17px;
  background-color: #02cd8d;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .table-info .table-row > [class^=col].col-right {
  width: 150px;
  border-left: 1px solid #d4f0e3;
  text-align: center;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .table-info .table-row > [class^=col].col-right .circles-text {
  font-size: 24px !important;
  color: #888888 !important;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .table-info .table-row > [class^=col].col-right .circles-text b {
  color: #444444 !important;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .table-info .table-row > [class^=col] .black {
  font-size: 12px;
  font-weight: bold;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .summary {
  word-break: break-all;
  -ms-word-break: break-all;
  word-break: break-all;
  /* Non standard for WebKit */
  word-break: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  /*.title {
              margin-bottom: 15px;
            }
            .blue {
              color: $color-22;
              font-weight: 700;
              font-family: $font-1;
              font-size: 13px;
            }
            .black {
              color: $color-19;
              font-weight: 700;
              font-family: $font-1;
              font-size: 13px;
            }
            .paragraph {
              font-size: 13px;
              font-family: $font-1;
              color: $color-20;
              line-height: 1.5;
              font-weight: 700;
              padding-bottom: 20px;
            }
            .feedback {
              margin: 0 0 20px;
              border: 1px solid $color-16;
              -webkit-border-radius: 2px;
              -moz-border-radius: 2px;
              border-radius: 2px;
              padding: 15px 50px;
              background-color: $color-27;
              position: relative;
              &:before {
                content: '“';
                position: absolute;
                top: -10px;
                left: 10px;
                font-size: 70px;
                color: $color-29;
                font-weight: bold;
              }
              &:after {
                content: '”';
                position: absolute;
                bottom: -40px;
                right: 10px;
                font-size: 70px;
                color: $color-29;
                font-weight: bold;
              }
              .title {
                color: $color-28;
                margin-bottom: 15px;
                font-weight: 700;
                font-family: $font-1;
                font-size: 13px;
                font-style: italic;
              }
              .paragraph {
                color: $color-2;
                line-height: 1.5;
                font-size: 13px;
                font-family: $font-1;
                font-weight: 700;
                font-style: italic;
              }
            }*/
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .summary.active .content.content-hidden {
  display: block;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .summary .showMore {
  margin: 10px auto;
  padding: 10px;
  cursor: pointer;
  color: #02cd8d;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  letter-spacing: 1px;
  display: block;
  width: 220px;
  text-align: center;
  position: relative;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .summary .showMore:after {
  content: '';
  background-color: #02cd8d;
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  transition: width .3s;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .summary .showMore:hover:after {
  width: 100%;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .review-content .content {
  margin-bottom: 20px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .review-content .content > h4 {
  font-weight: bold;
  color: #3f92f1;
  font-size: 16px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .review-content .content b {
  font-size: 14px;
  color: #000;
  font-weight: bold;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .review-content .content blockquote {
  margin: 0 0 20px;
  border: 1px solid #d4f0e3;
  border-radius: 2px;
  padding: 15px 50px;
  background-color: #f0f4f7;
  position: relative;
  color: #888888;
  line-height: 1.5;
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: italic;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .review-content .content blockquote:before {
  content: '“';
  position: absolute;
  top: -10px;
  left: 10px;
  font-size: 70px;
  color: #d1d8de;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .review-content .content blockquote:after {
  font-family: "Roboto", sans-serif;
  content: '”';
  position: absolute;
  bottom: -40px;
  right: 10px;
  font-size: 70px;
  color: #d1d8de;
  font-weight: bold;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .review-content .content blockquote h4 {
  color: #5a5a5a;
  margin-bottom: 15px;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-style: italic;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .content.content-hidden {
  display: none;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.about .col {
  display: inline-block;
  margin-top: 15px;
  padding-left: 30px;
  padding-right: 30px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.about .col .value {
  color: #02cd8d;
  font-size: 36px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.about .col .label {
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  color: #494949;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.about .col .label .fa {
  font-size: 15px;
  display: inline-block;
  margin-left: 3px;
  color: #d9d9d9;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-green {
  border: 1px solid #c7ffe4;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-green .box-heading {
  background-color: #c7ffe4;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-positioning .positions .position {
  position: relative;
  padding-top: 20px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-positioning .positions .position .label {
  color: #3f3f3f;
  position: absolute;
  font-size: 13px;
  padding: 0;
  top: 0;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-positioning .positions .position .label.label-left {
  left: 0;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-positioning .positions .position .label.label-right {
  right: 0;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-positioning .positions .position .bar {
  position: relative;
  width: 100%;
  height: 10px;
  margin-bottom: 13px;
  background-color: #dddddd;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-positioning .positions .position .bar .pointer {
  width: 20px;
  height: 20px;
  position: absolute;
  top: -5px;
  background-color: #02cd8d;
  border-radius: 50%;
  transform: translateX(-50%);
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-positioning .positions .position:not(:first-child) {
  margin-top: 20px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-qualification .data:not(:first-child) {
  margin-top: 5px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-qualification .data a {
  border-radius: 2px;
  background: #505961;
  display: inline-block;
  padding: 5px 10px;
  margin-bottom: 10px;
  color: #d9dbdc;
  font-size: 12px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-qualification .data a:hover {
  text-decoration: none;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-qualification .data .collapse {
  padding-bottom: 15px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-portfolio .results {
  margin-left: -10px;
  margin-right: -10px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper {
  float: left;
  width: 33.33333%;
  padding: 0 10px 20px 10px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio {
  font-family: "roboto";
  text-decoration: none;
  box-shadow: 0 2px 2px 0 #D8D8D8;
  background-color: #fff;
  margin-bottom: 20px;
  border-radius: 5px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .portfolio-ajax-link {
  cursor: pointer;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .portfolio-wrapper-heading {
  padding-bottom: 75%;
  background-size: cover;
  background-position: 0 0;
  position: relative;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .portfolio-wrapper-heading .portfolio-wrapper-heading-title {
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  z-index: 10;
  color: #fff;
  font-size: 18px;
  line-height: 22px;
  width: 70%;
  padding-bottom: 15px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .lazy {
  position: relative;
  border-radius: 4px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .lazy::after {
  content: '';
  display: block;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("../../images/root/work/grid-grey-layer.png");
  background-repeat: repeat;
  background-size: 1px 190px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .lazy .location {
  z-index: 3;
  position: absolute;
  bottom: 8px;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .lazy .location .fa {
  color: #ffffff;
  padding-top: 5px;
  font-size: 18px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .lazy .location span {
  color: white;
  padding-left: 0px;
  font-size: 14px;
  text-transform: capitalize;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .bookmark {
  top: 0px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .bookmark .review .fa-bookmark {
  z-index: 3;
  color: #f5c343;
  position: absolute;
  font-size: 50px;
  transform: scaleX(0.8);
  top: -2%;
  right: 5%;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .bookmark .review .fa-star {
  z-index: 3;
  color: #ffffff;
  position: absolute;
  font-size: 20px;
  right: 8.5%;
  top: 2%;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .bookmark .feature {
  position: absolute;
  top: -5px;
  right: 5%;
  z-index: 3;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .content {
  padding: 0 10px 15px 15px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .content .title {
  color: #18293E;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
  line-height: 1.3;
  letter-spacing: 0;
  padding-bottom: 10px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .content .rate .fa {
  color: #f5c343;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .content .services {
  margin-left: -4px;
  margin-top: 4px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .content .services .service {
  margin-left: 4px;
  margin-bottom: 4px;
  background-color: #505961;
  display: inline-block;
  padding: 3px 8px;
  border-radius: 2px;
  color: rgba(255, 255, 255, 0.75);
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .content .services .service.hidden {
  display: none;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .content .divider {
  height: 1px;
  background-color: #edf1f3;
  margin-top: 10px;
  margin-bottom: 10px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .content .divider-short {
  height: 1px;
  width: 10px;
  background-color: #868686;
  margin-top: 10px;
  margin-bottom: 10px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .content .description {
  color: #737373;
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  line-height: 1.3;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .content .details .tags {
  color: #8b8b8b;
  font-size: 14px;
  padding-top: 10px;
  font-weight: 300;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .content .details .item-client-name {
  width: 65%;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .content .details .premium {
  width: 35%;
  text-align: right;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .content .details .premium span {
  color: #ffffff;
  background-color: #ee7781;
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 2px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .content .details .pro {
  width: 35%;
  text-align: right;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .content .details .pro span {
  color: #ffffff;
  background-color: #071c2f;
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 2px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .footer {
  background-color: #f9fafb;
  padding: 10px 50px 10px 15px;
  min-height: 46px;
  position: relative;
  border-radius: 5px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .footer .services {
  display: block;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .footer .services .service {
  margin-right: 4px;
  margin-bottom: 4px;
  background-color: #97a1b0;
  display: inline-block;
  padding: 3px 8px;
  border-radius: 2px;
  color: rgba(255, 255, 255, 0.75);
  font-weight: 300;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .footer .services .service.hidden {
  display: none;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .footer .col-favourite {
  position: absolute;
  right: 10px;
  top: 10px;
  display: block;
  text-decoration: none;
  cursor: pointer;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .footer .col-favourite .fa {
  color: #28f2b2;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .footer .col-favourite .fa-heart-o {
  display: block;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .footer .col-favourite .fa-heart {
  display: none;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .footer .col-favourite.un-favourite .fa-heart-o {
  display: none;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .footer .col-favourite.un-favourite .fa-heart {
  display: block;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-portfolio .results:after {
  content: '';
  display: table;
  clear: both;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-portfolio .wrapper-btn-load-result {
  padding: 50px 0;
  display: none;
  text-align: center;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-portfolio .wrapper-btn-load-result .btn-load-result {
  padding: 15px 35px;
  display: inline-block;
  color: #FFFFFF;
  background-color: #18d197;
  line-height: 25px;
  border-radius: 28px;
  cursor: pointer;
  text-decoration: none;
  font-size: 18px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-portfolio .wrapper-btn-load-result .btn-load-result:hover {
  background-color: #18e5aa;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-portfolio .wrapper-btn-load-result.show {
  display: block;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-service .tab-header {
  margin-left: -4px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-service .tab-header a {
  margin-left: 4px;
  margin-bottom: 4px;
  background-color: #505961;
  border: 1px solid #505961;
  display: inline-block;
  padding: 3px 8px;
  border-radius: 2px;
  color: rgba(255, 255, 255, 0.75);
  text-decoration: none;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-service .tab-header a.opened {
  background-color: #fff;
  border: 1px solid #02cd8d;
  color: #02cd8d;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-service .tab-content {
  min-height: 420px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-service .tab-content .service-content {
  display: none;
  padding-top: 20px;
  position: relative;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-service .tab-content .service-content .col-paragraph {
  position: relative;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-service .tab-content .service-content.opened {
  display: block;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-service .tab-content .chart-wrapper {
  position: relative;
  right: 0;
  top: 20px;
  padding-bottom: 400px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-service .tab-content .chart-wrapper .chart-legends {
  margin: 0 auto -50px;
  position: relative;
  z-index: 1;
  max-width: 380px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-service .tab-content .chart-wrapper .chart-legends .chart-legend {
  padding-left: 5px;
  padding-right: 5px;
  float: left;
  color: #565656;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-service .tab-content .chart-wrapper .chart-legends .chart-legend span {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin-right: 10px;
  display: inline-block;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-service .tab-content .chart-wrapper #serviceChart {
  position: absolute;
  bottom: 0;
  left: 0;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-skill .skills {
  margin-left: -4px;
  margin-top: 4px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-skill .skills .skill {
  margin-left: 4px;
  margin-bottom: 4px;
  background-color: #505961;
  display: inline-block;
  padding: 3px 8px;
  border-radius: 2px;
  color: rgba(255, 255, 255, 0.75);
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.green {
  background-color: #d8f1e6;
  border: 1px solid #FFFFFF;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.green .box-heading {
  border-bottom: 1px solid #FFFFFF;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.green .box-body {
  padding-left: 50px;
  padding-right: 50px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.green .box-body .paragraph {
  padding-bottom: 0;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-focus {
  margin-bottom: 20px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-focus .chart-title {
  color: #737373;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  text-align: center;
  font-weight: bold;
  z-index: 2;
  position: relative;
  margin-bottom: -40px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-focus .chart-legends {
  margin: 0 auto 0;
  position: relative;
  z-index: 1;
  max-width: 380px;
  text-align: center;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-focus .chart-legends .chart-legend {
  padding-left: 5px;
  padding-right: 5px;
  display: inline-block;
  color: #565656;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-focus .chart-legends .chart-legend span {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin-right: 10px;
  display: inline-block;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-faq .panel-group {
  margin: 0;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-faq .panel-group .panel {
  box-shadow: none;
  border-radius: 0;
  border: none;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-faq .panel-group .panel .panel-heading {
  background: none;
  border: none;
  color: #02cd8d;
  padding: 0;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-faq .panel-group .panel .panel-heading h4 {
  font-size: 16px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-faq .panel-group .panel .panel-heading h4 a {
  padding-top: 10px;
  padding-bottom: 10px;
  display: inline-block;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-faq .panel-group .panel .panel-heading h4 a:hover, #client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-faq .panel-group .panel .panel-heading h4 a:focus {
  text-decoration: none;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-faq .panel-group .panel .panel-heading + .panel-collapse > .panel-body {
  border-top: 0;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-faq .panel-group .panel .panel-body {
  padding: 10px 0 10px;
  line-height: 1.5;
  font-family: "Roboto", sans-serif;
  color: #737373;
  font-size: 13px;
  position: relative;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-faq .panel-group .panel .panel-body p {
  margin-bottom: 10px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-faq .panel-group .panel:not(:first-child) {
  border-top: 1px solid #d8f1e6;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box button {
  width: 100%;
  text-transform: uppercase;
  border-bottom: 6px solid #235a9a;
  letter-spacing: 1px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.colorful {
  position: relative;
  background-image: linear-gradient(to bottom right, #03cd8e, #7b93ba, #c86dd7);
  padding: 15px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.colorful .bg-white {
  background-color: #FFFFFF;
  padding: 20px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.colorful .bg-white span {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  letter-spacing: 1px;
  color: #02cd8d;
  margin-bottom: 10px;
  display: block;
  font-weight: bold;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.colorful .bg-white ul {
  display: block;
  padding: 0;
  margin: 0 0 15px;
  list-style: none;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.colorful .bg-white ul li {
  padding-top: 5px;
  padding-bottom: 5px;
  color: #888888;
  font-weight: 400;
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  letter-spacing: 1px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.colorful .bg-white ul li img {
  vertical-align: middle;
  display: inline-block;
  margin-right: 10px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box.colorful button {
  width: 100%;
  text-transform: uppercase;
  border-bottom: 6px solid #178462;
  letter-spacing: 1px;
}

#client-business-profile-preview #professional-profile-right #professional-profile-content .box:not(:first-child) {
  margin-top: 20px;
}

#client-business-profile-preview-submitted-modal {
  width: 596px;
  margin: 0 auto;
  border-radius: 4px;
  overflow: hidden;
  padding-bottom: 20px;
  background-color: #FFFFFF;
}

#client-business-profile-preview-submitted-modal .modal-heading {
  padding: 15px;
  text-align: center;
  background-color: #02cd8d;
  color: #fff;
  line-height: 1.3;
  font-size: 14px;
  font-weight: bold;
}

#client-business-profile-preview-submitted-modal .modal-body {
  padding: 40px 15px 45px;
  text-align: center;
  color: #3b3b3b;
  line-height: 1.5;
  font-size: 12px;
}

#client-business-profile-preview-submitted-modal .modal-body strong {
  color: #333;
}

#client-business-profile-preview-submitted-modal .modal-footer {
  padding: 0;
  text-align: center;
  border: none;
}

#client-business-profile-preview-submitted-modal .modal-footer .modal-close {
  padding: 15px;
  display: block;
  background-color: #ebebeb;
  color: #02cd8d;
  line-height: 1.5;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
}

@media only screen and (max-width: 991px) {
  #client-business-profile-content .business-profile .box-top .box-row .box-col.col-type .types {
    font-size: 0;
  }
  #client-business-profile-content .business-profile .box-top .box-row .box-col.col-type .types .type {
    width: 25%;
    font-size: 13px;
  }
  #client-business-profile-content .business-profile .box-top .box-row .box-col.col-type .types .arrow-next.arrow-1 {
    left: 25%;
  }
  #client-business-profile-content .business-profile .box-top .box-row .box-col.col-type .types .arrow-next.arrow-2 {
    left: 50%;
  }
  #client-business-profile-content .business-profile .box-top .box-row .box-col.col-type .types .arrow-next.arrow-3 {
    left: 75%;
  }
  #client-business-profile-content .business-profile .box-tab.service .box-body .service-group .row-option .box-service {
    padding: 0;
  }
  #client-business-profile-content .business-profile .box-tab.service .box-body .service-group .row-option .box-service .box-header {
    padding: 30px 0;
    font-size: 12px;
  }
  #client-business-profile-content .business-profile .box .box-body {
    width: 100%;
    padding: 30px;
  }
  #client-business-profile-preview {
    padding-top: 30px;
  }
  #client-business-profile-preview .container-row {
    display: block;
  }
  #client-business-profile-preview .container-row > div {
    display: block;
  }
  #client-business-profile-preview #professional-profile-left {
    width: 100%;
    float: none;
    margin-bottom: 30px;
    border-radius: 0;
  }
  #client-business-profile-preview #professional-profile-left .box:not(.box-header) {
    padding: 20px 27px;
    float: left;
    width: 50%;
  }
  #client-business-profile-preview #professional-profile-left .box .type.type-location {
    width: 100%;
  }
  #client-business-profile-preview #professional-profile-left:after {
    content: '';
    display: block;
    clear: both;
  }
  #client-business-profile-preview #professional-profile-right {
    width: 100%;
    float: none;
  }
  #client-business-profile-preview #professional-profile-right #professional-profile-header .tab-header {
    padding: 0 20px;
  }
  #client-business-profile-preview #professional-profile-right #professional-profile-content {
    padding: 20px;
  }
  #client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-service .tab-content .chart-wrapper #serviceChart {
    width: 100% !important;
    height: 260px !important;
  }
  #client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper {
    width: 50%;
  }
}

@media only screen and (max-width: 767px) {
  #client-business-profile-content .wrapper .container {
    padding-left: 0px;
    padding-right: 0px;
  }
  #client-business-profile-content .business-profile .text-right {
    text-align: center;
  }
  #client-business-profile-content .business-profile .box-top .box-row .box-col {
    display: block;
  }
  #client-business-profile-content .business-profile .box-top .box-row .box-col:not(:first-child) {
    border-top: 1px solid #e2e5e7;
    padding-top: 15px;
  }
  #client-business-profile-content .business-profile .box-top .box-row .box-col.col-progress {
    width: 100%;
  }
  #client-business-profile-content .business-profile .box-top .box-row .box-col.col-type .types .type {
    width: 25%;
  }
  #client-business-profile-content .business-profile .box-top .box-row .box-col.col-status {
    width: 100%;
    padding: 10px 30px;
  }
  #client-business-profile-content .business-profile .box .box-body {
    padding: 0 0 10px;
  }
  #client-business-profile-content .business-profile .box .box-body .form-group {
    margin-bottom: 25px;
  }
  #client-business-profile-content .business-profile .box .box-body .form-group.with-group .label-control {
    padding-top: 20px;
  }
  #client-business-profile-content .business-profile .box .box-body .form-group.form-target_market .irs-grid .irs-grid-text {
    font-size: 10px;
  }
  #client-business-profile-content .business-profile .box .box-body .form-group.form-target_market .irs-grid .js-grid-text-0 {
    left: 0% !important;
  }
  #client-business-profile-content .business-profile .box .box-body .form-group.form-target_market .irs-grid .js-grid-text-1 {
    display: none;
  }
  #client-business-profile-content .business-profile .box .box-body .form-group.form-target_market .irs-grid .js-grid-text-2 {
    left: 88% !important;
  }
  #client-business-profile-content .business-profile .box .box-body .upload-wrapper {
    position: relative;
  }
  #client-business-profile-content .business-profile .box .box-body .upload-wrapper .upload-preview + .upload-description {
    padding-left: 0px;
    padding-top: 180px;
  }
  #client-business-profile-content .business-profile .box .box-body .type-content.youtube .youtube-lists .youtube-row {
    padding-left: 90px;
    padding-right: 40px;
  }
  #client-business-profile-content .business-profile .box-button {
    padding: 0 15px 0 0;
  }
  #client-business-profile-content .business-profile .box-button .back {
    padding-left: 5px;
  }
  #client-business-profile-content .business-profile .box-tab.service .box-body {
    padding-right: 0;
  }
  #client-business-profile-content .business-profile .box-tab.service .box-body .service-group .row-option .box-service .box-header h5 {
    font-size: 10px;
  }
  #client-business-profile-content .business-profile .box-tab.service .box-body .service-group .box-selected {
    margin-bottom: 30px;
  }
  #client-business-profile-content .business-profile .box-tab.service .box-body .service-group .box-selected .box-body .col .item {
    font-size: 14px;
  }
  #client-business-profile-content .business-profile .box-tab .form-group-multi-language .group-body .language {
    padding: 0;
    display: none;
  }
  #client-business-profile-preview .container {
    padding-left: 0;
    padding-right: 0;
  }
  #client-business-profile-preview #professional-profile-left .box {
    padding: 10px 10px;
  }
  #client-business-profile-preview #professional-profile-left .box:not(.box-header) {
    float: none;
    width: 100%;
  }
  #client-business-profile-preview #professional-profile-left .box.box-header .btn-effect-1 {
    line-height: 35px;
    height: 38px;
    font-size: 14px;
  }
  #client-business-profile-preview #professional-profile-right #professional-profile-header .tab-header {
    width: 100%;
    display: table;
    padding: 0;
  }
  #client-business-profile-preview #professional-profile-right #professional-profile-header .tab-header .tab {
    padding: 10px 0;
    margin: 0;
    font-size: 12px;
    letter-spacing: normal;
    display: block;
    text-align: center;
    float: left;
    width: 50%;
  }
  #client-business-profile-preview #professional-profile-right #professional-profile-header .box .table-row {
    display: block;
    width: 100%;
    position: absolute;
    bottom: 0;
  }
  #client-business-profile-preview #professional-profile-right #professional-profile-header .box .table-row .col-right .info .tag {
    margin-bottom: 10px;
    margin-left: 10px;
  }
  #client-business-profile-preview #professional-profile-right #professional-profile-header .box .table-row .col-right .info .tag:not(:first-child) {
    margin-left: 20px;
  }
  #client-business-profile-preview #professional-profile-right #professional-profile-header .badges {
    right: 0;
    left: 85%;
    transform: translateX(-85%);
    display: inline-flex;
  }
  #client-business-profile-preview #professional-profile-right #professional-profile-header .badges .svg-menu img {
    width: 30px;
  }
  #client-business-profile-preview #professional-profile-right #professional-profile-content {
    padding: 0;
  }
  #client-business-profile-preview #professional-profile-right #professional-profile-content .box {
    margin-bottom: 0;
    border: none;
  }
  #client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-heading {
    padding: 15px 20px;
  }
  #client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .table-info {
    padding: 0;
  }
  #client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .table-info .table-row {
    display: block;
    width: 100%;
  }
  #client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .table-info .table-row [class^=col] {
    display: block;
    width: 100% !important;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  #client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .table-info .table-row [class^=col].col-right {
    border-left: none;
    border-top: 1px solid #d4f0e3;
  }
  #client-business-profile-preview #professional-profile-right #professional-profile-content .box .box-body .table-info .table-row [class^=col].col-middle .info-row .info-label {
    width: 50%;
    display: block;
  }
  #client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper {
    width: 100%;
    padding: 15px 20px 20px;
    border-bottom: 1px solid #dbdbdb;
  }
  #client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio {
    box-shadow: none;
  }
  #client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .portfolio-wrapper-heading {
    padding-bottom: 50%;
  }
  #client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .portfolio-wrapper-heading .portfolio-wrapper-heading-title {
    width: 65%;
    font-size: 14px;
    line-height: 14px;
  }
  #client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .lazy {
    width: 45%;
    display: block;
    float: left;
  }
  #client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .lazy .location {
    padding-left: 5px;
    padding-right: 0px;
  }
  #client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .lazy .location .pull-left {
    width: 65%;
  }
  #client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .lazy .location .fa {
    padding-top: 0;
  }
  #client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .lazy .location .fa.fa-map-marker {
    display: none;
  }
  #client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .lazy .location .fa.fa-youtube-play {
    padding-right: 5px;
  }
  #client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .lazy .location span {
    padding-left: 0px;
    font-size: 12px;
  }
  #client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .bookmark .feature {
    right: 3%;
  }
  #client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .bookmark .feature img {
    width: 20px;
  }
  #client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .content {
    width: 55%;
    display: block;
    float: left;
    font-size: 12px;
    padding: 0 0 0 10px;
  }
  #client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .content .title {
    margin-top: 0;
    font-size: 12px;
    line-height: 1.2;
  }
  #client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .content .details .item-client-name {
    font-size: 12px;
    line-height: 1.2;
  }
  #client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .content .details .tags {
    font-size: 12px;
    line-height: 1.2;
  }
  #client-business-profile-preview #professional-profile-right #professional-profile-content .box.box-portfolio .results .portfolio-wrapper .portfolio .footer {
    padding: 10px 50px 10px 0px;
    background-color: initial;
    display: block;
    float: left;
    width: 100%;
  }
  #client-business-profile-preview #professional-profile-right #professional-profile-content > .row {
    margin: 0;
  }
  #client-business-profile-preview #professional-profile-right #professional-profile-content .review-page-title {
    padding: 12px 20px;
  }
  #client-business-profile-preview #professional-profile-right #professional-profile-content .review-page-title .title {
    line-height: 25px;
    margin-bottom: 10px;
  }
}

#client-inbox-index-content {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #f0f4f7;
}

#client-inbox-index-content .box {
  border-radius: 4px;
  margin-bottom: 20px;
}

#client-inbox-index-content .box .box-header {
  padding-top: 25px;
}

#client-inbox-index-content .box .box-header .tab-header {
  cursor: pointer;
  padding: 0 0 17px;
  float: left;
  color: #999999;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: bold;
  border-bottom: 3px solid transparent;
  transition: border-bottom .3s;
}

#client-inbox-index-content .box .box-header .tab-header:not(:first-child) {
  margin-left: 25px;
}

#client-inbox-index-content .box .box-header .tab-header.active {
  border-bottom: 3px solid #02cd8d;
  color: #585858;
}

#client-inbox-index-content .box .box-header .tab-header:hover {
  text-decoration: none;
}

#client-inbox-index-content .box .box-header .filter-wrapper {
  padding: 0 0 20px;
  float: right;
  color: #999999;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}

#client-inbox-index-content .box .box-header .filter-wrapper .fa {
  display: inline-block;
  margin-left: 7px;
}

#client-inbox-index-content .box .box-body {
  border: 1px solid #d4f0e3;
  padding: 0 25px 15px;
  background-color: #FFFFFF;
  font-family: "Roboto", sans-serif;
}

#client-inbox-index-content .box .box-body .messages {
  display: table;
  width: 100%;
}

#client-inbox-index-content .box .box-body .messages .message {
  display: table-row;
  position: relative;
}

#client-inbox-index-content .box .box-body .messages .message > [class^=col] {
  display: table-cell;
  padding: 17px 17px;
  vertical-align: top;
}

#client-inbox-index-content .box .box-body .messages .message > [class^=col]:first-child {
  padding-left: 0;
}

#client-inbox-index-content .box .box-body .messages .message > [class^=col]:last-child {
  padding-right: 0;
}

#client-inbox-index-content .box .box-body .messages .message .col-logo {
  width: 82px;
}

#client-inbox-index-content .box .box-body .messages .message .col-logo img {
  max-width: 100%;
}

#client-inbox-index-content .box .box-body .messages .message .col-info {
  width: 150px;
  color: #585858;
}

#client-inbox-index-content .box .box-body .messages .message .col-info .name {
  font-weight: bold;
  margin-bottom: 5px;
}

#client-inbox-index-content .box .box-body .messages .message .col-info .name a {
  color: black;
  text-decoration: none;
}

#client-inbox-index-content .box .box-body .messages .message .col-details .title {
  font-weight: bold;
  margin-bottom: 5px;
}

#client-inbox-index-content .box .box-body .messages .message .col-action {
  vertical-align: middle;
  width: 130px;
  position: relative;
}

#client-inbox-index-content .box .box-body .messages .message .col-action a {
  border: 1px solid #585858;
  border-radius: 4px;
  color: #585858;
  display: inline-block;
  width: 100%;
  padding: 10px 15px;
  font-size: 12px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  position: relative;
}

#client-inbox-index-content .box .box-body .messages .message .col-action a:hover {
  text-decoration: none;
}

#client-inbox-index-content .box .box-body .messages .message .col-action .notification-dot {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: block;
  font-size: 0;
  background-color: #02cd8d;
}

#client-inbox-index-content .box .box-body .messages .message:not(:first-child) > [class^=col] {
  border-top: 2px solid #e9f7f1;
}

@media only screen and (max-width: 991px) {
  #client-inbox-index-content .box .box-body .messages .message > [class^=col]:not(:first-child) {
    display: block;
  }
  #client-inbox-index-content .box .box-body .messages .message > [class^=col]:not(:first-child).col-details {
    border-top: none;
  }
  #client-inbox-index-content .box .box-body .messages .message > [class^=col]:not(:first-child).col-action {
    border-top: none;
  }
  #client-inbox-index-content .box .box-body .messages .message .col-info {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  #client-inbox-index-content {
    padding-bottom: 0px;
  }
  #client-inbox-index-content .container {
    padding-left: 0;
    padding-right: 0;
  }
  #client-inbox-index-content .container .box .box-header {
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 0px;
  }
  #client-inbox-index-content .container .box .box-header .tab-header {
    font-size: 13px;
  }
  #client-inbox-index-content .container .box .box-body {
    padding: 0 15px 15px;
  }
}

#client-favorite-index-content {
  font-family: 'Roboto', sans-serif;
}

#client-favorite-index-content .header {
  padding: 40px 0;
  color: #18293e;
  background-color: #f7fafc;
}

#client-favorite-index-content .header .title {
  font-weight: 500;
  font-size: 20px;
}

#client-favorite-index-content .header .title span {
  font-weight: 300;
  font-size: 18px;
}

#client-favorite-index-content .header .description {
  font-weight: 300;
  font-size: 18px;
  padding-top: 20px;
}

#client-favorite-index-content .body {
  background-color: #f0f4f7;
  padding-top: 50px;
}

#client-favorite-index-content .body .tab-links {
  background-color: #FFFFFF;
  padding: 0;
}

#client-favorite-index-content .body .tab-links .link {
  color: #18293e;
  display: block;
  text-decoration: none;
  cursor: pointer;
  float: left;
  position: relative;
  margin-right: 20px;
  margin-left: 20px;
  padding: 20px 35px;
  font-size: 16px;
  font-weight: 500;
}

#client-favorite-index-content .body .tab-links .link.active {
  color: #18293e;
  font-weight: bold;
}

#client-favorite-index-content .body .tab-links .link.active::after {
  content: '';
  display: block;
  height: 4px;
  width: 55%;
  position: absolute;
  bottom: 0px;
  background-color: #28f2b2;
}

#client-favorite-index-content .body .tab-links .link:not(.active):hover {
  color: #333;
}

#client-favorite-index-content .body .tab-links::after {
  content: '';
  display: table;
  clear: both;
}

#client-favorite-index-content .body .results {
  margin-left: -10px;
  margin-right: -10px;
  padding-top: 20px;
}

#client-favorite-index-content .body .results .portfolio-wrapper {
  float: left;
  width: 20%;
  padding: 0 10px 20px 10px;
}

#client-favorite-index-content .body .results .portfolio-wrapper .portfolio {
  font-family: "roboto";
  text-decoration: none;
  box-shadow: 0 2px 2px 0 #D8D8D8;
  background-color: #fff;
  margin-bottom: 20px;
  border-radius: 5px;
}

#client-favorite-index-content .body .results .portfolio-wrapper .portfolio .portfolio-ajax-link {
  cursor: pointer;
}

#client-favorite-index-content .body .results .portfolio-wrapper .portfolio .portfolio-wrapper-heading {
  padding-bottom: 75%;
  background-size: cover;
  background-position: 0 0;
  position: relative;
  overflow: hidden;
}

#client-favorite-index-content .body .results .portfolio-wrapper .portfolio .portfolio-wrapper-heading .portfolio-wrapper-heading-title {
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  z-index: 10;
  color: #fff;
  font-size: 18px;
  line-height: 26px;
  width: 70%;
}

#client-favorite-index-content .body .results .portfolio-wrapper .portfolio .lazy {
  position: relative;
  border-radius: 4px;
}

#client-favorite-index-content .body .results .portfolio-wrapper .portfolio .lazy::after {
  content: '';
  display: block;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("../../images/root/work/grid-grey-layer.png");
  background-repeat: repeat;
  background-size: 1px 190px;
}

#client-favorite-index-content .body .results .portfolio-wrapper .portfolio .lazy .location {
  z-index: 3;
  position: absolute;
  bottom: 8px;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
}

#client-favorite-index-content .body .results .portfolio-wrapper .portfolio .lazy .location .fa {
  color: #ffffff;
  padding-top: 5px;
  font-size: 24px;
}

#client-favorite-index-content .body .results .portfolio-wrapper .portfolio .lazy .location span {
  color: white;
  padding-left: 5px;
  font-size: 14px;
  text-transform: capitalize;
}

#client-favorite-index-content .body .results .portfolio-wrapper .portfolio .bookmark {
  top: 0px;
}

#client-favorite-index-content .body .results .portfolio-wrapper .portfolio .bookmark .review .fa-bookmark {
  z-index: 3;
  color: #f5c343;
  position: absolute;
  font-size: 50px;
  transform: scaleX(0.8);
  top: -2%;
  right: 5%;
}

#client-favorite-index-content .body .results .portfolio-wrapper .portfolio .bookmark .review .fa-star {
  z-index: 3;
  color: #ffffff;
  position: absolute;
  font-size: 20px;
  right: 8.5%;
  top: 2%;
}

#client-favorite-index-content .body .results .portfolio-wrapper .portfolio .bookmark .feature {
  position: absolute;
  top: 0;
  right: 5%;
  z-index: 3;
}

#client-favorite-index-content .body .results .portfolio-wrapper .portfolio .content {
  padding: 0 10px 15px 15px;
}

#client-favorite-index-content .body .results .portfolio-wrapper .portfolio .content .title {
  color: #18293E;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
  line-height: 1.3;
  letter-spacing: 0;
  padding-bottom: 10px;
}

#client-favorite-index-content .body .results .portfolio-wrapper .portfolio .content .rate .fa {
  color: #f5c343;
}

#client-favorite-index-content .body .results .portfolio-wrapper .portfolio .content .services {
  margin-left: -4px;
  margin-top: 4px;
}

#client-favorite-index-content .body .results .portfolio-wrapper .portfolio .content .services .service {
  margin-left: 4px;
  margin-bottom: 4px;
  background-color: #505961;
  display: inline-block;
  padding: 3px 8px;
  border-radius: 2px;
  color: rgba(255, 255, 255, 0.75);
}

#client-favorite-index-content .body .results .portfolio-wrapper .portfolio .content .services .service.hidden {
  display: none;
}

#client-favorite-index-content .body .results .portfolio-wrapper .portfolio .content .divider {
  height: 1px;
  background-color: #edf1f3;
  margin-top: 10px;
  margin-bottom: 10px;
}

#client-favorite-index-content .body .results .portfolio-wrapper .portfolio .content .divider-short {
  height: 1px;
  width: 10px;
  background-color: #868686;
  margin-top: 10px;
  margin-bottom: 10px;
}

#client-favorite-index-content .body .results .portfolio-wrapper .portfolio .content .description {
  color: #737373;
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  line-height: 1.3;
}

#client-favorite-index-content .body .results .portfolio-wrapper .portfolio .content .details .tags {
  color: #8b8b8b;
  font-size: 14px;
  padding-top: 10px;
  font-weight: 300;
}

#client-favorite-index-content .body .results .portfolio-wrapper .portfolio .content .details .item-client-name {
  width: 65%;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
}

#client-favorite-index-content .body .results .portfolio-wrapper .portfolio .content .details .premium {
  width: 35%;
  text-align: right;
}

#client-favorite-index-content .body .results .portfolio-wrapper .portfolio .content .details .premium span {
  color: #ffffff;
  background-color: #ee7781;
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 2px;
}

#client-favorite-index-content .body .results .portfolio-wrapper .portfolio .content .details .pro {
  width: 35%;
  text-align: right;
}

#client-favorite-index-content .body .results .portfolio-wrapper .portfolio .content .details .pro span {
  color: #ffffff;
  background-color: #071c2f;
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 2px;
}

#client-favorite-index-content .body .results .portfolio-wrapper .portfolio .footer {
  background-color: #f9fafb;
  padding: 10px 50px 10px 15px;
  min-height: 46px;
  position: relative;
  border-radius: 5px;
}

#client-favorite-index-content .body .results .portfolio-wrapper .portfolio .footer .services {
  display: block;
}

#client-favorite-index-content .body .results .portfolio-wrapper .portfolio .footer .services .service {
  margin-right: 4px;
  margin-bottom: 4px;
  background-color: #97a1b0;
  display: inline-block;
  padding: 3px 8px;
  border-radius: 2px;
  color: rgba(255, 255, 255, 0.75);
  font-weight: 300;
}

#client-favorite-index-content .body .results .portfolio-wrapper .portfolio .footer .services .service.hidden {
  display: none;
}

#client-favorite-index-content .body .results .portfolio-wrapper .portfolio .footer .col-favourite {
  position: absolute;
  right: 10px;
  top: 10px;
  display: block;
  text-decoration: none;
  cursor: pointer;
}

#client-favorite-index-content .body .results .portfolio-wrapper .portfolio .footer .col-favourite .fa {
  color: #28f2b2;
}

#client-favorite-index-content .body .results .portfolio-wrapper .portfolio .footer .col-favourite .fa-heart-o {
  display: block;
}

#client-favorite-index-content .body .results .portfolio-wrapper .portfolio .footer .col-favourite .fa-heart {
  display: none;
}

#client-favorite-index-content .body .results .portfolio-wrapper .portfolio .footer .col-favourite.un-favourite .fa-heart-o {
  display: none;
}

#client-favorite-index-content .body .results .portfolio-wrapper .portfolio .footer .col-favourite.un-favourite .fa-heart {
  display: block;
}

#client-favorite-index-content .body .results:after {
  content: '';
  display: table;
  clear: both;
}

@media only screen and (max-width: 991px) {
  #client-favorite-index-content .header {
    text-align: center;
  }
  #client-favorite-index-content .body .results .portfolio-wrapper {
    width: 33.333333%;
  }
}

@media only screen and (max-width: 767px) {
  #client-favorite-index-content .header {
    padding: 15px 0 20px;
  }
  #client-favorite-index-content .header .title {
    font-weight: bold;
    font-size: 24px;
  }
  #client-favorite-index-content .header .title span {
    font-weight: 300;
    font-size: 18px;
  }
  #client-favorite-index-content .body .tab-links .link {
    margin-right: 0px;
    margin-left: 0px;
  }
  #client-favorite-index-content .body .results .portfolio-wrapper {
    width: 100%;
    padding: 15px 20px 20px;
    border-bottom: 1px solid #dbdbdb;
  }
  #client-favorite-index-content .body .results .portfolio-wrapper .portfolio {
    box-shadow: none;
  }
  #client-favorite-index-content .body .results .portfolio-wrapper .portfolio .portfolio-wrapper-heading {
    padding-bottom: 50%;
  }
  #client-favorite-index-content .body .results .portfolio-wrapper .portfolio .lazy {
    width: 40%;
    display: block;
    float: left;
  }
  #client-favorite-index-content .body .results .portfolio-wrapper .portfolio .lazy .location {
    padding-left: 5px;
    padding-right: 0px;
    width: 65%;
  }
  #client-favorite-index-content .body .results .portfolio-wrapper .portfolio .lazy .location .fa {
    display: none;
  }
  #client-favorite-index-content .body .results .portfolio-wrapper .portfolio .lazy .location span {
    padding-left: 0px;
    font-size: 12px;
  }
  #client-favorite-index-content .body .results .portfolio-wrapper .portfolio .bookmark .feature img {
    width: 25px;
  }
  #client-favorite-index-content .body .results .portfolio-wrapper .portfolio .content {
    width: 60%;
    display: block;
    float: left;
    font-size: 12px;
    padding: 0 0 0 15px;
  }
  #client-favorite-index-content .body .results .portfolio-wrapper .portfolio .content .title {
    margin-top: 0;
    font-size: 12px;
    line-height: 1.2;
  }
  #client-favorite-index-content .body .results .portfolio-wrapper .portfolio .content .details .item-client-name {
    font-size: 12px;
    line-height: 1.2;
  }
  #client-favorite-index-content .body .results .portfolio-wrapper .portfolio .content .details .tags {
    font-size: 12px;
    line-height: 1.2;
  }
  #client-favorite-index-content .body .results .portfolio-wrapper .portfolio .footer {
    padding: 10px 50px 10px 0px;
    background-color: initial;
    display: block;
    float: left;
    width: 100%;
  }
}

#client-request-index-content {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #f0f4f7;
}

#client-request-index-content .wrapper .wrapper-title {
  color: #333333;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 35px;
  letter-spacing: 1px;
}

#client-request-index-content .wrapper .tab-links ul {
  padding: 10px 0;
  margin: 0;
  list-style: none;
}

#client-request-index-content .wrapper .tab-links ul li {
  display: block;
}

#client-request-index-content .wrapper .tab-links ul li a {
  padding: 12px 19px;
  display: block;
  color: #888888;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  background-color: transparent;
  transition: background-color .3s, color .3s;
  cursor: pointer;
  position: relative;
}

#client-request-index-content .wrapper .tab-links ul li a.active {
  background-color: #FFFFFF;
  color: #585858;
}

#client-request-index-content .wrapper .tab-links ul li a:hover {
  text-decoration: none;
}

#client-request-index-content .wrapper .tab-links ul li a span {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  font-size: 0;
  background-color: #02cd8d;
  vertical-align: text-top;
  margin-left: 5px;
}

#client-request-index-content .wrapper .tab-links ul li:not(:first-child) {
  border-top: 2px solid #e2f2ed;
}

#client-request-index-content .wrapper .tab-links .btn {
  background-color: #02cd8d;
  color: #FFFFFF;
  display: block;
  width: 100%;
  border-radius: 4px;
  border-bottom: 3px solid #056648;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  font-size: 14px;
}

#client-request-index-content .wrapper .tab-coordinator {
  border: 1px solid #000;
  border-radius: 4px;
}

#client-request-index-content .wrapper .tab-coordinator .tab-title {
  padding: 10px;
  font-size: 16px;
}

#client-request-index-content .wrapper .tab-coordinator .info-row {
  padding: 0 10px 10px;
}

#client-request-index-content .wrapper .tab-coordinator .info-row img {
  display: inline-block;
  vertical-align: top;
  max-width: 90px;
  max-height: 90px;
}

#client-request-index-content .wrapper .tab-coordinator .info-row .info-right {
  display: inline-block;
  padding-left: 10px;
  width: 65%;
}

#client-request-index-content .wrapper .tab-coordinator .info-row .info-right .info {
  color: #000;
}

#client-request-index-content .wrapper .tab-coordinator .info-row .info-right .info.info-name {
  font-size: 16px;
  font-weight: bold;
}

#client-request-index-content .wrapper .tab-coordinator .info-row .info-right .info.info-tel {
  font-style: italic;
}

#client-request-index-content .wrapper .tab-coordinator .tab-footer {
  border-top: 1px solid #000;
  text-align: center;
  position: relative;
}

#client-request-index-content .wrapper .tab-coordinator .tab-footer img {
  position: absolute;
  left: 10%;
  top: 25%;
}

#client-request-index-content .wrapper .tab-coordinator .tab-footer a {
  display: block;
  color: #02cd8d;
  padding: 10px;
  font-size: 16px;
  text-decoration: none;
}

#client-request-index-content .wrapper .tab-body {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s .5s, opacity .5s linear;
  height: 0;
  overflow: hidden;
}

#client-request-index-content .wrapper .tab-body .tab-body-legend {
  font-size: 20px;
  color: #585858;
  line-height: 1;
  font-weight: bolder;
  margin-bottom: 20px;
  position: relative;
  padding-bottom: 12px;
  float: left;
}

#client-request-index-content .wrapper .tab-body .tab-body-legend:after {
  content: '';
  display: block;
  height: 2px;
  position: absolute;
  bottom: 0;
  width: 100px;
  left: 50%;
  background-color: #02cd8d;
  transform: translateX(-50%);
}

#client-request-index-content .wrapper .tab-body.active {
  overflow: visible;
  height: auto;
  visibility: visible;
  opacity: 1;
  transition: opacity .5s linear;
}

#client-request-index-content .wrapper .tab-body.requests_active .box-request, #client-request-index-content .wrapper .tab-body.requests_inactive .box-request {
  border-left: 1px solid #eaeaea;
  border-top: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 1px solid transparent;
  transition: border .3s;
  margin-bottom: 30px;
  position: relative;
  box-shadow: 1px 1px 5px 1px rgba(215, 216, 217, 0.75);
}

#client-request-index-content .wrapper .tab-body.requests_active .box-request:before, #client-request-index-content .wrapper .tab-body.requests_inactive .box-request:before {
  position: absolute;
  content: '';
  display: block;
  width: 5px;
  left: 0;
  top: -1px;
  bottom: -1px;
  background-color: #eaeaea;
}

#client-request-index-content .wrapper .tab-body.requests_active .box-request:hover, #client-request-index-content .wrapper .tab-body.requests_inactive .box-request:hover {
  border: 1px solid #02cd8d;
}

#client-request-index-content .wrapper .tab-body.requests_active .box-request.white-green, #client-request-index-content .wrapper .tab-body.requests_inactive .box-request.white-green {
  background-color: #FFFFFF;
  border-left: 1px solid #02cd8d;
}

#client-request-index-content .wrapper .tab-body.requests_active .box-request.white-green:hover, #client-request-index-content .wrapper .tab-body.requests_inactive .box-request.white-green:hover {
  border: 1px solid #02cd8d;
}

#client-request-index-content .wrapper .tab-body.requests_active .box-request.white-green:before, #client-request-index-content .wrapper .tab-body.requests_inactive .box-request.white-green:before {
  background-color: #02cd8d;
}

#client-request-index-content .wrapper .tab-body.requests_active .box-request.white-yellow, #client-request-index-content .wrapper .tab-body.requests_inactive .box-request.white-yellow {
  background-color: #FFFFFF;
  border-left: 1px solid #ffec00;
}

#client-request-index-content .wrapper .tab-body.requests_active .box-request.white-yellow:hover, #client-request-index-content .wrapper .tab-body.requests_inactive .box-request.white-yellow:hover {
  border: 1px solid #ffec00;
}

#client-request-index-content .wrapper .tab-body.requests_active .box-request.white-yellow:before, #client-request-index-content .wrapper .tab-body.requests_inactive .box-request.white-yellow:before {
  background-color: #ffec00;
}

#client-request-index-content .wrapper .tab-body.requests_active .box-request.dark-grey, #client-request-index-content .wrapper .tab-body.requests_inactive .box-request.dark-grey {
  background-color: #f0f4f7;
}

#client-request-index-content .wrapper .tab-body.requests_active .box-request.dark-grey:hover, #client-request-index-content .wrapper .tab-body.requests_inactive .box-request.dark-grey:hover {
  border: 1px solid #02cd8d;
}

#client-request-index-content .wrapper .tab-body.requests_active .box-request.dark-grey:before, #client-request-index-content .wrapper .tab-body.requests_inactive .box-request.dark-grey:before {
  top: 0;
  bottom: 0;
  background-color: #eaeaea;
}

#client-request-index-content .wrapper .tab-body.requests_active .box-request .box-body, #client-request-index-content .wrapper .tab-body.requests_inactive .box-request .box-body {
  padding-top: 30px;
  padding-bottom: 30px;
  min-height: 110px;
}

#client-request-index-content .wrapper .tab-body.requests_active .box-request .box-body .info-table, #client-request-index-content .wrapper .tab-body.requests_inactive .box-request .box-body .info-table {
  display: table;
  width: 100%;
}

#client-request-index-content .wrapper .tab-body.requests_active .box-request .box-body .info-table .info-row, #client-request-index-content .wrapper .tab-body.requests_inactive .box-request .box-body .info-table .info-row {
  display: table-row;
}

#client-request-index-content .wrapper .tab-body.requests_active .box-request .box-body .info-table .info-row > [class^=col], #client-request-index-content .wrapper .tab-body.requests_inactive .box-request .box-body .info-table .info-row > [class^=col] {
  display: table-cell;
  padding-left: 15px;
  padding-right: 15px;
}

#client-request-index-content .wrapper .tab-body.requests_active .box-request .box-body .info-table .info-row > [class^=col]:first-child, #client-request-index-content .wrapper .tab-body.requests_inactive .box-request .box-body .info-table .info-row > [class^=col]:first-child {
  padding-left: 30px;
}

#client-request-index-content .wrapper .tab-body.requests_active .box-request .box-body .info-table .info-row > [class^=col]:last-child, #client-request-index-content .wrapper .tab-body.requests_inactive .box-request .box-body .info-table .info-row > [class^=col]:last-child {
  padding-right: 30px;
}

#client-request-index-content .wrapper .tab-body.requests_active .box-request .box-body .info-table .info-row .col-main .status, #client-request-index-content .wrapper .tab-body.requests_inactive .box-request .box-body .info-table .info-row .col-main .status {
  color: #999999;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  margin-bottom: 20px;
  line-height: 1;
}

#client-request-index-content .wrapper .tab-body.requests_active .box-request .box-body .info-table .info-row .col-main .status span, #client-request-index-content .wrapper .tab-body.requests_inactive .box-request .box-body .info-table .info-row .col-main .status span {
  font-weight: bold;
  color: #000000;
}

#client-request-index-content .wrapper .tab-body.requests_active .box-request .box-body .info-table .info-row .col-main .title, #client-request-index-content .wrapper .tab-body.requests_inactive .box-request .box-body .info-table .info-row .col-main .title {
  color: #000000;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  margin-bottom: 10px;
  line-height: 1;
}

#client-request-index-content .wrapper .tab-body.requests_active .box-request .box-body .info-table .info-row .col-main .news, #client-request-index-content .wrapper .tab-body.requests_inactive .box-request .box-body .info-table .info-row .col-main .news {
  margin-left: -12px;
  margin-right: -12px;
}

#client-request-index-content .wrapper .tab-body.requests_active .box-request .box-body .info-table .info-row .col-main .news > div, #client-request-index-content .wrapper .tab-body.requests_inactive .box-request .box-body .info-table .info-row .col-main .news > div {
  float: left;
  padding-left: 12px;
  padding-right: 12px;
  color: #02cd8d;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  line-height: 1;
  vertical-align: middle;
  white-space: nowrap;
}

#client-request-index-content .wrapper .tab-body.requests_active .box-request .box-body .info-table .info-row .col-main .news > div a, #client-request-index-content .wrapper .tab-body.requests_inactive .box-request .box-body .info-table .info-row .col-main .news > div a {
  color: #02cd8d;
  text-decoration: none;
}

#client-request-index-content .wrapper .tab-body.requests_active .box-request .box-body .info-table .info-row .col-main .news > div a:hover, #client-request-index-content .wrapper .tab-body.requests_inactive .box-request .box-body .info-table .info-row .col-main .news > div a:hover {
  text-decoration: none;
}

#client-request-index-content .wrapper .tab-body.requests_active .box-request .box-body .info-table .info-row .col-main .news > div:before, #client-request-index-content .wrapper .tab-body.requests_inactive .box-request .box-body .info-table .info-row .col-main .news > div:before {
  content: '';
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: #02cd8d;
  border-radius: 50%;
  margin-right: 8px;
}

#client-request-index-content .wrapper .tab-body.requests_active .box-request .box-body .info-table .info-row .col-proposal, #client-request-index-content .wrapper .tab-body.requests_inactive .box-request .box-body .info-table .info-row .col-proposal {
  vertical-align: middle;
  color: #999999;
  width: 180px;
  font-size: 14px;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
}

#client-request-index-content .wrapper .tab-body.requests_active .box-request .box-body .info-table .info-row .col-proposal .green, #client-request-index-content .wrapper .tab-body.requests_inactive .box-request .box-body .info-table .info-row .col-proposal .green {
  font-size: 15px;
  color: #02cd8d;
}

#client-request-index-content .wrapper .tab-body.requests_active .box-request .box-body .info-table .info-row .col-chosen, #client-request-index-content .wrapper .tab-body.requests_inactive .box-request .box-body .info-table .info-row .col-chosen {
  width: 400px;
  font-weight: bold;
  color: #000000;
}

#client-request-index-content .wrapper .tab-body.requests_active .box-request .box-body .info-table .info-row .col-chosen a, #client-request-index-content .wrapper .tab-body.requests_inactive .box-request .box-body .info-table .info-row .col-chosen a {
  color: black;
  text-decoration: none;
}

#client-request-index-content .wrapper .tab-body.requests_active .box-request .box-body .info-table .info-row .col-chosen .news, #client-request-index-content .wrapper .tab-body.requests_inactive .box-request .box-body .info-table .info-row .col-chosen .news {
  margin-left: -12px;
  margin-right: -12px;
}

#client-request-index-content .wrapper .tab-body.requests_active .box-request .box-body .info-table .info-row .col-chosen .news > div, #client-request-index-content .wrapper .tab-body.requests_inactive .box-request .box-body .info-table .info-row .col-chosen .news > div {
  float: left;
  padding-left: 12px;
  padding-right: 12px;
  color: #02cd8d;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  line-height: 1;
  vertical-align: middle;
  white-space: nowrap;
}

#client-request-index-content .wrapper .tab-body.requests_active .box-request .box-body .info-table .info-row .col-chosen .news > div a, #client-request-index-content .wrapper .tab-body.requests_inactive .box-request .box-body .info-table .info-row .col-chosen .news > div a {
  color: #02cd8d;
  text-decoration: none;
}

#client-request-index-content .wrapper .tab-body.requests_active .box-request .box-body .info-table .info-row .col-chosen .news > div a:hover, #client-request-index-content .wrapper .tab-body.requests_inactive .box-request .box-body .info-table .info-row .col-chosen .news > div a:hover {
  text-decoration: none;
}

#client-request-index-content .wrapper .tab-body.requests_active .box-request .box-body .info-table .info-row .col-chosen .news > div:before, #client-request-index-content .wrapper .tab-body.requests_inactive .box-request .box-body .info-table .info-row .col-chosen .news > div:before {
  content: '';
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: #02cd8d;
  border-radius: 50%;
  margin-right: 8px;
}

#client-request-index-content .wrapper .tab-body.requests_active .box-request .box-body .info-table .info-row .col-action, #client-request-index-content .wrapper .tab-body.requests_inactive .box-request .box-body .info-table .info-row .col-action {
  vertical-align: middle;
  width: 200px;
}

#client-request-index-content .wrapper .tab-body.requests_active .box-request .box-body .info-table .info-row .col-action a, #client-request-index-content .wrapper .tab-body.requests_inactive .box-request .box-body .info-table .info-row .col-action a {
  border: 1px solid #585858;
  border-radius: 4px;
  color: #585858;
  display: inline-block;
  width: 100%;
  padding: 9px 0;
  font-size: 12px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  text-transform: uppercase;
}

#client-request-index-content .wrapper .tab-body.requests_active .box-request .box-body .info-table .info-row .col-action a:not(:first-child), #client-request-index-content .wrapper .tab-body.requests_inactive .box-request .box-body .info-table .info-row .col-action a:not(:first-child) {
  margin-top: 15px;
}

#client-request-index-content .wrapper .tab-body.requests_active .box-request .box-body .info-table .info-row .col-action a:hover, #client-request-index-content .wrapper .tab-body.requests_inactive .box-request .box-body .info-table .info-row .col-action a:hover {
  text-decoration: none;
}

#client-request-index-content .wrapper .tab-body.requests_active .box-request .box-body .info-table .info-row .col-action a.btn-green, #client-request-index-content .wrapper .tab-body.requests_inactive .box-request .box-body .info-table .info-row .col-action a.btn-green {
  background-color: #02cd8d;
  border: 1px solid #02cd8d;
  color: #fff;
}

#client-request-index-content .wrapper .tab-body.requests_active .box-request .box-body .info-table .info-row .col-action a.btn-grey, #client-request-index-content .wrapper .tab-body.requests_inactive .box-request .box-body .info-table .info-row .col-action a.btn-grey {
  background-color: #eaeaea;
  border: 1px solid #eaeaea;
  color: #fff;
}

#client-request-index-content .wrapper .tab-body.requests_active .box-request .box-body .info-table .info-row .col-action .notification-dot, #client-request-index-content .wrapper .tab-body.requests_inactive .box-request .box-body .info-table .info-row .col-action .notification-dot {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: block;
  background-color: #02cd8d;
  color: #fff;
  text-align: center;
  font-size: 12px;
  line-height: 21px;
  border: 2px solid #fff;
}

#client-request-index-content .wrapper .tab-body.messages {
  padding: 0 30px;
  background-color: #FFFFFF;
  border: 1px solid #d4f0e3;
}

#client-request-index-content .wrapper .tab-body.messages > [class^=mode] {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s .5s, opacity .5s linear;
  height: 0;
  overflow: hidden;
}

#client-request-index-content .wrapper .tab-body.messages > [class^=mode].active {
  overflow: visible;
  height: auto;
  visibility: visible;
  opacity: 1;
  transition: opacity .5s linear;
}

#client-request-index-content .wrapper .tab-body.messages .mode-index .box-message:not(:first-child) .box-body .info-table {
  border-top: 1px solid #02cd8d;
}

#client-request-index-content .wrapper .tab-body.messages .mode-index .box-message .box-body .info-table {
  display: table;
  width: 100%;
  padding: 25px 10px;
}

#client-request-index-content .wrapper .tab-body.messages .mode-index .box-message .box-body .info-table .info-row {
  display: table-row;
}

#client-request-index-content .wrapper .tab-body.messages .mode-index .box-message .box-body .info-table .info-row > [class^=col] {
  display: table-cell;
  padding-left: 15px;
  padding-right: 15px;
  vertical-align: top;
}

#client-request-index-content .wrapper .tab-body.messages .mode-index .box-message .box-body .info-table .info-row > [class^=col]:first-child {
  padding-left: 0;
}

#client-request-index-content .wrapper .tab-body.messages .mode-index .box-message .box-body .info-table .info-row > [class^=col]:last-child {
  padding-right: 0;
}

#client-request-index-content .wrapper .tab-body.messages .mode-index .box-message .box-body .info-table .info-row .col-logo {
  width: 80px;
}

#client-request-index-content .wrapper .tab-body.messages .mode-index .box-message .box-body .info-table .info-row .col-logo img {
  max-width: 65px;
  max-height: 65px;
}

#client-request-index-content .wrapper .tab-body.messages .mode-index .box-message .box-body .info-table .info-row .col-detail {
  width: 120px;
}

#client-request-index-content .wrapper .tab-body.messages .mode-index .box-message .box-body .info-table .info-row .col-detail .title {
  color: #585858;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  margin-bottom: 10px;
  line-height: 22px;
}

#client-request-index-content .wrapper .tab-body.messages .mode-index .box-message .box-body .info-table .info-row .col-detail a {
  color: black;
  text-decoration: none;
}

#client-request-index-content .wrapper .tab-body.messages .mode-index .box-message .box-body .info-table .info-row .col-detail .date {
  color: #585858;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
}

#client-request-index-content .wrapper .tab-body.messages .mode-index .box-message .box-body .info-table .info-row .col-main .title {
  color: #585858;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  margin-bottom: 10px;
  line-height: 22px;
}

#client-request-index-content .wrapper .tab-body.messages .mode-index .box-message .box-body .info-table .info-row .col-main .content {
  color: #999999;
}

#client-request-index-content .wrapper .tab-body.messages .mode-index .box-message .box-body .info-table .info-row .col-action {
  vertical-align: middle;
  width: 145px;
  position: relative;
}

#client-request-index-content .wrapper .tab-body.messages .mode-index .box-message .box-body .info-table .info-row .col-action a {
  border: 1px solid #585858;
  border-radius: 4px;
  color: #585858;
  display: inline-block;
  width: 100%;
  padding: 9px 0;
  font-size: 12px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}

#client-request-index-content .wrapper .tab-body.messages .mode-index .box-message .box-body .info-table .info-row .col-action a:hover {
  text-decoration: none;
}

#client-request-index-content .wrapper .tab-body.messages .mode-index .box-message .box-body .info-table .info-row .col-action a.btn-grey {
  background-color: #eaeaea;
  border: 1px solid #eaeaea;
  color: #fff;
}

#client-request-index-content .wrapper .tab-body.messages .mode-index .box-message .box-body .info-table .info-row .col-action .notification-dot {
  position: absolute;
  top: -25px;
  right: -40px;
  transform: translate(50%, -50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: block;
  font-size: 0;
  background-color: #02cd8d;
}

#client-request-index-content .wrapper .tab-body.messages .mode-show .box-message .box-title {
  margin-left: -30px;
  margin-right: -30px;
  color: #02cd8d;
  background-color: #ccd9e0;
  font-size: 18px;
  font-weight: bold;
  padding: 30px 20px;
  line-height: 1;
}

#client-request-index-content .wrapper .tab-body.messages .mode-show .box-message .box-title a {
  display: block;
  float: right;
  padding: 15px;
  color: #585858;
  background-color: transparent;
  border: 1px solid #585858;
  font-size: 16px;
  margin-top: -15px;
  margin-bottom: -15px;
}

#client-request-index-content .wrapper .tab-body.messages .mode-show .box-message .box-title a:hover {
  text-decoration: none;
}

#client-request-index-content .wrapper .tab-body.messages .mode-show .box-message .box-header {
  padding-top: 20px;
  padding-bottom: 20px;
}

#client-request-index-content .wrapper .tab-body.messages .mode-show .box-message .box-header .title {
  color: #585858;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  float: left;
  line-height: 38px;
}

#client-request-index-content .wrapper .tab-body.messages .mode-show .box-message .box-header .schedule {
  outline: 0;
  text-decoration: none;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  line-height: 1;
  float: right;
  display: block;
  padding: 12px;
  border-radius: 4px;
  background-color: #eeeeee;
  color: #02cd8d;
  cursor: pointer;
}

#client-request-index-content .wrapper .tab-body.messages .mode-show .box-message .box-header .schedule:hover {
  text-decoration: none;
}

#client-request-index-content .wrapper .tab-body.messages .mode-show .box-message .box-input textarea {
  width: 100%;
  min-height: 110px;
  border-radius: 4px;
  border: 2px solid #ccd9e0;
  background-color: #FFFFFF;
  color: #484848;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  padding: 13px;
  transition: border .6s;
  outline: none;
  margin-bottom: 20px;
  display: block;
}

#client-request-index-content .wrapper .tab-body.messages .mode-show .box-message .box-input textarea:focus {
  border: 2px solid #02cd8d;
}

#client-request-index-content .wrapper .tab-body.messages .mode-show .box-message .box-input button {
  width: 195px;
  max-width: 100%;
  border-radius: 4px;
}

#client-request-index-content .wrapper .tab-body.messages .mode-show .box-message .box-body {
  padding-bottom: 20px;
}

#client-request-index-content .wrapper .tab-body.messages .mode-show .box-message .box-body .messages .message {
  position: relative;
  margin-top: 50px;
}

#client-request-index-content .wrapper .tab-body.messages .mode-show .box-message .box-body .messages .message.left .col-logo {
  width: 100px;
  position: absolute;
  top: 0;
  left: 0;
}

#client-request-index-content .wrapper .tab-body.messages .mode-show .box-message .box-body .messages .message.left .col-logo img {
  max-width: 70px;
  max-height: 70px;
}

#client-request-index-content .wrapper .tab-body.messages .mode-show .box-message .box-body .messages .message.left .col-message {
  padding-left: 120px;
  float: left;
  width: 80%;
}

#client-request-index-content .wrapper .tab-body.messages .mode-show .box-message .box-body .messages .message.left .col-message .content {
  padding: 25px 20px;
  background-color: #f4f4f4;
  border-radius: 4px;
  color: #484848;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  line-height: 1.6;
}

#client-request-index-content .wrapper .tab-body.messages .mode-show .box-message .box-body .messages .message.left .col-message .content .time {
  margin-top: 17px;
  color: #888888;
  font-size: 15px;
  font-family: "Roboto", sans-serif;
}

#client-request-index-content .wrapper .tab-body.messages .mode-show .box-message .box-body .messages .message.right .col-logo {
  width: 100px;
  position: absolute;
  top: 0;
  right: 0;
}

#client-request-index-content .wrapper .tab-body.messages .mode-show .box-message .box-body .messages .message.right .col-logo img {
  max-width: 70px;
  max-height: 70px;
}

#client-request-index-content .wrapper .tab-body.messages .mode-show .box-message .box-body .messages .message.right .col-message {
  padding-right: 120px;
  float: right;
  width: 80%;
}

#client-request-index-content .wrapper .tab-body.messages .mode-show .box-message .box-body .messages .message.right .col-message .content {
  padding: 25px 20px;
  background-color: #c7ffe4;
  color: #484848;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  line-height: 1.6;
  border-radius: 4px;
}

#client-request-index-content .wrapper .tab-body.messages .mode-show .box-message .box-body .messages .message.right .col-message .content .time {
  margin-top: 17px;
  color: #888888;
  font-size: 15px;
  font-family: "Roboto", sans-serif;
}

#client-request-index-content .wrapper .tab-body.messages .mode-show .box-message .box-body .messages .message:after {
  content: '';
  display: block;
  clear: both;
}

#client-request-index-content .wrapper .tab-guide {
  border: 1px solid #000000;
  border-radius: 3px;
  padding: 10px 15px;
}

#client-request-index-content .wrapper .tab-guide .legend {
  color: #000;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0;
}

#client-request-index-content .wrapper .tab-guide ul.checks {
  list-style: none;
  padding: 0;
  margin: 10px 0 25px 0;
}

#client-request-index-content .wrapper .tab-guide ul.checks li {
  padding-left: 35px;
  position: relative;
  color: #000;
  font-size: 14px;
  font-weight: bold;
}

#client-request-index-content .wrapper .tab-guide ul.checks li:before {
  content: '';
  display: block;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 2px;
  background-color: #dddddd;
}

#client-request-index-content .wrapper .tab-guide ul.checks li:not(:first-child) {
  margin-top: 10px;
}

#client-request-index-content .wrapper .tab-guide ul.links {
  list-style: none;
  margiN: 10px 0 0 0;
  padding: 0;
}

#client-request-index-content .wrapper .tab-guide ul.links li a {
  display: block;
  margin-bottom: 3px;
  color: #02cd8d;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
}

#client-request-proposal-schedule-consult {
  width: 600px;
  margin: 0 auto;
  background-color: #f5f5f5;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
}

#client-request-proposal-schedule-consult .popup-heading {
  padding: 20px 25px;
  font-size: 18px;
  font-weight: bold;
  color: #585858;
  background-color: #FFFFFF;
}

#client-request-proposal-schedule-consult .popup-body {
  padding: 20px 25px;
  font-size: 14px;
  color: #585858;
  font-weight: normal;
}

#client-request-proposal-schedule-consult .popup-body .description {
  margin-bottom: 10px;
}

#client-request-proposal-schedule-consult .popup-body .form-group {
  padding-left: 10px;
  padding-right: 10px;
}

#client-request-proposal-schedule-consult .popup-body .form-group .label-control {
  color: #888888;
  display: block;
  margin-bottom: 5px;
}

#client-request-proposal-schedule-consult .popup-body .form-group input,
#client-request-proposal-schedule-consult .popup-body .form-group select {
  height: 30px;
  line-height: 20px;
  display: block;
  font-size: 12px;
  padding: 5px 10px;
  outline: 0;
  border: 1px solid #dddddd;
  transition: border .3s;
  color: #585858;
  width: 100%;
}

#client-request-proposal-schedule-consult .popup-body .form-group input:focus,
#client-request-proposal-schedule-consult .popup-body .form-group select:focus {
  border: 1px solid #029470;
}

#client-request-proposal-schedule-consult .popup-body .form-group textarea {
  font-size: 12px;
  padding: 5px 10px;
  outline: 0;
  border: 1px solid #dddddd;
  transition: border .3s;
  color: #585858;
  width: 100%;
  height: 100px;
}

#client-request-proposal-schedule-consult .popup-body .form-group textarea:focus {
  border: 1px solid #029470;
}

#client-request-proposal-schedule-consult .popup-body .form-group .phone {
  float: left;
  width: 180px;
  height: 30px;
  line-height: 30px;
  margin-left: 15px;
}

#client-request-proposal-schedule-consult .popup-body .form-group .phone_prefix-group {
  float: left;
  width: 70px;
}

#client-request-proposal-schedule-consult .popup-body .form-group .phone_prefix-group .phone_prefix {
  height: 30px;
  line-height: 30px;
}

#client-request-proposal-schedule-consult .popup-body .form-group .date-group,
#client-request-proposal-schedule-consult .popup-body .form-group .time-group {
  width: 180px;
  display: inline-block;
  position: relative;
  background-color: #fff;
  border: 1px solid #dddddd;
}

#client-request-proposal-schedule-consult .popup-body .form-group .date-group .date, #client-request-proposal-schedule-consult .popup-body .form-group .date-group .time,
#client-request-proposal-schedule-consult .popup-body .form-group .time-group .date,
#client-request-proposal-schedule-consult .popup-body .form-group .time-group .time {
  width: 100%;
  padding-right: 35px;
  background: none;
}

#client-request-proposal-schedule-consult .popup-body .form-group .date-group .icon-append,
#client-request-proposal-schedule-consult .popup-body .form-group .time-group .icon-append {
  position: absolute;
  right: 10px;
  top: 5px;
}

#client-request-proposal-schedule-consult .popup-body .form-group .date-group {
  margin-right: 20px;
}

#client-request-proposal-schedule-consult .popup-body .form-group .date-group .bootstrap-datetimepicker-widget {
  z-index: 999999999999999999999999999999999999999;
}

#client-request-proposal-schedule-consult .popup-body .form-group .time-group {
  margin-left: 20px;
}

#client-request-proposal-schedule-consult .popup-body .form-group .time-group .bootstrap-datetimepicker-widget {
  z-index: 999999999999999999999999999999999999999;
}

#client-request-proposal-schedule-consult .popup-body .form-group:after {
  content: '';
  display: block;
  clear: both;
}

#client-request-proposal-schedule-consult .popup-body .button-group .btn-effect-1 {
  width: 100%;
}

@media only screen and (max-width: 992px) {
  #client-request-index-content .container {
    width: 100%;
  }
  #client-request-index-content .wrapper .top {
    padding: 15px 0;
  }
  #client-request-index-content .wrapper .top .btn-new-request {
    width: 50%;
    float: left;
    display: block;
  }
  #client-request-index-content .wrapper .top .btn-new-request .btn-effect-1 {
    width: 70%;
    font-size: 16px;
    padding: 10px 5px 13px;
  }
  #client-request-index-content .wrapper .top .show-request {
    width: 50%;
    float: left;
    display: block;
  }
  #client-request-index-content .wrapper .top .show-request .action {
    float: right;
    font-size: 14px;
    padding-top: 10px;
  }
  #client-request-index-content .wrapper .top .show-request .action img {
    width: 19px;
    height: 13px;
  }
  #client-request-index-content .wrapper .tab-coordinator {
    display: inline-block;
    width: 100%;
  }
  #client-request-index-content .wrapper .tab-coordinator .tab-title {
    display: block;
  }
  #client-request-index-content .wrapper .tab-coordinator .info-row {
    display: block;
    width: 50%;
    float: left;
  }
  #client-request-index-content .wrapper .tab-coordinator .tab-footer {
    display: block;
    border-top: none;
    float: left;
    width: 50%;
  }
  #client-request-index-content .wrapper .tab-coordinator .tab-footer .clearfix {
    padding-top: 20px;
    padding-left: 130px;
  }
  #client-request-index-content .wrapper .tab-coordinator .tab-footer .clearfix img {
    float: left;
  }
  #client-request-index-content .wrapper .tab-coordinator .tab-footer .clearfix a {
    float: left;
    padding: 0 10px 10px;
  }
  #client-request-index-content .wrapper .tab-body {
    padding-top: 15px;
  }
  #client-request-index-content .wrapper .tab-body.requests_active .box-request .box-body .info-table .info-row > [class^=col], #client-request-index-content .wrapper .tab-body.requests_inactive .box-request .box-body .info-table .info-row > [class^=col] {
    display: block;
  }
  #client-request-index-content .wrapper .tab-body.requests_active .box-request .box-body .info-table .info-row .col-main, #client-request-index-content .wrapper .tab-body.requests_inactive .box-request .box-body .info-table .info-row .col-main {
    display: block;
    width: 100%;
  }
  #client-request-index-content .wrapper .tab-body.requests_active .box-request .box-body .info-table .info-row .col-main .status, #client-request-index-content .wrapper .tab-body.requests_inactive .box-request .box-body .info-table .info-row .col-main .status {
    margin-bottom: 10px;
  }
  #client-request-index-content .wrapper .tab-body.requests_active .box-request .box-body .info-table .info-row .col-chosen, #client-request-index-content .wrapper .tab-body.requests_inactive .box-request .box-body .info-table .info-row .col-chosen {
    padding-left: 30px;
    width: 100%;
    display: block;
  }
  #client-request-index-content .wrapper .tab-body.requests_active .box-request .box-body .info-table .info-row .col-chosen .title, #client-request-index-content .wrapper .tab-body.requests_inactive .box-request .box-body .info-table .info-row .col-chosen .title {
    padding-top: 8px;
  }
  #client-request-index-content .wrapper .tab-body.requests_active .box-request .box-body .info-table .info-row .col-chosen .news, #client-request-index-content .wrapper .tab-body.requests_inactive .box-request .box-body .info-table .info-row .col-chosen .news {
    padding-top: 20px;
  }
  #client-request-index-content .wrapper .tab-body.requests_active .box-request .box-body .info-table .info-row .col-action, #client-request-index-content .wrapper .tab-body.requests_inactive .box-request .box-body .info-table .info-row .col-action {
    display: table-cell;
    padding-bottom: 150px;
    right: 30px;
  }
  #client-request-index-content .wrapper .tab-body.requests_active .box-request .box-body .info-table .info-row .col-action a, #client-request-index-content .wrapper .tab-body.requests_inactive .box-request .box-body .info-table .info-row .col-action a {
    width: 140px;
  }
  #client-request-index-content .wrapper .tab-body.active {
    padding-top: 25px;
  }
  #client-request-index-content .wrapper .tab-body.active .no-record .bg-info {
    color: #18283e;
    font-weight: bold;
    padding: 20px 30px;
    background-color: #ffffff;
  }
  #client-request-index-content .wrapper .tab-body.messages .mode-index .box-message .box-body .info-table {
    display: block;
  }
  #client-request-index-content .wrapper .tab-body.messages .mode-index .box-message .box-body .info-table:not(:first-child) {
    border-top: 1px solid #02cd8d;
  }
  #client-request-index-content .wrapper .tab-body.messages .mode-index .box-message .box-body .info-table .info-row > [class^=col]:not(:first-child) {
    display: block;
  }
  #client-request-index-content .wrapper .tab-body.messages .mode-index .box-message .box-body .info-table .info-row > [class^=col]:not(:first-child).col-details {
    border-top: none;
  }
  #client-request-index-content .wrapper .tab-body.messages .mode-index .box-message .box-body .info-table .info-row > [class^=col]:not(:first-child).col-action {
    border-top: none;
  }
  #client-request-index-content .wrapper .tab-body.messages .mode-index .box-message .box-body .info-table .info-row > [class^=col]:not(:first-child).col-main .content {
    padding-bottom: 10px;
  }
  #client-request-index-content .wrapper .tab-guide .legend {
    font-size: 16px;
  }
  #client-request-index-content .wrapper .tab-guide ul.checks li {
    font-size: 12px;
  }
  #client-request-index-content .wrapper .tab-guide ul.links li a {
    font-size: 12px;
  }
}

@media only screen and (max-width: 767px) {
  #client-request-index-content .wrapper .top {
    padding: 15px 0;
  }
  #client-request-index-content .wrapper .top .btn-new-request .btn-effect-1 {
    width: auto;
    font-size: 12px;
  }
  #client-request-index-content .wrapper .top .show-request .action {
    font-size: 12px;
  }
  #client-request-index-content .wrapper .top .show-request .action img {
    width: 13px;
    height: 13px;
  }
  #client-request-index-content .wrapper .tab-body.requests_active .box-request .box-body, #client-request-index-content .wrapper .tab-body.requests_inactive .box-request .box-body {
    padding-bottom: 100px;
    width: 100%;
  }
  #client-request-index-content .wrapper .tab-body.requests_active .box-request .box-body .info-table .info-row, #client-request-index-content .wrapper .tab-body.requests_inactive .box-request .box-body .info-table .info-row {
    font-size: 10px;
    padding-right: 30%;
    position: relative;
    width: 100%;
    display: block;
  }
  #client-request-index-content .wrapper .tab-body.requests_active .box-request .box-body .info-table .info-row > [class^=col], #client-request-index-content .wrapper .tab-body.requests_inactive .box-request .box-body .info-table .info-row > [class^=col] {
    padding-left: 5px;
    padding-right: 10px;
  }
  #client-request-index-content .wrapper .tab-body.requests_active .box-request .box-body .info-table .info-row > [class^=col]:first-child, #client-request-index-content .wrapper .tab-body.requests_inactive .box-request .box-body .info-table .info-row > [class^=col]:first-child {
    padding-left: 20px;
  }
  #client-request-index-content .wrapper .tab-body.requests_active .box-request .box-body .info-table .info-row .col-main .status, #client-request-index-content .wrapper .tab-body.requests_inactive .box-request .box-body .info-table .info-row .col-main .status {
    font-size: 10px;
  }
  #client-request-index-content .wrapper .tab-body.requests_active .box-request .box-body .info-table .info-row .col-chosen, #client-request-index-content .wrapper .tab-body.requests_inactive .box-request .box-body .info-table .info-row .col-chosen {
    padding-left: 20px;
    width: 100%;
    display: block;
  }
  #client-request-index-content .wrapper .tab-body.requests_active .box-request .box-body .info-table .info-row .col-chosen .title, #client-request-index-content .wrapper .tab-body.requests_inactive .box-request .box-body .info-table .info-row .col-chosen .title {
    padding-top: 8px;
  }
  #client-request-index-content .wrapper .tab-body.requests_active .box-request .box-body .info-table .info-row .col-chosen .news, #client-request-index-content .wrapper .tab-body.requests_inactive .box-request .box-body .info-table .info-row .col-chosen .news {
    padding-top: 20px;
  }
  #client-request-index-content .wrapper .tab-body.requests_active .box-request .box-body .info-table .info-row .col-chosen .news > div, #client-request-index-content .wrapper .tab-body.requests_inactive .box-request .box-body .info-table .info-row .col-chosen .news > div {
    font-size: 10px;
  }
  #client-request-index-content .wrapper .tab-body.requests_active .box-request .box-body .info-table .info-row .col-action, #client-request-index-content .wrapper .tab-body.requests_inactive .box-request .box-body .info-table .info-row .col-action {
    position: absolute;
    top: 0;
    right: 0px;
    padding-right: 15px;
    width: 35%;
  }
  #client-request-index-content .wrapper .tab-body.requests_active .box-request .box-body .info-table .info-row .col-action a, #client-request-index-content .wrapper .tab-body.requests_inactive .box-request .box-body .info-table .info-row .col-action a {
    width: 90px;
    font-size: 10px;
  }
  #client-request-index-content .wrapper .tab-body.requests_active .box-request .box-body .info-table .info-row .col-action .notification-dot, #client-request-index-content .wrapper .tab-body.requests_inactive .box-request .box-body .info-table .info-row .col-action .notification-dot {
    line-height: 15px;
    width: 20px;
    height: 20px;
    font-size: 10px;
    top: -30px;
    right: 0;
  }
  #client-request-index-content .wrapper .tab-coordinator .tab-title {
    font-size: 10px;
  }
  #client-request-index-content .wrapper .tab-coordinator .info-row {
    width: 66%;
    font-size: 10px;
  }
  #client-request-index-content .wrapper .tab-coordinator .info-row .info-right {
    padding-left: 3px;
  }
  #client-request-index-content .wrapper .tab-coordinator .info-row .info-right .info {
    color: #000;
  }
  #client-request-index-content .wrapper .tab-coordinator .info-row .info-right .info.info-name {
    font-size: 10px;
  }
  #client-request-index-content .wrapper .tab-coordinator .info-row img {
    max-width: 50px;
    max-height: 50px;
  }
  #client-request-index-content .wrapper .tab-coordinator .tab-footer {
    width: 34%;
  }
  #client-request-index-content .wrapper .tab-coordinator .tab-footer .clearfix {
    padding-top: 40px;
    padding-left: 0px;
  }
  #client-request-index-content .wrapper .tab-coordinator .tab-footer .clearfix img {
    width: 30px;
    height: 30px;
    left: 20%;
  }
  #client-request-index-content .wrapper .tab-coordinator .tab-footer .clearfix a {
    padding: 0px 4px 0px;
    font-size: 10px;
  }
  #client-request-index-content .wrapper .tab-guide .legend {
    font-size: 14px;
  }
  #client-request-index-content .wrapper .tab-guide ul.checks li {
    font-size: 12px;
  }
  #client-request-proposal-schedule-consult {
    width: 100%;
  }
  #client-request-proposal-schedule-consult .popup-heading {
    padding: 45px 20px 20px;
    font-size: 18px;
  }
  #client-request-proposal-schedule-consult .popup-body {
    padding: 20px 20px;
    font-size: 14px;
    color: #585858;
    font-weight: normal;
  }
  #client-request-proposal-schedule-consult .popup-body .description {
    margin-bottom: 10px;
  }
  #client-request-proposal-schedule-consult .popup-body .form-group {
    padding-left: 10px;
    padding-right: 10px;
  }
  #client-request-proposal-schedule-consult .popup-body .form-group .phone {
    width: 65%;
  }
  #client-request-proposal-schedule-consult .popup-body .form-group .phone_prefix {
    float: left;
    width: 70px;
    height: 30px;
    line-height: 30px;
  }
  #client-request-proposal-schedule-consult .popup-body .form-group .time-group {
    margin-left: 0;
  }
}

#client-request-show-content {
  background-color: #f0f4f7;
  padding-top: 30px;
  padding-bottom: 30px;
}

#client-request-show-content .content-header {
  border-top: 1px solid #d4f0e3;
  border-left: 1px solid #d4f0e3;
  border-right: 1px solid #d4f0e3;
  background-color: #FFFFFF;
  padding: 25px 30px;
}

#client-request-show-content .content-header .title {
  color: #333;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
  line-height: 1;
}

#client-request-show-content .content-header .links a {
  color: #00b37b;
  line-height: 20px;
  font-size: 15px;
  float: right;
  font-weight: bold;
}

#client-request-show-content .content-header .links a img {
  display: inline-block;
  margin-right: 10px;
}

#client-request-show-content .content-header .links a:not(:first-child) {
  margin-right: 24px;
}

#client-request-show-content .navigation {
  background-color: #373f48;
  padding: 0 20px;
}

#client-request-show-content .navigation .link {
  display: block;
  float: left;
  font-size: 12px;
  color: #FFFFFF;
  padding: 20px 0;
  position: relative;
  cursor: pointer;
  line-height: 15px;
}

#client-request-show-content .navigation .link img {
  display: inline-block;
  margin-right: 5px;
  vertical-align: bottom;
}

#client-request-show-content .navigation .link:hover {
  text-decoration: none;
}

#client-request-show-content .navigation .link:not(:first-child) {
  margin-left: 30px;
}

#client-request-show-content .navigation .link.current:after {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 3px;
  background-color: #28f2b2;
}

#client-request-show-content .content-body {
  padding-top: 22px;
}

#client-request-show-content .content-body > .row {
  margin-left: -11px;
  margin-right: -11px;
}

#client-request-show-content .content-body > .row [class^=col] {
  padding-left: 11px;
  padding-right: 11px;
}

#client-request-show-content .content-body .tab.tab-coordinator {
  border: 1px solid #000;
  border-radius: 4px;
}

#client-request-show-content .content-body .tab.tab-coordinator .tab-title {
  padding: 10px;
  font-size: 16px;
}

#client-request-show-content .content-body .tab.tab-coordinator .info-row {
  padding: 0 10px 10px;
}

#client-request-show-content .content-body .tab.tab-coordinator .info-row img {
  display: inline-block;
  vertical-align: top;
}

#client-request-show-content .content-body .tab.tab-coordinator .info-row .info-right {
  display: inline-block;
  padding-left: 10px;
  width: 65%;
}

#client-request-show-content .content-body .tab.tab-coordinator .info-row .info-right .info {
  color: #000;
}

#client-request-show-content .content-body .tab.tab-coordinator .info-row .info-right .info.info-name {
  font-size: 16px;
  font-weight: bold;
}

#client-request-show-content .content-body .tab.tab-coordinator .info-row .info-right .info.info-tel {
  font-style: italic;
}

#client-request-show-content .content-body .tab.tab-coordinator .tab-footer {
  position: relative;
  border-top: 1px solid #000;
  text-align: center;
}

#client-request-show-content .content-body .tab.tab-coordinator .tab-footer img {
  position: absolute;
  left: 10%;
  top: 25%;
}

#client-request-show-content .content-body .tab.tab-coordinator .tab-footer a {
  display: block;
  color: #02cd8d;
  padding: 10px;
  font-size: 16px;
  text-decoration: none;
}

#client-request-show-content .content-body .tab.tab-guide {
  border: 1px solid #000000;
  border-radius: 3px;
  padding: 10px 15px;
}

#client-request-show-content .content-body .tab.tab-guide .legend {
  color: #000;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0;
}

#client-request-show-content .content-body .tab.tab-guide ul.checks {
  list-style: none;
  padding: 0;
  margin: 10px 0 25px 0;
}

#client-request-show-content .content-body .tab.tab-guide ul.checks li {
  padding-left: 35px;
  position: relative;
  color: #000;
  font-size: 14px;
  font-weight: bold;
}

#client-request-show-content .content-body .tab.tab-guide ul.checks li:before {
  content: '';
  display: block;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 2px;
  background-color: #dddddd;
}

#client-request-show-content .content-body .tab.tab-guide ul.checks li:not(:first-child) {
  margin-top: 10px;
}

#client-request-show-content .content-body .tab.tab-guide ul.links {
  list-style: none;
  margiN: 10px 0 0 0;
  padding: 0;
}

#client-request-show-content .content-body .tab.tab-guide ul.links li a {
  display: block;
  margin-bottom: 3px;
  color: #02cd8d;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
}

#client-request-show-content .content-body .tab:not(:first-child) {
  margin-top: 22px;
}

#client-request-show-content .content-body .box-proposal {
  background-color: #fff;
  border-radius: 4px;
  border-left: 1px solid #eaeaea;
  border-top: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 1px solid transparent;
  box-shadow: 1px 1px 5px 1px rgba(215, 216, 217, 0.75);
  position: relative;
}

#client-request-show-content .content-body .box-proposal:not(:first-child) {
  margin-top: 22px;
}

#client-request-show-content .content-body .box-proposal .box-header {
  padding: 20px 10px;
  display: table;
  width: 100%;
  position: relative;
}

#client-request-show-content .content-body .box-proposal .box-header > [class^=col] {
  display: table-cell;
  vertical-align: top;
  padding: 0 10px;
}

#client-request-show-content .content-body .box-proposal .box-header .col-badges {
  position: absolute;
  top: -5px;
  left: 80%;
  transform: translateX(-80%);
}

#client-request-show-content .content-body .box-proposal .box-header .col-badges .svg-menu {
  float: left;
  display: inline;
  margin: 0 3px;
}

#client-request-show-content .content-body .box-proposal .box-header .col-badges .svg-menu img {
  width: 30px;
}

#client-request-show-content .content-body .box-proposal .box-header .col-logo {
  width: 100px;
}

#client-request-show-content .content-body .box-proposal .box-header .col-logo .logo-border {
  width: 80px;
  height: 80px;
  background-color: #ccd9e0;
  border-radius: 4px;
  padding: 3px;
}

#client-request-show-content .content-body .box-proposal .box-header .col-logo .logo-border .logo {
  width: 74px;
  height: 74px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

#client-request-show-content .content-body .box-proposal .box-header .col-info {
  padding-top: 10px;
}

#client-request-show-content .content-body .box-proposal .box-header .col-info .title {
  color: #00b37b;
  font-size: 16px;
  line-height: 1;
  font-weight: bold;
}

#client-request-show-content .content-body .box-proposal .box-header .col-info .title a {
  color: black;
  text-decoration: none;
}

#client-request-show-content .content-body .box-proposal .box-header .col-info .title .fa {
  color: #28f1b1;
  padding-left: 5px;
}

#client-request-show-content .content-body .box-proposal .box-header .col-info .tagline {
  margin-top: 4px;
  font-size: 12px;
  color: #5a5a5a;
  font-weight: bold;
  width: 100%;
  max-width: 500px;
  display: block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

#client-request-show-content .content-body .box-proposal .box-header .col-info .inline-row [class^=tag] {
  display: inline-block;
  background-color: #02cd8d;
  color: #FFFFFF;
  font-size: 14px;
  padding: 5px 10px;
  margin-top: 6px;
  position: relative;
  border-radius: 4px 0 0 4px;
}

#client-request-show-content .content-body .box-proposal .box-header .col-info .inline-row [class^=tag]:not(:first-child) {
  margin-left: 30px;
}

#client-request-show-content .content-body .box-proposal .box-header .col-info .inline-row [class^=tag]:before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 15px 0 0;
  border-color: #02cd8d transparent transparent transparent;
  position: absolute;
  left: 100%;
  top: 0;
}

#client-request-show-content .content-body .box-proposal .box-header .col-info .inline-row [class^=tag]:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 0 0 15px;
  border-color: transparent transparent transparent #02cd8d;
  position: absolute;
  left: 100%;
  bottom: 0;
}

#client-request-show-content .content-body .box-proposal .box-header .col-review {
  width: 230px;
  position: relative;
  padding: 5px 30px;
}

#client-request-show-content .content-body .box-proposal .box-header .col-review .text {
  position: absolute;
  top: 50%;
  right: 118px;
  transform: translate(0, -50%);
  color: #28ac78;
  font-size: 14px;
  font-weight: bold;
}

#client-request-show-content .content-body .box-proposal .box-header .col-review .rating-circle-class {
  float: right;
}

#client-request-show-content .content-body .box-proposal .box-header .col-review .rating-circle-class .circles-text {
  font-size: 16px !important;
}

#client-request-show-content .content-body .box-proposal .box-summary {
  padding: 20px 10px;
  display: table;
  width: 100%;
  border-top: 1px solid #d4f0e3;
}

#client-request-show-content .content-body .box-proposal .box-summary > [class^=col] {
  display: table-cell;
  vertical-align: top;
  padding: 0 10px 20px;
}

#client-request-show-content .content-body .box-proposal .box-summary .col-summary {
  line-height: 1.3;
  font-size: 14px;
}

#client-request-show-content .content-body .box-proposal .box-summary .col-summary .notification-news {
  margin-left: -10px;
  margin-right: -10px;
}

#client-request-show-content .content-body .box-proposal .box-summary .col-summary .notification-news .notification-text {
  float: left;
  padding-left: 10px;
  padding-right: 10px;
  color: #02cd8d;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  line-height: 1;
  vertical-align: middle;
  white-space: nowrap;
}

#client-request-show-content .content-body .box-proposal .box-summary .col-summary .notification-news .notification-text span {
  color: #02cd8d;
  text-decoration: none;
}

#client-request-show-content .content-body .box-proposal .box-summary .col-summary .notification-news .notification-text span:hover {
  text-decoration: none;
}

#client-request-show-content .content-body .box-proposal .box-summary .col-summary .notification-news .notification-text:before {
  content: '';
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: #02cd8d;
  border-radius: 50%;
  margin-right: 8px;
}

#client-request-show-content .content-body .box-proposal .box-summary .col-summary strong {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

#client-request-show-content .content-body .box-proposal .box-summary .col-summary .paragraph {
  padding-top: 5px;
}

#client-request-show-content .content-body .box-proposal .box-summary .col-price {
  width: 300px;
  font-size: 15px;
  font-weight: bold;
  text-align: right;
  line-height: 1.3;
  padding-top: 16px;
}

#client-request-show-content .content-body .box-proposal .box-summary .col-price span {
  display: block;
  text-align: right;
  font-size: 14px;
  font-weight: normal;
}

#client-request-show-content .content-body .box-proposal .box-summary .col-action {
  width: 230px;
  padding: 16px 30px 0 40px;
}

#client-request-show-content .content-body .box-proposal .box-summary .col-action a:not(.btn-effect-1) {
  display: block;
  border: 1px solid #585858;
  height: 34px;
  line-height: 34px;
  text-align: center;
  cursor: pointer;
  color: #595959;
  font-size: 14px;
  font-weight: bold;
}

#client-request-show-content .content-body .box-proposal .box-summary .col-action a:not(.btn-effect-1):hover {
  text-decoration: none;
}

#client-request-show-content .content-body .box-proposal .box-summary .col-action .btn-grey {
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 22px;
  display: block;
  text-align: center;
  background-color: #eaeaea;
  color: #fff;
  font-size: 14px;
  border-radius: 2px;
}

#client-request-show-content .content-body .box-proposal .box-summary .col-action a + * {
  margin-top: 15px;
}

#client-request-show-content .content-body .box-proposal .box-insight,
#client-request-show-content .content-body .box-proposal .box-comment {
  padding: 20px 40px 30px 120px;
  border-top: 1px solid #d4f0e3;
  position: relative;
  color: #333;
  min-height: 70px;
  line-height: 15px;
  word-break: normal;
}

#client-request-show-content .content-body .box-proposal .box-insight img,
#client-request-show-content .content-body .box-proposal .box-comment img {
  position: absolute;
  max-height: 70px;
  max-width: 70px;
  display: block;
  top: 20px;
  left: 20px;
}

#client-request-show-content .content-body .box-proposal .box-insight strong,
#client-request-show-content .content-body .box-proposal .box-comment strong {
  font-weight: bold;
  font-size: 16px;
}

#client-request-show-content .content-body .box-proposal .box-insight p,
#client-request-show-content .content-body .box-proposal .box-comment p {
  line-height: 1.5;
  padding-top: 5px;
}

#client-request-show-content .content-body .box-proposal .notification-dot {
  position: absolute;
  right: 0;
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-left: 5px;
  text-align: center;
  line-height: 25px;
  font-size: 12px;
  color: #fff;
  background-color: #02cd8d;
  border-radius: 50%;
  top: 0;
  transform: translate(50%, -50%);
}

#client-request-show-content .content-body .box-proposal:before {
  position: absolute;
  content: '';
  display: block;
  width: 5px;
  left: 0;
  top: -1px;
  bottom: -1px;
  z-index: 2;
  background-color: #eaeaea;
}

#client-request-show-content .content-body .box-proposal:hover {
  border: 1px solid #02cd8d;
}

#client-request-show-content .content-body .box-proposal.box-green {
  background-color: #FFFFFF;
  border-left: 1px solid #02cd8d;
}

#client-request-show-content .content-body .box-proposal.box-green:hover {
  border: 1px solid #02cd8d;
}

#client-request-show-content .content-body .box-proposal.box-green:before {
  background-color: #02cd8d;
}

#client-request-show-content .content-body .box-proposal.box-orange {
  background-color: #FFFFFF;
  border-left: 1px solid #ffec00;
}

#client-request-show-content .content-body .box-proposal.box-orange:hover {
  border: 1px solid #ffec00;
}

#client-request-show-content .content-body .box-proposal.box-orange:before {
  background-color: #ffec00;
}

#client-request-show-content .content-body .box-proposal.box-grey {
  background-color: #f0f4f7;
}

#client-request-show-content .content-body .box-proposal.box-grey:hover {
  border: 1px solid #02cd8d;
}

#client-request-show-content .content-body .box-proposal.box-grey:before {
  top: 0;
  bottom: 0;
  background-color: #eaeaea;
}

#project-details-content {
  background-color: #fff;
  min-height: 500px;
  width: 900px;
  margin: 0 auto;
}

#project-details-content .content-header {
  background: #02cd8d;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 20px;
}

#project-details-content .content-header .title {
  font-size: 30px;
  color: #fffffc;
}

#project-details-content .content-header .row {
  margin-left: -40px;
  margin-right: -40px;
  color: #fffffc;
}

#project-details-content .content-header .row [class^=col] {
  padding: 20px 40px 20px 40px;
  position: relative;
}

#project-details-content .content-header .row [class^=col]:not(:first-child):after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 20px;
  bottom: 20px;
  width: 1px;
  background: #fff;
}

#project-details-content .content-header .legend {
  font-size: 20px;
  color: #fffffc;
}

#project-details-content .content-header .with-icon {
  line-height: 17px;
  margin-top: 10px;
  font-size: 12px;
  padding-left: 35px;
  position: relative;
  margin-bottom: 25px;
}

#project-details-content .content-header .with-icon img {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 15px;
  position: absolute;
  top: 0;
  left: 0;
}

#project-details-content .content-header .without-icon {
  line-height: 17px;
  margin-top: 10px;
  font-size: 12px;
}

#project-details-content .content-body {
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 20px;
  font-size: 12px;
  color: #56646d;
}

#project-details-content .content-body .legend {
  font-size: 14px;
  font-weight: bolder;
  color: #000000;
}

#project-details-content .content-body span {
  float: right;
  text-align: left;
  color: #a1a1a1;
  font-size: 10px;
}

#project-details-content .content-body img {
  padding-left: 35px;
}

#project-details-content .content-body .file {
  color: #02b37b;
}

#project-details-content .content-body p.questions {
  font-size: 13px;
  font-weight: normal;
  color: #56646d;
  padding-left: 18px;
  line-height: 1.5;
}

#project-details-content .content-body p.questions strong {
  font-size: 14px;
  font-weight: bold;
  color: #333;
  margin-left: -18px;
  line-height: 3;
}

#popup-request-cancel .content-body .form-group,
#popup-request-update_info .content-body .form-group {
  margin-bottom: 15px;
  margin-left: 0;
  margin-right: 0;
}

#popup-request-cancel .content-body .form-group .radios input[type=radio],
#popup-request-update_info .content-body .form-group .radios input[type=radio] {
  visibility: hidden;
  opacity: 0;
}

#popup-request-cancel .content-body .form-group .radios label,
#popup-request-update_info .content-body .form-group .radios label {
  display: block;
  margin: 0 0 10px;
  font-size: 20px;
  cursor: pointer;
  position: relative;
  padding-left: 30px;
  line-height: 20px;
}

#popup-request-cancel .content-body .form-group .radios label span:not(.text-error),
#popup-request-update_info .content-body .form-group .radios label span:not(.text-error) {
  display: inline-block;
  vertical-align: middle;
  font-size: 11px;
}

#popup-request-cancel .content-body .form-group .radios label span:not(.text-error).radio,
#popup-request-update_info .content-body .form-group .radios label span:not(.text-error).radio {
  border: 2px solid #d4f0e3;
  background-color: #d8f1e6;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  min-height: 20px;
  transition: background-color .3s;
}

#popup-request-cancel .content-body .form-group .radios label span:not(.text-error).radio input,
#popup-request-update_info .content-body .form-group .radios label span:not(.text-error).radio input {
  visibility: hidden;
  opacity: 0;
}

#popup-request-cancel .content-body .form-group .radios label.checked span:not(.text-error).radio,
#popup-request-update_info .content-body .form-group .radios label.checked span:not(.text-error).radio {
  background-color: #02cd8d;
}

#popup-request-cancel .content-body .form-group .radios input[type=text],
#popup-request-update_info .content-body .form-group .radios input[type=text] {
  display: inline-block;
  width: auto;
}

#popup-request-cancel .content-body .form-group textarea.form-control,
#popup-request-update_info .content-body .form-group textarea.form-control {
  width: 100%;
  outline: none;
  font-size: 12px;
  padding: 15px;
  border: 1px solid #e5e7e7;
  background-color: #fff;
  border-radius: 4px;
  min-height: 100px;
}

#popup-request-cancel .content-body .form-group textarea.form-control.error,
#popup-request-update_info .content-body .form-group textarea.form-control.error {
  border-color: red;
}

#popup-request-cancel .content-body .form-group .label-control,
#popup-request-update_info .content-body .form-group .label-control {
  color: #333;
  font-weight: bold;
  margin-bottom: 10px;
}

#popup-request-cancel .content-body .form-group .label-control .red,
#popup-request-update_info .content-body .form-group .label-control .red {
  color: red;
}

#client-request-proposal-hire .form-group label.radio {
  font-weight: normal;
  padding: 0 0 0 30px;
  position: relative;
  margin-top: 10px;
  line-height: 30px;
  cursor: pointer;
}

#client-request-proposal-hire .form-group label.radio span {
  position: absolute;
  left: 0;
  top: 5px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #dddddd;
}

#client-request-proposal-hire .form-group label.radio [type=radio]:checked + span:after {
  content: '';
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 16px;
  height: 16px;
  background-color: #02cd8d;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

@media only screen and (max-width: 1199px) {
  #client-request-show-content .content-body .box-proposal .box-header .col-info .tagline {
    max-width: 300px;
  }
}

@media only screen and (max-width: 992px) {
  #client-request-show-content .container {
    width: 100%;
    padding: 0 15px;
  }
  #client-request-show-content .content-header {
    padding: 20px 25px;
  }
  #client-request-show-content .content-header .links {
    padding-top: 15px;
    padding-bottom: 10px;
  }
  #client-request-show-content .content-header .links a {
    float: left;
  }
  #client-request-show-content .content-header .links a.btn-close {
    padding-left: 15px;
  }
  #client-request-show-content .content-header .links a img {
    width: 15px;
  }
  #client-request-show-content .content-body .col-md-9 {
    margin-top: 25px;
  }
  #client-request-show-content .content-body .box-proposal .box-header .col-badges {
    left: 70%;
    transform: translateX(-70%);
  }
  #client-request-show-content .content-body .box-proposal .box-summary .col-summary {
    width: 55%;
  }
  #client-request-show-content .content-body .box-proposal .box-summary .col-price {
    width: 20%;
  }
  #client-request-show-content .content-body .box-proposal .box-summary .col-action {
    width: 25%;
  }
  #client-request-show-content .content-body .tab.tab-coordinator {
    display: inline-block;
    width: 100%;
  }
  #client-request-show-content .content-body .tab.tab-coordinator .tab-title {
    display: block;
  }
  #client-request-show-content .content-body .tab.tab-coordinator .info-row {
    display: block;
    width: 50%;
    float: left;
  }
  #client-request-show-content .content-body .tab.tab-coordinator .tab-footer {
    display: block;
    border-top: none;
    float: left;
    width: 50%;
  }
  #client-request-show-content .content-body .tab.tab-coordinator .tab-footer .clearfix {
    padding-top: 20px;
    padding-left: 130px;
  }
  #client-request-show-content .content-body .tab.tab-coordinator .tab-footer .clearfix img {
    float: left;
  }
  #client-request-show-content .content-body .tab.tab-coordinator .tab-footer .clearfix a {
    float: left;
    padding: 0 10px 10px;
  }
  #client-request-show-content .content-body .verify {
    padding-bottom: 15px;
  }
  #client-request-show-content .content-body .verify .verify-email {
    background-color: #02cd8d;
    color: #ffffff;
  }
  #client-request-show-content .content-body .verify .verify-email .col-logo {
    float: left;
    display: block;
    padding: 20px 30px;
    width: 15%;
  }
  #client-request-show-content .content-body .verify .verify-email .col-description {
    float: left;
    display: block;
    padding: 20px 30px;
    width: 55%;
  }
  #client-request-show-content .content-body .verify .verify-email .col-link {
    float: left;
    display: block;
    width: 30%;
    padding: 40px 20px;
  }
  #client-request-show-content .content-body .verify .verify-email .col-link a {
    padding: 10px 20px;
    border-radius: 2px;
    width: 30%;
    border: 1px solid #ffffff;
    color: #ffffff;
    font-size: 18px;
  }
  #client-request-show-content .content-body .verify .verify-email .col-link a:hover {
    text-decoration: none;
  }
  #client-request-show-content .content-body .verify .verify-phone {
    background-color: #02cd8d;
    color: #ffffff;
  }
  #client-request-show-content .content-body .verify .verify-phone .col-logo {
    float: left;
    display: block;
    padding: 20px 30px;
    width: 15%;
  }
  #client-request-show-content .content-body .verify .verify-phone .col-description {
    float: left;
    display: block;
    padding: 20px 30px;
    width: 55%;
  }
  #client-request-show-content .content-body .verify .verify-phone .col-link {
    float: left;
    display: block;
    width: 30%;
    padding: 40px 20px;
  }
  #client-request-show-content .content-body .verify .verify-phone .col-link a {
    padding: 10px 20px;
    border-radius: 2px;
    width: 30%;
    border: 1px solid #ffffff;
    color: #ffffff;
    font-size: 18px;
  }
  #client-request-show-content .content-body .verify .verify-phone .col-link a:hover {
    text-decoration: none;
  }
  #client-request-show-content .content-body .verify .tab:not(:first-child) {
    margin-top: 15px;
  }
  #client-request-show-content .navigation .link {
    padding: 15px 0;
  }
  #project-details-content {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  #client-request-show-content .content-header {
    padding: 20px 15px 0px;
  }
  #client-request-show-content .content-header .col-sm-6 {
    padding-bottom: 15px;
  }
  #client-request-show-content .content-header .title {
    margin-bottom: 10px;
  }
  #client-request-show-content .content-header .links a {
    font-size: 13px;
  }
  #client-request-show-content .content-header .links a:not(:first-child) {
    margin-right: 0px;
  }
  #client-request-show-content .content-header .links a img {
    margin-right: 5px;
  }
  #client-request-show-content .content-body {
    padding-top: 0px;
  }
  #client-request-show-content .content-body .box-proposal .box-header {
    display: block;
  }
  #client-request-show-content .content-body .box-proposal .box-header .col-badges {
    display: inline-flex;
    left: 100%;
    transform: translateX(-100%);
  }
  #client-request-show-content .content-body .box-proposal .box-header .col-logo {
    width: 100%;
    display: block;
    padding-bottom: 20px;
  }
  #client-request-show-content .content-body .box-proposal .box-header .col-logo .logo-border {
    width: 100px;
    margin: 0 auto;
  }
  #client-request-show-content .content-body .box-proposal .box-header .col-logo .logo-border .logo {
    width: 100px;
  }
  #client-request-show-content .content-body .box-proposal .box-header .col-review {
    float: left;
    display: block;
    width: 30%;
    padding: 5px 10px;
  }
  #client-request-show-content .content-body .box-proposal .box-header .col-review .text {
    right: 4%;
    font-size: 10px;
    top: 100%;
    padding: 5px;
  }
  #client-request-show-content .content-body .box-proposal .box-header .col-info {
    float: left;
    display: block;
    width: 70%;
  }
  #client-request-show-content .content-body .box-proposal .box-header .col-info .inline-row [class^=tag] {
    font-size: 10px;
  }
  #client-request-show-content .content-body .box-proposal .box-header .col-info .inline-row [class^=tag]:not(:first-child) {
    margin-left: 0px;
  }
  #client-request-show-content .content-body .box-proposal .box-header .col-info .inline-row [class^=tag]:before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 15px 15px 0 0;
    border-color: #02cd8d transparent transparent transparent;
    position: absolute;
    left: 100%;
    top: 0;
  }
  #client-request-show-content .content-body .box-proposal .box-header .col-info .inline-row [class^=tag]:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 15px 0 0 15px;
    border-color: transparent transparent transparent #02cd8d;
    position: absolute;
    left: 100%;
    bottom: 0;
  }
  #client-request-show-content .content-body .box-proposal .box-header .col-info .tagline {
    max-width: 150px;
  }
  #client-request-show-content .content-body .box-proposal .box-summary {
    padding: 20px 10px;
  }
  #client-request-show-content .content-body .box-proposal .box-summary > [class^=col] {
    display: initial;
    padding: 0 10px 20px;
  }
  #client-request-show-content .content-body .box-proposal .box-summary .col-summary {
    width: 100%;
    padding: 0;
  }
  #client-request-show-content .content-body .box-proposal .box-summary .col-summary strong {
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }
  #client-request-show-content .content-body .box-proposal .box-summary .col-price {
    width: 100%;
    padding: 0;
  }
  #client-request-show-content .content-body .box-proposal .box-summary .col-price span {
    text-align: left;
    padding-bottom: 15px;
  }
  #client-request-show-content .content-body .box-proposal .box-summary .col-action {
    width: 100%;
  }
  #client-request-show-content .content-body .box-proposal .box-insight {
    border: 1px solid #82cd8d;
    border-top: 1px solid #82cd8d;
  }
  #client-request-show-content .content-body .verify {
    padding-top: 15px;
  }
  #client-request-show-content .content-body .verify .verify-email .col-logo {
    padding: 20px 10px;
    width: 20%;
  }
  #client-request-show-content .content-body .verify .verify-email .col-logo img {
    width: 50px;
    height: 50px;
  }
  #client-request-show-content .content-body .verify .verify-email .col-description {
    padding: 20px 5px;
    width: 40%;
  }
  #client-request-show-content .content-body .verify .verify-email .col-link {
    width: 40%;
    padding: 25px 5px;
  }
  #client-request-show-content .content-body .verify .verify-email .col-link a {
    padding: 10px 5px;
    font-size: 11px;
  }
  #client-request-show-content .content-body .verify .verify-phone .col-logo {
    padding: 20px 10px;
    width: 20%;
  }
  #client-request-show-content .content-body .verify .verify-phone .col-logo img {
    width: 50px;
    height: 50px;
  }
  #client-request-show-content .content-body .verify .verify-phone .col-description {
    padding: 20px 5px;
    width: 40%;
  }
  #client-request-show-content .content-body .verify .verify-phone .col-link {
    width: 40%;
    padding: 30px 10px;
  }
  #client-request-show-content .content-body .verify .verify-phone .col-link a {
    padding: 10px 5px;
    font-size: 11px;
  }
  #client-request-show-content .content-body .tab.tab-coordinator .tab-title {
    font-size: 10px;
  }
  #client-request-show-content .content-body .tab.tab-coordinator .info-row {
    width: 66%;
    font-size: 10px;
  }
  #client-request-show-content .content-body .tab.tab-coordinator .info-row .info-right {
    padding-left: 3px;
  }
  #client-request-show-content .content-body .tab.tab-coordinator .info-row .info-right .info {
    color: #000;
  }
  #client-request-show-content .content-body .tab.tab-coordinator .info-row .info-right .info.info-name {
    font-size: 10px;
  }
  #client-request-show-content .content-body .tab.tab-coordinator .info-row img {
    max-width: 50px;
    max-height: 50px;
  }
  #client-request-show-content .content-body .tab.tab-coordinator .tab-footer {
    width: 34%;
  }
  #client-request-show-content .content-body .tab.tab-coordinator .tab-footer .clearfix {
    padding-top: 40px;
    padding-left: 0px;
  }
  #client-request-show-content .content-body .tab.tab-coordinator .tab-footer .clearfix img {
    width: 30px;
    height: 30px;
    left: 20%;
  }
  #client-request-show-content .content-body .tab.tab-coordinator .tab-footer .clearfix a {
    padding: 0px 4px 0px;
    font-size: 10px;
  }
  #project-details-content .content-header {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 35px;
  }
  #project-details-content .content-header .row [class^=col] {
    padding: 20px 40px 10px 40px;
  }
  #project-details-content .content-header .title {
    font-size: 20px;
  }
  #project-details-content .content-header .legend {
    font-size: 16px;
  }
  #project-details-content .content-body {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
  }
  #project-details-content .content-body span {
    float: none;
  }
  #popup-request-cancel .mfp-close,
  #popup-request-update_info .mfp-close {
    display: none;
  }
}

#client-request-proposal-show-content {
  background-color: #f0f4f7;
  padding-top: 30px;
  padding-bottom: 30px;
}

#client-request-proposal-show-content .content-header {
  border-top: 1px solid #d4f0e3;
  border-left: 1px solid #d4f0e3;
  border-right: 1px solid #d4f0e3;
  background-color: #FFFFFF;
  padding: 25px 30px;
}

#client-request-proposal-show-content .content-header .title {
  color: #333;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
  line-height: 1;
}

#client-request-proposal-show-content .content-header .links a {
  color: #00b37b;
  line-height: 20px;
  font-size: 15px;
  float: right;
  font-weight: bold;
}

#client-request-proposal-show-content .content-header .links a img {
  display: inline-block;
  margin-right: 10px;
}

#client-request-proposal-show-content .content-header .links a:not(:first-child) {
  margin-right: 30px;
}

#client-request-proposal-show-content .navigation {
  background-color: #373f48;
  padding: 0 20px;
}

#client-request-proposal-show-content .navigation .link {
  display: block;
  float: left;
  font-size: 12px;
  color: #FFFFFF;
  padding: 20px 0;
  position: relative;
  cursor: pointer;
  line-height: 15px;
}

#client-request-proposal-show-content .navigation .link img {
  display: inline-block;
  margin-right: 5px;
  vertical-align: bottom;
}

#client-request-proposal-show-content .navigation .link:hover {
  text-decoration: none;
}

#client-request-proposal-show-content .navigation .link:not(:first-child) {
  margin-left: 30px;
}

#client-request-proposal-show-content .navigation .link.current:after {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 3px;
  background-color: #28f2b2;
}

#client-request-proposal-show-content .content-body {
  padding-top: 22px;
}

#client-request-proposal-show-content .content-body > .row {
  margin-left: -11px;
  margin-right: -11px;
}

#client-request-proposal-show-content .content-body > .row [class^=col] {
  padding-left: 11px;
  padding-right: 11px;
}

#client-request-proposal-show-content .content-body > .row [class^=col] .tab-coordinator {
  border: 1px solid #000;
  border-radius: 4px;
  margin-top: 20px;
}

#client-request-proposal-show-content .content-body > .row [class^=col] .tab-coordinator .tab-title {
  padding: 10px;
  font-size: 16px;
}

#client-request-proposal-show-content .content-body > .row [class^=col] .tab-coordinator .info-row {
  padding: 0 10px 10px;
}

#client-request-proposal-show-content .content-body > .row [class^=col] .tab-coordinator .info-row img {
  display: inline-block;
  vertical-align: top;
}

#client-request-proposal-show-content .content-body > .row [class^=col] .tab-coordinator .info-row .info-right {
  display: inline-block;
  padding-left: 10px;
  width: 60%;
}

#client-request-proposal-show-content .content-body > .row [class^=col] .tab-coordinator .info-row .info-right .info {
  color: #000;
}

#client-request-proposal-show-content .content-body > .row [class^=col] .tab-coordinator .info-row .info-right .info.info-name {
  font-size: 16px;
  font-weight: bold;
}

#client-request-proposal-show-content .content-body > .row [class^=col] .tab-coordinator .info-row .info-right .info.info-tel {
  font-style: italic;
}

#client-request-proposal-show-content .content-body > .row [class^=col] .tab-coordinator .tab-footer {
  border-top: 1px solid #000;
  text-align: center;
  position: relative;
}

#client-request-proposal-show-content .content-body > .row [class^=col] .tab-coordinator .tab-footer img {
  position: absolute;
  left: 10%;
  top: 25%;
}

#client-request-proposal-show-content .content-body > .row [class^=col] .tab-coordinator .tab-footer a {
  display: block;
  color: #02cd8d;
  padding: 10px;
  font-size: 16px;
  text-decoration: none;
}

#client-request-proposal-show-content .content-body .tab.tab-coordinator {
  border: 1px solid #000;
  border-radius: 4px;
}

#client-request-proposal-show-content .content-body .tab.tab-coordinator .tab-title {
  padding: 10px;
  font-size: 16px;
}

#client-request-proposal-show-content .content-body .tab.tab-coordinator .info-row {
  padding: 0 10px 10px;
}

#client-request-proposal-show-content .content-body .tab.tab-coordinator .info-row img {
  display: inline-block;
  vertical-align: top;
}

#client-request-proposal-show-content .content-body .tab.tab-coordinator .info-row .info-right {
  display: inline-block;
  padding-left: 10px;
  width: 65%;
}

#client-request-proposal-show-content .content-body .tab.tab-coordinator .info-row .info-right .info {
  color: #000;
}

#client-request-proposal-show-content .content-body .tab.tab-coordinator .info-row .info-right .info.info-name {
  font-size: 16px;
  font-weight: bold;
}

#client-request-proposal-show-content .content-body .tab.tab-coordinator .info-row .info-right .info.info-tel {
  font-style: italic;
}

#client-request-proposal-show-content .content-body .tab.tab-coordinator .tab-footer {
  border-top: 1px solid #000;
  text-align: center;
}

#client-request-proposal-show-content .content-body .tab.tab-coordinator .tab-footer a {
  display: block;
  color: #02cd8d;
  padding: 10px;
  font-size: 16px;
  text-decoration: none;
}

#client-request-proposal-show-content .content-body .tab.tab-guide {
  border: 1px solid #000000;
  border-radius: 3px;
  padding: 10px 15px;
}

#client-request-proposal-show-content .content-body .tab.tab-guide .legend {
  color: #000;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0;
}

#client-request-proposal-show-content .content-body .tab.tab-guide ul.checks {
  list-style: none;
  padding: 0;
  margin: 10px 0 25px 0;
}

#client-request-proposal-show-content .content-body .tab.tab-guide ul.checks li {
  padding-left: 35px;
  position: relative;
  color: #000;
  font-size: 14px;
  font-weight: bold;
}

#client-request-proposal-show-content .content-body .tab.tab-guide ul.checks li:before {
  content: '';
  display: block;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 2px;
  background-color: #dddddd;
}

#client-request-proposal-show-content .content-body .tab.tab-guide ul.checks li:not(:first-child) {
  margin-top: 10px;
}

#client-request-proposal-show-content .content-body .tab.tab-guide ul.links {
  list-style: none;
  margiN: 10px 0 0 0;
  padding: 0;
}

#client-request-proposal-show-content .content-body .tab.tab-guide ul.links li a {
  display: block;
  margin-bottom: 3px;
  color: #02cd8d;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
}

#client-request-proposal-show-content .content-body .tab:not(:first-child) {
  margin-top: 22px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile {
  background-color: #FFFFFF;
  box-shadow: 5px 0px 8px 1px #c3c3c3;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header {
  background-color: #f7f7f9;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box {
  height: 280px;
  padding-bottom: 20px;
  background-size: cover;
  background-position: center center;
  position: relative;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row {
  display: table;
  position: absolute;
  bottom: 20px;
  left: 0;
  width: 100%;
  z-index: 2;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row [class^=col] {
  padding-left: 15px;
  padding-right: 15px;
  display: table-cell;
  vertical-align: bottom;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row [class^=col]:first-child {
  padding-left: 25px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row [class^=col]:last-child {
  padding-right: 45px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row .col-logo {
  width: 120px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row .col-logo .img-wrapper {
  border: 5px solid #FFFFFF;
  border-radius: 4px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row .col-logo .img-wrapper img {
  max-width: 100%;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row .col-info .name {
  font-size: 24px;
  font-family: "Roboto", sans-serif;
  color: #FFFFFF;
  font-weight: bold;
  margin: 0 0 15px;
  letter-spacing: 1px;
  display: block;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row .col-info .name a {
  color: white;
  text-decoration: none;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row .col-info .info {
  display: block;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row .col-info .info .tag {
  display: inline-block;
  background-color: #02cd8d;
  color: #FFFFFF;
  font-size: 14px;
  padding: 5px 10px;
  position: relative;
  border-radius: 2px 0 0 2px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row .col-info .info .tag:not(:first-child) {
  margin-left: 30px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row .col-info .info .tag:before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 15px 0 0;
  border-color: #02cd8d transparent transparent transparent;
  position: absolute;
  left: 100%;
  top: 0;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row .col-info .info .tag:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 0 0 15px;
  border-color: transparent transparent transparent #02cd8d;
  position: absolute;
  left: 100%;
  bottom: 0;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row .col-contact {
  width: 280px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row .col-contact .with-icon {
  color: #fff;
  white-space: nowrap;
  font-size: 13px;
  line-height: 16px;
  padding-left: 30px;
  position: relative;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row .col-contact .with-icon:not(:first-child) {
  margin-top: 10px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row .col-contact .with-icon .fa {
  display: inline-block;
  margin-right: 15px;
  font-size: 16px;
  position: absolute;
  left: 0;
  top: 0;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row .col-contact .with-icon a {
  color: #fff;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row .col-review {
  width: 145px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row .col-review .circles-text {
  font-size: 16px !important;
  color: #fff !important;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row .col-review .text {
  margin-top: 10px;
  color: #02cd8d;
  font-size: 14px;
  font-weight: bold;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row-responsive {
  display: table;
  position: absolute;
  bottom: 20px;
  left: 0;
  width: 100%;
  z-index: 2;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row-responsive [class^=col] {
  padding-left: 15px;
  padding-right: 15px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row-responsive .col-logo {
  width: 120px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row-responsive .col-logo .img-wrapper {
  border: 5px solid #FFFFFF;
  border-radius: 4px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row-responsive .col-logo .img-wrapper img {
  max-width: 100%;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row-responsive .name {
  padding-left: 15px;
  padding-right: 15px;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  color: #FFFFFF;
  font-weight: bold;
  margin: 0 0 15px;
  letter-spacing: 1px;
  display: block;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row-responsive .name a {
  color: white;
  text-decoration: none;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row-responsive .col-info .info {
  display: block;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row-responsive .col-info .info .tag {
  display: inline-block;
  background-color: #02cd8d;
  color: #FFFFFF;
  font-size: 14px;
  padding: 5px 10px;
  position: relative;
  border-radius: 2px 0 0 2px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row-responsive .col-info .info .tag:not(:first-child) {
  margin-left: 30px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row-responsive .col-info .info .tag:before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 15px 0 0;
  border-color: #02cd8d transparent transparent transparent;
  position: absolute;
  left: 100%;
  top: 0;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row-responsive .col-info .info .tag:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 0 0 15px;
  border-color: transparent transparent transparent #02cd8d;
  position: absolute;
  left: 100%;
  bottom: 0;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row-responsive .col-contact {
  width: 280px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row-responsive .col-contact .with-icon {
  color: #fff;
  white-space: nowrap;
  font-size: 13px;
  line-height: 16px;
  padding-left: 30px;
  position: relative;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row-responsive .col-contact .with-icon:not(:first-child) {
  margin-top: 10px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row-responsive .col-contact .with-icon .fa {
  display: inline-block;
  margin-right: 15px;
  font-size: 16px;
  position: absolute;
  left: 0;
  top: 0;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row-responsive .col-contact .with-icon a {
  color: #fff;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row-responsive .col-review {
  width: 145px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row-responsive .col-review .circles-text {
  font-size: 16px !important;
  color: #fff !important;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row-responsive .col-review .text {
  margin-top: 10px;
  color: #02cd8d;
  font-size: 14px;
  font-weight: bold;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row-responsive .col-match-score {
  padding-left: 25px;
  vertical-align: top;
  display: table-cell;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row-responsive .col-match-score .result {
  background-color: #28ac78;
  display: inline-block;
  padding: 5px 10px;
  font-size: 16px;
  color: #fff;
  border-radius: 3px;
  margin-right: 5px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row-responsive .col-match-score .result .symbol {
  color: #91d4ba;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row-responsive .col-match-score p {
  display: inline-block;
  color: #28ac78;
  font-weight: bolder;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box:after {
  content: '';
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .tab-header {
  background-color: #f3f3f3;
  padding: 0 30px 0 0;
  position: relative;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .tab-header .tab {
  text-decoration: none;
  padding: 12px 10px 14px;
  color: #02cd8d;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  border-bottom: 3px solid transparent;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 3px solid transparent;
  float: left;
  margin: 0 0 0 !important;
  letter-spacing: 1px;
  cursor: pointer;
  background-color: transparent;
  position: relative;
  font-weight: bold;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .tab-header .tab.opened {
  border-bottom: 3px solid #ffffff;
  color: #18293e;
  background-color: white;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .tab-header .tab:hover {
  color: #ffffff;
  background-color: #02cd8d;
  transition: all 0.3s ease-out;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .tab-header .match-score {
  float: right;
  padding: 10px 0 5px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .tab-header .match-score .result {
  background-color: #28ac78;
  display: inline-block;
  padding: 5px 10px;
  font-size: 16px;
  color: #fff;
  border-radius: 3px;
  margin-right: 5px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .tab-header .match-score .result .symbol {
  color: #91d4ba;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .tab-header .match-score p {
  display: inline-block;
  color: #28ac78;
  font-weight: bolder;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body {
  padding: 15px 20px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .container {
  display: table;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .container > .row {
  margin: 0;
  display: table-row;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .container > .row > [class^=col] {
  display: table-cell;
  vertical-align: top;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .container .col-left {
  padding-right: 25px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .container .col-right {
  padding-left: 25px;
  width: 295px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .header-container {
  display: table;
  margin-bottom: 10px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .header-container .row {
  margin: 0;
  display: table-row;
  color: #333333;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: bold;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .header-container .row > [class^=col] {
  display: table-cell;
  vertical-align: top;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .header-container .col-left {
  padding-right: 25px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .header-container .col-right {
  padding-left: 25px;
  width: 295px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .header-container .name {
  display: inline-block;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .header-container .cases {
  margin-left: 15px;
  display: inline-block;
  font-weight: normal;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .header-container .cases span {
  display: inline-block;
  color: #444444;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  padding: 2px 6px;
  border: 1px solid #444444;
  border-radius: 2px;
  position: relative;
  top: -2px;
  margin-right: 5px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .header-container select {
  margin: 0 15px;
  display: inline-block;
  border: 1px solid #d4f0e3;
  background-color: #FFFFFF;
  padding: 4px 60px 4px 10px;
  position: relative;
  top: -4px;
  font-weight: normal;
  outline: 0;
  cursor: pointer;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .header-container button {
  letter-spacing: 0;
  color: #FFFFFF;
  border-bottom: 3px solid #0d513b;
  padding: 8px 15px;
  position: relative;
  top: -6px;
  font-size: 14px;
  line-height: normal;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box {
  background-color: #FFFFFF;
  border: 1px solid #f0f4f7;
  border-radius: 5px;
  margin-bottom: 20px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-heading {
  padding: 20px 25px;
  background-color: #f0f4f7;
  border-bottom: 1px solid #f0f4f7;
  color: #333333;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: bold;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-heading img {
  display: inline-block;
  margin-left: 10px;
  height: 20px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-heading .right {
  color: #aaaaaa;
  font-size: 12px;
  float: right;
  font-weight: normal;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body {
  padding: 20px 25px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .info-row {
  margin-bottom: 15px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .info-row .info-label {
  display: inline-block;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  color: #737373;
  font-size: 14px;
  line-height: normal;
  width: 80px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .info-row .info-value {
  display: inline-block;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  color: #737373;
  font-size: 14px;
  line-height: normal;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .paragraph {
  line-height: 1.5;
  color: #3d3d3d;
  font-size: 13px;
  padding-bottom: 30px;
  position: relative;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .paragraph p {
  margin-bottom: 10px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .paragraph .showMore {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #029470;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  cursor: pointer;
  transition: all .3s;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .paragraph .showMore i {
  display: inline-block;
  margin-right: 5px;
  font-style: normal;
  font-size: 14px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .paragraph .showMore:hover {
  font-weight: bold;
  font-size: 14px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .paragraph .showMore:hover i {
  transition: all .3s;
  font-weight: bold;
  font-size: 16px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .link-box {
  display: block;
  border: 1px solid #585858;
  height: 40px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  color: #595959;
  font-size: 14px;
  font-weight: bold;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .link-box:hover {
  text-decoration: none;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .col-item {
  border-radius: 5px;
  border: 1px solid #c7ffe4;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .col-item .name {
  border-bottom: 1px solid #c7ffe4;
  color: #444444;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  padding: 15px 10px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .col-item .paragraph {
  padding: 15px 10px 55px 10px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .col-item .paragraph .showMore {
  left: 10px;
  bottom: 25px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .col-member {
  border: 1px solid #f0f4f7;
  padding: 15px 0;
  border-radius: 2px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .col-member .member-row {
  display: table;
  width: 100%;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .col-member .member-row .col-img {
  display: table-cell;
  width: 123px;
  padding-left: 50px;
  padding-right: 23px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .col-member .member-row .col-img img {
  max-width: 50px;
  max-height: 50px;
  border-radius: 50%;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .col-member .member-row .col-info {
  display: table-cell;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .col-member .member-row .col-info .name {
  color: #414141;
  font-family: "Roboto", sans-serif;
  margin-bottom: 5px;
  font-size: 16px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .col-member .member-row .col-info .position {
  font-size: 14px;
  color: #313131;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .col-member .histories {
  margin-top: 20px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .col-member .histories .history {
  padding-left: 50px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .col-member .histories .history .img {
  width: 16px;
  display: inline-block;
  margin-right: 10px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .col-member .histories .history .img img {
  max-height: 16px;
  max-width: 16px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .col-member .histories .history .history-name {
  display: inline-block;
  color: #868686;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .tabs .tab-header {
  margin-right: -10px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .tabs .tab-header .tab {
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
  background-color: #505961;
  border: 1px solid #505961;
  color: #FFFFFF;
  border-radius: 2px;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color .3s, border .3s, color .3s;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .tabs .tab-header .tab.opened {
  background-color: #FFFFFF;
  color: #02cd8d;
  border: 1px solid #02cd8d;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .tabs .tab-header .tab:hover:not(.opened) {
  background-color: #737373;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .tabs .tab-body {
  padding-top: 15px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .tabs .tab-body .tab {
  display: none;
  visibility: hidden;
  opacity: 0;
  transition: opacity .3s;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .tabs .tab-body .tab .title {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  color: #737373;
  font-size: 14px;
  line-height: normal;
  margin-bottom: 15px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .tabs .tab-body .tab .paragraph {
  padding-bottom: 0;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .tabs .tab-body .tab.opened {
  opacity: 1;
  visibility: visible;
  display: block;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .table-info {
  padding: 25px 0;
  margin-bottom: 25px;
  border: 1px solid #d8f1e6;
  display: table;
  width: 100%;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .table-info .table-row {
  display: table-row;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .table-info .table-row > [class^=col] {
  display: table-cell;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  color: #333333;
  vertical-align: top;
  width: 308px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .table-info .table-row > [class^=col].col-left .row {
  display: block;
  margin-left: -15px;
  margin-right: -15px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .table-info .table-row > [class^=col].col-left .black {
  margin-bottom: 20px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .table-info .table-row > [class^=col].col-left .with-icon {
  padding-top: 5px;
  padding-bottom: 5px;
  min-height: 30px;
  padding-left: 25px;
  position: relative;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .table-info .table-row > [class^=col].col-left .with-icon .icon {
  position: absolute;
  left: 0;
  top: 5px;
  width: 20px;
  height: 20px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .table-info .table-row > [class^=col].col-left .with-icon:not(:first-child) {
  margin-top: 5px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .table-info .table-row > [class^=col].col-left .with-icon .more {
  float: right;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .table-info .table-row > [class^=col].col-left .with-icon .tooltip_content {
  display: none;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .table-info .table-row > [class^=col].col-left .tag {
  border-radius: 2px;
  background-color: #444444;
  padding: 7px 14px 7px 35px;
  color: #FFFFFF;
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
  display: inline-block;
  position: relative;
  cursor: context-menu;
  margin-top: 10px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .table-info .table-row > [class^=col].col-left .tag:before {
  content: '';
  width: 14px;
  height: 8px;
  border-left: 2px solid #FFFFFF;
  border-bottom: 2px solid #FFFFFF;
  transform: rotate(-45deg);
  position: absolute;
  left: 10px;
  bottom: 16px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .table-info .table-row > [class^=col].col-middle {
  width: 262px;
  border-left: 1px solid #d4f0e3;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .table-info .table-row > [class^=col].col-middle .info-row:after {
  content: '';
  display: block;
  clear: both;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .table-info .table-row > [class^=col].col-middle .info-row .info-label {
  float: left;
  width: 122px;
  font-size: 11px;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  color: #333333;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .table-info .table-row > [class^=col].col-middle .info-row .info-value {
  float: left;
  position: relative;
  background-color: #dbdbdb;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .table-info .table-row > [class^=col].col-middle .info-row .info-value:after {
  content: '';
  display: block;
  clear: both;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .table-info .table-row > [class^=col].col-middle .info-row .info-value .value-column {
  width: 18px;
  height: 17px;
  background-color: transparent;
  display: inline-block;
  float: left;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .table-info .table-row > [class^=col].col-middle .info-row .info-value .value-column:not(:first-child) {
  border-left: 1px solid #FFFFFF;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .table-info .table-row > [class^=col].col-middle .info-row .info-value .value {
  height: 17px;
  background-color: #02cd8d;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .table-info .table-row > [class^=col].col-right {
  width: 150px;
  border-left: 1px solid #d4f0e3;
  text-align: center;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .table-info .table-row > [class^=col].col-right .circles-text {
  font-size: 24px !important;
  color: #888888 !important;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .table-info .table-row > [class^=col].col-right .circles-text b {
  color: #444444 !important;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .table-info .table-row > [class^=col] .black {
  font-size: 12px;
  font-weight: bold;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .summary {
  word-break: break-all;
  -ms-word-break: break-all;
  word-break: break-all;
  /* Non standard for WebKit */
  word-break: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  /*.title {
                margin-bottom: 15px;
              }
              .blue {
                color: $color-22;
                font-weight: 700;
                font-family: $font-1;
                font-size: 14px;
              }
              .black {
                color: $color-19;
                font-weight: 700;
                font-family: $font-1;
                font-size: 14px;
              }
              .paragraph {
                font-size: 13px;
                font-family: $font-1;
                color: $color-20;
                line-height: 1.5;
                font-weight: 700;
                padding-bottom: 20px;
              }
              .feedback {
                margin: 0 0 20px;
                border: 1px solid $color-16;
                -webkit-border-radius: 2px;
                -moz-border-radius: 2px;
                border-radius: 2px;
                padding: 15px 50px;
                background-color: $color-27;
                position: relative;
                &:before {
                  content: '“';
                  position: absolute;
                  top: -10px;
                  left: 10px;
                  font-size: 70px;
                  color: $color-29;
                  font-weight: bold;
                }
                &:after {
                  content: '”';
                  position: absolute;
                  bottom: -40px;
                  right: 10px;
                  font-size: 70px;
                  color: $color-29;
                  font-weight: bold;
                }
                .title {
                  color: $color-28;
                  margin-bottom: 15px;
                  font-weight: 700;
                  font-family: $font-1;
                  font-size: 14px;
                  font-style: italic;
                }
                .paragraph {
                  color: $color-2;
                  line-height: 1.5;
                  font-size: 13px;
                  font-family: $font-1;
                  font-weight: 700;
                  font-style: italic;
                }
              }*/
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .summary.active .content.content-hidden {
  display: block;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .summary .showMore {
  margin: 10px auto;
  padding: 10px;
  cursor: pointer;
  color: #02cd8d;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  letter-spacing: 1px;
  display: block;
  width: 220px;
  text-align: center;
  position: relative;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .summary .showMore:after {
  content: '';
  background-color: #02cd8d;
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  transition: width .3s;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .summary .showMore:hover:after {
  width: 100%;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .review-content .content {
  margin-bottom: 20px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .review-content .content > h4 {
  font-weight: bold;
  color: #3f92f1;
  font-size: 16px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .review-content .content b {
  font-size: 14px;
  color: #000;
  font-weight: bold;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .review-content .content blockquote {
  margin: 0 0 20px;
  border: 1px solid #d4f0e3;
  border-radius: 2px;
  padding: 15px 50px;
  background-color: #f0f4f7;
  position: relative;
  color: #888888;
  line-height: 1.5;
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: italic;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .review-content .content blockquote:before {
  content: '“';
  position: absolute;
  top: -10px;
  left: 10px;
  font-size: 70px;
  color: #d1d8de;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .review-content .content blockquote:after {
  font-family: "Roboto", sans-serif;
  content: '”';
  position: absolute;
  bottom: -40px;
  right: 10px;
  font-size: 70px;
  color: #d1d8de;
  font-weight: bold;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .review-content .content blockquote h4 {
  color: #5a5a5a;
  margin-bottom: 15px;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: italic;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .review-content .content.content-hidden {
  display: none;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.about .col {
  display: inline-block;
  margin-top: 15px;
  padding-left: 30px;
  padding-right: 30px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.about .col .value {
  color: #02cd8d;
  font-size: 36px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.about .col .label {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  color: #494949;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.about .col .label .fa {
  font-size: 15px;
  display: inline-block;
  margin-left: 3px;
  color: #d9d9d9;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.box-green {
  border: 1px solid #c7ffe4;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.box-green .box-heading {
  background-color: #c7ffe4;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.box-positioning .positions .position {
  position: relative;
  padding-top: 30px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.box-positioning .positions .position .label {
  color: #3f3f3f;
  position: absolute;
  font-size: 14px;
  top: 0;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.box-positioning .positions .position .label.label-left {
  left: 0;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.box-positioning .positions .position .label.label-right {
  right: 0;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.box-positioning .positions .position .bar {
  position: relative;
  width: 100%;
  height: 10px;
  margin-bottom: 13px;
  background-color: #dddddd;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.box-positioning .positions .position .bar .pointer {
  width: 20px;
  height: 20px;
  position: absolute;
  top: -5px;
  background-color: #02cd8d;
  border-radius: 50%;
  transform: translateX(-50%);
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.box-positioning .positions .position:not(:first-child) {
  margin-top: 5px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.box-qualification .data:not(:first-child) {
  margin-top: 5px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.box-qualification .data a {
  border-radius: 2px;
  background: #505961;
  display: inline-block;
  padding: 5px 10px;
  margin-bottom: 10px;
  color: #d9dbdc;
  font-size: 12px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.box-qualification .data a:hover {
  text-decoration: none;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.box-qualification .data .collapse {
  padding-bottom: 15px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.box-portfolio .portfolio {
  box-shadow: 0 0px 2px 1px #edf1f3;
  margin-top: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  overflow: hidden;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.box-portfolio .portfolio .content {
  padding: 0 15px 15px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.box-portfolio .portfolio .content .title {
  color: #444444;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
  line-height: 18px;
  letter-spacing: 0;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.box-portfolio .portfolio .content .services {
  margin-left: -4px;
  margin-top: 4px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.box-portfolio .portfolio .content .services .service {
  margin-left: 4px;
  margin-bottom: 4px;
  background-color: #505961;
  display: inline-block;
  padding: 3px 8px;
  border-radius: 2px;
  color: rgba(255, 255, 255, 0.75);
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.box-portfolio .portfolio .content .divider {
  height: 1px;
  background-color: #edf1f3;
  margin-top: 10px;
  margin-bottom: 10px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.box-portfolio .portfolio .content .tags {
  color: #8b8b8b;
  font-size: 12px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.box-portfolio .portfolio .content .description {
  color: #737373;
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  line-height: 1.3;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.box-qualification .data:not(:first-child) {
  margin-top: 5px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.box-qualification .data a {
  border-radius: 2px;
  background: #505961;
  display: inline-block;
  padding: 5px 10px;
  margin-bottom: 10px;
  color: #d9dbdc;
  font-size: 12px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.box-qualification .data a:hover {
  text-decoration: none;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.box-qualification .data .collapse {
  padding-bottom: 15px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.box-service .tab-header {
  margin-left: -4px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.box-service .tab-header a {
  margin-left: 4px;
  margin-bottom: 4px;
  background-color: #505961;
  border: 1px solid #505961;
  display: inline-block;
  padding: 3px 8px;
  border-radius: 2px;
  color: #ffffff;
  text-decoration: none;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.box-service .tab-header a.opened {
  background-color: #fff;
  border: 1px solid #02cd8d;
  color: #02cd8d;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.box-service .tab-content .service-content {
  display: none;
  padding-top: 20px;
  position: relative;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.box-service .tab-content .service-content .col-paragraph {
  position: relative;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.box-service .tab-content .service-content.opened {
  display: block;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.box-service .tab-content .chart-wrapper {
  position: absolute;
  right: 0;
  top: 20px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.box-service .tab-content .chart-wrapper .chart-legends {
  margin: 0 auto -50px;
  position: relative;
  z-index: 1;
  max-width: 380px;
  padding-bottom: 50px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.box-service .tab-content .chart-wrapper .chart-legends .chart-legend {
  padding-left: 5px;
  padding-right: 5px;
  float: left;
  color: #565656;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.box-service .tab-content .chart-wrapper .chart-legends .chart-legend span {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin-right: 10px;
  display: inline-block;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.box-skill .skills {
  margin-left: -4px;
  margin-top: 4px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.box-skill .skills .skill {
  margin-left: 4px;
  margin-bottom: 4px;
  background-color: #505961;
  display: inline-block;
  padding: 3px 8px;
  border-radius: 2px;
  color: #ffffff;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.service .box-body .result {
  padding-bottom: 30px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.service .box-body .result .image {
  text-align: center;
  margin-top: 10px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.service .box-body .result .header {
  font-size: 14px;
  padding-bottom: 15px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.service .box-body .result .content {
  display: inline-block;
  background-color: #505961;
  color: #e9eaeb;
  padding: 5px 0px;
  margin-right: 10px;
  border-radius: 3px;
  min-width: 100px;
  text-align: center;
  margin-bottom: 10px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.service .box-body .result .content span {
  padding: 0px 15px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.service .box-body .result .point {
  display: inline-block;
  width: 335px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.service .box-body .result .point span {
  padding-left: 10px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.service .box-body .result .point .circle-green {
  display: inline-block;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: #02cd8d;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.service .box-body .result .point .circle-black {
  display: inline-block;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: #373f48;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.green {
  background-color: #d8f1e6;
  border: 1px solid #FFFFFF;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.green .box-heading {
  border-bottom: 1px solid #FFFFFF;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.green .box-body {
  padding-left: 50px;
  padding-right: 50px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.green .box-body .paragraph {
  padding-bottom: 0;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.box-focus {
  margin-bottom: 20px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.box-focus .chart-title {
  color: #737373;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  text-align: center;
  font-weight: bold;
  z-index: 2;
  position: relative;
  margin-bottom: -40px;
  padding-bottom: 30px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.box-focus .chart-legends {
  margin: -50px auto 0;
  position: relative;
  z-index: 1;
  max-width: 380px;
  padding-bottom: 50px;
  padding-top: 50px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.box-focus .chart-legends .chart-legend {
  padding-left: 5px;
  padding-right: 5px;
  float: left;
  color: #565656;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.box-focus .chart-legends .chart-legend span {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin-right: 10px;
  display: inline-block;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.box-faq .panel-group {
  margin: 0;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.box-faq .panel-group .panel {
  box-shadow: none;
  border-radius: 0;
  border: none;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.box-faq .panel-group .panel .panel-heading {
  background: none;
  border: none;
  color: #02cd8d;
  padding: 0;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.box-faq .panel-group .panel .panel-heading h4 {
  font-size: 16px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.box-faq .panel-group .panel .panel-heading h4 a {
  padding-top: 10px;
  padding-bottom: 10px;
  display: inline-block;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.box-faq .panel-group .panel .panel-heading h4 a:hover, #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.box-faq .panel-group .panel .panel-heading h4 a:focus {
  text-decoration: none;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.box-faq .panel-group .panel .panel-heading + .panel-collapse > .panel-body {
  border-top: 0;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.box-faq .panel-group .panel .panel-body {
  padding: 10px 0 10px;
  line-height: 1.5;
  font-family: "Roboto", sans-serif;
  color: #737373;
  font-size: 13px;
  position: relative;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.box-faq .panel-group .panel .panel-body p {
  margin-bottom: 10px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.box-faq .panel-group .panel:not(:first-child) {
  border-top: 1px solid #d8f1e6;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box button {
  width: 100%;
  text-transform: uppercase;
  border-bottom: 6px solid #235a9a;
  letter-spacing: 1px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.colorful {
  position: relative;
  background-image: linear-gradient(to bottom right, #03cd8e, #7b93ba, #c86dd7);
  padding: 15px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.colorful .bg-white {
  background-color: #FFFFFF;
  padding: 20px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.colorful .bg-white span {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  letter-spacing: 1px;
  color: #02cd8d;
  margin-bottom: 10px;
  display: block;
  font-weight: bold;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.colorful .bg-white ul {
  display: block;
  padding: 0;
  margin: 0 0 15px;
  list-style: none;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.colorful .bg-white ul li {
  padding-top: 5px;
  padding-bottom: 5px;
  color: #888888;
  font-weight: 400;
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  letter-spacing: 1px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.colorful .bg-white ul li img {
  vertical-align: middle;
  display: inline-block;
  margin-right: 10px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.colorful button {
  width: 100%;
  text-transform: uppercase;
  border-bottom: 6px solid #178462;
  letter-spacing: 1px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box:not(:first-child) {
  margin-top: 20px;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box:not(:first-child) .more {
  float: right;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box:not(:first-child) .tooltip_content {
  display: none;
}

#client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .title {
  font-weight: normal;
}

#client-request-proposal-show-content .content-body .box-black {
  background-color: #373f48;
  color: #ffffff;
  box-shadow: 5px 0px 8px 1px #c3c3c3;
}

#client-request-proposal-show-content .content-body .box-black .match-score {
  padding: 30px;
  text-align: center;
  font-size: 15px;
}

#client-request-proposal-show-content .content-body .box-black .match-score .result {
  background-color: #28ac78;
  display: inline-block;
  border-radius: 3px;
  padding: 3px 10px;
  font-size: 20px;
}

#client-request-proposal-show-content .content-body .box-black .match-score .result .symbol {
  color: #91d4ba;
}

#client-request-proposal-show-content .content-body .box-black .match-score p {
  display: inline-block;
  color: #28ac78;
}

#client-request-proposal-show-content .content-body .important {
  padding-top: 25px;
  width: 800px;
  font-size: 15px;
}

#client-request-proposal-show-content .content-body .match-title {
  font-size: 20px;
  padding-left: 20px;
}

#client-request-proposal-show-content .content-body .box {
  background-color: #FFFFFF;
}

#client-request-proposal-show-content .content-body .box.box-proposal-list .box-title {
  padding: 15px 20px;
  font-size: 18px;
}

#client-request-proposal-show-content .content-body .box.box-proposal-list .box-body .item {
  text-decoration: none;
  padding: 10px 0px 10px 10px;
  vertical-align: top;
  display: table;
  width: 100%;
}

#client-request-proposal-show-content .content-body .box.box-proposal-list .box-body .item.selected {
  background-color: #313840;
}

#client-request-proposal-show-content .content-body .box.box-proposal-list .box-body .item.selected .col-right .title, #client-request-proposal-show-content .content-body .box.box-proposal-list .box-body .item.selected .col-right .tagline {
  color: #FFFFFF;
}

#client-request-proposal-show-content .content-body .box.box-proposal-list .box-body .item.selected .col-right .col-right-bottom .col-review [id^="rating-circle-"] .circles-text {
  color: #fff !important;
}

#client-request-proposal-show-content .content-body .box.box-proposal-list .box-body .item .col-logo {
  display: table-cell;
  width: 40px;
  vertical-align: top;
  padding: 0;
  position: relative;
}

#client-request-proposal-show-content .content-body .box.box-proposal-list .box-body .item .col-logo .logo-wrapper {
  padding: 4px;
  background: #ccd9e0;
  width: 40px;
  height: 40px;
  position: relative;
  border-radius: 4px;
}

#client-request-proposal-show-content .content-body .box.box-proposal-list .box-body .item .col-logo .logo-wrapper img {
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#client-request-proposal-show-content .content-body .box.box-proposal-list .box-body .item .col-logo .declined {
  position: absolute;
  top: 50%;
  display: block;
  left: -5px;
  right: -5px;
  transform: translateY(-50%);
  background-color: #F0F0F0;
  z-index: 2;
  line-height: 25px;
  height: 25px;
  font-size: 10px;
  text-align: center;
  color: #646464;
}

#client-request-proposal-show-content .content-body .box.box-proposal-list .box-body .item .col-right {
  padding-left: 10px;
  vertical-align: top;
  display: table-cell;
}

#client-request-proposal-show-content .content-body .box.box-proposal-list .box-body .item .col-right .title {
  font-size: 14px;
  margin-bottom: 0;
}

#client-request-proposal-show-content .content-body .box.box-proposal-list .box-body .item .col-right .tagline {
  font-size: 12px;
}

#client-request-proposal-show-content .content-body .box.box-proposal-list .box-body .item .col-right .col-right-bottom {
  display: table;
  width: 100%;
  position: relative;
}

#client-request-proposal-show-content .content-body .box.box-proposal-list .box-body .item .col-right .col-right-bottom .match-score {
  vertical-align: middle;
  display: table-cell;
  padding: 11px 0 5px;
}

#client-request-proposal-show-content .content-body .box.box-proposal-list .box-body .item .col-right .col-right-bottom .match-score .result {
  background-color: #28ac78;
  display: block;
  padding: 5px 10px;
  font-size: 16px;
  color: #fff;
  border-radius: 3px;
  margin-right: 5px;
}

#client-request-proposal-show-content .content-body .box.box-proposal-list .box-body .item .col-right .col-right-bottom .match-score .result .percentage {
  color: #fff;
}

#client-request-proposal-show-content .content-body .box.box-proposal-list .box-body .item .col-right .col-right-bottom .match-score .result .symbol {
  color: #91d4ba;
}

#client-request-proposal-show-content .content-body .box.box-proposal-list .box-body .item .col-right .col-right-bottom .match-score .result span {
  display: inline-block;
}

#client-request-proposal-show-content .content-body .box.box-proposal-list .box-body .item .col-right .col-right-bottom .match-score span {
  display: block;
  color: #28ac78;
  font-weight: normal;
}

#client-request-proposal-show-content .content-body .box.box-proposal-list .box-body .item .col-right .col-right-bottom .col-review {
  padding-right: 0;
  white-space: nowrap;
  vertical-align: middle;
  display: table-cell;
  width: auto;
}

#client-request-proposal-show-content .content-body .box.box-proposal-list .box-body .item .col-right .col-right-bottom .col-review [id^=rating-circle-] {
  display: inline-block;
  float: none;
  vertical-align: middle;
}

#client-request-proposal-show-content .content-body .box.box-proposal-list .box-body .item .col-right .col-right-bottom .col-review [id^=rating-circle-] .circles-text {
  font-size: 14px !important;
  color: #02cd8d !important;
  letter-spacing: 1px !important;
}

#client-request-proposal-show-content .content-body .box.box-proposal-list .box-body .item .col-right .col-right-bottom .col-review .text {
  padding-left: 4px;
  vertical-align: middle;
  float: none;
  display: inline-block;
  color: #02cd8d;
  font-size: 11px;
  font-weight: normal;
}

#client-request-proposal-show-content .content-body .box.box-proposal-list .box-body .item .col-match-score {
  padding-left: 25px;
  vertical-align: top;
  display: table-cell;
}

#client-request-proposal-show-content .content-body .box.box-proposal-list .box-body .item .col-match-score .result {
  background-color: #28ac78;
  display: inline-block;
  padding: 5px 10px;
  font-size: 16px;
  color: #fff;
  border-radius: 3px;
  margin-right: 5px;
}

#client-request-proposal-show-content .content-body .box.box-proposal-list .box-body .item .col-match-score .result .symbol {
  color: #91d4ba;
}

#client-request-proposal-show-content .content-body .box.box-proposal-list .box-body .item .col-match-score p {
  display: inline-block;
  color: #28ac78;
  font-weight: bolder;
}

#client-request-proposal-show-content .content-body .box.box-proposal-list .box-body .item .col-review {
  padding-left: 25px;
  vertical-align: top;
  display: table-cell;
}

#client-request-proposal-show-content .content-body .box.box-proposal-list .box-body .item .col-review .circles-text {
  font-size: 16px !important;
  color: #fff !important;
}

#client-request-proposal-show-content .content-body .box.box-proposal-list .box-body .item .col-review .text {
  color: #02cd8d;
  font-size: 14px;
  font-weight: bold;
}

#client-request-proposal-show-content .content-body .box-white {
  background-color: #FFFFFF;
  padding: 20px;
  word-break: break-all;
  -ms-word-break: break-all;
  word-break: break-all;
  /* Non standard for WebKit */
  word-break: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

#client-request-proposal-show-content .content-body .box-transparent {
  background-color: #f0f4f7;
  padding: 40px;
}

#client-request-proposal-show-content .content-body .description-wrapper {
  padding-top: 20px;
}

#client-request-proposal-show-content .content-body .attachment_box {
  position: relative;
  bottom: 0;
  right: 0;
  width: 400px;
}

#client-request-proposal-show-content .content-body .attachment_box a {
  margin-bottom: 10px;
  color: #02cd8d;
  max-width: 300px;
  display: block;
  line-height: 40px;
  vertical-align: middle;
}

#client-request-proposal-show-content .content-body .attachment_box a:before {
  vertical-align: middle;
  content: "";
  display: inline-block;
  width: 40px;
  height: 40px;
  background-image: url("../../images/root/client/job/attachment.png");
  margin-right: 5px;
}

#client-request-proposal-show-content .content-body .title {
  color: #000000;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 15px;
}

#client-request-proposal-show-content .content-body .title .last_update {
  float: right;
}

#client-request-proposal-show-content .content-body .title:after {
  content: '';
  display: block;
  clear: both;
}

#client-request-proposal-show-content .content-body .price {
  font-size: 20px;
}

#client-request-proposal-show-content .content-body .price span {
  display: block;
  font-size: 14px;
  font-weight: normal;
}

#client-request-proposal-show-content .content-body .read_more {
  color: #02cd8d;
  cursor: pointer;
}

#client-request-proposal-show-content .content-body .last_update {
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  color: #9c9c9c;
}

#client-request-proposal-show-content .content-body .message-box {
  padding: 30px 20px;
  background-color: #ccd9e0;
}

#client-request-proposal-show-content .content-body .message-box .box-header {
  padding-bottom: 10px;
}

#client-request-proposal-show-content .content-body .message-box .box-header .title {
  color: #585858;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  float: left;
  line-height: 38px;
}

#client-request-proposal-show-content .content-body .message-box .box-header a {
  color: #585858;
  text-decoration: none;
}

#client-request-proposal-show-content .content-body .message-box .box-header a:hover {
  text-decoration: underline;
}

#client-request-proposal-show-content .content-body .message-box .box-header .schedule {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  line-height: 1;
  float: right;
  display: block;
  padding: 12px;
  border-radius: 4px;
  background-color: #eeeeee;
  color: #02cd8d;
  cursor: pointer;
}

#client-request-proposal-show-content .content-body .message-box .policy_reminder {
  padding-bottom: 30px;
  color: red;
}

#client-request-proposal-show-content .content-body .message-box .box-input textarea {
  width: 100%;
  min-height: 110px;
  border-radius: 4px;
  border: 2px solid #FFFFFF;
  background-color: #FFFFFF;
  color: #484848;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  padding: 13px;
  transition: border .6s;
  outline: none;
  margin-bottom: 20px;
  display: block;
}

#client-request-proposal-show-content .content-body .message-box .box-input textarea:focus {
  border: 2px solid #02cd8d;
}

#client-request-proposal-show-content .content-body .message-box .box-body .messages .message {
  position: relative;
  margin-top: 50px;
}

#client-request-proposal-show-content .content-body .message-box .box-body .messages .message.left .col-logo {
  width: 100px;
  position: absolute;
  top: 0;
  left: 0;
}

#client-request-proposal-show-content .content-body .message-box .box-body .messages .message.left .col-logo img {
  max-width: 70px;
  max-height: 70px;
  display: block;
  margin: 0 auto;
}

#client-request-proposal-show-content .content-body .message-box .box-body .messages .message.left .col-message {
  padding-left: 120px;
  float: left;
  width: 80%;
}

#client-request-proposal-show-content .content-body .message-box .box-body .messages .message.left .col-message .content {
  padding: 25px 20px;
  background-color: #f4f4f4;
  border-radius: 4px;
  color: #484848;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  line-height: 1.6;
  position: relative;
  word-break: break-all;
}

#client-request-proposal-show-content .content-body .message-box .box-body .messages .message.left .col-message .content .time {
  margin-top: 17px;
  color: #373f48;
  font-size: 15px;
  font-family: "Roboto", sans-serif;
}

#client-request-proposal-show-content .content-body .message-box .box-body .messages .message.left .col-message .content:after {
  content: "";
  display: block;
  position: absolute;
  top: 16px;
  right: 100%;
  border-right: 16px solid #f4f4f4;
  border-top: 16px solid transparent;
  border-bottom: 16px solid transparent;
}

#client-request-proposal-show-content .content-body .message-box .box-body .messages .message.right .col-logo {
  width: 100px;
  position: absolute;
  top: 0;
  right: 0;
}

#client-request-proposal-show-content .content-body .message-box .box-body .messages .message.right .col-logo img {
  max-width: 70px;
  max-height: 70px;
  display: block;
  margin: 0 auto;
}

#client-request-proposal-show-content .content-body .message-box .box-body .messages .message.right .col-message {
  padding-right: 120px;
  float: right;
  width: 80%;
}

#client-request-proposal-show-content .content-body .message-box .box-body .messages .message.right .col-message .content {
  padding: 25px 20px;
  background-color: #f4f4f4;
  color: #484848;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  line-height: 1.6;
  border-radius: 4px;
  position: relative;
  word-break: break-all;
}

#client-request-proposal-show-content .content-body .message-box .box-body .messages .message.right .col-message .content .time {
  margin-top: 17px;
  color: #373f48;
  font-size: 15px;
  font-family: "Roboto", sans-serif;
}

#client-request-proposal-show-content .content-body .message-box .box-body .messages .message.right .col-message .content:after {
  content: "";
  display: block;
  position: absolute;
  top: 16px;
  left: 100%;
  border-left: 16px solid #f4f4f4;
  border-top: 16px solid transparent;
  border-bottom: 16px solid transparent;
}

#client-request-proposal-show-content .content-body .message-box .box-body .messages .message:after {
  content: '';
  display: block;
  clear: both;
}

#client-request-proposal-show-content .content-body .services-wrapper .services {
  margin-left: -10px;
  padding-top: 15px;
}

#client-request-proposal-show-content .content-body .services-wrapper .services .title {
  margin-left: 10px;
}

#client-request-proposal-show-content .content-body .services-wrapper .services .service {
  display: inline-block;
  margin-left: 10px;
  margin-bottom: 10px;
  padding: 5px 10px;
  background-color: #dcdcdc;
  color: #252525;
  border-radius: 3px;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
}

#client-request-proposal-show-content .content-body .with-action {
  padding-right: 200px;
  position: relative;
}

#client-request-proposal-show-content .content-body .with-action .action-box {
  position: absolute;
  top: 0;
  right: 0;
  width: 200px;
}

#client-request-proposal-show-content .content-body .with-action .action-box a:not(:first-child) {
  margin-top: 10px;
}

#client-request-proposal-show-content .content-body .with-action .hourly_rate .estimated_hour .answer {
  margin-bottom: 15px;
}

#client-request-proposal-show-content .content-body ul {
  list-style: disc;
}

#client-request-proposal-show-content .content-body .action-box a:not(.btn-effect-1) {
  display: block;
  height: 40px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  color: #02cd8d;
  border: 1px solid transparent;
}

#client-request-proposal-show-content .content-body .action-box a:hover {
  text-decoration: none;
}

#client-request-proposal-show-content .content-body .action-box a.link-box {
  border: 1px solid #585858;
  color: #595959;
}

#client-request-proposal-show-content .content-body .action-box a.link-box:hover {
  text-decoration: none;
}

#client-request-proposal-show-content .content-body .action-box .btn-grey {
  height: 42px;
  line-height: 42px;
  display: block;
  text-align: center;
  background-color: #eaeaea;
  color: #fff;
  font-size: 14px;
  border-radius: 2px;
}

#client-request-proposal-show-content .content-body .matched-points {
  background-color: #f5fbfc;
  border: 1px solid #02cd8d;
  border-radius: 3px;
  padding: 10px 20px;
  color: #5b5b5b;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  margin-bottom: 20px;
}

#client-request-proposal-show-content .content-body .matched-points .matched {
  color: #28ac78;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
}

#client-request-proposal-show-content .content-body .matched-points .matched .value {
  background-color: #28ac78;
  border-radius: 3px;
  margin-right: 3px;
  display: inline-block;
  color: #92d5ba;
  padding: 3px 6px;
  margin-bottom: 6px;
}

#client-request-proposal-show-content .content-body .matched-points .matched .value span {
  color: #FFFFFF;
}

#client-request-proposal-show-content .content-body .matched-points .tick-grey {
  line-height: 20px;
  margin-bottom: 3px;
}

#client-request-proposal-show-content .content-body .matched-points .tick-grey:before {
  content: '';
  display: inline-block;
  background: url("../../images/root/all.png") no-repeat -882px -555px;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  vertical-align: middle;
}

#client-request-proposal-show-content .content-body .matched-points .tick-green {
  line-height: 20px;
  margin-bottom: 3px;
}

#client-request-proposal-show-content .content-body .matched-points .tick-green:before {
  content: '';
  display: inline-block;
  background: url("../../images/root/all.png") no-repeat -767px -556px;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  vertical-align: middle;
}

#client-request-proposal-show-content .content-body .matched-points .cross {
  line-height: 20px;
  margin-bottom: 3px;
}

#client-request-proposal-show-content .content-body .matched-points .cross:before {
  content: '';
  display: inline-block;
  background: url("../../images/root/all.png") no-repeat -664px -556px;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  vertical-align: middle;
}

#client-request-proposal-show-content .content-body .info-icon {
  margin-bottom: 10px;
  position: relative;
  padding-left: 22px;
}

#client-request-proposal-show-content .content-body .info-icon:not(.with-img):before {
  content: '';
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 0;
  background: url("../../images/root/all.png") no-repeat;
}

#client-request-proposal-show-content .content-body .info-icon.with-img img {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: 16px;
  left: 0;
}

#client-request-proposal-show-content .content-body .info-icon.map:before {
  background-position: -556px -555px;
  width: 12px;
  left: 2px;
  height: 16px;
}

#client-request-proposal-show-content .content-body .info-icon.company:before {
  background-position: -333px -561px;
  width: 16px;
  height: 14px;
}

#client-request-proposal-show-content .content-body .info-icon.industry:before {
  background-position: -211px -558px;
  width: 16px;
  height: 16px;
}

#client-request-proposal-show-content .content-body .info-icon.business:before {
  background-position: -455px -559px;
  width: 16px;
  height: 14px;
}

#client-request-proposal-show-content .content-body .info-label {
  color: #707c85;
  font-weight: normal;
  font-family: "Roboto", sans-serif;
}

#client-request-proposal-show-content .content-body .positions .position {
  position: relative;
  padding-top: 30px;
}

#client-request-proposal-show-content .content-body .positions .position .label {
  color: #3f3f3f;
  position: absolute;
  font-size: 14px;
  top: 0;
  padding: 0;
}

#client-request-proposal-show-content .content-body .positions .position .label.label-left {
  left: 0;
}

#client-request-proposal-show-content .content-body .positions .position .label.label-right {
  right: 0;
}

#client-request-proposal-show-content .content-body .positions .position .bar {
  position: relative;
  width: 100%;
  height: 10px;
  margin-bottom: 13px;
  background-color: #dddddd;
}

#client-request-proposal-show-content .content-body .positions .position .bar .pointer {
  width: 20px;
  height: 20px;
  position: absolute;
  top: -5px;
  background-color: #02cd8d;
  border-radius: 50%;
  transform: translateX(-50%);
  z-index: 3;
}

#client-request-proposal-show-content .content-body .positions .position .bar .pointer-black {
  z-index: 2;
  width: 24px;
  height: 24px;
  position: absolute;
  top: -7px;
  background-color: #373f48;
  border-radius: 50%;
  transform: translateX(-50%);
}

#client-request-proposal-show-content .content-body .positions .position:not(:first-child) {
  margin-top: 15px;
}

#client-request-proposal-show-content .content-body.details .box-white {
  float: left;
  width: 75%;
}

#client-request-proposal-show-content .content-body.details .box-transparent {
  float: left;
  width: 25%;
}

#client-request-proposal-show-content .content-body.details:after {
  content: '';
  display: table;
  clear: both;
}

@media only screen and (max-width: 992px) {
  #client-request-proposal-show-content .container {
    width: 100%;
  }
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row .col-info .info .tag:not(:first-child) {
    margin-left: 14px;
    margin-top: 5px;
  }
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row-responsive .col-logo {
    float: left;
    display: block;
    padding-bottom: 30px;
  }
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row-responsive .col-logo .img-wrapper {
    border: 5px solid #FFFFFF;
    border-radius: 4px;
  }
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row-responsive .col-logo .img-wrapper img {
    max-width: 100%;
  }
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row-responsive .name {
    float: left;
    display: block;
    padding-left: 0;
  }
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row-responsive .col-info {
    padding-top: 25px;
  }
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row-responsive .col-info .info .tag {
    padding: 5px 40px;
    margin-bottom: 10px;
  }
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row-responsive .col-info .info .tag:not(:first-child) {
    margin-left: 0px;
  }
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row-responsive .col-info .info .tag:first-child {
    margin-right: 40px;
  }
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row-responsive .col-contact .with-icon {
    font-size: 16px;
  }
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row-responsive .col-contact .with-icon:not(:first-child) {
    margin-top: 15px;
  }
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row-responsive .col-review {
    width: 145px;
  }
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row-responsive .col-review .circles-text {
    font-size: 16px !important;
    color: #fff !important;
  }
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row-responsive .col-review .text {
    margin-top: 10px;
    color: #02cd8d;
    font-size: 14px;
    font-weight: bold;
  }
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row-responsive .col-match-score {
    padding-top: 10px;
    padding-left: 15px;
  }
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .tab-header {
    padding: 0 20px 0 0;
  }
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .tab-header .tab {
    margin: 0 15px 0 !important;
  }
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body #serviceChart,
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body #focusChart,
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body #industryChart {
    margin: 0 auto;
  }
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .table-info .table-row > [class^=col].col-middle {
    width: 163px;
  }
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.box-focus .chart-legends {
    padding-bottom: 10px;
    max-width: 400px;
  }
  #client-request-proposal-show-content .content-body .tab.tab-guide {
    margin-bottom: 20px;
  }
  #client-request-proposal-show-content .content-body .box.box-proposal-list .box-body .item .col-logo .logo-wrapper {
    width: 100px;
    height: 100px;
  }
  #client-request-proposal-show-content .content-body .box.box-proposal-list .box-body .item.selected {
    position: relative;
    background-color: #313840;
  }
  #client-request-proposal-show-content .content-body .box.box-proposal-list .box-body .item.selected .col-right .col-right-bottom {
    display: table-cell;
    padding-left: 100%;
    padding-top: 15px;
  }
  #client-request-proposal-show-content .content-body .box.box-proposal-list .box-body .item.selected .col-right .col-right-bottom .match-score {
    padding-left: 50px;
  }
  #client-request-proposal-show-content .content-body .box.box-proposal-list .box-body .item.selected .col-right .col-right-bottom .col-review {
    padding-left: 50px;
  }
  #client-request-proposal-show-content .content-body .box.box-proposal-list .box-body .item.selected .col-right .col-right-bottom .col-review .text {
    font-size: 12px;
  }
  #client-request-proposal-show-content .content-body .box.box-proposal-list .box-body .item.selected .col-right .title {
    position: absolute;
    top: 40%;
    width: 40%;
  }
  #client-request-proposal-show-content .content-body .box.box-proposal-list .box-body .item:not(.selected) {
    position: relative;
  }
  #client-request-proposal-show-content .content-body .box.box-proposal-list .box-body .item:not(.selected) .col-right .col-right-bottom {
    display: table-cell;
    padding-left: 100%;
    padding-top: 15px;
  }
  #client-request-proposal-show-content .content-body .box.box-proposal-list .box-body .item:not(.selected) .col-right .col-right-bottom .match-score {
    padding-left: 50px;
  }
  #client-request-proposal-show-content .content-body .box.box-proposal-list .box-body .item:not(.selected) .col-right .col-right-bottom .col-review {
    padding-left: 50px;
  }
  #client-request-proposal-show-content .content-body .box.box-proposal-list .box-body .item:not(.selected) .col-right .col-right-bottom .col-review .text {
    font-size: 12px;
  }
  #client-request-proposal-show-content .content-body .box.box-proposal-list .box-body .item:not(.selected) .col-right .title {
    position: absolute;
    top: 40%;
    width: 40%;
  }
}

@media only screen and (max-width: 767px) {
  #client-request-proposal-show-content {
    padding-top: 20px;
    padding-bottom: 0;
  }
  #client-request-proposal-show-content .navigation .link:not(:first-child) {
    margin-left: 15px;
  }
  #client-request-proposal-show-content .content-header {
    padding: 20px 15px 0px;
  }
  #client-request-proposal-show-content .content-header .col-sm-6 {
    padding-bottom: 15px;
  }
  #client-request-proposal-show-content .content-header .title {
    margin-bottom: 10px;
  }
  #client-request-proposal-show-content .content-header .links a {
    float: left;
    font-size: 13px;
    padding-right: 10px;
  }
  #client-request-proposal-show-content .content-header .links a img {
    width: 15px;
  }
  #client-request-proposal-show-content .content-header .links a:last-child {
    margin-right: 0px;
  }
  #client-request-proposal-show-content .content-body {
    padding-top: 0px;
  }
  #client-request-proposal-show-content .content-body > .row [class^=col] {
    padding-top: 20px;
  }
  #client-request-proposal-show-content .content-body .box.box-proposal-list .box-body .item .col-logo .logo-wrapper {
    width: 65px;
    height: 80px;
  }
  #client-request-proposal-show-content .content-body .box.box-proposal-list .box-body .item.selected, #client-request-proposal-show-content .content-body .box.box-proposal-list .box-body .item:not(.selected) {
    position: inherit;
  }
  #client-request-proposal-show-content .content-body .box.box-proposal-list .box-body .item.selected .col-right, #client-request-proposal-show-content .content-body .box.box-proposal-list .box-body .item:not(.selected) .col-right {
    padding-top: 0;
    padding-right: 0;
  }
  #client-request-proposal-show-content .content-body .box.box-proposal-list .box-body .item.selected .col-right .col-right-bottom, #client-request-proposal-show-content .content-body .box.box-proposal-list .box-body .item:not(.selected) .col-right .col-right-bottom {
    display: table-cell;
    padding-left: 0;
    padding-top: 0;
  }
  #client-request-proposal-show-content .content-body .box.box-proposal-list .box-body .item.selected .col-right .col-right-bottom .match-score, #client-request-proposal-show-content .content-body .box.box-proposal-list .box-body .item:not(.selected) .col-right .col-right-bottom .match-score {
    padding-left: 0;
    padding-top: 5px;
  }
  #client-request-proposal-show-content .content-body .box.box-proposal-list .box-body .item.selected .col-right .col-right-bottom .col-review, #client-request-proposal-show-content .content-body .box.box-proposal-list .box-body .item:not(.selected) .col-right .col-right-bottom .col-review {
    padding-left: 10px;
    padding-top: 5px;
  }
  #client-request-proposal-show-content .content-body .box.box-proposal-list .box-body .item.selected .col-right .col-right-bottom .col-review .text, #client-request-proposal-show-content .content-body .box.box-proposal-list .box-body .item:not(.selected) .col-right .col-right-bottom .col-review .text {
    font-size: 11px;
  }
  #client-request-proposal-show-content .content-body .box.box-proposal-list .box-body .item.selected .col-right .title, #client-request-proposal-show-content .content-body .box.box-proposal-list .box-body .item:not(.selected) .col-right .title {
    position: inherit;
    top: 0;
    width: 100%;
  }
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box {
    height: 320px;
  }
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row-responsive {
    bottom: 0;
  }
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row-responsive .col-logo {
    padding-bottom: 10px;
  }
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row-responsive .col-logo .img-wrapper {
    border: 5px solid #FFFFFF;
    border-radius: 4px;
  }
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row-responsive .col-logo .img-wrapper img {
    max-width: 100%;
  }
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row-responsive .name {
    font-size: 12px;
    margin: 0;
  }
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row-responsive .col-info {
    padding-top: 20px;
    padding-left: 5px;
  }
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row-responsive .col-info .info .tag {
    padding: 5px 10px;
    margin-bottom: 10px;
    font-size: 10px;
  }
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row-responsive .col-info .info .tag:not(:first-child) {
    margin-left: 0px;
  }
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row-responsive .col-info .info .tag:first-child {
    margin-right: 5px;
  }
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row-responsive .col-contact .with-icon {
    font-size: 12px;
  }
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row-responsive .col-contact .with-icon:not(:first-child) {
    margin-top: 5px;
  }
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row-responsive .col-review {
    width: 145px;
  }
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row-responsive .col-review .circles-text {
    font-size: 16px !important;
    color: #fff !important;
  }
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row-responsive .col-review .text {
    margin-top: 10px;
    color: #02cd8d;
    font-size: 14px;
    font-weight: bold;
  }
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .box .table-row-responsive .col-match-score {
    padding-top: 10px;
    padding-left: 15px;
  }
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .tab-header {
    padding: 0;
    width: 100%;
  }
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .tab-header .tab {
    padding: 15px 0 19px;
    font-size: 12px;
    margin: 0 !important;
    width: 25%;
    text-align: center;
  }
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .tab-header .tab.opened {
    border-bottom: 2px solid #02cd8d;
  }
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .tab-header .match-score {
    float: right;
    padding: 11px 0 5px;
  }
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .tab-header .match-score .result {
    background-color: #28ac78;
    display: inline-block;
    padding: 5px 10px;
    font-size: 16px;
    color: #fff;
    border-radius: 3px;
    margin-right: 5px;
  }
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .tab-header .match-score .result .symbol {
    color: #91d4ba;
  }
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-header .tab-header .match-score p {
    display: inline-block;
    color: #28ac78;
    font-weight: bolder;
  }
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body {
    padding: 15px 0px;
  }
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.service .box-body {
    padding: 20px 20px 20px 0px;
  }
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.service .box-body .result {
    position: relative;
    padding-left: 75px;
    padding-top: 10px;
  }
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.service .box-body .result .image {
    margin-top: 18px;
    position: absolute;
    left: 0;
  }
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.service .box-body .result .header {
    padding-top: 0px;
  }
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.service .box-body .result .col-sm-11 {
    padding-top: 0px;
  }
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.service .box-body .result .point {
    width: 100%;
  }
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.box-focus #industryChart {
    width: 100% !important;
    height: 300px !important;
  }
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.box-focus #focusChart {
    width: 100% !important;
    height: 300px !important;
  }
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box.box-focus #serviceChart {
    width: 100% !important;
    height: 300px !important;
  }
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .table-info {
    padding: 0 0 20px 0;
  }
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .table-info .table-row {
    display: table-row;
    width: 100%;
  }
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .table-info .table-row > [class^=col] {
    display: block;
    width: 100%;
  }
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .table-info .table-row > [class^=col].col-left .row {
    display: block;
    margin-left: -15px;
    margin-right: -15px;
  }
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .table-info .table-row > [class^=col].col-left .black {
    margin-bottom: 20px;
  }
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .table-info .table-row > [class^=col].col-left .with-icon {
    padding-top: 5px;
    padding-bottom: 5px;
    min-height: 30px;
    padding-left: 25px;
    position: relative;
  }
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .table-info .table-row > [class^=col].col-left .with-icon .icon {
    position: absolute;
    left: 0;
    top: 5px;
    width: 20px;
    height: 20px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .table-info .table-row > [class^=col].col-left .with-icon:not(:first-child) {
    margin-top: 5px;
  }
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .table-info .table-row > [class^=col].col-left .with-icon .more {
    float: right;
  }
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .table-info .table-row > [class^=col].col-left .with-icon .tooltip_content {
    display: none;
  }
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .table-info .table-row > [class^=col].col-left .tag {
    font-size: 10px;
  }
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .table-info .table-row > [class^=col].col-left .tag:before {
    width: 11px;
    height: 5px;
    bottom: 12px;
  }
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .table-info .table-row > [class^=col].col-middle {
    width: 100%;
  }
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .table-info .table-row > [class^=col].col-middle .info-row .info-label {
    width: 50%;
    text-align: right;
    padding-right: 10px;
  }
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .box .box-body .table-info .table-row > [class^=col].col-right {
    width: 100%;
    text-align: center;
  }
  #client-request-proposal-show-content .content-body .proposal-vendor-profile .proposal-vendor-profile-body .title.price {
    width: 60%;
  }
  #client-request-proposal-show-content .content-body .box-white {
    padding: 0px;
    border: 1px solid #c7ffe4;
  }
  #client-request-proposal-show-content .content-body .box-white .insight {
    padding: 0;
    border: 1px solid #c7ffe4;
  }
  #client-request-proposal-show-content .content-body .box-white .insight .header {
    padding: 10px 20px;
    background-color: #c7ffe4;
  }
  #client-request-proposal-show-content .content-body .box-white .insight .header .title {
    margin-bottom: 0;
  }
  #client-request-proposal-show-content .content-body .box-white .insight .header .title img {
    margin-right: 5px;
  }
  #client-request-proposal-show-content .content-body .box-white .insight .header .title b {
    font-size: 12px;
  }
  #client-request-proposal-show-content .content-body .box-white .insight .body p {
    font-size: 12px;
    padding: 5px 20px;
  }
  #client-request-proposal-show-content .content-body .attachment_box {
    padding-left: 10px;
    padding-right: 10px;
    height: 80px;
  }
  #client-request-proposal-show-content .content-body .attachment_box a {
    right: 20px;
    max-width: 70%;
    width: 70%;
    bottom: 0px;
    top: 30px;
  }
  #client-request-proposal-show-content .content-body .attachment_box:after {
    left: 10px;
    bottom: 0px;
    top: 30px;
  }
  #client-request-proposal-show-content .content-body .box-black {
    margin-bottom: 15px;
  }
  #client-request-proposal-show-content .content-body .box-black .match-score {
    padding: 20px;
    font-size: 14px;
  }
  #client-request-proposal-show-content .content-body .box-black .match-score .result {
    padding: 3px 5px;
    font-size: 16px;
  }
  #client-request-proposal-show-content .content-body .with-action {
    padding-right: 10px;
    padding-left: 10px;
    position: relative;
  }
  #client-request-proposal-show-content .content-body .with-action .action-box {
    width: 40%;
    right: 10px;
  }
  #client-request-proposal-show-content .content-body .with-action .action-box a:not(.btn-effect-1) {
    font-size: 11px;
  }
  #client-request-proposal-show-content .content-body .with-action .action-box .btn-effect-1 {
    padding: 10px 10px 13px;
    font-size: 11px;
  }
  #client-request-proposal-show-content .content-body .description-wrapper {
    padding-left: 10px;
    padding-right: 10px;
  }
  #client-request-proposal-show-content .content-body .clearfix {
    padding-left: 10px;
    padding-right: 10px;
  }
  #client-request-proposal-show-content .content-body .last_update {
    padding-left: 10px;
    padding-right: 10px;
    text-align: left;
  }
  #client-request-proposal-show-content .content-body .message-box .box-header {
    padding-bottom: 20px;
  }
  #client-request-proposal-show-content .content-body .message-box .box-header .title {
    line-height: 1.5;
    font-size: 16px;
  }
  #client-request-proposal-show-content .content-body .message-box .box-input button {
    width: 100%;
  }
  #client-request-proposal-show-content .content-body .message-box .box-body .messages .message.left .col-logo {
    width: 80px;
  }
  #client-request-proposal-show-content .content-body .message-box .box-body .messages .message.left .col-logo img {
    max-width: 50px;
    max-height: 50px;
  }
  #client-request-proposal-show-content .content-body .message-box .box-body .messages .message.left .col-message {
    padding-left: 95px;
    width: 100%;
  }
  #client-request-proposal-show-content .content-body .message-box .box-body .messages .message.left .col-message .content {
    padding: 25px 15px;
    font-size: 12px;
  }
  #client-request-proposal-show-content .content-body .message-box .box-body .messages .message.right .col-logo {
    width: 80px;
  }
  #client-request-proposal-show-content .content-body .message-box .box-body .messages .message.right .col-logo img {
    max-width: 50px;
    max-height: 50px;
  }
  #client-request-proposal-show-content .content-body .message-box .box-body .messages .message.right .col-message {
    padding-right: 95px;
    width: 100%;
  }
  #client-request-proposal-show-content .content-body .message-box .box-body .messages .message.right .col-message .content {
    padding: 25px 15px;
    font-size: 12px;
  }
  #client-request-proposal-show-content .content-body .services-wrapper .services {
    width: 60%;
  }
}

#pages-faqs-banner-wrapper {
  height: 540px;
  position: relative;
  z-index: 3;
  background-size: cover;
}

#pages-faqs-banner-wrapper::after {
  content: '';
  display: block;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #18283e;
}

#pages-faqs-banner-wrapper .inner {
  position: absolute;
  margin-top: 0px;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#pages-faqs-banner-wrapper .inner .description {
  white-space: normal;
  font-size: 55px;
  font-style: italic;
  color: #FFFFFF;
  text-align: center;
  width: 780px;
  margin: 25px auto;
}

#pages-faqs-content.vendor .content-wrapper .heading {
  padding: 70px 0;
  background-color: #6a90b9;
  text-align: center;
}

#pages-faqs-content.vendor .content-wrapper .heading h1 {
  font-size: 40px;
  color: #02cd8d;
  font-family: "Roboto", sans-serif;
  letter-spacing: 2px;
  margin: 0 0 40px;
  line-height: 1;
}

#pages-faqs-content.vendor .content-wrapper .heading .description {
  color: #FFFFFF;
  font-family: "Roboto", sans-serif;
  letter-spacing: 1px;
  font-size: 16px;
  line-height: 1.5;
  margin: 0;
}

#pages-faqs-content.vendor .content-wrapper .heading .icon {
  height: 150px;
  margin: 45px 0;
}

#pages-faqs-content.vendor .content-wrapper .body {
  color: #18293e;
}

#pages-faqs-content.vendor .content-wrapper .body .row-1 {
  margin-top: 50px;
}

#pages-faqs-content.vendor .content-wrapper .body .row-1:last-child {
  margin-bottom: 50px;
}

#pages-faqs-content.vendor .content-wrapper .body .row-1 .title {
  font-size: 30px;
}

#pages-faqs-content.vendor .content-wrapper .body .row-1 .description {
  background-color: #f7f5f1;
  padding: 0px 30px;
  margin-bottom: 30px;
}

#pages-faqs-content.vendor .content-wrapper .body .row-1 .description.active {
  padding: 0px 30px 50px;
}

#pages-faqs-content.vendor .content-wrapper .body .row-1 .description.active .header img.up {
  display: block;
}

#pages-faqs-content.vendor .content-wrapper .body .row-1 .description.active .down {
  display: none;
}

#pages-faqs-content.vendor .content-wrapper .body .row-1 .description.active .content {
  max-height: 800px;
  transition: max-height 0.25s ease-in;
}

#pages-faqs-content.vendor .content-wrapper .body .row-1 .description .header {
  padding: 50px 0px;
  font-weight: bolder;
  color: #18283e;
  font-size: 14px;
  cursor: pointer;
}

#pages-faqs-content.vendor .content-wrapper .body .row-1 .description .header img {
  float: right;
}

#pages-faqs-content.vendor .content-wrapper .body .row-1 .description .header img.up {
  display: none;
}

#pages-faqs-content.vendor .content-wrapper .body .row-1 .description .content {
  font-size: 14px;
  max-height: 0;
  transition: max-height 0.15s ease-out;
  overflow: hidden;
}

#pages-faqs-content.vendor .content-wrapper .body .row-1 .description:last-child {
  margin-bottom: 0px;
}

#pages-faqs-content.client .content-wrapper .heading {
  padding: 70px 0;
  background-color: #6a90b9;
  text-align: center;
}

#pages-faqs-content.client .content-wrapper .heading h1 {
  font-size: 40px;
  color: #02cd8d;
  font-family: "Roboto", sans-serif;
  letter-spacing: 2px;
  margin: 0 0 40px;
  line-height: 1;
}

#pages-faqs-content.client .content-wrapper .heading .description {
  color: #FFFFFF;
  font-family: "Roboto", sans-serif;
  letter-spacing: 1px;
  font-size: 16px;
  line-height: 1.5;
  margin: 0;
}

#pages-faqs-content.client .content-wrapper .heading .icon {
  height: 150px;
  margin: 45px 0;
}

#pages-faqs-content.client .content-wrapper .body {
  color: #18293e;
}

#pages-faqs-content.client .content-wrapper .body .row-1 {
  margin-top: 50px;
}

#pages-faqs-content.client .content-wrapper .body .row-1:last-child {
  margin-bottom: 50px;
}

#pages-faqs-content.client .content-wrapper .body .row-1 .title {
  font-size: 30px;
}

#pages-faqs-content.client .content-wrapper .body .row-1 .description {
  background-color: #f7f5f1;
  padding: 0px 30px;
  margin-bottom: 30px;
}

#pages-faqs-content.client .content-wrapper .body .row-1 .description.active {
  padding: 0px 30px 50px;
}

#pages-faqs-content.client .content-wrapper .body .row-1 .description.active .header img.up {
  display: block;
}

#pages-faqs-content.client .content-wrapper .body .row-1 .description.active .content {
  max-height: 800px;
  transition: max-height 0.25s ease-in;
}

#pages-faqs-content.client .content-wrapper .body .row-1 .description.active .down {
  display: none;
}

#pages-faqs-content.client .content-wrapper .body .row-1 .description .header {
  padding: 50px 30px 50px 0;
  font-weight: bolder;
  color: #18283e;
  font-size: 14px;
  cursor: pointer;
  position: relative;
}

#pages-faqs-content.client .content-wrapper .body .row-1 .description .header img {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateX(-50%);
}

#pages-faqs-content.client .content-wrapper .body .row-1 .description .header img.up {
  display: none;
}

#pages-faqs-content.client .content-wrapper .body .row-1 .description .content {
  font-size: 14px;
  max-height: 0;
  transition: max-height 0.15s ease-out;
  overflow: hidden;
}

#pages-faqs-content.client .content-wrapper .body .row-1 .description:last-child {
  margin-bottom: 0px;
}

@media only screen and (max-width: 991px) {
  #pages-faqs-banner-wrapper .inner .title {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
  #pages-faqs-banner-wrapper .inner .description {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
  #pages-faqs-content.vendor .content-wrapper .body .row-1 .title {
    padding-bottom: 15px;
    text-align: center;
  }
  #pages-faqs-content.client .content-wrapper .body .row-1 .title {
    padding-bottom: 15px;
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  #pages-faqs-banner-wrapper {
    height: 100vh;
    max-height: 550px;
  }
  #pages-faqs-banner-wrapper .inner {
    width: 100%;
  }
  #pages-faqs-banner-wrapper .inner .description {
    font-size: 20px;
    margin: 0 auto 20px;
  }
  #pages-faqs-content.vendor .content-wrapper .container .row .col-md-8 {
    padding-left: 0;
    padding-right: 0;
  }
  #pages-faqs-content.vendor .content-wrapper .body .row-1 {
    padding-top: 20px;
  }
  #pages-faqs-content.vendor .content-wrapper .body .row-1 .title {
    font-size: 20px;
  }
  #pages-faqs-content.vendor .content-wrapper .body .row-1 .description {
    padding-left: 20px;
    padding-right: 20px;
  }
  #pages-faqs-content.vendor .content-wrapper .body .row-1 .description .header {
    padding: 20px 0;
  }
  #pages-faqs-content.client .content-wrapper .container .row .col-md-8 {
    padding-left: 0;
    padding-right: 0;
  }
  #pages-faqs-content.client .content-wrapper .body .row-1:not(:first-child) {
    padding-top: 20px;
  }
  #pages-faqs-content.client .content-wrapper .body .row-1 .title {
    font-size: 20px;
  }
  #pages-faqs-content.client .content-wrapper .body .row-1 .description {
    padding-left: 20px;
    padding-right: 20px;
  }
  #pages-faqs-content.client .content-wrapper .body .row-1 .description .header {
    padding: 20px 0;
  }
}

#content-how-it-work-banner {
  height: 595px;
  position: relative;
  z-index: 3;
  background: url("../../images/root/client/how-works/2Easy-how-it-work-client-banner.jpg") center center;
  background-size: cover;
}

#content-how-it-work-banner::after {
  content: '';
  display: block;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 17, 50, 0.5);
}

#content-how-it-work-banner .inner {
  position: absolute;
  margin-top: 0px;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#content-how-it-work-banner .inner .icon.icon-monitor {
  width: 160px;
  height: 150px;
  background-position: -794px -878px;
  background-image: url(../../images/root/all.png);
  margin: 0 auto 35px;
}

#content-how-it-work-banner .inner .title {
  color: white;
  font-size: 35px;
  text-align: center;
  width: 840px;
  font-weight: bolder;
  margin: 25px auto;
}

#content-how-it-work-banner .inner .description {
  white-space: normal;
  font-size: 21px;
  color: #FFFFFF;
  text-align: center;
  width: 1085px;
  margin: 25px auto;
}

#work-content .body {
  background-color: #f0f7f7;
  color: #18293e;
}

#work-content .body .row-1 {
  height: 600px;
}

#work-content .body .row-1 .comment-image {
  background: url("../../images/root/client/how-works/2Easy-how-it-works-client-step-1.png") #f0f7f7 no-repeat center;
  background-size: 650px;
  height: 600px;
}

#work-content .body .row-1 .comment-box {
  padding-top: 135px;
  padding-left: 150px;
}

#work-content .body .row-1 .comment-box .box-header {
  font-weight: bolder;
  color: #02cd8d;
  font-size: 30px;
}

#work-content .body .row-1 .comment-box .box-body {
  padding-top: 20px;
}

#work-content .body .row-1 .comment-box .box-body ul {
  padding: 0;
}

#work-content .body .row-1 .comment-box .box-body ul li {
  padding-left: 30px;
  padding-bottom: 17px;
  position: relative;
  line-height: 25px;
  list-style: none;
  font-size: 18px;
}

#work-content .body .row-1 .comment-box .box-body ul li .fa {
  color: #02cd8d;
  position: absolute;
  left: 0;
  top: 5px;
}

#work-content .body .row-2 {
  height: 490px;
}

#work-content .body .row-2 .comment-image {
  background: url("../../images/root/client/how-works/2Easy-how-it-works-client-step-2.png") #f0f7f7 no-repeat center;
  background-size: 540px;
  height: 490px;
}

#work-content .body .row-2 .comment-box {
  padding-top: 90px;
  padding-right: 80px;
}

#work-content .body .row-2 .comment-box .box-header {
  font-weight: bolder;
  color: #02cd8d;
  font-size: 30px;
}

#work-content .body .row-2 .comment-box .box-body {
  padding-top: 20px;
}

#work-content .body .row-2 .comment-box .box-body ul {
  padding: 0;
}

#work-content .body .row-2 .comment-box .box-body ul li {
  padding-left: 30px;
  padding-bottom: 17px;
  position: relative;
  line-height: 25px;
  list-style: none;
  font-size: 18px;
}

#work-content .body .row-2 .comment-box .box-body ul li .fa {
  color: #02cd8d;
  position: absolute;
  left: 0;
  top: 5px;
}

#work-content .body .row-3 {
  height: 488px;
}

#work-content .body .row-3 .comment-image {
  background: url("../../images/root/client/how-works/2Easy-how-it-works-client-step-3.png") #f0f7f7 no-repeat center;
  background-size: 538px;
  height: 488px;
}

#work-content .body .row-3 .comment-box {
  padding-top: 90px;
  padding-left: 80px;
}

#work-content .body .row-3 .comment-box .box-header {
  font-weight: bolder;
  color: #02cd8d;
  font-size: 30px;
}

#work-content .body .row-3 .comment-box .box-body {
  padding-top: 20px;
}

#work-content .body .row-3 .comment-box .box-body ul {
  padding: 0;
}

#work-content .body .row-3 .comment-box .box-body ul li {
  padding-left: 30px;
  padding-bottom: 17px;
  position: relative;
  line-height: 25px;
  list-style: none;
  font-size: 18px;
}

#work-content .body .row-3 .comment-box .box-body ul li .fa {
  color: #02cd8d;
  position: absolute;
  left: 0;
  top: 5px;
}

#work-content .body .row-4 {
  position: relative;
  height: 500px;
  margin-top: 50px;
}

#work-content .body .row-4 .introduction {
  padding: 0;
}

#work-content .body .row-4 .introduction .comment-image {
  background: url("../../images/root/client/how-works/2Easy-how-it-work-client-one-stop-solution.jpg") center center;
  background-size: cover;
  height: 500px;
}

#work-content .body .row-4 .introduction .comment-box .box-1 {
  background-color: white;
  color: #02cd8d;
  font-size: 26px;
  height: 250px;
  padding: 75px;
}

#work-content .body .row-4 .introduction .comment-box .box-2 {
  background-color: #02cd8d;
  color: white;
  font-size: 26px;
  height: 250px;
  padding-top: 50px;
  padding-left: 75px;
  padding-right: 120px;
}

#work-content .body .row-4 .introduction .comment-box .box-2 img {
  position: absolute;
  top: 210px;
  left: -20px;
  box-shadow: 5px 5px 10px 0px #55bc9b;
  border-radius: 50%;
}

#work-content #bottom-search-wrapper {
  height: 500px;
  z-index: 1;
  background-color: #e5edf2;
  position: relative;
}

#work-content #bottom-search-wrapper form {
  position: absolute;
  width: 860px;
  max-width: 100%;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
}

#work-content #bottom-search-wrapper form .legend {
  text-align: center;
  color: #18293e;
  font-size: 26px;
  position: relative;
  padding-bottom: 30px;
  margin-bottom: 30px;
  font-weight: bold;
}

#work-content #bottom-search-wrapper form .legend:after {
  position: absolute;
  display: block;
  content: '';
  width: 100px;
  height: 3px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #02cd8d;
}

#work-content #bottom-search-wrapper form .description {
  letter-spacing: 1px;
  color: #f3f3f3;
  margin-bottom: 30px;
  font-size: 17px;
  text-align: center;
}

#work-content #bottom-search-wrapper form .form-row {
  display: table;
  width: 100%;
  border-radius: 3px;
  box-shadow: #02cd8d 0px 2px 2px 1px;
}

#work-content #bottom-search-wrapper form .form-row [class^=col] {
  display: table-cell;
}

#work-content #bottom-search-wrapper form .form-row .col-service {
  width: 350px;
  padding: 15px 15px 10px;
  background-color: #FFFFFF;
}

#work-content #bottom-search-wrapper form .form-row .col-service label {
  display: block;
  color: #4b4b4b;
  font-size: 13px;
  margin: 0;
  font-weight: normal;
  font-weight: bold;
}

#work-content #bottom-search-wrapper form .form-row .col-service input {
  display: block;
  padding: 5px 0;
  color: #02cd8d;
  border: none;
  outline: 0;
  box-shadow: none;
  font-weight: bold;
}

#work-content #bottom-search-wrapper form .form-row .col-service input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-weight: normal;
}

#work-content #bottom-search-wrapper form .form-row .col-service input::-moz-placeholder {
  /* Firefox 19+ */
  font-weight: normal;
}

#work-content #bottom-search-wrapper form .form-row .col-service input:-ms-input-placeholder {
  /* IE 10+ */
  font-weight: normal;
}

#work-content #bottom-search-wrapper form .form-row .col-service input:-moz-placeholder {
  /* Firefox 18- */
  font-weight: normal;
}

#work-content #bottom-search-wrapper form .form-row .col-location {
  width: 350px;
  padding: 15px 15px 10px;
  background-color: #FFFFFF;
  border-left: 1px solid #808080;
}

#work-content #bottom-search-wrapper form .form-row .col-location label {
  display: block;
  color: #4b4b4b;
  font-size: 13px;
  margin: 0;
  font-weight: bold;
}

#work-content #bottom-search-wrapper form .form-row .col-location input {
  font-weight: bold;
  display: block;
  padding: 5px 0;
  color: #02cd8d;
  border: none;
  outline: 0;
  box-shadow: none;
}

#work-content #bottom-search-wrapper form .form-row .col-location input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-weight: normal;
}

#work-content #bottom-search-wrapper form .form-row .col-location input::-moz-placeholder {
  /* Firefox 19+ */
  font-weight: normal;
}

#work-content #bottom-search-wrapper form .form-row .col-location input:-ms-input-placeholder {
  /* IE 10+ */
  font-weight: normal;
}

#work-content #bottom-search-wrapper form .form-row .col-location input:-moz-placeholder {
  /* Firefox 18- */
  font-weight: normal;
}

#work-content #bottom-search-wrapper form .form-row .col-button {
  border-left: 1px solid #808080;
  position: relative;
  min-width: 72px;
  background-color: #FFFFFF;
}

#work-content #bottom-search-wrapper form .form-row .col-button button {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: transparent;
  border: none;
  outline: 0;
}

#work-content #bottom-search-wrapper form .form-row .col-button button .fa {
  color: #28f3b2;
  font-size: 30px;
  text-align: center;
}

#work-content #bottom-search-wrapper form .option-box {
  margin-top: 1px;
  width: 860px;
  max-width: 100%;
  padding: 8px 30px 15px;
  background-color: #FFFFFF;
  position: absolute;
  display: none;
  opacity: 0;
  visibility: hidden;
  top: 100%;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 1;
  transition: opacity .3s;
}

#work-content #bottom-search-wrapper form .option-box .service-category,
#work-content #bottom-search-wrapper form .option-box .location-group {
  font-size: 13px;
  color: #000000;
  font-family: "Roboto", sans-serif;
  display: block;
  font-weight: bold;
  margin-top: 10px;
}

#work-content #bottom-search-wrapper form .option-box .service-category:hover,
#work-content #bottom-search-wrapper form .option-box .location-group:hover {
  text-decoration: none;
  cursor: pointer;
}

#work-content #bottom-search-wrapper form .option-box .services a,
#work-content #bottom-search-wrapper form .option-box .locations a {
  display: block;
  padding: 5px 10px 5px 0;
  font-size: 13px;
  color: #585858;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
}

#work-content #bottom-search-wrapper form .option-box .services a:hover,
#work-content #bottom-search-wrapper form .option-box .locations a:hover {
  text-decoration: none;
  font-weight: bold;
  padding-right: 0;
}

#work-content #bottom-search-wrapper form .option-box .services.with-border,
#work-content #bottom-search-wrapper form .option-box .locations.with-border {
  border-left: 1px solid #585858;
  cursor: pointer;
}

#work-content #bottom-search-wrapper form .option-box.active {
  display: block;
  opacity: 1;
  visibility: visible;
}

#work-content #bottom-search-wrapper form .option-box .col-options {
  overflow: hidden;
}

#work-content #bottom-search-wrapper form .option-box .col-options .row {
  margin-left: -10px;
  margin-right: -10px;
}

#work-content #bottom-search-wrapper form .option-box .col-options .row [class^=col] {
  padding-left: 10px;
  padding-right: 10px;
}

#work-content #bottom-search-wrapper form .option-box .col-next-button {
  position: relative;
}

#work-content #bottom-search-wrapper form .option-box .col-next-button .for-slick-next {
  font-size: 0;
  text-align: center;
  border-radius: 50%;
  height: 60px;
  width: 60px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  color: #02cd8d;
  cursor: pointer;
  border: 1px solid #02cd8d;
  transform: translate(-50%, -50%);
}

#work-content #bottom-search-wrapper form .option-box .col-next-button .for-slick-next .fa {
  font-size: 50px;
  line-height: 60px;
}

#work-content #bottom-search-wrapper form .option-box .col-next-button:before {
  content: '';
  display: block;
  width: 1px;
  background-color: rgba(148, 154, 158, 0.5);
  position: absolute;
  left: -1px;
  top: 33px;
  bottom: 5px;
}

#work-content #bottom-search-wrapper form .option-box .col-services {
  position: relative;
  height: 250px;
}

#work-content #bottom-search-wrapper form .option-box .col-services:not(:first-child):before {
  content: '';
  display: block;
  width: 1px;
  background-color: rgba(148, 154, 158, 0.5);
  position: absolute;
  left: -1px;
  top: 33px;
  bottom: 5px;
}

#work-content #bottom-search-wrapper form .form-type-row {
  margin-bottom: 12px;
}

#work-content #bottom-search-wrapper form .form-type-row .types .type {
  cursor: pointer;
  height: 60px;
  position: relative;
  padding-left: 75px;
  padding-right: 20px;
  line-height: 60px;
  border-radius: 5px;
  float: left;
  background-color: #e5edf2;
  border: 1px solid #ffffff;
}

#work-content #bottom-search-wrapper form .form-type-row .types .type .checkbox {
  position: absolute;
  left: 9px;
  top: 50%;
  margin: 0;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #fff;
}

#work-content #bottom-search-wrapper form .form-type-row .types .type .checkbox:after {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  content: "";
  display: none;
  width: 12px;
  height: 12px;
  background-color: #18293e;
  border-radius: 50%;
}

#work-content #bottom-search-wrapper form .form-type-row .types .type .icon {
  position: absolute;
  left: 40px;
  top: 50%;
  transform: translateY(-50%);
  width: 22px;
  height: 30px;
  background-size: cover;
}

#work-content #bottom-search-wrapper form .form-type-row .types .type .text {
  display: block;
  font-size: 14px;
  font-weight: 700;
  color: #38485a;
}

#work-content #bottom-search-wrapper form .form-type-row .types .type.type-service .icon {
  background-image: url("../../images/root/search/serach bar by service-grey.png");
}

#work-content #bottom-search-wrapper form .form-type-row .types .type.type-portfolio {
  padding-left: 86px;
}

#work-content #bottom-search-wrapper form .form-type-row .types .type.type-portfolio .icon {
  width: 30px;
  left: 41px;
  background-image: url("../../images/root/search/serach bar by portfolio-grey.png");
}

#work-content #bottom-search-wrapper form .form-type-row .types .type:not(:first-child) {
  margin-left: 10px;
}

#work-content #bottom-search-wrapper form .form-type-row .types .type.checked {
  background-color: #28f3b2;
  border: 1px solid #28f3b2;
}

#work-content #bottom-search-wrapper form .form-type-row .types .type.checked .text {
  color: #fff;
}

#work-content #bottom-search-wrapper form .form-type-row .types .type.checked .checkbox:after {
  display: block;
}

#work-content #bottom-search-wrapper form .form-type-row .types .type.checked.type-service .icon {
  background-image: url("../../images/root/search/serach bar by service-white.png");
}

#work-content #bottom-search-wrapper form .form-type-row .types .type.checked.type-portfolio .icon {
  background-image: url("../../images/root/search/serach bar by portfolio-white.png");
}

#work-content #bottom-search-wrapper form .form-type-row .types:after {
  content: "";
  display: table;
  clear: both;
}

#work-content #bottom-search-wrapper form .form-type-content {
  display: none;
}

#work-content #bottom-search-wrapper form .form-type-content.show {
  display: block;
}

#work-content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-input {
  height: 50px;
  padding-right: 150px;
  position: relative;
  border-radius: 4px;
  background-color: #fff;
  border: 2px solid transparent;
  transition: border .3s;
  text-align: center;
  font-size: 24pt;
  font-weight: 300;
  color: #fff;
}

#work-content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-input input {
  width: 100%;
  display: block;
  height: 46px;
  padding: 15px 15px 10px 15px;
  font-size: 16px;
  font-weight: 300;
  border: none;
  outline: none;
  color: #18293E;
  line-height: 1;
  border-radius: 4px 0 0 4px;
}

#work-content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-input input ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #949A9E;
}

#work-content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-input input :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #969ca0;
}

#work-content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-input input ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #969ca0;
}

#work-content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-input button {
  padding: 3px;
  display: block;
  top: -2px;
  right: -2px;
  width: 150px;
  position: absolute;
  box-shadow: none;
  background-color: #28f3b2;
  max-height: 50px;
  border-radius: 4px;
  border: none;
  text-align: center;
  transition: background-color .3s;
}

#work-content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-input button:hover {
  background-color: #18e0a5;
}

#work-content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-input.focus {
  border: 2px solid #28f3b2;
}

#work-content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options {
  z-index: 200;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  display: none;
  background-color: #FFFFFF;
}

#work-content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options.active {
  display: block;
}

#work-content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .all-options {
  display: none;
}

#work-content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .all-options.active {
  display: block;
}

#work-content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .all-options .main-level {
  flex: 0 0 200px;
  width: 200px;
  border-right: 1px solid #f0f4f7;
}

#work-content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .all-options .main-level .first-level-option {
  position: relative;
  padding: 15px 25px;
  cursor: pointer;
  font-size: 14px;
  line-height: 1.3;
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
  color: #000000;
}

#work-content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .all-options .main-level .first-level-option:after {
  content: "";
  display: none;
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  border-left: 12px solid #28f3b2;
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
}

#work-content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .all-options .main-level .first-level-option.selected {
  background-color: #28f3b2;
  color: #FFFFFF;
}

#work-content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .all-options .main-level .first-level-option.selected:after {
  display: block;
}

#work-content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .all-options .sub-level {
  display: none;
}

#work-content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .all-options .sub-level.active {
  display: block;
}

#work-content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .all-options .sub-level .sub-level-options {
  position: absolute;
  top: 0;
  left: 220px;
  width: calc(100% - 220px);
}

#work-content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .all-options .sub-level .sub-level-options .third-level-option a {
  text-decoration: none;
  cursor: pointer;
  display: block;
  float: left;
  color: #797979;
  padding: 15px 10px;
  font-size: 13px;
  font-weight: 300;
}

#work-content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .all-options .sub-level .sub-level-options .third-level-option a:hover {
  color: #18293E;
}

#work-content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .search-options {
  display: none;
}

#work-content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .search-options.active {
  display: block;
}

#work-content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .search-options .option-row {
  position: relative;
}

#work-content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .search-options .option-row .text {
  padding: 15px 120px 15px 15px;
  display: block;
  text-align: left;
  font-size: 12px;
  color: #222222;
  text-decoration: none;
}

#work-content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .search-options .option-row .text-type {
  color: #a9b1be;
  font-size: 12px;
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: -1;
}

#work-content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .search-options .option-row:hover, #work-content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .search-options .option-row.hover {
  background-color: #28f3b2;
  color: #FFFFFF;
}

#work-content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .search-options .option-row:hover .text,
#work-content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .search-options .option-row:hover .text-type, #work-content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .search-options .option-row.hover .text,
#work-content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .search-options .option-row.hover .text-type {
  color: #FFFFFF;
  z-index: 2;
}

#work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-input {
  height: 50px;
  padding-right: 150px;
  position: relative;
  border-radius: 4px;
  background-color: #fff;
  border: 2px solid transparent;
  transition: border .3s;
  text-align: center;
  font-size: 24pt;
  font-weight: 300;
  color: #fff;
}

#work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-input input {
  width: 100%;
  display: block;
  padding: 15px;
  font-size: 16px;
  font-weight: 300;
  border: none;
  outline: none;
  color: #18293E;
  line-height: 1;
  border-radius: 4px 0 0 4px;
}

#work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-input input ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #949A9E;
}

#work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-input input :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #969ca0;
}

#work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-input input ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #969ca0;
}

#work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-input button {
  padding: 3px;
  display: block;
  top: -2px;
  right: -2px;
  width: 150px;
  position: absolute;
  box-shadow: none;
  background-color: #28f3b2;
  max-height: 50px;
  border-radius: 4px;
  border: none;
  text-align: center;
  transition: background-color .3s;
}

#work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-input button:hover {
  background-color: #18e0a5;
}

#work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-input.focus {
  border: 2px solid #28f3b2;
}

#work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options {
  z-index: 200;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  display: none;
  background-color: #FFFFFF;
}

#work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options.active {
  display: block;
}

#work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .all-options {
  display: none;
  x-justify-content: space-around;
  x-align-items: stretch;
}

#work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .all-options.active {
  display: flex;
}

#work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .all-options .main-level {
  flex: 0 0 200px;
  width: 200px;
  border-right: 1px solid #f0f4f7;
}

#work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .all-options .main-level .first-level-option {
  text-align: left;
  position: relative;
  padding: 15px 25px;
  cursor: pointer;
  font-size: 14px;
  line-height: 1.3;
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
  color: #000;
}

#work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .all-options .main-level .first-level-option:after {
  content: "";
  display: none;
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  border-left: 12px solid #28f3b2;
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
}

#work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .all-options .main-level .first-level-option.selected {
  background-color: #28f3b2;
  color: #FFFFFF;
}

#work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .all-options .main-level .first-level-option.selected:after {
  display: block;
}

#work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .all-options .sub-level {
  display: none;
  flex: 1;
}

#work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .all-options .sub-level.active {
  display: block;
}

#work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .all-options .sub-level .sub-level-options {
  display: flex;
  padding: 10px;
  x-justify-content: space-around;
  x-align-items: stretch;
}

#work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .all-options .sub-level .sub-level-options .second-level-option {
  width: 200px;
  flex: 0 0 200px;
  color: #18293E;
  padding: 10px;
  font-size: 13px;
  font-weight: 700;
  cursor: default;
  text-align: left;
}

#work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .all-options .sub-level .sub-level-options .third-level-option {
  flex: 1;
}

#work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .all-options .sub-level .sub-level-options .third-level-option:after {
  content: "";
  display: table;
  clear: both;
}

#work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .all-options .sub-level .sub-level-options .third-level-option a {
  text-decoration: none;
  cursor: pointer;
  display: block;
  float: left;
  color: #797979;
  padding: 10px;
  font-size: 13px;
  font-weight: 300;
}

#work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .all-options .sub-level .sub-level-options .third-level-option a:hover {
  color: #18293E;
}

#work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .search-options {
  display: none;
}

#work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .search-options.active {
  display: block;
}

#work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .search-options .option-row {
  position: relative;
}

#work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .search-options .option-row .text {
  padding: 15px 120px 15px 15px;
  display: block;
  text-align: left;
  font-size: 12px;
  color: #222222;
  text-decoration: none;
}

#work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .search-options .option-row .text-type {
  color: #a9b1be;
  font-size: 12px;
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: -1;
}

#work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .search-options .option-row:hover, #work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .search-options .option-row.hover {
  background-color: #28f3b2;
  color: #FFFFFF;
}

#work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .search-options .option-row:hover .text,
#work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .search-options .option-row:hover .text-type, #work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .search-options .option-row.hover .text,
#work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .search-options .option-row.hover .text-type {
  color: #FFFFFF;
  z-index: 2;
}

@media only screen and (max-width: 991px) {
  #content-how-it-work-banner {
    height: 595px;
  }
  #content-how-it-work-banner .inner .title {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }
  #content-how-it-work-banner .inner .description {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }
  #work-content .body .row-1 {
    height: auto;
    margin-bottom: 0;
    margin-top: 30px;
  }
  #work-content .body .row-1 .comment-image {
    background-size: contain;
    height: 300px;
  }
  #work-content .body .row-1 .comment-box {
    padding: 30px;
  }
  #work-content .body .row-2 {
    height: auto;
    margin-bottom: 0;
    margin-top: 30px;
  }
  #work-content .body .row-2 .comment-image {
    background-size: contain;
    height: 300px;
  }
  #work-content .body .row-2 .comment-box {
    padding: 30px;
  }
  #work-content .body .row-3 {
    height: auto;
    margin-bottom: 0;
    margin-top: 30px;
  }
  #work-content .body .row-3 .comment-image {
    background-size: contain;
    height: 300px;
  }
  #work-content .body .row-3 .comment-box {
    padding: 30px;
  }
  #work-content .body .row-4 {
    height: auto;
    margin-bottom: 0;
    margin-top: 30px;
  }
  #work-content .body .row-4 .introduction {
    padding: 0;
  }
  #work-content .body .row-4 .introduction .comment-image {
    background-size: cover;
    height: 360px;
  }
  #work-content .body .row-4 .introduction .comment-box .box-1 {
    font-size: 22px;
    padding: 25px;
    height: 180px;
  }
  #work-content .body .row-4 .introduction .comment-box .box-2 {
    font-size: 22px;
    padding: 25px;
    height: 180px;
  }
  #work-content .body .row-4 .introduction .comment-box .box-2 img {
    position: absolute;
    top: 50%;
    left: 0;
    box-shadow: 5px 5px 10px 0px #55bc9b;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
  }
  #work-content #bottom-search-wrapper form {
    width: 90%;
  }
}

@media only screen and (max-width: 767px) {
  #content-how-it-work-banner {
    height: 100vh;
    max-height: 550px;
  }
  #content-how-it-work-banner .inner {
    padding-top: 40px;
    width: 100%;
  }
  #content-how-it-work-banner .inner .title {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    font-size: 26px;
    line-height: 26px;
  }
  #content-how-it-work-banner .inner .description {
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 18px;
    line-height: 20px;
  }
  #work-content .body {
    background-color: #f0f7f7;
  }
  #work-content .body .row-1 .comment-image,
  #work-content .body .row-2 .comment-image,
  #work-content .body .row-3 .comment-image {
    height: 200px;
    background-size: contain;
  }
  #work-content .body .row-1 .comment-box,
  #work-content .body .row-2 .comment-box,
  #work-content .body .row-3 .comment-box {
    padding-left: 0;
    padding-right: 0;
  }
  #work-content .body .row-1 .comment-box .box-header,
  #work-content .body .row-2 .comment-box .box-header,
  #work-content .body .row-3 .comment-box .box-header {
    font-size: 20px;
    line-height: 1;
  }
  #work-content .body .row-1 .comment-box .box-body,
  #work-content .body .row-2 .comment-box .box-body,
  #work-content .body .row-3 .comment-box .box-body {
    padding-top: 20px;
  }
  #work-content .body .row-1 .comment-box .box-body ul li,
  #work-content .body .row-2 .comment-box .box-body ul li,
  #work-content .body .row-3 .comment-box .box-body ul li {
    line-height: 1.5;
    font-size: 14px;
  }
  #work-content .body .row-1 .comment-box .box-body ul li:not(:first-child),
  #work-content .body .row-2 .comment-box .box-body ul li:not(:first-child),
  #work-content .body .row-3 .comment-box .box-body ul li:not(:first-child) {
    margin-top: 10px;
  }
  #work-content .body .row-4 {
    margin-top: 0;
  }
  #work-content .body .row-4 .introduction .comment-box .box-1 {
    font-size: 20px;
    height: auto;
    padding: 20px;
    line-height: 1.5;
  }
  #work-content .body .row-4 .introduction .comment-box .box-2 {
    font-size: 20px;
    height: auto;
    padding: 20px;
    line-height: 1.5;
  }
  #work-content #bottom-search-wrapper {
    min-height: 600px;
    height: 120vh;
  }
  #work-content #bottom-search-wrapper form {
    width: 92%;
  }
  #work-content #bottom-search-wrapper form .legend {
    font-size: 20px;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
  #work-content #bottom-search-wrapper form .description {
    margin-bottom: 30px;
    font-size: 14px;
    white-space: normal;
  }
  #work-content #bottom-search-wrapper form .form-row .col-service,
  #work-content #bottom-search-wrapper form .form-row .col-location,
  #work-content #bottom-search-wrapper form .form-row .col-button {
    display: block;
    width: 100%;
  }
  #work-content #bottom-search-wrapper form .form-row .col-location {
    border-left: none;
    border-top: 1px solid #808080;
  }
  #work-content #bottom-search-wrapper form .form-row .col-button {
    border-left: none;
    border-top: 1px solid #808080;
  }
  #work-content #bottom-search-wrapper form .form-row .col-button button {
    padding: 20px 0;
    font-size: 18px;
    color: #02cd8d;
    position: static;
  }
  #work-content #bottom-search-wrapper form .form-row .col-button button .fa {
    display: none;
  }
  #work-content #bottom-search-wrapper form .option-box.option-service .option-box-col-right {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(-50%, 50%);
    width: 60px;
  }
  #work-content #bottom-search-wrapper form .option-box .col-options .row [class^=col] {
    padding-left: 40px;
  }
  #work-content #bottom-search-wrapper form .form-type-row .types .type {
    padding-left: 60px;
    padding-right: 10px;
  }
  #work-content #bottom-search-wrapper form .form-type-row .types .type:first-child {
    margin-right: 10px;
  }
  #work-content #bottom-search-wrapper form .form-type-row .types .type .icon {
    left: 33px;
  }
  #work-content #bottom-search-wrapper form .form-type-row .types .type .text {
    font-size: 12px;
  }
  #work-content #bottom-search-wrapper form .form-type-row .types .type.type-portfolio {
    padding-left: 70px;
  }
  #work-content #bottom-search-wrapper form .form-type-row .types .type.type-portfolio .icon {
    width: 28px;
    left: 34px;
  }
  #work-content #bottom-search-wrapper form .form-type-row .types .type:last-child {
    margin-left: 0;
  }
  #work-content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-input {
    font-size: 24px;
    padding-right: 55px;
  }
  #work-content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-input button {
    padding: 8px;
    width: 20%;
    color: #18d197;
    background-color: transparent;
  }
  #work-content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-input button:hover {
    background-color: transparent;
  }
  #work-content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-input button .fa {
    color: #18d197;
  }
  #work-content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-input input {
    font-size: 14px;
    padding: 15px 5px 10px 10px;
  }
  #work-content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .all-options .main-level {
    flex: none;
    width: 40%;
  }
  #work-content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .all-options .main-level .first-level-option.selected:after {
    display: none;
  }
  #work-content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .all-options .sub-level.active {
    width: 50%;
  }
  #work-content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .all-options .sub-level .sub-level-options {
    display: initial;
  }
  #work-content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .all-options .sub-level .sub-level-options .second-level-option {
    width: 100%;
  }
  #work-content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .responsive-all-options {
    display: none;
  }
  #work-content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .responsive-all-options.active {
    display: block;
  }
  #work-content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .responsive-all-options .main-level {
    font-size: 14px;
    color: #18293e;
    font-weight: bold;
    text-align: left;
  }
  #work-content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .responsive-all-options .main-level .first-level-option {
    background-color: #f5f5f5;
    padding: 20px 0 0 0;
    cursor: pointer;
  }
  #work-content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .responsive-all-options .main-level .first-level-option .title {
    padding-bottom: 20px;
    padding-left: 10px;
    position: relative;
  }
  #work-content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .responsive-all-options .main-level .first-level-option .title i.up, #work-content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .responsive-all-options .main-level .first-level-option .title i.down {
    position: absolute;
    right: 10%;
    top: 0;
  }
  #work-content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .responsive-all-options .main-level .first-level-option .title i.up {
    display: none;
  }
  #work-content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .responsive-all-options .main-level .first-level-option .title i.down {
    display: block;
  }
  #work-content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .responsive-all-options .main-level .first-level-option .third-level-option {
    display: none;
    padding-left: 10px;
    font-size: 14px;
    font-weight: 300;
  }
  #work-content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .responsive-all-options .main-level .first-level-option .third-level-option.active {
    display: block;
  }
  #work-content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .responsive-all-options .main-level .first-level-option .third-level-option .third-level-option-items {
    padding-top: 20px;
    padding-left: 10px;
    padding-bottom: 20px;
    border-bottom: 1px solid #dbdbdb;
  }
  #work-content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .responsive-all-options .main-level .first-level-option .third-level-option .third-level-option-items a {
    color: #18293e;
    text-decoration: none;
  }
  #work-content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .responsive-all-options .main-level .first-level-option.active .title i.up {
    display: block;
  }
  #work-content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .responsive-all-options .main-level .first-level-option.active .title i.down {
    display: none;
  }
  #work-content #bottom-search-wrapper form .form-type-content.form-type-content-service .box-options .responsive-all-options .main-level .first-level-option.active .sub-level-options {
    display: block;
  }
  #work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-input {
    font-size: 24px;
    padding-right: 55px;
  }
  #work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-input button {
    padding: 8px;
    width: 20%;
    color: #18d197;
    background-color: transparent;
  }
  #work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-input button:hover {
    background-color: transparent;
  }
  #work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-input button .fa {
    color: #18d197;
  }
  #work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-input input {
    font-size: 14px;
    padding: 15px 5px 10px 10px;
  }
  #work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .all-options .main-level {
    flex: none;
    width: 40%;
  }
  #work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .all-options .main-level .first-level-option.selected:after {
    display: none;
  }
  #work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .all-options .sub-level.active {
    width: 50%;
  }
  #work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .all-options .sub-level .sub-level-options {
    display: initial;
  }
  #work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .all-options .sub-level .sub-level-options .second-level-option {
    width: 100%;
  }
  #work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options {
    display: none;
  }
  #work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options.active {
    display: block;
  }
  #work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options .main-level {
    font-size: 14px;
    color: #18293e;
    font-weight: bold;
    text-align: left;
  }
  #work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options .main-level .first-level-option {
    background-color: #f5f5f5;
    padding: 20px 0 0 0;
    cursor: pointer;
  }
  #work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options .main-level .first-level-option .title {
    padding-bottom: 20px;
    padding-left: 10px;
    position: relative;
  }
  #work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options .main-level .first-level-option .title i.up, #work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options .main-level .first-level-option .title i.down {
    position: absolute;
    right: 10%;
    top: 0;
  }
  #work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options .main-level .first-level-option .title i.up {
    display: none;
  }
  #work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options .main-level .first-level-option .title i.down {
    display: block;
  }
  #work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options .main-level .first-level-option .sub-level-options {
    display: none;
    padding-left: 20px;
    background-color: #ffffff;
  }
  #work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options .main-level .first-level-option .sub-level-options.active .third-level-option {
    display: block;
  }
  #work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options .main-level .first-level-option .sub-level-options .second-level-option {
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #dbdbdb;
    cursor: pointer;
    position: relative;
  }
  #work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options .main-level .first-level-option .sub-level-options .second-level-option i.up, #work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options .main-level .first-level-option .sub-level-options .second-level-option i.down {
    position: absolute;
    right: 10%;
    top: 50%;
    transform: translateY(-50%);
  }
  #work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options .main-level .first-level-option .sub-level-options .second-level-option i.up {
    display: none;
  }
  #work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options .main-level .first-level-option .sub-level-options .second-level-option i.down {
    display: block;
  }
  #work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options .main-level .first-level-option .sub-level-options .second-level-option.active i.up {
    display: none;
  }
  #work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options .main-level .first-level-option .sub-level-options .second-level-option.active i.down {
    display: block;
  }
  #work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options .main-level .first-level-option .sub-level-options .third-level-option {
    display: none;
    padding-left: 10px;
    font-size: 14px;
    font-weight: 300;
  }
  #work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options .main-level .first-level-option .sub-level-options .third-level-option .third-level-option-items {
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #dbdbdb;
  }
  #work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options .main-level .first-level-option .sub-level-options .third-level-option .third-level-option-items a {
    color: #18293e;
    text-decoration: none;
  }
  #work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options .main-level .first-level-option.active .title i.up {
    display: block;
  }
  #work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options .main-level .first-level-option.active .title i.down {
    display: none;
  }
  #work-content #bottom-search-wrapper form .form-type-content.form-type-content-portfolio .box-options .responsive-all-options .main-level .first-level-option.active .sub-level-options {
    display: block;
  }
}

#pages-terms-of-use-banner-wrapper {
  height: 540px;
  position: relative;
  z-index: 3;
  background-size: cover;
}

#pages-terms-of-use-banner-wrapper::after {
  content: '';
  display: block;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #18283e;
}

#pages-terms-of-use-banner-wrapper .inner {
  position: absolute;
  margin-top: 0px;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#pages-terms-of-use-banner-wrapper .inner .description {
  line-height: 50px;
  white-space: normal;
  font-size: 55px;
  font-style: italic;
  color: #FFFFFF;
  text-align: center;
  width: 780px;
  margin: 25px auto;
}

#pages-terms-of-use-content {
  padding-top: 60px;
  padding-bottom: 60px;
  line-height: 1.5;
}

#pages-terms-of-use-content ol.number {
  list-style-type: decimal;
}

#pages-terms-of-use-content ol.alphabet {
  list-style: lower-alpha;
}

#pages-terms-of-use-content ol.roman {
  list-style: lower-roman;
}

#pages-terms-of-use-content ol li {
  padding-top: 3px;
  padding-bottom: 3px;
}

@media only screen and (max-width: 991px) {
  #pages-terms-of-use-banner-wrapper .inner .description {
    font-size: 45px;
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  #pages-terms-of-use-banner-wrapper {
    height: 450px;
  }
  #pages-terms-of-use-banner-wrapper .inner .description {
    width: 100%;
    font-size: 35px;
  }
}

#pages-privacy-policy-banner-wrapper {
  height: 540px;
  position: relative;
  z-index: 3;
  background-size: cover;
}

#pages-privacy-policy-banner-wrapper::after {
  content: '';
  display: block;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #18283e;
}

#pages-privacy-policy-banner-wrapper .inner {
  position: absolute;
  margin-top: 0px;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#pages-privacy-policy-banner-wrapper .inner .description {
  line-height: 50px;
  white-space: normal;
  font-size: 55px;
  font-style: italic;
  color: #FFFFFF;
  text-align: center;
  width: 780px;
  margin: 25px auto;
}

#pages-privacy-policy-content {
  padding-top: 60px;
  padding-bottom: 60px;
  line-height: 1.5;
}

#pages-privacy-policy-content ol.number {
  list-style-type: decimal;
}

#pages-privacy-policy-content ol.alphabet {
  list-style: lower-alpha;
}

#pages-privacy-policy-content ol.roman {
  list-style: lower-roman;
}

#pages-privacy-policy-content ol li {
  padding-top: 3px;
  padding-bottom: 3px;
}

@media only screen and (max-width: 991px) {
  #pages-privacy-policy-banner-wrapper .inner .description {
    font-size: 45px;
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  #pages-privacy-policy-banner-wrapper {
    height: 450px;
  }
  #pages-privacy-policy-banner-wrapper .inner .description {
    width: 100%;
    font-size: 35px;
  }
  #pages-privacy-policy-content {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  #pages-privacy-policy-content ol {
    padding-left: 20px;
  }
}

#content-page-2easy-charge-banner {
  height: 800px;
  position: relative;
  z-index: 3;
  background-size: cover;
  background-image: url("../../images/root/2easy-charges/2Easy_ChargesBanner.png");
  background-position: center;
}

#content-page-2easy-charge-banner .inner {
  position: absolute;
  z-index: 2;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -30%);
}

#content-page-2easy-charge-banner .inner .title {
  color: #ffffff;
  font-size: 40px;
  width: 780px;
  text-align: center;
  font-weight: 500;
  margin: 30px auto;
  line-height: 50px;
  font-family: 'Roboto', sans-serif;
}

#content-page-2easy-charge-banner .inner .description {
  width: 800px;
  white-space: normal;
  font-size: 20px;
  color: #ffffff;
  text-align: center;
  margin: 25px auto;
  word-spacing: 1px;
  font-family: 'Roboto', sans-serif;
}

#content-page-2easy-charge-body {
  display: block;
  position: relative;
  background-color: #f0f4f7;
  font-family: 'Roboto', sans-serif;
}

#content-page-2easy-charge-body .container {
  min-width: initial;
  width: 100%;
  max-width: 1200px;
}

#content-page-2easy-charge-body .center-box {
  position: absolute;
  z-index: 3;
  width: 1200px;
  top: -420px;
  left: 50%;
  transform: translate(-50%, 0);
}

#content-page-2easy-charge-body .center-box [class^=schedule_] {
  border-radius: 10px;
  background-color: #ffffff;
}

#content-page-2easy-charge-body .center-box [class^=schedule_] .top {
  text-align: center;
  line-height: 20px;
  padding: 30px;
  border-bottom: 3px solid #f2f2f2;
}

#content-page-2easy-charge-body .center-box [class^=schedule_] .top .title {
  font-size: 16px;
  padding-bottom: 30px;
  font-weight: bold;
}

#content-page-2easy-charge-body .center-box [class^=schedule_] .top .title span {
  font-size: 24px;
}

#content-page-2easy-charge-body .center-box [class^=schedule_] .top .service {
  font-size: 24px;
}

#content-page-2easy-charge-body .center-box [class^=schedule_] .top .service span {
  font-size: 12px;
}

#content-page-2easy-charge-body .center-box [class^=schedule_] .bottom {
  text-align: center;
  padding: 30px;
}

#content-page-2easy-charge-body .center-box [class^=schedule_] .bottom .value-title, #content-page-2easy-charge-body .center-box [class^=schedule_] .bottom .rate-title {
  padding-bottom: 15px;
  font-weight: bold;
  font-size: 13px;
}

#content-page-2easy-charge-body .center-box [class^=schedule_] .bottom .answer {
  font-size: 14px;
}

#content-page-2easy-charge-body .center-box [class^=schedule_] .line {
  margin: 30px 0;
  height: 2px;
}

#content-page-2easy-charge-body .center-box .schedule_1 {
  border: 3px solid #485cec;
}

#content-page-2easy-charge-body .center-box .schedule_1 .top .title {
  color: #485cec;
}

#content-page-2easy-charge-body .center-box .schedule_1 .bottom {
  text-align: left;
}

#content-page-2easy-charge-body .center-box .schedule_1 .bottom .value-title, #content-page-2easy-charge-body .center-box .schedule_1 .bottom .rate-title {
  color: #485cec;
}

#content-page-2easy-charge-body .center-box .schedule_1 .line {
  background-color: #485cec;
}

#content-page-2easy-charge-body .center-box .schedule_1 .example {
  margin: 0px 15px 30px;
  background-color: #485cec;
  border-radius: 10px;
}

#content-page-2easy-charge-body .center-box .schedule_1 .example .content {
  color: #ffffff;
  padding: 20px 15px;
  font-size: 14px;
}

#content-page-2easy-charge-body .center-box .schedule_1 .example .content .title {
  font-size: 16px;
  padding-bottom: 15px;
}

#content-page-2easy-charge-body .center-box .schedule_1 .example .content .last {
  padding-top: 5px;
}

#content-page-2easy-charge-body .center-box .schedule_2 {
  border: 3px solid #ec6348;
}

#content-page-2easy-charge-body .center-box .schedule_2 .top .title {
  color: #ec6348;
}

#content-page-2easy-charge-body .center-box .schedule_2 .bottom .value-title, #content-page-2easy-charge-body .center-box .schedule_2 .bottom .rate-title {
  color: #ec6348;
}

#content-page-2easy-charge-body .center-box .schedule_2 .line {
  background-color: #ec6348;
}

#content-page-2easy-charge-body .center-box .schedule_3 {
  border: 3px solid #16bc9f;
}

#content-page-2easy-charge-body .center-box .schedule_3 .top .title {
  color: #16bc9f;
}

#content-page-2easy-charge-body .center-box .schedule_3 .bottom .value-title, #content-page-2easy-charge-body .center-box .schedule_3 .bottom .rate-title {
  color: #16bc9f;
}

#content-page-2easy-charge-body .center-box .schedule_3 .line {
  background-color: #16bc9f;
}

#content-page-2easy-charge-body .center-box .note {
  margin-top: 30px;
  padding: 40px 60px;
  font-size: 16px;
  font-weight: 300;
  background-color: #29f2b1;
}

#content-page-2easy-charge-body .center-box .note span {
  color: #485cec;
  font-size: 12px;
}

#content-page-2easy-charge-body .content-faqs {
  padding-top: 680px;
}

#content-page-2easy-charge-body .content-faqs .icon {
  text-align: center;
}

#content-page-2easy-charge-body .content-faqs .title {
  padding: 25px 0 35px 0;
  position: relative;
  text-align: center;
  font-size: 64px;
  font-weight: 300;
  border-bottom: 1px solid #28f2b2;
}

#content-page-2easy-charge-body .content-faqs .title:after {
  position: absolute;
  display: block;
  content: '';
  width: 280px;
  height: 3px;
  bottom: 0;
  top: 98%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #28f2b2;
}

#content-page-2easy-charge-body .content-faqs .tab-body {
  padding: 95px 65px 120px;
}

#content-page-2easy-charge-body .content-faqs .tab-body .item {
  padding-bottom: 35px;
}

#content-page-2easy-charge-body .content-faqs .tab-body .item .header {
  font-weight: bold;
  padding-bottom: 30px;
  font-size: 16px;
}

#content-page-2easy-charge-body .content-faqs .tab-body .item .content {
  font-weight: 300;
  padding-bottom: 30px;
  font-size: 18px;
}

@media only screen and (max-width: 991px) {
  #content-page-2easy-charge-banner {
    height: 540px;
  }
  #content-page-2easy-charge-banner .inner {
    width: 100%;
    top: 30%;
    transform: translate(-50%, -30%);
  }
  #content-page-2easy-charge-banner .inner .title {
    font-size: 45px;
    width: 100%;
  }
  #content-page-2easy-charge-banner .inner .description {
    font-size: 20px;
    width: 100%;
  }
  #content-page-2easy-charge-body .center-box {
    width: 100%;
    padding: 70px;
    top: -300px;
  }
  #content-page-2easy-charge-body .center-box .col-md-4 {
    margin-bottom: 20px;
  }
  #content-page-2easy-charge-body .content-faqs {
    padding-top: 1730px;
  }
  #content-page-2easy-charge-body .content-faqs .tab-body {
    padding: 45px 25px 60px;
  }
  #content-page-2easy-charge-body .content-faqs .tab-body .item .header {
    padding-bottom: 20px;
  }
  #content-page-2easy-charge-body .content-faqs .tab-body .item .content {
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  #content-page-2easy-charge-banner {
    height: 540px;
  }
  #content-page-2easy-charge-banner .inner .title {
    font-size: 35px;
    width: 100%;
    margin-bottom: 10px;
  }
  #content-page-2easy-charge-banner .inner .description {
    font-size: 18px;
    width: 100%;
  }
  #content-page-2easy-charge-body .center-box {
    padding: 15px;
    top: -200px;
  }
  #content-page-2easy-charge-body .center-box [class^=schedule_] .top {
    padding: 15px;
  }
  #content-page-2easy-charge-body .center-box [class^=schedule_] .bottom {
    padding: 15px;
  }
  #content-page-2easy-charge-body .center-box [class^=schedule_] .line {
    margin: 30px 0;
    height: 2px;
  }
  #content-page-2easy-charge-body .center-box .schedule_1 .example .content {
    padding: 20px 10px;
    font-size: 12px;
  }
  #content-page-2easy-charge-body .center-box .schedule_1 .example .content .title {
    font-size: 14px;
  }
  #content-page-2easy-charge-body .center-box .note {
    margin-top: 20px;
    padding: 30px 20px;
    font-size: 14px;
  }
  #content-page-2easy-charge-body .content-faqs {
    padding-top: 1800px;
  }
  #content-page-2easy-charge-body .content-faqs .title {
    padding: 15px 0 25px 0;
  }
  #content-page-2easy-charge-body .content-faqs .title:after {
    width: 200px;
  }
  #content-page-2easy-charge-body .content-faqs .tab-body {
    padding: 35px 5px 30px;
  }
  #content-page-2easy-charge-body .content-faqs .tab-body .item .header {
    padding-bottom: 20px;
  }
}

#affiliate-banner {
  font-family: "roboto";
  height: 540px;
  position: relative;
  z-index: 3;
  background-size: cover;
}

#affiliate-banner::after {
  content: '';
  display: block;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #18283e;
}

#affiliate-banner .inner {
  position: absolute;
  margin-top: 0px;
  z-index: 2;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#affiliate-banner .inner .title {
  color: white;
  font-size: 36px;
  text-align: center;
  width: 780px;
  font-weight: 500;
  margin: 40px auto;
  line-height: 50px;
}

#affiliate-banner .inner .description {
  white-space: normal;
  font-size: 24px;
  color: #FFFFFF;
  text-align: center;
  font-weight: 300;
  width: 780px;
  margin: 25px auto;
}

#affiliate-body {
  font-family: "roboto";
  display: block;
  position: relative;
  background-color: #e5edf2;
}

#affiliate-body #contact-box {
  min-width: initial;
  width: 100%;
  max-width: 1160px;
  height: 950px;
  position: relative;
  top: -80px;
  z-index: 3;
  margin-bottom: 50px;
}

#affiliate-body #contact-box .left-content {
  float: left;
  padding: 50px 50px 0 50px;
  width: 50%;
  font-size: 28px;
  font-weight: 500;
  color: white;
  background: url(../../images/root/contact/cyberport-building.png) #01d693 no-repeat bottom;
  background-size: contain;
  border-radius: 4px 0 0 4px;
  line-height: 40px;
}

#affiliate-body #contact-box .right-content {
  float: right;
  width: 50%;
  background-color: white;
  border-radius: 0 4px 4px 0;
}

#affiliate-body #contact-box .right-content #form-affiliate {
  padding: 50px 75px 60px 75px;
}

#affiliate-body #contact-box .right-content #form-affiliate .form-group label {
  padding: 8px 0 8px 0;
  font-size: 16px;
  font-weight: 500;
  font-style: italic;
}

#affiliate-body #contact-box .right-content #form-affiliate .form-group .select2 .select2-selection--single {
  border-color: #eaeaea;
  height: 50px;
  padding: 8px 0;
  font-size: 16px;
  font-weight: 500;
  font-style: italic;
}

#affiliate-body #contact-box .right-content #form-affiliate .form-group .select2 .select2-selection--single .select2-selection__arrow {
  height: 50px;
}

#affiliate-body #contact-box .right-content #form-affiliate .form-group select span.placeholder {
  color: #cccccc;
}

#affiliate-body #contact-box .right-content #form-affiliate .form-group .SumoSelect > .CaptionCont {
  margin: 0px;
  border: 1px solid #eaeaea;
}

#affiliate-body #contact-box .right-content #form-affiliate .form-group .SumoSelect > .CaptionCont > span {
  padding: 8px 0 8px 0;
  font-size: 13px;
  font-weight: 500;
  font-style: italic;
}

#affiliate-body #contact-box .right-content #form-affiliate .form-group .SumoSelect > .CaptionCont > span.placeholder {
  color: #cccccc;
}

#affiliate-body #contact-box .right-content #form-affiliate .form-group .SumoSelect > .optWrapper ul li.disabled {
  display: none;
}

#affiliate-body #contact-box .right-content #form-affiliate .form-group .form-control {
  height: 50px;
  line-height: 1;
  font-style: italic;
  font-size: 13px;
  transition: background-color .5s, border .5s;
  padding: 5px;
}

#affiliate-body #contact-box .right-content #form-affiliate .form-group .form-control:focus {
  background-color: #cffff0;
}

#affiliate-body #contact-box .right-content #form-affiliate .form-group ::-webkit-input-placeholder {
  /* Chrome */
  color: #cccccc;
  font-style: italic;
}

#affiliate-body #contact-box .right-content #form-affiliate .form-group :-ms-input-placeholder {
  /* IE 10+ */
  color: #cccccc;
  font-style: italic;
}

#affiliate-body #contact-box .right-content #form-affiliate .form-group ::-moz-placeholder {
  /* Firefox 19+ */
  color: #cccccc;
  font-style: italic;
  opacity: 1;
}

#affiliate-body #contact-box .right-content #form-affiliate .form-group :-moz-placeholder {
  /* Firefox 4 - 18 */
  color: #cccccc;
  font-style: italic;
  opacity: 1;
}

#affiliate-body #contact-box .right-content #form-affiliate .btn-group {
  display: block;
}

#affiliate-body #contact-box .right-content #form-affiliate .btn-group .btn-effect-1 {
  width: 100%;
}

#affiliate-body #contact-box .right-content #form-affiliate .btn-group .btn-effect-1 span {
  font-weight: bold;
}

#affiliate-body #contact-box .right-content #form-affiliate .btn-group .btn-effect-1:not(.btn-disabled):after {
  background-color: inherit;
}

#affiliate-body #contact-box .right-content .title {
  font-weight: 500;
  font-size: 30px;
  color: #02CD8D;
  line-height: 28px;
}

#affiliate-body #contact-box .right-content .header {
  font-weight: 300;
  font-size: 16px;
  padding: 7px 0;
}

#affiliate-body .content-faqs {
  background-color: #fff;
}

#affiliate-body .content-faqs .icon {
  text-align: center;
  position: relative;
  top: -64px;
}

#affiliate-body .content-faqs .title {
  padding: 10px 0 35px;
  position: relative;
  text-align: center;
  font-size: 36px;
  font-weight: 300;
  border-bottom: 1px solid #28f2b2;
}

#affiliate-body .content-faqs .title:after {
  position: absolute;
  display: block;
  content: '';
  width: 275px;
  height: 3px;
  bottom: 0;
  top: 98%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #28f2b2;
}

#affiliate-body .content-faqs .tab-body {
  padding: 85px 40px 85px;
}

#affiliate-body .content-faqs .tab-body .item {
  padding-bottom: 30px;
}

#affiliate-body .content-faqs .tab-body .item .header {
  font-weight: bold;
  padding-bottom: 15px;
  font-size: 16px;
}

#affiliate-body .content-faqs .tab-body .item .content {
  font-weight: 300;
  padding-bottom: 30px;
  font-size: 16px;
}

#affiliate-body .content-faqs .tab-body .item .content a {
  color: #28f2b2;
  text-decoration: none;
}

@media only screen and (max-width: 991px) {
  #affiliate-banner {
    height: 400px;
  }
  #affiliate-banner .inner .description {
    width: 620px;
  }
  #affiliate-body #contact-box {
    max-width: 570px;
    margin-bottom: 0px;
  }
  #affiliate-body #contact-box .right-content {
    width: 100%;
  }
  #affiliate-body #contact-box .right-content #form-affiliate {
    padding: 50px 70px 60px;
  }
  #affiliate-body #contact-box .right-content #form-affiliate .form-group .col-md-6 {
    margin-bottom: 15px;
  }
  #affiliate-body .content-faqs {
    background-color: #e5edf2;
  }
  #affiliate-body .content-faqs .title {
    padding: 10px 60px 35px;
  }
  #affiliate-body .content-faqs .tab-body {
    padding: 60px 65px 30px;
  }
  #affiliate-body .content-faqs .tab-body .item .header {
    padding-bottom: 20px;
  }
  #affiliate-body .content-faqs .tab-body .item .content {
    padding-bottom: 0px;
  }
}

@media only screen and (max-width: 767px) {
  #affiliate-banner {
    height: 450px;
  }
  #affiliate-banner .inner {
    top: 45%;
  }
  #affiliate-banner .inner .title {
    font-size: 24px;
    width: 270px;
  }
  #affiliate-banner .inner .description {
    font-size: 18px;
    width: 270px;
  }
  #affiliate-body {
    background-color: #ffffff;
  }
  #affiliate-body #contact-box {
    padding: 0;
  }
  #affiliate-body #contact-box .right-content .title {
    font-size: 24px;
  }
  #affiliate-body #contact-box .right-content .header {
    font-size: 14px;
    padding-right: 10px;
  }
  #affiliate-body #contact-box .right-content #form-affiliate {
    padding: 40px 30px 90px;
  }
  #affiliate-body #contact-box .right-content #form-affiliate .form-group .col-xs-4 {
    padding-right: 0px;
  }
  #affiliate-body .content-faqs .icon {
    text-align: center;
    position: relative;
    top: -135px;
    z-index: 5;
  }
  #affiliate-body .content-faqs .title {
    padding: 10px 40px 25px;
    font-size: 24px;
    border-bottom: 1px solid #28f2b2;
    margin-top: -110px;
  }
  #affiliate-body .content-faqs .title:after {
    width: 200px;
  }
  #affiliate-body .content-faqs .tab-body {
    padding: 25px 15px 30px;
  }
}

body {
  width: 100%;
  overflow-x: hidden;
}

#landing-page-banner {
  font-family: 'Roboto', sans-serif;
  scroll-behavior: smooth;
}

#landing-page-banner .inner {
  position: relative;
}

#landing-page-banner .inner .banner_parallax {
  z-index: -100;
  min-height: 640px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

#landing-page-banner .inner .container {
  position: absolute;
  top: 35%;
  transform: translateY(-35%);
  left: 0;
  right: 0;
}

#landing-page-banner .inner .required-category {
  font-size: 28px;
  color: #ffffff;
  font-weight: bold;
}

#landing-page-banner .inner .required-category .fields.service {
  padding: 10px 0;
  position: relative;
  text-align: left;
  margin-left: calc(50% - 50px);
  margin-top: 15px;
}

#landing-page-banner .inner .required-category .fields.service .category-icon {
  float: left;
  position: absolute;
  left: -100px;
  top: 0%;
}

#landing-page-banner .inner .required-category .fields.service .category-icon img {
  height: 70px;
}

#landing-page-banner .inner .required-category .fields .selected {
  position: relative;
  cursor: pointer;
}

#landing-page-banner .inner .required-category .fields .selected .text {
  color: #28f1b1;
  font-size: 34px;
  border-bottom: 3px solid #28f1b1;
}

#landing-page-banner .inner .required-category .fields .options {
  z-index: 5;
  position: absolute;
  font-weight: 500;
  display: none;
  width: 560px;
  overflow-x: hidden;
}

#landing-page-banner .inner .required-category .fields .options::-webkit-scrollbar {
  width: 0px;
  /* remove scrollbar space */
  background: transparent;
  /* optional: just make scrollbar invisible */
}

#landing-page-banner .inner .required-category .fields .options.active {
  display: block;
  background: #ffffff;
  max-width: 560px;
  cursor: inherit;
  z-index: 9999999;
}

#landing-page-banner .inner .required-category .fields .options.active ul {
  max-height: 240px;
  overflow-y: auto;
  margin-right: -17px;
  list-style: none;
  font-size: 16px;
  padding-left: 0px;
  margin-bottom: 0;
}

#landing-page-banner .inner .required-category .fields .options.active ul li {
  cursor: pointer;
}

#landing-page-banner .inner .required-category .fields .options.active ul li.active {
  cursor: inherit;
}

#landing-page-banner .inner .required-category .fields .options.active ul li.active a {
  color: #28f1b1;
}

#landing-page-banner .inner .required-category .fields .options.active ul li:hover {
  background-color: #28f1b1;
  color: #ffffff;
  transition: all 0.5s ease-out;
}

#landing-page-banner .inner .required-category .fields .options.active ul li:hover a {
  color: #ffffff;
}

#landing-page-banner .inner .required-category .fields .options.active ul li a {
  color: #18293e;
  text-decoration: none;
  padding: 10px 0px 10px 20px;
  display: block;
}

#landing-page-banner .inner .required-category .fields .options .view-more {
  position: absolute;
  bottom: 0;
  width: 560px;
  height: 50px;
  text-align: center;
  padding-top: 17px;
  border-radius: 2px;
  color: #28f1b1;
  font-size: 12px;
  background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0.5), #fff);
}

#landing-page-banner .inner .required-package-location {
  font-size: 28px;
  color: #ffffff;
  padding-bottom: 50px;
  padding-top: 15px;
  text-align: center;
}

#landing-page-banner .inner .required-package-location .fields {
  display: inline-block;
}

#landing-page-banner .inner .required-package-location .fields .options {
  z-index: 5;
  position: absolute;
  font-weight: 500;
  display: none;
  width: 560px;
  overflow-x: hidden;
}

#landing-page-banner .inner .required-package-location .fields .options::-webkit-scrollbar {
  width: 0px;
  /* remove scrollbar space */
  background: transparent;
  /* optional: just make scrollbar invisible */
}

#landing-page-banner .inner .required-package-location .fields .options.active {
  display: block;
  background: #ffffff;
  max-width: 450px;
  text-align: left;
}

#landing-page-banner .inner .required-package-location .fields .options.active ul {
  max-height: 240px;
  overflow-y: auto;
  margin-right: -17px;
  list-style: none;
  font-size: 16px;
  padding-left: 0px;
  margin-bottom: 0;
}

#landing-page-banner .inner .required-package-location .fields .options.active ul li {
  cursor: pointer;
}

#landing-page-banner .inner .required-package-location .fields .options.active ul li.active {
  cursor: inherit;
}

#landing-page-banner .inner .required-package-location .fields .options.active ul li.active a {
  color: #28f1b1;
}

#landing-page-banner .inner .required-package-location .fields .options.active ul li:hover {
  background-color: #28f1b1;
  color: #ffffff;
  transition: all 0.5s ease-out;
}

#landing-page-banner .inner .required-package-location .fields .options.active ul li:hover a {
  color: #ffffff;
}

#landing-page-banner .inner .required-package-location .fields .options.active ul li a {
  color: #18293e;
  text-decoration: none;
  padding: 10px 0px 10px 20px;
  display: block;
}

#landing-page-banner .inner .required-package-location .fields .options .view-more {
  position: absolute;
  bottom: 0;
  width: 450px;
  height: 50px;
  text-align: center;
  padding-top: 17px;
  border-radius: 2px;
  color: #28f1b1;
  font-size: 12px;
  background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0.5), #fff);
}

#landing-page-banner .inner .required-package-location .fields .selected {
  cursor: pointer;
}

#landing-page-banner .inner .required-package-location .text {
  color: #28f1b1;
  border-bottom: 3px solid #28f1b1;
}

#landing-page-banner .inner .required-package-location .fa {
  color: #28f1b1;
  border: 2px solid #28f1b1;
  border-radius: 50%;
  padding-left: 3px;
  padding-right: 3px;
  margin-right: 30px;
  font-size: 22px;
}

#landing-page-banner .inner .find {
  padding-top: 10px;
}

#landing-page-banner .inner .find a {
  padding: 20px 40px;
  background-color: #28f1b1;
  color: #18293e;
  font-size: 16px;
  border-radius: 3px;
  text-decoration: none;
  cursor: pointer;
}

#landing-page-banner .inner .find a:hover {
  opacity: 1;
  transition: all 0.5s ease-out;
  background-color: #18293e;
  color: #28f1b1;
}

#landing-page-banner .inner .arrow {
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  position: absolute;
  bottom: -150px;
  left: 50%;
  transform: translateX(-50%);
}

#landing-page-banner .inner .arrow .fa-chevron-down {
  padding-bottom: 20px;
  font-size: 30px;
}

#landing-page-banner .inner .arrow .mouse {
  max-width: 2rem;
  width: 100%;
  height: auto;
}

#landing-page-banner .inner .arrow .scroll {
  animation-name: scroll;
  animation-duration: 1.5s;
  animation-timing-function: cubic-bezier(0.65, -0.55, 0.25, 1.5);
  animation-iteration-count: infinite;
  transform-origin: 50% 20.5px;
  will-change: transform;
}

@keyframes scroll {
  0%, 20% {
    transform: translateY(0) scaleY(1);
  }
  10% {
    opacity: 1;
  }
  100% {
    transform: translateY(36px) scaleY(2);
    opacity: 0.01;
  }
}

#landing-page-content {
  font-family: 'Roboto', sans-serif;
  color: #18293e;
  scroll-behavior: smooth;
}

#landing-page-content #market-insight .insight-wrapper {
  padding: 30px 90px 50px;
  border: 2px solid #eaf0f7;
  background-color: #ffffff;
  margin-top: -50px;
}

#landing-page-content #market-insight .insight-wrapper .insight-title {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 20px;
}

#landing-page-content #market-insight .insight-wrapper .insight-description {
  float: left;
  display: inline-block;
  font-size: 14px;
  padding-bottom: 30px;
}

#landing-page-content #market-insight .insight-wrapper .insight-content-wrapper .insight-contents .slick-prev, #landing-page-content #market-insight .insight-wrapper .insight-content-wrapper .insight-contents .slick-next {
  opacity: 1;
  width: 30px;
  height: 30px;
}

#landing-page-content #market-insight .insight-wrapper .insight-content-wrapper .insight-contents .slick-prev:hover::before, #landing-page-content #market-insight .insight-wrapper .insight-content-wrapper .insight-contents .slick-next:hover::before {
  color: #28f1b1;
}

#landing-page-content #market-insight .insight-wrapper .insight-content-wrapper .insight-contents .slick-prev::before, #landing-page-content #market-insight .insight-wrapper .insight-content-wrapper .insight-contents .slick-next::before {
  color: #18293e;
  font-size: 30px;
  cursor: pointer;
}

#landing-page-content #market-insight .insight-wrapper .insight-content-wrapper .insight-contents .slick-prev {
  left: -45px;
  top: 40%;
}

#landing-page-content #market-insight .insight-wrapper .insight-content-wrapper .insight-contents .slick-prev::before {
  content: '\f137';
}

#landing-page-content #market-insight .insight-wrapper .insight-content-wrapper .insight-contents .slick-next {
  right: -45px;
  top: 40%;
}

#landing-page-content #market-insight .insight-wrapper .insight-content-wrapper .insight-contents .slick-next::before {
  content: '\f138';
}

#landing-page-content #market-insight .insight-wrapper .insight-content-wrapper .insight-contents .insight-content {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}

#landing-page-content #market-insight .insight-wrapper .insight-content-wrapper .insight-contents .insight-content:hover .insight-content-image .more {
  display: block;
}

#landing-page-content #market-insight .insight-wrapper .insight-content-wrapper .insight-contents .insight-content .insight-content-image {
  padding-bottom: 20px;
  cursor: pointer;
  position: relative;
}

#landing-page-content #market-insight .insight-wrapper .insight-content-wrapper .insight-contents .insight-content .insight-content-image img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

#landing-page-content #market-insight .insight-wrapper .insight-content-wrapper .insight-contents .insight-content .insight-content-image img::after {
  background: red;
  opacity: 0.5;
}

#landing-page-content #market-insight .insight-wrapper .insight-content-wrapper .insight-contents .insight-content .insight-content-image .more {
  display: none;
  background: rgba(24, 40, 62, 0.8);
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 200px;
  transform: translate(-50%, -55%);
}

#landing-page-content #market-insight .insight-wrapper .insight-content-wrapper .insight-contents .insight-content .insight-content-image .more span {
  font-weight: bold;
  color: #02cd8d;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#landing-page-content #market-insight .insight-wrapper .insight-content-wrapper .insight-contents .insight-content .insight-content-title {
  font-weight: bold;
}

#landing-page-content #market-insight .insight-wrapper .insight-content-wrapper .insight-contents .insight-content .insight-content-date, #landing-page-content #market-insight .insight-wrapper .insight-content-wrapper .insight-contents .insight-content .insight-content-writer {
  color: #71839c;
}

#landing-page-content #how-hire-agency {
  margin: 0;
  padding: 95px 0 120px;
  z-index: -1;
}

#landing-page-content #how-hire-agency .title {
  font-size: 30px;
  font-weight: bold;
  position: relative;
}

#landing-page-content #how-hire-agency .title span {
  color: #18d197;
}

#landing-page-content #how-hire-agency .hire-row {
  margin: 0 auto;
  padding-top: 50px;
  padding-left: 85px;
  padding-right: 85px;
}

#landing-page-content #how-hire-agency .hire-row .step-1, #landing-page-content #how-hire-agency .hire-row .step-2, #landing-page-content #how-hire-agency .hire-row .step-3 {
  position: relative;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  color: #18293e;
}

#landing-page-content #how-hire-agency .hire-row .step-1 .image-wrapper, #landing-page-content #how-hire-agency .hire-row .step-2 .image-wrapper, #landing-page-content #how-hire-agency .hire-row .step-3 .image-wrapper {
  margin-bottom: 30px;
  position: relative;
  height: 170px;
  width: 100%;
}

#landing-page-content #how-hire-agency .hire-row .step-1 .image-wrapper img, #landing-page-content #how-hire-agency .hire-row .step-2 .image-wrapper img, #landing-page-content #how-hire-agency .hire-row .step-3 .image-wrapper img {
  display: block;
  max-height: 100%;
  max-width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#landing-page-content #how-hire-agency .hire-row .step-1 .legend, #landing-page-content #how-hire-agency .hire-row .step-2 .legend, #landing-page-content #how-hire-agency .hire-row .step-3 .legend {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #18d197;
}

#landing-page-content #how-hire-agency .hire-row .step-1 .description, #landing-page-content #how-hire-agency .hire-row .step-2 .description, #landing-page-content #how-hire-agency .hire-row .step-3 .description {
  font-size: 16px;
  line-height: 20px;
}

#landing-page-content #how-hire-agency .hire-row .step-1:not(:first-child):before, #landing-page-content #how-hire-agency .hire-row .step-2:not(:first-child):before, #landing-page-content #how-hire-agency .hire-row .step-3:not(:first-child):before {
  color: #28f2b2;
  font: normal normal normal 40px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f105";
  position: absolute;
  right: 100%;
  top: 60px;
}

#landing-page-content #video-2easy {
  padding: 60px 0;
  background-color: #28f1b1;
}

#landing-page-content #video-2easy .info-wrapper {
  height: 340px;
  position: relative;
}

#landing-page-content #video-2easy .info-wrapper .col-left {
  font-size: 26px;
  font-weight: bold;
  padding-right: 650px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

#landing-page-content #video-2easy .info-wrapper .col-left .legend span {
  color: #ffffff;
}

#landing-page-content #video-2easy .info-wrapper .col-right {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}

#landing-page-content #why-need {
  padding: 85px 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#landing-page-content #why-need.video-production .graph #dot-1 {
  top: 77%;
  left: 20.2%;
}

#landing-page-content #why-need.video-production .graph #dot-2 {
  top: 72.2%;
  left: 58.3%;
}

#landing-page-content #why-need.video-production .graph #dot-3 {
  top: 50.4%;
  left: 96.1%;
}

#landing-page-content #why-need.video-production .graph #dot-4 {
  top: 74.9%;
  left: 20.2%;
}

#landing-page-content #why-need.video-production .graph #dot-5 {
  top: 68.7%;
  left: 58.3%;
}

#landing-page-content #why-need.video-production .graph #dot-6 {
  top: 35.3%;
  left: 96.1%;
}

#landing-page-content #why-need.video-production .graph #dot-7 {
  top: 72.5%;
  left: 20.2%;
}

#landing-page-content #why-need.video-production .graph #dot-8 {
  top: 65.2%;
  left: 58.3%;
}

#landing-page-content #why-need.video-production .graph #dot-9 {
  top: 20%;
  left: 96.1%;
}

#landing-page-content #why-need.ar-3d-vr .graph #dot-1 {
  top: 73.4%;
  left: 20.5%;
}

#landing-page-content #why-need.ar-3d-vr .graph #dot-2 {
  top: 68.1%;
  left: 58.8%;
}

#landing-page-content #why-need.ar-3d-vr .graph #dot-3 {
  top: 46.2%;
  left: 96.3%;
}

#landing-page-content #why-need.ar-3d-vr .graph #dot-4 {
  top: 72.6%;
  left: 20.5%;
}

#landing-page-content #why-need.ar-3d-vr .graph #dot-5 {
  top: 63.8%;
  left: 58.8%;
}

#landing-page-content #why-need.ar-3d-vr .graph #dot-6 {
  top: 37.7%;
  left: 96.3%;
}

#landing-page-content #why-need.ar-3d-vr .graph #dot-7 {
  top: 71.6%;
  left: 20.5%;
}

#landing-page-content #why-need.ar-3d-vr .graph #dot-8 {
  top: 59.3%;
  left: 58.8%;
}

#landing-page-content #why-need.ar-3d-vr .graph #dot-9 {
  top: 24.4%;
  left: 96.3%;
}

#landing-page-content #why-need.branding .graph #dot-1 {
  top: 73.1%;
  left: 20.2%;
}

#landing-page-content #why-need.branding .graph #dot-2 {
  top: 62.8%;
  left: 58.6%;
}

#landing-page-content #why-need.branding .graph #dot-3 {
  top: 35.3%;
  left: 96.1%;
}

#landing-page-content #why-need.branding .graph #dot-4 {
  top: 71.1%;
  left: 20.2%;
}

#landing-page-content #why-need.branding .graph #dot-5 {
  top: 60.8%;
  left: 58.6%;
}

#landing-page-content #why-need.branding .graph #dot-6 {
  top: 30.5%;
  left: 96.1%;
}

#landing-page-content #why-need.branding .graph #dot-7 {
  top: 69.1%;
  left: 20.2%;
}

#landing-page-content #why-need.branding .graph #dot-8 {
  top: 50.5%;
  left: 58.6%;
}

#landing-page-content #why-need.branding .graph #dot-9 {
  top: 19.9%;
  left: 96.1%;
}

#landing-page-content #why-need.e-commerce .graph #dot-1 {
  top: 79.4%;
  left: 20.5%;
}

#landing-page-content #why-need.e-commerce .graph #dot-2 {
  top: 75.1%;
  left: 58.8%;
}

#landing-page-content #why-need.e-commerce .graph #dot-3 {
  top: 50.5%;
  left: 96.3%;
}

#landing-page-content #why-need.e-commerce .graph #dot-4 {
  top: 78.4%;
  left: 20.5%;
}

#landing-page-content #why-need.e-commerce .graph #dot-5 {
  top: 72%;
  left: 58.8%;
}

#landing-page-content #why-need.e-commerce .graph #dot-6 {
  top: 32.4%;
  left: 96.3%;
}

#landing-page-content #why-need.e-commerce .graph #dot-7 {
  top: 77%;
  left: 20.5%;
}

#landing-page-content #why-need.e-commerce .graph #dot-8 {
  top: 65.9%;
  left: 58.8%;
}

#landing-page-content #why-need.e-commerce .graph #dot-9 {
  top: 20%;
  left: 96.3%;
}

#landing-page-content #why-need.mobile-app .graph #dot-1 {
  top: 76.5%;
  left: 20.5%;
}

#landing-page-content #why-need.mobile-app .graph #dot-2 {
  top: 70.2%;
  left: 58.8%;
}

#landing-page-content #why-need.mobile-app .graph #dot-3 {
  top: 44.5%;
  left: 96.3%;
}

#landing-page-content #why-need.mobile-app .graph #dot-4 {
  top: 75.3%;
  left: 20.5%;
}

#landing-page-content #why-need.mobile-app .graph #dot-5 {
  top: 65.9%;
  left: 58.8%;
}

#landing-page-content #why-need.mobile-app .graph #dot-6 {
  top: 29.4%;
  left: 96.3%;
}

#landing-page-content #why-need.mobile-app .graph #dot-7 {
  top: 73.9%;
  left: 20.5%;
}

#landing-page-content #why-need.mobile-app .graph #dot-8 {
  top: 62.8%;
  left: 58.8%;
}

#landing-page-content #why-need.mobile-app .graph #dot-9 {
  top: 20%;
  left: 96.3%;
}

#landing-page-content #why-need.marketing .graph #dot-1 {
  top: 73.1%;
  left: 20.2%;
}

#landing-page-content #why-need.marketing .graph #dot-2 {
  top: 62.9%;
  left: 58.5%;
}

#landing-page-content #why-need.marketing .graph #dot-3 {
  top: 19.9%;
  left: 96.1%;
}

#landing-page-content #why-need.marketing .graph #dot-4 {
  top: 71.1%;
  left: 20.2%;
}

#landing-page-content #why-need.marketing .graph #dot-5 {
  top: 60.8%;
  left: 58.5%;
}

#landing-page-content #why-need.marketing .graph #dot-6 {
  top: 30.4%;
  left: 96.1%;
}

#landing-page-content #why-need.marketing .graph #dot-7 {
  top: 69%;
  left: 20.2%;
}

#landing-page-content #why-need.marketing .graph #dot-8 {
  top: 50.6%;
  left: 58.5%;
}

#landing-page-content #why-need.marketing .graph #dot-9 {
  top: 35.3%;
  left: 96.1%;
}

#landing-page-content #why-need.web .graph #dot-1 {
  top: 79.6%;
  left: 20.5%;
}

#landing-page-content #why-need.web .graph #dot-2 {
  top: 74.9%;
  left: 58.8%;
}

#landing-page-content #why-need.web .graph #dot-3 {
  top: 50.5%;
  left: 96.3%;
}

#landing-page-content #why-need.web .graph #dot-4 {
  top: 78.2%;
  left: 20.5%;
}

#landing-page-content #why-need.web .graph #dot-5 {
  top: 70.9%;
  left: 58.8%;
}

#landing-page-content #why-need.web .graph #dot-6 {
  top: 31.5%;
  left: 96.3%;
}

#landing-page-content #why-need.web .graph #dot-7 {
  top: 76.8%;
  left: 20.5%;
}

#landing-page-content #why-need.web .graph #dot-8 {
  top: 65.9%;
  left: 58.8%;
}

#landing-page-content #why-need.web .graph #dot-9 {
  top: 20%;
  left: 96.3%;
}

#landing-page-content #why-need .title {
  font-size: 30px;
  padding-bottom: 40px;
  font-weight: bold;
  margin: 0px;
}

#landing-page-content #why-need .title span {
  color: #18d197;
}

#landing-page-content #why-need [class^=content-] {
  padding-bottom: 20px;
  position: relative;
}

#landing-page-content #why-need [class^=content-] .legend {
  font-size: 24px;
  font-weight: bold;
  color: #18d197;
}

#landing-page-content #why-need [class^=content-] .description {
  font-size: 16px;
  line-height: 20px;
}

#landing-page-content #why-need .graph {
  width: calc(100% - 52px);
  text-align: center;
  position: relative;
}

#landing-page-content #why-need .graph img {
  width: 100%;
}

#landing-page-content #why-need .graph .dot {
  transform: translate(-50%, -50%);
  height: 5px;
  width: 5px;
  background-color: #18293e;
  border-radius: 50%;
  display: inline-block;
  z-index: 99;
  position: absolute;
  cursor: pointer;
}

#landing-page-content #why-need .graph .dot.active {
  z-index: 100;
}

#landing-page-content #why-need .graph .dot.active .detail {
  display: block;
}

#landing-page-content #why-need .graph .dot .detail {
  z-index: 999999999;
  display: none;
  background-color: #18293e;
  font-size: 10px;
  color: #ffffff;
  white-space: nowrap;
  position: absolute;
  bottom: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
  border-radius: 10px;
}

#landing-page-content #why-need .graph .dot .detail strong {
  color: #28f1b1;
}

#landing-page-content #why-need .graph .dot .detail::after {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 10px solid #18293e;
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, calc(100% - 1px));
}

#landing-page-content #why-need .button {
  padding-top: 25px;
  position: relative;
}

#landing-page-content #why-need .button a {
  color: #18293e;
  background-color: #28f1b1;
  padding: 15px 40px;
  font-weight: bold;
  text-decoration: none;
  border-radius: 2px;
}

#landing-page-content #why-need .button a:hover {
  color: #28f1b1;
  background-color: #18293e;
  transition: all .3s ease-in;
}

#landing-page-content #faqs {
  background-color: #f2f2f2;
  padding: 100px 0;
}

#landing-page-content #faqs .title {
  font-size: 30px;
  font-weight: bold;
}

#landing-page-content #faqs .title span {
  font-size: 16px;
  font-weight: normal;
}

#landing-page-content #faqs .answer-box {
  margin-top: 30px;
  padding-left: 200px;
  position: relative;
}

#landing-page-content #faqs .answer-box .answer-icon {
  float: left;
  position: absolute;
  left: 0;
  top: 0%;
}

#landing-page-content #faqs .answer-box .answer-icon img {
  height: 170px;
}

#landing-page-content #faqs .answer-box .answer .slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

#landing-page-content #faqs .answer-box .answer .slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
  left: -30px;
}

#landing-page-content #faqs .answer-box .answer .slick-dots li.slick-active button {
  background-color: #28f1b1;
}

#landing-page-content #faqs .answer-box .answer .slick-dots li.slick-active button::before {
  opacity: 1;
  color: #28f2b2;
}

#landing-page-content #faqs .answer-box .answer .slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  background-color: #18293e;
  border: 0;
  outline: none;
}

#landing-page-content #faqs .answer-box .answer .slick-dots li button::before {
  opacity: 1;
  font-size: 15px;
  font-family: 'slick';
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '•';
  text-align: center;
}

#landing-page-content #faqs .answer-box .answer .answer-message {
  background: #ffffff;
  padding: 30px 60px;
  margin-bottom: 15px;
  border-radius: 20px;
  display: block;
  margin-right: 30px;
  outline: 0;
}

#landing-page-content #faqs .answer-box .answer .answer-message .question .title {
  font-size: 24px;
  font-weight: bold;
}

#landing-page-content #faqs .answer-box .answer .answer-message .question .content {
  font-size: 18px;
  font-weight: normal;
}

#landing-page-content #faqs .answer-box .answer .answer-message .question .content a {
  text-decoration: none;
  color: #28f1b1;
}

#landing-page-content #featured-agency {
  padding: 100px 0 90px;
}

#landing-page-content #featured-agency .title {
  font-size: 30px;
  font-weight: bold;
  padding-bottom: 40px;
}

#landing-page-content #featured-agency .title span {
  font-size: 16px;
  font-weight: normal;
}

#landing-page-content #featured-agency .title span.service-category {
  color: #18d197;
  font-size: 30px;
  font-weight: bold;
}

#landing-page-content #featured-agency .agency-logo .logo img {
  padding: 0px 15px;
  display: inline-block;
}

#landing-page-content #featured-agency .agency-logo .logo img .child:not(:hover) {
  opacity: 0.4;
}

#landing-page-content #featured-agency .agency-logo .logo img.hvr-bounce-in:hover {
  transform: scale(1.3) !important;
  z-index: 5;
}

#landing-page-content #rating {
  text-align: center;
  background-color: #eaf0f7;
  padding: 60px 0px 90px;
}

#landing-page-content #rating .title {
  font-size: 28px;
  font-weight: bold;
  padding-bottom: 30px;
}

#landing-page-content #rating .rating-score .info {
  padding: 45px 30px 30px;
  background-color: #ffffff;
  width: 32%;
  float: left;
  margin-right: 15px;
}

#landing-page-content #rating .rating-score .title {
  font-size: 16px;
  font-weight: inherit;
  color: #70839c;
}

#landing-page-content #rating .rating-score .title span {
  font-weight: bold;
}

#landing-page-content #rating .rating-score .description {
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 30px;
}

#landing-page-content #rating .rating-score .marks {
  padding-bottom: 10px;
}

#landing-page-content #rating .rating-score .marks .col-left .info-row:after {
  content: '';
  display: block;
  clear: both;
}

#landing-page-content #rating .rating-score .marks .col-left .info-row .info-label {
  float: left;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.8;
}

#landing-page-content #rating .rating-score .marks .col-left .info-row .info-value {
  float: right;
  position: relative;
}

#landing-page-content #rating .rating-score .marks .col-left .info-row .info-value #Isolation_Mode {
  margin: 0 auto;
}

#landing-page-content #rating .rating-score .marks .col-right {
  text-align: center;
}

#landing-page-content #rating .rating-score .marks .col-right .circles-text {
  font-size: 24px !important;
  color: #18293e !important;
}

#landing-page-content #rating .rating-score .marks .col-right .circles-text b {
  color: #18293e !important;
}

#landing-page-content #rating .rating-score .slick-dots {
  position: absolute;
  bottom: -55px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

#landing-page-content #rating .rating-score .slick-dots li {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
  left: -30px;
}

#landing-page-content #rating .rating-score .slick-dots li.slick-active button {
  background-color: #28f1b1;
}

#landing-page-content #rating .rating-score .slick-dots li.slick-active button::before {
  opacity: 1;
  color: #28f2b2;
}

#landing-page-content #rating .rating-score .slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 10px;
  height: 10px;
  padding: 5px;
  cursor: pointer;
  background-color: #71839c;
  border: 0;
  outline: none;
}

#landing-page-content #rating .rating-score .slick-dots li button::before {
  opacity: 1;
  font-size: 10px;
  font-family: 'slick';
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  content: '•';
  text-align: center;
}

#landing-page-content #about-category {
  padding: 100px 0;
  background-color: #f2f2f2;
}

#landing-page-content #about-category .title {
  padding-bottom: 40px;
}

#landing-page-content #about-category .title h1 {
  font-size: 30px;
  font-weight: bold;
  margin: 0;
}

#landing-page-content #about-category .title span {
  font-size: 16px;
  font-weight: normal;
}

#landing-page-content #about-category .title span.service-category {
  color: #18d197;
  font-size: 30px;
  font-weight: bold;
}

#landing-page-content #about-category .content {
  font-size: 16px;
  display: inline-block;
}

#landing-page-content #about-category .content .left-description {
  float: left;
  display: inline-block;
  width: 50%;
}

#landing-page-content #about-category .content .left-description .description {
  padding-bottom: 35px;
  padding-right: 35px;
}

#landing-page-content #about-category .content .left-description .description .title {
  font-size: 24px;
  padding-bottom: 10px;
}

#landing-page-content #about-category .content .left-description .description .title.first {
  color: #18293e;
}

#landing-page-content #about-category .content .left-description .description .info strong {
  font-size: 16px;
  color: #18d197;
}

#landing-page-content #about-category .content .right-description {
  width: 50%;
  display: inline-block;
  float: left;
}

#landing-page-content #about-category .content .right-description .description {
  padding-bottom: 35px;
  padding-left: 35px;
}

#landing-page-content #about-category .content .right-description .description .title {
  font-size: 24px;
  padding-bottom: 10px;
}

#landing-page-content #about-category .content .right-description .description .info strong {
  font-size: 16px;
  color: #18d197;
}

#landing-page-content #about-category .link {
  padding: 60px 0;
}

#landing-page-content #about-category .link .title {
  font-size: 24px;
  padding-bottom: 40px;
}

#landing-page-content #about-category .link .info {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #ffffff;
  margin-right: 30px;
  width: 31%;
  height: 300px;
  position: relative;
}

#landing-page-content #about-category .link .info a {
  color: #ffffff;
}

#landing-page-content #about-category .link .info:hover {
  cursor: pointer;
}

#landing-page-content #about-category .link .info .content {
  font-size: 28px;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#landing-page-content #about-category .link .info .content.eng_version {
  font-size: 24px;
  line-height: 30px;
}

#landing-page-content #about-category .link .info.facebook {
  background-image: url("../../images/root/price-guide/other/facebook.jpg");
}

#landing-page-content #about-category .link .info.facebook:hover {
  background-color: #19293e;
  background-image: none;
}

#landing-page-content #about-category .link .info.facebook:hover .enter {
  display: block;
}

#landing-page-content #about-category .link .info.sem {
  background-image: url("../../images/root/price-guide/other/sem.jpg");
}

#landing-page-content #about-category .link .info.sem:hover {
  background-color: #19293e;
  background-image: none;
}

#landing-page-content #about-category .link .info.sem:hover .enter {
  display: block;
}

#landing-page-content #about-category .link .info.seo {
  background-image: url("../../images/root/price-guide/other/seo.jpg");
}

#landing-page-content #about-category .link .info.seo:hover {
  background-color: #19293e;
  background-image: none;
}

#landing-page-content #about-category .link .info.seo:hover .enter {
  display: block;
}

#landing-page-content #about-category .link .info .enter {
  width: 30px;
  fill: #29f2b1;
  position: absolute;
  right: 0px;
  bottom: 0px;
  margin: 0 20px 20px 0;
  display: none;
}

#landing-page-content #about-category .find {
  padding-top: 25px;
  position: relative;
}

#landing-page-content #about-category .find a {
  color: #18293e;
  background-color: #28f1b1;
  padding: 15px 40px;
  text-decoration: none;
  border-radius: 2px;
}

#landing-page-content #about-category .find a:hover {
  color: #28f1b1;
  background-color: #18293e;
  transition: all .3s ease-in;
}

#landing-page-content #project-package {
  padding: 80px 0 140px;
  background: linear-gradient(0deg, #54ffc9, #27f2f6);
  background-size: 400% 400%;
  animation: backgroundColorAnimation 8s ease infinite;
}

#landing-page-content #project-package .title {
  font-size: 30px;
  font-weight: bold;
  padding-bottom: 35px;
}

#landing-page-content #project-package .title span {
  color: #ffffff;
  font-size: 24px;
}

#landing-page-content #project-package .package-list {
  text-align: center;
  padding-bottom: 30px;
}

#landing-page-content #project-package .package-list [class^=package-] {
  position: relative;
  display: inline-block;
  padding: 5px 20px;
  border-radius: 20px;
  border: 2px solid #11344c;
  font-size: 14px;
  margin: 0 20px 20px 0;
  cursor: pointer;
}

#landing-page-content #project-package .package-list [class^=package-].active {
  background-color: #11344c;
  color: #ffffff;
}

#landing-page-content #project-package .package-list [class^=package-]:hover {
  background-color: #ffffff;
  color: #11344c;
  border-color: #ffffff;
  transition: all .3s ease-in;
}

#landing-page-content #project-package .find {
  position: relative;
}

#landing-page-content #project-package .find a {
  padding: 10px 25px;
  background-color: #11344c;
  color: #ffffff;
  font-size: 14px;
  border-radius: 3px;
  text-decoration: none;
  cursor: pointer;
}

#landing-page-content #project-package .find a:hover {
  opacity: 1;
  transition: all 0.5s ease-out;
  background-color: #ffffff;
  color: #11344c;
}

nav.type-listing > div .container > ul {
  text-align: center !important;
}

nav.type-listing > div .container > ul > li {
  display: inline-block !important;
  text-align: left !important;
  float: inherit !important;
}

@keyframes backgroundColorAnimation {
  0% {
    background-position: 100% 0%;
  }
  50% {
    background-position: 0% 100%;
  }
  100% {
    background-position: 100% 0%;
  }
}

/** class*/
.container {
  max-width: 1400px;
  padding-left: 30px;
  padding-right: 30px;
}

.rotate-center {
  animation: rotate-center 0.6s ease-in-out both;
}

/**
 * ----------------------------------------
 * animation rotate-center
 * ----------------------------------------
 */

@keyframes rotate-center {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}

@media only screen and (max-width: 991px) {
  #landing-page-banner .inner .required-category .fields .options {
    width: 410px;
  }
  #landing-page-banner .inner .required-category .fields .options .view-more {
    width: 100%;
  }
  #landing-page-content #market-insight .insight-wrapper {
    padding: 10px 70px 30px;
  }
  #landing-page-content #market-insight .insight-wrapper .insight-title {
    font-size: 26px;
  }
  #landing-page-content #how-hire-agency {
    padding: 50px 0;
  }
  #landing-page-content #how-hire-agency .title {
    font-size: 26px;
  }
  #landing-page-content #how-hire-agency .hire-row {
    margin: 0 auto;
    padding-top: 20px;
    padding-left: 30px;
    padding-right: 30px;
  }
  #landing-page-content #how-hire-agency .hire-row .step-1 .legend, #landing-page-content #how-hire-agency .hire-row .step-2 .legend, #landing-page-content #how-hire-agency .hire-row .step-3 .legend {
    font-size: 18px;
  }
  #landing-page-content #how-hire-agency .hire-row .step-1 .description, #landing-page-content #how-hire-agency .hire-row .step-2 .description, #landing-page-content #how-hire-agency .hire-row .step-3 .description {
    font-size: 14px;
  }
  #landing-page-content #video-2easy .info-wrapper {
    height: 170px;
  }
  #landing-page-content #video-2easy .info-wrapper .col-left {
    font-size: 20px;
    padding-right: 400px;
    top: 50%;
  }
  #landing-page-content #video-2easy .info-wrapper .col-right img {
    width: 50%;
    float: right;
  }
  #landing-page-content #why-need {
    padding: 50px 0;
  }
  #landing-page-content #why-need .title {
    font-size: 26px;
    padding-bottom: 30px;
  }
  #landing-page-content #why-need [class^=content-] .legend {
    font-size: 20px;
  }
  #landing-page-content #why-need [class^=content-] .description {
    font-size: 14px;
  }
  #landing-page-content #why-need .graph {
    margin: 0 auto;
    width: 70%;
  }
  #landing-page-content #why-need .button {
    text-align: center;
    padding-bottom: 50px;
  }
  #landing-page-content #faqs {
    padding: 60px 0;
  }
  #landing-page-content #faqs .title {
    font-size: 26px;
  }
  #landing-page-content #faqs .answer-box {
    margin-top: 30px;
    padding-left: 170px;
    position: relative;
  }
  #landing-page-content #faqs .answer-box .answer-icon {
    left: 30px;
  }
  #landing-page-content #faqs .answer-box .answer-icon img {
    height: 120px;
  }
  #landing-page-content #faqs .answer-box .answer .answer-message {
    padding: 15px 30px;
    margin-bottom: 10px;
  }
  #landing-page-content #faqs .answer-box .answer .answer-message .question .title {
    font-size: 20px;
  }
  #landing-page-content #faqs .answer-box .answer .answer-message .question .title span {
    font-size: 14px;
  }
  #landing-page-content #faqs .answer-box .answer .answer-message .question .content {
    font-size: 14px;
  }
  #landing-page-content #featured-agency {
    padding: 50px 0;
  }
  #landing-page-content #featured-agency .title {
    font-size: 26px;
    font-weight: bold;
    padding-bottom: 20px;
  }
  #landing-page-content #featured-agency .title span {
    font-size: 14px;
  }
  #landing-page-content #featured-agency .agency-logo .logo img {
    padding: 20px 15px;
  }
  #landing-page-content #about-category {
    padding: 50px 0;
  }
  #landing-page-content #about-category .title {
    font-size: 26px;
    padding-bottom: 30px;
  }
  #landing-page-content #about-category .title span {
    font-size: 14px;
  }
  #landing-page-content #about-category .content {
    font-size: 14px;
  }
  #landing-page-content #about-category .content .left-description .description {
    padding-bottom: 15px;
    padding-right: 25px;
  }
  #landing-page-content #about-category .content .left-description .description .title {
    font-size: 20px;
  }
  #landing-page-content #about-category .content .left-description .description .info strong {
    font-size: 14px;
  }
  #landing-page-content #about-category .content .right-description .description {
    padding-bottom: 15px;
    padding-left: 25px;
  }
  #landing-page-content #about-category .content .right-description .description .title {
    font-size: 20px;
    padding-bottom: 10px;
  }
  #landing-page-content #about-category .content .right-description .description .info strong {
    font-size: 14px;
  }
  #landing-page-content #about-category .link .info {
    margin: 1%;
    width: 31%;
    height: 200px;
  }
  #landing-page-content #about-category .link .info .content {
    font-size: 24px;
  }
  #landing-page-content #about-category .find {
    text-align: center;
    padding-bottom: 50px;
  }
  #landing-page-content #project-package {
    padding: 70px;
  }
  #landing-page-content #project-package .title {
    font-size: 26px;
    padding-bottom: 25px;
  }
  #landing-page-content #project-package .package-list {
    text-align: center;
    padding-bottom: 30px;
  }
  #landing-page-content #project-package .package-list [class^=package-] {
    font-size: 14px;
  }
  #landing-page-content #project-package .find {
    position: relative;
  }
  #landing-page-content #project-package .find a {
    padding: 10px 25px;
    background-color: #11344c;
    color: #ffffff;
    font-size: 14px;
    border-radius: 3px;
    text-decoration: none;
    cursor: pointer;
  }
  #landing-page-content #project-package .find a:hover {
    opacity: 1;
    transition: all 0.5s ease-out;
    background-color: #ffffff;
    color: #11344c;
  }
}

@media only screen and (max-width: 767px) {
  #landing-page-banner .inner .required-category {
    font-size: 20px;
    padding: 0;
  }
  #landing-page-banner .inner .required-category .fields.service {
    margin-left: 0;
    padding: 80px 0 0;
  }
  #landing-page-banner .inner .required-category .fields.service .category-icon {
    left: 50%;
    top: 20px;
    transform: translateX(-50%);
  }
  #landing-page-banner .inner .required-category .fields.service .category-icon img {
    height: 50px;
  }
  #landing-page-banner .inner .required-category .fields .selected {
    text-align: center;
  }
  #landing-page-banner .inner .required-category .fields .selected .text {
    font-size: 28px;
  }
  #landing-page-banner .inner .required-category .fields .options {
    width: 100%;
  }
  #landing-page-banner .inner .required-category .fields .options.active ul {
    font-size: 13px;
  }
  #landing-page-banner .inner .required-category .fields .options.active ul li a {
    padding-right: 18px;
  }
  #landing-page-banner .inner .required-package-location {
    font-size: 18px;
    line-height: 2;
    padding-bottom: 30px;
  }
  #landing-page-banner .inner .required-package-location .fields {
    display: inline-block;
  }
  #landing-page-banner .inner .required-package-location .fields .options {
    width: 100%;
  }
  #landing-page-banner .inner .required-package-location .fields .options.active {
    max-width: 245px;
  }
  #landing-page-banner .inner .required-package-location .fields .options.active ul {
    font-size: 13px;
    max-height: 220px;
  }
  #landing-page-banner .inner .required-package-location .fields .options.active ul li {
    width: 100%;
  }
  #landing-page-banner .inner .required-package-location .fields .options.active ul li a {
    padding-right: 0px;
  }
  #landing-page-banner .inner .required-package-location .fields .options .view-more {
    width: 100%;
  }
  #landing-page-banner .inner .required-package-location .fields .selected {
    cursor: pointer;
  }
  #landing-page-banner .inner .required-package-location .fa {
    font-size: 16px;
    margin-right: 0;
  }
  #landing-page-banner .inner .find a {
    padding: 13px 40px;
    font-size: 14px;
  }
  #landing-page-banner .inner .arrow {
    bottom: -150px;
  }
  #landing-page-banner .inner .arrow .fa-chevron-down {
    font-size: 20px;
  }
  #landing-page-content #market-insight .insight-wrapper {
    padding: 10px 20px;
  }
  #landing-page-content #market-insight .insight-wrapper .insight-title {
    font-size: 22px;
    text-align: center;
  }
  #landing-page-content #market-insight .insight-wrapper .insight-description {
    padding-bottom: 20px;
  }
  #landing-page-content #market-insight .insight-wrapper .insight-content-wrapper .insight-contents .slick-prev, #landing-page-content #market-insight .insight-wrapper .insight-content-wrapper .insight-contents .slick-next {
    width: 20px;
    height: 20px;
  }
  #landing-page-content #market-insight .insight-wrapper .insight-content-wrapper .insight-contents .slick-prev::before, #landing-page-content #market-insight .insight-wrapper .insight-content-wrapper .insight-contents .slick-next::before {
    font-size: 20px;
  }
  #landing-page-content #how-hire-agency {
    padding: 40px 0;
  }
  #landing-page-content #how-hire-agency .title {
    font-size: 22px;
  }
  #landing-page-content #how-hire-agency .hire-row {
    margin: 0 auto;
    padding-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
  }
  #landing-page-content #how-hire-agency .hire-row .step-1, #landing-page-content #how-hire-agency .hire-row .step-2, #landing-page-content #how-hire-agency .hire-row .step-3 {
    padding-bottom: 20px;
  }
  #landing-page-content #how-hire-agency .hire-row .step-1 .legend, #landing-page-content #how-hire-agency .hire-row .step-2 .legend, #landing-page-content #how-hire-agency .hire-row .step-3 .legend {
    font-size: 18px;
    margin-bottom: 5px;
  }
  #landing-page-content #how-hire-agency .hire-row .step-1 .description, #landing-page-content #how-hire-agency .hire-row .step-2 .description, #landing-page-content #how-hire-agency .hire-row .step-3 .description {
    font-size: 14px;
  }
  #landing-page-content #how-hire-agency .hire-row .step-1 .image-wrapper, #landing-page-content #how-hire-agency .hire-row .step-2 .image-wrapper, #landing-page-content #how-hire-agency .hire-row .step-3 .image-wrapper {
    margin-bottom: 0;
    height: 130px;
  }
  #landing-page-content #how-hire-agency .hire-row .step-1:not(:first-child):before, #landing-page-content #how-hire-agency .hire-row .step-2:not(:first-child):before, #landing-page-content #how-hire-agency .hire-row .step-3:not(:first-child):before {
    display: none;
  }
  #landing-page-content #video-2easy {
    height: 400px;
    padding: 40px 0;
  }
  #landing-page-content #video-2easy .info-wrapper {
    height: auto;
  }
  #landing-page-content #video-2easy .info-wrapper .col-left {
    text-align: center;
    transform: none;
    padding-right: 0;
  }
  #landing-page-content #video-2easy .info-wrapper .col-right {
    top: 140px;
  }
  #landing-page-content #video-2easy .info-wrapper .col-right img {
    width: 100%;
  }
  #landing-page-content #why-need {
    padding: 40px 0;
  }
  #landing-page-content #why-need .title {
    font-size: 22px;
    padding-bottom: 25px;
  }
  #landing-page-content #why-need [class^=content-] .legend {
    font-size: 18px;
  }
  #landing-page-content #why-need [class^=content-] .description {
    font-size: 14px;
  }
  #landing-page-content #why-need .graph {
    width: 100%;
  }
  #landing-page-content #why-need .button {
    text-align: center;
  }
  #landing-page-content #faqs {
    padding: 40px 0;
  }
  #landing-page-content #faqs .title {
    font-size: 22px;
  }
  #landing-page-content #faqs .title span {
    display: block;
    font-size: 14px;
  }
  #landing-page-content #faqs .answer-box {
    margin-top: 30px;
    margin-right: 0px;
    padding-left: 0;
  }
  #landing-page-content #faqs .answer-box .answer-icon {
    left: 50%;
    transform: translateX(-50%);
  }
  #landing-page-content #faqs .answer-box .answer-icon img {
    height: 120px;
  }
  #landing-page-content #faqs .answer-box .answer .answer-message {
    padding: 15px 30px;
    margin-top: 150px;
    margin-right: 5px;
    width: 100%;
  }
  #landing-page-content #faqs .answer-box .answer .answer-message .question .title {
    font-size: 18px;
  }
  #landing-page-content #faqs .answer-box .answer .answer-message .question .title span {
    font-size: 14px;
  }
  #landing-page-content #faqs .answer-box .answer .answer-message .question .content {
    font-size: 14px;
  }
  #landing-page-content #faqs .answer-box .answer .slick-dots li {
    left: 0;
  }
  #landing-page-content #featured-agency {
    padding: 40px 0;
  }
  #landing-page-content #featured-agency .title {
    font-size: 22px;
  }
  #landing-page-content #featured-agency .title span {
    font-size: 14px;
    display: block;
  }
  #landing-page-content #featured-agency .title span.service-category {
    font-size: 22px;
    display: inline-block;
  }
  #landing-page-content #featured-agency .agency-logo .logo img {
    width: 45%;
  }
  #landing-page-content #about-category {
    padding: 40px 0;
  }
  #landing-page-content #about-category .title {
    font-size: 22px;
    padding-bottom: 30px;
  }
  #landing-page-content #about-category .title span {
    font-size: 14px;
    display: block;
  }
  #landing-page-content #about-category .title span.service-category {
    font-size: 22px;
    display: inline-block;
  }
  #landing-page-content #about-category .content {
    font-size: 14px;
  }
  #landing-page-content #about-category .content .left-description {
    text-align: center;
    width: 100%;
  }
  #landing-page-content #about-category .content .left-description .description {
    padding-right: 0px;
  }
  #landing-page-content #about-category .content .left-description .description .title {
    font-size: 18px;
  }
  #landing-page-content #about-category .content .right-description {
    width: 100%;
  }
  #landing-page-content #about-category .content .right-description .description {
    text-align: center;
    padding-bottom: 15px;
    padding-left: 0px;
  }
  #landing-page-content #about-category .content .right-description .description .title {
    font-size: 18px;
    padding-bottom: 10px;
  }
  #landing-page-content #about-category .link {
    padding: 20px 0;
  }
  #landing-page-content #about-category .link .title {
    font-size: 22px;
    padding-bottom: 0px;
  }
  #landing-page-content #about-category .link .info {
    margin: 20px;
    width: 85%;
    height: 200px;
  }
  #landing-page-content #about-category .link .info .content {
    font-size: 22px;
  }
  #landing-page-content #project-package {
    padding: 40px 10px;
  }
  #landing-page-content #project-package .package-list {
    padding-bottom: 20px;
  }
  #landing-page-content #project-package .package-list [class^=package-] {
    font-size: 12px;
    margin-right: 0;
    margin-bottom: 10px;
  }
  #landing-page-content #project-package .find {
    position: relative;
  }
  #landing-page-content #project-package .find a {
    padding: 10px 25px;
    background-color: #11344c;
    color: #ffffff;
    font-size: 14px;
    border-radius: 3px;
    text-decoration: none;
    cursor: pointer;
  }
  #landing-page-content #project-package .find a:hover {
    opacity: 1;
    transition: all 0.5s ease-out;
    background-color: #ffffff;
    color: #11344c;
  }
}

#error-page {
  font-family: 'Roboto', sans-serif;
  color: #18293e;
  text-align: center;
  padding: 105px 0 125px;
}

#error-page .container .image {
  width: 300px;
  margin: 0 auto;
}

#error-page .container .image #Layer_1 .background {
  fill: #ffba00;
  animation: color_change 3s alternate infinite;
}

#error-page .container .title {
  font-size: 36px;
  padding-top: 30px;
}

#error-page .container .description {
  font-size: 16px;
  padding-top: 10px;
}

#error-page .container .text-center {
  padding-top: 60px;
}

#error-page .container .text-center .button {
  cursor: pointer;
  padding: 20px 55px;
  background-color: #28f1b1;
  margin: 0 20px;
  text-decoration: none;
  display: inline-block;
  color: #18293e;
  transition: all 0.3s;
  width: 270px;
}

#error-page .container .text-center .button .fa {
  padding-left: 10px;
  font-size: 16px;
  display: none;
}

#error-page .container .text-center .button span {
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

#error-page .container .text-center .button:hover {
  background-color: #18293e;
  color: #28f1b1;
}

#error-page .container .text-center .button:hover .fa {
  font-size: 16px;
  display: inline-block;
}

#error-page .container .text-center .button:hover span {
  padding-right: 10px;
}

#error-page .container .back {
  padding-top: 40px;
}

#error-page .container .back a {
  color: #29d7a0;
  margin: 0 auto;
  transition: all 0.3s;
  text-decoration: none;
  padding: 5px;
  border-bottom: 1px solid #29d7a0;
}

#error-page .container .back a:hover {
  background-color: #29d7a0;
  color: #ffffff;
  border-bottom: none;
}

@keyframes color_change {
  0% {
    fill: #ffba00;
  }
  30% {
    fill: #ff4930;
  }
  60% {
    fill: #00c6f3;
  }
  100% {
    fill: #00e04e;
  }
}

@media only screen and (max-width: 767px) {
  #error-page {
    padding: 60px 0;
  }
  #error-page .container .image {
    width: 100%;
  }
  #error-page .container .title {
    font-size: 22px;
  }
  #error-page .container .description {
    font-size: 14px;
  }
  #error-page .container .text-center {
    padding-top: 30px;
  }
  #error-page .container .text-center .button {
    padding: 10px;
    display: inline-block;
    margin: 0;
  }
  #error-page .container .back {
    padding-top: 20px;
  }
}

#price-guide-banner {
  font-family: 'Roboto', sans-serif;
  color: #72839c;
  scroll-behavior: smooth;
}

#price-guide-banner .inner {
  text-align: center;
  position: relative;
}

#price-guide-banner .inner .banner_parallax {
  z-index: 0;
  min-height: 678px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#price-guide-banner .inner .container {
  position: absolute;
  top: 35%;
  transform: translateY(-35%);
  left: 0;
  right: 0;
}

#price-guide-banner .inner .title {
  font-size: 24px;
  font-weight: bold;
  color: #ffffff;
  margin: 0;
}

#price-guide-banner .inner .title .category {
  color: #28f2b2;
}

#price-guide-banner .inner .description {
  font-size: 36px;
  font-weight: bold;
  color: #ffffff;
  padding-bottom: 30px;
  padding-top: 5px;
}

#price-guide-banner .inner .button {
  padding-bottom: 45px;
}

#price-guide-banner .inner .button a {
  padding: 15px 40px;
  font-size: 16px;
  border-radius: 2px;
  background-color: #29f2b1;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  color: #18293e;
}

#price-guide-banner .inner .button a:hover {
  opacity: 0.8;
  transition: all .3s ease-in;
}

#price-guide-content {
  font-family: 'Roboto', sans-serif;
  color: #18293e;
}

#price-guide-content #price-comparison {
  text-align: center;
  padding: 60px 0;
}

#price-guide-content #price-comparison .content {
  font-size: 14px;
  padding-bottom: 60px;
}

#price-guide-content #price-comparison .description {
  font-size: 18px;
  font-weight: bold;
  padding: 0 75px 60px;
}

#price-guide-content #price-comparison .title {
  font-size: 28px;
  font-weight: bold;
  padding-bottom: 10px;
  margin: 0;
}

#price-guide-content #price-comparison .title.eng_version {
  font-size: 24px;
}

#price-guide-content #price-comparison .note {
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  width: 75%;
  margin: 0 auto;
  color: #71839c;
  padding-bottom: 20px;
}

#price-guide-content #price-comparison .table-comparison {
  width: 75%;
  font-size: 14px;
  margin: 0 auto;
}

#price-guide-content #price-comparison .table-comparison .row-title {
  text-align: center;
  background-color: #18283e;
  color: #ffffff;
  font-weight: bold;
}

#price-guide-content #price-comparison .table-comparison .row-title .none {
  background-color: #ffffff;
  width: 19%;
}

#price-guide-content #price-comparison .table-comparison .row-title td {
  width: 27%;
}

#price-guide-content #price-comparison .table-comparison td {
  padding: 25px 20px;
}

#price-guide-content #price-comparison .table-comparison td.col-title {
  width: 20%;
  border-bottom: 1px solid #ffffff;
  background-color: #71839c;
  color: #ffffff;
  font-weight: bold;
  padding: 25px 55px;
}

#price-guide-content #price-comparison .table-comparison td.odd {
  background-color: #ffffff;
}

#price-guide-content #price-comparison .table-comparison td.even {
  background-color: #eaf0f7;
}

#price-guide-content #price-comparison .helper {
  font-size: 14px;
  text-align: right;
  padding-top: 30px;
  width: 75%;
  margin: 0 auto;
}

#price-guide-content #price-comparison .helper span {
  color: #28f1b1;
}

#price-guide-content #why-better {
  text-align: center;
  padding: 60px 0;
  background-color: #eaf0f7;
}

#price-guide-content #why-better .title {
  font-size: 28px;
  font-weight: bold;
  padding-bottom: 15px;
  padding-top: 30px;
}

#price-guide-content #why-better .title.eng_version {
  font-size: 24px;
}

#price-guide-content #why-better .content {
  padding: 0 130px;
}

#price-guide-content #why-better .content .right-content {
  text-align: left;
  padding: 10px 0px 50px 130px;
  position: relative;
}

#price-guide-content #why-better .content .right-content:nth-child(2) {
  padding-top: 18px;
}

#price-guide-content #why-better .content .right-content .icon {
  position: absolute;
  left: 0;
  top: 0;
}

#price-guide-content #why-better .content .right-content .header {
  font-size: 18px;
  font-weight: bold;
}

#price-guide-content #why-better .content .right-content .info {
  font-size: 14px;
}

#price-guide-content #why-better .description {
  font-size: 16px;
  padding-bottom: 30px;
}

#price-guide-content #why-better .description.eng_version {
  font-size: 14px;
}

#price-guide-content #why-better .button {
  padding-bottom: 45px;
}

#price-guide-content #why-better .button a {
  padding: 15px 40px;
  font-size: 16px;
  border-radius: 2px;
  background-color: #29f2b1;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  color: #18293e;
}

#price-guide-content #why-better .button a:hover {
  color: #28f1b1;
  background-color: #18293e;
  transition: all .3s ease-in;
}

#price-guide-content #more-info {
  padding: 60px 0;
}

#price-guide-content #more-info .title {
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 30px;
}

#price-guide-content #more-info .information {
  border: 3px solid #eaf0f7;
  border-radius: 5px;
  margin-bottom: 20px;
}

#price-guide-content #more-info .information:hover {
  transition: all 0.5s ease-out;
  border: 3px solid #71839c;
}

#price-guide-content #more-info .information:hover .header {
  transition: all 0.5s ease-out;
  background-color: #71839c;
  color: #ffffff;
}

#price-guide-content #more-info .information:hover .header .fa {
  transition: all 0.5s ease-out;
  color: #ffffff;
}

#price-guide-content #more-info .information:hover .content {
  transition: all 0.5s ease-out;
  background-color: #71839c;
  color: #ffffff;
}

#price-guide-content #more-info .information.active:hover {
  border: 3px solid #eaf0f7;
}

#price-guide-content #more-info .information.active:hover .header {
  background-color: #ffffff;
  color: #18293e;
}

#price-guide-content #more-info .information.active:hover .header .fa {
  color: #18293e;
}

#price-guide-content #more-info .information.active .header .fa {
  color: #18293e;
}

#price-guide-content #more-info .information.active .header .fa.fa-chevron-circle-down {
  display: none;
}

#price-guide-content #more-info .information.active .header .fa.fa-chevron-circle-up {
  display: block;
}

#price-guide-content #more-info .information.active .content {
  display: block;
  transition: all 0.5s ease-out;
  background-color: #ffffff;
  color: #18293e;
}

#price-guide-content #more-info .information .header {
  padding: 25px 30px;
  font-weight: bold;
  color: #18283e;
  font-size: 18px;
  cursor: pointer;
  margin: 0;
}

#price-guide-content #more-info .information .header .fa {
  font-size: 24px;
  float: right;
  color: #71839c;
  display: none;
}

#price-guide-content #more-info .information .header .fa.fa-chevron-circle-down {
  display: block;
}

#price-guide-content #more-info .information .content {
  font-size: 14px;
  padding: 0 25px 35px;
  display: none;
  color: #18283e;
}

#price-guide-content #more-info .info-wrapper {
  height: 280px;
}

#price-guide-content #more-info .info-wrapper .col-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../../images/root/price-guide/banner_small.jpg");
  height: 280px;
}

#price-guide-content #more-info .info-wrapper .col-content {
  background-color: #1b283b;
  height: 280px;
  padding: 60px 70px;
}

#price-guide-content #more-info .info-wrapper .col-content .header {
  font-size: 28px;
  font-weight: bold;
  color: #ffffff;
}

#price-guide-content #more-info .info-wrapper .col-content .description {
  font-size: 24px;
  color: #ffffff;
  padding-bottom: 20px;
}

#price-guide-content #more-info .info-wrapper .col-content .button a {
  padding: 13px 40px;
  font-size: 16px;
  border-radius: 2px;
  background-color: #29f2b1;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  color: #18293e;
  border: 1px solid #28f1b1;
}

#price-guide-content #more-info .info-wrapper .col-content .button a:hover {
  color: #28f1b1;
  background-color: #ffffff;
  transition: all .3s ease-in;
  border: 1px solid #28f1b1;
}

#price-guide-content #portfolio {
  padding-bottom: 60px;
}

#price-guide-content #portfolio .title {
  text-align: center;
  font-size: 28px;
  font-weight: bold;
  padding-bottom: 30px;
}

#price-guide-content #portfolio .title.eng_version {
  font-size: 24px;
}

#price-guide-content #portfolio .button {
  text-align: center;
}

#price-guide-content #portfolio .button a {
  padding: 15px 40px;
  font-size: 16px;
  border-radius: 2px;
  background-color: #ffffff;
  border: 1px solid #02cd8d;
  color: #02cd8d;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
}

#price-guide-content #portfolio .works-listing-results {
  margin-left: -10px;
  margin-right: -10px;
}

#price-guide-content #portfolio .works-listing-results .portfolio-wrapper {
  float: left;
  width: 20%;
  padding: 0 10px 20px 10px;
}

#price-guide-content #portfolio .works-listing-results .portfolio-wrapper .portfolio {
  font-family: "roboto";
  text-decoration: none;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
  margin-bottom: 20px;
  border-radius: 5px;
}

#price-guide-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .portfolio-ajax-link {
  cursor: pointer;
}

#price-guide-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .portfolio-wrapper-heading {
  padding-bottom: 75%;
  background-size: cover;
  background-position: 0 0;
  position: relative;
}

#price-guide-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .portfolio-wrapper-heading .portfolio-wrapper-heading-title {
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  z-index: 10;
  color: #fff;
  font-size: 18px;
  line-height: 26px;
  width: 75%;
}

#price-guide-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .lazy {
  position: relative;
  border-radius: 4px;
}

#price-guide-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .lazy::after {
  content: '';
  display: block;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("../../images/root/work/grid-grey-layer.png");
  background-repeat: repeat;
  background-size: 1px 190px;
}

#price-guide-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .lazy .location {
  z-index: 3;
  position: absolute;
  bottom: 8px;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
}

#price-guide-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .lazy .location .fa {
  color: #ffffff;
  padding-top: 5px;
  font-size: 24px;
}

#price-guide-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .lazy .location span {
  color: white;
  padding-left: 5px;
  font-size: 14px;
}

#price-guide-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .bookmark {
  top: 0px;
}

#price-guide-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .bookmark .review .fa-bookmark {
  z-index: 3;
  color: #f5c343;
  position: absolute;
  font-size: 50px;
  transform: scaleX(0.8);
  top: -2%;
  right: 5%;
}

#price-guide-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .bookmark .review .fa-star {
  z-index: 3;
  color: #ffffff;
  position: absolute;
  font-size: 20px;
  right: 8.5%;
  top: 2%;
}

#price-guide-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .bookmark .feature {
  position: absolute;
  top: -5px;
  right: 10%;
  z-index: 3;
}

#price-guide-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .content {
  padding: 0 10px 15px 15px;
}

#price-guide-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .content .title {
  text-align: inherit;
  color: #18293E;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
  line-height: 1.3;
  letter-spacing: 0;
  padding-bottom: 10px;
}

#price-guide-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .content .rate .fa {
  color: #f5c343;
}

#price-guide-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .content .services {
  margin-left: -4px;
  margin-top: 4px;
}

#price-guide-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .content .services .service {
  margin-left: 4px;
  margin-bottom: 4px;
  background-color: #505961;
  display: inline-block;
  padding: 3px 8px;
  border-radius: 2px;
  color: rgba(255, 255, 255, 0.75);
}

#price-guide-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .content .services .service.hidden {
  display: none;
}

#price-guide-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .content .divider {
  height: 1px;
  background-color: #edf1f3;
  margin-top: 10px;
  margin-bottom: 10px;
}

#price-guide-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .content .divider-short {
  height: 1px;
  width: 10px;
  background-color: #868686;
  margin-top: 10px;
  margin-bottom: 10px;
}

#price-guide-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .content .description {
  color: #737373;
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  line-height: 1.3;
}

#price-guide-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .content .details .tags {
  color: #8b8b8b;
  font-size: 14px;
  padding-top: 10px;
  font-weight: 300;
}

#price-guide-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .content .details .item-client-name {
  width: 65%;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
}

#price-guide-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .content .details .premium {
  width: 35%;
  text-align: right;
}

#price-guide-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .content .details .premium span {
  color: #ffffff;
  background-color: #ee7781;
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 2px;
}

#price-guide-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .content .details .pro {
  width: 35%;
  text-align: right;
}

#price-guide-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .content .details .pro span {
  color: #ffffff;
  background-color: #071c2f;
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 2px;
}

#price-guide-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .footer {
  background-color: #f9fafb;
  padding: 10px 50px 10px 15px;
  min-height: 46px;
  position: relative;
  border-radius: 4px;
}

#price-guide-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .footer .services {
  display: block;
}

#price-guide-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .footer .services .service {
  margin-right: 4px;
  margin-bottom: 4px;
  background-color: #97a1b0;
  display: inline-block;
  padding: 3px 8px;
  border-radius: 2px;
  color: rgba(255, 255, 255, 0.75);
}

#price-guide-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .footer .services .service.hidden {
  display: none;
}

#price-guide-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .footer .col-favourite {
  position: absolute;
  right: 10px;
  top: 10px;
  display: block;
  text-decoration: none;
  cursor: pointer;
}

#price-guide-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .footer .col-favourite .fa {
  color: #28f2b2;
}

#price-guide-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .footer .col-favourite .fa-heart-o {
  display: block;
}

#price-guide-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .footer .col-favourite .fa-heart {
  display: none;
}

#price-guide-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .footer .col-favourite.un-favourite .fa-heart-o {
  display: none;
}

#price-guide-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .footer .col-favourite.un-favourite .fa-heart {
  display: block;
}

#price-guide-content #portfolio .works-listing-results:after {
  content: '';
  display: table;
  clear: both;
}

#price-guide-content #rating {
  text-align: center;
  background-color: #eaf0f7;
  padding: 60px 0px 90px;
}

#price-guide-content #rating .title {
  font-size: 28px;
  font-weight: bold;
  padding-bottom: 30px;
}

#price-guide-content #rating .title.eng_version {
  font-size: 24px;
}

#price-guide-content #rating .rating-score .info {
  padding: 45px 30px 30px;
  background-color: #ffffff;
  width: 32%;
  float: left;
  margin-right: 15px;
}

#price-guide-content #rating .rating-score .title {
  font-size: 16px;
  font-weight: inherit;
  color: #70839c;
}

#price-guide-content #rating .rating-score .title span {
  font-weight: bold;
}

#price-guide-content #rating .rating-score .description {
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 30px;
}

#price-guide-content #rating .rating-score .marks {
  padding-bottom: 10px;
}

#price-guide-content #rating .rating-score .marks .col-left .info-row:after {
  content: '';
  display: block;
  clear: both;
}

#price-guide-content #rating .rating-score .marks .col-left .info-row .info-label {
  float: left;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.8;
}

#price-guide-content #rating .rating-score .marks .col-left .info-row .info-value {
  float: right;
  position: relative;
}

#price-guide-content #rating .rating-score .marks .col-left .info-row .info-value #Isolation_Mode {
  margin: 0 auto;
}

#price-guide-content #rating .rating-score .marks .col-right {
  text-align: center;
}

#price-guide-content #rating .rating-score .marks .col-right .circles-text {
  font-size: 24px !important;
  color: #18293e !important;
}

#price-guide-content #rating .rating-score .marks .col-right .circles-text b {
  color: #18293e !important;
}

#price-guide-content #rating .rating-score .slick-dots {
  position: absolute;
  bottom: -55px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

#price-guide-content #rating .rating-score .slick-dots li {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
  left: -30px;
}

#price-guide-content #rating .rating-score .slick-dots li.slick-active button {
  background-color: #28f1b1;
}

#price-guide-content #rating .rating-score .slick-dots li.slick-active button::before {
  opacity: 1;
  color: #28f2b2;
}

#price-guide-content #rating .rating-score .slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 10px;
  height: 10px;
  padding: 5px;
  cursor: pointer;
  background-color: #71839c;
  border: 0;
  outline: none;
}

#price-guide-content #rating .rating-score .slick-dots li button::before {
  opacity: 1;
  font-size: 10px;
  font-family: 'slick';
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  content: '•';
  text-align: center;
}

#price-guide-content #faqs {
  padding: 60px 0;
}

#price-guide-content #faqs .title {
  font-size: 28px;
  font-weight: bold;
  padding-bottom: 30px;
  text-align: center;
}

#price-guide-content #faqs .title.eng_version {
  font-size: 24px;
}

#price-guide-content #faqs .title span {
  font-size: 18px;
}

#price-guide-content #faqs .answer-box {
  border: 3px solid #eaf0f7;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 30px;
}

#price-guide-content #faqs .answer-box .question {
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 25px;
}

#price-guide-content #faqs .answer-box .question span {
  color: #02cd8d;
}

#price-guide-content #faqs .answer-box .answer {
  font-size: 14px;
}

#price-guide-content #other {
  padding: 60px 0;
  background-color: #29f2b1;
}

#price-guide-content #other .info {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #ffffff;
  margin-right: 30px;
  width: 31%;
  height: 300px;
  position: relative;
}

#price-guide-content #other .info a {
  color: #ffffff;
}

#price-guide-content #other .info:hover {
  cursor: pointer;
}

#price-guide-content #other .info .content {
  font-size: 28px;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#price-guide-content #other .info .content.eng_version {
  font-size: 24px;
  line-height: 30px;
}

#price-guide-content #other .info.facebook {
  background-image: url("../../images/root/price-guide/other/facebook.jpg");
}

#price-guide-content #other .info.facebook:hover {
  background-color: #19293e;
  background-image: none;
}

#price-guide-content #other .info.facebook:hover .enter {
  display: block;
}

#price-guide-content #other .info.sem {
  background-image: url("../../images/root/price-guide/other/sem.jpg");
}

#price-guide-content #other .info.sem:hover {
  background-color: #19293e;
  background-image: none;
}

#price-guide-content #other .info.sem:hover .enter {
  display: block;
}

#price-guide-content #other .info.e-commerce {
  background-image: url("../../images/root/price-guide/other/e-commerce.jpg");
}

#price-guide-content #other .info.e-commerce:hover {
  background-color: #19293e;
  background-image: none;
}

#price-guide-content #other .info.e-commerce:hover .enter {
  display: block;
}

#price-guide-content #other .info.video-production {
  background-image: url("../../images/root/price-guide/other/video-production.jpg");
}

#price-guide-content #other .info.video-production:hover {
  background-color: #19293e;
  background-image: none;
}

#price-guide-content #other .info.video-production:hover .enter {
  display: block;
}

#price-guide-content #other .info.seo {
  background-image: url("../../images/root/price-guide/other/seo.jpg");
}

#price-guide-content #other .info.seo:hover {
  background-color: #19293e;
  background-image: none;
}

#price-guide-content #other .info.seo:hover .enter {
  display: block;
}

#price-guide-content #other .info.web-development {
  background-image: url("../../images/root/price-guide/other/web-development.jpg");
}

#price-guide-content #other .info.web-development:hover {
  background-color: #19293e;
  background-image: none;
}

#price-guide-content #other .info.web-development:hover .enter {
  display: block;
}

#price-guide-content #other .info.mobile-app {
  background-image: url("../../images/root/price-guide/other/mobile-app.jpg");
}

#price-guide-content #other .info.mobile-app:hover {
  background-color: #19293e;
  background-image: none;
}

#price-guide-content #other .info.mobile-app:hover .enter {
  display: block;
}

#price-guide-content #other .info.branding {
  background-image: url("../../images/root/price-guide/other/branding.jpg");
}

#price-guide-content #other .info.branding:hover {
  background-color: #19293e;
  background-image: none;
}

#price-guide-content #other .info.branding:hover .enter {
  display: block;
}

#price-guide-content #other .info.ar-vr {
  background-image: url("../../images/root/price-guide/other/3d_ar_vr.jpg");
}

#price-guide-content #other .info.ar-vr:hover {
  background-color: #19293e;
  background-image: none;
}

#price-guide-content #other .info.ar-vr:hover .enter {
  display: block;
}

#price-guide-content #other .info .enter {
  width: 30px;
  fill: #29f2b1;
  position: absolute;
  right: 0px;
  bottom: 0px;
  margin: 0 20px 20px 0;
  display: none;
}

@media only screen and (max-width: 991px) {
  #price-guide-banner .inner .banner_parallax {
    min-height: 678px;
  }
  #price-guide-banner .inner .description {
    font-size: 32px;
  }
  #price-guide-banner .inner .button {
    padding-bottom: 45px;
  }
  #price-guide-banner .inner .button a {
    padding: 15px 40px;
    font-size: 16px;
    border-radius: 2px;
    background-color: #29f2b1;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    color: #18293e;
  }
  #price-guide-banner .inner .button a:hover {
    color: #28f1b1;
    background-color: #18293e;
    transition: all .3s ease-in;
  }
  #price-guide-banner .inner .image {
    width: 100%;
  }
  #price-guide-banner .inner .image img {
    width: 600px;
  }
  #price-guide-content {
    font-family: 'Roboto', sans-serif;
    color: #18293e;
  }
  #price-guide-content #price-comparison .note {
    font-size: 16px;
    width: 90%;
    margin: 0 auto;
  }
  #price-guide-content #price-comparison .table-comparison {
    width: 90%;
  }
  #price-guide-content #price-comparison .table-comparison td.col-title {
    padding: 25px 10px;
  }
  #price-guide-content #price-comparison .helper {
    width: 90%;
  }
  #price-guide-content #why-better {
    padding: 40px 0;
  }
  #price-guide-content #why-better .content {
    padding: 0 20px;
  }
  #price-guide-content #more-info .title {
    font-size: 18px;
  }
  #price-guide-content #more-info .information .header {
    margin: 0;
  }
  #price-guide-content #more-info .information .header .fa {
    font-size: 20px;
  }
  #price-guide-content #more-info .info-wrapper {
    height: 260px;
  }
  #price-guide-content #more-info .info-wrapper .col-image {
    height: 260px;
  }
  #price-guide-content #more-info .info-wrapper .col-content {
    height: 260px;
    padding: 55px 40px;
  }
  #price-guide-content #more-info .info-wrapper .col-content .header {
    font-size: 24px;
    font-weight: bold;
    color: #ffffff;
  }
  #price-guide-content #more-info .info-wrapper .col-content .description {
    font-size: 20px;
    padding-bottom: 15px;
  }
  #price-guide-content #more-info .info-wrapper .col-content .button a {
    padding: 10px 30px;
    font-size: 16px;
  }
  #price-guide-content #portfolio {
    padding-bottom: 60px;
  }
  #price-guide-content #portfolio .title {
    text-align: center;
    font-size: 28px;
    font-weight: bold;
    padding-bottom: 30px;
  }
  #price-guide-content #portfolio .button {
    text-align: center;
  }
  #price-guide-content #portfolio .button a {
    padding: 15px 40px;
    font-size: 16px;
    border-radius: 2px;
    background-color: #ffffff;
    border: 1px solid #02cd8d;
    color: #02cd8d;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
  }
  #price-guide-content #portfolio .works-listing-results .portfolio-wrapper {
    width: 33.333333%;
  }
  #price-guide-content #rating {
    padding: 20px 0px 70px;
  }
  #price-guide-content #rating .rating-score .info {
    padding: 20px;
  }
  #price-guide-content #rating .rating-score .title {
    padding-bottom: 15px;
  }
  #price-guide-content #rating .rating-score .description {
    padding-bottom: 15px;
  }
  #price-guide-content #faqs {
    padding: 60px 0;
  }
  #price-guide-content #faqs .title {
    font-size: 28px;
    font-weight: bold;
    padding-bottom: 30px;
    text-align: center;
  }
  #price-guide-content #faqs .title span {
    font-size: 18px;
  }
  #price-guide-content #faqs .answer-box {
    border: 3px solid #eaf0f7;
    border-radius: 5px;
    margin-bottom: 20px;
    padding: 30px;
  }
  #price-guide-content #faqs .answer-box .question {
    font-size: 28px;
    font-weight: bold;
  }
  #price-guide-content #faqs .answer-box .question span {
    color: #02cd8d;
  }
  #price-guide-content #faqs .answer-box .answer {
    font-size: 18px;
  }
  #price-guide-content #other .info {
    margin: 1%;
    width: 31%;
    height: 200px;
  }
  #price-guide-content #other .info .content {
    font-size: 24px;
  }
}

@media only screen and (max-width: 767px) {
  #price-guide-banner .inner .banner_parallax {
    min-height: 650px;
  }
  #price-guide-banner .inner .container {
    top: 50%;
    transform: translateY(-50%);
  }
  #price-guide-banner .inner .title {
    font-size: 22px;
    padding-bottom: 10px;
  }
  #price-guide-banner .inner .description {
    font-size: 24px;
    padding-bottom: 15px;
  }
  #price-guide-banner .inner .button {
    padding-bottom: 45px;
  }
  #price-guide-banner .inner .button a {
    padding: 10px 25px;
    font-size: 14px;
  }
  #price-guide-banner .inner .responsive_graph .title {
    font-size: 15px;
    padding-bottom: 10px;
  }
  #price-guide-banner .inner .responsive_graph .image {
    padding-bottom: 15px;
  }
  #price-guide-banner .inner .responsive_graph .image img {
    width: 260px;
  }
  #price-guide-banner .inner .responsive_graph .info {
    text-align: left;
  }
  #price-guide-banner .inner .responsive_graph .info .price {
    padding-bottom: 10px;
    color: #ffffff;
  }
  #price-guide-banner .inner .responsive_graph .info .price span {
    font-size: 14px;
  }
  #price-guide-banner .inner .responsive_graph .info .price span.low {
    color: #5cf4ff;
  }
  #price-guide-banner .inner .responsive_graph .info .price span.general {
    color: #008ee7;
  }
  #price-guide-banner .inner .responsive_graph .info .price span.high {
    color: #3f15f9;
  }
  #price-guide-content #price-comparison {
    padding: 20px 0;
  }
  #price-guide-content #price-comparison .container {
    padding: 0 15px;
  }
  #price-guide-content #price-comparison .content {
    padding-bottom: 30px;
  }
  #price-guide-content #price-comparison .description {
    font-size: 18px;
    padding: 0 0px 30px;
  }
  #price-guide-content #price-comparison .title {
    font-size: 28px;
  }
  #price-guide-content #price-comparison .note {
    font-size: 12px;
    width: 100%;
    text-align: center;
  }
  #price-guide-content #price-comparison .table-comparison {
    width: 100%;
    font-size: 14px;
  }
  #price-guide-content #price-comparison .table-comparison .row-title td {
    width: 27%;
  }
  #price-guide-content #price-comparison .table-comparison td {
    padding: 10px;
  }
  #price-guide-content #price-comparison .table-comparison td.col-title {
    width: 19%;
    padding: 5px 15px;
  }
  #price-guide-content #price-comparison .helper {
    font-size: 12px;
    text-align: center;
    padding-top: 20px;
    width: 100%;
  }
  #price-guide-content #why-better {
    padding: 20px 0;
  }
  #price-guide-content #why-better .title {
    padding-bottom: 30px;
    padding-top: 0px;
  }
  #price-guide-content #why-better .content .right-content {
    text-align: left;
    padding-top: 120px;
    padding-left: 0px;
  }
  #price-guide-content #why-better .content .right-content:nth-child(2) {
    padding-top: 120px;
  }
  #price-guide-content #why-better .content .right-content .icon {
    left: 50%;
    transform: translateX(-50%);
  }
  #price-guide-content #why-better .button {
    padding: 10px 30px;
  }
  #price-guide-content #more-info {
    padding: 20px 0;
  }
  #price-guide-content #more-info .title {
    font-size: 18px;
  }
  #price-guide-content #more-info .information {
    border: 3px solid #eaf0f7;
    border-radius: 5px;
    margin-bottom: 20px;
  }
  #price-guide-content #more-info .information:hover {
    transition: all 0.5s ease-out;
    border: 3px solid #71839c;
  }
  #price-guide-content #more-info .information:hover .header {
    transition: all 0.5s ease-out;
    background-color: #71839c;
    color: #ffffff;
  }
  #price-guide-content #more-info .information:hover .header .fa {
    transition: all 0.5s ease-out;
    color: #ffffff;
  }
  #price-guide-content #more-info .information:hover .content {
    transition: all 0.5s ease-out;
    background-color: #71839c;
    color: #ffffff;
  }
  #price-guide-content #more-info .information.active:hover {
    border: 3px solid #eaf0f7;
  }
  #price-guide-content #more-info .information.active:hover .header {
    background-color: #ffffff;
    color: #18293e;
  }
  #price-guide-content #more-info .information.active:hover .header .fa {
    color: #18293e;
  }
  #price-guide-content #more-info .information.active .header .fa {
    color: #18293e;
  }
  #price-guide-content #more-info .information.active .header .fa.fa-chevron-circle-down {
    display: none;
  }
  #price-guide-content #more-info .information.active .header .fa.fa-chevron-circle-up {
    display: block;
  }
  #price-guide-content #more-info .information.active .content {
    display: block;
    transition: all 0.5s ease-out;
    background-color: #ffffff;
    color: #18293e;
  }
  #price-guide-content #more-info .information .header {
    padding: 15px 10px;
    font-size: 16px;
  }
  #price-guide-content #more-info .information .header .fa {
    font-size: 15px;
  }
  #price-guide-content #more-info .information .content {
    font-size: 14px;
    padding: 0 15px 25px;
  }
  #price-guide-content #more-info .info-wrapper {
    min-height: 560px;
  }
  #price-guide-content #more-info .info-wrapper .col-image {
    height: 280px;
  }
  #price-guide-content #more-info .info-wrapper .col-content {
    height: 280px;
    padding: 60px 20px;
    text-align: center;
  }
  #price-guide-content #more-info .info-wrapper .col-content .header {
    font-size: 20px;
    font-weight: bold;
    color: #ffffff;
  }
  #price-guide-content #more-info .info-wrapper .col-content .description {
    font-size: 18px;
  }
  #price-guide-content #portfolio {
    padding-bottom: 20px;
    padding-top: 20px;
  }
  #price-guide-content #portfolio .title {
    font-size: 28px;
  }
  #price-guide-content #portfolio .button {
    padding-top: 20px;
  }
  #price-guide-content #portfolio .button a {
    padding: 10px 20px;
    font-size: 14px;
  }
  #price-guide-content #portfolio .works-listing-results .portfolio-wrapper {
    width: 100%;
    padding: 15px 20px 20px;
    border-bottom: 1px solid #dbdbdb;
  }
  #price-guide-content #portfolio .works-listing-results .portfolio-wrapper .portfolio {
    box-shadow: none;
  }
  #price-guide-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .portfolio-wrapper-heading {
    padding-bottom: 50%;
  }
  #price-guide-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .portfolio-wrapper-heading .portfolio-wrapper-heading-title {
    width: 65%;
    font-size: 14px;
    line-height: 14px;
  }
  #price-guide-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .lazy {
    width: 45%;
    display: block;
    float: left;
  }
  #price-guide-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .lazy .location {
    padding-left: 5px;
    padding-right: 0px;
  }
  #price-guide-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .lazy .location .pull-left {
    width: 65%;
  }
  #price-guide-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .lazy .location .fa {
    padding-top: 0;
  }
  #price-guide-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .lazy .location .fa.fa-map-marker {
    display: none;
  }
  #price-guide-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .lazy .location .fa.fa-youtube-play {
    padding-right: 5px;
  }
  #price-guide-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .lazy .location span {
    padding-left: 0px;
    font-size: 12px;
  }
  #price-guide-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .bookmark .feature {
    right: 3%;
  }
  #price-guide-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .bookmark .feature img {
    width: 20px;
  }
  #price-guide-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .content {
    width: 55%;
    display: block;
    float: left;
    font-size: 12px;
    padding: 0 0 0 10px;
  }
  #price-guide-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .content .title {
    margin-top: 0;
    font-size: 12px;
    line-height: 1.2;
  }
  #price-guide-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .content .details .item-client-name {
    font-size: 12px;
    line-height: 1.2;
  }
  #price-guide-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .content .details .tags {
    font-size: 12px;
    line-height: 1.2;
  }
  #price-guide-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .footer {
    padding: 10px 50px 10px 0px;
    background-color: initial;
    display: block;
    float: left;
    width: 100%;
  }
  #price-guide-content #rating .title {
    font-size: 28px;
    font-weight: bold;
    padding-bottom: 30px;
  }
  #price-guide-content #rating .rating-score .info {
    margin-right: 5px;
  }
  #price-guide-content #rating .rating-score .title {
    font-size: 14px;
  }
  #price-guide-content #rating .rating-score .description {
    font-size: 16px;
  }
  #price-guide-content #rating .rating-score .marks {
    font-size: 14px;
  }
  #price-guide-content #rating .rating-score .marks .col-left {
    padding-bottom: 25px;
  }
  #price-guide-content #rating .rating-score .slick-dots li {
    left: 0;
  }
  #price-guide-content #faqs {
    padding: 20px 0;
  }
  #price-guide-content #faqs .title {
    line-height: 1;
  }
  #price-guide-content #faqs .title span {
    font-size: 14px;
  }
  #price-guide-content #faqs .answer-box {
    padding: 15px;
  }
  #price-guide-content #faqs .answer-box .question {
    font-size: 18px;
  }
  #price-guide-content #faqs .answer-box .question span {
    color: #02cd8d;
  }
  #price-guide-content #faqs .answer-box .answer {
    padding-top: 15px;
    font-size: 16px;
  }
  #price-guide-content #other {
    padding: 20px 0;
  }
  #price-guide-content #other .info {
    margin: 20px;
    width: 85%;
    height: 200px;
  }
  #price-guide-content #other .info .content {
    font-size: 22px;
  }
}

@media only screen and (max-width: 500px) {
  #price-guide-content #price-comparison .table-comparison {
    font-size: 9px;
  }
  #price-guide-content #price-comparison .table-comparison td {
    padding: 5px;
  }
  #price-guide-content #price-comparison .table-comparison td.col-title {
    padding: 5px;
  }
}

#guest-vendor-create-banner-wrapper {
  height: 595px;
  position: relative;
  z-index: 3;
  background: url("../../images/root/client/how-works/2Easy-how-it-work-client-banner.jpg") center center;
  background-size: cover;
}

#guest-vendor-create-banner-wrapper::after {
  content: '';
  display: block;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 17, 50, 0.5);
}

#guest-vendor-create-banner-wrapper .inner {
  position: absolute;
  margin-top: 0px;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#guest-vendor-create-banner-wrapper .inner .icon.icon-monitor {
  width: 160px;
  height: 150px;
  background-position: -794px -878px;
  background-image: url(../../images/root/all.png);
  margin: 0 auto 35px;
}

#guest-vendor-create-banner-wrapper .inner .title {
  color: #02cd8d;
  margin-bottom: 20px;
  font-size: 30px;
  text-align: center;
  white-space: nowrap;
  font-weight: bolder;
}

#guest-vendor-create-banner-wrapper .inner .description {
  white-space: normal;
  font-size: 16px;
  color: #FFFFFF;
  text-align: center;
  margin-bottom: 30px;
  width: 900px;
  margin: 0 auto;
}

#guest-vendor-create-banner-wrapper .inner .text-center .btn-effect-1 {
  width: 230px;
  text-align: center;
  margin: 0 auto;
}

#guest-vendor-create-content.main .content-wrapper .heading {
  padding: 70px 0;
  background-color: #6a90b9;
  text-align: center;
}

#guest-vendor-create-content.main .content-wrapper .heading h1 {
  font-size: 40px;
  color: #02cd8d;
  font-family: "Roboto", sans-serif;
  letter-spacing: 2px;
  margin: 0 0 40px;
  line-height: 1;
}

#guest-vendor-create-content.main .content-wrapper .heading .description {
  color: #FFFFFF;
  font-family: "Roboto", sans-serif;
  letter-spacing: 1px;
  font-size: 16px;
  line-height: 1.5;
  margin: 0;
}

#guest-vendor-create-content.main .content-wrapper .heading .icon {
  height: 150px;
  margin: 45px 0;
}

#guest-vendor-create-content.main .content-wrapper .body .row-1 > [class^=col] {
  background-color: #f0f7f7;
  padding-top: 100px;
  padding-bottom: 100px;
  font-family: "Roboto", sans-serif;
  color: #585858;
  font-weight: bold;
}

#guest-vendor-create-content.main .content-wrapper .body .row-1 > [class^=col] strong {
  font-size: 30px;
  margin-bottom: 35px;
  display: block;
}

#guest-vendor-create-content.main .content-wrapper .body .row-1 > [class^=col] .comment-box {
  margin-top: 15px;
  padding-left: 180px;
  position: relative;
  font-weight: normal;
}

#guest-vendor-create-content.main .content-wrapper .body .row-1 > [class^=col] .comment-box .comment-icon {
  position: absolute;
  left: 0;
  top: 0;
}

#guest-vendor-create-content.main .content-wrapper .body .row-1 > [class^=col] .comment-box .comment-icon:before {
  content: '';
  display: block;
  width: 125px;
  height: 125px;
  border-radius: 50%;
  background-color: #d8d8d8;
}

#guest-vendor-create-content.main .content-wrapper .body .row-1 > [class^=col] .comment-box .comment-message {
  background-color: #FFFFFF;
  padding: 20px 15px;
  border: 2px solid #e5e5e5;
  margin-bottom: 15px;
  max-width: 325px;
}

#guest-vendor-create-content.main .content-wrapper .body .row-1 > [class^=col] .comment-box .blue {
  color: #2c81ac;
}

#guest-vendor-create-content.main .content-wrapper .body .row-1 > [class^=col] .icon.icon-reputation {
  width: 255px;
  height: 245px;
  background-size: cover;
  background-image: url("../../images/root/guest/vendor/type/overview/star icon.png");
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

#guest-vendor-create-content.main .content-wrapper .body .row-2 > [class^=col] {
  padding-top: 50px;
  padding-bottom: 130px;
  font-family: "Roboto", sans-serif;
  color: #585858;
  text-align: center;
  font-weight: bold;
}

#guest-vendor-create-content.main .content-wrapper .body .row-2 > [class^=col] .icon {
  margin: 0 auto 35px;
}

#guest-vendor-create-content.main .content-wrapper .body .row-2 > [class^=col] .icon.icon-sales {
  width: 108px;
  height: 130px;
  background-position: 389px -887px;
  background-image: url(../../images/root/all.png);
}

#guest-vendor-create-content.main .content-wrapper .body .row-2 > [class^=col] .icon.icon-conversion {
  width: 135px;
  height: 130px;
  background-position: 610px -887px;
  background-image: url(../../images/root/all.png);
}

#guest-vendor-create-content.main .content-wrapper .body .row-2 > [class^=col] strong {
  font-size: 30px;
  margin-bottom: 35px;
  display: block;
}

#guest-vendor-create-content.main .content-wrapper .body .row-2 > [class^=col].grey-background {
  background-color: #dfeeee;
}

#guest-vendor-create-content.main .content-wrapper .body .row-2 > [class^=col].green-background {
  background-color: #cffff0;
}

#guest-vendor-create-content.main .content-wrapper .body .row-3 > [class^=col] {
  background-color: #f0f7f7;
  padding-top: 100px;
  padding-bottom: 100px;
  font-family: "Roboto", sans-serif;
  color: #585858;
  font-weight: bold;
}

#guest-vendor-create-content.main .content-wrapper .body .row-3 > [class^=col] strong {
  font-size: 30px;
  margin-bottom: 35px;
  display: block;
}

#guest-vendor-create-content.main .content-wrapper .body .row-3 > [class^=col] .comment-box {
  margin-top: 15px;
  padding-left: 180px;
  position: relative;
  font-weight: normal;
}

#guest-vendor-create-content.main .content-wrapper .body .row-3 > [class^=col] .comment-box .comment-icon {
  position: absolute;
  left: 0;
  top: 0;
}

#guest-vendor-create-content.main .content-wrapper .body .row-3 > [class^=col] .comment-box .comment-icon:before {
  content: '';
  display: block;
  width: 125px;
  height: 125px;
  border-radius: 50%;
  background-color: #d8d8d8;
}

#guest-vendor-create-content.main .content-wrapper .body .row-3 > [class^=col] .comment-box .comment-message {
  background-color: #FFFFFF;
  padding: 20px 15px;
  border: 2px solid #e5e5e5;
  margin-bottom: 15px;
  max-width: 325px;
}

#guest-vendor-create-content.main .content-wrapper .body .row-3 > [class^=col] .comment-box .blue {
  color: #2c81ac;
}

#guest-vendor-create-content.main .content-wrapper .body .row-3 > [class^=col] .icon.icon-matches {
  width: 257px;
  height: 226px;
  background-position: -31px -837px;
  background-image: url(../../images/root/all.png);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

#guest-vendor-create-content.overview .content-wrapper .heading {
  padding: 70px 0;
  background-color: #6a90b9;
  text-align: center;
}

#guest-vendor-create-content.overview .content-wrapper .heading h1 {
  font-size: 40px;
  color: #02cd8d;
  font-family: "Roboto", sans-serif;
  letter-spacing: 2px;
  margin: 0 0 40px;
  line-height: 1;
}

#guest-vendor-create-content.overview .content-wrapper .heading .description {
  color: #FFFFFF;
  font-family: "Roboto", sans-serif;
  letter-spacing: 1px;
  font-size: 16px;
  line-height: 1.5;
  margin: 0;
}

#guest-vendor-create-content.overview .content-wrapper .heading .icon {
  height: 150px;
  margin: 45px 0;
}

#guest-vendor-create-content.overview .content-wrapper .body {
  color: #18293e;
}

#guest-vendor-create-content.overview .content-wrapper .body .row-1 > [class^=col] {
  background-color: #f0f7f7;
  padding-top: 100px;
  padding-bottom: 100px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
}

#guest-vendor-create-content.overview .content-wrapper .body .row-1 > [class^=col] strong {
  font-size: 30px;
  margin-bottom: 35px;
  display: block;
}

#guest-vendor-create-content.overview .content-wrapper .body .row-1 > [class^=col] .comment-box {
  margin-top: 15px;
  padding-left: 180px;
  position: relative;
  font-weight: normal;
}

#guest-vendor-create-content.overview .content-wrapper .body .row-1 > [class^=col] .comment-box .comment-icon {
  position: absolute;
  left: 0;
  top: 0;
}

#guest-vendor-create-content.overview .content-wrapper .body .row-1 > [class^=col] .comment-box .comment-message {
  background-color: #FFFFFF;
  padding: 20px 15px;
  border: 2px solid #e5e5e5;
  margin-bottom: 15px;
  max-width: 325px;
}

#guest-vendor-create-content.overview .content-wrapper .body .row-1 > [class^=col] .comment-box .blue {
  color: #2c81ac;
}

#guest-vendor-create-content.overview .content-wrapper .body .row-1 > [class^=col] .icon.icon-reputation {
  width: 255px;
  height: 245px;
  background-size: cover;
  background-image: url("../../images/root/guest/vendor/type/overview/star icon.png");
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

#guest-vendor-create-content.overview .content-wrapper .body .row-2 > [class^=col] {
  padding-top: 50px;
  padding-bottom: 130px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: bold;
}

#guest-vendor-create-content.overview .content-wrapper .body .row-2 > [class^=col] .icon {
  margin: 0 auto 35px;
}

#guest-vendor-create-content.overview .content-wrapper .body .row-2 > [class^=col] .icon.icon-sales {
  width: 108px;
  height: 130px;
  background-position: 389px -887px;
  background-image: url(../../images/root/all.png);
}

#guest-vendor-create-content.overview .content-wrapper .body .row-2 > [class^=col] .icon.icon-conversion {
  width: 135px;
  height: 130px;
  background-position: 610px -887px;
  background-image: url(../../images/root/all.png);
}

#guest-vendor-create-content.overview .content-wrapper .body .row-2 > [class^=col] strong {
  font-size: 30px;
  margin-bottom: 35px;
  display: block;
}

#guest-vendor-create-content.overview .content-wrapper .body .row-2 > [class^=col].grey-background {
  background-color: #dfeeee;
}

#guest-vendor-create-content.overview .content-wrapper .body .row-2 > [class^=col].green-background {
  background-color: #cffff0;
}

#guest-vendor-create-content.overview .content-wrapper .body .row-3 > [class^=col] {
  background-color: #f0f7f7;
  padding-top: 100px;
  padding-bottom: 100px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  padding-left: 120px;
}

#guest-vendor-create-content.overview .content-wrapper .body .row-3 > [class^=col] strong {
  font-size: 30px;
  margin-bottom: 35px;
  display: block;
}

#guest-vendor-create-content.overview .content-wrapper .body .row-3 > [class^=col] .comment-box {
  margin-top: 15px;
  padding-left: 180px;
  position: relative;
  font-weight: normal;
}

#guest-vendor-create-content.overview .content-wrapper .body .row-3 > [class^=col] .comment-box .comment-icon {
  position: absolute;
  left: 0;
  top: 0;
}

#guest-vendor-create-content.overview .content-wrapper .body .row-3 > [class^=col] .comment-box .comment-message {
  background-color: #FFFFFF;
  padding: 20px 15px;
  border: 2px solid #e5e5e5;
  margin-bottom: 15px;
  max-width: 325px;
}

#guest-vendor-create-content.overview .content-wrapper .body .row-3 > [class^=col] .comment-box .blue {
  color: #2c81ac;
}

#guest-vendor-create-content.overview .content-wrapper .body .row-3 > [class^=col] .icon.icon-matches {
  width: 257px;
  height: 226px;
  background-position: -31px -837px;
  background-image: url(../../images/root/all.png);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

#guest-vendor-create-content.works .content-wrapper .heading {
  padding: 70px 0;
  background-color: #6a90b9;
  text-align: center;
}

#guest-vendor-create-content.works .content-wrapper .heading h1 {
  font-size: 40px;
  color: #02cd8d;
  font-family: "Roboto", sans-serif;
  letter-spacing: 2px;
  margin: 0 0 40px;
  line-height: 1;
}

#guest-vendor-create-content.works .content-wrapper .heading .description {
  color: #FFFFFF;
  font-family: "Roboto", sans-serif;
  letter-spacing: 1px;
  font-size: 16px;
  line-height: 1.5;
  margin: 0;
}

#guest-vendor-create-content.works .content-wrapper .heading .icon {
  height: 150px;
  margin: 45px 0;
}

#guest-vendor-create-content.works .content-wrapper .body .description {
  font-family: "Roboto", sans-serif;
  color: #18293e;
  font-weight: bold;
  transform: translate(0, -50%);
  top: 50%;
  width: 550px;
  position: absolute;
  margin-top: 50px;
}

#guest-vendor-create-content.works .content-wrapper .body .description strong {
  font-size: 30px;
  margin-bottom: 35px;
  display: block;
}

#guest-vendor-create-content.works .content-wrapper .body .image {
  width: 600px;
  height: 360px;
  background-color: #FFFFFF;
  border-radius: 20px 20px 0 0;
  padding: 30px 30px 0 30px;
}

#guest-vendor-create-content.works .content-wrapper .body .row-1 {
  margin-left: -15px;
  margin-right: -15px;
  background-color: #f0f7f7;
}

#guest-vendor-create-content.works .content-wrapper .body .row-1 .inner {
  position: relative;
  padding-right: 400px;
  padding-top: 70px;
}

#guest-vendor-create-content.works .content-wrapper .body .row-1 .inner .description {
  right: 0;
}

#guest-vendor-create-content.works .content-wrapper .body .row-1 .inner .description ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#guest-vendor-create-content.works .content-wrapper .body .row-1 .inner .description ul li {
  padding-left: 10px;
  position: relative;
}

#guest-vendor-create-content.works .content-wrapper .body .row-1 .inner .description ul li:before {
  content: '-';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

#guest-vendor-create-content.works .content-wrapper .body .row-1 .inner .image {
  float: left;
  background: url("../../images/root/guest/vendor/type/works/how-it-works-for-vendor-step-1.png") no-repeat top;
  background-size: cover;
  height: 370px;
}

#guest-vendor-create-content.works .content-wrapper .body .row-2 {
  margin-left: -15px;
  margin-right: -15px;
  background-color: #cffff0;
}

#guest-vendor-create-content.works .content-wrapper .body .row-2 .inner {
  position: relative;
  padding-left: 400px;
  padding-top: 30px;
}

#guest-vendor-create-content.works .content-wrapper .body .row-2 .inner .description {
  left: 0;
}

#guest-vendor-create-content.works .content-wrapper .body .row-2 .inner .description ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#guest-vendor-create-content.works .content-wrapper .body .row-2 .inner .description li {
  padding-left: 10px;
  position: relative;
}

#guest-vendor-create-content.works .content-wrapper .body .row-2 .inner .description li:before {
  content: '-';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

#guest-vendor-create-content.works .content-wrapper .body .row-2 .inner .image {
  float: right;
  background: url("../../images/root/guest/vendor/type/works/how-it-works-for-vendor-step-2.png") no-repeat top;
  background-size: cover;
  height: 600px;
}

#guest-vendor-create-content.works .content-wrapper .body .row-3 {
  margin-left: -15px;
  margin-right: -15px;
  background-color: #f0f7f7;
}

#guest-vendor-create-content.works .content-wrapper .body .row-3 .inner {
  position: relative;
  padding-right: 400px;
  padding-top: 60px;
  right: 0;
}

#guest-vendor-create-content.works .content-wrapper .body .row-3 .inner .description {
  right: 0;
}

#guest-vendor-create-content.works .content-wrapper .body .row-3 .inner .description ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#guest-vendor-create-content.works .content-wrapper .body .row-3 .inner .description li {
  padding-left: 10px;
  position: relative;
}

#guest-vendor-create-content.works .content-wrapper .body .row-3 .inner .description li:before {
  content: '-';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

#guest-vendor-create-content.works .content-wrapper .body .row-3 .inner .image {
  float: left;
  background: url("../../images/root/guest/vendor/type/works/how-it-works-for-vendor-step-3.png") no-repeat top;
  background-size: cover;
  height: 390px;
}

#guest-vendor-create-content.works .content-wrapper .body .row-4 {
  margin-left: -15px;
  margin-right: -15px;
  background-color: #cffff0;
}

#guest-vendor-create-content.works .content-wrapper .body .row-4 .inner {
  position: relative;
  padding-left: 400px;
  padding-top: 100px;
}

#guest-vendor-create-content.works .content-wrapper .body .row-4 .inner .description {
  left: 0;
}

#guest-vendor-create-content.works .content-wrapper .body .row-4 .inner .description ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#guest-vendor-create-content.works .content-wrapper .body .row-4 .inner .description li {
  padding-left: 10px;
  position: relative;
}

#guest-vendor-create-content.works .content-wrapper .body .row-4 .inner .description li:before {
  content: '-';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

#guest-vendor-create-content.works .content-wrapper .body .row-4 .inner .image {
  float: right;
  background: url("../../images/root/guest/vendor/type/works/how-it-works-for-vendor-step-4.png") no-repeat top;
  background-size: cover;
}

#guest-vendor-create-content.features .content-wrapper .heading {
  padding: 70px 0;
  background-color: #6a90b9;
  text-align: center;
}

#guest-vendor-create-content.features .content-wrapper .heading h1 {
  font-size: 40px;
  color: #02cd8d;
  font-family: "Roboto", sans-serif;
  letter-spacing: 2px;
  margin: 0 0 40px;
  line-height: 1;
}

#guest-vendor-create-content.features .content-wrapper .heading .description {
  color: #FFFFFF;
  font-family: "Roboto", sans-serif;
  letter-spacing: 1px;
  font-size: 16px;
  line-height: 1.5;
  margin: 0;
}

#guest-vendor-create-content.features .content-wrapper .heading .icon {
  height: 150px;
  margin: 45px 0;
}

#guest-vendor-create-content.features .content-wrapper .body .description {
  font-family: "Roboto", sans-serif;
  color: #18293e;
  font-weight: bold;
  transform: translate(0, -50%);
  top: 50%;
  width: 550px;
  position: absolute;
  margin-top: 50px;
}

#guest-vendor-create-content.features .content-wrapper .body .description strong {
  font-size: 30px;
  margin-bottom: 35px;
  display: block;
}

#guest-vendor-create-content.features .content-wrapper .body .image {
  width: 600px;
  height: 300px;
  background-color: #FFFFFF;
  border-radius: 20px 20px 0 0;
  padding: 30px 30px 0 30px;
}

#guest-vendor-create-content.features .content-wrapper .body .row-1 {
  margin-left: -15px;
  margin-right: -15px;
  background-color: #f0f7f7;
}

#guest-vendor-create-content.features .content-wrapper .body .row-1 .inner {
  position: relative;
  padding-right: 400px;
  padding-top: 100px;
}

#guest-vendor-create-content.features .content-wrapper .body .row-1 .inner .description {
  right: 0;
}

#guest-vendor-create-content.features .content-wrapper .body .row-1 .inner .image {
  float: left;
  background: url("../../images/root/guest/vendor/type/features/2Easy-join as pro-features-step-1.png") no-repeat top;
  background-size: cover;
}

#guest-vendor-create-content.features .content-wrapper .body .row-2 {
  margin-left: -15px;
  margin-right: -15px;
  background-color: #cffff0;
}

#guest-vendor-create-content.features .content-wrapper .body .row-2 .inner {
  position: relative;
  padding-left: 400px;
  padding-top: 30px;
}

#guest-vendor-create-content.features .content-wrapper .body .row-2 .inner .description {
  left: 0;
}

#guest-vendor-create-content.features .content-wrapper .body .row-2 .inner .image {
  float: right;
  background: url("../../images/root/guest/vendor/type/features/2Easy-join as pro-features-step-2.png") no-repeat top;
  background-size: cover;
  height: 315px;
}

#guest-vendor-create-content.features .content-wrapper .body .row-3 {
  margin-left: -15px;
  margin-right: -15px;
  background-color: #f0f7f7;
}

#guest-vendor-create-content.features .content-wrapper .body .row-3 .inner {
  position: relative;
  padding-right: 400px;
  padding-top: 30px;
  right: 0;
}

#guest-vendor-create-content.features .content-wrapper .body .row-3 .inner .description {
  right: 0;
}

#guest-vendor-create-content.features .content-wrapper .body .row-3 .inner .image {
  float: left;
  background: url("../../images/root/guest/vendor/type/features/2Easy-join as pro-features-step-3.png") no-repeat top;
  background-size: cover;
  height: 380px;
}

#guest-vendor-create-content.pricing {
  background-color: #eaeaea;
}

#guest-vendor-create-content.pricing .content-wrapper .heading {
  padding: 70px 0;
  text-align: center;
}

#guest-vendor-create-content.pricing .content-wrapper .heading h1 {
  font-size: 40px;
  color: #02cd8d;
  font-family: "Roboto", sans-serif;
  letter-spacing: 2px;
  margin: 0 0 40px;
  line-height: 1;
}

#guest-vendor-create-content.pricing .content-wrapper .body {
  padding: 0 0 70px;
  text-align: center;
}

#guest-vendor-create-content.pricing .content-wrapper .body p {
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  width: 360px;
  text-align: left;
}

#guest-vendor-create-content.pricing .content-wrapper .body .box {
  width: 360px;
  display: inline-block;
  margin: 0 85px;
  padding: 60px 0;
  text-align: left;
  color: #272727;
  background-color: #FFFFFF;
}

#guest-vendor-create-content.pricing .content-wrapper .body .box .box-heading {
  text-align: center;
  padding: 0 60px;
  font-size: 30px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  margin: 0 0 40px;
  line-height: 1;
}

#guest-vendor-create-content.pricing .content-wrapper .body .box .box-body {
  padding: 0 60px;
}

#guest-vendor-create-content.pricing .content-wrapper .body .box .box-body .price {
  text-align: center;
  color: #57bafb;
  margin-bottom: 40px;
  font-size: 20px;
}

#guest-vendor-create-content.pricing .content-wrapper .body .box .box-body .price span {
  font-size: 60px;
}

#guest-vendor-create-content.pricing .content-wrapper .body .box .box-body .price.red {
  color: #fc3842;
}

#guest-vendor-create-content.pricing .content-wrapper .body .box .box-body ul {
  list-style: none;
  margin: -20px;
  padding: 0;
}

#guest-vendor-create-content.pricing .content-wrapper .body .box .box-body ul li {
  padding-left: 50px;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
}

#guest-vendor-create-content.pricing .content-wrapper .body .box .box-body ul li:not(:first-child) {
  margin-top: 15px;
}

#guest-vendor-create-content.pricing .content-wrapper .body .box .box-body a {
  margin-top: 50px;
  width: 100%;
  text-align: center;
}

#guest-vendor-create-content.pricing .content-wrapper .body .box .box-body .tick-green {
  line-height: 20px;
  margin-bottom: 3px;
}

#guest-vendor-create-content.pricing .content-wrapper .body .box .box-body .tick-green:before {
  content: '';
  display: inline-block;
  background: url("../../images/root/all.png") no-repeat -767px -556px;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  vertical-align: middle;
}

#guest-vendor-create-content.pricing .content-wrapper .body .box .box-body .cross {
  line-height: 20px;
  margin-bottom: 3px;
}

#guest-vendor-create-content.pricing .content-wrapper .body .box .box-body .cross:before {
  content: '';
  display: inline-block;
  background: url("../../images/root/all.png") no-repeat -664px -556px;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  vertical-align: middle;
}

#guest-vendor-create-content.pricing .content-wrapper .body .box .box-body .btn {
  display: block;
  background-color: #02cd8d;
  position: relative;
  padding: 10px 25px 13px;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  color: #ffffe7;
  cursor: pointer;
  white-space: nowrap;
  text-decoration: none;
}

#guest-vendor-create-content.pricing .content-wrapper .body .box .box-body .btn span {
  position: relative;
  z-index: 2;
}

#guest-vendor-create-content.pricing .content-wrapper .body .box .box-body .btn:after {
  transition: height .3s;
  height: 5px;
  width: 100%;
  position: absolute;
  bottom: 0;
  background-color: #28ac78;
  left: 0;
  content: '';
  z-index: 1;
}

#guest-vendor-create-content.pricing .content-wrapper .body .box .box-body .btn:hover:after {
  height: 100%;
}

@media only screen and (max-width: 991px) {
  #guest-vendor-create-banner-wrapper .inner .title {
    width: 100%;
    white-space: inherit;
    padding-left: 30px;
    padding-right: 30px;
  }
  #guest-vendor-create-banner-wrapper .inner .description {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }
  #guest-vendor-create-content.overview .content-wrapper .body .row-1 > [class^=col] {
    padding-top: 30px;
    padding-bottom: 30px;
    text-align: center;
  }
  #guest-vendor-create-content.overview .content-wrapper .body .row-1 > [class^=col] strong {
    font-size: 24px;
    margin-bottom: 35px;
    display: block;
  }
  #guest-vendor-create-content.overview .content-wrapper .body .row-1 > [class^=col] .comment-box {
    text-align: center;
    width: 600px;
  }
  #guest-vendor-create-content.overview .content-wrapper .body .row-1 > [class^=col] .comment-box .comment-icon {
    left: 35px;
    top: 35px;
  }
  #guest-vendor-create-content.overview .content-wrapper .body .row-1 > [class^=col] .comment-box .comment-message {
    max-width: 100%;
  }
  #guest-vendor-create-content.overview .content-wrapper .body .row-1 > [class^=col] .icon.icon-reputation {
    position: static;
    transform: initial;
    margin: 0 auto;
  }
  #guest-vendor-create-content.overview .content-wrapper .body .row-1 > [class^=col]:not(:first-child) {
    padding-top: 0;
  }
  #guest-vendor-create-content.overview .content-wrapper .body .row-2 > [class^=col] {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  #guest-vendor-create-content.overview .content-wrapper .body .row-2 > [class^=col] strong {
    font-size: 24px;
  }
  #guest-vendor-create-content.overview .content-wrapper .body .row-3 > [class^=col] {
    padding: 40px 20px;
  }
  #guest-vendor-create-content.overview .content-wrapper .body .row-3 > [class^=col] .pull-right {
    float: none;
    text-align: center;
  }
  #guest-vendor-create-content.overview .content-wrapper .body .row-3 > [class^=col] strong {
    font-size: 24px;
  }
  #guest-vendor-create-content.overview .content-wrapper .body .row-3 > [class^=col] .icon.icon-matches {
    margin: 0 auto;
    position: static;
    transform: initial;
  }
  #guest-vendor-create-content.overview .content-wrapper .body .row-3 > [class^=col] .comment-box {
    text-align: left;
  }
  #guest-vendor-create-content.overview .content-wrapper .body .row-3 > [class^=col] .comment-box .comment-message {
    max-width: initial;
  }
  #guest-vendor-create-content.features .content-wrapper .body .description {
    left: 50%;
    width: 56%;
    top: 40%;
  }
  #guest-vendor-create-content.features .content-wrapper .body .image {
    width: 100%;
  }
  #guest-vendor-create-content.works .content-wrapper .body .description {
    left: 50%;
    width: 50%;
    top: 40%;
  }
  #guest-vendor-create-content.works .content-wrapper .body .image {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  #guest-vendor-create-banner-wrapper {
    height: 100vh;
    max-height: 550px;
  }
  #guest-vendor-create-banner-wrapper .inner {
    width: 100%;
  }
  #guest-vendor-create-banner-wrapper .inner .title {
    font-size: 20px;
    margin: 0 auto 20px;
  }
  #guest-vendor-create-banner-wrapper .inner .description {
    font-size: 18px;
    padding: 0;
    margin: 0;
  }
  #guest-vendor-create-banner-wrapper .inner .text-center .btn-effect-1 {
    text-align: center;
    margin: 0 auto;
  }
  #guest-vendor-create-banner-wrapper .text-center {
    padding-left: 20px;
    padding-right: 20px;
  }
  #guest-vendor-create-content.overview .content-wrapper .body .row-1 > [class^=col] strong {
    padding-bottom: 20px;
    margin-bottom: 0px;
    line-height: 30px;
  }
  #guest-vendor-create-content.overview .content-wrapper .body .row-1 > [class^=col] .comment-box {
    text-align: center;
    width: 100%;
    padding-left: 110px;
  }
  #guest-vendor-create-content.overview .content-wrapper .body .row-1 > [class^=col] .comment-box .comment-icon {
    left: 0;
    top: 0;
  }
  #guest-vendor-create-content.overview .content-wrapper .body .row-1 > [class^=col] .comment-box .comment-icon .comment-message {
    max-width: 100%;
  }
  #guest-vendor-create-content.overview .content-wrapper .body .row-1 > [class^=col] .icon.icon-reputation {
    width: 155px;
    height: 144px;
  }
  #guest-vendor-create-content.overview .content-wrapper .body .row-2 > [class^=col] strong {
    line-height: 30px;
  }
  #guest-vendor-create-content.overview .content-wrapper .body .row-3 > [class^=col] .pull-right {
    padding-right: 0px;
    width: 100%;
  }
  #guest-vendor-create-content.overview .content-wrapper .body .row-3 > [class^=col] .pull-right strong {
    line-height: 30px;
    padding-bottom: 20px;
    margin-bottom: 0px;
  }
  #guest-vendor-create-content.overview .content-wrapper .body .row-3 > [class^=col] .pull-right .comment-box {
    text-align: center;
    width: 100%;
    padding-left: 90px;
  }
  #guest-vendor-create-content.overview .content-wrapper .body .row-3 > [class^=col] .pull-right .comment-box .comment-icon {
    left: -15px;
    top: 0;
  }
  #guest-vendor-create-content.overview .content-wrapper .body .row-3 > [class^=col] .pull-right .comment-box .comment-icon img {
    width: 95px;
    height: 95px;
  }
  #guest-vendor-create-content.overview .content-wrapper .body .row-3 > [class^=col] .pull-right .comment-box .comment-message {
    max-width: 100%;
  }
  #guest-vendor-create-content.works .content-wrapper .body .description {
    left: 0%;
    width: 100%;
    top: 120%;
  }
  #guest-vendor-create-content.works .content-wrapper .body .row-1, #guest-vendor-create-content.works .content-wrapper .body .row-2, #guest-vendor-create-content.works .content-wrapper .body .row-3, #guest-vendor-create-content.works .content-wrapper .body .row-4 {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  #guest-vendor-create-content.works .content-wrapper .body .row-1 .inner, #guest-vendor-create-content.works .content-wrapper .body .row-2 .inner, #guest-vendor-create-content.works .content-wrapper .body .row-3 .inner, #guest-vendor-create-content.works .content-wrapper .body .row-4 .inner {
    padding: 0;
  }
  #guest-vendor-create-content.works .content-wrapper .body .row-1 .inner .image, #guest-vendor-create-content.works .content-wrapper .body .row-2 .inner .image, #guest-vendor-create-content.works .content-wrapper .body .row-3 .inner .image, #guest-vendor-create-content.works .content-wrapper .body .row-4 .inner .image {
    height: 260px;
    float: none;
  }
  #guest-vendor-create-content.works .content-wrapper .body .row-1 .inner .description, #guest-vendor-create-content.works .content-wrapper .body .row-2 .inner .description, #guest-vendor-create-content.works .content-wrapper .body .row-3 .inner .description, #guest-vendor-create-content.works .content-wrapper .body .row-4 .inner .description {
    position: static;
    margin-top: 20px;
    padding-right: 5px;
    padding-top: 5px;
    transform: initial;
    font-size: 14px;
  }
  #guest-vendor-create-content.works .content-wrapper .body .row-1 .inner .description strong, #guest-vendor-create-content.works .content-wrapper .body .row-2 .inner .description strong, #guest-vendor-create-content.works .content-wrapper .body .row-3 .inner .description strong, #guest-vendor-create-content.works .content-wrapper .body .row-4 .inner .description strong {
    font-size: 20px;
    margin-bottom: 10px;
  }
  #guest-vendor-create-content.works .content-wrapper .body .row-1 .inner .description br, #guest-vendor-create-content.works .content-wrapper .body .row-2 .inner .description br, #guest-vendor-create-content.works .content-wrapper .body .row-3 .inner .description br, #guest-vendor-create-content.works .content-wrapper .body .row-4 .inner .description br {
    display: none;
  }
  #guest-vendor-create-content.features .content-wrapper .body .description {
    left: 0%;
    width: 100%;
    top: 120%;
  }
  #guest-vendor-create-content.features .content-wrapper .body .row-1, #guest-vendor-create-content.features .content-wrapper .body .row-2, #guest-vendor-create-content.features .content-wrapper .body .row-3 {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  #guest-vendor-create-content.features .content-wrapper .body .row-1 .inner, #guest-vendor-create-content.features .content-wrapper .body .row-2 .inner, #guest-vendor-create-content.features .content-wrapper .body .row-3 .inner {
    padding: 0;
  }
  #guest-vendor-create-content.features .content-wrapper .body .row-1 .inner .image, #guest-vendor-create-content.features .content-wrapper .body .row-2 .inner .image, #guest-vendor-create-content.features .content-wrapper .body .row-3 .inner .image {
    height: 260px;
    float: none;
  }
  #guest-vendor-create-content.features .content-wrapper .body .row-1 .inner .description, #guest-vendor-create-content.features .content-wrapper .body .row-2 .inner .description, #guest-vendor-create-content.features .content-wrapper .body .row-3 .inner .description {
    position: static;
    margin-top: 20px;
    padding-right: 5px;
    padding-top: 5px;
    transform: initial;
    font-size: 14px;
  }
  #guest-vendor-create-content.features .content-wrapper .body .row-1 .inner .description strong, #guest-vendor-create-content.features .content-wrapper .body .row-2 .inner .description strong, #guest-vendor-create-content.features .content-wrapper .body .row-3 .inner .description strong {
    font-size: 20px;
    margin-bottom: 10px;
  }
  #guest-vendor-create-content.features .content-wrapper .body .row-1 .inner .description br, #guest-vendor-create-content.features .content-wrapper .body .row-2 .inner .description br, #guest-vendor-create-content.features .content-wrapper .body .row-3 .inner .description br {
    display: none;
  }
  #guest-vendor-create-content.pricing .content-wrapper .heading {
    padding: 40px 0;
  }
  #guest-vendor-create-content.pricing .content-wrapper .heading h1 {
    font-size: 30px;
    margin: 0;
  }
  #guest-vendor-create-content.pricing .content-wrapper .body {
    padding: 0 0 70px;
    text-align: center;
  }
  #guest-vendor-create-content.pricing .content-wrapper .body p {
    width: 100%;
  }
  #guest-vendor-create-content.pricing .content-wrapper .body .box {
    width: 100%;
    margin: 0;
    padding: 20px 0;
  }
  #guest-vendor-create-content.pricing .content-wrapper .body .box .box-heading {
    padding: 0 30px;
    margin: 0;
  }
}

#guest-vendor-welcome-content.language .content-wrapper {
  background: #F0F4F7;
}

#guest-vendor-welcome-content.language .content-wrapper .heading {
  padding: 70px 0;
  text-align: left;
  color: #313232;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
}

#guest-vendor-welcome-content.language .content-wrapper .heading .inner {
  width: 940px;
  margin: 0 auto;
  text-align: center;
}

#guest-vendor-welcome-content.language .content-wrapper .heading h1 {
  font-size: 30px;
  color: #313232;
  margin: 0 0 30px;
  line-height: 1.5;
  position: relative;
  font-weight: bolder;
  text-align: center;
}

#guest-vendor-welcome-content.language .content-wrapper .heading h1:before {
  content: '';
  display: block;
  margin: 0 auto 30px;
  width: 100px;
  height: 100px;
  background: url("../../images/root/all.png") no-repeat -723px -735px;
}

#guest-vendor-welcome-content.language .content-wrapper .body {
  padding-bottom: 70px;
  text-align: center;
}

#guest-vendor-welcome-content.language .content-wrapper .body .button-group {
  width: 345px;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: -15px;
}

#guest-vendor-welcome-content.language .content-wrapper .body .button-group .row:not(:first-child) {
  margin-top: 30px;
}

#guest-vendor-welcome-content.language .content-wrapper .body .button-group label {
  display: block;
  cursor: pointer;
  padding: 15px 0;
  background-color: #FFFFFF;
  border: 1px solid #eaeaea;
  color: #585858;
  display: block;
  width: 100%;
  font-weight: normal;
  margin: 0;
  line-height: 1;
  border-radius: 5px;
}

#guest-vendor-welcome-content.language .content-wrapper .body .button-group label input {
  display: none;
}

#guest-vendor-welcome-content.language .content-wrapper .body .button-group label.checked {
  background-color: #cffff0;
  border: 1px solid #02cd8d;
}

#guest-vendor-welcome-content.language .content-wrapper .body .button-group button {
  width: 100%;
}

#guest-vendor-welcome-content.language .content-wrapper .body .btn {
  display: block;
  background-color: #02cd8d;
  position: relative;
  padding: 10px 25px 13px;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  color: #ffffe7;
  cursor: pointer;
  white-space: nowrap;
  text-decoration: none;
}

#guest-vendor-welcome-content.language .content-wrapper .body .btn span {
  position: relative;
  z-index: 2;
}

#guest-vendor-welcome-content.language .content-wrapper .body .btn:after {
  transition: height .3s;
  height: 5px;
  width: 100%;
  position: absolute;
  bottom: 0;
  background-color: #28ac78;
  left: 0;
  content: '';
  z-index: 1;
}

#guest-vendor-welcome-content.language .content-wrapper .body .btn:hover:after {
  height: 100%;
}

#guest-vendor-welcome-content.business .content-wrapper {
  background: #F0F4F7;
}

#guest-vendor-welcome-content.business .content-wrapper .heading {
  padding: 70px 0;
  text-align: left;
  color: #313232;
  font-family: "Roboto", sans-serif;
}

#guest-vendor-welcome-content.business .content-wrapper .heading .inner {
  width: 600px;
  margin: 0 auto;
  text-align: center;
}

#guest-vendor-welcome-content.business .content-wrapper .heading h1 {
  font-size: 30px;
  color: #313232;
  margin: 0 0 30px;
  line-height: 1.5;
  font-weight: bolder;
  text-align: center;
}

#guest-vendor-welcome-content.business .content-wrapper .heading h1:before {
  content: '';
  display: block;
  margin: 0 auto 30px;
  width: 100px;
  height: 100px;
  background: url("../../images/root/all.png") no-repeat -410px -719px;
}

#guest-vendor-welcome-content.business .content-wrapper .heading h1:before {
  content: '';
  display: block;
  margin: 0 auto 30px;
  width: 100px;
  height: 100px;
  background: url("../../images/root/all.png") no-repeat -571px -728px;
}

#guest-vendor-welcome-content.business .content-wrapper .body {
  padding-bottom: 70px;
  width: 640px;
  margin: 0 auto;
}

#guest-vendor-welcome-content.business .content-wrapper .body .form-group .group-header a {
  float: left;
  text-align: center;
  display: block;
  padding: 5px;
  width: 55px;
  color: #585858;
  border-top: 1px solid #eaeaea;
  border-left: 1px solid #eaeaea;
}

#guest-vendor-welcome-content.business .content-wrapper .body .form-group .group-header a:last-child {
  border-right: 1px solid #eaeaea;
}

#guest-vendor-welcome-content.business .content-wrapper .body .form-group .group-header a:not(.active) {
  background-color: #eaeaea;
}

#guest-vendor-welcome-content.business .content-wrapper .body .form-group .group-header a:hover {
  text-decoration: none;
}

#guest-vendor-welcome-content.business .content-wrapper .body .form-group .group-header a:hover:not(.active) {
  cursor: pointer;
}

#guest-vendor-welcome-content.business .content-wrapper .body .form-group .group-body {
  border: 1px solid #eaeaea;
}

#guest-vendor-welcome-content.business .content-wrapper .body .form-group .group-body .language {
  display: none;
}

#guest-vendor-welcome-content.business .content-wrapper .body .form-group .group-body .language input {
  padding: 10px 15px;
  width: 100%;
  border: none;
  background-color: #FFFFFF;
  outline: 0;
}

#guest-vendor-welcome-content.business .content-wrapper .body .form-group .group-body .language.active {
  display: block;
}

#guest-vendor-welcome-content.business .content-wrapper .body .action-group .back {
  color: #02cd8d;
  float: left;
  display: block;
  padding: 10px 10px;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  text-decoration: none;
  outline: 0;
  border: 0;
  cursor: pointer;
}

#guest-vendor-welcome-content.business .content-wrapper .body .action-group .back .fa {
  display: inline-block;
  padding-right: 10px;
  font-weight: bold;
}

#guest-vendor-welcome-content.service .content-wrapper {
  background: #F0F4F7;
}

#guest-vendor-welcome-content.service .content-wrapper .heading {
  padding: 70px 0;
  text-align: left;
  color: #313232;
  font-family: "Roboto", sans-serif;
}

#guest-vendor-welcome-content.service .content-wrapper .heading .inner {
  width: 740px;
  margin: 0 auto;
  text-align: center;
}

#guest-vendor-welcome-content.service .content-wrapper .heading h1 {
  font-size: 30px;
  color: #313232;
  margin: 0 0 30px;
  line-height: 1.5;
  font-weight: bolder;
  text-align: center;
}

#guest-vendor-welcome-content.service .content-wrapper .heading h1:before {
  content: '';
  display: block;
  margin: 0 auto 30px;
  width: 100px;
  height: 100px;
  background: url("../../images/root/all.png") no-repeat -410px -719px;
}

#guest-vendor-welcome-content.service .content-wrapper .body {
  padding: 0 15px 70px;
  width: 700px;
  margin: 0 auto;
}

#guest-vendor-welcome-content.service .content-wrapper .body .form-group {
  padding-left: 30px;
  padding-right: 30px;
}

#guest-vendor-welcome-content.service .content-wrapper .body .form-group label {
  border: 1px solid #eaeaea;
  display: block;
  font-weight: normal;
  width: 100%;
  margin: 0;
  padding-left: 50px;
  position: relative;
  background-color: #FFFFFF;
}

#guest-vendor-welcome-content.service .content-wrapper .body .form-group label .fa {
  position: absolute;
  left: 15px;
  top: 10px;
}

#guest-vendor-welcome-content.service .content-wrapper .body .form-group label input {
  padding: 10px 15px;
  width: 100%;
  border: none;
  background-color: #FFFFFF;
  outline: 0;
}

#guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option {
  margin-bottom: 30px;
  margin-left: -10px;
  margin-right: -10px;
}

#guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option .box-service {
  width: 25%;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;
  float: left;
}

#guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option .box-service .box-header {
  cursor: pointer;
  transition: border .3s;
  border: 1px solid #eaeaea;
  background-color: #FFFFFF;
  text-align: center;
  padding: 45px 0 38px;
}

#guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option .box-service .box-header .image-wrapper {
  position: relative;
  display: block;
  height: 40px;
  margin-bottom: 15px;
}

#guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option .box-service .box-header .image-wrapper img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option .box-service .box-header .image-wrapper img.default {
  display: block;
}

#guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option .box-service .box-header .image-wrapper img.hover {
  display: none;
}

#guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option .box-service .box-header h5 {
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  line-height: 1;
  margin: 0;
}

#guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option .box-service .box-header .back {
  display: none;
  float: right;
  padding: 10px 15px;
  border: 1px solid #585858;
  color: #585858;
  font-family: "Roboto", sans-serif;
}

#guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option .box-service .box-header .back .fa {
  display: inline-block;
  padding-right: 15px;
}

#guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option .box-service .box-header .back:hover {
  text-decoration: none;
}

#guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option .box-service .box-body {
  display: none;
  border-left: 1px solid #eaeaea;
  border-right: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
  background-color: #FFFFFF;
}

#guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option .box-service .box-body .col {
  float: left;
  width: 50%;
  padding: 10px;
}

#guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option .box-service .box-body .col .item {
  padding: 10px 15px;
  text-align: left;
  color: #585858;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  cursor: pointer;
}

#guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option .box-service .box-body .col .item .add {
  display: none;
  float: right;
}

#guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option .box-service .box-body .col .item .tick {
  display: none;
  float: right;
}

#guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option .box-service .box-body .col .item:not(.selected):hover {
  color: #FFFFFF;
  background-color: #373f48;
}

#guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option .box-service .box-body .col .item:not(.selected):hover .add {
  display: block;
  color: #FFFFFF;
}

#guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option .box-service .box-body .col .item.selected {
  background-color: #c7ffe4;
}

#guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option .box-service .box-body .col .item.selected .tick {
  display: block;
  color: #FFFFFF;
}

#guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option .box-service .box-body .col .item:after {
  content: '';
  display: table;
  clear: both;
}

#guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option .box-service:hover .box-header {
  border: 1px solid #02cd8d;
}

#guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option .box-service:hover .box-header .image-wrapper img.default {
  display: none;
}

#guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option .box-service:hover .box-header .image-wrapper img.hover {
  display: block;
}

#guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option .box-service:hover .box-header h5 {
  color: #02cd8d;
}

#guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option .box-all-service {
  display: none;
  width: 100%;
  float: left;
  padding-left: 10px;
  padding-right: 10px;
}

#guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option .box-all-service .box-header {
  transition: border .3s;
  border: 1px solid #eaeaea;
  background-color: #FFFFFF;
  text-align: left;
  padding: 30px;
}

#guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option .box-all-service .box-header h5 {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  line-height: 1.2;
  float: left;
}

#guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option .box-all-service .box-header .back {
  display: block;
  float: right;
  padding: 10px 15px;
  border: 1px solid #585858;
  color: #585858;
  font-family: "Roboto", sans-serif;
  cursor: pointer;
}

#guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option .box-all-service .box-header .back .fa {
  display: inline-block;
  padding-right: 15px;
}

#guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option .box-all-service .box-header .back:hover {
  text-decoration: none;
}

#guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option .box-all-service .box-body {
  border-left: 1px solid #eaeaea;
  border-right: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
  background-color: #FFFFFF;
}

#guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option .box-all-service .box-body .col {
  float: left;
  width: 50%;
  padding: 10px;
}

#guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option .box-all-service .box-body .col .item {
  padding: 10px 15px;
  text-align: left;
  color: #585858;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  cursor: pointer;
}

#guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option .box-all-service .box-body .col .item .add {
  display: none;
  float: right;
}

#guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option .box-all-service .box-body .col .item .tick {
  display: none;
  float: right;
}

#guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option .box-all-service .box-body .col .item:not(.selected):hover {
  color: #FFFFFF;
  background-color: #373f48;
}

#guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option .box-all-service .box-body .col .item:not(.selected):hover .add {
  display: block;
  color: #FFFFFF;
}

#guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option .box-all-service .box-body .col .item.selected {
  background-color: #c7ffe4;
}

#guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option .box-all-service .box-body .col .item.selected .tick {
  display: block;
  color: #FFFFFF;
}

#guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option .box-all-service .box-body .col .item:after {
  content: '';
  display: table;
  clear: both;
}

#guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option .box-all-service.active {
  display: block;
}

#guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option.extended .box-service {
  display: none;
}

#guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option.extended .box-service.active {
  display: block;
  width: 100%;
}

#guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option.extended .box-service.active .box-header {
  padding: 20px 30px;
}

#guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option.extended .box-service.active .box-header .image-wrapper {
  display: none;
}

#guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option.extended .box-service.active .box-header h5 {
  float: left;
  text-align: left;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  line-height: 40px;
}

#guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option.extended .box-service.active .box-header .back {
  display: block;
}

#guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option.extended .box-service.active .box-body {
  display: block;
}

#guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option.extended .box-service.active:hover .box-header {
  border: 1px solid #eaeaea;
}

#guest-vendor-welcome-content.service .content-wrapper .body .service-group .box-selected {
  margin-bottom: 30px;
}

#guest-vendor-welcome-content.service .content-wrapper .body .service-group .box-selected .box-heading {
  border: 1px solid #eaeaea;
  background-color: #FFFFFF;
  text-align: center;
  padding: 30px;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  line-height: 1.3;
}

#guest-vendor-welcome-content.service .content-wrapper .body .service-group .box-selected .box-body {
  display: block;
  border-left: 1px solid #eaeaea;
  border-right: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
  background-color: #FFFFFF;
}

#guest-vendor-welcome-content.service .content-wrapper .body .service-group .box-selected .box-body .col {
  float: left;
  width: 50%;
  padding: 10px;
}

#guest-vendor-welcome-content.service .content-wrapper .body .service-group .box-selected .box-body .col .item {
  padding: 10px 15px;
  text-align: left;
  color: #585858;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  cursor: pointer;
}

#guest-vendor-welcome-content.service .content-wrapper .body .service-group .box-selected .box-body .col .item .cancel {
  display: none;
  float: right;
}

#guest-vendor-welcome-content.service .content-wrapper .body .service-group .box-selected .box-body .col .item:hover {
  color: #FFFFFF;
  background-color: #373f48;
}

#guest-vendor-welcome-content.service .content-wrapper .body .service-group .box-selected .box-body .col .item:hover .cancel {
  display: block;
  color: #FFFFFF;
}

#guest-vendor-welcome-content.service .content-wrapper .body .service-group .box-selected .box-body .col .item:after {
  content: '';
  display: table;
  clear: both;
}

#guest-vendor-welcome-content.service .content-wrapper .body .action-group .back {
  color: #02cd8d;
  float: left;
  display: block;
  padding: 10px 10px;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  text-decoration: none;
  outline: 0;
  border: 0;
  cursor: pointer;
}

#guest-vendor-welcome-content.service .content-wrapper .body .action-group .back .fa {
  display: inline-block;
  padding-right: 10px;
  font-weight: bold;
}

#guest-vendor-welcome-content.info .content-wrapper {
  background: #F0F4F7;
}

#guest-vendor-welcome-content.info .content-wrapper .heading {
  padding: 70px 0;
  text-align: left;
  color: #313232;
  font-family: "Roboto", sans-serif;
  line-height: 1.5;
}

#guest-vendor-welcome-content.info .content-wrapper .heading .inner {
  width: 740px;
  margin: 0 auto;
  text-align: center;
}

#guest-vendor-welcome-content.info .content-wrapper .heading h1 {
  font-size: 30px;
  color: #313232;
  margin: 0 0 30px;
  line-height: 1;
  font-weight: bolder;
  text-align: center;
}

#guest-vendor-welcome-content.info .content-wrapper .heading h1:before {
  content: '';
  display: block;
  margin: 0 auto 30px;
  width: 100px;
  height: 100px;
  background: url("../../images/root/all.png") no-repeat -410px -719px;
}

#guest-vendor-welcome-content.info .content-wrapper .body {
  padding: 0 15px 70px;
  width: 960px;
  margin: 0 auto;
}

#guest-vendor-welcome-content.info .content-wrapper .body .form-group {
  color: #333333;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

#guest-vendor-welcome-content.info .content-wrapper .body .form-group:after {
  content: "";
  display: table;
  clear: both;
}

#guest-vendor-welcome-content.info .content-wrapper .body .form-group > label {
  color: #333333;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}

#guest-vendor-welcome-content.info .content-wrapper .body .form-group label.addMore {
  color: #02cd8d;
  line-height: 20px;
  font-size: 14px !important;
  margin-top: 12px !important;
}

#guest-vendor-welcome-content.info .content-wrapper .body .form-group label.addMore i {
  display: inline-block;
  margin-right: 5px;
  font-style: normal;
}

#guest-vendor-welcome-content.info .content-wrapper .body .form-group .checkboxes input[type=checkbox] {
  visibility: hidden;
  opacity: 0;
}

#guest-vendor-welcome-content.info .content-wrapper .body .form-group .checkboxes label {
  display: block;
  margin: 0 0 10px;
  font-size: 12px;
  cursor: pointer;
}

#guest-vendor-welcome-content.info .content-wrapper .body .form-group .checkboxes label span {
  display: inline-block;
  vertical-align: middle;
}

#guest-vendor-welcome-content.info .content-wrapper .body .form-group .checkboxes label span.checkbox {
  border: 1px solid #d4f0e3;
  background-color: #d8f1e6;
  border-radius: 2px;
  margin: 0 10px 0 0;
  width: 20px;
  height: 20px;
  position: relative;
  transition: background-color .3s;
}

#guest-vendor-welcome-content.info .content-wrapper .body .form-group .checkboxes label span.checkbox input {
  visibility: hidden;
  opacity: 0;
}

#guest-vendor-welcome-content.info .content-wrapper .body .form-group .checkboxes label.checked span.checkbox {
  background-color: #FFFFFF;
  border: 1px solid #02cd8d;
}

#guest-vendor-welcome-content.info .content-wrapper .body .form-group .checkboxes label.checked span.checkbox:before {
  top: 50%;
  left: 50%;
  content: '';
  position: absolute;
  height: 6px;
  width: 15px;
  border-left: 2px solid #02cd8d;
  border-bottom: 2px solid #02cd8d;
  display: block;
  margin: 0 auto;
  transform: translate(-50%, -55%) rotate(-45deg);
  -webkit-transform: translate(-50%, -55%) rotate(-45deg);
  -moz-transform: translate(-50%, -55%) rotate(-45deg);
  -ms-transform: translate(-50%, -55%) rotate(-45deg);
  -o-transform: translate(-50%, -55%) rotate(-45deg);
}

#guest-vendor-welcome-content.info .content-wrapper .body .form-group .checkboxes label:hover:not(.checked) span.checkbox {
  background-color: #d4f0e3;
}

#guest-vendor-welcome-content.info .content-wrapper .body .form-group .checkboxes label:hover.checked span.checkbox {
  background-color: #d8f1e6;
}

#guest-vendor-welcome-content.info .content-wrapper .body .form-group .checkboxes label:hover.checked span.checkbox:before {
  border-left: 2px solid #02cd8d;
  border-bottom: 2px solid #02cd8d;
}

#guest-vendor-welcome-content.info .content-wrapper .body .form-group .checkboxes input[type=text] {
  display: inline-block;
  width: auto;
}

#guest-vendor-welcome-content.info .content-wrapper .body .form-group .checkboxes .checkbox-group {
  margin-bottom: 10px;
}

#guest-vendor-welcome-content.info .content-wrapper .body .form-group .checkboxes .checkbox-group .checkbox-group {
  padding-left: 35px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s .5s, opacity .5s linear;
  height: 0;
  overflow: hidden;
  margin-bottom: 0;
}

#guest-vendor-welcome-content.info .content-wrapper .body .form-group .checkboxes .checkbox-group .checkbox-group.active {
  margin-bottom: 10px;
  overflow: visible;
  height: auto;
  visibility: visible;
  opacity: 1;
  transition: opacity .5s linear;
}

#guest-vendor-welcome-content.info .content-wrapper .body .form-group .checkboxes .checkbox-group label {
  margin-bottom: 0;
}

#guest-vendor-welcome-content.info .content-wrapper .body .form-group .checkboxes .checkbox-group .extend {
  display: inline-block;
  margin-left: 10px;
  color: #02cd8d;
  font-size: 24px;
  font-weight: bold;
}

#guest-vendor-welcome-content.info .content-wrapper .body .form-group .radios input[type=radio] {
  visibility: hidden;
  opacity: 0;
}

#guest-vendor-welcome-content.info .content-wrapper .body .form-group .radios label {
  display: block;
  margin: 0;
  font-size: 12px;
  cursor: pointer;
}

#guest-vendor-welcome-content.info .content-wrapper .body .form-group .radios label:not(:first-child) {
  margin-top: 10px;
}

#guest-vendor-welcome-content.info .content-wrapper .body .form-group .radios label span {
  display: inline-block;
  vertical-align: middle;
}

#guest-vendor-welcome-content.info .content-wrapper .body .form-group .radios label span.radio {
  border: 2px solid #d4f0e3;
  background-color: #d8f1e6;
  border-radius: 50%;
  margin: 0 10px 0 0;
  width: 20px;
  height: 20px;
  position: relative;
  transition: background-color .3s;
}

#guest-vendor-welcome-content.info .content-wrapper .body .form-group .radios label span.radio input {
  visibility: hidden;
  opacity: 0;
}

#guest-vendor-welcome-content.info .content-wrapper .body .form-group .radios label.checked span.radio {
  background-color: #02cd8d;
}

#guest-vendor-welcome-content.info .content-wrapper .body .form-group .radios input[type=text] {
  display: inline-block;
  width: auto;
}

#guest-vendor-welcome-content.info .content-wrapper .body .form-group textarea {
  width: 100%;
  outline: none;
  font-size: 12px;
  padding: 15px;
  border: 1px solid #e1e3e3;
  border-radius: 4px;
}

#guest-vendor-welcome-content.info .content-wrapper .body .form-group .label-file label {
  font-size: 14px;
  padding: 5px 15px 11px;
  margin-right: 20px;
  position: relative;
  border-radius: 3px;
  background-color: #02cd8d;
  cursor: pointer;
  overflow: hidden;
}

#guest-vendor-welcome-content.info .content-wrapper .body .form-group .label-file label:after {
  position: absolute;
  bottom: 0;
  height: 6px;
  left: 0;
  right: 0;
  background-color: #0d513b;
  transition: height .3s;
  content: '';
  display: block;
  z-index: 1;
}

#guest-vendor-welcome-content.info .content-wrapper .body .form-group .label-file label span {
  position: relative;
  z-index: 2;
  color: #FFFFFF;
}

#guest-vendor-welcome-content.info .content-wrapper .body .form-group .label-file label:hover:after {
  height: 100%;
}

#guest-vendor-welcome-content.info .content-wrapper .body .form-group .label-file input[type=file] {
  display: none;
}

#guest-vendor-welcome-content.info .content-wrapper .body .form-group .label-file input[type=text] {
  border: none;
  padding: 0;
}

#guest-vendor-welcome-content.info .content-wrapper .body .form-group input[type=text],
#guest-vendor-welcome-content.info .content-wrapper .body .form-group input[type=email],
#guest-vendor-welcome-content.info .content-wrapper .body .form-group select,
#guest-vendor-welcome-content.info .content-wrapper .body .form-group input[type=number],
#guest-vendor-welcome-content.info .content-wrapper .body .form-group input[type=password] {
  outline: 0;
  padding: 7px 15px;
  border: 1px solid #e1e3e3;
  border-radius: 2px;
  display: block;
  width: 100%;
  background-color: #fff;
  transition: border .3s;
}

#guest-vendor-welcome-content.info .content-wrapper .body .form-group input[type=text]:focus,
#guest-vendor-welcome-content.info .content-wrapper .body .form-group input[type=email]:focus,
#guest-vendor-welcome-content.info .content-wrapper .body .form-group select:focus,
#guest-vendor-welcome-content.info .content-wrapper .body .form-group input[type=number]:focus,
#guest-vendor-welcome-content.info .content-wrapper .body .form-group input[type=password]:focus {
  border: 1px solid #8d989e;
}

#guest-vendor-welcome-content.info .content-wrapper .body .form-group .select2-selection--multiple {
  border: 1px solid #e1e3e3 !important;
}

#guest-vendor-welcome-content.info .content-wrapper .body .form-group .select2-search__field {
  padding: 7px 20px;
  margin-top: 0;
}

#guest-vendor-welcome-content.info .content-wrapper .body .form-group .offices .offices-container .office-row {
  margin-bottom: 10px;
}

#guest-vendor-welcome-content.info .content-wrapper .body .form-group .offices .add_office {
  background: #FFFFFF;
  border: 1px dashed #02cd8d;
  color: #02cd8d;
  font-size: 16px;
  border-radius: 4px;
  padding: 8px 15px;
  margin-top: 15px;
  margin-bottom: 15px;
  cursor: pointer;
}

#guest-vendor-welcome-content.info .content-wrapper .body .form-group .offices .add_office:hover {
  background-color: #f8f8f8;
}

#guest-vendor-welcome-content.info .content-wrapper .body .form-group .SumoSelect > .CaptionCont {
  margin-top: 1px;
  height: 35px;
}

#guest-vendor-welcome-content.info .content-wrapper .body .action-group .back {
  color: #02cd8d;
  float: left;
  display: block;
  padding: 10px 10px;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  text-decoration: none;
  outline: 0;
  border: 0;
  cursor: pointer;
}

#guest-vendor-welcome-content.info .content-wrapper .body .action-group .back .fa {
  display: inline-block;
  padding-right: 10px;
  font-weight: bold;
}

#guest-vendor-welcome-content.service-details .content-wrapper {
  background: #F0F4F7;
}

#guest-vendor-welcome-content.service-details .content-wrapper .heading {
  padding: 70px 0;
  text-align: left;
  color: #313232;
  font-family: "Roboto", sans-serif;
  line-height: 1.5;
}

#guest-vendor-welcome-content.service-details .content-wrapper .heading .inner {
  width: 740px;
  margin: 0 auto;
  text-align: center;
}

#guest-vendor-welcome-content.service-details .content-wrapper .heading h1 {
  font-size: 30px;
  color: #313232;
  margin: 0 0 30px;
  line-height: 1;
  font-weight: bolder;
  text-align: center;
}

#guest-vendor-welcome-content.service-details .content-wrapper .heading h1:before {
  content: '';
  display: block;
  margin: 0 auto 30px;
  width: 100px;
  height: 100px;
  background: url("../../images/root/all.png") no-repeat -410px -719px;
}

#guest-vendor-welcome-content.service-details .content-wrapper .body {
  padding: 0 15px 70px;
  width: 960px;
  margin: 0 auto;
}

#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group {
  color: #333333;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group:after {
  content: "";
  display: table;
  clear: both;
}

#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group > label {
  color: #333333;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}

#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group label.addMore {
  color: #02cd8d;
  line-height: 20px;
  font-size: 14px !important;
  margin-top: 12px !important;
}

#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group label.addMore i {
  display: inline-block;
  margin-right: 5px;
  font-style: normal;
}

#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group .checkboxes input[type=checkbox] {
  visibility: hidden;
  opacity: 0;
}

#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group .checkboxes label {
  display: block;
  margin: 0 0 10px;
  font-size: 12px;
  cursor: pointer;
}

#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group .checkboxes label span {
  display: inline-block;
  vertical-align: middle;
}

#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group .checkboxes label span.checkbox {
  border: 1px solid #d4f0e3;
  background-color: #d8f1e6;
  border-radius: 2px;
  margin: 0 10px 0 0;
  width: 20px;
  height: 20px;
  position: relative;
  transition: background-color .3s;
}

#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group .checkboxes label span.checkbox input {
  visibility: hidden;
  opacity: 0;
}

#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group .checkboxes label.checked span.checkbox {
  background-color: #FFFFFF;
  border: 1px solid #02cd8d;
}

#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group .checkboxes label.checked span.checkbox:before {
  top: 50%;
  left: 50%;
  content: '';
  position: absolute;
  height: 6px;
  width: 15px;
  border-left: 2px solid #02cd8d;
  border-bottom: 2px solid #02cd8d;
  display: block;
  margin: 0 auto;
  transform: translate(-50%, -55%) rotate(-45deg);
  -webkit-transform: translate(-50%, -55%) rotate(-45deg);
  -moz-transform: translate(-50%, -55%) rotate(-45deg);
  -ms-transform: translate(-50%, -55%) rotate(-45deg);
  -o-transform: translate(-50%, -55%) rotate(-45deg);
}

#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group .checkboxes label:hover:not(.checked) span.checkbox {
  background-color: #d4f0e3;
}

#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group .checkboxes label:hover.checked span.checkbox {
  background-color: #d8f1e6;
}

#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group .checkboxes label:hover.checked span.checkbox:before {
  border-left: 2px solid #02cd8d;
  border-bottom: 2px solid #02cd8d;
}

#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group .checkboxes input[type=text] {
  display: inline-block;
  width: auto;
}

#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group .checkboxes .checkbox-group {
  margin-bottom: 10px;
}

#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group .checkboxes .checkbox-group .checkbox-group {
  padding-left: 35px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s .5s, opacity .5s linear;
  height: 0;
  overflow: hidden;
  margin-bottom: 0;
}

#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group .checkboxes .checkbox-group .checkbox-group.active {
  margin-bottom: 10px;
  overflow: visible;
  height: auto;
  visibility: visible;
  opacity: 1;
  transition: opacity .5s linear;
}

#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group .checkboxes .checkbox-group label {
  margin-bottom: 0;
}

#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group .checkboxes .checkbox-group .extend {
  display: inline-block;
  margin-left: 10px;
  color: #02cd8d;
  font-size: 24px;
  font-weight: bold;
}

#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group .radios input[type=radio] {
  visibility: hidden;
  opacity: 0;
}

#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group .radios label {
  display: block;
  margin: 0;
  font-size: 12px;
  cursor: pointer;
}

#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group .radios label:not(:first-child) {
  margin-top: 10px;
}

#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group .radios label span {
  display: inline-block;
  vertical-align: middle;
}

#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group .radios label span.radio {
  border: 2px solid #d4f0e3;
  background-color: #d8f1e6;
  border-radius: 50%;
  margin: 0 10px 0 0;
  width: 20px;
  height: 20px;
  position: relative;
  transition: background-color .3s;
}

#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group .radios label span.radio input {
  visibility: hidden;
  opacity: 0;
}

#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group .radios label.checked span.radio {
  background-color: #02cd8d;
}

#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group .radios input[type=text] {
  display: inline-block;
  width: auto;
}

#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group textarea {
  width: 100%;
  outline: none;
  font-size: 12px;
  padding: 15px;
  border: 1px solid #e1e3e3;
  border-radius: 4px;
}

#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group .label-file label {
  font-size: 14px;
  padding: 5px 15px 11px;
  margin-right: 20px;
  position: relative;
  border-radius: 3px;
  background-color: #02cd8d;
  cursor: pointer;
  overflow: hidden;
}

#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group .label-file label:after {
  position: absolute;
  bottom: 0;
  height: 6px;
  left: 0;
  right: 0;
  background-color: #0d513b;
  transition: height .3s;
  content: '';
  display: block;
  z-index: 1;
}

#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group .label-file label span {
  position: relative;
  z-index: 2;
  color: #FFFFFF;
}

#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group .label-file label:hover:after {
  height: 100%;
}

#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group .label-file input[type=file] {
  display: none;
}

#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group .label-file input[type=text] {
  border: none;
  padding: 0;
}

#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group input[type=text],
#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group input[type=email],
#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group select,
#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group input[type=number],
#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group input[type=password] {
  outline: 0;
  padding: 7px 15px;
  border: 1px solid #e1e3e3;
  border-radius: 2px;
  display: block;
  width: 100%;
  transition: border .3s;
}

#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group input[type=text]:focus,
#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group input[type=email]:focus,
#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group select:focus,
#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group input[type=number]:focus,
#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group input[type=password]:focus {
  border: 1px solid #8d989e;
}

#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group .select2-selection--multiple {
  border: 1px solid #e1e3e3 !important;
}

#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group .select2-search__field {
  padding: 7px 20px;
  margin-top: 0;
}

#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group .industries .industries-container .industry-column {
  margin-bottom: 10px;
}

#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group .industries .add_industry {
  background: transparent;
  border: 1px dashed #02cd8d;
  color: #02cd8d;
  font-size: 16px;
  border-radius: 4px;
  padding: 8px 15px;
  margin-top: 15px;
  margin-bottom: 15px;
  cursor: pointer;
}

#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group .industries .add_industry:hover {
  background-color: #f8f8f8;
}

#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group.form-target_market {
  padding-bottom: 30px;
}

#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group.form-target_market .irs-grid .js-grid-text-0 {
  margin-left: -10px !important;
  text-align: left !important;
}

#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group.form-target_market .irs-grid .js-grid-text-1 {
  margin-right: -10px !important;
  text-align: right !important;
}

#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group.form-minimum_project_value {
  padding-bottom: 30px;
}

#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group.form-minimum_project_value .irs-min,
#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group.form-minimum_project_value .irs-max {
  top: 60px;
  visibility: visible !important;
  position: absolute;
  background: transparent;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  letter-spacing: 1px;
  color: #585858;
  bottom: initial;
  margin-top: -10px;
  line-height: 1.3;
  font-weight: bold;
  color: #000000;
}

#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group.form-minimum_project_value .irs-bar,
#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group.form-minimum_project_value .irs-bar-edge {
  background-color: transparent !important;
}

#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group.form-minimum_project_value .irs-slider {
  top: 21px;
  border: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #02cd8d;
}

#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group.form-minimum_project_value .irs-slider:before {
  display: none;
}

#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group.form-minimum_project_value .irs-slider:after {
  display: none;
}

#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group.form-minimum_project_value .irs-single {
  background-color: #02cd8d;
  font-size: 12px;
  padding: 3px 5px;
  top: -5px;
}

#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group.form-minimum_project_value .irs-single:after {
  display: none !important;
}

#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group.form-positioning label {
  margin-bottom: 0;
}

#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group.form-positioning .slider-row {
  padding-top: 15px;
}

#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group.form-positioning .slider-row:first-child {
  padding-top: 0;
}

#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group.form-positioning .irs-single {
  visibility: hidden !important;
}

#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group.form-positioning .irs-min,
#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group.form-positioning .irs-max {
  font-weight: bold;
  color: #000000;
  bottom: 21px;
  position: absolute;
  background: transparent;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  letter-spacing: 1px;
  top: initial;
  margin-top: -10px;
  line-height: 1.3;
  visibility: visible !important;
}

#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group.form-positioning .irs-bar,
#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group.form-positioning .irs-bar-edge {
  background-color: transparent !important;
}

#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group.form-positioning .irs-slider {
  top: 21px;
  border: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #02cd8d;
}

#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group.form-positioning .irs-slider:before {
  display: none;
}

#guest-vendor-welcome-content.service-details .content-wrapper .body .form-group.form-positioning .irs-slider:after {
  display: none;
}

#guest-vendor-welcome-content.service-details .content-wrapper .body .action-group .back {
  color: #02cd8d;
  float: left;
  display: block;
  padding: 10px 10px;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  text-decoration: none;
  outline: 0;
  border: 0;
  cursor: pointer;
}

#guest-vendor-welcome-content.service-details .content-wrapper .body .action-group .back .fa {
  display: inline-block;
  padding-right: 10px;
  font-weight: bold;
}

@media only screen and (max-width: 991px) {
  #guest-vendor-welcome-content.language .content-wrapper .heading .inner {
    width: 100%;
  }
  #guest-vendor-welcome-content.language .content-wrapper .body .button-group {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  #guest-vendor-welcome-content.language .content-wrapper .heading {
    font-size: 15px;
  }
  #guest-vendor-welcome-content.language .content-wrapper .heading h1 {
    font-size: 24px;
  }
  #guest-vendor-welcome-content.language .content-wrapper .body .button-group {
    padding-left: 15px;
    padding-right: 15px;
  }
  #guest-vendor-welcome-content.business .content-wrapper .heading .inner {
    width: 100%;
  }
  #guest-vendor-welcome-content.business .content-wrapper .heading h1 {
    font-size: 24px;
  }
  #guest-vendor-welcome-content.business .content-wrapper .body {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
  #guest-vendor-welcome-content.business .content-wrapper .body .action-group .btn-type-1 {
    padding: 9px 20px;
    font-size: 12px;
  }
  #guest-vendor-welcome-content.business .content-wrapper .body .action-group .back {
    font-size: 12px;
    padding: 10px 0;
  }
  #guest-vendor-welcome-content.service .content-wrapper .heading .inner {
    width: 100%;
  }
  #guest-vendor-welcome-content.service .content-wrapper .heading h1 {
    font-size: 24px;
  }
  #guest-vendor-welcome-content.service .content-wrapper .body {
    width: 100%;
  }
  #guest-vendor-welcome-content.service .content-wrapper .body .service-group .box-selected {
    margin-bottom: 30px;
  }
  #guest-vendor-welcome-content.service .content-wrapper .body .service-group .box-selected .box-heading {
    font-size: 14px;
  }
  #guest-vendor-welcome-content.service .content-wrapper .body .service-group .box-selected .box-body .col .item {
    font-size: 12px;
  }
  #guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option .box-service {
    width: 25%;
    padding-left: 5px;
    padding-right: 5px;
  }
  #guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option .box-service .box-header .back {
    float: left;
    padding: 10px;
  }
  #guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option .box-service .box-header .back .fa {
    padding-right: 5px;
  }
  #guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option .box-service .box-header h5 {
    font-size: 10px;
  }
  #guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option .box-service .box-body .col .item {
    font-size: 12px;
  }
  #guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option .box-service.active .box-header h5 {
    padding-right: 40px;
  }
  #guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option .box-all-service {
    display: none;
    width: 100%;
    float: left;
    padding-left: 10px;
    padding-right: 10px;
  }
  #guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option .box-all-service .box-header {
    transition: border .3s;
    border: 1px solid #eaeaea;
    background-color: #FFFFFF;
    text-align: left;
    padding: 30px;
  }
  #guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option .box-all-service .box-header h5 {
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    line-height: 1.2;
    float: left;
  }
  #guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option .box-all-service .box-header .back {
    display: block;
    float: right;
    padding: 10px 15px;
    border: 1px solid #585858;
    color: #585858;
    font-family: "Roboto", sans-serif;
    cursor: pointer;
  }
  #guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option .box-all-service .box-header .back .fa {
    display: inline-block;
    padding-right: 15px;
  }
  #guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option .box-all-service .box-header .back:hover {
    text-decoration: none;
  }
  #guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option .box-all-service .box-body {
    border-left: 1px solid #eaeaea;
    border-right: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea;
    background-color: #FFFFFF;
  }
  #guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option .box-all-service .box-body .col {
    float: left;
    width: 50%;
    padding: 10px;
  }
  #guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option .box-all-service .box-body .col .item {
    padding: 10px 15px;
    text-align: left;
    color: #585858;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    cursor: pointer;
  }
  #guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option .box-all-service .box-body .col .item .add {
    display: none;
    float: right;
  }
  #guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option .box-all-service .box-body .col .item .tick {
    display: none;
    float: right;
  }
  #guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option .box-all-service .box-body .col .item:not(.selected):hover {
    color: #FFFFFF;
    background-color: #373f48;
  }
  #guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option .box-all-service .box-body .col .item:not(.selected):hover .add {
    display: block;
    color: #FFFFFF;
  }
  #guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option .box-all-service .box-body .col .item.selected {
    background-color: #c7ffe4;
  }
  #guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option .box-all-service .box-body .col .item.selected .tick {
    display: block;
    color: #FFFFFF;
  }
  #guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option .box-all-service .box-body .col .item:after {
    content: '';
    display: table;
    clear: both;
  }
  #guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option .box-all-service.active {
    display: block;
  }
  #guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option.extended .box-service {
    display: none;
  }
  #guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option.extended .box-service.active {
    display: block;
    width: 100%;
  }
  #guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option.extended .box-service.active .box-header {
    padding: 20px 30px;
  }
  #guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option.extended .box-service.active .box-header .image-wrapper {
    display: none;
  }
  #guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option.extended .box-service.active .box-header h5 {
    float: left;
    text-align: left;
    font-size: 18px;
    font-family: "Roboto", sans-serif;
    line-height: 40px;
  }
  #guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option.extended .box-service.active .box-header .back {
    display: block;
  }
  #guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option.extended .box-service.active .box-body {
    display: block;
  }
  #guest-vendor-welcome-content.service .content-wrapper .body .service-group .row-option.extended .box-service.active:hover .box-header {
    border: 1px solid #eaeaea;
  }
  #guest-vendor-welcome-content.service .content-wrapper .body .action-group .back {
    padding: 10px 0;
    font-size: 12px;
  }
  #guest-vendor-welcome-content.service .content-wrapper .body .action-group .btn-type-1 {
    padding: 9px 20px;
    font-size: 12px;
  }
  #guest-vendor-welcome-content.info .content-wrapper .heading .inner {
    width: 100%;
  }
  #guest-vendor-welcome-content.info .content-wrapper .heading h1 {
    font-size: 24px;
  }
  #guest-vendor-welcome-content.info .content-wrapper .body {
    width: 100%;
  }
  #guest-vendor-welcome-content.info .content-wrapper .body .form-group .offices .add_office {
    font-size: 12px;
  }
  #guest-vendor-welcome-content.info .content-wrapper .body .action-group .back {
    font-size: 12px;
  }
  #guest-vendor-welcome-content.info .content-wrapper .body .action-group .btn-type-1 {
    font-size: 12px;
  }
  #guest-vendor-welcome-content.service-details .content-wrapper .heading .inner {
    width: 100%;
  }
  #guest-vendor-welcome-content.service-details .content-wrapper .heading h1 {
    font-size: 24px;
  }
  #guest-vendor-welcome-content.service-details .content-wrapper .body {
    width: 100%;
  }
  #guest-vendor-welcome-content.service-details .content-wrapper .body .form-group.form-target_market {
    padding-bottom: 30px;
  }
  #guest-vendor-welcome-content.service-details .content-wrapper .body .form-group.form-target_market .irs-grid .irs-grid-text {
    font-size: 10px;
  }
  #guest-vendor-welcome-content.service-details .content-wrapper .body .form-group.form-target_market .irs-grid .js-grid-text-1 {
    display: none;
  }
  #guest-vendor-welcome-content.service-details .content-wrapper .body .form-group.form-target_market .irs-grid .js-grid-text-2 {
    left: 87% !important;
  }
  #guest-vendor-welcome-content.service-details .content-wrapper .body .form-group.form-minimum_project_value .irs {
    margin-top: 5px;
  }
  #guest-vendor-welcome-content.service-details .content-wrapper .body .form-group .industries .add_industry {
    font-size: 12px;
  }
  #guest-vendor-welcome-content.service-details .content-wrapper .body .action-group .back {
    font-size: 12px;
  }
  #guest-vendor-welcome-content.service-details .content-wrapper .body .action-group .btn-type-1 {
    font-size: 12px;
  }
}

.vendor-verify-progress {
  margin-top: 30px;
  margin-bottom: 30px;
  background-color: #cffff0;
  text-align: center;
  padding: 30px;
}

.vendor-verify-progress h3 {
  color: #585858;
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
}

.vendor-verify-progress h5 {
  color: #585858;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 20px;
}

.vendor-verify-progress .progresses {
  position: relative;
  width: 480px;
  margin: 0 auto;
}

.vendor-verify-progress .progresses [class^=progress] {
  float: left;
  width: 33.33%;
  padding-top: 120px;
  position: relative;
}

.vendor-verify-progress .progresses [class^=progress] span {
  position: absolute;
  bottom: 30px;
  white-space: nowrap;
}

.vendor-verify-progress .progresses [class^=progress] .number {
  position: absolute;
  left: 0;
  top: 45px;
  z-index: 1;
}

.vendor-verify-progress .progresses [class^=progress] .number:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  color: #fff;
  transform: translate(-50%, -50%);
  background-color: #373f48;
  border-radius: 50%;
  line-height: 30px;
  height: 30px;
  width: 30px;
  text-align: center;
  z-index: 2;
}

.vendor-verify-progress .progresses .progress-1 span {
  left: 0;
  transform: translate(-50%, 0);
}

.vendor-verify-progress .progresses .progress-1 .number:after {
  content: '1';
  display: block;
}

.vendor-verify-progress .progresses .progress-2 span {
  left: 50%;
  transform: translate(-50%, 0);
}

.vendor-verify-progress .progresses .progress-2 .number {
  left: 50%;
}

.vendor-verify-progress .progresses .progress-2 .number:after {
  content: '2';
  display: block;
}

.vendor-verify-progress .progresses .progress-3 span {
  left: 100%;
  transform: translate(-50%, 0);
}

.vendor-verify-progress .progresses .progress-3 .number {
  left: 100%;
}

.vendor-verify-progress .progresses .progress-3 .number:after {
  content: '3';
  display: block;
}

.vendor-verify-progress .progresses:before {
  content: '';
  display: block;
  position: absolute;
  top: 45px;
  left: 0;
  transform: translate(0, -50%);
  height: 8px;
  width: 100%;
  background-color: #373f48;
}

.vendor-verify-progress .progresses.current-1 .progress-1 span {
  font-weight: bold;
}

.vendor-verify-progress .progresses.current-1 .progress-1:after {
  background-color: #09cb8e;
}

.vendor-verify-progress .progresses.current-1 .progress-1 .number:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 38px;
  height: 38px;
  border: 1px solid #09cb8e;
  background-color: #fff;
  display: block;
  z-index: 1;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.vendor-verify-progress .progresses.current-2 .progress-1 .number:after {
  content: '';
  background-color: #09cb8e;
}

.vendor-verify-progress .progresses.current-2 .progress-1 .number:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 12px;
  height: 6px;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  background-color: transparent;
  display: block;
  z-index: 3;
  transform: translate(-50%, -50%) rotate(-45deg);
}

.vendor-verify-progress .progresses.current-2 .progress-2 span {
  font-weight: bold;
}

.vendor-verify-progress .progresses.current-2 .progress-2:before {
  content: '';
  display: block;
  position: absolute;
  top: 45px;
  right: 0;
  transform: translate(-33.33%, -50%);
  height: 8px;
  width: 150%;
  background-color: #09cb8e;
}

.vendor-verify-progress .progresses.current-2 .progress-2 .number:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 38px;
  height: 38px;
  border: 1px solid #09cb8e;
  background-color: #fff;
  display: block;
  z-index: 1;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.vendor-verify-progress .progresses.current-3 .progress-1 .number:after, .vendor-verify-progress .progresses.current-3 .progress-2 .number:after {
  content: '';
  background-color: #09cb8e;
}

.vendor-verify-progress .progresses.current-3 .progress-1 .number:before, .vendor-verify-progress .progresses.current-3 .progress-2 .number:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 12px;
  height: 6px;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  background-color: transparent;
  display: block;
  z-index: 3;
  transform: translate(-50%, -50%) rotate(-45deg);
}

.vendor-verify-progress .progresses.current-3 .progress-3 span {
  font-weight: bold;
}

.vendor-verify-progress .progresses.current-3 .progress-3:before {
  content: '';
  display: block;
  position: absolute;
  top: 45px;
  right: 0;
  transform: translate(0, -50%);
  height: 8px;
  width: 300%;
  background-color: #09cb8e;
}

.vendor-verify-progress .progresses.current-3 .progress-3 .number:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 38px;
  height: 38px;
  border: 1px solid #09cb8e;
  background-color: #fff;
  display: block;
  z-index: 1;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.vendor-verify-progress .description {
  font-weight: bold;
}

.vendor-verify-progress .button {
  padding-top: 20px;
  width: 200px;
  margin: 0 auto;
}

.vendor-verify-progress .button .btn-effect-1 {
  font-size: 12px;
}

#vendor-job-index-content {
  padding: 20px 0;
  background-color: #f0f4f7;
}

#vendor-job-index-content .title {
  font-weight: bold;
  font-size: 16px;
  color: #000000;
  margin-bottom: 25px;
}

#vendor-job-index-content .title .green {
  color: #02cd8d;
}

#vendor-job-index-content .links {
  background-color: #FFFFFF;
  padding: 0 15px;
  border: 1px solid #d6d6d6;
}

#vendor-job-index-content .links a {
  float: left;
  display: block;
  padding: 20px 5px;
  margin: 0 15px;
  color: #5e6c7e;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  border-bottom: 3px solid transparent;
  cursor: pointer;
}

#vendor-job-index-content .links a.with-dot {
  padding-right: 35px;
  position: relative;
}

#vendor-job-index-content .links a:hover {
  text-decoration: none;
}

#vendor-job-index-content .links a.active {
  font-weight: bold;
  color: #000000;
  border-bottom: 3px solid #02cd8d;
}

#vendor-job-index-content .links a .notification-dot {
  position: absolute;
  right: 0;
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-left: 5px;
  text-align: center;
  line-height: 25px;
  font-size: 12px;
  color: #fff;
  background-color: #02cd8d;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
}

#vendor-job-index-content .notification-phone {
  margin-top: 20px;
  border-radius: 5px;
  background-color: #02cd8d;
  color: #FFFFFF;
}

#vendor-job-index-content .notification-phone [class^=col] {
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
}

#vendor-job-index-content .notification-phone .phone-icon:before {
  background: url("../../images/root/all.png") no-repeat -429px -628px;
  width: 68px;
  height: 68px;
  display: block;
  content: '';
  margin: 0 auto;
}

#vendor-job-index-content .notification-phone h5 {
  margin-bottom: 7px;
}

#vendor-job-index-content .notification-phone a {
  background: none;
  display: block;
  padding: 10px 15px;
  width: 150px;
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #FFFFFF;
  border-radius: 3px;
  color: #FFFFFF;
  cursor: pointer;
}

#vendor-job-index-content .notification-phone a:hover {
  text-decoration: none;
}

#vendor-job-index-content .jobs-wrapper .job-heading {
  padding: 5px 0;
}

#vendor-job-index-content .jobs-wrapper .job-heading:after {
  clear: both;
  display: block;
}

#vendor-job-index-content .jobs-wrapper .job-heading .sortBy {
  float: right;
  padding: 15px 0;
  color: #999999;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
}

#vendor-job-index-content .jobs-wrapper .job-heading .sortBy:after {
  clear: both;
  display: block;
}

#vendor-job-index-content .jobs-wrapper .job-heading .sortBy .selection {
  padding: 0 10px;
  display: block;
  float: right;
}

#vendor-job-index-content .jobs-wrapper .job-data {
  background-color: #FFFFFF;
  border: 1px solid #dcdcdc;
  transition: border .3s;
  margin-bottom: 20px;
  position: relative;
  display: table;
  width: 100%;
}

#vendor-job-index-content .jobs-wrapper .job-data .job-row {
  display: table-row;
}

#vendor-job-index-content .jobs-wrapper .job-data .job-row > [class^=job] {
  display: table-cell;
  vertical-align: top;
  padding: 20px;
}

#vendor-job-index-content .jobs-wrapper .job-data .job-row .job-information {
  position: relative;
}

#vendor-job-index-content .jobs-wrapper .job-data .job-row .job-information .job-owner {
  color: #56646d;
  font-size: 15px;
  margin-bottom: 5px;
}

#vendor-job-index-content .jobs-wrapper .job-data .job-row .job-information .job-owner .new {
  display: inline-block;
  color: #02cd8d;
}

#vendor-job-index-content .jobs-wrapper .job-data .job-row .job-information .job-owner .new:before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  background: #02cd8d;
  border-radius: 50%;
  font-size: 12px;
  width: 13px;
  height: 13px;
  margin-right: 10px;
  margin-left: 20px;
  position: relative;
  top: -2px;
}

#vendor-job-index-content .jobs-wrapper .job-data .job-row .job-information .job-title {
  width: 65%;
  color: #111111;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 1.3;
  font-weight: bold;
  margin-bottom: 5px;
}

#vendor-job-index-content .jobs-wrapper .job-data .job-row .job-information .job-title .news {
  margin-left: 0;
  margin-right: 0;
  display: inline-block;
}

#vendor-job-index-content .jobs-wrapper .job-data .job-row .job-information .job-title .news > div {
  float: left;
  padding-left: 12px;
  padding-right: 12px;
  color: #02cd8d;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  line-height: 1;
  vertical-align: middle;
}

#vendor-job-index-content .jobs-wrapper .job-data .job-row .job-information .job-title .news > div a {
  color: #02cd8d;
  text-decoration: none;
}

#vendor-job-index-content .jobs-wrapper .job-data .job-row .job-information .job-title .news > div a:hover {
  text-decoration: none;
}

#vendor-job-index-content .jobs-wrapper .job-data .job-row .job-information .job-title .news > div:before {
  content: '';
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: #02cd8d;
  border-radius: 50%;
  margin-right: 8px;
}

#vendor-job-index-content .jobs-wrapper .job-data .job-row .job-information .job-short-description {
  color: #3f92f1;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 1.3;
  width: 400px;
}

#vendor-job-index-content .jobs-wrapper .job-data .job-row .job-information .job-points {
  padding: 5px 0 10px;
}

#vendor-job-index-content .jobs-wrapper .job-data .job-row .job-information .job-points .job-point {
  display: inline-block;
  padding: 0 15px 0 30px;
  margin-top: 5px;
  position: relative;
  color: #5e6c74;
  font-family: "Roboto", sans-serif;
}

#vendor-job-index-content .jobs-wrapper .job-data .job-row .job-information .job-points .job-point:before {
  content: '';
  display: block;
  background: url("../../images/root/all.png") no-repeat;
  width: 20px;
  height: 20px;
  position: absolute;
  left: 0;
  top: 0;
}

#vendor-job-index-content .jobs-wrapper .job-data .job-row .job-information .job-points .job-point.time:before {
  background-position: -333px -655px;
}

#vendor-job-index-content .jobs-wrapper .job-data .job-row .job-information .job-points .job-point.location:before {
  background-position: -208px -659px;
}

#vendor-job-index-content .jobs-wrapper .job-data .job-row .job-information .job-points .job-point.budget:before {
  background-position: -91px -657px;
}

#vendor-job-index-content .jobs-wrapper .job-data .job-row .job-information .job-services {
  padding-top: 25px;
  margin-left: -10px;
}

#vendor-job-index-content .jobs-wrapper .job-data .job-row .job-information .job-services .service {
  margin-left: 10px;
  display: inline-block;
  background-color: #dcdcdc;
  border-radius: 3px;
  padding: 10px 15px;
  color: #252525;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  margin-top: 5px;
}

#vendor-job-index-content .jobs-wrapper .job-data .job-row .job-information .job-quote-type {
  font-size: 15px;
  position: absolute;
  color: #5e6c74;
  font-family: "Roboto", sans-serif;
  right: 20px;
  top: 20px;
}

#vendor-job-index-content .jobs-wrapper .job-data .job-row .job-information .job-quote-type.has-job-quote-type {
  top: 40px;
}

#vendor-job-index-content .jobs-wrapper .job-data .job-row .job-information .job-quote-type .green {
  color: #02cd8d;
}

#vendor-job-index-content .jobs-wrapper .job-data .job-row .job-applicants {
  vertical-align: middle;
  color: #586871;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
}

#vendor-job-index-content .jobs-wrapper .job-data .job-row .job-applicants span {
  color: #02cd8d;
  display: inline-block;
  padding-right: 6px;
}

#vendor-job-index-content .jobs-wrapper .job-data .job-row .job-applicants .quote-price {
  color: #333;
}

#vendor-job-index-content .jobs-wrapper .job-data .job-row .job-applicants .quote-price .green {
  color: #02cd8d;
  font-weight: bold;
}

#vendor-job-index-content .jobs-wrapper .job-data .job-row .job-action {
  vertical-align: middle;
  width: 1px;
}

#vendor-job-index-content .jobs-wrapper .job-data .job-row .job-action .matched {
  color: #28ac78;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
}

#vendor-job-index-content .jobs-wrapper .job-data .job-row .job-action .matched .value {
  background-color: #28ac78;
  border-radius: 3px;
  margin-right: 3px;
  display: inline-block;
  color: #92d5ba;
  padding: 3px 6px;
  margin-bottom: 6px;
}

#vendor-job-index-content .jobs-wrapper .job-data .job-row .job-action .matched .value span {
  color: #FFFFFF;
}

#vendor-job-index-content .jobs-wrapper .job-data .job-row .job-action .btn-action {
  display: block;
  background-color: #02cd8d;
  position: relative;
  padding: 10px 25px 13px;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  color: #ffffe7;
  cursor: pointer;
  white-space: nowrap;
  text-decoration: none;
}

#vendor-job-index-content .jobs-wrapper .job-data .job-row .job-action .btn-action span {
  position: relative;
  z-index: 2;
}

#vendor-job-index-content .jobs-wrapper .job-data .job-row .job-action .btn-action:after {
  transition: height .3s;
  height: 5px;
  width: 100%;
  position: absolute;
  bottom: 0;
  background-color: #28ac78;
  left: 0;
  content: '';
  z-index: 1;
}

#vendor-job-index-content .jobs-wrapper .job-data .job-row .job-action .btn-action:hover:after {
  height: 100%;
}

#vendor-job-index-content .tab-coordinator {
  border: 1px solid #000;
  border-radius: 4px;
}

#vendor-job-index-content .tab-coordinator .tab-title {
  padding: 10px;
  font-size: 16px;
}

#vendor-job-index-content .tab-coordinator .info-row {
  padding: 0 10px 10px;
}

#vendor-job-index-content .tab-coordinator .info-row img {
  display: inline-block;
  vertical-align: top;
}

#vendor-job-index-content .tab-coordinator .info-row .info-right {
  display: inline-block;
  padding-left: 10px;
  width: 65%;
}

#vendor-job-index-content .tab-coordinator .info-row .info-right .info {
  color: #000;
}

#vendor-job-index-content .tab-coordinator .info-row .info-right .info.info-name {
  font-size: 16px;
  font-weight: bold;
}

#vendor-job-index-content .tab-coordinator .info-row .info-right .info.info-tel {
  font-style: italic;
}

#vendor-job-index-content .tab-coordinator .tab-footer {
  border-top: 1px solid #000;
  text-align: center;
  position: relative;
}

#vendor-job-index-content .tab-coordinator .tab-footer img {
  position: absolute;
  left: 10%;
  top: 25%;
}

#vendor-job-index-content .tab-coordinator .tab-footer a {
  display: block;
  color: #02cd8d;
  padding: 10px;
  font-size: 16px;
  text-decoration: none;
}

#vendor-job-index-content .tab-guide {
  border: 1px solid #000000;
  border-radius: 3px;
  padding: 10px 15px;
  margin-top: 10px;
}

#vendor-job-index-content .tab-guide .legend {
  color: #000;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0;
}

#vendor-job-index-content .tab-guide ul.checks {
  list-style: none;
  padding: 0;
  margin: 10px 0 25px 0;
}

#vendor-job-index-content .tab-guide ul.checks li {
  padding-left: 35px;
  position: relative;
  color: #000;
  font-size: 14px;
  font-weight: bold;
}

#vendor-job-index-content .tab-guide ul.checks li:before {
  content: '';
  display: block;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 2px;
  background-color: #dddddd;
}

#vendor-job-index-content .tab-guide ul.checks li:not(:first-child) {
  margin-top: 10px;
}

#vendor-job-index-content .tab-guide ul.linker {
  list-style: none;
  margiN: 10px 0 0 0;
  padding: 0;
}

#vendor-job-index-content .tab-guide ul.linker li a {
  display: block;
  margin-bottom: 3px;
  color: #02cd8d;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
}

@media only screen and (max-width: 991px) {
  #vendor-job-index-content .jobs-wrapper .job-data .job-row .job-information .job-title {
    width: 100%;
  }
  #vendor-job-index-content .jobs-wrapper .job-data .job-row .job-information .job-services .service {
    margin-bottom: 5px;
  }
  #vendor-job-index-content .jobs-wrapper .job-data .job-row .job-information .job-quote-type {
    right: -180px;
  }
  #vendor-job-index-content .jobs-wrapper .job-data .job-row .job-information .job-short-description {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  #vendor-job-index-content {
    padding: 20px 0 0;
  }
  #vendor-job-index-content .col-md-9 {
    padding-left: 10px;
    padding-right: 10px;
  }
  #vendor-job-index-content .jobs-wrapper .job-heading {
    padding: 5px 0;
  }
  #vendor-job-index-content .jobs-wrapper .job-heading:after {
    clear: both;
    display: block;
  }
  #vendor-job-index-content .jobs-wrapper .job-heading .sortBy {
    float: right;
    padding: 15px 0;
    color: #999999;
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    font-weight: normal;
  }
  #vendor-job-index-content .jobs-wrapper .job-heading .sortBy:after {
    clear: both;
    display: block;
  }
  #vendor-job-index-content .jobs-wrapper .job-heading .sortBy .selection {
    padding: 0 10px;
    display: block;
    float: right;
  }
  #vendor-job-index-content .jobs-wrapper .job-data {
    margin-bottom: 0;
  }
  #vendor-job-index-content .jobs-wrapper .job-data .job-row {
    display: table-row;
  }
  #vendor-job-index-content .jobs-wrapper .job-data .job-row > [class^=job] {
    display: block;
    padding: 10px 20px 20px;
  }
  #vendor-job-index-content .jobs-wrapper .job-data .job-row .job-information .job-title .news > div {
    padding-left: 0px;
    padding-top: 5px;
  }
  #vendor-job-index-content .jobs-wrapper .job-data .job-row .job-information .job-points {
    padding: 5px 0 0;
  }
  #vendor-job-index-content .jobs-wrapper .job-data .job-row .job-information .job-points .job-point {
    padding: 0 15px 10px 30px;
  }
  #vendor-job-index-content .jobs-wrapper .job-data .job-row .job-information .job-services .service {
    margin-bottom: 10px;
    padding: 10px 10px;
  }
  #vendor-job-index-content .jobs-wrapper .job-data .job-row .job-information .job-quote-type {
    right: 0;
    top: 0;
    position: inherit;
    text-align: left;
    font-size: 14px;
  }
  #vendor-job-index-content .jobs-wrapper .job-data .job-row .job-information .job-quote-type.has-job-quote-type {
    top: 0;
  }
  #vendor-job-index-content .jobs-wrapper .job-data .job-row .job-action {
    width: 100%;
  }
  #vendor-job-index-content .jobs-wrapper .job-data .job-row .job-action .btn-action {
    text-align: center;
  }
  #vendor-job-index-content .jobs-wrapper .job-data .job-row .job-information .job-short-description {
    width: 100%;
    word-break: break-all;
    -ms-word-break: break-all;
    word-break: break-all;
    /* Non standard for WebKit */
    word-break: break-word;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
  }
  #vendor-job-index-content .notification-phone {
    width: 100%;
  }
  #vendor-job-index-content .notification-phone .row {
    width: 100%;
  }
  #vendor-job-index-content .notification-phone [class^=col] {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  #vendor-job-index-content .notification-phone .phone-icon {
    padding-top: 20px;
    padding-left: 20px;
  }
}

#vendor-job-show-content {
  background-color: #f7f7f9;
}

#vendor-job-show-content #vendor-job-breadcrumb {
  background-color: #18283e;
  color: #FFFFFF;
  padding: 5px 0;
  text-align: center;
}

#vendor-job-show-content #vendor-job-breadcrumb span {
  font-weight: bold;
}

#vendor-job-show-content #vendor-job-breadcrumb a {
  color: #FFFFFF;
  text-decoration: none;
}

#vendor-job-show-content #vendor-job-header {
  background-color: #e5eef2;
  padding: 60px 0 60px;
  text-align: center;
}

#vendor-job-show-content #vendor-job-header .job-title {
  color: #313840;
  font-size: 20px;
  letter-spacing: 1px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  margin-bottom: 15px;
}

#vendor-job-show-content #vendor-job-header .job-info {
  margin-bottom: 10px;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  color: #384048;
}

#vendor-job-show-content #vendor-job-header .job-info strong {
  font-weight: bold;
  color: #313840;
}

#vendor-job-show-content #vendor-job-header .job-info .green {
  font-weight: bold;
  color: #03cd8d;
}

#vendor-job-show-content .content-quick-link {
  transform: translate(0, -50%);
}

#vendor-job-show-content .content-quick-link .quick-link-bar {
  background-color: #313840;
  border-radius: 3px 3px 0 0;
  padding: 0 17px;
}

#vendor-job-show-content .content-quick-link .quick-link-bar a {
  color: #eaeaea;
  display: inline-block;
  padding: 15px 17px;
  text-decoration: none;
  position: relative;
}

#vendor-job-show-content .content-quick-link .quick-link-bar a .angel-left-icon {
  background: url("../../images/root/all.png") no-repeat -393px -1423px;
  width: 15px;
  height: 15px;
  margin-right: 10px;
  display: inline-block;
}

#vendor-job-show-content .content-quick-link .quick-link-bar a:after {
  content: '';
  display: block;
  height: 3px;
  position: absolute;
  bottom: 0;
  left: 17px;
  right: 17px;
  background-color: transparent;
  transition: background-color .3s;
}

#vendor-job-show-content .content-quick-link .quick-link-bar a.current:after {
  background-color: #02cd8d;
}

#vendor-job-show-content .content-quick-link .quick-link-bar a:not(.current):hover:after {
  background-color: #FFFFFF;
}

#vendor-job-show-content .content-body {
  padding-bottom: 40px;
}

#vendor-job-show-content .content-body .content-body-box {
  border: 1px solid #d4f0e3;
  margin-bottom: 20px;
}

#vendor-job-show-content .content-body .content-body-box .box-white {
  float: left;
  width: 75%;
  background-color: #FFFFFF;
  position: relative;
}

#vendor-job-show-content .content-body .content-body-box .box-white .box-inner {
  padding: 40px;
}

#vendor-job-show-content .content-body .content-body-box .box-transparent {
  float: left;
  width: 25%;
  background-color: #f0f4f7;
  padding: 20px;
  padding-top: 0px;
}

#vendor-job-show-content .content-body .content-body-box .box-transparent .box1 {
  margin-bottom: 20px;
  box-shadow: 1px 4px 1px 0px #adabad;
  margin-left: -6px;
}

#vendor-job-show-content .content-body .content-body-box .box-transparent .box1 .row-1 {
  background-color: #02cd8d;
  font-size: 18px;
  color: white;
  padding: 10px;
}

#vendor-job-show-content .content-body .content-body-box .box-transparent .box1 .row-1 .p {
  font-size: 12px;
  color: black;
  margin-top: 5px;
}

#vendor-job-show-content .content-body .content-body-box .box-transparent .box1 .row-2 {
  background-color: #f5fbfc;
  display: block;
  margin-top: 0px;
  border-top: 1px solid #d4f0e3;
  padding: 10px;
}

#vendor-job-show-content .content-body .content-body-box .box-transparent .box1 .row-2 .heading {
  font-size: 20px;
  font-weight: bolder;
  padding-top: -5px;
  margin-top: auto;
}

#vendor-job-show-content .content-body .content-body-box .box-transparent .box1 .row-2 .heading2 {
  font-weight: bolder;
  font-size: 14px;
  display: inline;
  margin-bottom: 5px;
}

#vendor-job-show-content .content-body .content-body-box .box-transparent .box1 .row-2 .p2 {
  margin-bottom: 0px;
}

#vendor-job-show-content .content-body .content-body-box .box-transparent .box1 .row-2 .p3 {
  display: inline;
}

#vendor-job-show-content .content-body .content-body-box .box-transparent .box1 .row-2 .p4 {
  font-size: 14px;
  color: #02cd8d;
  display: inline;
  font-weight: bolder;
}

#vendor-job-show-content .content-body .content-body-box .description-wrapper {
  padding-top: 20px;
}

#vendor-job-show-content .content-body .content-body-box .description-wrapper p {
  word-wrap: break-word;
}

#vendor-job-show-content .content-body .content-body-box .description-wrapper .questions {
  padding-left: 18px;
}

#vendor-job-show-content .content-body .content-body-box .description-wrapper .questions strong {
  line-height: 3;
  font-size: 14px;
  display: inline-block;
  margin-left: -18px;
}

#vendor-job-show-content .content-body .content-body-box .description-wrapper .pull-left:last-child {
  margin-left: 100px;
}

#vendor-job-show-content .content-body .content-body-box .clearfix {
  padding-bottom: 15px;
}

#vendor-job-show-content .content-body .content-body-box .attachment_box {
  position: relative;
  bottom: 0;
  right: 0;
  width: 400px;
}

#vendor-job-show-content .content-body .content-body-box .attachment_box a {
  margin-bottom: 10px;
  color: #02cd8d;
  max-width: 300px;
  display: block;
  line-height: 40px;
  vertical-align: middle;
}

#vendor-job-show-content .content-body .content-body-box .attachment_box a:before {
  vertical-align: middle;
  content: "";
  display: inline-block;
  width: 40px;
  height: 40px;
  background-image: url("../../images/root/client/job/attachment.png");
  margin-right: 5px;
}

#vendor-job-show-content .content-body .content-body-box .title {
  color: #000000;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 15px;
}

#vendor-job-show-content .content-body .content-body-box .title.price {
  font-size: 20px;
}

#vendor-job-show-content .content-body .content-body-box .title.price span {
  display: block;
  font-size: 14px;
}

#vendor-job-show-content .content-body .content-body-box .title .last_update {
  float: right;
}

#vendor-job-show-content .content-body .content-body-box .title:after {
  content: '';
  display: block;
  clear: both;
}

#vendor-job-show-content .content-body .content-body-box .price {
  color: #000000;
  font-size: 20px;
  font-weight: bold;
}

#vendor-job-show-content .content-body .content-body-box .read_more {
  color: #02cd8d;
  cursor: pointer;
}

#vendor-job-show-content .content-body .content-body-box .last_update {
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  color: #9c9c9c;
}

#vendor-job-show-content .content-body .content-body-box .message-box {
  padding: 30px 20px;
  background-color: #ccd9e0;
}

#vendor-job-show-content .content-body .content-body-box .message-box .box-header {
  padding-bottom: 10px;
}

#vendor-job-show-content .content-body .content-body-box .message-box .box-header .title {
  color: #585858;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  float: left;
  line-height: 15px;
}

#vendor-job-show-content .content-body .content-body-box .message-box .box-header a {
  color: #585858;
  text-decoration: none;
}

#vendor-job-show-content .content-body .content-body-box .message-box .box-header a:hover {
  text-decoration: underline;
}

#vendor-job-show-content .content-body .content-body-box .message-box .box-header .schedule {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  line-height: 1;
  float: right;
  display: block;
  padding: 12px;
  border-radius: 4px;
  background-color: #eeeeee;
  color: #02cd8d;
  cursor: pointer;
}

#vendor-job-show-content .content-body .content-body-box .message-box .policy_reminder {
  font-size: 14px;
  padding-bottom: 35px;
  line-height: 15px;
}

#vendor-job-show-content .content-body .content-body-box .message-box .box-input textarea {
  width: 100%;
  min-height: 110px;
  border-radius: 4px;
  border: 2px solid #FFFFFF;
  background-color: #FFFFFF;
  color: #484848;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  padding: 13px;
  transition: border .6s;
  outline: none;
  margin-bottom: 20px;
  display: block;
}

#vendor-job-show-content .content-body .content-body-box .message-box .box-input textarea:focus {
  border: 2px solid #02cd8d;
}

#vendor-job-show-content .content-body .content-body-box .message-box .box-input button {
  width: 195px;
  max-width: 100%;
  border-radius: 4px;
}

#vendor-job-show-content .content-body .content-body-box .message-box .box-body .messages .message {
  position: relative;
  margin-top: 50px;
}

#vendor-job-show-content .content-body .content-body-box .message-box .box-body .messages .message.left .col-logo {
  width: 100px;
  position: absolute;
  top: 0;
  left: 0;
}

#vendor-job-show-content .content-body .content-body-box .message-box .box-body .messages .message.left .col-logo img {
  max-width: 70px;
  max-height: 70px;
  display: block;
  margin: 0 auto;
}

#vendor-job-show-content .content-body .content-body-box .message-box .box-body .messages .message.left .col-message {
  padding-left: 120px;
  float: left;
  width: 80%;
}

#vendor-job-show-content .content-body .content-body-box .message-box .box-body .messages .message.left .col-message .content {
  padding: 25px 20px;
  background-color: #f4f4f4;
  border-radius: 4px;
  color: #484848;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  line-height: 1.6;
  position: relative;
  word-break: break-all;
}

#vendor-job-show-content .content-body .content-body-box .message-box .box-body .messages .message.left .col-message .content .time {
  margin-top: 17px;
  color: #888888;
  font-size: 15px;
  font-family: "Roboto", sans-serif;
}

#vendor-job-show-content .content-body .content-body-box .message-box .box-body .messages .message.left .col-message .content:after {
  content: "";
  display: block;
  position: absolute;
  top: 16px;
  right: 100%;
  border-right: 16px solid #f4f4f4;
  border-top: 16px solid transparent;
  border-bottom: 16px solid transparent;
}

#vendor-job-show-content .content-body .content-body-box .message-box .box-body .messages .message.right .col-logo {
  width: 100px;
  position: absolute;
  top: 0;
  right: 0;
}

#vendor-job-show-content .content-body .content-body-box .message-box .box-body .messages .message.right .col-logo img {
  max-width: 70px;
  max-height: 70px;
  display: block;
  margin: 0 auto;
}

#vendor-job-show-content .content-body .content-body-box .message-box .box-body .messages .message.right .col-message {
  padding-right: 120px;
  float: right;
  width: 80%;
}

#vendor-job-show-content .content-body .content-body-box .message-box .box-body .messages .message.right .col-message .content {
  padding: 25px 20px;
  background-color: #f4f4f4;
  color: #484848;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  line-height: 1.6;
  border-radius: 4px;
  position: relative;
  word-break: break-all;
}

#vendor-job-show-content .content-body .content-body-box .message-box .box-body .messages .message.right .col-message .content .time {
  margin-top: 17px;
  color: #888888;
  font-size: 15px;
  font-family: "Roboto", sans-serif;
}

#vendor-job-show-content .content-body .content-body-box .message-box .box-body .messages .message.right .col-message .content:after {
  content: "";
  display: block;
  position: absolute;
  top: 16px;
  left: 100%;
  border-left: 16px solid #f4f4f4;
  border-top: 16px solid transparent;
  border-bottom: 16px solid transparent;
}

#vendor-job-show-content .content-body .content-body-box .message-box .box-body .messages .message:after {
  content: '';
  display: block;
  clear: both;
}

#vendor-job-show-content .content-body .content-body-box .services-wrapper .hourly_rate .estimated_hour .answer {
  margin-bottom: 15px;
}

#vendor-job-show-content .content-body .content-body-box .services-wrapper .services {
  margin-left: -10px;
  padding-top: 15px;
}

#vendor-job-show-content .content-body .content-body-box .services-wrapper .services .title {
  margin-left: 10px;
}

#vendor-job-show-content .content-body .content-body-box .services-wrapper .services .service {
  display: inline-block;
  margin-left: 10px;
  margin-bottom: 10px;
  padding: 5px 10px;
  background-color: #dcdcdc;
  color: #252525;
  border-radius: 3px;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
}

#vendor-job-show-content .content-body .content-body-box .with-action {
  padding-right: 200px;
  min-height: 100px;
  position: relative;
}

#vendor-job-show-content .content-body .content-body-box .with-action .action-box {
  position: absolute;
  top: 0;
  right: 0;
  width: 200px;
}

#vendor-job-show-content .content-body .content-body-box .with-action .action-box a:not(:first-child) {
  margin-top: 10px;
}

#vendor-job-show-content .content-body .content-body-box ul {
  list-style: disc;
}

#vendor-job-show-content .content-body .content-body-box .action-box .btn-effect-1 {
  text-align: center;
}

#vendor-job-show-content .content-body .content-body-box .action-box .btn-grey {
  height: 42px;
  line-height: 42px;
  display: block;
  text-align: center;
  background-color: #eaeaea;
  color: #fff;
  font-size: 14px;
  border-radius: 2px;
}

#vendor-job-show-content .content-body .content-body-box .matched-points {
  background-color: #f5fbfc;
  border: 1px solid #02cd8d;
  border-radius: 3px;
  padding: 10px 20px;
  color: #5b5b5b;
  font-family: "Roboto", sans-serif;
  font-size: 11px;
  margin-bottom: 20px;
  margin-left: -6px;
}

#vendor-job-show-content .content-body .content-body-box .matched-points .col-sm-6.box {
  padding: 0 5px;
}

#vendor-job-show-content .content-body .content-body-box .matched-points .matched {
  color: #28ac78;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  margin-left: -10px;
}

#vendor-job-show-content .content-body .content-body-box .matched-points .matched .value {
  background-color: #28ac78;
  border-radius: 3px;
  margin-right: 3px;
  display: inline-block;
  color: #92d5ba;
  padding: 3px 6px;
  margin-bottom: 6px;
}

#vendor-job-show-content .content-body .content-body-box .matched-points .matched .value span {
  color: #FFFFFF;
}

#vendor-job-show-content .content-body .content-body-box .matched-points .tick-grey {
  line-height: 20px;
  margin-bottom: 3px;
}

#vendor-job-show-content .content-body .content-body-box .matched-points .tick-grey:before {
  content: '';
  display: inline-block;
  background: url("../../images/root/all.png") no-repeat -882px -555px;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  vertical-align: middle;
}

#vendor-job-show-content .content-body .content-body-box .matched-points .tick-green {
  line-height: 20px;
  margin-bottom: 3px;
}

#vendor-job-show-content .content-body .content-body-box .matched-points .tick-green:before {
  content: '';
  display: inline-block;
  background: url("../../images/root/all.png") no-repeat -767px -556px;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  vertical-align: middle;
}

#vendor-job-show-content .content-body .content-body-box .matched-points .cross {
  line-height: 20px;
  margin-bottom: 3px;
}

#vendor-job-show-content .content-body .content-body-box .matched-points .cross:before {
  content: '';
  display: inline-block;
  background: url("../../images/root/all.png") no-repeat -664px -556px;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  vertical-align: middle;
}

#vendor-job-show-content .content-body .content-body-box .info-icon {
  margin-bottom: 10px;
  position: relative;
  padding-left: 22px;
}

#vendor-job-show-content .content-body .content-body-box .info-icon span {
  margin-left: 3px;
}

#vendor-job-show-content .content-body .content-body-box .info-icon:not(.with-img):before {
  content: '';
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 0;
  background: url("../../images/root/all.png") no-repeat;
}

#vendor-job-show-content .content-body .content-body-box .info-icon.with-img img {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: 16px;
  left: 0;
}

#vendor-job-show-content .content-body .content-body-box .info-icon.map:before {
  background-position: -556px -555px;
  left: 2px;
  width: 12px;
  height: 16px;
}

#vendor-job-show-content .content-body .content-body-box .info-icon.company:before {
  background-position: -333px -561px;
  width: 16px;
  height: 14px;
}

#vendor-job-show-content .content-body .content-body-box .info-icon.industry:before {
  background-position: -211px -558px;
  width: 16px;
  height: 16px;
}

#vendor-job-show-content .content-body .content-body-box .info-icon.business:before {
  background-position: -455px -559px;
  width: 16px;
  height: 14px;
}

#vendor-job-show-content .content-body .content-body-box .info-label {
  color: #707c85;
  font-weight: normal;
  font-family: "Roboto", sans-serif;
}

#vendor-job-show-content .content-body .content-body-box .positions .position {
  position: relative;
  padding-top: 30px;
}

#vendor-job-show-content .content-body .content-body-box .positions .position .label {
  color: #3f3f3f;
  position: absolute;
  font-size: 14px;
  top: 0;
  padding: 0;
}

#vendor-job-show-content .content-body .content-body-box .positions .position .label.label-left {
  left: 0;
}

#vendor-job-show-content .content-body .content-body-box .positions .position .label.label-right {
  right: 0;
}

#vendor-job-show-content .content-body .content-body-box .positions .position .bar {
  position: relative;
  width: 100%;
  height: 10px;
  margin-bottom: 13px;
  background-color: #dddddd;
}

#vendor-job-show-content .content-body .content-body-box .positions .position .bar .pointer {
  width: 20px;
  height: 20px;
  position: absolute;
  top: -5px;
  background-color: #02cd8d;
  border-radius: 50%;
  transform: translateX(-50%);
}

#vendor-job-show-content .content-body .content-body-box .positions .position:not(:first-child) {
  margin-top: 15px;
}

#business-user-job-decline .form-group label.radio {
  font-weight: normal;
  padding: 0 0 0 30px;
  position: relative;
  margin-top: 10px;
  line-height: 30px;
  cursor: pointer;
}

#business-user-job-decline .form-group label.radio span:not(.text-error) {
  position: absolute;
  left: 0;
  top: 5px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #dddddd;
}

#business-user-job-decline .form-group label.radio [type=radio]:checked + span:after {
  content: '';
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 16px;
  height: 16px;
  background-color: #02cd8d;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

@media only screen and (max-width: 991px) {
  #vendor-job-show-content .content-responsive .box-transparent {
    width: 100%;
    background-color: #f0f4f7;
    padding: 20px;
  }
  #vendor-job-show-content .content-responsive .box-transparent .box1 {
    width: 100%;
    margin-bottom: 20px;
    box-shadow: 1px 4px 1px 0px #adabad;
  }
  #vendor-job-show-content .content-responsive .box-transparent .box1 .row-1 {
    background-color: #02cd8d;
    font-size: 18px;
    color: white;
    padding: 10px 20px 10px 20px;
  }
  #vendor-job-show-content .content-responsive .box-transparent .box1 .row-1 .p {
    font-size: 12px;
    color: black;
    margin-top: 5px;
    padding-left: 0;
    word-break: break-all;
    -ms-word-break: break-all;
    word-break: break-all;
    /* Non standard for WebKit */
    word-break: break-word;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
  }
  #vendor-job-show-content .content-responsive .box-transparent .box1 .row-2 {
    background-color: #f5fbfc;
    display: block;
    margin-top: 0px;
    border-top: 1px solid #d4f0e3;
    padding: 10px 20px 10px 20px;
  }
  #vendor-job-show-content .content-responsive .box-transparent .box1 .row-2 .heading {
    font-size: 20px;
    font-weight: bolder;
    padding-top: -5px;
    margin-top: auto;
  }
  #vendor-job-show-content .content-responsive .box-transparent .box1 .row-2 .heading2 {
    font-weight: bolder;
    font-size: 14px;
    display: inline;
    margin-bottom: 5px;
  }
  #vendor-job-show-content .content-responsive .box-transparent .box1 .row-2 .p2 {
    margin-bottom: 0px;
  }
  #vendor-job-show-content .content-responsive .box-transparent .box1 .row-2 .p3 {
    display: inline;
  }
  #vendor-job-show-content .content-responsive .box-transparent .box1 .row-2 .p4 {
    font-size: 14px;
    color: #02cd8d;
    display: inline;
    font-weight: bolder;
  }
  #vendor-job-show-content .content-responsive .box-transparent .box1 .row-2 p {
    padding-left: 0px;
  }
  #vendor-job-show-content .content-responsive .box-transparent .title {
    color: #000000;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 15px;
    padding-left: 20px;
  }
  #vendor-job-show-content .content-responsive .box-transparent .title.price {
    font-size: 20px;
  }
  #vendor-job-show-content .content-responsive .box-transparent .title.price span {
    display: block;
    font-size: 14px;
  }
  #vendor-job-show-content .content-responsive .box-transparent .title .last_update {
    float: right;
  }
  #vendor-job-show-content .content-responsive .box-transparent .title:after {
    content: '';
    display: block;
    clear: both;
  }
  #vendor-job-show-content .content-responsive .box-transparent .info-icon {
    margin-bottom: 10px;
    position: relative;
    padding-left: 42px;
  }
  #vendor-job-show-content .content-responsive .box-transparent .info-icon span {
    margin-left: 3px;
    word-wrap: break-word;
  }
  #vendor-job-show-content .content-responsive .box-transparent .info-icon:not(.with-img):before {
    content: '';
    position: absolute;
    top: 2px;
    left: 20px;
    background: url("../../images/root/all.png") no-repeat;
  }
  #vendor-job-show-content .content-responsive .box-transparent .info-icon.with-img img {
    position: absolute;
    top: 2px;
    width: 16px;
    left: 20px;
  }
  #vendor-job-show-content .content-responsive .box-transparent .info-icon.map:before {
    background-position: -556px -555px;
    width: 12px;
    height: 16px;
  }
  #vendor-job-show-content .content-responsive .box-transparent .info-icon.company:before {
    background-position: -333px -561px;
    width: 16px;
    height: 14px;
  }
  #vendor-job-show-content .content-responsive .box-transparent .info-icon.industry:before {
    background-position: -211px -558px;
    width: 16px;
    height: 16px;
  }
  #vendor-job-show-content .content-responsive .box-transparent .info-icon.business:before {
    background-position: -455px -559px;
    width: 16px;
    height: 14px;
  }
  #vendor-job-show-content .content-responsive .box-transparent .info-label {
    padding-left: 20px;
    color: #707c85;
    font-weight: normal;
    font-family: "Roboto", sans-serif;
  }
  #vendor-job-show-content .content-responsive .box-transparent p {
    padding-left: 20px;
  }
  #vendor-job-show-content .content-body .container {
    width: 100%;
  }
  #vendor-job-show-content .content-body .content-body-box .box-white {
    width: 100%;
  }
  #vendor-job-show-content .content-body .content-body-box .box-white .box-inner {
    padding: 20px;
  }
  #vendor-job-show-content .content-body .content-body-box .title {
    font-size: 13px;
  }
  #vendor-job-show-content .content-body .content-body-box .title .last-update {
    font-size: 11px;
  }
  #vendor-job-show-content .content-body .content-body-box .info-icon {
    font-size: 12px;
  }
  #vendor-job-show-content .content-body .content-body-box .info-label {
    font-size: 12px;
  }
  #vendor-job-show-content .content-body .content-body-box .with-action {
    padding-right: 160px;
  }
  #vendor-job-show-content .content-body .content-body-box .with-action .action-box {
    width: 160px;
  }
  #vendor-job-show-content .content-body .content-body-box .message-box .box-header {
    padding-bottom: 20px;
  }
  #vendor-job-show-content .content-body .content-body-box .message-box .box-header .title {
    margin-bottom: 0;
    font-size: 16px;
  }
  #vendor-job-show-content .content-body .content-body-box .message-box .box-input button {
    width: 100%;
  }
  #vendor-job-show-content .content-body .content-body-box .action-box .btn-effect-1,
  #vendor-job-show-content .content-body .content-body-box .action-box .btn-effect-2 {
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  #vendor-job-show-content {
    background-color: #f0f4f7;
  }
  #vendor-job-show-content #vendor-job-header {
    padding: 25px 0 60px;
  }
  #vendor-job-show-content .content-quick-link .quick-link-bar {
    padding: 0;
  }
  #vendor-job-show-content .content-quick-link .quick-link-bar a {
    padding: 15px 10px;
  }
  #vendor-job-show-content .container {
    padding-left: 0;
    padding-right: 0;
  }
  #vendor-job-show-content .content-body {
    padding: 20px;
    padding-bottom: 0;
  }
  #vendor-job-show-content .content-body .container {
    padding-left: 0px;
    padding-right: 0px;
  }
  #vendor-job-show-content .content-body .content-body-box {
    margin-bottom: 0px;
  }
  #vendor-job-show-content .content-body .content-body-box .attachment_box {
    width: 100%;
  }
  #vendor-job-show-content .content-body .content-body-box .title {
    font-size: 12px;
  }
  #vendor-job-show-content .content-body .content-body-box .title.price {
    font-size: 16px;
  }
  #vendor-job-show-content .content-body .content-body-box .title.price span {
    font-size: 14px;
  }
  #vendor-job-show-content .content-body .content-body-box .box-white {
    width: 100%;
  }
  #vendor-job-show-content .content-body .content-body-box .box-white .box-inner {
    padding: 20px 10px;
    font-size: 10px;
  }
  #vendor-job-show-content .content-body .content-body-box .box-white .action-box .btn-effect-1 {
    text-align: center;
    padding: 10px 10px 13px;
    font-size: 10px;
  }
  #vendor-job-show-content .content-body .content-body-box .box-white .action-box .btn-effect-2 {
    font-size: 10px;
    padding: 10px 10px 13px;
  }
  #vendor-job-show-content .content-body .content-body-box .with-action {
    padding-right: 0;
    padding-top: 0;
  }
  #vendor-job-show-content .content-body .content-body-box .with-action .services {
    width: 50%;
  }
  #vendor-job-show-content .content-body .content-body-box .with-action .action-box {
    width: 50%;
    right: 10px;
    padding-left: 35px;
  }
  #vendor-job-show-content .content-body .content-body-box .services-wrapper .services {
    margin-left: 0px;
    padding-top: 10px;
  }
  #vendor-job-show-content .content-body .content-body-box .services-wrapper .services .service {
    font-size: 10px;
  }
  #vendor-job-show-content .content-body .content-body-box .services-wrapper .services .title {
    margin-left: 0;
  }
  #vendor-job-show-content .content-body .content-body-box .services-wrapper .hourly_rate .title {
    margin-bottom: 0px;
  }
  #vendor-job-show-content .content-body .content-body-box .description-wrapper p {
    padding-bottom: 0px;
  }
  #vendor-job-show-content .content-body .content-body-box .description-wrapper .questions {
    padding-left: 18px;
  }
  #vendor-job-show-content .content-body .content-body-box .description-wrapper .questions strong {
    line-height: 20px;
  }
  #vendor-job-show-content .content-body .content-body-box .description-wrapper .pull-left {
    padding-right: 100px;
  }
  #vendor-job-show-content .content-body .content-body-box .description-wrapper .pull-left .title {
    margin-bottom: 0px;
  }
  #vendor-job-show-content .content-body .content-body-box .description-wrapper .pull-left:last-child {
    margin-left: 0px;
  }
  #vendor-job-show-content .content-body .content-body-box .description-wrapper .action-box .row .col-sm-6 {
    padding-bottom: 10px;
  }
  #vendor-job-show-content .content-body .content-body-box .message-box .box-header {
    padding-bottom: 20px;
  }
  #vendor-job-show-content .content-body .content-body-box .message-box .box-header .title {
    line-height: 1.5;
    font-size: 16px;
  }
  #vendor-job-show-content .content-body .content-body-box .message-box .box-input button {
    width: 100%;
  }
  #vendor-job-show-content .content-body .content-body-box .message-box .box-body .messages .message.left .col-logo {
    width: 80px;
  }
  #vendor-job-show-content .content-body .content-body-box .message-box .box-body .messages .message.left .col-logo img {
    max-width: 50px;
    max-height: 50px;
  }
  #vendor-job-show-content .content-body .content-body-box .message-box .box-body .messages .message.left .col-message {
    padding-left: 95px;
    width: 100%;
  }
  #vendor-job-show-content .content-body .content-body-box .message-box .box-body .messages .message.left .col-message .content {
    padding: 25px 15px;
    font-size: 12px;
  }
  #vendor-job-show-content .content-body .content-body-box .message-box .box-body .messages .message.right .col-logo {
    width: 80px;
  }
  #vendor-job-show-content .content-body .content-body-box .message-box .box-body .messages .message.right .col-logo img {
    max-width: 50px;
    max-height: 50px;
  }
  #vendor-job-show-content .content-body .content-body-box .message-box .box-body .messages .message.right .col-message {
    padding-right: 95px;
    width: 100%;
  }
  #vendor-job-show-content .content-body .content-body-box .message-box .box-body .messages .message.right .col-message .content {
    padding: 25px 15px;
    font-size: 12px;
  }
  #vendor-job-show-content .content-body .content-body-box .last_update {
    font-size: 10px;
  }
  #vendor-job-show-content .content-responsive .box-transparent > row .col-sm-6 + .col-sm-6 {
    margin-top: 20px;
  }
  #business-user-job-decline .mfp-close {
    display: none;
  }
}

#vendor-job-form-content .content-body {
  background-color: #f7f7f9;
  padding: 40px 0;
}

#vendor-job-form-content .content-body .content-body-box {
  border: 1px solid #d4f0e3;
}

#vendor-job-form-content .content-body .content-body-box .box-white {
  float: left;
  width: 75%;
  background-color: #FFFFFF;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body {
  padding: 40px;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .services-wrapper .services {
  margin-left: -10px;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .services-wrapper .services .title {
  margin-left: 10px;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .services-wrapper .services .service {
  display: inline-block;
  margin-left: 10px;
  margin-bottom: 10px;
  padding: 5px 10px;
  background-color: #dcdcdc;
  color: #252525;
  border-radius: 3px;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .with-action {
  padding-right: 200px;
  min-height: 100px;
  position: relative;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .with-action .action-box {
  position: absolute;
  top: 0;
  right: 0;
  width: 200px;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .with-action .action-box a:not(:first-child) {
  margin-top: 10px;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group {
  margin-bottom: 20px;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .question {
  word-wrap: break-word;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group:after {
  content: '';
  display: block;
  clear: both;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .label-control {
  padding-top: 11px;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .radio-option-types:after {
  content: '';
  display: block;
  clear: both;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .radio-option-types .radio-option-type {
  font-weight: normal;
  float: left;
  cursor: pointer;
  text-align: center;
  font-family: "Roboto", sans-serif;
  width: 200px;
  border: 1px solid #56646d;
  color: #56646d;
  padding: 31px 21px 11px 21px;
  position: relative;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .radio-option-types .radio-option-type.checked {
  border: 1px solid #00b37b;
  color: #00b37b;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .radio-option-types .radio-option-type.checked:after {
  content: '';
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 1px solid #00b37b;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .radio-option-types .radio-option-type:not(:first-child) {
  margin-left: 20px;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .radio-option-types .radio-option-type.proposal:before {
  background: url("../../images/root/all.png") no-repeat -79px -556px;
  width: 36px;
  height: 26px;
  content: '';
  display: block;
  margin: 0 auto 10px;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .radio-option-types .radio-option-type.proposal.checked:before {
  background: url("../../images/root/all.png") no-repeat -645px -475px;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .radio-option-types .radio-option-type.need_more_info:before {
  background: url("../../images/root/all.png") no-repeat -542px -473px;
  width: 27px;
  height: 25px;
  content: '';
  display: block;
  margin: 0 auto 10px;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .radio-option-types .radio-option-type.need_more_info.checked:before {
  background: url("../../images/root/all.png") no-repeat -877px -468px;
  width: 27px;
  height: 25px;
  content: '';
  display: block;
  margin: 0 auto 10px;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .radio-option-quote-types:after {
  content: '';
  display: block;
  clear: both;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .radio-option-quote-types .radio-option-quote-type {
  float: left;
  cursor: pointer;
  text-align: center;
  font-family: "Roboto", sans-serif;
  width: 200px;
  border: 1px solid #56646d;
  color: #56646d;
  padding: 31px 21px 11px 21px;
  position: relative;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .radio-option-quote-types .radio-option-quote-type.checked {
  border: 1px solid #00b37b;
  color: #00b37b;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .radio-option-quote-types .radio-option-quote-type.checked:after {
  content: '';
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 1px solid #00b37b;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .radio-option-quote-types .radio-option-quote-type:not(:first-child) {
  margin-left: 20px;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .radio-option-quote-types .radio-option-quote-type.fixed_rate:before {
  background: url("../../images/root/all.png") no-repeat -88px -475px;
  width: 25px;
  height: 25px;
  content: '';
  display: block;
  margin: 0 auto 10px;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .radio-option-quote-types .radio-option-quote-type.fixed_rate.checked:before {
  background: url("../../images/root/all.png") no-repeat -458px -477px;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .radio-option-quote-types .radio-option-quote-type.hourly_rate:before {
  background: url("../../images/root/all.png") no-repeat -330px -475px;
  width: 25px;
  height: 25px;
  content: '';
  display: block;
  margin: 0 auto 10px;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .radio-option-quote-types .radio-option-quote-type.hourly_rate.checked:before {
  background: url("../../images/root/all.png") no-repeat -207px -476px;
  width: 27px;
  height: 25px;
  content: '';
  display: block;
  margin: 0 auto 10px;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .radios-grid .radios-grid-header {
  margin-bottom: 15px;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .radios-grid .radios-grid-header .label {
  font-size: 12px;
  color: #585858;
  font-weight: bold;
  display: block;
  white-space: normal;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .radios-grid .radios-grid-body .radios-grid-row {
  margin-bottom: 15px;
  font-size: 14px;
  color: #585858;
  font-weight: normal;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .radios-grid .radios-grid-body .radios-grid-row .radio-input {
  cursor: pointer;
  border-radius: 50%;
  border: 4px solid #dddddd;
  background-color: #dddddd;
  width: 20px;
  height: 20px;
  margin: 0 auto;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .radios-grid .radios-grid-body .radios-grid-row .radio-input.checked {
  background-color: #02cd8d;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .date-control {
  display: inline-block;
  width: 200px;
  margin-left: 50px;
  position: relative;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .date-control .fa {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translate(0, -50%);
  color: #03cd8e;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group input.form-control,
#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group textarea.form-control,
#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group select.form-control {
  border: 1px solid #e1e3e3;
  border-radius: 4px;
  padding: 10px 20px;
  line-height: 1;
  outline: 0;
  font-size: 14px;
  height: auto;
  color: #585858;
  box-shadow: none;
  font-family: "Roboto", sans-serif;
  background-color: #FFFFFF;
  font-weight: bold;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group input.form-control:focus,
#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group textarea.form-control:focus,
#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group select.form-control:focus {
  box-shadow: none;
  border: 1px solid #e1e3e3;
  outline: 0;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group input.form-control:disabled,
#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group textarea.form-control:disabled,
#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group select.form-control:disabled {
  background-color: #e1e3e3;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group textarea.form-control {
  height: 180px;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .helper + .label-file {
  margin-top: 15px;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .button_add_more_attachment {
  color: #02cd8d;
  cursor: pointer;
  display: inline-block;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .label-file label {
  overflow: hidden;
  font-size: 14px;
  padding: 5px 15px 11px;
  margin-right: 20px;
  position: relative;
  border-radius: 3px;
  background-color: #02cd8d;
  cursor: pointer;
  float: left;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .label-file label:after {
  position: absolute;
  bottom: 0;
  height: 6px;
  left: 0;
  right: 0;
  background-color: #28ac78;
  transition: height .3s;
  content: '';
  display: block;
  z-index: 1;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .label-file label span {
  position: relative;
  z-index: 2;
  color: #FFFFFF;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .label-file label:hover:after {
  height: 100%;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .label-file input[type=file] {
  display: none;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .label-file input[type=text] {
  border: none;
  padding: 0;
  display: inline-block;
  line-height: 36px;
  float: left;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .label-file .uploaded {
  font-size: 13px;
  line-height: 1.428571429;
  color: #333333;
  margin-bottom: 15px;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .label-file .uploaded a[download] {
  color: #02cd8d;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .label-file .uploaded .remove-file {
  display: inline-block;
  margin-left: 5px;
  cursor: pointer;
  text-decoration: none;
  background-image: url(../../images/root/tools/remove-file.png);
  background-size: cover;
  width: 20px;
  height: 20px;
  vertical-align: middle;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .label-file .btn-file:after {
  background-color: #28ac78;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .quote_price span {
  margin-right: 10px;
  display: block;
  float: left;
  border-radius: 0;
  line-height: 41px;
  height: 41px;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .quote_price select {
  width: 90px;
  display: block;
  float: left;
  border-radius: 0;
  height: 40px;
  padding: 3px 5px;
  font-weight: normal;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .quote_price input[type=text] {
  border-radius: 0;
  width: 150px;
  display: block;
  float: left;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .estimated_hour span {
  margin-right: 10px;
  display: block;
  float: left;
  border-radius: 0;
  line-height: 41px;
  height: 41px;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .estimated_hour input[type=text] {
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
  border-radius: 0;
  width: 100px;
  display: block;
  float: left;
  margin-right: 10px;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .estimated_hour_value input[type=text] {
  border-radius: 0;
  width: 300px;
  display: block;
  margin-right: 10px;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .input-radios-list .radio-list-row {
  margin-bottom: 15px;
  font-size: 14px;
  color: #585858;
  font-weight: normal;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .input-radios-list .radio-list-row .radio-input {
  cursor: pointer;
  border-radius: 50%;
  border: 4px solid #dddddd;
  background-color: #dddddd;
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 15px;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .input-radios-list .radio-list-row .radio-input.checked {
  background-color: #02cd8d;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .input-radios-list .radio-list-row .radio-input.checked + span {
  color: #02cd8d;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .label-checkbox {
  padding-left: 35px;
  line-height: 20px;
  position: relative;
  cursor: pointer;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .label-checkbox input[type=checkbox] {
  position: absolute;
  opacity: 0;
  width: 20px;
  height: 20px;
  left: 0;
  top: 0;
  margin: 0;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .label-checkbox input[type=checkbox]:checked + .checkbox {
  border-color: #02CD8D;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .label-checkbox input[type=checkbox]:checked + .checkbox:after {
  opacity: 1 !important;
  border-color: #02CD8D;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .label-checkbox input[type=checkbox]:hover + .checkbox {
  border-color: #02CD8D;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .label-checkbox input[type=checkbox]:hover + .checkbox:after {
  opacity: 0.5;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .label-checkbox .checkbox {
  border: 2px solid #bbb;
  margin: 0;
  width: 20px;
  height: 20px;
  position: absolute;
  left: 0;
  top: 0;
  transition: border-color 0.3s;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .label-checkbox .checkbox.error {
  border-color: red;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .label-checkbox .checkbox:after {
  display: block;
  opacity: 0;
  content: '';
  height: 5px;
  width: 15px;
  position: absolute;
  border-bottom: 2px solid #bbb;
  border-left: 2px solid #bbb;
  /* IE 9 */
  /* Chrome, Safari, Opera */
  transform: rotate(-45deg) translate(-50%, -50%);
  left: 50%;
  transition: opacity 0.3s, border-color 0.3s;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body .attachments .label-file {
  padding-top: 11px;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body #btn-submit {
  width: 100%;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body ul {
  list-style: disc;
}

#vendor-job-form-content .content-body .content-body-box .box-white .box-body:not(:first-child) {
  border-top: 1px solid #d4f0e3;
}

#vendor-job-form-content .content-body .content-body-box .box-transparent {
  float: left;
  width: 25%;
  background-color: #f0f4f7;
  padding: 20px;
}

#vendor-job-form-content .content-body .content-body-box .box-transparent .box1 {
  margin-bottom: 20px;
  box-shadow: 1px 4px 1px 0px #adabad;
  margin-left: -6px;
}

#vendor-job-form-content .content-body .content-body-box .box-transparent .box1 .row-1 {
  background-color: #02cd8d;
  font-size: 18px;
  color: white;
  padding: 10px;
}

#vendor-job-form-content .content-body .content-body-box .box-transparent .box1 .row-1 .p {
  font-size: 12px;
  color: black;
  margin-top: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#vendor-job-form-content .content-body .content-body-box .box-transparent .box1 .row-2 {
  background-color: #f5fbfc;
  display: block;
  margin-top: 0px;
  border-top: 1px solid #d4f0e3;
  padding: 10px;
}

#vendor-job-form-content .content-body .content-body-box .box-transparent .box1 .row-2 .heading {
  font-size: 20px;
  font-weight: bolder;
  padding-top: -5px;
  margin-top: auto;
}

#vendor-job-form-content .content-body .content-body-box .box-transparent .box1 .row-2 .heading2 {
  font-weight: bolder;
  font-size: 14px;
  display: inline;
  margin-bottom: 5px;
}

#vendor-job-form-content .content-body .content-body-box .box-transparent .box1 .row-2 .p2 {
  margin-bottom: 0px;
}

#vendor-job-form-content .content-body .content-body-box .box-transparent .box1 .row-2 .p3 {
  display: inline;
}

#vendor-job-form-content .content-body .content-body-box .box-transparent .box1 .row-2 .p4 {
  font-size: 14px;
  color: #02cd8d;
  display: inline;
  font-weight: bolder;
}

#vendor-job-form-content .content-body .content-body-box .title {
  color: #000000;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 15px;
}

#vendor-job-form-content .content-body .content-body-box .title .last_update {
  float: right;
}

#vendor-job-form-content .content-body .content-body-box .title:after {
  content: '';
  display: block;
  clear: both;
}

#vendor-job-form-content .content-body .content-body-box .matched-points {
  background-color: #f5fbfc;
  border: 1px solid #02cd8d;
  border-radius: 3px;
  padding: 10px 20px;
  color: #5b5b5b;
  font-family: "Roboto", sans-serif;
  font-size: 11px;
  margin-bottom: 20px;
  margin-left: -6px;
}

#vendor-job-form-content .content-body .content-body-box .matched-points .col-sm-6.box {
  padding: 0 5px;
}

#vendor-job-form-content .content-body .content-body-box .matched-points .matched {
  color: #28ac78;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  margin-left: -10px;
}

#vendor-job-form-content .content-body .content-body-box .matched-points .matched .value {
  background-color: #28ac78;
  border-radius: 3px;
  margin-right: 3px;
  display: inline-block;
  color: #92d5ba;
  padding: 3px 6px;
  margin-bottom: 6px;
}

#vendor-job-form-content .content-body .content-body-box .matched-points .matched .value span {
  color: #FFFFFF;
}

#vendor-job-form-content .content-body .content-body-box .matched-points .tick-grey {
  line-height: 20px;
  margin-bottom: 3px;
}

#vendor-job-form-content .content-body .content-body-box .matched-points .tick-grey:before {
  content: '';
  display: inline-block;
  background: url("../../images/root/all.png") no-repeat -882px -555px;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  vertical-align: middle;
}

#vendor-job-form-content .content-body .content-body-box .matched-points .tick-green {
  line-height: 20px;
  margin-bottom: 3px;
}

#vendor-job-form-content .content-body .content-body-box .matched-points .tick-green:before {
  content: '';
  display: inline-block;
  background: url("../../images/root/all.png") no-repeat -767px -556px;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  vertical-align: middle;
}

#vendor-job-form-content .content-body .content-body-box .matched-points .cross {
  line-height: 20px;
  margin-bottom: 3px;
}

#vendor-job-form-content .content-body .content-body-box .matched-points .cross:before {
  content: '';
  display: inline-block;
  background: url("../../images/root/all.png") no-repeat -664px -556px;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  vertical-align: middle;
}

#vendor-job-form-content .content-body .content-body-box .info-icon {
  margin-bottom: 10px;
  position: relative;
  padding-left: 22px;
}

#vendor-job-form-content .content-body .content-body-box .info-icon:not(.with-img):before {
  content: '';
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 0;
  background: url("../../images/root/all.png") no-repeat;
}

#vendor-job-form-content .content-body .content-body-box .info-icon.with-img img {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: 16px;
  left: 0;
}

#vendor-job-form-content .content-body .content-body-box .info-icon.map:before {
  background-position: -556px -555px;
  width: 12px;
  height: 16px;
  left: 2px;
}

#vendor-job-form-content .content-body .content-body-box .info-icon.company:before {
  background-position: -333px -561px;
  width: 16px;
  height: 14px;
}

#vendor-job-form-content .content-body .content-body-box .info-icon.industry:before {
  background-position: -211px -558px;
  width: 16px;
  height: 16px;
}

#vendor-job-form-content .content-body .content-body-box .info-icon.business:before {
  background-position: -455px -559px;
  width: 16px;
  height: 14px;
}

#vendor-job-form-content .content-body .content-body-box .info-label {
  color: #707c85;
  font-weight: normal;
  font-family: "Roboto", sans-serif;
}

#vendor-job-form-content .content-body .content-body-box .info-box {
  border: 1px solid #dfdfe0;
  margin-bottom: 20px;
}

#vendor-job-form-content .content-body .content-body-box .info-box .box-header {
  background-color: #02cd8d;
  padding: 15px 20px;
}

#vendor-job-form-content .content-body .content-body-box .info-box .box-header h4 {
  color: #FFFFFF;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
}

#vendor-job-form-content .content-body .content-body-box .info-box .box-header .description {
  color: #585858;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
}

#vendor-job-form-content .content-body .content-body-box .info-box .box-body {
  padding: 15px 20px;
  border-left: 1px solid #d4f0e3;
  border-right: 1px solid #d4f0e3;
  border-bottom: 1px solid #d4f0e3;
}

#vendor-job-form-content .content-body .content-body-box .info-box .box-body:not(:first-child) {
  border-top: 1px solid #dfdfe0;
}

#vendor-job-form-content .content-body .content-body-box .info-box .box-body .price {
  color: #000000;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
}

#vendor-job-form-content .content-body .content-body-box .info-box .box-body b {
  color: #000000;
}

#vendor-job-form-content .content-body .content-body-box .info-box .box-body .green {
  color: #28ac78;
  font-weight: bold;
}

#vendor-job-form-content .content-body .content-body-box .positions .position {
  position: relative;
  padding-top: 30px;
}

#vendor-job-form-content .content-body .content-body-box .positions .position .label {
  color: #3f3f3f;
  position: absolute;
  font-size: 14px;
  top: 0;
  padding: 0;
}

#vendor-job-form-content .content-body .content-body-box .positions .position .label.label-left {
  left: 0;
}

#vendor-job-form-content .content-body .content-body-box .positions .position .label.label-right {
  right: 0;
}

#vendor-job-form-content .content-body .content-body-box .positions .position .bar {
  position: relative;
  width: 100%;
  height: 10px;
  margin-bottom: 13px;
  background-color: #dddddd;
}

#vendor-job-form-content .content-body .content-body-box .positions .position .bar .pointer {
  width: 20px;
  height: 20px;
  position: absolute;
  top: -5px;
  background-color: #02cd8d;
  border-radius: 50%;
  transform: translateX(-50%);
}

#vendor-job-form-content .content-body .content-body-box .positions .position:not(:first-child) {
  margin-top: 15px;
}

#vendor-job-form-content .content-body .content-body-box .description-wrapper {
  transition: height .3s;
}

#vendor-job-form-content .content-body .content-body-box .description-wrapper .read_more {
  color: #02CD8D;
  font-weight: normal;
}

#vendor-job-form-content .content-body .content-body-box .description-wrapper .questions {
  padding-left: 18px;
}

#vendor-job-form-content .content-body .content-body-box .description-wrapper .questions strong {
  line-height: 3;
  font-size: 14px;
  display: inline-block;
  margin-left: -18px;
}

.hidden-quote-type {
  display: none;
}

#popup-vendor-proposal-create {
  padding-top: 30px;
  padding-bottom: 30px;
}

#popup-vendor-proposal-create .popup-heading {
  max-width: 100%;
  width: 1075px;
  padding-left: 130px;
  padding-right: 130px;
  margin: 0 auto;
  text-align: center;
  position: relative;
}

#popup-vendor-proposal-create .popup-heading h3 {
  font-family: "roboto";
  font-weight: 500;
  color: #02cd8d;
  font-size: 24px;
  margin: 0 0 20px;
  line-height: 1.5;
}

#popup-vendor-proposal-create .popup-heading .mfp-close {
  background-image: url("../../images/root/popup/maginific-close.png");
  width: 20px;
  height: 20px;
  right: 10px;
  top: 0;
  opacity: 1;
  cursor: pointer;
}

#popup-vendor-proposal-create .popup-content {
  max-width: 100%;
  width: 1075px;
  margin: 0 auto;
}

#popup-vendor-proposal-create .popup-content .content-body {
  background-color: transparent;
  border-radius: 4px 4px 0 0;
}

#popup-vendor-proposal-create .popup-content .content-body [class^=schedule_] {
  border-radius: 10px;
  background-color: #ffffff;
}

#popup-vendor-proposal-create .popup-content .content-body [class^=schedule_] .top {
  text-align: center;
  line-height: 20px;
  padding: 30px;
  border-bottom: 3px solid #f2f2f2;
}

#popup-vendor-proposal-create .popup-content .content-body [class^=schedule_] .top .title {
  font-size: 16px;
  padding-bottom: 30px;
  font-weight: bold;
}

#popup-vendor-proposal-create .popup-content .content-body [class^=schedule_] .top .title span {
  font-size: 24px;
}

#popup-vendor-proposal-create .popup-content .content-body [class^=schedule_] .top .service {
  font-size: 24px;
}

#popup-vendor-proposal-create .popup-content .content-body [class^=schedule_] .top .service span {
  font-size: 12px;
}

#popup-vendor-proposal-create .popup-content .content-body [class^=schedule_] .bottom {
  text-align: center;
  padding: 30px;
}

#popup-vendor-proposal-create .popup-content .content-body [class^=schedule_] .bottom .value-title, #popup-vendor-proposal-create .popup-content .content-body [class^=schedule_] .bottom .rate-title {
  padding-bottom: 15px;
  font-weight: bold;
  font-size: 13px;
}

#popup-vendor-proposal-create .popup-content .content-body [class^=schedule_] .bottom .answer {
  font-size: 14px;
}

#popup-vendor-proposal-create .popup-content .content-body [class^=schedule_] .line {
  margin: 30px 0;
  height: 2px;
}

#popup-vendor-proposal-create .popup-content .content-body .schedule_1 {
  border: 3px solid #485cec;
}

#popup-vendor-proposal-create .popup-content .content-body .schedule_1 .top .title {
  color: #485cec;
}

#popup-vendor-proposal-create .popup-content .content-body .schedule_1 .bottom {
  text-align: left;
}

#popup-vendor-proposal-create .popup-content .content-body .schedule_1 .bottom .value-title, #popup-vendor-proposal-create .popup-content .content-body .schedule_1 .bottom .rate-title {
  color: #485cec;
}

#popup-vendor-proposal-create .popup-content .content-body .schedule_1 .line {
  background-color: #485cec;
}

#popup-vendor-proposal-create .popup-content .content-body .schedule_1 .example {
  margin: 0px 15px 30px;
  background-color: #485cec;
  border-radius: 10px;
}

#popup-vendor-proposal-create .popup-content .content-body .schedule_1 .example .content {
  color: #ffffff;
  padding: 20px 15px;
  font-size: 14px;
}

#popup-vendor-proposal-create .popup-content .content-body .schedule_1 .example .content .title {
  font-size: 16px;
  padding-bottom: 15px;
}

#popup-vendor-proposal-create .popup-content .content-body .schedule_1 .example .content .last {
  padding-top: 5px;
}

#popup-vendor-proposal-create .popup-content .content-body .schedule_2 {
  border: 3px solid #ec6348;
}

#popup-vendor-proposal-create .popup-content .content-body .schedule_2 .top .title {
  color: #ec6348;
}

#popup-vendor-proposal-create .popup-content .content-body .schedule_2 .bottom .value-title, #popup-vendor-proposal-create .popup-content .content-body .schedule_2 .bottom .rate-title {
  color: #ec6348;
}

#popup-vendor-proposal-create .popup-content .content-body .schedule_2 .line {
  background-color: #ec6348;
}

#popup-vendor-proposal-create .popup-content .content-body .schedule_3 {
  border: 3px solid #16bc9f;
}

#popup-vendor-proposal-create .popup-content .content-body .schedule_3 .top .title {
  color: #16bc9f;
}

#popup-vendor-proposal-create .popup-content .content-body .schedule_3 .bottom .value-title, #popup-vendor-proposal-create .popup-content .content-body .schedule_3 .bottom .rate-title {
  color: #16bc9f;
}

#popup-vendor-proposal-create .popup-content .content-body .schedule_3 .line {
  background-color: #16bc9f;
}

#popup-vendor-proposal-create .popup-content .content-body .note {
  width: 100%;
  margin-top: 30px;
  padding: 40px 60px;
  font-size: 16px;
  font-weight: 300;
  background-color: #29f2b1;
}

#popup-vendor-proposal-create .popup-content .content-body .note span {
  color: #485cec;
  font-size: 12px;
}

#popup-vendor-proposal-create .popup-content .content-footer {
  padding: 20px 0;
  background-color: #ebebec;
  border-radius: 0 0 4px 4px;
}

#popup-vendor-proposal-create .popup-content .content-footer .term {
  padding: 0 30px;
}

#popup-vendor-proposal-create .popup-content .content-footer .term .label-checkbox {
  font-family: "roboto";
  font-size: 16px;
  font-weight: 300;
  padding-left: 30px;
  line-height: 20px;
  position: relative;
  cursor: pointer;
}

#popup-vendor-proposal-create .popup-content .content-footer .term .label-checkbox input[type=checkbox] {
  position: absolute;
  opacity: 0;
  width: 20px;
  height: 20px;
  left: 0;
  top: 0;
  margin: 0;
}

#popup-vendor-proposal-create .popup-content .content-footer .term .label-checkbox input[type=checkbox]:checked + .checkbox {
  border-color: #02CD8D;
}

#popup-vendor-proposal-create .popup-content .content-footer .term .label-checkbox input[type=checkbox]:checked + .checkbox:after {
  opacity: 1 !important;
  border-color: #02CD8D;
}

#popup-vendor-proposal-create .popup-content .content-footer .term .label-checkbox input[type=checkbox]:hover + .checkbox {
  border-color: #02CD8D;
}

#popup-vendor-proposal-create .popup-content .content-footer .term .label-checkbox input[type=checkbox]:hover + .checkbox:after {
  opacity: 0.5;
}

#popup-vendor-proposal-create .popup-content .content-footer .term .label-checkbox .checkbox {
  border: 2px solid #bbb;
  margin: 0;
  width: 20px;
  height: 20px;
  position: absolute;
  left: 0;
  top: 0;
  transition: border-color 0.3s;
}

#popup-vendor-proposal-create .popup-content .content-footer .term .label-checkbox .checkbox.error {
  border-color: red;
}

#popup-vendor-proposal-create .popup-content .content-footer .term .label-checkbox .checkbox:after {
  display: block;
  opacity: 0;
  content: '';
  height: 5px;
  width: 15px;
  position: absolute;
  border-bottom: 2px solid #bbb;
  border-left: 2px solid #bbb;
  /* IE 9 */
  /* Chrome, Safari, Opera */
  transform: rotate(-45deg) translate(-50%, -50%);
  left: 50%;
  transition: opacity 0.3s, border-color 0.3s;
}

#popup-vendor-proposal-create .popup-content .content-footer .footer-button {
  margin-top: 20px;
  background-color: #02cd8d;
  padding: 15px 0;
  width: 100%;
  color: #fff;
  text-align: center;
  cursor: pointer;
  font-family: "roboto";
  font-weight: 700;
  font-size: 18px;
  border: none;
  box-shadow: none;
  outline: 0;
  transition: background-color .1s, opacity 1s;
  display: none;
  opacity: 0;
}

#popup-vendor-proposal-create .popup-content .content-footer .footer-button.active {
  opacity: 1;
  display: block;
}

#popup-vendor-proposal-create .popup-content .content-footer .footer-button:not(:disabled):active {
  background-color: rgba(2, 205, 141, 0.8);
}

@media only screen and (max-width: 991px) {
  #vendor-job-form-content .content-responsive .box-transparent {
    width: 100%;
    background-color: #f0f4f7;
    padding: 20px;
  }
  #vendor-job-form-content .content-responsive .box-transparent .box1 {
    width: 100%;
    margin-bottom: 20px;
    box-shadow: 1px 4px 1px 0px #adabad;
  }
  #vendor-job-form-content .content-responsive .box-transparent .box1 .row-1 {
    background-color: #02cd8d;
    font-size: 18px;
    color: white;
    padding: 10px 20px 10px 20px;
  }
  #vendor-job-form-content .content-responsive .box-transparent .box1 .row-1 .p {
    font-size: 12px;
    color: black;
    margin-top: 5px;
    padding-left: 0;
  }
  #vendor-job-form-content .content-responsive .box-transparent .box1 .row-2 {
    background-color: #f5fbfc;
    display: block;
    margin-top: 0px;
    border-top: 1px solid #d4f0e3;
    padding: 10px 20px 10px 20px;
  }
  #vendor-job-form-content .content-responsive .box-transparent .box1 .row-2 .heading {
    font-size: 20px;
    font-weight: bolder;
    padding-top: -5px;
    margin-top: auto;
  }
  #vendor-job-form-content .content-responsive .box-transparent .box1 .row-2 .heading2 {
    font-weight: bolder;
    font-size: 14px;
    display: inline;
    margin-bottom: 5px;
  }
  #vendor-job-form-content .content-responsive .box-transparent .box1 .row-2 .p2 {
    margin-bottom: 0px;
  }
  #vendor-job-form-content .content-responsive .box-transparent .box1 .row-2 .p3 {
    display: inline;
  }
  #vendor-job-form-content .content-responsive .box-transparent .box1 .row-2 .p4 {
    font-size: 14px;
    color: #02cd8d;
    display: inline;
    font-weight: bolder;
  }
  #vendor-job-form-content .content-responsive .box-transparent .box1 .row-2 p {
    padding-left: 0px;
  }
  #vendor-job-form-content .content-responsive .box-transparent .title {
    color: #000000;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 15px;
    padding-left: 20px;
  }
  #vendor-job-form-content .content-responsive .box-transparent .title.price {
    font-size: 20px;
  }
  #vendor-job-form-content .content-responsive .box-transparent .title.price span {
    display: block;
    font-size: 14px;
  }
  #vendor-job-form-content .content-responsive .box-transparent .title .last_update {
    float: right;
  }
  #vendor-job-form-content .content-responsive .box-transparent .title:after {
    content: '';
    display: block;
    clear: both;
  }
  #vendor-job-form-content .content-responsive .box-transparent .info-icon {
    margin-bottom: 10px;
    position: relative;
    padding-left: 42px;
  }
  #vendor-job-form-content .content-responsive .box-transparent .info-icon span {
    margin-left: 3px;
    word-wrap: break-word;
  }
  #vendor-job-form-content .content-responsive .box-transparent .info-icon:not(.with-img):before {
    content: '';
    position: absolute;
    top: 2px;
    left: 20px;
    background: url("../../images/root/all.png") no-repeat;
  }
  #vendor-job-form-content .content-responsive .box-transparent .info-icon.with-img img {
    position: absolute;
    top: 2px;
    width: 16px;
    left: 20px;
  }
  #vendor-job-form-content .content-responsive .box-transparent .info-icon.map:before {
    background-position: -556px -555px;
    width: 12px;
    height: 16px;
  }
  #vendor-job-form-content .content-responsive .box-transparent .info-icon.company:before {
    background-position: -333px -561px;
    width: 16px;
    height: 14px;
  }
  #vendor-job-form-content .content-responsive .box-transparent .info-icon.industry:before {
    background-position: -211px -558px;
    width: 16px;
    height: 16px;
  }
  #vendor-job-form-content .content-responsive .box-transparent .info-icon.business:before {
    background-position: -455px -559px;
    width: 16px;
    height: 14px;
  }
  #vendor-job-form-content .content-responsive .box-transparent .info-label {
    padding-left: 20px;
    color: #707c85;
    font-weight: normal;
    font-family: "Roboto", sans-serif;
  }
  #vendor-job-form-content .content-responsive .box-transparent p {
    padding-left: 20px;
  }
  #vendor-job-form-content .content-body {
    padding: 0px 15px 20px;
    background-color: #f0f4f7;
  }
  #vendor-job-form-content .content-body .container {
    width: 100%;
  }
  #vendor-job-form-content .content-body .content-body-box .box-white {
    width: 100%;
  }
  #vendor-job-form-content .content-body .content-body-box .box-white .box-body {
    padding: 20px;
  }
  #vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .radio-option-types .radio-option-type {
    width: 160px;
  }
  #vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .radio-option-quote-types .radio-option-quote-type {
    width: 160px;
  }
  #vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .radios-grid .radios-grid-body .radios-grid-row {
    font-size: 12px;
  }
  #vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group input.form-control,
  #vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group textarea.form-control {
    padding: 12px 20px;
  }
  #vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .quote_price input[type=text] {
    width: 130px;
    margin-left: 5px;
  }
  #vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .date-control {
    margin-left: 0px;
  }
  #vendor-job-form-content .content-body .content-body-box .box-transparent .box1 {
    margin-bottom: 20px;
    box-shadow: 1px 4px 1px 0px #adabad;
    margin-left: -6px;
  }
  #vendor-job-form-content .content-body .content-body-box .box-transparent .box1 .row-1 {
    font-size: 16px;
  }
  #vendor-job-form-content .content-body .content-body-box .box-transparent .box1 .row-2 {
    font-size: 12px;
  }
  #vendor-job-form-content .content-body .content-body-box .box-transparent .box1 .row-2 .heading {
    font-size: 14px;
  }
  #vendor-job-form-content .content-body .content-body-box .box-transparent .box1 .row-2 .heading2 {
    font-size: 10px;
  }
  #vendor-job-form-content .content-body .content-body-box .box-transparent .box1 .row-2 .p4 {
    font-size: 10px;
  }
  #popup-vendor-proposal-create .popup-content .content-body .schedule_1 {
    margin-bottom: 5px;
  }
  #popup-vendor-proposal-create .popup-content .content-body .schedule_2 {
    margin-bottom: 5px;
  }
}

@media only screen and (max-width: 767px) {
  #vendor-job-form-content .content-body .container {
    padding-left: 0px;
    padding-right: 0px;
  }
  #vendor-job-form-content .content-body .content-body-box .box-white {
    width: 100%;
  }
  #vendor-job-form-content .content-body .content-body-box .box-white .box-body {
    padding: 20px 20px 0px 20px;
  }
  #vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .radio-option-types {
    width: 100%;
  }
  #vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .radio-option-types .radio-option-type {
    width: 40%;
    padding: 26px;
  }
  #vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .radio-option-types .radio-option-type:not(:first-child) {
    margin-left: 10px;
    float: left;
    display: block;
  }
  #vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .radios-grid .radios-grid-header .row .col-sm-3 {
    width: 25%;
  }
  #vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .radios-grid .radios-grid-header .row .col-sm-3:not(:first-child) {
    width: 25%;
    float: left;
    padding-left: 0px;
    padding-right: 0px;
  }
  #vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .radios-grid .radios-grid-header .row .col-sm-3:not(:first-child):nth-child(2) {
    margin-left: 25%;
  }
  #vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .radios-grid .radios-grid-header .label {
    font-size: 10px;
  }
  #vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .radios-grid .radios-grid-body .radios-grid-row .row .col-sm-3 {
    width: 25%;
    float: left;
    padding-left: 0px;
    padding-right: 0px;
  }
  #vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .radio-option-quote-types {
    width: 100%;
  }
  #vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .radio-option-quote-types .radio-option-quote-type {
    width: 40%;
    padding: 26px;
  }
  #vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .radio-option-quote-types .radio-option-quote-type:not(:first-child) {
    margin-left: 10px;
    float: left;
    display: block;
  }
  #vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .quote_price input[type=text] {
    width: 203px;
    margin-left: 0px;
    margin-top: 0px;
  }
  #vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .estimated_hour input[type=text] {
    width: 59px;
  }
  #vendor-job-form-content .content-body .content-body-box .box-white .box-body .form-group .estimated_hour_value input[type=text] {
    width: 100%;
    font-size: 12px;
    padding: 10px;
  }
  #vendor-job-form-content .content-body .content-body-box .box-white .box-body #btn-submit {
    margin-bottom: 20px;
  }
  #popup-vendor-proposal-create {
    padding-top: 20px;
  }
  #popup-vendor-proposal-create .popup-heading {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 40px;
  }
  #popup-vendor-proposal-create .popup-heading h3 {
    font-size: 16px;
  }
  #popup-vendor-proposal-create .popup-content {
    padding-left: 10px;
    padding-right: 10px;
  }
  #popup-vendor-proposal-create .popup-content .content-body {
    padding: 0px !important;
  }
  #popup-vendor-proposal-create .popup-content .content-body [class^=schedule_] .top {
    padding: 15px;
  }
  #popup-vendor-proposal-create .popup-content .content-body [class^=schedule_] .bottom {
    padding: 15px;
  }
  #popup-vendor-proposal-create .popup-content .content-body [class^=schedule_] .line {
    margin: 30px 0;
    height: 2px;
  }
  #popup-vendor-proposal-create .popup-content .content-body .schedule_1 .example .content {
    padding: 20px 10px;
    font-size: 12px;
  }
  #popup-vendor-proposal-create .popup-content .content-body .schedule_1 .example .content .title {
    font-size: 14px;
  }
  #popup-vendor-proposal-create .popup-content .content-body .note {
    margin-top: 20px;
    padding: 30px 20px;
    font-size: 14px;
  }
  #popup-vendor-proposal-create .popup-content .content-footer .term .label-checkbox {
    font-size: 12px;
  }
  #popup-vendor-proposal-create .popup-content .content-footer .footer-button {
    font-size: 18px;
  }
}

#vendor-profile-content {
  padding-top: 25px;
  padding-bottom: 25px;
  background-color: #f0f4f7;
}

#vendor-profile-content .wrapper .wrapper-title {
  color: #333333;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  letter-spacing: 1px;
}

#vendor-profile-content .wrapper .tab-links ul {
  padding: 10px 0;
  margin: 0;
  list-style: none;
}

#vendor-profile-content .wrapper .tab-links ul li {
  display: block;
  border-bottom: 2px solid #e2f2ed;
}

#vendor-profile-content .wrapper .tab-links ul li a {
  padding: 12px 19px;
  display: block;
  color: #888888;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  background-color: transparent;
  transition: background-color .3s, color .3s;
  cursor: pointer;
}

#vendor-profile-content .wrapper .tab-links ul li a.active {
  background-color: #FFFFFF;
  color: #585858;
}

#vendor-profile-content .wrapper .tab-links ul li a:hover {
  text-decoration: none;
}

#vendor-profile-content .wrapper .tab-links > a {
  margin-left: 19px;
  font-size: 13px;
}

#vendor-profile-content .tab-body {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s .5s, opacity .5s linear;
  height: 0;
  overflow: hidden;
}

#vendor-profile-content .tab-body.active {
  overflow: visible;
  height: auto;
  visibility: visible;
  opacity: 1;
  transition: opacity .5s linear;
}

#vendor-profile-content .tab-body.client-profile .box .box-body {
  padding: 15px 100px 15px 25px;
}

#vendor-profile-content .tab-body.client-profile .box .box-body .form-group .group-header a {
  float: left;
  text-align: center;
  display: block;
  padding: 5px;
  width: 55px;
  color: #585858;
  border-top: 1px solid #eaeaea;
  border-left: 1px solid #eaeaea;
}

#vendor-profile-content .tab-body.client-profile .box .box-body .form-group .group-header a:last-child {
  border-right: 1px solid #eaeaea;
}

#vendor-profile-content .tab-body.client-profile .box .box-body .form-group .group-header a.active {
  background-color: #eaeaea;
}

#vendor-profile-content .tab-body.client-profile .box .box-body .form-group .group-header a:hover {
  text-decoration: none;
}

#vendor-profile-content .tab-body.client-profile .box .box-body .form-group .group-body {
  border: 1px solid #eaeaea;
}

#vendor-profile-content .tab-body.client-profile .box .box-body .form-group .group-body .language {
  display: none;
}

#vendor-profile-content .tab-body.client-profile .box .box-body .form-group .group-body .language input {
  padding: 10px 15px;
  width: 100%;
  border: none;
  background-color: #FFFFFF;
  outline: 0;
}

#vendor-profile-content .tab-body.client-profile .box .box-body .form-group .group-body .language.active {
  display: block;
}

#vendor-profile-content .tab-body.client-profile .box .box-body .form-group .label-control {
  color: #585858;
  font-size: 14px;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  line-height: 1.5;
  padding-top: 38px;
}

#vendor-profile-content .tab-body.client-profile .box .box-body .upload-wrapper {
  position: relative;
}

#vendor-profile-content .tab-body.client-profile .box .box-body .upload-wrapper .upload-preview {
  width: 169px;
  height: 169px;
  position: absolute;
  border: 3px solid #e1e3e3;
  left: 0;
  top: 0;
}

#vendor-profile-content .tab-body.client-profile .box .box-body .upload-wrapper .upload-preview img:not(.remove-file) {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
}

#vendor-profile-content .tab-body.client-profile .box .box-body .upload-wrapper .upload-preview img.remove-file {
  cursor: pointer;
  position: absolute;
  left: 100%;
  bottom: 100%;
  transform: translate(-50%, 50%);
}

#vendor-profile-content .tab-body.client-profile .box .box-body .upload-wrapper .upload-preview + .upload-description {
  padding-left: 200px;
  min-height: 175px;
}

#vendor-profile-content .tab-body.client-profile .box .box-body .upload-wrapper .upload-description .helper + .label-file {
  margin-top: 15px;
}

#vendor-profile-content .tab-body.client-profile .box .box-body .upload-wrapper .upload-description .label-file label {
  font-size: 14px;
  padding: 5px 15px 11px;
  margin-right: 20px;
  position: relative;
  border-radius: 3px;
  background-color: #02cd8d;
  cursor: pointer;
  overflow: hidden;
}

#vendor-profile-content .tab-body.client-profile .box .box-body .upload-wrapper .upload-description .label-file label:after {
  position: absolute;
  bottom: 0;
  height: 6px;
  left: 0;
  right: 0;
  background-color: #0d513b;
  transition: height .3s;
  content: '';
  display: block;
  z-index: 1;
}

#vendor-profile-content .tab-body.client-profile .box .box-body .upload-wrapper .upload-description .label-file label span {
  position: relative;
  z-index: 2;
  color: #FFFFFF;
}

#vendor-profile-content .tab-body.client-profile .box .box-body .upload-wrapper .upload-description .label-file label:hover:after {
  height: 100%;
}

#vendor-profile-content .tab-body.client-profile .box .box-body .upload-wrapper .upload-description .label-file input[type=file] {
  display: none;
}

#vendor-profile-content .tab-body.client-profile .box .box-body .upload-wrapper .upload-description .label-file input[type=text] {
  border: none;
  padding: 0;
  display: block;
}

#vendor-profile-content .tab-body.trust-verification .box .box-body {
  padding: 0;
}

#vendor-profile-content .tab-body.trust-verification .box .box-body .content-bar {
  padding: 15px 25px;
}

#vendor-profile-content .tab-body.trust-verification .box .box-body .content-bar:not(:first-child) {
  border-top: 1px solid #d4f0e3;
}

#vendor-profile-content .tab-body.trust-verification .box .box-body .content-bar .title {
  color: #585858;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  line-height: 1.4;
  margin-bottom: 10px;
}

#vendor-profile-content .tab-body.trust-verification .box .box-body .content-bar .description {
  color: #999999;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  line-height: 1.4;
}

#vendor-profile-content .tab-body.trust-verification .box .box-body .content-bar.with-button {
  padding-right: 190px;
  position: relative;
}

#vendor-profile-content .tab-body.trust-verification .box .box-body .content-bar.with-button .button-right {
  width: 150px;
  position: absolute;
  right: 25px;
  top: 15px;
  white-space: nowrap;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
  font-size: 14px;
}

#vendor-profile-content .tab-body.review .box-reviews .box-header {
  padding-top: 25px;
}

#vendor-profile-content .tab-body.review .box-reviews .box-header .inner-tab-header {
  padding: 0 0 17px;
  float: left;
  color: #999999;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: bold;
  border-bottom: 3px solid transparent;
  transition: border-bottom .3s;
}

#vendor-profile-content .tab-body.review .box-reviews .box-header .inner-tab-header:not(:first-child) {
  margin-left: 25px;
}

#vendor-profile-content .tab-body.review .box-reviews .box-header .inner-tab-header.active {
  border-bottom: 3px solid #02cd8d;
  color: #585858;
}

#vendor-profile-content .tab-body.review .box-reviews .box-body .inner-tab-body {
  border: 1px solid #d4f0e3;
  background-color: #FFFFFF;
  font-family: "Roboto", sans-serif;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s .5s, opacity .5s linear;
  height: 0;
  overflow: hidden;
}

#vendor-profile-content .tab-body.review .box-reviews .box-body .inner-tab-body.active {
  overflow: visible;
  height: auto;
  visibility: visible;
  opacity: 1;
  transition: opacity .5s linear;
  padding: 0 25px 15px;
}

#vendor-profile-content .tab-body.review .box-reviews .box-body .inner-tab-body .box-review {
  cursor: pointer;
}

#vendor-profile-content .tab-body.review .box-reviews .box-body .inner-tab-body .box-review .box-body {
  padding-top: 30px;
  padding-bottom: 30px;
  min-height: 110px;
}

#vendor-profile-content .tab-body.review .box-reviews .box-body .inner-tab-body .box-review .box-body .info-table {
  display: table;
  width: 100%;
}

#vendor-profile-content .tab-body.review .box-reviews .box-body .inner-tab-body .box-review .box-body .info-table .info-row {
  display: table-row;
}

#vendor-profile-content .tab-body.review .box-reviews .box-body .inner-tab-body .box-review .box-body .info-table .info-row > [class^=col] {
  display: table-cell;
  padding-left: 15px;
  padding-right: 15px;
  vertical-align: top;
}

#vendor-profile-content .tab-body.review .box-reviews .box-body .inner-tab-body .box-review .box-body .info-table .info-row > [class^=col]:first-child {
  padding-left: 0;
}

#vendor-profile-content .tab-body.review .box-reviews .box-body .inner-tab-body .box-review .box-body .info-table .info-row > [class^=col]:last-child {
  padding-right: 0;
}

#vendor-profile-content .tab-body.review .box-reviews .box-body .inner-tab-body .box-review .box-body .info-table .info-row .col-logo {
  width: 80px;
}

#vendor-profile-content .tab-body.review .box-reviews .box-body .inner-tab-body .box-review .box-body .info-table .info-row .col-logo img {
  max-width: 65px;
  max-height: 65px;
}

#vendor-profile-content .tab-body.review .box-reviews .box-body .inner-tab-body .box-review .box-body .info-table .info-row .col-detail {
  width: 120px;
}

#vendor-profile-content .tab-body.review .box-reviews .box-body .inner-tab-body .box-review .box-body .info-table .info-row .col-detail .title {
  color: #585858;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  margin-bottom: 10px;
  line-height: 1.6;
}

#vendor-profile-content .tab-body.review .box-reviews .box-body .inner-tab-body .box-review .box-body .info-table .info-row .col-detail .date {
  color: #585858;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
}

#vendor-profile-content .tab-body.review .box-reviews .box-body .inner-tab-body .box-review .box-body .info-table .info-row .col-main .title {
  color: #585858;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  margin-bottom: 10px;
  line-height: 1;
}

#vendor-profile-content .tab-body.review .box-reviews .box-body .inner-tab-body .box-review .box-body .info-table .info-row .col-main .content {
  color: #999999;
}

#vendor-profile-content .tab-body.review .box-reviews .box-body .inner-tab-body .box-review .box-body .info-table .info-row .col-action {
  vertical-align: middle;
  width: 145px;
}

#vendor-profile-content .tab-body.review .box-reviews .box-body .inner-tab-body .box-review .box-body .info-table .info-row .col-action .view {
  border: 1px solid #585858;
  border-radius: 4px;
  color: #585858;
  display: inline-block;
  width: 100%;
  padding: 9px 0;
  font-size: 12px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}

#vendor-profile-content .tab-body.review .box-reviews .box-body .inner-tab-body .box-review .box-body .info-table .info-row .col-action .view:hover {
  text-decoration: none;
}

#vendor-profile-content .tab-body.review .box-reviews .box-body .inner-tab-body .box-review .box-body .info-table .info-row .col-action .write {
  display: inline-block;
  width: 100%;
  padding: 9px 0;
  font-size: 12px;
  text-align: center;
  text-decoration: none;
}

#vendor-profile-content .tab-body.review .box-reviews .box-body .inner-tab-body .box-review:not(:first-child) {
  border-top: 1px solid #d4f0e3;
}

#vendor-profile-content .tab-body .box {
  border: 1px solid #d4f0e3;
  margin-bottom: 20px;
}

#vendor-profile-content .tab-body .box .box-heading {
  padding: 25px 25px;
  background-color: #ccd9e0;
  color: #585858;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: bold;
}

#vendor-profile-content .tab-body .box .box-body {
  padding: 15px 25px;
  background-color: #FFFFFF;
  font-family: "Roboto", sans-serif;
}

#vendor-profile-content .tab-body .box .box-footer {
  border-top: 1px solid #d4f0e3;
  padding: 15px 25px;
  background-color: #FFFFFF;
}

#vendor-profile-content .tab-body .checkboxes input[type=checkbox] {
  visibility: hidden;
  opacity: 0;
}

#vendor-profile-content .tab-body .checkboxes label {
  display: block;
  margin: 0 0 10px;
  font-size: 12px;
  cursor: pointer;
}

#vendor-profile-content .tab-body .checkboxes label span {
  display: inline-block;
  vertical-align: middle;
}

#vendor-profile-content .tab-body .checkboxes label span.checkbox {
  border: 1px solid #d4f0e3;
  background-color: #d8f1e6;
  border-radius: 2px;
  margin: 0 10px 0 0;
  width: 20px;
  height: 20px;
  position: relative;
  transition: background-color .3s;
}

#vendor-profile-content .tab-body .checkboxes label span.checkbox input {
  visibility: hidden;
  opacity: 0;
}

#vendor-profile-content .tab-body .checkboxes label.checked span.checkbox {
  background-color: #FFFFFF;
  border: 1px solid #02cd8d;
}

#vendor-profile-content .tab-body .checkboxes label.checked span.checkbox:before {
  top: 50%;
  left: 50%;
  content: '';
  position: absolute;
  height: 6px;
  width: 15px;
  border-left: 2px solid #02cd8d;
  border-bottom: 2px solid #02cd8d;
  display: block;
  margin: 0 auto;
  transform: translate(-50%, -55%) rotate(-45deg);
  -webkit-transform: translate(-50%, -55%) rotate(-45deg);
  -moz-transform: translate(-50%, -55%) rotate(-45deg);
  -ms-transform: translate(-50%, -55%) rotate(-45deg);
  -o-transform: translate(-50%, -55%) rotate(-45deg);
}

#vendor-profile-content .tab-body .checkboxes label:hover:not(.checked) span.checkbox {
  background-color: #d4f0e3;
}

#vendor-profile-content .tab-body .checkboxes label:hover.checked span.checkbox {
  background-color: #d8f1e6;
}

#vendor-profile-content .tab-body .checkboxes label:hover.checked span.checkbox:before {
  border-left: 2px solid #02cd8d;
  border-bottom: 2px solid #02cd8d;
}

#vendor-profile-content .tab-body .checkboxes input[type=text] {
  display: inline-block;
  width: auto;
}

#vendor-profile-content .tab-body .checkboxes .checkbox-group {
  margin-bottom: 10px;
}

#vendor-profile-content .tab-body .checkboxes .checkbox-group .checkbox-group {
  padding-left: 35px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s .5s, opacity .5s linear;
  height: 0;
  overflow: hidden;
  margin-bottom: 0;
}

#vendor-profile-content .tab-body .checkboxes .checkbox-group .checkbox-group.active {
  margin-bottom: 10px;
  overflow: visible;
  height: auto;
  visibility: visible;
  opacity: 1;
  transition: opacity .5s linear;
}

#vendor-profile-content .tab-body .checkboxes .checkbox-group label {
  margin-bottom: 0;
}

#vendor-profile-content .tab-body .checkboxes .checkbox-group .extend {
  display: inline-block;
  margin-left: 10px;
  color: #02cd8d;
  font-size: 24px;
  font-weight: bold;
}

#popup-past-work-form {
  padding-top: 60px;
  padding-bottom: 90px;
}

#popup-past-work-form .popup-heading {
  max-width: 100%;
  width: 700px;
  margin: 0 auto;
  position: relative;
}

#popup-past-work-form .popup-heading h3 {
  font-family: "roboto";
  font-weight: 500;
  color: #28F282;
  font-size: 24px;
  margin: 0 0 20px;
  line-height: 1.5;
  text-align: left;
}

#popup-past-work-form .popup-heading .mfp-close {
  background-image: url("../../images/root/popup/maginific-close.png");
  width: 20px;
  height: 20px;
  right: 10px;
  top: 0;
  opacity: 1;
  cursor: pointer;
}

#popup-past-work-form .popup-content {
  max-width: 100%;
  width: 700px;
  margin: 0 auto;
  font-family: "roboto";
  color: #18293e;
}

#popup-past-work-form .popup-content .content-body {
  background-color: #fff;
  padding: 30px 20px;
}

#popup-past-work-form .popup-content .content-body .row {
  margin-top: 12px;
}

#popup-past-work-form .popup-content .content-body .row.item-row {
  border-top: 1px solid #d4f0e3;
}

#popup-past-work-form .popup-content .content-body .row.item-row:last-child {
  padding-bottom: 10px;
  border-bottom: 1px solid #d4f0e3;
}

#popup-past-work-form .popup-content .content-body .row.item-row .label-control {
  padding-top: 10px;
  font-weight: 700;
  font-size: 16px;
}

#popup-past-work-form .popup-content .content-body .row.item-row .label-control .red {
  color: red;
}

#popup-past-work-form .popup-content .content-body .error-text {
  display: none;
  color: red;
}

#popup-past-work-form .popup-content .content-body .has-error-row {
  border: none;
}

#popup-past-work-form .popup-content .content-body .has-error-row input[type=text] {
  border-color: red !important;
}

#popup-past-work-form .popup-content .content-body .has-error-row .error-text {
  display: block;
}

#popup-past-work-form .popup-content .content-body .SumoSelect > .CaptionCont {
  margin: 0;
  padding: 10px 20px;
}

#popup-past-work-form .popup-content .content-body .label-control {
  font-weight: 700;
  font-size: 16px;
  padding-left: 30px;
}

#popup-past-work-form .popup-content .content-body .label-control .red {
  color: red;
}

#popup-past-work-form .popup-content .content-body .label-control + [class^=col] {
  padding-right: 30px;
}

#popup-past-work-form .popup-content .content-body .type-radios {
  margin-left: -4px;
}

#popup-past-work-form .popup-content .content-body .type-radios .radio {
  margin-left: 4px;
  font-weight: normal;
  display: inline-block;
  padding: 5px 12px;
  border-radius: 15px;
  background-color: #e1e3e3;
  border: 2px solid #e1e3e3;
  cursor: pointer;
}

#popup-past-work-form .popup-content .content-body .type-radios .radio.checked {
  border: 2px solid #02cd8d;
}

#popup-past-work-form .popup-content .content-body .type-radios .radio input {
  display: none;
}

#popup-past-work-form .popup-content .content-body .type-content {
  display: none;
}

#popup-past-work-form .popup-content .content-body .type-content .helper {
  color: #9C9C9C;
  font-size: 10px;
  margin-bottom: 10px;
}

#popup-past-work-form .popup-content .content-body .type-content .btn-add-past-work-image {
  cursor: pointer;
  background-color: #02cd8d;
  border-radius: 3px;
  padding: 10px 20px;
  font-size: 14px;
  display: inline-block;
  color: #FFFFFF;
}

#popup-past-work-form .popup-content .content-body .type-content .btn-add-past-work-image.disabled {
  cursor: context-menu;
  opacity: 0.7;
}

#popup-past-work-form .popup-content .content-body .type-content.image .image-lists {
  list-style: none;
  padding: 0;
  margin: 0;
}

#popup-past-work-form .popup-content .content-body .type-content.image .image-lists .image-row {
  margin-bottom: 15px;
  position: relative;
}

#popup-past-work-form .popup-content .content-body .type-content.image .image-lists .image-row .file-input-wrapper input {
  display: none;
}

#popup-past-work-form .popup-content .content-body .type-content.image .image-lists .image-row .image-row-inner {
  position: relative;
  padding-left: 100px;
  padding-right: 60px;
}

#popup-past-work-form .popup-content .content-body .type-content.image .image-lists .image-row .image-row-inner .image-wrapper {
  position: absolute;
  left: 0;
  width: 95px;
  height: 100%;
  overflow: hidden;
  border-radius: 4px;
}

#popup-past-work-form .popup-content .content-body .type-content.image .image-lists .image-row .image-row-inner .image-wrapper img {
  max-width: 100%;
}

#popup-past-work-form .popup-content .content-body .type-content.image .image-lists .image-row .image-row-inner .input-wrapper {
  width: 100%;
}

#popup-past-work-form .popup-content .content-body .type-content.image .image-lists .image-row .image-row-inner .remove-wrapper {
  position: absolute;
  top: 0;
  height: 100%;
  right: 10px;
  width: 40px;
}

#popup-past-work-form .popup-content .content-body .type-content.image .image-lists .image-row .image-row-inner .remove-wrapper .btn-remove {
  background-image: url("../../images/root/client/business-profile/portfolio/remove.png");
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
  position: absolute;
  height: 80%;
  width: 20px;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
}

#popup-past-work-form .popup-content .content-body .type-content.image .image-lists .image-row .image-row-inner:after {
  content: '';
  display: block;
  clear: both;
}

#popup-past-work-form .popup-content .content-body .type-content.image .image-lists li.placeholder {
  position: relative;
  margin: 0;
  padding: 0;
  border: none;
}

#popup-past-work-form .popup-content .content-body .type-content.image .image-lists li.placeholder:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  margin-top: -5px;
  left: -5px;
  top: -4px;
  border: 5px solid transparent;
  border-left-color: #FF0000;
  border-right: none;
}

#popup-past-work-form .popup-content .content-body .type-content.youtube .input-with-append {
  padding-right: 68px;
  position: relative;
}

#popup-past-work-form .popup-content .content-body .type-content.youtube .input-with-append .btn-add-past-work-youtube {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  background-color: #02cd8d;
  border-radius: 3px;
  padding: 10px 20px;
  font-size: 14px;
  display: inline-block;
  color: #FFFFFF;
}

#popup-past-work-form .popup-content .content-body .type-content.youtube .youtube-lists {
  list-style: none;
  padding: 0;
  margin: 0;
}

#popup-past-work-form .popup-content .content-body .type-content.youtube .youtube-lists li.placeholder {
  position: relative;
  margin: 0;
  padding: 0;
  border: none;
}

#popup-past-work-form .popup-content .content-body .type-content.youtube .youtube-lists li.placeholder:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  margin-top: -5px;
  left: -5px;
  top: -4px;
  border: 5px solid transparent;
  border-left-color: #FF0000;
  border-right: none;
}

#popup-past-work-form .popup-content .content-body .type-content.youtube .youtube-lists .youtube-row {
  margin-top: 15px;
  position: relative;
  padding-left: 100px;
  padding-right: 60px;
}

#popup-past-work-form .popup-content .content-body .type-content.youtube .youtube-lists .youtube-row .image-wrapper {
  position: absolute;
  left: 0;
  width: 95px;
  height: 100%;
  overflow: hidden;
  border-radius: 4px;
}

#popup-past-work-form .popup-content .content-body .type-content.youtube .youtube-lists .youtube-row .image-wrapper img {
  max-width: 100%;
}

#popup-past-work-form .popup-content .content-body .type-content.youtube .youtube-lists .youtube-row .input-wrapper {
  width: 100%;
}

#popup-past-work-form .popup-content .content-body .type-content.youtube .youtube-lists .youtube-row .remove-wrapper {
  position: absolute;
  top: 0;
  height: 100%;
  right: 10px;
  width: 40px;
}

#popup-past-work-form .popup-content .content-body .type-content.youtube .youtube-lists .youtube-row .remove-wrapper .btn-remove {
  background-image: url("../../images/root/client/business-profile/portfolio/remove.png");
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
  position: absolute;
  height: 80%;
  width: 20px;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
}

#popup-past-work-form .popup-content .content-body .type-content.youtube .youtube-lists .youtube-row:after {
  content: '';
  display: block;
  clear: both;
}

#popup-past-work-form .popup-content .content-body .type-content.vimeo .input-with-append {
  padding-right: 68px;
  position: relative;
}

#popup-past-work-form .popup-content .content-body .type-content.vimeo .input-with-append .btn-add-past-work-vimeo {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  background-color: #02cd8d;
  border-radius: 3px;
  padding: 10px 20px;
  font-size: 14px;
  display: inline-block;
  color: #FFFFFF;
}

#popup-past-work-form .popup-content .content-body .type-content.vimeo .vimeo-lists {
  list-style: none;
  padding: 0;
  margin: 0;
}

#popup-past-work-form .popup-content .content-body .type-content.vimeo .vimeo-lists li.placeholder {
  position: relative;
  margin: 0;
  padding: 0;
  border: none;
}

#popup-past-work-form .popup-content .content-body .type-content.vimeo .vimeo-lists li.placeholder:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  margin-top: -5px;
  left: -5px;
  top: -4px;
  border: 5px solid transparent;
  border-left-color: #FF0000;
  border-right: none;
}

#popup-past-work-form .popup-content .content-body .type-content.vimeo .vimeo-lists .vimeo-row {
  margin-top: 15px;
  position: relative;
  padding-left: 100px;
  padding-right: 60px;
}

#popup-past-work-form .popup-content .content-body .type-content.vimeo .vimeo-lists .vimeo-row .image-wrapper {
  position: absolute;
  left: 0;
  width: 95px;
  height: 100%;
  overflow: hidden;
  border-radius: 4px;
}

#popup-past-work-form .popup-content .content-body .type-content.vimeo .vimeo-lists .vimeo-row .image-wrapper img {
  max-width: 100%;
}

#popup-past-work-form .popup-content .content-body .type-content.vimeo .vimeo-lists .vimeo-row .input-wrapper {
  width: 100%;
}

#popup-past-work-form .popup-content .content-body .type-content.vimeo .vimeo-lists .vimeo-row .remove-wrapper {
  position: absolute;
  top: 0;
  height: 100%;
  right: 10px;
  width: 40px;
}

#popup-past-work-form .popup-content .content-body .type-content.vimeo .vimeo-lists .vimeo-row .remove-wrapper .btn-remove {
  background-image: url("../../images/root/client/business-profile/portfolio/remove.png");
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
  position: absolute;
  height: 80%;
  width: 20px;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
}

#popup-past-work-form .popup-content .content-body .type-content.vimeo .vimeo-lists .vimeo-row:after {
  content: '';
  display: block;
  clear: both;
}

#popup-past-work-form .popup-content .content-body .type-content.opened {
  display: block;
}

#popup-past-work-form .popup-content .content-body input.form-control, #popup-past-work-form .popup-content .content-body textarea.form-control {
  border: 1px solid #e1e3e3;
  border-radius: 4px;
  padding: 10px 20px;
  line-height: 1;
  outline: 0;
  font-size: 14px;
  height: auto;
  color: #18293e;
  box-shadow: none;
  font-family: "Roboto", sans-serif;
  background-color: #FFFFFF;
  font-weight: normal;
}

#popup-past-work-form .popup-content .content-body input.form-control:focus, #popup-past-work-form .popup-content .content-body textarea.form-control:focus {
  box-shadow: none;
  border: 1px solid #e1e3e3;
  outline: 0;
}

#popup-past-work-form .popup-content .content-body input.form-control:disabled, #popup-past-work-form .popup-content .content-body textarea.form-control:disabled {
  background-color: #e1e3e3;
}

#popup-past-work-form .popup-content .content-body textarea.form-control {
  min-height: 180px;
}

#popup-past-work-form .popup-content .content-body .twp-select-box {
  position: relative;
}

#popup-past-work-form .popup-content .content-body .twp-select-box .placeholder {
  border-radius: 4px;
  padding: 10px 20px;
  line-height: 1;
  outline: 0;
  font-size: 14px;
  height: auto;
  color: #585858;
  box-shadow: none;
  font-family: "Roboto", sans-serif;
  background-color: #FFFFFF;
  font-weight: normal;
  cursor: pointer;
  border: 1px solid #e1e3e3;
  -moz-appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, #02cd8d 50%), linear-gradient(135deg, #02cd8d 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px);
  background-size: 5px 5px, 5px 5px;
  background-repeat: no-repeat;
}

#popup-past-work-form .popup-content .content-body .twp-select-box .placeholder i.count {
  font-weight: normal;
}

#popup-past-work-form .popup-content .content-body .twp-select-box .placeholder.active {
  border: 1px solid #02cd8d;
}

#popup-past-work-form .popup-content .content-body .twp-select-box .options {
  display: none;
  position: absolute;
  top: 100%;
  width: 100%;
  padding: 20px;
  z-index: 2;
  left: 0;
  background-color: #FFFFFF;
  box-shadow: 0px 2px 5px 1px #ccc;
  max-height: 70vh;
  overflow: auto;
}

#popup-past-work-form .popup-content .content-body .twp-select-box .options ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

#popup-past-work-form .popup-content .content-body .twp-select-box .options ul li {
  position: relative;
  margin-bottom: 10px;
}

#popup-past-work-form .popup-content .content-body .twp-select-box .options ul li i.arrow {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
}

#popup-past-work-form .popup-content .content-body .twp-select-box .options ul li label.checkbox {
  position: absolute;
  top: 2px;
  left: 0;
  width: 13px;
  height: 13px;
  border: 1px solid #dbdbdb;
  border-radius: 1px;
  margin: 0;
  cursor: pointer;
}

#popup-past-work-form .popup-content .content-body .twp-select-box .options ul li label.checkbox input[type=checkbox] {
  visibility: hidden;
  opacity: 0;
}

#popup-past-work-form .popup-content .content-body .twp-select-box .options ul li label.checkbox:after {
  content: "";
  display: block;
  position: absolute;
  width: 9px;
  height: 5px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  top: 2px;
  left: 1px;
  opacity: 0;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  transition: opacity .1s;
}

#popup-past-work-form .popup-content .content-body .twp-select-box .options ul li label.checkbox.checked {
  background-color: #02cd8d;
  border: 1px solid #02cd8d;
}

#popup-past-work-form .popup-content .content-body .twp-select-box .options ul li label.checkbox.checked:after {
  opacity: 1;
}

#popup-past-work-form .popup-content .content-body .twp-select-box .options ul li label.checkbox:hover {
  border: 1px solid #585858;
}

#popup-past-work-form .popup-content .content-body .twp-select-box .options ul li a {
  font-size: 14px;
  color: #585858;
  text-decoration: none;
  cursor: pointer;
}

#popup-past-work-form .popup-content .content-body .twp-select-box .options ul li i.count {
  line-height: 16px;
  height: 16px;
  width: 26px;
  text-align: center;
  color: #FFFFFF;
  background-color: #02cd8d;
  border-radius: 3px;
  display: inline-block;
  margin-left: 15px;
  font-style: normal;
}

#popup-past-work-form .popup-content .content-body .twp-select-box .options ul li ul {
  margin-top: 5px;
  margin-bottom: 5px;
}

#popup-past-work-form .popup-content .content-body .twp-select-box .options ul li ul li {
  padding-left: 0;
  padding-right: 30px;
}

#popup-past-work-form .popup-content .content-body .twp-select-box .options ul li ul li label.checkbox {
  left: initial;
  right: 15px;
}

#popup-past-work-form .popup-content .content-body .twp-select-box .options ul li ul li a {
  font-size: 13px;
}

#popup-past-work-form .popup-content .content-body .twp-select-box .options ul li ul.active {
  display: block;
}

#popup-past-work-form .popup-content .content-body .twp-select-box .options.active {
  display: block;
}

#popup-past-work-form .popup-content .content-body .twp-select-box.categories ul li {
  padding-left: 20px;
}

#popup-past-work-form .popup-content .content-body .twp-select-box.categories ul li ul {
  display: none;
  padding-left: 15px;
}

#popup-past-work-form .popup-content .content-body .twp-select-box.services.wide .options, #popup-past-work-form .popup-content .content-body .twp-select-box.styles.wide .options, #popup-past-work-form .popup-content .content-body .twp-select-box.types.wide .options, #popup-past-work-form .popup-content .content-body .twp-select-box.platforms.wide .options, #popup-past-work-form .popup-content .content-body .twp-select-box.tech.wide .options {
  width: 700px;
}

@media (max-width: 1024px) {
  #popup-past-work-form .popup-content .content-body .twp-select-box.services.wide .options, #popup-past-work-form .popup-content .content-body .twp-select-box.styles.wide .options, #popup-past-work-form .popup-content .content-body .twp-select-box.types.wide .options, #popup-past-work-form .popup-content .content-body .twp-select-box.platforms.wide .options, #popup-past-work-form .popup-content .content-body .twp-select-box.tech.wide .options {
    width: 640px;
  }
}

@media (max-width: 767px) {
  #popup-past-work-form .popup-content .content-body .twp-select-box.services.wide .options, #popup-past-work-form .popup-content .content-body .twp-select-box.styles.wide .options, #popup-past-work-form .popup-content .content-body .twp-select-box.types.wide .options, #popup-past-work-form .popup-content .content-body .twp-select-box.platforms.wide .options, #popup-past-work-form .popup-content .content-body .twp-select-box.tech.wide .options {
    width: 100%;
  }
}

#popup-past-work-form .popup-content .content-body .twp-select-box.services .options ul > li, #popup-past-work-form .popup-content .content-body .twp-select-box.styles .options ul > li, #popup-past-work-form .popup-content .content-body .twp-select-box.types .options ul > li, #popup-past-work-form .popup-content .content-body .twp-select-box.platforms .options ul > li, #popup-past-work-form .popup-content .content-body .twp-select-box.tech .options ul > li {
  margin-bottom: 20px;
}

#popup-past-work-form .popup-content .content-body .twp-select-box.services .options ul > li > a, #popup-past-work-form .popup-content .content-body .twp-select-box.styles .options ul > li > a, #popup-past-work-form .popup-content .content-body .twp-select-box.types .options ul > li > a, #popup-past-work-form .popup-content .content-body .twp-select-box.platforms .options ul > li > a, #popup-past-work-form .popup-content .content-body .twp-select-box.tech .options ul > li > a {
  font-size: 16px;
  font-weight: 700;
}

#popup-past-work-form .popup-content .content-body .twp-select-box.services .options ul > li ul, #popup-past-work-form .popup-content .content-body .twp-select-box.styles .options ul > li ul, #popup-past-work-form .popup-content .content-body .twp-select-box.types .options ul > li ul, #popup-past-work-form .popup-content .content-body .twp-select-box.platforms .options ul > li ul, #popup-past-work-form .popup-content .content-body .twp-select-box.tech .options ul > li ul {
  margin-top: 5px;
}

#popup-past-work-form .popup-content .content-body .twp-select-box.services .options ul > li ul li a, #popup-past-work-form .popup-content .content-body .twp-select-box.styles .options ul > li ul li a, #popup-past-work-form .popup-content .content-body .twp-select-box.types .options ul > li ul li a, #popup-past-work-form .popup-content .content-body .twp-select-box.platforms .options ul > li ul li a, #popup-past-work-form .popup-content .content-body .twp-select-box.tech .options ul > li ul li a {
  font-weight: 400;
  font-size: 13px;
}

@media (min-width: 769px) {
  #popup-past-work-form .popup-content .content-body .twp-select-box.services .options .col-sm-6:first-child, #popup-past-work-form .popup-content .content-body .twp-select-box.styles .options .col-sm-6:first-child, #popup-past-work-form .popup-content .content-body .twp-select-box.types .options .col-sm-6:first-child, #popup-past-work-form .popup-content .content-body .twp-select-box.platforms .options .col-sm-6:first-child, #popup-past-work-form .popup-content .content-body .twp-select-box.tech .options .col-sm-6:first-child {
    border-right: 1px solid #e1e3e3;
  }
}

#popup-past-work-form .popup-content .content-body .button_add_past_work_item {
  margin-top: 20px;
  margin-left: 15px;
  color: #02cd8d;
  cursor: pointer;
  display: inline-block;
}

#popup-past-work-form .popup-content .content-footer {
  position: relative;
  padding: 25px 0 0 0;
}

#popup-past-work-form .popup-content .content-footer .add-buttons,
#popup-past-work-form .popup-content .content-footer .update-buttons {
  display: none;
}

#popup-past-work-form .popup-content .content-footer .add-buttons .btn-type-1,
#popup-past-work-form .popup-content .content-footer .add-buttons .btn-type-2,
#popup-past-work-form .popup-content .content-footer .update-buttons .btn-type-1,
#popup-past-work-form .popup-content .content-footer .update-buttons .btn-type-2 {
  padding: 10px 45px;
}

#popup-past-work-form .popup-content .content-footer .add-buttons.active,
#popup-past-work-form .popup-content .content-footer .update-buttons.active {
  display: block;
}

#popup-past-work-form .popup-content .content-footer button {
  margin-right: 25px;
  float: left;
}

#popup-vendor-profile-submit {
  padding-top: 30px;
  padding-bottom: 30px;
}

#popup-vendor-profile-submit .popup-heading {
  max-width: 100%;
  width: 1075px;
  padding-left: 130px;
  padding-right: 130px;
  margin: 0 auto;
  text-align: center;
  position: relative;
}

#popup-vendor-profile-submit .popup-heading h3 {
  font-family: "roboto";
  font-weight: 500;
  color: #28F282;
  font-size: 24px;
  margin: 0 0 20px;
  line-height: 1.5;
}

#popup-vendor-profile-submit .popup-heading .mfp-close {
  background-image: url("../../images/root/popup/maginific-close.png");
  width: 20px;
  height: 20px;
  right: 10px;
  top: 0;
  opacity: 1;
  cursor: pointer;
}

#popup-vendor-profile-submit .popup-content {
  max-width: 100%;
  width: 1075px;
  margin: 0 auto;
}

#popup-vendor-profile-submit .popup-content .content-body {
  background-color: #ffffff;
  border-radius: 4px 4px 0 0;
}

#popup-vendor-profile-submit .popup-content .content-body [class^=schedule_] {
  border-radius: 10px;
  background-color: #ffffff;
}

#popup-vendor-profile-submit .popup-content .content-body [class^=schedule_] .top {
  text-align: center;
  line-height: 20px;
  padding: 30px;
  border-bottom: 3px solid #f2f2f2;
}

#popup-vendor-profile-submit .popup-content .content-body [class^=schedule_] .top .title {
  font-size: 16px;
  padding-bottom: 30px;
  font-weight: bold;
}

#popup-vendor-profile-submit .popup-content .content-body [class^=schedule_] .top .title span {
  font-size: 24px;
}

#popup-vendor-profile-submit .popup-content .content-body [class^=schedule_] .top .service {
  font-size: 24px;
}

#popup-vendor-profile-submit .popup-content .content-body [class^=schedule_] .top .service span {
  font-size: 12px;
}

#popup-vendor-profile-submit .popup-content .content-body [class^=schedule_] .bottom {
  text-align: center;
  padding: 30px;
}

#popup-vendor-profile-submit .popup-content .content-body [class^=schedule_] .bottom .value-title, #popup-vendor-profile-submit .popup-content .content-body [class^=schedule_] .bottom .rate-title {
  padding-bottom: 15px;
  font-weight: bold;
  font-size: 13px;
}

#popup-vendor-profile-submit .popup-content .content-body [class^=schedule_] .bottom .answer {
  font-size: 14px;
}

#popup-vendor-profile-submit .popup-content .content-body [class^=schedule_] .line {
  margin: 30px 0;
  height: 2px;
}

#popup-vendor-profile-submit .popup-content .content-body .schedule_1 {
  border: 3px solid #485cec;
}

#popup-vendor-profile-submit .popup-content .content-body .schedule_1 .top .title {
  color: #485cec;
}

#popup-vendor-profile-submit .popup-content .content-body .schedule_1 .bottom {
  text-align: left;
}

#popup-vendor-profile-submit .popup-content .content-body .schedule_1 .bottom .value-title, #popup-vendor-profile-submit .popup-content .content-body .schedule_1 .bottom .rate-title {
  color: #485cec;
}

#popup-vendor-profile-submit .popup-content .content-body .schedule_1 .line {
  background-color: #485cec;
}

#popup-vendor-profile-submit .popup-content .content-body .schedule_1 .example {
  margin: 0px 15px 30px;
  background-color: #485cec;
  border-radius: 10px;
}

#popup-vendor-profile-submit .popup-content .content-body .schedule_1 .example .content {
  color: #ffffff;
  padding: 20px 15px;
  font-size: 14px;
}

#popup-vendor-profile-submit .popup-content .content-body .schedule_1 .example .content .title {
  font-size: 16px;
  padding-bottom: 15px;
}

#popup-vendor-profile-submit .popup-content .content-body .schedule_1 .example .content .last {
  padding-top: 5px;
}

#popup-vendor-profile-submit .popup-content .content-body .schedule_2 {
  border: 3px solid #ec6348;
}

#popup-vendor-profile-submit .popup-content .content-body .schedule_2 .top .title {
  color: #ec6348;
}

#popup-vendor-profile-submit .popup-content .content-body .schedule_2 .bottom .value-title, #popup-vendor-profile-submit .popup-content .content-body .schedule_2 .bottom .rate-title {
  color: #ec6348;
}

#popup-vendor-profile-submit .popup-content .content-body .schedule_2 .line {
  background-color: #ec6348;
}

#popup-vendor-profile-submit .popup-content .content-body .schedule_3 {
  border: 3px solid #16bc9f;
}

#popup-vendor-profile-submit .popup-content .content-body .schedule_3 .top .title {
  color: #16bc9f;
}

#popup-vendor-profile-submit .popup-content .content-body .schedule_3 .bottom .value-title, #popup-vendor-profile-submit .popup-content .content-body .schedule_3 .bottom .rate-title {
  color: #16bc9f;
}

#popup-vendor-profile-submit .popup-content .content-body .schedule_3 .line {
  background-color: #16bc9f;
}

#popup-vendor-profile-submit .popup-content .content-body .note {
  width: 100%;
  margin-top: 30px;
  padding: 40px 60px;
  font-size: 16px;
  font-weight: 300;
  background-color: #29f2b1;
}

#popup-vendor-profile-submit .popup-content .content-body .note span {
  color: #485cec;
  font-size: 12px;
}

#popup-vendor-profile-submit .popup-content .content-footer {
  padding: 20px 0;
  background-color: #ebebec;
  border-radius: 0 0 4px 4px;
}

#popup-vendor-profile-submit .popup-content .content-footer .term {
  padding: 0 30px;
}

#popup-vendor-profile-submit .popup-content .content-footer .term .label-checkbox {
  font-family: "roboto";
  font-size: 16px;
  font-weight: 300;
  padding-left: 30px;
  line-height: 20px;
  position: relative;
  cursor: pointer;
}

#popup-vendor-profile-submit .popup-content .content-footer .term .label-checkbox input[type=checkbox] {
  position: absolute;
  opacity: 0;
  width: 20px;
  height: 20px;
  left: 0;
  top: 0;
  margin: 0;
}

#popup-vendor-profile-submit .popup-content .content-footer .term .label-checkbox input[type=checkbox]:checked + .checkbox {
  border-color: #02CD8D;
}

#popup-vendor-profile-submit .popup-content .content-footer .term .label-checkbox input[type=checkbox]:checked + .checkbox:after {
  opacity: 1 !important;
  border-color: #02CD8D;
}

#popup-vendor-profile-submit .popup-content .content-footer .term .label-checkbox input[type=checkbox]:hover + .checkbox {
  border-color: #02CD8D;
}

#popup-vendor-profile-submit .popup-content .content-footer .term .label-checkbox input[type=checkbox]:hover + .checkbox:after {
  opacity: 0.5;
}

#popup-vendor-profile-submit .popup-content .content-footer .term .label-checkbox .checkbox {
  border: 2px solid #bbb;
  margin: 0;
  width: 20px;
  height: 20px;
  position: absolute;
  left: 0;
  top: 0;
  transition: border-color 0.3s;
}

#popup-vendor-profile-submit .popup-content .content-footer .term .label-checkbox .checkbox.error {
  border-color: red;
}

#popup-vendor-profile-submit .popup-content .content-footer .term .label-checkbox .checkbox:after {
  display: block;
  opacity: 0;
  content: '';
  height: 5px;
  width: 15px;
  position: absolute;
  border-bottom: 2px solid #bbb;
  border-left: 2px solid #bbb;
  /* IE 9 */
  /* Chrome, Safari, Opera */
  transform: rotate(-45deg) translate(-50%, -50%);
  left: 50%;
  transition: opacity 0.3s, border-color 0.3s;
}

#popup-vendor-profile-submit .popup-content .content-footer .footer-button {
  margin-top: 20px;
  background-color: #02cd8d;
  padding: 15px 0;
  width: 100%;
  color: #fff;
  text-align: center;
  cursor: pointer;
  font-family: "roboto";
  font-weight: 700;
  font-size: 18px;
  border: none;
  box-shadow: none;
  outline: 0;
  transition: background-color .1s, opacity 1s;
  display: none;
  opacity: 0;
}

#popup-vendor-profile-submit .popup-content .content-footer .footer-button.active {
  opacity: 1;
  display: block;
}

#popup-vendor-profile-submit .popup-content .content-footer .footer-button:not(:disabled):active {
  background-color: rgba(2, 205, 141, 0.8);
}

@media only screen and (max-width: 767px) {
  #popup-vendor-profile-submit {
    padding-top: 20px;
  }
  #popup-vendor-profile-submit .popup-heading {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }
  #popup-vendor-profile-submit .popup-heading h3 {
    font-size: 16px;
  }
  #popup-vendor-profile-submit .popup-content {
    padding-left: 20px;
    padding-right: 20px;
  }
  #popup-vendor-profile-submit .popup-content .content-body {
    padding: 25px;
  }
  #popup-vendor-profile-submit .popup-content .content-body .table-schedule {
    padding: 10px 0 10px 0;
    position: relative;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
  }
  #popup-vendor-profile-submit .popup-content .content-body .table-schedule:before, #popup-vendor-profile-submit .popup-content .content-body .table-schedule:after {
    content: "";
    position: absolute;
    z-index: -1;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
    top: 10px;
    bottom: 10px;
    left: 0;
    right: 0;
    border-radius: 100px / 10px;
  }
  #popup-vendor-profile-submit .popup-content .content-body .table-schedule:after {
    right: 10px;
    left: auto;
    transform: skew(8deg) rotate(3deg);
  }
  #popup-vendor-profile-submit .popup-content .content-body .table-schedule .center-box {
    padding: 0px !important;
  }
  #popup-vendor-profile-submit .popup-content .content-body .table-schedule .center-box [class^=schedule_] {
    margin-bottom: 5px;
  }
  #popup-vendor-profile-submit .popup-content .content-body .table-schedule .center-box [class^=schedule_] .top {
    padding: 15px;
  }
  #popup-vendor-profile-submit .popup-content .content-body .table-schedule .center-box [class^=schedule_] .bottom {
    padding: 15px;
  }
  #popup-vendor-profile-submit .popup-content .content-body .table-schedule .center-box [class^=schedule_] .line {
    margin: 30px 0;
    height: 2px;
  }
  #popup-vendor-profile-submit .popup-content .content-body .table-schedule .center-box .schedule_1 .example .content {
    padding: 20px 10px;
    font-size: 12px;
  }
  #popup-vendor-profile-submit .popup-content .content-body .table-schedule .center-box .schedule_1 .example .content .title {
    font-size: 14px;
  }
  #popup-vendor-profile-submit .popup-content .content-body .table-schedule .center-box .note {
    margin-top: 15px;
    padding: 30px 20px;
    font-size: 14px;
  }
  #popup-vendor-profile-submit .popup-content .content-footer .footer-button {
    font-size: 18px;
  }
}

#vendor-review-create-content {
  padding-top: 25px;
  padding-bottom: 25px;
  background-color: #f0f4f7;
}

#vendor-review-create-content .wrapper .wrapper-title {
  margin-bottom: 25px;
  font-size: 18px;
  color: #333;
  font-weight: bold;
}

#vendor-review-create-content .wrapper [class^=step] {
  opacity: 0;
  display: none;
  visibility: hidden;
  transition: opacity .3s;
}

#vendor-review-create-content .wrapper [class^=step].active {
  display: block;
  opacity: 1;
  visibility: visible;
}

#vendor-review-create-content .wrapper .box {
  background-color: #FFFFFF;
}

#vendor-review-create-content .wrapper .box .box-header {
  padding: 20px;
}

#vendor-review-create-content .wrapper .box .box-header .box-title {
  font-size: 18px;
  font-weight: bold;
}

#vendor-review-create-content .wrapper .box .box-header .box-title .helper {
  color: #b5b5b5;
  display: block;
  font-size: 12px;
  font-weight: normal;
}

#vendor-review-create-content .wrapper .box .box-body {
  padding: 20px;
}

#vendor-review-create-content .wrapper .box .box-body .legend {
  color: #02cd8d;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 20px;
  margin-top: 20px;
}

#vendor-review-create-content .wrapper .box .box-body .form-group {
  margin-bottom: 10px;
  font-weight: bold;
  margin-left: -15px;
  margin-right: -15px;
}

#vendor-review-create-content .wrapper .box .box-body .form-group:after {
  content: '';
  display: table;
  clear: both;
}

#vendor-review-create-content .wrapper .box .box-body .form-group input.form-control,
#vendor-review-create-content .wrapper .box .box-body .form-group select.form-control,
#vendor-review-create-content .wrapper .box .box-body .form-group textarea.form-control {
  outline: 0;
  box-shadow: none;
  font-weight: normal;
  background-color: #FFFFFF;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  padding: 4px 12px;
  color: #000;
  transition: all .3s;
}

#vendor-review-create-content .wrapper .box .box-body .form-group input.form-control:focus,
#vendor-review-create-content .wrapper .box .box-body .form-group select.form-control:focus,
#vendor-review-create-content .wrapper .box .box-body .form-group textarea.form-control:focus {
  background-color: #cffff0;
  border: #cffff0;
  box-shadow: none;
}

#vendor-review-create-content .wrapper .box .box-body .form-group input.form-control:disabled,
#vendor-review-create-content .wrapper .box .box-body .form-group select.form-control:disabled,
#vendor-review-create-content .wrapper .box .box-body .form-group textarea.form-control:disabled {
  background-color: #eaeaea;
}

#vendor-review-create-content .wrapper .box .box-body .form-group textarea.form-control {
  min-height: 120px;
}

#vendor-review-create-content .wrapper .box .box-body .form-group .control-label {
  font-weight: bold;
  font-size: 14px;
  color: #333;
}

#vendor-review-create-content .wrapper .box .box-body .form-group .form-control.error {
  border-color: red;
}

#vendor-review-create-content .wrapper .box .box-body .form-group .text-error {
  font-size: 11px;
  color: red;
}

#vendor-review-create-content .wrapper .box .box-body .form-group .helper {
  margin-top: 5px;
  color: #b5b5b5;
  display: block;
  font-size: 12px;
  font-weight: normal;
}

#vendor-review-create-content .wrapper .box .box-body .form-group.required .control-label::after {
  margin-left: 5px;
  font-size: 16px;
  content: '*';
  color: #ff0303;
  display: inline-block;
}

#vendor-review-create-content .wrapper .button-group {
  padding-top: 25px;
}

#vendor-review-create-content .wrapper .button-group a {
  color: #02cd8d;
}

#vendor-review-create-content .wrapper .item {
  margin-bottom: 10px;
}

#vendor-review-create-content .wrapper .item .item-label {
  font-weight: bold;
  font-size: 14px;
  color: #333;
}

#vendor-review-create-content .wrapper .item .item-label.pull-left {
  width: 200px;
}

#vendor-review-create-content .wrapper .item .item-value {
  color: #57656e;
}

#vendor-review-create-content .wrapper .item::after {
  content: '';
  display: table;
  clear: both;
}

@media only screen and (max-width: 767px) {
  #vendor-review-create-content {
    padding-bottom: 0;
  }
  #vendor-review-create-content .container {
    padding-left: 0;
    padding-right: 0;
  }
  #vendor-review-create-content .container .button-group {
    padding: 25px 10px;
  }
  #vendor-review-create-content .container .button-group .pull-left {
    line-height: 3;
  }
  #vendor-review-create-content .container .button-group .pull-right .btn-effect-1 {
    padding: 11px 40px 13px;
  }
  #vendor-review-create-content .wrapper .item .item-label.pull-left {
    min-width: 50%;
    width: 0;
  }
  #vendor-review-create-content .wrapper .item .item-value {
    padding: 0 10px;
    width: 40%;
  }
  #vendor-review-create-content .button-group .pull-right.step-2 {
    float: none !important;
  }
  #vendor-review-create-content .button-group .pull-right.step-2 .btn-effect-1 {
    margin: 0 auto;
  }
}

#vendor-review-index-content {
  padding-top: 25px;
  padding-bottom: 25px;
  background-color: #f0f4f7;
}

#vendor-review-index-content .wrapper .wrapper-title {
  margin-bottom: 25px;
  font-size: 18px;
  color: #333;
  font-weight: bold;
}

#vendor-review-index-content .wrapper .tab-links {
  background-color: #FFFFFF;
  padding: 0;
  border: 1px solid #d6d6d6;
}

#vendor-review-index-content .wrapper .tab-links .link {
  color: #5d6972;
  display: block;
  text-decoration: none;
  cursor: pointer;
  float: left;
  position: relative;
  margin-right: 20px;
  margin-left: 20px;
  padding: 15px 0;
}

#vendor-review-index-content .wrapper .tab-links .link.active {
  color: #333;
  font-weight: bold;
}

#vendor-review-index-content .wrapper .tab-links .link.active::after {
  content: '';
  display: block;
  height: 3px;
  width: 100%;
  position: absolute;
  bottom: -1px;
  background-color: #02cd8d;
}

#vendor-review-index-content .wrapper .tab-links .link:not(.active):hover {
  color: #333;
}

#vendor-review-index-content .wrapper .tab-links::after {
  content: '';
  display: table;
  clear: both;
}

#vendor-review-index-content .wrapper .content-description {
  position: relative;
}

#vendor-review-index-content .wrapper .content-description .col-left {
  position: absolute;
  top: 0;
  left: 0;
  width: 180px;
}

#vendor-review-index-content .wrapper .content-description .col-left .btn-effect-1 {
  color: #FFFFFF;
  background-color: #02cd8d;
  border-radius: 3px;
  display: block;
  padding: 5px;
  text-align: center;
  cursor: pointer;
  line-height: 36px;
  font-size: 15px;
}

#vendor-review-index-content .wrapper .content-description .col-right {
  padding-left: 200px;
  color: #5f6c75;
  line-height: 1.2;
}

#vendor-review-index-content .wrapper .review-listing {
  background-color: #FFFFFF;
  border: 1px solid #d4f0e3;
}

#vendor-review-index-content .wrapper .review-listing .thead-row {
  display: table;
  width: 100%;
  border-bottom: 1px solid #d4f0e3;
}

#vendor-review-index-content .wrapper .review-listing .thead-row .thead-col {
  display: table-cell;
  color: #56646d;
  padding: 15px 25px;
}

#vendor-review-index-content .wrapper .review-listing .thead-row .thead-col.col-1 {
  width: 250px;
}

#vendor-review-index-content .wrapper .review-listing .thead-row .thead-col.col-3 {
  width: 200px;
}

#vendor-review-index-content .wrapper .review-listing .thead-row .thead-col.col-4 {
  width: 200px;
}

#vendor-review-index-content .wrapper .review-listing .thead-row .thead-col.col-5 {
  width: 200px;
}

#vendor-review-index-content .wrapper .review-listing .thead-row .thead-col.col-6 {
  width: 200px;
}

#vendor-review-index-content .wrapper .review-listing .tbody-row {
  display: table;
  width: 100%;
  border-bottom: 1px solid #d4f0e3;
}

#vendor-review-index-content .wrapper .review-listing .tbody-row .tbody-col {
  display: table-cell;
  color: #56646d;
  padding: 15px 25px;
  vertical-align: middle;
}

#vendor-review-index-content .wrapper .review-listing .tbody-row .tbody-col.col-1 {
  width: 250px;
}

#vendor-review-index-content .wrapper .review-listing .tbody-row .tbody-col.col-1 .text-circle {
  display: block;
  float: left;
  font-weight: bold;
  color: #585858;
  height: 60px;
  width: 60px;
  line-height: 60px;
  font-size: 25px;
  border-radius: 50%;
  background-color: #d8d8d8;
  text-align: center;
}

#vendor-review-index-content .wrapper .review-listing .tbody-row .tbody-col.col-1 .full_name {
  padding: 20px 0 20px 10px;
  display: inline-block;
  width: 135px;
  word-break: break-all;
  -ms-word-break: break-all;
  word-break: break-all;
  /* Non standard for WebKit */
  word-break: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

#vendor-review-index-content .wrapper .review-listing .tbody-row .tbody-col.col-1:after {
  content: "";
  display: table;
  clear: both;
}

#vendor-review-index-content .wrapper .review-listing .tbody-row .tbody-col.col-3 {
  width: 200px;
}

#vendor-review-index-content .wrapper .review-listing .tbody-row .tbody-col.col-4 {
  width: 200px;
}

#vendor-review-index-content .wrapper .review-listing .tbody-row .tbody-col.col-4 span.green {
  color: #02cd8d;
}

#vendor-review-index-content .wrapper .review-listing .tbody-row .tbody-col.col-5 {
  width: 200px;
}

#vendor-review-index-content .wrapper .review-listing .tbody-row .tbody-col.col-5 .circles-text {
  font-size: 16px !important;
}

#vendor-review-index-content .wrapper .review-listing .tbody-row .tbody-col.col-6 {
  width: 200px;
}

#vendor-review-index-content .wrapper .review-listing .tbody-row .tbody-col.col-6 .btn-link {
  border: 1px solid #56646d;
  color: #56646d;
  text-decoration: none;
  border-radius: 3px;
  padding: 10px 15px;
  display: inline-block;
  position: relative;
}

#vendor-review-index-content .wrapper .review-listing .tbody-row .tbody-col.col-6 .btn-link:hover {
  text-decoration: none;
}

#vendor-review-index-content .wrapper .review-listing .tbody-row .tbody-col.col-6 .btn-link .notification-dot {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  border-radius: 50%;
  background-color: #02cd8d;
  display: block;
  width: 15px;
  height: 15px;
}

@media only screen and (max-width: 991px) {
  #vendor-review-index-content .wrapper .container {
    width: 100%;
  }
  #vendor-review-index-content .wrapper .review-listing .thead-row .thead-col {
    padding: 10px 5px;
  }
  #vendor-review-index-content .wrapper .review-listing .thead-row .thead-col.col-1 {
    width: 200px;
  }
  #vendor-review-index-content .wrapper .review-listing .thead-row .thead-col.col-2 {
    width: 200px;
  }
  #vendor-review-index-content .wrapper .review-listing .tbody-row {
    padding-right: 140px;
    position: relative;
    width: 100%;
    display: block;
    min-height: 280px;
  }
  #vendor-review-index-content .wrapper .review-listing .tbody-row .tbody-col {
    padding: 25px 40px;
  }
  #vendor-review-index-content .wrapper .review-listing .tbody-row .tbody-col.col-1 {
    display: table-cell;
    width: 0px;
  }
  #vendor-review-index-content .wrapper .review-listing .tbody-row .tbody-col.col-2 {
    position: absolute;
    top: 0px;
    left: 150px;
    padding-right: 0;
    width: auto;
  }
  #vendor-review-index-content .wrapper .review-listing .tbody-row .tbody-col.col-3 {
    position: absolute;
    top: 30px;
    left: 150px;
    padding-right: 0;
    width: auto;
  }
  #vendor-review-index-content .wrapper .review-listing .tbody-row .tbody-col.col-3 .project-info {
    padding-top: 10px;
    color: #595959;
    font-weight: bold;
  }
  #vendor-review-index-content .wrapper .review-listing .tbody-row .tbody-col.col-4 {
    position: absolute;
    top: 90px;
    left: 150px;
    padding-right: 0;
    width: auto;
  }
  #vendor-review-index-content .wrapper .review-listing .tbody-row .tbody-col.col-5 {
    position: absolute;
    top: 130px;
    left: 150px;
    padding-right: 0;
    width: auto;
  }
  #vendor-review-index-content .wrapper .review-listing .tbody-row .tbody-col.col-6 {
    position: absolute;
    top: 0;
    right: 30px;
    padding-right: 0;
    width: auto;
  }
  #vendor-review-index-content .wrapper .content-description .col-right {
    padding-top: 60px;
    padding-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  #vendor-review-index-content .wrapper .wrapper-title {
    padding-left: 10px;
    padding-right: 10px;
  }
  #vendor-review-index-content .wrapper .tab-links {
    padding-left: 10px;
    padding-right: 10px;
  }
  #vendor-review-index-content .wrapper .content-description .col-right {
    padding-left: 0px;
    padding-top: 60px;
  }
  #vendor-review-index-content .wrapper .review-listing .tbody-row {
    padding-right: 80px;
    font-size: 10px;
  }
  #vendor-review-index-content .wrapper .review-listing .tbody-row .tbody-col {
    padding: 25px 10px;
  }
  #vendor-review-index-content .wrapper .review-listing .tbody-row .tbody-col.col-1 {
    display: table-cell;
    width: 0px;
  }
  #vendor-review-index-content .wrapper .review-listing .tbody-row .tbody-col.col-2 {
    top: 0px;
    left: 80px;
  }
  #vendor-review-index-content .wrapper .review-listing .tbody-row .tbody-col.col-3 {
    top: 30px;
    left: 80px;
  }
  #vendor-review-index-content .wrapper .review-listing .tbody-row .tbody-col.col-4 {
    top: 90px;
    left: 80px;
  }
  #vendor-review-index-content .wrapper .review-listing .tbody-row .tbody-col.col-5 {
    top: 130px;
    left: 80px;
  }
  #vendor-review-index-content .wrapper .review-listing .tbody-row .tbody-col.col-6 {
    top: 0;
    right: 10px;
  }
}

#vendor-review-show-content {
  padding-top: 25px;
  padding-bottom: 25px;
  background-color: #f0f4f7;
}

#vendor-review-show-content .wrapper .wrapper-title {
  margin-bottom: 25px;
  font-size: 18px;
  color: #333;
  font-weight: bold;
}

#vendor-review-show-content .wrapper .box {
  background-color: #FFFFFF;
  border: 1px solid #d4f0e3;
  margin-bottom: 20px;
}

#vendor-review-show-content .wrapper .box .box-header {
  padding: 20px;
  border-bottom: 1px solid #d4f0e3;
}

#vendor-review-show-content .wrapper .box .box-header .box-title strong {
  font-size: 18px;
  font-weight: bold;
}

#vendor-review-show-content .wrapper .box .box-header .box-title .helper {
  color: #b5b5b5;
  display: block;
  font-size: 12px;
  font-weight: normal;
}

#vendor-review-show-content .wrapper .box .box-header .box-title.with-icon-references {
  padding-left: 55px;
  position: relative;
  min-height: 33px;
  padding-top: 5px;
}

#vendor-review-show-content .wrapper .box .box-header .box-title.with-icon-references:before {
  position: absolute;
  background: url("../../images/root/client/reference/icon-reference.png") no-repeat;
  background-size: cover;
  width: 40px;
  height: 33px;
  content: '';
  display: block;
  top: 0;
  left: 0;
}

#vendor-review-show-content .wrapper .box .box-body {
  padding: 20px;
}

#vendor-review-show-content .wrapper .box .box-body .legend {
  color: #02cd8d;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 20px;
  margin-top: 20px;
}

#vendor-review-show-content .wrapper .box .box-body .form-group {
  margin-bottom: 10px;
  font-weight: bold;
  margin-left: -15px;
  margin-right: -15px;
}

#vendor-review-show-content .wrapper .box .box-body .form-group:after {
  content: '';
  display: table;
  clear: both;
}

#vendor-review-show-content .wrapper .box .box-body .form-group input.form-control,
#vendor-review-show-content .wrapper .box .box-body .form-group select.form-control,
#vendor-review-show-content .wrapper .box .box-body .form-group textarea.form-control {
  outline: 0;
  box-shadow: none;
  font-weight: normal;
  background-color: #FFFFFF;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  padding: 4px 12px;
  color: #000;
  transition: all .3s;
}

#vendor-review-show-content .wrapper .box .box-body .form-group input.form-control:focus,
#vendor-review-show-content .wrapper .box .box-body .form-group select.form-control:focus,
#vendor-review-show-content .wrapper .box .box-body .form-group textarea.form-control:focus {
  background-color: #cffff0;
  border: #cffff0;
  box-shadow: none;
}

#vendor-review-show-content .wrapper .box .box-body .form-group input.form-control:disabled,
#vendor-review-show-content .wrapper .box .box-body .form-group select.form-control:disabled,
#vendor-review-show-content .wrapper .box .box-body .form-group textarea.form-control:disabled {
  background-color: #eaeaea;
}

#vendor-review-show-content .wrapper .box .box-body .form-group textarea.form-control {
  min-height: 120px;
}

#vendor-review-show-content .wrapper .box .box-body .form-group .control-label {
  font-weight: bold;
  font-size: 14px;
  color: #333;
}

#vendor-review-show-content .wrapper .box .box-body .form-group .helper {
  margin-top: 5px;
  color: #b5b5b5;
  display: block;
  font-size: 12px;
  font-weight: normal;
}

#vendor-review-show-content .wrapper .box .box-body .form-group.required .control-label::after {
  margin-left: 5px;
  font-size: 16px;
  content: '*';
  color: #ff0303;
  display: inline-block;
}

#vendor-review-show-content .wrapper .box .box-body .review_info_row {
  display: table;
  width: 100%;
  margin-bottom: 30px;
  background-color: #f0f4f7;
  border: 1px solid #d4f0e3;
  padding: 15px 0;
}

#vendor-review-show-content .wrapper .box .box-body .review_info_row .review_info_col {
  display: table-cell;
  padding: 0 15px;
  font-weight: normal;
  font-size: 14px;
  color: #5e5e5e;
  vertical-align: top;
}

#vendor-review-show-content .wrapper .box .box-body .review_info_row .review_info_col:not(:first-child) {
  border-left: 1px solid #d4f0e3;
}

#vendor-review-show-content .wrapper .box .box-body .review_info_row .review_info_col.col-1 {
  width: 35%;
}

#vendor-review-show-content .wrapper .box .box-body .review_info_row .review_info_col.col-2 {
  width: 35%;
  padding-top: 20px;
  text-align: right;
}

#vendor-review-show-content .wrapper .box .box-body .review_info_row .review_info_col.col-2 .row {
  margin-bottom: 15px;
}

#vendor-review-show-content .wrapper .box .box-body .review_info_row .review_info_col.col-2 .row .rating-bar {
  position: relative;
  background-color: #dbdbdb;
}

#vendor-review-show-content .wrapper .box .box-body .review_info_row .review_info_col.col-2 .row .rating-bar .rating-col {
  width: 20%;
  height: 17px;
  background-color: transparent;
  display: inline-block;
  float: left;
}

#vendor-review-show-content .wrapper .box .box-body .review_info_row .review_info_col.col-2 .row .rating-bar .rating-col + .rating-col {
  border-left: 1px solid #ffffff;
}

#vendor-review-show-content .wrapper .box .box-body .review_info_row .review_info_col.col-2 .row .rating-bar .rating-value {
  height: 17px;
  background-color: #02cd8d;
}

#vendor-review-show-content .wrapper .box .box-body .review_info_row .review_info_col.col-2 .row .rating-bar:after {
  clear: both;
  content: '';
  display: table;
}

#vendor-review-show-content .wrapper .box .box-body .review_info_row .review_info_col.col-3 {
  width: 30%;
  text-align: center;
}

#vendor-review-show-content .wrapper .box .box-body .review_info_row .review_info_col .col-legend {
  font-weight: bold;
  font-size: 15px;
  margin-bottom: 15px;
  color: #333;
}

#vendor-review-show-content .wrapper .box .box-body .review_info_row .review_info_col .with-icon {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 25px;
  position: relative;
}

#vendor-review-show-content .wrapper .box .box-body .review_info_row .review_info_col .with-icon .fa {
  position: absolute;
  top: 5px;
  left: 2px;
  width: 20px;
  display: inline-block;
}

#vendor-review-show-content .wrapper .box .box-body .review_info_row .review_info_col .with-icon .more {
  float: right;
}

#vendor-review-show-content .wrapper .box .box-body .review_info_row .review_info_col .with-icon .tooltip_content {
  display: none;
}

#vendor-review-show-content .wrapper .box .box-body .review_info_row .review_info_col .verified {
  margin-top: 15px;
  border-radius: 3px;
  background: #444;
  color: #FFFFFF;
  padding: 5px 10px 5px 30px;
  position: relative;
  display: inline-block;
}

#vendor-review-show-content .wrapper .box .box-body .review_info_row .review_info_col .verified .fa {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translate(0, -50%);
}

#vendor-review-show-content .wrapper .box .box-body .review-content {
  word-break: break-all;
  -ms-word-break: break-all;
  word-break: break-all;
  /* Non standard for WebKit */
  word-break: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

#vendor-review-show-content .wrapper .box .box-body .review-content .content {
  margin-bottom: 20px;
}

#vendor-review-show-content .wrapper .box .box-body .review-content .content > h4 {
  font-weight: bold;
  color: #3f92f1;
  font-size: 16px;
}

#vendor-review-show-content .wrapper .box .box-body .review-content .content b {
  font-size: 14px;
  color: #000;
  font-weight: bold;
}

#vendor-review-show-content .wrapper .box .box-body .review-content .content blockquote {
  margin: 0 0 20px;
  border: 1px solid #d4f0e3;
  border-radius: 2px;
  padding: 15px 50px;
  background-color: #f0f4f7;
  position: relative;
  color: #888888;
  line-height: 1.5;
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: italic;
}

#vendor-review-show-content .wrapper .box .box-body .review-content .content blockquote:before {
  content: '“';
  position: absolute;
  top: -10px;
  left: 10px;
  font-size: 70px;
  color: #d1d8de;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
}

#vendor-review-show-content .wrapper .box .box-body .review-content .content blockquote:after {
  font-family: "Roboto", sans-serif;
  content: '”';
  position: absolute;
  bottom: -40px;
  right: 10px;
  font-size: 70px;
  color: #d1d8de;
  font-weight: bold;
}

#vendor-review-show-content .wrapper .box .box-body .review-content .content blockquote h4 {
  color: #5a5a5a;
  margin-bottom: 15px;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: italic;
}

#vendor-review-show-content .wrapper .button-group {
  padding-top: 25px;
}

#vendor-review-show-content .wrapper .button-group .btn-type-grey {
  padding: 9px 28px;
}

#vendor-review-show-content .wrapper .pull-right .btn-effect-1.btn-approve {
  display: inline-block;
  padding: 10px 25px;
  background-color: #02cd8d;
  border: 1px solid #02cd8d;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  color: #ffffe7;
  transition: background-color .3s;
  text-decoration: none;
  outline: 0;
  border: 0;
  cursor: pointer;
}

#vendor-review-show-content .wrapper .item {
  margin-bottom: 10px;
  word-break: break-all;
  -ms-word-break: break-all;
  word-break: break-all;
  /* Non standard for WebKit */
  word-break: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

#vendor-review-show-content .wrapper .item .item-label {
  font-weight: bold;
  font-size: 14px;
  color: #333;
}

#vendor-review-show-content .wrapper .item .item-value {
  color: #57656e;
}

#vendor-review-show-content .wrapper .item::after {
  content: '';
  display: table;
  clear: both;
}

#popup-appeal-review {
  background-color: #fff;
  border-radius: 4px;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

#popup-appeal-review .content-header {
  background: #02cd8d;
  padding: 15px 20px;
  position: relative;
}

#popup-appeal-review .content-header .title {
  font-size: 14px;
  color: #fffffc;
  line-height: 1;
  text-align: center;
}

#popup-appeal-review .content-header .cross {
  width: 20px;
  height: 20px;
  cursor: pointer;
  background: url("../../images/root/client/reference/popup-cross.png") center center no-repeat #02cd8d;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translate(0, -50%);
}

#popup-appeal-review .content-body {
  padding: 20px 35px;
}

#popup-appeal-review .content-body .form-group {
  margin-bottom: 15px;
}

#popup-appeal-review .content-body .form-group .radios input[type=radio] {
  visibility: hidden;
  opacity: 0;
}

#popup-appeal-review .content-body .form-group .radios label {
  display: block;
  margin: 0 0 10px;
  font-size: 20px;
  cursor: pointer;
  position: relative;
  padding-left: 30px;
  line-height: 20px;
}

#popup-appeal-review .content-body .form-group .radios label span {
  display: inline-block;
  vertical-align: middle;
  font-size: 11px;
}

#popup-appeal-review .content-body .form-group .radios label span.radio {
  border: 2px solid #d4f0e3;
  background-color: #d8f1e6;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  min-height: 20px;
  transition: background-color .3s;
}

#popup-appeal-review .content-body .form-group .radios label span.radio input {
  visibility: hidden;
  opacity: 0;
}

#popup-appeal-review .content-body .form-group .radios label.checked span.radio {
  background-color: #02cd8d;
}

#popup-appeal-review .content-body .form-group .radios input[type=text] {
  display: inline-block;
  width: auto;
}

#popup-appeal-review .content-body .form-group textarea.form-control {
  width: 100%;
  outline: none;
  font-size: 12px;
  padding: 15px;
  border: 1px solid #e5e7e7;
  background-color: #fff;
  border-radius: 4px;
  min-height: 100px;
}

#popup-appeal-review .content-body .form-group .text-error {
  color: red;
  font-size: 11px;
  display: block;
}

#popup-appeal-review .content-body .form-group textarea.form-control.error {
  border-color: red;
}

#popup-appeal-review .content-body .form-group .label-control {
  color: #333;
  font-weight: bold;
  margin-bottom: 10px;
}

#popup-appeal-review .content-body .form-group .label-control .red {
  color: red;
}

#popup-appeal-review .content-footer {
  padding-bottom: 15px;
}

#popup-appeal-review .content-footer button {
  background-color: #ebebeb;
  padding: 15px 20px;
  color: #02cd8d;
  border: none;
  display: block;
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  transition: background-color .3s;
}

#popup-appeal-review .content-footer button:hover {
  background-color: #f0f0f0;
}

@media only screen and (max-width: 991px) {
  #vendor-review-show-content .wrapper .box .box-body .review_info_row .review_info_col {
    font-size: 12px;
  }
  #vendor-review-show-content .wrapper .box .box-body .review_info_row .review_info_col .col-legend {
    font-size: 14px;
  }
  #vendor-review-show-content .wrapper .box .box-body .review_info_row .review_info_col.col-3 #review-rating .circles-wrp .circles-text {
    font-size: 28px !important;
  }
}

@media only screen and (max-width: 767px) {
  #vendor-review-show-content .wrapper .box .box-body .review_info_row .review_info_col {
    font-size: 12px;
    display: block;
  }
  #vendor-review-show-content .wrapper .box .box-body .review_info_row .review_info_col.col-1 {
    width: 100%;
  }
  #vendor-review-show-content .wrapper .box .box-body .review_info_row .review_info_col.col-2 {
    width: 100%;
    text-align: left !important;
  }
  #vendor-review-show-content .wrapper .box .box-body .review_info_row .review_info_col.col-2 .row .rating-bar {
    width: 80%;
  }
  #vendor-review-show-content .wrapper .box .box-body .review_info_row .review_info_col.col-3 {
    width: 100%;
  }
  #vendor-review-show-content .wrapper .box .box-body .review_info_row .review_info_col.col-3 #review-rating .circles-wrp .circles-text {
    font-size: 24px !important;
  }
  #vendor-review-show-content .wrapper .button-group {
    padding-top: 25px;
  }
  #vendor-review-show-content .wrapper .button-group .btn-type-grey {
    margin-bottom: 10px;
  }
}

body .portfolio-result_loading {
  text-align: center;
  font-size: 26px;
  position: fixed;
  top: 50%;
  left: 0;
  right: 0;
  z-index: 201;
  display: none;
}

body .portfolio-result_loading body {
  background-color: #3F51B5;
}

body .portfolio-result_loading .spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  height: 40px;
  width: 40px;
  transform: translate(-50%, -50%);
}

body .portfolio-result_loading [class^="ball-"] {
  position: absolute;
  display: block;
  left: 30px;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  transition: all 0.5s;
  animation: circleRotate 4s both infinite;
  transform-origin: 0 250% 0;
}

@keyframes circleRotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(1440deg);
  }
}

body .portfolio-result_loading .ball-1 {
  z-index: -1;
  background-color: #28f1b1;
  animation-timing-function: cubic-bezier(0.5, 0.3, 0.9, 0.9);
}

body .portfolio-result_loading .ball-2 {
  z-index: -2;
  background-color: #28f17f;
  animation-timing-function: cubic-bezier(0.5, 0.6, 0.9, 0.9);
}

body .portfolio-result_loading .ball-3 {
  z-index: -3;
  background-color: #28f1f1;
  animation-timing-function: cubic-bezier(0.5, 0.9, 0.9, 0.9);
}

body .portfolio-result_loading .ball-4 {
  z-index: -4;
  background-color: #28f5b5;
  animation-timing-function: cubic-bezier(0.5, 1.2, 0.9, 0.9);
}

body .portfolio-result_loading .ball-5 {
  z-index: -5;
  background-color: #28f7b7;
  animation-timing-function: cubic-bezier(0.5, 1.5, 0.9, 0.9);
}

body .portfolio-result_loading .ball-6 {
  z-index: -6;
  background-color: #28f6b6;
  animation-timing-function: cubic-bezier(0.5, 1.8, 0.9, 0.9);
}

body .portfolio-result_loading .ball-7 {
  z-index: -7;
  background-color: #28f4b4;
  animation-timing-function: cubic-bezier(0.5, 2.1, 0.9, 0.9);
}

body .portfolio-result_loading .ball-8 {
  z-index: -8;
  background-color: #28f9b9;
  animation-timing-function: cubic-bezier(0.5, 2.4, 0.9, 0.9);
}

body.portfolio-loading {
  position: relative;
  overflow: hidden;
}

body.portfolio-loading:before {
  z-index: 200;
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #fff;
  opacity: 0.7;
}

body.portfolio-loading .portfolio-result_loading {
  display: block;
}

#work-listing-content {
  background-color: #f0f4f7;
  font-family: 'Roboto', sans-serif;
}

#work-listing-content .work-listing-category-navigation {
  background-color: #ffffff;
}

#work-listing-content .work-listing-category-navigation ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

#work-listing-content .work-listing-category-navigation ul li {
  float: left;
}

#work-listing-content .work-listing-category-navigation ul li a {
  padding-left: 30px;
  padding-right: 30px;
  display: block;
  line-height: 70px;
  background-color: #fff;
  color: #2d2d2d;
  cursor: pointer;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  text-decoration: none;
  transition: background-color .3s;
}

#work-listing-content .work-listing-category-navigation ul li a:hover {
  background-color: #eeeeee;
}

#work-listing-content .work-listing-category-navigation ul:after {
  content: "";
  display: table;
  clear: both;
}

#work-listing-content .work-listing-header {
  background-color: #f48476;
  background-size: cover;
  background-position: center center;
  padding: 80px 0;
  text-align: center;
  font-size: 24pt;
  font-weight: 300;
  color: #fff;
}

#work-listing-content .work-listing-header h1 {
  font-weight: bold;
  color: #fff;
  font-size: 36px;
  line-height: 1;
  margin-bottom: 30px;
}

#work-listing-content .work-listing-header span {
  font-weight: 300;
  color: #fff;
  font-size: 20px;
}

#work-listing-content .work-listing-header #portfolio-search-input-desktop {
  max-width: 860px;
  margin: 50px auto 0;
  width: 90%;
  position: relative;
}

#work-listing-content .work-listing-header #portfolio-search-input-desktop .box-input {
  height: 51px;
  padding-right: 150px;
  position: relative;
  border-radius: 4px;
  background-color: #fff;
  border: 2px solid transparent;
  transition: border .3s;
}

#work-listing-content .work-listing-header #portfolio-search-input-desktop .box-input input {
  width: 100%;
  display: block;
  height: 46px;
  padding: 15px 15px 10px 15px;
  font-size: 16px;
  font-weight: 300;
  border: none;
  outline: none;
  color: #949A9E;
  line-height: 1;
  border-radius: 4px 0 0 4px;
}

#work-listing-content .work-listing-header #portfolio-search-input-desktop .box-input input ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #949A9E;
}

#work-listing-content .work-listing-header #portfolio-search-input-desktop .box-input input :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #969ca0;
}

#work-listing-content .work-listing-header #portfolio-search-input-desktop .box-input input ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #969ca0;
}

#work-listing-content .work-listing-header #portfolio-search-input-desktop .box-input button {
  padding: 3px;
  display: block;
  top: -2px;
  right: -2px;
  width: 150px;
  position: absolute;
  box-shadow: none;
  background-color: #28f3b2;
  max-height: 51px;
  border-radius: 4px;
  border: none;
  text-align: center;
  transition: background-color .3s;
}

#work-listing-content .work-listing-header #portfolio-search-input-desktop .box-input button:hover {
  background-color: #18e0a5;
}

#work-listing-content .work-listing-header #portfolio-search-input-desktop .box-input.focus {
  border: 2px solid #28f3b2;
}

#work-listing-content .work-listing-header #portfolio-search-input-desktop .box-options {
  z-index: 200;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  display: none;
  background-color: #FFFFFF;
}

#work-listing-content .work-listing-header #portfolio-search-input-desktop .box-options.active {
  display: block;
}

#work-listing-content .work-listing-header #portfolio-search-input-desktop .box-options .all-options {
  display: none;
  x-justify-content: space-around;
  x-align-items: stretch;
}

#work-listing-content .work-listing-header #portfolio-search-input-desktop .box-options .all-options.active {
  display: flex;
}

#work-listing-content .work-listing-header #portfolio-search-input-desktop .box-options .all-options .main-level {
  flex: 0 0 200px;
  width: 200px;
  border-right: 1px solid #f0f4f7;
}

#work-listing-content .work-listing-header #portfolio-search-input-desktop .box-options .all-options .main-level .first-level-option {
  text-align: left;
  position: relative;
  padding: 15px 25px;
  cursor: pointer;
  font-size: 14px;
  line-height: 1.3;
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
  color: #000;
}

#work-listing-content .work-listing-header #portfolio-search-input-desktop .box-options .all-options .main-level .first-level-option:after {
  content: "";
  display: none;
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  border-left: 12px solid #28f3b2;
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
}

#work-listing-content .work-listing-header #portfolio-search-input-desktop .box-options .all-options .main-level .first-level-option.selected {
  background-color: #28f3b2;
  color: #FFFFFF;
}

#work-listing-content .work-listing-header #portfolio-search-input-desktop .box-options .all-options .main-level .first-level-option.selected:after {
  display: block;
}

#work-listing-content .work-listing-header #portfolio-search-input-desktop .box-options .all-options .sub-level {
  display: none;
  flex: 1;
}

#work-listing-content .work-listing-header #portfolio-search-input-desktop .box-options .all-options .sub-level.active {
  display: block;
}

#work-listing-content .work-listing-header #portfolio-search-input-desktop .box-options .all-options .sub-level .sub-level-options {
  display: flex;
  padding: 10px;
  x-justify-content: space-around;
  x-align-items: stretch;
}

#work-listing-content .work-listing-header #portfolio-search-input-desktop .box-options .all-options .sub-level .sub-level-options .second-level-option {
  width: 200px;
  flex: 0 0 200px;
  color: #18293E;
  padding: 10px;
  font-size: 13px;
  font-weight: 700;
  cursor: default;
  text-align: left;
}

#work-listing-content .work-listing-header #portfolio-search-input-desktop .box-options .all-options .sub-level .sub-level-options .third-level-option {
  flex: 1;
}

#work-listing-content .work-listing-header #portfolio-search-input-desktop .box-options .all-options .sub-level .sub-level-options .third-level-option:after {
  content: "";
  display: table;
  clear: both;
}

#work-listing-content .work-listing-header #portfolio-search-input-desktop .box-options .all-options .sub-level .sub-level-options .third-level-option a {
  text-decoration: none;
  cursor: pointer;
  display: block;
  float: left;
  color: #797979;
  padding: 10px;
  font-size: 13px;
  font-weight: 300;
}

#work-listing-content .work-listing-header #portfolio-search-input-desktop .box-options .all-options .sub-level .sub-level-options .third-level-option a:hover {
  color: #18293E;
}

#work-listing-content .work-listing-header #portfolio-search-input-desktop .box-options .search-options {
  display: none;
}

#work-listing-content .work-listing-header #portfolio-search-input-desktop .box-options .search-options.active {
  display: block;
}

#work-listing-content .work-listing-header #portfolio-search-input-desktop .box-options .search-options .option-row {
  position: relative;
}

#work-listing-content .work-listing-header #portfolio-search-input-desktop .box-options .search-options .option-row .text {
  padding: 15px 120px 15px 15px;
  display: block;
  text-align: left;
  font-size: 12px;
  color: #222222;
  text-decoration: none;
}

#work-listing-content .work-listing-header #portfolio-search-input-desktop .box-options .search-options .option-row .text-type {
  color: #a9b1be;
  font-size: 12px;
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: -1;
}

#work-listing-content .work-listing-header #portfolio-search-input-desktop .box-options .search-options .option-row:hover, #work-listing-content .work-listing-header #portfolio-search-input-desktop .box-options .search-options .option-row.hover {
  background-color: #28f3b2;
  color: #FFFFFF;
}

#work-listing-content .work-listing-header #portfolio-search-input-desktop .box-options .search-options .option-row:hover .text,
#work-listing-content .work-listing-header #portfolio-search-input-desktop .box-options .search-options .option-row:hover .text-type, #work-listing-content .work-listing-header #portfolio-search-input-desktop .box-options .search-options .option-row.hover .text,
#work-listing-content .work-listing-header #portfolio-search-input-desktop .box-options .search-options .option-row.hover .text-type {
  color: #FFFFFF;
  z-index: 2;
}

#work-listing-content .work-listing-filter-bar {
  padding: 15px 0;
  background-color: #fff;
}

#work-listing-content .work-listing-filter-bar .form-group,
#work-listing-content .work-listing-filter-bar .button-group {
  float: left;
  margin-right: 15px;
  min-width: 200px;
  margin-bottom: 0;
}

#work-listing-content .work-listing-filter-bar .form-group input,
#work-listing-content .work-listing-filter-bar .button-group input {
  border: 1px solid #d4f0e3;
  min-height: 14px;
  background-color: #fff;
  border-radius: 2px;
  padding: 5px 8px;
}

#work-listing-content .work-listing-filter-bar .form-group input:focus, #work-listing-content .work-listing-filter-bar .form-group input:hover,
#work-listing-content .work-listing-filter-bar .button-group input:focus,
#work-listing-content .work-listing-filter-bar .button-group input:hover {
  box-shadow: 0 0 2px #7799D0;
  border-color: #7799D0;
}

#work-listing-content .work-listing-filter-bar .form-group > *,
#work-listing-content .work-listing-filter-bar .button-group > * {
  font-size: 16px;
  display: block;
  width: 100%;
}

#work-listing-content .work-listing-filter-bar .form-group .SumoSelect > .optWrapper > .options li label,
#work-listing-content .work-listing-filter-bar .button-group .SumoSelect > .optWrapper > .options li label {
  font-size: 13px;
  font-weight: normal !important;
}

#work-listing-content .work-listing-filter-bar .form-group .SumoSelect > .optWrapper > .options li.group > label,
#work-listing-content .work-listing-filter-bar .button-group .SumoSelect > .optWrapper > .options li.group > label {
  font-weight: bold !important;
}

#work-listing-content .work-listing-filter-bar .form-group .SumoSelect > .CaptionCont,
#work-listing-content .work-listing-filter-bar .button-group .SumoSelect > .CaptionCont {
  margin: 0;
}

#work-listing-content .work-listing-filter-bar .form-group button,
#work-listing-content .work-listing-filter-bar .button-group button {
  min-height: 14px;
  background-color: #fff;
  border-radius: 2px;
  padding: 5px 8px;
  border: 1px solid #02cd8d;
  color: #02cd8d;
  text-align: inherit;
}

#work-listing-content .work-listing-filter-bar .form-group button:hover,
#work-listing-content .work-listing-filter-bar .button-group button:hover {
  box-shadow: 0 0 2px #7799D0;
  border-color: #7799D0;
}

#work-listing-content .work-listing-body {
  background-color: #f0f4f7;
  padding-bottom: 60px;
  padding-top: 30px;
  position: relative;
}

#work-listing-content .work-listing-body #filter_bar {
  padding-bottom: 18px;
  margin-left: -6px;
  margin-right: -6px;
}

#work-listing-content .work-listing-body #filter_bar .filter-column {
  padding-left: 6px;
  padding-right: 6px;
  float: left;
  padding-bottom: 12px;
}

#work-listing-content .work-listing-body #filter_bar .filter-column .column {
  background-color: #e9ebef;
  padding: 7px 45px 7px 10px;
  border-radius: 3px;
  display: block;
  position: relative;
}

#work-listing-content .work-listing-body #filter_bar .filter-column .column span {
  font-size: 13px;
  color: #454545;
  display: block;
  line-height: 18px;
}

#work-listing-content .work-listing-body #filter_bar .filter-column .column i.btn-close {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  width: 18px;
  height: 18px;
  background-size: cover;
  background-image: url(../../images/root/work/filter-close.png);
  cursor: pointer;
}

#work-listing-content .work-listing-body #filter_bar:after {
  content: "";
  display: table;
  clear: both;
}

#work-listing-content .work-listing-body .filters {
  padding-right: 60px;
}

#work-listing-content .work-listing-body .filters .section-filter .section-heading {
  color: #18293E;
  font-size: 16px;
  font-weight: 600;
  position: relative;
  padding-top: 15px;
  padding-bottom: 15px;
}

#work-listing-content .work-listing-body .filters .section-filter .section-body {
  padding-bottom: 20px;
}

#work-listing-content .work-listing-body .filters .section-filter .section-body .checkboxes .label-checkbox {
  cursor: pointer;
  display: block;
  padding-left: 30px;
  color: #18293E;
  font-size: 16px;
  position: relative;
  margin-bottom: 10px;
  font-weight: 300;
  line-height: 1;
}

#work-listing-content .work-listing-body .filters .section-filter .section-body .checkboxes .label-checkbox input {
  display: none;
}

#work-listing-content .work-listing-body .filters .section-filter .section-body .checkboxes .label-checkbox input:checked + .tick:after {
  display: block;
}

#work-listing-content .work-listing-body .filters .section-filter .section-body .checkboxes .label-checkbox .tick {
  display: block;
  position: absolute;
  border: 1px solid #dbdbdb;
  border-radius: 1px;
  background: transparent;
  left: 0;
  top: 0;
  width: 14px;
  height: 14px;
}

#work-listing-content .work-listing-body .filters .section-filter .section-body .checkboxes .label-checkbox .tick:after {
  display: none;
  position: absolute;
  content: "";
  width: 4px;
  height: 9px;
  border-right: 1px solid #464646;
  border-bottom: 1px solid #464646;
  left: 4px;
  top: 1px;
  transform: rotate(50deg);
}

#work-listing-content .work-listing-body .filters .section-filter .section-body .checkboxes .label-checkbox:nth-child(n+9) {
  display: none;
}

#work-listing-content .work-listing-body .filters .section-filter .section-body .checkbox-show-button {
  margin-top: 10px;
  color: #18d197;
  font-size: 13px;
  font-weight: bold;
  cursor: pointer;
}

#work-listing-content .work-listing-body .filters .section-filter .section-body .checkbox-show-button:hover {
  color: #18e5ab;
}

#work-listing-content .work-listing-body .filters .section-filter .section-body .checkbox-show-button.checkbox-show-more {
  display: inline-block;
}

#work-listing-content .work-listing-body .filters .section-filter .section-body .checkbox-show-button.checkbox-show-less {
  display: none;
}

#work-listing-content .work-listing-body .filters .section-filter .section-body.expended .checkboxes .label-checkbox:nth-child(n+9) {
  display: block;
}

#work-listing-content .work-listing-body .filters .section-filter .section-body.expended .checkbox-show-button.checkbox-show-more {
  display: none;
}

#work-listing-content .work-listing-body .filters .section-filter .section-body.expended .checkbox-show-button.checkbox-show-less {
  display: inline-block;
}

#work-listing-content .work-listing-body .filters .section-filter .section-body .links a {
  color: #18293E;
  font-size: 16px;
  font-weight: 600;
  position: relative;
  padding-top: 5px;
  padding-bottom: 5px;
  display: block;
  text-decoration: none;
}

#work-listing-content .work-listing-body .filters .section-filter.collapseable .section-heading {
  cursor: pointer;
}

#work-listing-content .work-listing-body .filters .section-filter.collapseable .section-heading i.up, #work-listing-content .work-listing-body .filters .section-filter.collapseable .section-heading i.down {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

#work-listing-content .work-listing-body .filters .section-filter.collapseable .section-heading i.up {
  display: none;
}

#work-listing-content .work-listing-body .filters .section-filter.collapseable .section-heading i.down {
  display: block;
}

#work-listing-content .work-listing-body .filters .section-filter.collapseable .section-body {
  display: none;
}

#work-listing-content .work-listing-body .filters .section-filter.collapseable.active .section-heading i.up {
  display: block;
}

#work-listing-content .work-listing-body .filters .section-filter.collapseable.active .section-heading i.down {
  display: none;
}

#work-listing-content .work-listing-body .filters .section-filter.collapseable.active .section-body {
  display: block;
}

#work-listing-content .work-listing-body .filters [class*=-wrapper].with-border-top {
  border-top: 1px solid #dbdbdb;
}

#work-listing-content .work-listing-body .filters [class*=-wrapper].with-border-bottom {
  border-bottom: 1px solid #dbdbdb;
}

#work-listing-content .work-listing-body .search-guide {
  border: 1px solid #18d197;
  margin-top: 30px;
  width: 240px;
  border-radius: 5px;
  font-size: 14px;
}

#work-listing-content .work-listing-body .search-guide .title {
  color: #18d197;
  font-size: 14px;
  font-weight: bold;
  padding: 35px 25px;
}

#work-listing-content .work-listing-body .search-guide .step {
  padding: 0 25px 35px;
}

#work-listing-content .work-listing-body .search-guide .step .img-1 {
  width: 50px;
  height: 50px;
  background-image: url(../../images/root/work/how-2easy-work-portfilio-search-1.png);
  background-repeat: no-repeat;
  margin: 0 auto 25px;
}

#work-listing-content .work-listing-body .search-guide .step .img-2 {
  width: 50px;
  height: 50px;
  background-image: url(../../images/root/work/how-2easy-work-portfilio-search-2.png);
  background-repeat: no-repeat;
  margin: 0 auto 25px;
}

#work-listing-content .work-listing-body .search-guide .step .img-3 {
  width: 50px;
  height: 50px;
  background-image: url(../../images/root/work/how-2easy-work-portfilio-search-3.png);
  background-repeat: no-repeat;
  margin: 0 auto 25px;
}

#work-listing-content .work-listing-body .post-job {
  border: 1px solid #18d197;
  margin-top: 30px;
  width: 240px;
  border-radius: 5px;
  font-size: 14px;
}

#work-listing-content .work-listing-body .post-job .title {
  color: #18d197;
  font-size: 14px;
  font-weight: bold;
  padding: 35px 25px;
  text-align: center;
}

#work-listing-content .work-listing-body .post-job .content {
  padding: 0 25px 35px;
}

#work-listing-content .work-listing-body .post-job .content .img {
  width: 50px;
  height: 50px;
  background-image: url(../../images/root/work/2Easy-Professional-matching-artificial-intelligence-post-request.png);
  background-repeat: no-repeat;
  margin: 0 auto 25px;
}

#work-listing-content .work-listing-body .post-job .content span {
  font-size: 14px;
}

#work-listing-content .work-listing-body .post-job .button-group {
  padding: 0 25px 35px;
}

#work-listing-content .work-listing-body .post-job .button-group .button {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  padding: 15px 22px;
  border-radius: 4px;
  background-color: #28f3b2;
  text-align: center;
  text-decoration: none;
  color: #fff;
  display: block;
  transition: background-color .3s;
}

#work-listing-content .work-listing-body .post-job .button-group .button :hover {
  background-color: rgba(24, 209, 151, 0.6);
}

#work-listing-content .work-listing-body .legend {
  font-size: 18px;
  color: #18293E;
  font-weight: 500;
  margin-bottom: 20px;
}

#work-listing-content .work-listing-body .legend img {
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
}

#work-listing-content .work-listing-body .results {
  margin-left: -10px;
  margin-right: -10px;
}

#work-listing-content .work-listing-body .results .portfolio-wrapper {
  float: left;
  width: 25%;
  padding: 0 10px 20px 10px;
}

#work-listing-content .work-listing-body .results .portfolio-wrapper .portfolio {
  font-family: "roboto";
  text-decoration: none;
  box-shadow: 0 2px 2px 0 #D8D8D8;
  background-color: #fff;
  margin-bottom: 20px;
  border-radius: 5px;
}

#work-listing-content .work-listing-body .results .portfolio-wrapper .portfolio .portfolio-ajax-link {
  cursor: pointer;
}

#work-listing-content .work-listing-body .results .portfolio-wrapper .portfolio .portfolio-wrapper-heading {
  padding-bottom: 75%;
  background-size: cover;
  background-position: 0 0;
  position: relative;
}

#work-listing-content .work-listing-body .results .portfolio-wrapper .portfolio .portfolio-wrapper-heading .portfolio-wrapper-heading-title {
  position: absolute;
  z-index: 10;
  color: #fff;
  font-size: 18px;
  line-height: 26px;
  padding: 10px 50px 10px 10px;
}

#work-listing-content .work-listing-body .results .portfolio-wrapper .portfolio .lazy {
  position: relative;
  border-radius: 4px;
}

#work-listing-content .work-listing-body .results .portfolio-wrapper .portfolio .lazy::after {
  content: '';
  display: block;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("../../images/root/work/grid-grey-layer.png");
  background-repeat: repeat;
  background-size: 1px 190px;
}

#work-listing-content .work-listing-body .results .portfolio-wrapper .portfolio .lazy .location {
  bottom: 3px;
  z-index: 3;
  position: absolute;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  line-height: 24px;
}

#work-listing-content .work-listing-body .results .portfolio-wrapper .portfolio .lazy .location .fa {
  color: #ffffff;
  padding-top: 5px;
  font-size: 24px;
}

#work-listing-content .work-listing-body .results .portfolio-wrapper .portfolio .lazy .location span {
  color: white;
  padding-left: 5px;
  font-size: 14px;
  text-transform: capitalize;
}

#work-listing-content .work-listing-body .results .portfolio-wrapper .portfolio .bookmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
}

#work-listing-content .work-listing-body .results .portfolio-wrapper .portfolio .bookmark .review .fa-bookmark {
  color: #f5c343;
  position: absolute;
  font-size: 50px;
  transform: scaleX(0.8);
  top: -2%;
  right: 5%;
}

#work-listing-content .work-listing-body .results .portfolio-wrapper .portfolio .bookmark .review .fa-star {
  z-index: 3;
  color: #ffffff;
  position: absolute;
  font-size: 20px;
  right: 8.5%;
  top: 2%;
}

#work-listing-content .work-listing-body .results .portfolio-wrapper .portfolio .bookmark .feature {
  position: absolute;
  top: -5px;
  right: 10px;
  z-index: 3;
}

#work-listing-content .work-listing-body .results .portfolio-wrapper .portfolio .content {
  padding: 0 10px 15px 15px;
}

#work-listing-content .work-listing-body .results .portfolio-wrapper .portfolio .content .title {
  color: #18293E;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
  line-height: 1.3;
  letter-spacing: 0;
  padding-bottom: 10px;
}

#work-listing-content .work-listing-body .results .portfolio-wrapper .portfolio .content .rate .fa {
  color: #f5c343;
}

#work-listing-content .work-listing-body .results .portfolio-wrapper .portfolio .content .services {
  margin-left: -4px;
  margin-top: 4px;
}

#work-listing-content .work-listing-body .results .portfolio-wrapper .portfolio .content .services .service {
  margin-left: 4px;
  margin-bottom: 4px;
  background-color: #505961;
  display: inline-block;
  padding: 3px 8px;
  border-radius: 2px;
  color: rgba(255, 255, 255, 0.75);
}

#work-listing-content .work-listing-body .results .portfolio-wrapper .portfolio .content .services .service.hidden {
  display: none;
}

#work-listing-content .work-listing-body .results .portfolio-wrapper .portfolio .content .divider {
  height: 1px;
  background-color: #edf1f3;
  margin-top: 10px;
  margin-bottom: 10px;
}

#work-listing-content .work-listing-body .results .portfolio-wrapper .portfolio .content .divider-short {
  height: 1px;
  width: 10px;
  background-color: #868686;
  margin-top: 10px;
  margin-bottom: 10px;
}

#work-listing-content .work-listing-body .results .portfolio-wrapper .portfolio .content .description {
  color: #737373;
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  line-height: 1.3;
}

#work-listing-content .work-listing-body .results .portfolio-wrapper .portfolio .content .details .tags {
  color: #8b8b8b;
  font-size: 14px;
  padding-top: 10px;
  font-weight: 300;
}

#work-listing-content .work-listing-body .results .portfolio-wrapper .portfolio .content .details .item-client-name {
  width: 65%;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
}

#work-listing-content .work-listing-body .results .portfolio-wrapper .portfolio .content .details .premium {
  width: 35%;
  text-align: right;
}

#work-listing-content .work-listing-body .results .portfolio-wrapper .portfolio .content .details .premium span {
  color: #ffffff;
  background-color: #ee7781;
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 2px;
}

#work-listing-content .work-listing-body .results .portfolio-wrapper .portfolio .content .details .pro {
  width: 35%;
  text-align: right;
}

#work-listing-content .work-listing-body .results .portfolio-wrapper .portfolio .content .details .pro span {
  color: #ffffff;
  background-color: #071c2f;
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 2px;
}

#work-listing-content .work-listing-body .results .portfolio-wrapper .portfolio .footer {
  background-color: #f9fafb;
  padding: 10px 50px 10px 15px;
  min-height: 46px;
  position: relative;
  border-radius: 5px;
}

#work-listing-content .work-listing-body .results .portfolio-wrapper .portfolio .footer .services {
  display: block;
}

#work-listing-content .work-listing-body .results .portfolio-wrapper .portfolio .footer .services .service {
  margin-right: 4px;
  margin-bottom: 4px;
  background-color: #97a1b0;
  display: inline-block;
  padding: 3px 8px;
  border-radius: 2px;
  color: rgba(255, 255, 255, 0.75);
  font-weight: 300;
}

#work-listing-content .work-listing-body .results .portfolio-wrapper .portfolio .footer .services .service.hidden {
  display: none;
}

#work-listing-content .work-listing-body .results .portfolio-wrapper .portfolio .footer .col-favourite {
  position: absolute;
  right: 10px;
  top: 10px;
  display: block;
  text-decoration: none;
  cursor: pointer;
}

#work-listing-content .work-listing-body .results .portfolio-wrapper .portfolio .footer .col-favourite .fa {
  color: #28f2b2;
}

#work-listing-content .work-listing-body .results .portfolio-wrapper .portfolio .footer .col-favourite .fa-heart-o {
  display: block;
}

#work-listing-content .work-listing-body .results .portfolio-wrapper .portfolio .footer .col-favourite .fa-heart {
  display: none;
}

#work-listing-content .work-listing-body .results .portfolio-wrapper .portfolio .footer .col-favourite.un-favourite .fa-heart-o {
  display: none;
}

#work-listing-content .work-listing-body .results .portfolio-wrapper .portfolio .footer .col-favourite.un-favourite .fa-heart {
  display: block;
}

#work-listing-content .work-listing-body .results:after {
  content: '';
  display: table;
  clear: both;
}

#work-listing-content .work-listing-body .flex-row {
  display: flex;
  x-justify-content: space-around;
  x-align-items: stretch;
}

#work-listing-content .work-listing-body .flex-row .flex-column-left {
  flex: 0 0 300px;
  width: 300px;
}

#work-listing-content .work-listing-body .flex-row .flex-column-right {
  flex: 1;
}

#work-listing-content .work-listing-body .no_matched {
  color: #18293e;
  text-align: center;
}

#work-listing-content .work-listing-body .no_matched .title {
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 25px;
}

#work-listing-content .work-listing-body .no_matched .title span {
  font-size: 24px;
}

#work-listing-content .work-listing-body .no_matched .description {
  font-size: 16px;
  font-weight: 300;
}

#work-listing-content .work-listing-body .wrapper-btn-load-result {
  padding: 50px 0;
  display: none;
  text-align: center;
}

#work-listing-content .work-listing-body .wrapper-btn-load-result .btn-load-result {
  padding: 15px 35px;
  display: inline-block;
  color: #FFFFFF;
  background-color: #28f3b2;
  line-height: 25px;
  border-radius: 28px;
  cursor: pointer;
  text-decoration: none;
  font-size: 18px;
}

#work-listing-content .work-listing-body .wrapper-btn-load-result .btn-load-result:hover {
  background-color: #28f3b2;
}

#work-listing-content .work-listing-body .wrapper-btn-load-result.show {
  display: block;
}

#work-listing-content .work-listing-body .package_suggestion {
  margin-top: 60px;
}

#work-listing-content .work-listing-body .package_suggestion .header {
  background-color: #8a8bb6;
}

#work-listing-content .work-listing-body .package_suggestion .header .title {
  padding: 30px 0px;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  font-size: 18px;
}

#work-listing-content .work-listing-body .package_suggestion .header .title img {
  width: 40px;
  margin-right: 20px;
}

#work-listing-content .work-listing-body .package_suggestion .header .packages {
  text-align: center;
  padding-bottom: 45px;
}

#work-listing-content .work-listing-body .package_suggestion .header .packages .package {
  text-decoration: none;
  cursor: pointer;
  padding: 15px 35px;
  display: inline-block;
  color: #18293e;
  background-color: #ffffff;
  line-height: 25px;
  border-radius: 28px;
  font-size: 18px;
  margin: 0 10px 25px;
}

#work-listing-content .work-listing-body .package_suggestion .header .packages .package:hover {
  background-color: #ebebeb;
}

#work-listing-content .work-listing-body .package_suggestion .body {
  color: #18293e;
  text-align: center;
}

#work-listing-content .work-listing-body .package_suggestion .body .main_text_title {
  font-weight: 500;
  font-size: 18px;
  padding: 100px 0 25px;
}

#work-listing-content .work-listing-body .package_suggestion .body .main_text_title h2 {
  margin: 0;
  font-size: 24px;
}

#work-listing-content .work-listing-body .package_suggestion .body .main_text_content ul {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 10px;
}

#work-listing-content .work-listing-body .package_suggestion .body .main_text_content ul li {
  color: #27384d;
  font-weight: 300;
  font-size: 14px;
  padding-left: 20px;
  position: relative;
  text-align: left;
  display: block;
  margin-bottom: 10px;
}

#work-listing-content .work-listing-body .package_suggestion .body .main_text_content ul li:before {
  color: #28f1b1;
  position: absolute;
  left: 0;
  top: 2px;
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
}

.portfolio-show-header {
  background-color: #102b4c;
  padding: 60px 0;
}

#show-portfolio {
  padding: 50px 0;
  background-color: #fafafa;
}

#show-portfolio .portfolio-show-left {
  width: 33.3333333%;
  border: 1px solid #e3e3e3;
  display: table-cell;
  vertical-align: top;
  background-color: #fafafa;
  border-right: 1px solid #cccccc;
  padding: 10px 30px;
  position: relative;
}

#show-portfolio .portfolio-show-left .vendor-info {
  padding-top: 30px;
}

#show-portfolio .portfolio-show-left .vendor-info strong {
  margin-bottom: 20px;
  display: block;
  font-size: 18px;
  color: #18293E;
}

#show-portfolio .portfolio-show-left .vendor-info a {
  font-size: 14px;
  overflow: hidden;
  border-radius: 3px;
}

#show-portfolio .portfolio-show-left .title {
  margin-top: 20px;
  color: #444444;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 20px;
  letter-spacing: 1px;
}

#show-portfolio .portfolio-show-left .title.profile {
  width: 60%;
}

#show-portfolio .portfolio-show-left .btn-group .btn-effect-1 {
  padding: 5px 15px 10px;
}

#show-portfolio .portfolio-show-left .btn-group span {
  color: #ffffff;
  text-decoration: none;
  font-size: 12px;
}

#show-portfolio .portfolio-show-left .header-logo {
  position: absolute;
  right: 30px;
  top: 20px;
  width: 75px;
  height: 75px;
  display: block;
  background-color: #FFFFFF;
  padding: 3px;
  border-radius: 50%;
}

#show-portfolio .portfolio-show-left .header-logo .image {
  border-radius: 50%;
  width: 69px;
  height: 69px;
  background-size: cover;
  background-position: center;
}

#show-portfolio .portfolio-show-left .services {
  margin-left: -4px;
  margin-top: 4px;
  margin-bottom: 20px;
}

#show-portfolio .portfolio-show-left .services .service {
  margin-left: 4px;
  margin-bottom: 4px;
  background-color: #505961;
  display: inline-block;
  padding: 3px 8px;
  border-radius: 2px;
  color: rgba(255, 255, 255, 0.75);
}

#show-portfolio .portfolio-show-left .divider {
  height: 1px;
  background-color: #d8f1e6;
  margin: 20px -30px;
}

#show-portfolio .portfolio-show-left .description {
  color: #737373;
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  line-height: 1.3;
  margin-bottom: 30px;
}

#show-portfolio .portfolio-show-left .description.with-icon {
  margin-bottom: 20px;
}

#show-portfolio .portfolio-show-left .description.with-icon img {
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
}

#show-portfolio .portfolio-show-right {
  width: 66.6666666%;
  display: table-cell;
  vertical-align: top;
  border-right: 1px solid #e3e3e3;
  border-top: 1px solid #e3e3e3;
  border-bottom: 1px solid #e3e3e3;
  padding-top: 30px;
  padding-bottom: 30px;
}

#show-portfolio .portfolio-show-right .cards {
  padding-top: 30px;
  padding-left: 50px;
  padding-right: 50px;
}

#show-portfolio .portfolio-show-right .description {
  padding: 0 30px 30px;
  color: #737373;
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  line-height: 1.3;
}

#show-portfolio .portfolio-show-right .videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;
  margin-bottom: 30px;
}

#show-portfolio .portfolio-show-right .videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#show-portfolio .portfolio-show-right .imageWrapper {
  margin-bottom: 30px;
}

#show-portfolio .portfolio-show-right .imageWrapper img {
  display: block;
  margin: 0 auto;
  max-height: 100vh;
  max-width: 100%;
}

#show-portfolio .portfolio-show-right .buttonWrapper a {
  font-size: 14px;
  overflow: hidden;
  border-radius: 3px;
}

#show-portfolio .portfolio-show-right .btn-group {
  margin: 0 auto;
  width: 150px;
  display: block;
}

#show-portfolio .portfolio-show-right .btn-group span {
  color: #ffffff;
  text-decoration: none;
}

#show-portfolio .card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
  padding: 20px;
  margin-bottom: 20px;
}

#show-portfolio .card .card-title {
  font-weight: bold;
  color: #333333;
  font-size: 14px;
}

#show-portfolio .card .card-body {
  padding-top: 20px;
}

#show-portfolio .card .card-body .tags {
  margin-left: -3px;
  margin-right: -3px;
}

#show-portfolio .card .card-body .tags .tag {
  float: left;
  padding-left: 3px;
  padding-right: 3px;
  margin-bottom: 6px;
}

#show-portfolio .card .card-body .tags .tag span {
  padding: 8px 15px;
  line-height: 1.2;
  background: #f9f9f9;
  background: -webkit-gradient(left top, left bottom, color-stop(0%, #f9f9f9), color-stop(100%, #e1e3e3));
  background: linear-gradient(to bottom, #f9f9f9 0%, #e1e3e3 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f9f9f9', endColorstr='#e1e3e3', GradientType=0);
  border: 1px solid #e1e3e3;
  display: block;
  color: #6c737a;
  font-size: 14px;
}

#show-portfolio .card .card-body .tags:after {
  content: "";
  display: table;
  clear: both;
}

@media only screen and (max-width: 991px) {
  #work-listing-content .work-listing-header h1 {
    font-size: 32px;
  }
  #work-listing-content .work-listing-header span {
    font-size: 18px;
  }
  #work-listing-content .work-listing-header #portfolio-search-input-desktop {
    width: 80%;
  }
  #work-listing-content .work-listing-header #portfolio-search-input-desktop .box-input input {
    font-size: 15px;
  }
  #work-listing-content .work-listing-header #portfolio-search-input-desktop .box-input button {
    width: 100px;
  }
  #work-listing-content .work-listing-body .results {
    padding: 0;
  }
  #work-listing-content .work-listing-body .results .portfolio-wrapper {
    width: 50%;
    padding-top: 10px;
  }
  #work-listing-content .work-listing-body .search-guide {
    width: 100%;
  }
  #work-listing-content .work-listing-body .search-guide .title {
    text-align: center;
    padding: 25px;
    font-size: 20px;
  }
  #work-listing-content .work-listing-body .search-guide .step {
    padding: 0 20px 35px;
    width: 33.333333%;
    display: block;
    float: left;
  }
  #work-listing-content .work-listing-body .post-job {
    width: 100%;
  }
  #work-listing-content .work-listing-body .post-job .title {
    padding: 25px;
    font-size: 20px;
  }
  #work-listing-content .work-listing-body .post-job .content {
    text-align: center;
  }
  #work-listing-content .work-listing-body .post-job .button-group {
    padding: 0 25px 25px;
    width: 50%;
    margin: 0 auto;
  }
  #work-listing-content .work-listing-body .post-job .button-group .button {
    text-align: center;
    font-size: 16px;
    padding: 15px;
  }
  #work-listing-content .work-listing-body .legend {
    font-size: 24px;
    position: relative;
    cursor: pointer;
  }
  #work-listing-content .work-listing-body .legend i.up, #work-listing-content .work-listing-body .legend i.down {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
  #work-listing-content .work-listing-body .legend i.up {
    display: none;
  }
  #work-listing-content .work-listing-body .legend i.down {
    display: block;
  }
  #work-listing-content .work-listing-body .filters {
    padding-right: 0px;
  }
  #work-listing-content .work-listing-body .filters .section-filter {
    display: none;
  }
  #work-listing-content .work-listing-body .filters .section-filter .section-heading {
    font-size: 18px;
  }
  #work-listing-content .work-listing-body .filters.active .section-filter {
    display: block;
  }
  #work-listing-content .work-listing-body .filters.active .legend i.up {
    display: block;
  }
  #work-listing-content .work-listing-body .filters.active .legend i.down {
    display: none;
  }
  #work-listing-content .work-listing-body .flex-row {
    display: initial;
  }
  #work-listing-content .work-listing-body .flex-row .flex-column-left {
    flex: 0 0 100%;
    width: 100%;
  }
  #work-listing-content .work-listing-body .flex-row .flex-column-right {
    width: 100%;
    padding-top: 30px;
  }
  #work-listing-content .work-listing-filter-bar {
    margin: 25px 50px;
  }
  #work-listing-content .work-listing-filter-bar .form-group,
  #work-listing-content .work-listing-filter-bar .button-group {
    padding: 10px;
    width: 50%;
    margin-right: 0px;
  }
  #work-listing-content .work-listing-filter-bar .button-group {
    float: right;
  }
  #show-portfolio .portfolio-show-left {
    width: 100%;
    display: block;
  }
  #show-portfolio .portfolio-show-right {
    width: 100%;
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  #work-listing-content .work-listing-category-navigation {
    width: 100%;
    overflow-x: scroll;
  }
  #work-listing-content .work-listing-category-navigation ul {
    width: 480px;
  }
  #work-listing-content .work-listing-category-navigation ul li a {
    padding-left: 10px;
    padding-right: 10px;
  }
  #work-listing-content .work-listing-header {
    background-color: #f0f4f7;
    padding: 30px 30px 15px;
    font-size: inherit;
  }
  #work-listing-content .work-listing-header h1 {
    line-height: 1.5;
    font-size: 24px;
    color: #ffffff;
    margin-bottom: 20px;
    margin-top: 0px;
  }
  #work-listing-content .work-listing-header span {
    font-size: 14px;
    color: #ffffff;
  }
  #work-listing-content .work-listing-header #portfolio-search-input-desktop {
    width: 100%;
  }
  #work-listing-content .work-listing-header #portfolio-search-input-desktop .box-input {
    padding-right: 20%;
  }
  #work-listing-content .work-listing-header #portfolio-search-input-desktop .box-input input {
    font-size: 14px;
  }
  #work-listing-content .work-listing-header #portfolio-search-input-desktop .box-input button {
    font-size: 25px;
    background-color: transparent;
    width: auto;
    top: 0%;
    right: 5%;
    padding: 0;
    height: 51px;
  }
  #work-listing-content .work-listing-header #portfolio-search-input-desktop .box-input button:hover {
    background-color: transparent;
  }
  #work-listing-content .work-listing-header #portfolio-search-input-desktop .box-input button .fa {
    color: #18d197;
  }
  #work-listing-content .work-listing-header #portfolio-search-input-desktop .box-options .responsive-all-options {
    display: none;
  }
  #work-listing-content .work-listing-header #portfolio-search-input-desktop .box-options .responsive-all-options.active {
    display: block;
  }
  #work-listing-content .work-listing-header #portfolio-search-input-desktop .box-options .responsive-all-options .main-level {
    font-size: 14px;
    color: #18293e;
    font-weight: bold;
    text-align: left;
  }
  #work-listing-content .work-listing-header #portfolio-search-input-desktop .box-options .responsive-all-options .main-level .first-level-option {
    background-color: #f5f5f5;
    padding: 20px 0 0 0;
    cursor: pointer;
  }
  #work-listing-content .work-listing-header #portfolio-search-input-desktop .box-options .responsive-all-options .main-level .first-level-option .title {
    padding-bottom: 20px;
    padding-left: 10px;
    position: relative;
  }
  #work-listing-content .work-listing-header #portfolio-search-input-desktop .box-options .responsive-all-options .main-level .first-level-option .title i.up, #work-listing-content .work-listing-header #portfolio-search-input-desktop .box-options .responsive-all-options .main-level .first-level-option .title i.down {
    position: absolute;
    right: 10%;
    top: 0;
  }
  #work-listing-content .work-listing-header #portfolio-search-input-desktop .box-options .responsive-all-options .main-level .first-level-option .title i.up {
    display: none;
  }
  #work-listing-content .work-listing-header #portfolio-search-input-desktop .box-options .responsive-all-options .main-level .first-level-option .title i.down {
    display: block;
  }
  #work-listing-content .work-listing-header #portfolio-search-input-desktop .box-options .responsive-all-options .main-level .first-level-option .sub-level-options {
    display: none;
    padding-left: 20px;
    background-color: #ffffff;
  }
  #work-listing-content .work-listing-header #portfolio-search-input-desktop .box-options .responsive-all-options .main-level .first-level-option .sub-level-options.active .third-level-option {
    display: block;
  }
  #work-listing-content .work-listing-header #portfolio-search-input-desktop .box-options .responsive-all-options .main-level .first-level-option .sub-level-options .second-level-option {
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #dbdbdb;
    cursor: pointer;
    position: relative;
  }
  #work-listing-content .work-listing-header #portfolio-search-input-desktop .box-options .responsive-all-options .main-level .first-level-option .sub-level-options .second-level-option i.up, #work-listing-content .work-listing-header #portfolio-search-input-desktop .box-options .responsive-all-options .main-level .first-level-option .sub-level-options .second-level-option i.down {
    position: absolute;
    right: 10%;
    top: 50%;
    transform: translateY(-50%);
  }
  #work-listing-content .work-listing-header #portfolio-search-input-desktop .box-options .responsive-all-options .main-level .first-level-option .sub-level-options .second-level-option i.up {
    display: none;
  }
  #work-listing-content .work-listing-header #portfolio-search-input-desktop .box-options .responsive-all-options .main-level .first-level-option .sub-level-options .second-level-option i.down {
    display: block;
  }
  #work-listing-content .work-listing-header #portfolio-search-input-desktop .box-options .responsive-all-options .main-level .first-level-option .sub-level-options .second-level-option.active i.up {
    display: none;
  }
  #work-listing-content .work-listing-header #portfolio-search-input-desktop .box-options .responsive-all-options .main-level .first-level-option .sub-level-options .second-level-option.active i.down {
    display: block;
  }
  #work-listing-content .work-listing-header #portfolio-search-input-desktop .box-options .responsive-all-options .main-level .first-level-option .sub-level-options .third-level-option {
    display: none;
    padding-left: 10px;
    font-size: 14px;
    font-weight: 300;
  }
  #work-listing-content .work-listing-header #portfolio-search-input-desktop .box-options .responsive-all-options .main-level .first-level-option .sub-level-options .third-level-option .third-level-option-items {
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #dbdbdb;
  }
  #work-listing-content .work-listing-header #portfolio-search-input-desktop .box-options .responsive-all-options .main-level .first-level-option .sub-level-options .third-level-option .third-level-option-items a {
    color: #18293e;
    text-decoration: none;
  }
  #work-listing-content .work-listing-header #portfolio-search-input-desktop .box-options .responsive-all-options .main-level .first-level-option.active .title i.up {
    display: block;
  }
  #work-listing-content .work-listing-header #portfolio-search-input-desktop .box-options .responsive-all-options .main-level .first-level-option.active .title i.down {
    display: none;
  }
  #work-listing-content .work-listing-header #portfolio-search-input-desktop .box-options .responsive-all-options .main-level .first-level-option.active .sub-level-options {
    display: block;
  }
  #work-listing-content .work-listing-filter-bar {
    margin: 10px 15px;
  }
  #work-listing-content .work-listing-filter-bar .form-group,
  #work-listing-content .work-listing-filter-bar .button-group {
    min-width: 0;
  }
  #work-listing-content .work-listing-filter-bar .form-group > *,
  #work-listing-content .work-listing-filter-bar .button-group > * {
    font-size: 12px;
    display: block;
    width: 100%;
  }
  #work-listing-content .work-listing-body {
    padding-top: 15px;
  }
  #work-listing-content .work-listing-body #responsive-filter_bar {
    padding-bottom: 18px;
    margin-left: -6px;
    margin-right: -6px;
  }
  #work-listing-content .work-listing-body #responsive-filter_bar .filter-column {
    padding-left: 6px;
    padding-right: 6px;
    float: left;
    padding-bottom: 12px;
  }
  #work-listing-content .work-listing-body #responsive-filter_bar .filter-column .column {
    background-color: #e9ebef;
    padding: 7px 45px 7px 10px;
    border-radius: 3px;
    display: block;
    position: relative;
  }
  #work-listing-content .work-listing-body #responsive-filter_bar .filter-column .column span {
    font-size: 13px;
    color: #454545;
    display: block;
    line-height: 18px;
  }
  #work-listing-content .work-listing-body #responsive-filter_bar .filter-column .column i.btn-close {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    width: 18px;
    height: 18px;
    background-size: cover;
    background-image: url(../../images/root/work/filter-close.png);
    cursor: pointer;
  }
  #work-listing-content .work-listing-body #responsive-filter_bar:after {
    content: "";
    display: table;
    clear: both;
  }
  #work-listing-content .work-listing-body .legend {
    font-size: 14px;
  }
  #work-listing-content .work-listing-body .filters {
    padding: 0 12px;
  }
  #work-listing-content .work-listing-body .flex-row .flex-column-left .legend {
    text-align: center;
    border: 1px solid #dbdbdb;
    padding: 10px;
    border-radius: 3px;
  }
  #work-listing-content .work-listing-body .results .portfolio-wrapper {
    width: 100%;
    padding: 15px 20px 20px;
    border-bottom: 1px solid #dbdbdb;
  }
  #work-listing-content .work-listing-body .results .portfolio-wrapper .portfolio {
    background: transparent;
    box-shadow: none;
    margin-bottom: 0;
  }
  #work-listing-content .work-listing-body .results .portfolio-wrapper .portfolio .portfolio-wrapper-heading {
    padding-bottom: 30px;
    min-height: 140px;
  }
  #work-listing-content .work-listing-body .results .portfolio-wrapper .portfolio .portfolio-wrapper-heading .portfolio-wrapper-heading-title {
    font-size: 15px;
    line-height: 1.3;
    padding-right: 30px;
    position: relative;
  }
  #work-listing-content .work-listing-body .results .portfolio-wrapper .portfolio .lazy {
    width: 45%;
    display: block;
    float: left;
  }
  #work-listing-content .work-listing-body .results .portfolio-wrapper .portfolio .lazy .location {
    padding-left: 10px;
    padding-right: 0px;
  }
  #work-listing-content .work-listing-body .results .portfolio-wrapper .portfolio .lazy .location .pull-left {
    width: 65%;
  }
  #work-listing-content .work-listing-body .results .portfolio-wrapper .portfolio .lazy .location .fa {
    padding-top: 0;
  }
  #work-listing-content .work-listing-body .results .portfolio-wrapper .portfolio .lazy .location .fa.fa-map-marker {
    display: none;
  }
  #work-listing-content .work-listing-body .results .portfolio-wrapper .portfolio .lazy .location .fa.fa-youtube-play {
    padding-right: 5px;
  }
  #work-listing-content .work-listing-body .results .portfolio-wrapper .portfolio .lazy .location span {
    padding-left: 0px;
    font-size: 12px;
    line-height: 1.3;
  }
  #work-listing-content .work-listing-body .results .portfolio-wrapper .portfolio .bookmark .feature {
    right: 3%;
  }
  #work-listing-content .work-listing-body .results .portfolio-wrapper .portfolio .bookmark .feature img {
    width: 20px;
  }
  #work-listing-content .work-listing-body .results .portfolio-wrapper .portfolio .content {
    width: 55%;
    display: block;
    float: left;
    font-size: 14px;
    line-height: 1.3;
    padding: 5px 10px 0 15px;
  }
  #work-listing-content .work-listing-body .results .portfolio-wrapper .portfolio .content .title {
    margin-top: 0;
    font-size: 14px;
    line-height: 1.3;
  }
  #work-listing-content .work-listing-body .results .portfolio-wrapper .portfolio .content .details .item-client-name {
    font-size: 14px;
    line-height: 1.2;
  }
  #work-listing-content .work-listing-body .results .portfolio-wrapper .portfolio .content .details .tags {
    font-size: 14px;
    line-height: 1.3;
  }
  #work-listing-content .work-listing-body .results .portfolio-wrapper .portfolio .footer {
    padding: 10px 50px 0 0;
    min-height: initial;
    background-color: transparent;
    display: block;
    clear: both;
  }
  #work-listing-content .work-listing-body .package_suggestion .header .title img {
    display: block;
    margin: auto;
  }
  #work-listing-content .work-listing-body .package_suggestion .header .packages .package {
    padding: 5px 15px;
    line-height: 20px;
    border-radius: 20px;
    font-size: 14px;
  }
}

#insight-details-page .insight-detail-wrapper .insight-detail-information .editor img {
  max-width: 100% !important;
}

#insight-details-page .insight-detail-wrapper .insight-detail-information .editor h2 {
  font-size: 22px;
  padding-bottom: 15px;
}

#insight-details-page .insight-detail-wrapper .insight-detail-information .editor p {
  margin-bottom: 15px;
}

#insight-details-page .insight-detail-wrapper .insight-detail-information .editor p a {
  text-decoration: none;
  color: #28f1b1;
  cursor: pointer;
}

#insight-details-page .insight-detail-wrapper .insight-detail-information .editor ol {
  margin-bottom: 15px;
  padding: 0 0 0 30px;
  list-style: none;
}

#insight-details-page .insight-detail-wrapper .insight-detail-information .editor ol a {
  text-decoration: underline;
  color: #18293e;
  cursor: pointer;
}

#insight-details-page .insight-detail-wrapper .insight-detail-information .editor ol li {
  position: relative;
  padding-left: 17px;
}

#insight-details-page .insight-detail-wrapper .insight-detail-information .editor ol li:before {
  content: "";
  display: block;
  background: url(../../images/root/svg/insight/check-circle-solid.svg) no-repeat;
  width: 15px;
  height: 12px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

#insight-details-page .insight-detail-wrapper .insight-detail-information .editor .table-comparison {
  width: 100%;
  font-size: 14px;
  margin: 0 auto;
}

#insight-details-page .insight-detail-wrapper .insight-detail-information .editor .table-comparison thead {
  text-align: center;
  background-color: #18283e;
  color: #ffffff;
  font-weight: bold;
}

#insight-details-page .insight-detail-wrapper .insight-detail-information .editor .table-comparison thead th {
  text-align: center;
  padding: 15px 0;
}

#insight-details-page .insight-detail-wrapper .insight-detail-information .editor .table-comparison thead th:first-child {
  background-color: #ffffff;
  width: 16%;
}

#insight-details-page .insight-detail-wrapper .insight-detail-information .editor .table-comparison tbody tr {
  text-align: center;
}

#insight-details-page .insight-detail-wrapper .insight-detail-information .editor .table-comparison tbody tr th {
  border-bottom: 1px solid #ffffff;
  background-color: #71839c !important;
  color: #ffffff;
  font-weight: bold;
  padding: 15px;
}

#insight-details-page .insight-detail-wrapper .insight-detail-information .editor .table-comparison tbody tr td {
  padding: 25px 20px;
}

#insight-details-page .insight-detail-wrapper .insight-detail-information .editor .table-comparison tbody tr:nth-child(odd) td:nth-child(odd) {
  background-color: #ffffff;
}

#insight-details-page .insight-detail-wrapper .insight-detail-information .editor .table-comparison tbody tr:nth-child(odd) td:nth-child(even) {
  background-color: #eaf0f7;
}

#insight-details-page .insight-detail-wrapper .insight-detail-information .editor .table-comparison tbody tr:nth-child(even) td:nth-child(odd) {
  background-color: #eaf0f7;
}

#insight-details-page .insight-detail-wrapper .insight-detail-information .editor .table-comparison tbody tr:nth-child(even) td:nth-child(even) {
  background-color: #ffffff;
}

#insight-details-page .insight-detail-wrapper .insight-detail-information .editor .helper {
  padding-bottom: 20px;
  text-align: right;
  padding-top: 30px;
  margin: 0 auto;
}

#insight-details-page .insight-detail-wrapper .insight-detail-information .editor .helper span {
  color: #28f1b1;
}

#insight-details-page .insight-detail-wrapper .insight-detail-information .editor .main_image {
  padding-bottom: 20px;
}

#insight-details-page .insight-detail-wrapper .insight-detail-information .editor .main_image img {
  width: 100% !important;
  height: auto !important;
}

#insight-details-page .insight-detail-wrapper .insight-detail-information .editor .main_image-source {
  color: #95a1b2;
  font-weight: bold;
  text-align: right;
  margin-bottom: 20px;
  border-right: 2px solid #28f1b1;
  padding-right: 5px;
}

@media only screen and (max-width: 767px) {
  #insight-details-page .insight-detail-wrapper .insight-detail-information .editor h2 {
    font-size: 18px;
    padding-bottom: 5px;
  }
  #insight-details-page .insight-detail-wrapper .insight-detail-information .editor ol {
    padding: 0 0 0 15px;
  }
  #insight-details-page .insight-detail-wrapper .insight-detail-information .editor .table-comparison {
    font-size: 10px;
  }
  #insight-details-page .insight-detail-wrapper .insight-detail-information .editor .table-comparison thead th {
    padding: 5px 0;
  }
  #insight-details-page .insight-detail-wrapper .insight-detail-information .editor .table-comparison thead th:first-child {
    background-color: #ffffff;
    width: 10%;
  }
  #insight-details-page .insight-detail-wrapper .insight-detail-information .editor .table-comparison tbody tr {
    text-align: center;
  }
  #insight-details-page .insight-detail-wrapper .insight-detail-information .editor .table-comparison tbody tr th {
    padding: 5px;
  }
  #insight-details-page .insight-detail-wrapper .insight-detail-information .editor .table-comparison tbody tr td {
    padding: 5px;
  }
  #insight-details-page .insight-detail-wrapper .insight-detail-information .editor .main_image-source {
    font-size: 12px;
  }
}

#insight-listing-page {
  padding: 60px 0 100px;
}

#insight-listing-page .listing {
  padding-right: 35px;
  padding-bottom: 60px;
}

#insight-listing-page .listing .top-insight-image {
  width: 100%;
  max-height: 500px;
  position: relative;
}

#insight-listing-page .listing .top-insight-image::before {
  content: '';
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  display: block;
  height: 100%;
  position: absolute;
  width: 100%;
}

#insight-listing-page .listing .top-insight-image img {
  width: 100%;
  max-height: 500px;
}

#insight-listing-page .listing .top-insight-image .date {
  position: absolute;
  font-size: 16px;
  top: 30px;
  left: 30px;
  color: #ffffff;
}

#insight-listing-page .listing .top-insight-image .date span {
  font-weight: bold;
}

#insight-listing-page .listing .top-insight-image .top-insight-info {
  min-width: 600px;
  text-align: center;
  position: absolute;
  font-size: 16px;
  color: #ffffff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#insight-listing-page .listing .top-insight-image .top-insight-info .category {
  font-weight: bold;
  font-size: 18px;
}

#insight-listing-page .listing .top-insight-image .top-insight-info .category .svg-menu img {
  width: 50px;
  margin: 0 auto;
}

#insight-listing-page .listing .top-insight-image .top-insight-info .category .svg-menu img svg {
  fill: #ffffff;
}

#insight-listing-page .listing .top-insight-image .top-insight-info .title {
  font-size: 30px;
  font-weight: bold;
  padding-bottom: 10px;
}

#insight-listing-page .listing .top-insight-image .top-insight-info .writer .company-logo {
  display: inline;
}

#insight-listing-page .listing .top-insight-image .top-insight-info .writer .company-logo img {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

#insight-listing-page .listing .top-insight-image .top-insight-info .writer span {
  color: #02cd8d;
}

#insight-listing-page .listing .top-insight-summary {
  border: 2px solid #eaf0f7;
  border-top: none;
}

#insight-listing-page .listing .top-insight-summary .summary-title {
  padding: 30px;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.3;
}

#insight-listing-page .listing .top-insight-summary .summary-content {
  padding: 30px 30px 30px 0;
}

#insight-listing-page .listing .top-insight-summary .summary-content .question {
  font-size: 22px;
  padding-bottom: 25px;
}

#insight-listing-page .listing .top-insight-summary .summary-content .answer {
  padding-bottom: 25px;
  font-size: 16px;
}

#insight-listing-page .listing .top-insight-summary .summary-content .button a {
  padding: 10px 35px;
  font-size: 12px;
  font-weight: bold;
  background-color: #18293e;
  text-decoration: none;
  color: #ffffff;
  display: inline-block;
  cursor: pointer;
}

#insight-listing-page .listing .listing-title {
  font-size: 30px;
  font-weight: bold;
  padding-bottom: 15px;
  line-height: 1;
}

#insight-listing-page .listing-box {
  padding: 60px 0;
  border-bottom: 2px solid #dedede;
}

#insight-listing-page .listing-box .main-top {
  font-weight: bold;
}

#insight-listing-page .listing-box .main-top.no-all {
  padding-bottom: 30px;
}

#insight-listing-page .listing-box .main-top .listing-category {
  float: left;
  display: inline;
  padding-left: 45px;
  position: relative;
  font-size: 22px;
}

#insight-listing-page .listing-box .main-top .listing-category .svg-menu {
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  height: 30px;
}

#insight-listing-page .listing-box .main-top .listing-category .svg-menu img {
  width: 30px;
  height: 30px;
}

#insight-listing-page .listing-box .main-top .view-all {
  float: right;
  display: inline-block;
  color: #02cd8d;
  cursor: pointer;
  padding-bottom: 30px;
  text-decoration: none;
}

#insight-listing-page .listing-box .main-top .view-all .fa {
  padding-right: 5px;
}

#insight-listing-page .listing-box .main-insight {
  padding-bottom: 30px;
}

#insight-listing-page .listing-box .main-insight .main-insight-content {
  padding-left: 20px;
}

#insight-listing-page .listing-box .main-insight .main-insight-content .featured {
  color: #72839d;
  font-size: 12px;
  font-weight: bold;
}

#insight-listing-page .listing-box .main-insight .main-insight-content .main-insight-title {
  font-size: 22px;
  padding-bottom: 10px;
  line-height: 1.2;
}

#insight-listing-page .listing-box .main-insight .main-insight-content .main-insight-title a {
  text-decoration: none;
  color: #18293e;
}

#insight-listing-page .listing-box .main-insight .main-insight-content .main-insight-description {
  padding-bottom: 10px;
}

#insight-listing-page .listing-box .main-insight .main-insight-content .main-content-writer, #insight-listing-page .listing-box .main-insight .main-insight-content .main-content-date {
  color: #8c9baf;
}

#insight-listing-page .listing-box .main-insight .main-insight-content .main-content-date {
  padding-bottom: 10px;
}

#insight-listing-page .listing-box .main-insight .main-insight-content .button a {
  padding: 10px 35px;
  font-size: 12px;
  font-weight: bold;
  background-color: #18293e;
  text-decoration: none;
  color: #ffffff;
  display: inline-block;
  cursor: pointer;
}

#insight-listing-page .listing-box .main-insight .main-insight-image img {
  width: 100%;
  max-height: 250px;
}

#insight-listing-page .listing-box .other-insights .other-insight-wrapper {
  width: 100%;
}

#insight-listing-page .listing-box .other-insights .other-insight-wrapper .other-insight-content {
  float: left;
  width: 22%;
  margin-right: 4%;
}

#insight-listing-page .listing-box .other-insights .other-insight-wrapper .other-insight-content:nth-child(4) {
  margin-right: 0%;
}

#insight-listing-page .listing-box .other-insights .other-insight-wrapper .other-insight-content:last-child {
  margin-right: 0%;
}

#insight-listing-page .listing-box .other-insights .other-insight-wrapper .other-insight-content:hover .other-insight-content-image .more {
  display: block;
}

#insight-listing-page .listing-box .other-insights .other-insight-wrapper .other-insight-content .other-insight-content-image {
  padding-bottom: 10px;
  cursor: pointer;
  position: relative;
}

#insight-listing-page .listing-box .other-insights .other-insight-wrapper .other-insight-content .other-insight-content-image img {
  width: 100%;
  height: 150px;
}

#insight-listing-page .listing-box .other-insights .other-insight-wrapper .other-insight-content .other-insight-content-image img::after {
  opacity: 0.5;
}

#insight-listing-page .listing-box .other-insights .other-insight-wrapper .other-insight-content .other-insight-content-image .more {
  display: none;
  background: rgba(24, 40, 62, 0.8);
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 150px;
  transform: translate(-50%, -54%);
}

#insight-listing-page .listing-box .other-insights .other-insight-wrapper .other-insight-content .other-insight-content-image .more span {
  font-weight: bold;
  color: #02cd8d;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#insight-listing-page .listing-box .other-insights .other-insight-wrapper .other-insight-content .other-insight-content-title {
  font-weight: bold;
  padding-bottom: 15px;
}

#insight-listing-page .listing-box .other-insights .other-insight-wrapper .other-insight-content .other-insight-content-date, #insight-listing-page .listing-box .other-insights .other-insight-wrapper .other-insight-content .other-insight-content-writer {
  color: #71839c;
}

#insight-listing-page .industry-list {
  height: 300px;
  padding-right: 35px;
}

#insight-listing-page .industry-list .col-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../../images/root/insight/industry_image.jpg");
  height: 300px;
}

#insight-listing-page .industry-list .col-content {
  background-color: #ebf0f6;
  height: 300px;
  padding: 30px 50px;
}

#insight-listing-page .industry-list .col-content .header {
  font-size: 24px;
  padding-bottom: 15px;
}

#insight-listing-page .industry-list .col-content .description {
  font-size: 16px;
  padding-bottom: 25px;
}

#insight-listing-page .industry-list .col-content .button a {
  font-weight: bold;
  padding: 13px 40px;
  font-size: 14px;
  border-radius: 2px;
  background-color: #18293e;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
}

#insight-listing-page .quick-links {
  padding-bottom: 30px;
}

#insight-listing-page .quick-links .box .box-title {
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 5px;
}

#insight-listing-page .quick-links .box .box-content .category {
  text-decoration: none;
  color: #18293e;
  cursor: pointer;
}

#insight-listing-page .quick-links .box .box-content .category .category-name {
  position: relative;
  padding: 10px 0 10px 55px;
  line-height: 2;
}

#insight-listing-page .quick-links .box .box-content .category .category-name:hover {
  background-color: #28f1b1;
}

#insight-listing-page .quick-links .box .box-content .category .category-name.active {
  background-color: #eaf0f7;
}

#insight-listing-page .quick-links .box .box-content .category .category-name .svg-menu {
  position: absolute;
  left: 0;
}

#insight-listing-page .quick-links .box .box-content .category .category-name .svg-menu img {
  width: 30px;
  height: 30px;
  margin-left: 10px;
}

#insight-listing-page .quick-links .box .box-content .category .category-name .svg-menu img svg {
  fill: #8c9baf;
}

#insight-listing-page .quick-links .box .box-content .category .types {
  display: none;
  padding-left: 15px;
}

#insight-listing-page .quick-links .box .box-content .category .types.active {
  display: block;
}

#insight-listing-page .quick-links .box .box-content .category .types .type {
  padding: 5px 0;
}

#insight-listing-page .quick-links .box .box-content .category .types .type .fa {
  color: #28f1b1;
  padding-right: 5px;
  font-size: 16px;
  font-weight: bold;
}

#insight-listing-page .quick-links .box .box-content .industry {
  color: #8c9baf;
  display: block;
  text-decoration: none;
  padding: 5px 0;
}

#insight-listing-page .quick-links .box .box-content .view-all {
  display: block;
  padding-left: 10px;
  color: #8c9baf;
  font-weight: bold;
  cursor: pointer;
  padding-top: 15px;
  text-decoration: none;
}

#insight-listing-page .quick-links .box .box-content .view-all.industry {
  padding-left: 0px;
}

#insight-listing-page .quick-links .box .box-content .view-all .fa {
  padding-right: 5px;
}

#insight-listing-page .quick-links .box .box-content .view-all:hover {
  color: #02cd8d;
}

#insight-listing-page .popular-links {
  padding-bottom: 30px;
}

#insight-listing-page .popular-links .popular-box .popular-title {
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 5px;
}

#insight-listing-page .popular-links .popular-box .popular-content .popular-insight {
  text-decoration: none;
  color: #18293e;
  cursor: pointer;
  padding-top: 30px;
}

#insight-listing-page .popular-links .popular-box .popular-content .popular-insight .popular-insight-title {
  position: relative;
  padding-left: 45px;
  padding-top: 15px;
  font-size: 18px;
  line-height: 1.2;
}

#insight-listing-page .popular-links .popular-box .popular-content .popular-insight .popular-insight-title .popular-insight-writer-name, #insight-listing-page .popular-links .popular-box .popular-content .popular-insight .popular-insight-title .popular-insight-writer-date {
  font-size: 14px;
  color: #8c9baf;
}

#insight-listing-page .popular-links .popular-box .popular-content .popular-insight .popular-insight-title .popular-insight-writer-name {
  padding-top: 5px;
}

#insight-listing-page .popular-links .popular-box .popular-content .popular-insight .popular-insight-title .number {
  position: absolute;
  left: 0;
  color: #8c9baf;
  font-size: 30px;
}

#insight-listing-page .price-guide-links .price-guide-box .price-guide-title {
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 5px;
}

#insight-listing-page .price-guide-links .price-guide-box .price-guide-content .link {
  text-decoration: none;
  color: #18293e;
  cursor: pointer;
  padding-top: 30px;
}

#insight-listing-page .price-guide-links .price-guide-box .price-guide-content .link:hover .link-more {
  color: #28f1b1;
}

#insight-listing-page .price-guide-links .price-guide-box .price-guide-content .link .link-title {
  padding-top: 15px;
  font-size: 18px;
}

#insight-listing-page .price-guide-links .price-guide-box .price-guide-content .link .link-more {
  color: #8c9baf;
}

#insight-listing-page .price-guide-links .price-guide-box .price-guide-content .link .link-more .fa {
  padding-right: 5px;
}

#insight-detail-page-banner {
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 500px;
  position: relative;
}

#insight-detail-page-banner::before {
  content: '';
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  display: block;
  height: 100%;
  position: absolute;
  width: 100%;
}

#insight-detail-page-banner .inner .insight-detail {
  height: 500px;
  position: relative;
}

#insight-detail-page-banner .inner .insight-detail .date {
  position: absolute;
  font-size: 16px;
  top: 30px;
  left: 30px;
  color: #ffffff;
  letter-spacing: 1px;
}

#insight-detail-page-banner .inner .insight-detail .date span {
  font-weight: bold;
}

#insight-detail-page-banner .inner .insight-detail .insight-info {
  min-width: 600px;
  text-align: center;
  position: absolute;
  font-size: 16px;
  color: #ffffff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#insight-detail-page-banner .inner .insight-detail .insight-info .category {
  font-weight: bold;
  font-size: 18px;
}

#insight-detail-page-banner .inner .insight-detail .insight-info .category .svg-menu {
  width: 50px;
  margin: 0 auto;
  padding-bottom: 10px;
}

#insight-detail-page-banner .inner .insight-detail .insight-info .category .svg-menu svg {
  fill: #ffffff;
}

#insight-detail-page-banner .inner .insight-detail .insight-info .title {
  font-size: 30px;
  font-weight: bold;
  padding-bottom: 10px;
}

#insight-detail-page-banner .inner .insight-detail .insight-info .writer .company-logo {
  display: inline;
}

#insight-detail-page-banner .inner .insight-detail .insight-info .writer .company-logo img {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

#insight-detail-page-banner .inner .insight-detail .insight-info .writer span {
  color: #02cd8d;
}

#insight-detail-page-banner .inner .insight-detail .button-share-wrapper {
  position: absolute;
  top: 30px;
  right: 30px;
}

#insight-detail-page-banner .inner .insight-detail .button-share-wrapper .button-share {
  cursor: pointer;
}

#insight-detail-page-banner .inner .insight-detail .button-share-wrapper .button-share .fa {
  color: #ffffff;
  border: 2px solid #28f1b1;
  border-radius: 50%;
  font-size: 18px;
  padding: 10px;
}

#insight-detail-page-banner .inner .insight-detail .button-share-wrapper.expended .linked, #insight-detail-page-banner .inner .insight-detail .button-share-wrapper.expended .facebook {
  display: none;
}

#insight-detail-page-banner .inner .insight-detail .button-share-wrapper .linked, #insight-detail-page-banner .inner .insight-detail .button-share-wrapper .facebook {
  display: block;
}

#insight-detail-page-banner .inner .insight-detail .button-share-wrapper .linked .fa, #insight-detail-page-banner .inner .insight-detail .button-share-wrapper .facebook .fa {
  border: 2px solid #28f1b1;
  border-radius: 50%;
  color: #ffffff;
  padding: 10px;
  font-size: 18px;
  cursor: pointer;
}

#insight-detail-page-banner .inner .insight-detail .button-share-wrapper .linked {
  position: absolute;
  top: 30px;
  right: 50px;
}

#insight-detail-page-banner .inner .insight-detail .button-share-wrapper .facebook {
  position: absolute;
  top: 55px;
  right: 10px;
}

#insight-details-page {
  padding: 60px 0 100px;
  position: relative;
}

#insight-details-page .left-share {
  width: 100px;
  position: absolute;
  left: -90px;
  top: 0;
}

#insight-details-page .left-share .like {
  color: #95a1b2;
}

#insight-details-page .left-share .like span {
  padding-left: 5px;
}

#insight-details-page .left-share .like .fa {
  border-radius: 50%;
  padding: 10px;
  border: 2px solid #eaf0f7;
  font-size: 20px;
  cursor: pointer;
}

#insight-details-page .left-share .bookmark, #insight-details-page .left-share .linked, #insight-details-page .left-share .facebook {
  vertical-align: middle;
  padding: 10px;
}

#insight-details-page .left-share .bookmark .fa, #insight-details-page .left-share .linked .fa, #insight-details-page .left-share .facebook .fa {
  color: #95a1b2;
  font-size: 20px;
  cursor: pointer;
}

#insight-details-page .insight-detail-wrapper {
  border: 2px solid #eaf0f7;
  margin-top: -125px;
  margin-right: 35px;
  background-color: #fff;
}

#insight-details-page .insight-detail-wrapper .insight-summary-title {
  padding: 60px 30px 0;
  color: #95a1b2;
}

#insight-details-page .insight-detail-wrapper .insight-summary-title span.symbol {
  font-weight: bold;
  color: #29f2b1;
}

#insight-details-page .insight-detail-wrapper .insight-summary-content {
  padding-bottom: 60px;
}

#insight-details-page .insight-detail-wrapper .insight-summary-content .summary-title {
  padding: 30px;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.3;
}

#insight-details-page .insight-detail-wrapper .insight-summary-content .summary-content {
  padding: 30px 30px 30px 0;
}

#insight-details-page .insight-detail-wrapper .insight-summary-content .summary-content .question {
  font-size: 22px;
  padding-bottom: 25px;
}

#insight-details-page .insight-detail-wrapper .insight-summary-content .summary-content .answer {
  padding-bottom: 25px;
  font-size: 16px;
}

#insight-details-page .insight-detail-wrapper .insight-summary-content .summary-content .price-link a {
  color: #02cd8d;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
}

#insight-details-page .insight-detail-wrapper .insight-summary-content .summary-content .price-link a .fa {
  padding-right: 5px;
}

#insight-details-page .insight-detail-wrapper .insight-detail-image {
  padding: 0 30px 60px;
  width: 100%;
}

#insight-details-page .insight-detail-wrapper .insight-detail-image img {
  width: 100%;
  padding-bottom: 20px;
}

#insight-details-page .insight-detail-wrapper .insight-detail-image .image-source {
  color: #95a1b2;
  font-weight: bold;
  text-align: right;
}

#insight-details-page .insight-detail-wrapper .insight-detail-information {
  padding: 0 30px 60px;
}

#insight-details-page .insight-detail-wrapper .insight-detail-information .info-bottom-action .like {
  float: left;
  color: #95a1b2;
}

#insight-details-page .insight-detail-wrapper .insight-detail-information .info-bottom-action .like span {
  padding-left: 5px;
}

#insight-details-page .insight-detail-wrapper .insight-detail-information .info-bottom-action .like .fa {
  border-radius: 50%;
  padding: 10px;
  border: 2px solid #eaf0f7;
  font-size: 20px;
  cursor: pointer;
}

#insight-details-page .insight-detail-wrapper .insight-detail-information .info-bottom-action .bookmark, #insight-details-page .insight-detail-wrapper .insight-detail-information .info-bottom-action .linked, #insight-details-page .insight-detail-wrapper .insight-detail-information .info-bottom-action .facebook {
  float: right;
  vertical-align: middle;
  padding: 10px;
}

#insight-details-page .insight-detail-wrapper .insight-detail-information .info-bottom-action .bookmark .fa, #insight-details-page .insight-detail-wrapper .insight-detail-information .info-bottom-action .linked .fa, #insight-details-page .insight-detail-wrapper .insight-detail-information .info-bottom-action .facebook .fa {
  color: #95a1b2;
  font-size: 20px;
  cursor: pointer;
}

#insight-details-page .listing-box {
  padding: 60px 35px 60px 0;
  border-bottom: 2px solid #dedede;
}

#insight-details-page .listing-box .main-insight {
  padding-bottom: 30px;
}

#insight-details-page .listing-box .main-insight .main-insight-content {
  padding-left: 20px;
}

#insight-details-page .listing-box .main-insight .main-insight-content .next {
  padding-bottom: 20px;
  font-weight: bold;
}

#insight-details-page .listing-box .main-insight .main-insight-content .next a {
  color: #02cd8d;
  cursor: pointer;
  text-decoration: none;
}

#insight-details-page .listing-box .main-insight .main-insight-content .next a .fa {
  padding-right: 5px;
}

#insight-details-page .listing-box .main-insight .main-insight-content .featured {
  color: #72839d;
  font-size: 12px;
  font-weight: bold;
}

#insight-details-page .listing-box .main-insight .main-insight-content .main-insight-title {
  font-size: 22px;
  padding-bottom: 20px;
  line-height: 1.2;
}

#insight-details-page .listing-box .main-insight .main-insight-content .main-insight-title a {
  text-decoration: none;
  color: #18293e;
}

#insight-details-page .listing-box .main-insight .main-insight-content .main-insight-description {
  padding-bottom: 20px;
}

#insight-details-page .listing-box .main-insight .main-insight-content .main-content-writer, #insight-details-page .listing-box .main-insight .main-insight-content .main-content-date {
  color: #8c9baf;
}

#insight-details-page .listing-box .main-insight .main-insight-content .main-content-date {
  padding-bottom: 20px;
}

#insight-details-page .listing-box .main-insight .main-insight-image img {
  width: 100%;
  max-height: 250px;
}

#insight-details-page .listing-box .other-insights .other-insight-wrapper {
  width: 100%;
}

#insight-details-page .listing-box .other-insights .other-insight-wrapper .other-insight-content {
  float: left;
  width: 22%;
  margin-right: 4%;
}

#insight-details-page .listing-box .other-insights .other-insight-wrapper .other-insight-content:last-child {
  margin-right: 0%;
}

#insight-details-page .listing-box .other-insights .other-insight-wrapper .other-insight-content:hover .other-insight-content-image .more {
  display: block;
}

#insight-details-page .listing-box .other-insights .other-insight-wrapper .other-insight-content .other-insight-content-image {
  padding-bottom: 10px;
  cursor: pointer;
  position: relative;
}

#insight-details-page .listing-box .other-insights .other-insight-wrapper .other-insight-content .other-insight-content-image img {
  width: 100%;
  height: 150px;
}

#insight-details-page .listing-box .other-insights .other-insight-wrapper .other-insight-content .other-insight-content-image img::after {
  opacity: 0.5;
}

#insight-details-page .listing-box .other-insights .other-insight-wrapper .other-insight-content .other-insight-content-image .more {
  display: none;
  background: rgba(24, 40, 62, 0.8);
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 150px;
  transform: translate(-50%, -54%);
}

#insight-details-page .listing-box .other-insights .other-insight-wrapper .other-insight-content .other-insight-content-image .more span {
  font-weight: bold;
  color: #02cd8d;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#insight-details-page .listing-box .other-insights .other-insight-wrapper .other-insight-content .other-insight-content-title {
  font-weight: bold;
  padding-bottom: 15px;
}

#insight-details-page .listing-box .other-insights .other-insight-wrapper .other-insight-content .other-insight-content-date, #insight-details-page .listing-box .other-insights .other-insight-wrapper .other-insight-content .other-insight-content-writer {
  color: #71839c;
}

#insight-details-page .quick-links {
  padding-bottom: 30px;
}

#insight-details-page .quick-links .box .box-title {
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 5px;
}

#insight-details-page .quick-links .box .box-content .category {
  text-decoration: none;
  color: #18293e;
  cursor: pointer;
}

#insight-details-page .quick-links .box .box-content .category .category-name {
  position: relative;
  padding: 10px 0 10px 55px;
  line-height: 2;
}

#insight-details-page .quick-links .box .box-content .category .category-name:hover {
  background-color: #28f1b1;
}

#insight-details-page .quick-links .box .box-content .category .category-name.active {
  background-color: #eaf0f7;
}

#insight-details-page .quick-links .box .box-content .category .category-name .svg-menu {
  position: absolute;
  left: 0;
}

#insight-details-page .quick-links .box .box-content .category .category-name .svg-menu img {
  width: 30px;
  height: 30px;
  margin-left: 10px;
}

#insight-details-page .quick-links .box .box-content .category .category-name .svg-menu img svg {
  fill: #8c9baf;
}

#insight-details-page .quick-links .box .box-content .category .types {
  display: none;
  padding-left: 15px;
}

#insight-details-page .quick-links .box .box-content .category .types.active {
  display: block;
}

#insight-details-page .quick-links .box .box-content .category .types .type {
  padding: 5px 0;
}

#insight-details-page .quick-links .box .box-content .category .types .type .fa {
  color: #28f1b1;
  padding-right: 5px;
  font-size: 16px;
  font-weight: bold;
}

#insight-details-page .quick-links .box .box-content .industry {
  color: #8c9baf;
  display: block;
  text-decoration: none;
  padding: 5px 0;
}

#insight-details-page .quick-links .box .box-content .view-all {
  display: block;
  padding-left: 10px;
  color: #8c9baf;
  font-weight: bold;
  cursor: pointer;
  padding-top: 15px;
  text-decoration: none;
}

#insight-details-page .quick-links .box .box-content .view-all .fa {
  padding-right: 5px;
}

#insight-details-page .quick-links .box .box-content .view-all:hover {
  color: #02cd8d;
}

#insight-details-page .quick-links .industry-box .industry-title {
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 20px;
}

#insight-details-page .quick-links .industry-box .industry-content .industry {
  color: #8c9baf;
  display: block;
  text-decoration: none;
  padding: 5px 0;
  cursor: pointer;
}

#insight-details-page .popular-links {
  padding-bottom: 30px;
}

#insight-details-page .popular-links .popular-box .popular-title {
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 5px;
}

#insight-details-page .popular-links .popular-box .popular-content .popular-insight {
  text-decoration: none;
  color: #18293e;
  cursor: pointer;
  padding-top: 30px;
}

#insight-details-page .popular-links .popular-box .popular-content .popular-insight .popular-insight-title {
  position: relative;
  padding: 10px 0px 10px 45px;
  font-size: 18px;
  line-height: 1.2;
}

#insight-details-page .popular-links .popular-box .popular-content .popular-insight .popular-insight-title:hover {
  background-color: #eaf0f7;
}

#insight-details-page .popular-links .popular-box .popular-content .popular-insight .popular-insight-title .popular-insight-writer-name, #insight-details-page .popular-links .popular-box .popular-content .popular-insight .popular-insight-title .popular-insight-writer-date {
  font-size: 14px;
  color: #8c9baf;
}

#insight-details-page .popular-links .popular-box .popular-content .popular-insight .popular-insight-title .popular-insight-writer-name {
  padding-top: 5px;
}

#insight-details-page .popular-links .popular-box .popular-content .popular-insight .popular-insight-title .number {
  position: absolute;
  left: 0;
  color: #8c9baf;
  font-size: 30px;
}

#insight-details-page .price-guide-links .price-guide-box .price-guide-title {
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 5px;
}

#insight-details-page .price-guide-links .price-guide-box .price-guide-content .link {
  text-decoration: none;
  color: #18293e;
  cursor: pointer;
  padding-top: 30px;
}

#insight-details-page .price-guide-links .price-guide-box .price-guide-content .link:hover .link-more {
  color: #28f1b1;
}

#insight-details-page .price-guide-links .price-guide-box .price-guide-content .link .link-title {
  padding-top: 15px;
  font-size: 18px;
}

#insight-details-page .price-guide-links .price-guide-box .price-guide-content .link .link-more {
  color: #8c9baf;
}

#insight-details-page .price-guide-links .price-guide-box .price-guide-content .link .link-more .fa {
  padding-right: 5px;
}

@media only screen and (max-width: 991px) {
  #insight-listing-page .listing {
    padding-right: 0px;
  }
  #insight-listing-page .listing .top-insight-image .top-insight-info .title {
    font-size: 20px;
  }
  #insight-listing-page .listing .top-insight-summary .summary-title {
    padding: 20px;
    font-size: 18px;
  }
  #insight-listing-page .listing .top-insight-summary .summary-content {
    padding: 20px 20px 20px 0;
  }
  #insight-listing-page .listing .top-insight-summary .summary-content .question {
    font-size: 16px;
    padding-bottom: 10px;
  }
  #insight-listing-page .listing .top-insight-summary .summary-content .answer {
    padding-bottom: 10px;
    font-size: 14px;
  }
  #insight-listing-page .listing .top-insight-summary .summary-content .button a {
    padding: 10px 35px;
  }
  #insight-listing-page .listing .listing-title {
    font-size: 20px;
  }
  #insight-listing-page .listing-box {
    padding: 40px 0 15px;
  }
  #insight-listing-page .listing-box .main-insight {
    padding-bottom: 30px;
  }
  #insight-listing-page .listing-box .main-insight .main-insight-content {
    padding-left: 0px;
  }
  #insight-listing-page .listing-box .main-insight .main-insight-content .main-insight-title {
    font-size: 20px;
  }
  #insight-listing-page .listing-box .main-insight .main-insight-image img {
    width: 100%;
    max-height: 250px;
  }
  #insight-listing-page .listing-box .other-insights .other-insight-wrapper {
    width: 100%;
  }
  #insight-listing-page .listing-box .other-insights .other-insight-wrapper .other-insight-content {
    width: 45%;
    margin-right: 5%;
    padding-bottom: 25px;
  }
  #insight-listing-page .industry-list {
    padding-right: 0;
  }
  #insight-listing-page .industry-list .col-image {
    height: 200px;
  }
  #insight-listing-page .industry-list .col-content {
    padding: 30px;
    height: auto;
  }
  #insight-listing-page .industry-list .col-content .header {
    font-size: 22px;
  }
  #insight-listing-page .industry-list .col-content .description {
    padding-bottom: 15px;
  }
  #insight-listing-page .industry-list .col-content .button {
    text-align: center;
  }
  #insight-details-page {
    position: relative;
  }
  #insight-details-page .left-share {
    display: none;
  }
  #insight-details-page .insight-detail-wrapper {
    margin-right: 0px;
  }
  #insight-details-page .insight-detail-wrapper .insight-summary-title {
    padding: 30px 20px 0;
  }
  #insight-details-page .insight-detail-wrapper .insight-summary-content .summary-title {
    padding: 20px 0px 20px 20px;
  }
  #insight-details-page .insight-detail-wrapper .insight-summary-content .summary-content {
    padding: 20px 20px 20px 0;
  }
  #insight-details-page .insight-detail-wrapper .insight-summary-content .summary-content .question {
    font-size: 18px;
    padding-bottom: 20px;
  }
  #insight-details-page .insight-detail-wrapper .insight-summary-content .summary-content .answer {
    padding-bottom: 20px;
    font-size: 14px;
  }
  #insight-details-page .listing-box {
    padding: 40px 0 15px;
  }
  #insight-details-page .listing-box .main-insight {
    padding-bottom: 30px;
  }
  #insight-details-page .listing-box .main-insight .main-insight-content {
    padding-left: 0px;
  }
  #insight-details-page .listing-box .main-insight .main-insight-content .main-insight-title {
    font-size: 20px;
  }
  #insight-details-page .listing-box .main-insight .main-insight-image img {
    width: 100%;
    max-height: 250px;
  }
  #insight-details-page .listing-box .other-insights .other-insight-wrapper {
    width: 100%;
  }
  #insight-details-page .listing-box .other-insights .other-insight-wrapper .other-insight-content {
    width: 45%;
    margin-right: 5%;
    padding-bottom: 25px;
  }
}

@media only screen and (max-width: 767px) {
  #insight-listing-page {
    padding: 30px 0 50px;
  }
  #insight-listing-page .container {
    padding-left: 15px;
    padding-right: 15px;
  }
  #insight-listing-page .listing {
    padding-top: 30px;
  }
  #insight-listing-page .listing .listing-title {
    font-size: 20px;
  }
  #insight-listing-page .listing .row {
    flex-direction: initial;
    display: block;
  }
  #insight-listing-page .listing .top-insight-image .date {
    font-size: 10px;
    top: 10px;
    left: 10px;
  }
  #insight-listing-page .listing .top-insight-image .top-insight-info {
    font-size: 12px;
  }
  #insight-listing-page .listing .top-insight-image .top-insight-info .category {
    font-size: 12px;
  }
  #insight-listing-page .listing .top-insight-image .top-insight-info .category .svg-menu img {
    width: 250px;
  }
  #insight-listing-page .listing .top-insight-image .top-insight-info .title {
    font-size: 18px;
  }
  #insight-listing-page .listing .top-insight-summary .summary-title {
    padding: 10px;
    font-size: 18px;
  }
  #insight-listing-page .listing .top-insight-summary .summary-content {
    padding: 10px;
  }
  #insight-listing-page .listing .top-insight-summary .summary-content .button {
    text-align: center;
  }
  #insight-listing-page .listing-box .main-top .listing-category {
    padding-left: 35px;
    font-size: 18px;
  }
  #insight-listing-page .listing-box .main-top .listing-category .svg-menu {
    width: 25px;
  }
  #insight-listing-page .listing-box .main-insight {
    padding-bottom: 30px;
  }
  #insight-listing-page .listing-box .main-insight .row {
    flex-direction: column-reverse;
    display: flex;
  }
  #insight-listing-page .listing-box .main-insight .main-insight-content {
    padding-top: 15px;
  }
  #insight-listing-page .listing-box .main-insight .main-insight-content .main-insight-title {
    font-size: 18px;
    padding-bottom: 5px;
  }
  #insight-listing-page .listing-box .main-insight .main-insight-content .main-insight-description {
    padding-bottom: 5px;
  }
  #insight-listing-page .listing-box .main-insight .main-insight-content .button {
    text-align: center;
  }
  #insight-listing-page .listing-box .main-insight .main-insight-image img {
    max-height: 200px;
  }
  #insight-listing-page .listing-box .other-insights .other-insight-wrapper .other-insight-content .other-insight-content-image .more span {
    font-size: 12px;
  }
  #insight-listing-page .industry-list {
    text-align: center;
    height: auto;
    padding-bottom: 20px;
  }
  #insight-listing-page .row {
    flex-direction: column-reverse;
    display: flex;
  }
  #insight-listing-page .row .quick-links {
    padding: 0px 15px;
  }
  #insight-listing-page .row .quick-links .box .box-title {
    text-align: center;
  }
  #insight-listing-page .row .popular-links {
    padding: 0px 15px;
  }
  #insight-listing-page .row .popular-links .popular-box .popular-title {
    text-align: center;
  }
  #insight-listing-page .row .price-guide-links {
    padding: 0px 15px;
  }
  #insight-listing-page .row .price-guide-links .price-guide-box .price-guide-title {
    text-align: center;
  }
  #insight-detail-page-banner .inner .insight-detail {
    height: 500px;
    position: relative;
  }
  #insight-detail-page-banner .inner .insight-detail .date {
    left: 0;
  }
  #insight-detail-page-banner .inner .insight-detail .date span {
    font-weight: bold;
  }
  #insight-detail-page-banner .inner .insight-detail .insight-info {
    width: 100%;
    top: 40%;
    transform: translate(-50%, -40%);
  }
  #insight-detail-page-banner .inner .insight-detail .insight-info .category {
    font-size: 16px;
  }
  #insight-detail-page-banner .inner .insight-detail .insight-info .category .svg-menu {
    width: 40px;
  }
  #insight-detail-page-banner .inner .insight-detail .insight-info .title {
    font-size: 24px;
  }
  #insight-detail-page-banner .inner .insight-detail .button-share {
    top: 20px;
    right: 0;
  }
  #insight-details-page {
    padding-bottom: 30px;
    position: relative;
  }
  #insight-details-page .insight-detail-wrapper {
    margin-right: 0px;
  }
  #insight-details-page .insight-detail-wrapper .insight-summary-content {
    padding-bottom: 30px;
  }
  #insight-details-page .insight-detail-wrapper .insight-summary-content .summary-title {
    font-size: 22px;
    padding: 20px;
  }
  #insight-details-page .insight-detail-wrapper .insight-summary-content .summary-content {
    padding: 0px 20px 20px;
  }
  #insight-details-page .insight-detail-wrapper .insight-summary-content .summary-content .question {
    font-size: 18px;
    padding-bottom: 20px;
  }
  #insight-details-page .insight-detail-wrapper .insight-summary-content .summary-content .answer {
    padding-bottom: 20px;
    font-size: 14px;
  }
  #insight-details-page .insight-detail-wrapper .insight-detail-information {
    padding: 0 10px 30px;
  }
  #insight-details-page .listing-box {
    margin-bottom: 30px;
  }
  #insight-details-page .listing-box .main-top .listing-category {
    padding-left: 35px;
    font-size: 18px;
  }
  #insight-details-page .listing-box .main-top .listing-category .svg-menu {
    width: 25px;
  }
  #insight-details-page .listing-box .main-insight {
    padding-bottom: 30px;
  }
  #insight-details-page .listing-box .main-insight .row {
    flex-direction: column-reverse;
    display: flex;
  }
  #insight-details-page .listing-box .main-insight .main-insight-content {
    padding-top: 15px;
  }
  #insight-details-page .listing-box .main-insight .main-insight-content .main-insight-title {
    font-size: 18px;
    padding-bottom: 5px;
  }
  #insight-details-page .listing-box .main-insight .main-insight-content .main-insight-description {
    padding-bottom: 5px;
  }
  #insight-details-page .listing-box .main-insight .main-insight-content .button {
    text-align: center;
  }
  #insight-details-page .listing-box .main-insight .main-insight-image img {
    max-height: 200px;
  }
  #insight-details-page .listing-box .other-insights .other-insight-wrapper .other-insight-content .other-insight-content-image .more span {
    font-size: 12px;
  }
  #insight-details-page .quick-links {
    padding: 0px 15px 30px;
  }
  #insight-details-page .quick-links .box .box-title {
    text-align: center;
  }
  #insight-details-page .popular-links {
    padding: 0px 15px 30px;
  }
  #insight-details-page .popular-links .popular-box .popular-title {
    text-align: center;
  }
  #insight-details-page .price-guide-links {
    padding: 0px 15px 30px;
  }
  #insight-details-page .price-guide-links .price-guide-box .price-guide-title {
    text-align: center;
  }
}

#insight-industry-content #first-col .editor {
  text-align: center;
  margin: 0;
  padding: 80px 0;
  background-color: #ffffff;
}

#insight-industry-content #first-col .editor h2 {
  font-size: 30px;
  font-weight: bold;
}

#insight-industry-content #first-col .editor p {
  font-size: 20px;
}

#insight-industry-content #first-col .editor .content {
  margin: 0 auto;
  padding-top: 50px;
}

#insight-industry-content #first-col .editor .content .content-row {
  padding: 40px 15px;
  text-align: center;
  color: #18293e;
  float: left;
  width: calc(33.3333% - 30px);
  margin-right: 30px;
}

#insight-industry-content #first-col .editor .content .content-row:last-child {
  margin-right: 0;
  margin-left: 30px;
}

#insight-industry-content #first-col .editor .content .content-row .image-wrapper {
  margin-bottom: 30px;
  height: 90px;
}

#insight-industry-content #first-col .editor .content .content-row .image-wrapper img {
  border-radius: 50%;
  width: 90px;
  height: 90px;
  object-fit: cover;
}

#insight-industry-content #first-col .editor .content .content-row .legend {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

#insight-industry-content #first-col .editor .content .content-row .description {
  font-size: 14px;
}

#insight-industry-content #second-col .editor {
  padding: 80px 0;
  background-color: #e9f0f6;
}

#insight-industry-content #second-col .editor img {
  max-width: 100%;
}

#insight-industry-content #second-col .editor .info-wrapper-top {
  padding-top: 0px;
}

#insight-industry-content #second-col .editor .info-wrapper-top .col-right .h2 {
  margin-top: 0;
  font-size: 30px;
  font-weight: bold;
  position: relative;
}

#insight-industry-content #second-col .editor .info-wrapper-top .col-right .description {
  font-size: 14px;
  padding-bottom: 20px;
}

#insight-industry-content #second-col .editor .info-wrapper-top .col-right .description span {
  display: block;
  font-weight: bold;
  font-size: 16px;
  padding-bottom: 10px;
}

#insight-industry-content #second-col .editor .info-wrapper-top .col-right .button a {
  font-size: 16px;
  display: inline-block;
  padding: 12px 55px;
  background-color: #18293e;
  color: #ffffff;
  border-radius: 2px;
  text-decoration: none;
  cursor: pointer;
  font-weight: bold;
}

#insight-industry-content #second-col .editor .info-wrapper-top .col-left {
  max-width: 100%;
}

#insight-industry-content #second-col .editor .info-wrapper-top .col-left img {
  width: 100%;
  object-fit: cover;
  max-height: 340px;
}

#insight-industry-content #first-main-content .editor {
  background-color: #ffffff;
}

#insight-industry-content #first-main-content .editor h2 {
  font-size: 30px;
  font-weight: bold;
  text-align: center;
}

#insight-industry-content #first-main-content .editor p {
  text-align: center;
  font-size: 20px;
}

#insight-industry-content #first-main-content .editor .with-image {
  padding: 50px 150px;
}

#insight-industry-content #first-main-content .editor .with-image .content-info {
  float: left;
  position: relative;
  width: 48%;
  margin-right: 2%;
}

#insight-industry-content #first-main-content .editor .with-image .content-info:nth-child(even) {
  margin-left: 2%;
  margin-right: 0;
}

#insight-industry-content #first-main-content .editor .with-image .content-info .image-wrapper {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 90px;
}

#insight-industry-content #first-main-content .editor .with-image .content-info .image-wrapper img {
  border-radius: 50%;
  display: block;
  width: 100%;
  height: 90px;
}

#insight-industry-content #first-main-content .editor .with-image .content-info .box {
  padding: 20px 0 20px 115px;
}

#insight-industry-content #first-main-content .editor .with-image .content-info .box .box-header {
  font-weight: bold;
  font-size: 16px;
}

#insight-industry-content #first-main-content .editor .with-image .content-info .box .box-body {
  font-size: 14px;
}

#insight-industry-content #first-main-content .editor .main-info {
  padding-bottom: 30px;
}

#insight-industry-content #first-main-content .editor .main-info .info {
  float: left;
  width: 48%;
  margin-right: 2%;
  font-size: 14px;
}

#insight-industry-content #first-main-content .editor .main-info .info:nth-child(2) {
  margin-left: 2%;
  margin-right: 0%;
}

#insight-industry-content #first-main-content .editor .main-info .info span {
  font-weight: bold;
  font-size: 16px;
}

#insight-industry-content #first-main-content .editor .main-info .info ul {
  margin-bottom: 15px;
  padding: 0 0 0 30px;
  list-style: none;
}

#insight-industry-content #first-main-content .editor .main-info .info ul li {
  position: relative;
  padding-left: 17px;
}

#insight-industry-content #first-main-content .editor .main-info .info ul li:before {
  content: "";
  display: block;
  background: url(../../images/root/svg/insight/check-circle-solid.svg) no-repeat;
  width: 15px;
  height: 12px;
  position: absolute;
  top: 5px;
  left: 0;
}

#insight-industry-content #first-main-content .editor .image {
  margin: 0 auto;
  text-align: center;
  padding-bottom: 60px;
}

#insight-industry-content #first-main-content .editor .image img {
  max-height: 500px;
}

#insight-industry-content #first-main-content .editor .box-wrapper {
  padding-top: 30px;
}

#insight-industry-content #first-main-content .editor .box-wrapper .box-information {
  margin-bottom: 30px;
  padding: 15px 30px;
  border: 2px solid #e9f0f6;
}

#insight-industry-content #first-main-content .editor .box-wrapper .box-information .box-header {
  font-size: 28px;
  font-weight: bold;
}

#insight-industry-content #first-main-content .editor .box-wrapper .box-information .box-body {
  font-size: 16px;
}

#insight-industry-content #first-main-content .editor .button {
  text-align: center;
  padding-bottom: 60px;
}

#insight-industry-content #first-main-content .editor .button a {
  padding: 12px 55px;
  font-size: 16px;
  border-radius: 2px;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  color: #29f2b1;
  border: 2px solid #29f2b1;
}

#insight-industry-content #second-main-content .editor {
  background-color: #ffffff;
}

#insight-industry-content #second-main-content .editor .client-reviews {
  padding-bottom: 30px;
  background-size: cover;
  background-position: center;
  width: 100%;
  margin-bottom: 60px;
}

#insight-industry-content #second-main-content .editor .client-reviews .review {
  color: #ffffff;
  text-align: center;
  padding: 60px 200px 20px;
}

#insight-industry-content #second-main-content .editor .client-reviews .review .profile {
  width: 90px;
  margin: 0 auto;
  padding-bottom: 15px;
}

#insight-industry-content #second-main-content .editor .client-reviews .review .profile img {
  border-radius: 50%;
  width: 90px;
  height: 90px;
  object-fit: cover;
}

#insight-industry-content #second-main-content .editor .client-reviews .review [class^=row-] {
  padding-bottom: 15px;
}

#insight-industry-content #second-main-content .editor .client-reviews .review .row-1 {
  font-size: 14px;
}

#insight-industry-content #second-main-content .editor .client-reviews .review .row-2 {
  font-size: 28px;
  font-weight: bold;
}

#insight-industry-content #second-main-content .editor .client-reviews .review .row-3 {
  font-size: 16px;
}

#insight-industry-content #second-main-content .editor .client-reviews .slick-dots {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

#insight-industry-content #second-main-content .editor .client-reviews .slick-dots li {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

#insight-industry-content #second-main-content .editor .client-reviews .slick-dots li.slick-active button {
  background-color: #28f1b1;
}

#insight-industry-content #second-main-content .editor .client-reviews .slick-dots li.slick-active button::before {
  opacity: 1;
  color: #28f2b2;
}

#insight-industry-content #second-main-content .editor .client-reviews .slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 10px;
  height: 10px;
  padding: 5px;
  cursor: pointer;
  background-color: #ffffff;
  border: 0;
  outline: none;
}

#insight-industry-content #second-main-content .editor .client-reviews .slick-dots li button::before {
  opacity: 1;
  font-size: 15px;
  font-family: 'slick';
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  content: '•';
  text-align: center;
}

#insight-industry-content #second-main-content .editor h2 {
  text-align: center;
  font-size: 30px;
  font-weight: bold;
}

#insight-industry-content #second-main-content .editor p {
  text-align: center;
  font-size: 20px;
}

#insight-industry-content #second-main-content .editor .content {
  margin: 0 auto;
  padding-top: 50px;
  padding-bottom: 50px;
}

#insight-industry-content #second-main-content .editor .content .content-row {
  padding: 40px 15px;
  border: 2px solid #e9f0f6;
  text-align: center;
  color: #18293e;
  float: left;
  width: calc(33.3333% - 30px);
  margin-right: 30px;
}

#insight-industry-content #second-main-content .editor .content .content-row:last-child {
  margin-right: 0;
  margin-left: 30px;
}

#insight-industry-content #second-main-content .editor .content .content-row .image-wrapper {
  margin-bottom: 30px;
  height: 90px;
}

#insight-industry-content #second-main-content .editor .content .content-row .image-wrapper img {
  width: 90px;
  height: 90px;
  object-fit: cover;
}

#insight-industry-content #second-main-content .editor .content .content-row .legend {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

#insight-industry-content #second-main-content .editor .content .content-row .description {
  font-size: 14px;
  padding-bottom: 15px;
}

#insight-industry-content #second-main-content .editor .content .content-row .link {
  font-size: 16px;
  color: #28f1b1;
  font-weight: bold;
}

#insight-industry-content #second-main-content .editor .content .content-row .link .fa {
  padding-right: 5px;
}

#insight-industry-content #second-main-content .editor .info-wrapper {
  height: 280px;
  margin-bottom: 60px;
  padding-top: 570px;
}

#insight-industry-content #second-main-content .editor .info-wrapper .col-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../../images/root/price-guide/banner_small.jpg");
  height: 280px;
}

#insight-industry-content #second-main-content .editor .info-wrapper .col-content {
  background-color: #1b283b;
  height: 280px;
  padding: 30px 70px;
}

#insight-industry-content #second-main-content .editor .info-wrapper .col-content .header {
  font-size: 28px;
  font-weight: bold;
  color: #ffffff;
}

#insight-industry-content #second-main-content .editor .info-wrapper .col-content .description {
  font-size: 24px;
  color: #ffffff;
  padding-bottom: 20px;
}

#insight-industry-content #second-main-content .editor .info-wrapper .col-content .button a {
  padding: 13px 40px;
  font-size: 16px;
  border-radius: 2px;
  background-color: #29f2b1;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  color: #18293e;
  border: 1px solid #28f1b1;
}

#industry-listing-page {
  padding: 60px 0 100px;
}

#industry-listing-page .listing {
  padding-right: 35px;
  padding-bottom: 60px;
}

#industry-listing-page .listing .listing-title {
  font-size: 30px;
  font-weight: bold;
  padding-bottom: 15px;
  line-height: 1;
}

#industry-listing-page .listing-box {
  padding: 60px 0;
  border-bottom: 2px solid #dedede;
}

#industry-listing-page .listing-box .main-top {
  font-weight: bold;
}

#industry-listing-page .listing-box .main-top .listing-industry {
  padding-bottom: 25px;
  padding-left: 45px;
  position: relative;
  font-size: 22px;
}

#industry-listing-page .listing-box .main-top .listing-industry .svg-menu {
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
}

#industry-listing-page .listing-box .main-top .listing-industry .svg-menu img {
  width: 30px;
}

#industry-listing-page .listing-box .main-top .listing-industry .svg-menu img svg {
  fill: #8c9baf;
}

#industry-listing-page .listing-box .main-industry {
  padding-bottom: 30px;
}

#industry-listing-page .listing-box .main-industry .main-industry-content {
  padding-left: 20px;
}

#industry-listing-page .listing-box .main-industry .main-industry-content .featured {
  letter-spacing: 1.5px;
  color: #72839d;
  font-size: 12px;
  font-weight: bold;
}

#industry-listing-page .listing-box .main-industry .main-industry-content .main-industry-title {
  font-size: 22px;
  padding-bottom: 10px;
  line-height: 1.2;
}

#industry-listing-page .listing-box .main-industry .main-industry-content .main-industry-title a {
  text-decoration: none;
  color: #18293e;
}

#industry-listing-page .listing-box .main-industry .main-industry-content .main-industry-description {
  padding-bottom: 10px;
}

#industry-listing-page .listing-box .main-industry .main-industry-content .button a {
  padding: 10px 35px;
  font-size: 12px;
  font-weight: bold;
  background-color: #18293e;
  text-decoration: none;
  color: #ffffff;
  display: inline-block;
  cursor: pointer;
}

#industry-listing-page .listing-box .main-industry .main-industry-image img {
  width: 100%;
  max-height: 250px;
}

#industry-listing-page .listing-box .other-industries .other-industry-wrapper {
  width: 100%;
}

#industry-listing-page .listing-box .other-industries .other-industry-wrapper .other-industry-content {
  float: left;
  width: 22%;
  margin-right: 4%;
}

#industry-listing-page .listing-box .other-industries .other-industry-wrapper .other-industry-content:last-child {
  margin-right: 0%;
}

#industry-listing-page .listing-box .other-industries .other-industry-wrapper .other-industry-content:hover .other-industry-content-image .more {
  display: block;
}

#industry-listing-page .listing-box .other-industries .other-industry-wrapper .other-industry-content .other-industry-content-image {
  padding-bottom: 10px;
  cursor: pointer;
  position: relative;
}

#industry-listing-page .listing-box .other-industries .other-industry-wrapper .other-industry-content .other-industry-content-image img {
  width: 100%;
  height: 150px;
}

#industry-listing-page .listing-box .other-industries .other-industry-wrapper .other-industry-content .other-industry-content-image img::after {
  opacity: 0.5;
}

#industry-listing-page .listing-box .other-industries .other-industry-wrapper .other-industry-content .other-industry-content-image .more {
  display: none;
  background: rgba(24, 40, 62, 0.8);
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 150px;
  transform: translate(-50%, -54%);
}

#industry-listing-page .listing-box .other-industries .other-industry-wrapper .other-industry-content .other-industry-content-image .more span {
  font-weight: bold;
  color: #02cd8d;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#industry-listing-page .listing-box .other-industries .other-industry-wrapper .other-industry-content .other-industry-content-title {
  font-weight: bold;
  padding-bottom: 15px;
}

#industry-listing-page .insight-list {
  height: 300px;
  padding-right: 35px;
}

#industry-listing-page .insight-list .col-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../../images/root/industry/listing.jpg");
  height: 300px;
}

#industry-listing-page .insight-list .col-content {
  background-color: #ebf0f6;
  height: 300px;
  padding: 30px 50px;
}

#industry-listing-page .insight-list .col-content .header {
  font-size: 24px;
  padding-bottom: 15px;
}

#industry-listing-page .insight-list .col-content .description {
  font-size: 16px;
  padding-bottom: 25px;
}

#industry-listing-page .insight-list .col-content .button a {
  font-weight: bold;
  padding: 13px 40px;
  font-size: 14px;
  border-radius: 2px;
  background-color: #18293e;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
}

#industry-listing-page .quick-links {
  padding-bottom: 30px;
}

#industry-listing-page .quick-links .box .box-title {
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 5px;
}

#industry-listing-page .quick-links .box .box-content .category {
  text-decoration: none;
  color: #18293e;
  cursor: pointer;
}

#industry-listing-page .quick-links .box .box-content .category .category-name {
  position: relative;
  padding: 10px 0 10px 55px;
  line-height: 2;
}

#industry-listing-page .quick-links .box .box-content .category .category-name:hover {
  background-color: #28f1b1;
}

#industry-listing-page .quick-links .box .box-content .category .category-name.active {
  background-color: #eaf0f7;
}

#industry-listing-page .quick-links .box .box-content .category .category-name .svg-menu {
  position: absolute;
  left: 0;
}

#industry-listing-page .quick-links .box .box-content .category .category-name .svg-menu img {
  width: 30px;
  height: 30px;
  margin-left: 10px;
}

#industry-listing-page .quick-links .box .box-content .category .category-name .svg-menu img svg {
  fill: #8c9baf;
}

#industry-listing-page .quick-links .box .box-content .category .types {
  display: none;
  padding-left: 15px;
}

#industry-listing-page .quick-links .box .box-content .category .types.active {
  display: block;
}

#industry-listing-page .quick-links .box .box-content .category .types .type {
  padding: 5px 0;
}

#industry-listing-page .quick-links .box .box-content .category .types .type .fa {
  color: #28f1b1;
  padding-right: 5px;
  font-size: 16px;
  font-weight: bold;
}

#industry-listing-page .quick-links .box .box-content .industry {
  color: #8c9baf;
  display: block;
  text-decoration: none;
  padding: 5px 0;
}

#industry-listing-page .popular-links {
  padding-bottom: 30px;
}

#industry-listing-page .popular-links .popular-box .popular-title {
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 5px;
}

#industry-listing-page .popular-links .popular-box .popular-content .popular-insight {
  text-decoration: none;
  color: #18293e;
  cursor: pointer;
  padding-top: 30px;
}

#industry-listing-page .popular-links .popular-box .popular-content .popular-insight .popular-insight-title {
  position: relative;
  padding-left: 45px;
  padding-top: 15px;
  font-size: 18px;
  line-height: 1.2;
}

#industry-listing-page .popular-links .popular-box .popular-content .popular-insight .popular-insight-title .popular-insight-writer-name, #industry-listing-page .popular-links .popular-box .popular-content .popular-insight .popular-insight-title .popular-insight-writer-date {
  font-size: 14px;
  color: #8c9baf;
}

#industry-listing-page .popular-links .popular-box .popular-content .popular-insight .popular-insight-title .popular-insight-writer-name {
  padding-top: 5px;
}

#industry-listing-page .popular-links .popular-box .popular-content .popular-insight .popular-insight-title .number {
  position: absolute;
  left: 0;
  color: #8c9baf;
  font-size: 30px;
}

#industry-listing-page .price-guide-links .price-guide-box .price-guide-title {
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 5px;
}

#industry-listing-page .price-guide-links .price-guide-box .price-guide-content .link {
  text-decoration: none;
  color: #18293e;
  cursor: pointer;
  padding-top: 30px;
}

#industry-listing-page .price-guide-links .price-guide-box .price-guide-content .link:hover .link-more {
  color: #28f1b1;
}

#industry-listing-page .price-guide-links .price-guide-box .price-guide-content .link .link-title {
  padding-top: 15px;
  font-size: 18px;
}

#industry-listing-page .price-guide-links .price-guide-box .price-guide-content .link .link-more {
  color: #8c9baf;
}

#industry-listing-page .price-guide-links .price-guide-box .price-guide-content .link .link-more .fa {
  padding-right: 5px;
}

#insight-industry-banner {
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 500px;
  position: relative;
}

#insight-industry-banner::before {
  content: '';
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  display: block;
  height: 100%;
  position: absolute;
  width: 100%;
}

#insight-industry-banner .inner {
  height: 500px;
  position: relative;
}

#insight-industry-banner .inner .insight-industry-detail {
  width: 50%;
  position: absolute;
  font-size: 16px;
  color: #ffffff;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

#insight-industry-banner .inner .insight-industry-detail .type {
  font-weight: bold;
  padding-bottom: 15px;
}

#insight-industry-banner .inner .insight-industry-detail .title {
  font-size: 26px;
  font-weight: bold;
  padding-bottom: 15px;
  line-height: 30px;
  color: #29f2b1;
}

#insight-industry-banner .inner .insight-industry-detail .description {
  padding-bottom: 15px;
}

#insight-industry-banner .inner .insight-industry-detail .button a {
  display: inline-block;
  padding: 15px 45px;
  background-color: #29f2b1;
  color: #18293e;
  border-radius: 2px;
  text-decoration: none;
  cursor: pointer;
  font-weight: bold;
}

#insight-industry-content #first-col {
  text-align: center;
  margin: 0;
  padding: 80px 0;
}

#insight-industry-content #first-col h2 {
  font-size: 30px;
  font-weight: bold;
}

#insight-industry-content #first-col p {
  font-size: 20px;
}

#insight-industry-content #first-col .content {
  margin: 0 auto;
  padding-top: 50px;
}

#insight-industry-content #first-col .content .content-row {
  padding: 40px 15px;
  text-align: center;
  color: #18293e;
  float: left;
  width: calc(33.3333% - 30px);
  margin-right: 30px;
}

#insight-industry-content #first-col .content .content-row:last-child {
  margin-right: 0;
  margin-left: 30px;
}

#insight-industry-content #first-col .content .content-row .image-wrapper {
  margin-bottom: 30px;
  height: 90px;
}

#insight-industry-content #first-col .content .content-row .image-wrapper img {
  border-radius: 50%;
  width: 90px;
  height: 90px;
  object-fit: cover;
}

#insight-industry-content #first-col .content .content-row .legend {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

#insight-industry-content #first-col .content .content-row .description {
  font-size: 14px;
}

#insight-industry-content #second-col {
  padding: 80px 0;
  background-color: #e9f0f6;
}

#insight-industry-content #second-col .info-wrapper-top .col-right h2 {
  margin-top: 0 !important;
  font-size: 30px;
  font-weight: bold;
  position: relative;
}

#insight-industry-content #second-col .info-wrapper-top .col-right .description {
  font-size: 14px;
  padding-bottom: 20px;
}

#insight-industry-content #second-col .info-wrapper-top .col-right .description span {
  display: block;
  font-weight: bold;
  font-size: 16px;
  padding-bottom: 10px;
}

#insight-industry-content #second-col .info-wrapper-top .col-right .button a {
  font-size: 16px;
  display: inline-block;
  padding: 12px 55px;
  background-color: #18293e;
  color: #ffffff;
  border-radius: 2px;
  text-decoration: none;
  cursor: pointer;
  font-weight: bold;
}

#insight-industry-content #second-col .info-wrapper-top .col-left {
  max-width: 100%;
}

#insight-industry-content #second-col .info-wrapper-top .col-left img {
  max-width: 100%;
}

#insight-industry-content #second-col .info-wrapper-top .col-left img {
  width: 100%;
  object-fit: cover;
  max-height: 340px;
}

#insight-industry-content #first-main-content {
  padding-top: 50px;
}

#insight-industry-content #first-main-content h2 {
  font-size: 30px;
  font-weight: bold;
  text-align: center;
}

#insight-industry-content #first-main-content p {
  text-align: center;
  font-size: 20px;
}

#insight-industry-content #first-main-content .with-image {
  padding: 50px 150px;
}

#insight-industry-content #first-main-content .with-image .content-info {
  float: left;
  position: relative;
  width: 48%;
  margin-right: 2%;
}

#insight-industry-content #first-main-content .with-image .content-info:nth-child(even) {
  margin-left: 2%;
  margin-right: 0;
}

#insight-industry-content #first-main-content .with-image .content-info .image-wrapper {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 90px;
}

#insight-industry-content #first-main-content .with-image .content-info .image-wrapper img {
  border-radius: 50%;
  display: block;
  width: 100%;
  height: 90px;
}

#insight-industry-content #first-main-content .with-image .content-info .box {
  padding: 20px 0 20px 115px;
}

#insight-industry-content #first-main-content .with-image .content-info .box .box-header {
  font-weight: bold;
  font-size: 16px;
}

#insight-industry-content #first-main-content .with-image .content-info .box .box-body {
  font-size: 14px;
}

#insight-industry-content #first-main-content .main-info {
  padding-bottom: 30px;
}

#insight-industry-content #first-main-content .main-info .info {
  float: left;
  width: 48%;
  margin-right: 2%;
  font-size: 14px;
}

#insight-industry-content #first-main-content .main-info .info:nth-child(2) {
  margin-left: 2%;
  margin-right: 0%;
}

#insight-industry-content #first-main-content .main-info .info span {
  font-weight: bold;
  font-size: 16px;
}

#insight-industry-content #first-main-content .main-info .info ul {
  margin-bottom: 15px;
  padding: 0 0 0 30px;
  list-style: none;
}

#insight-industry-content #first-main-content .main-info .info ul li {
  position: relative;
  padding-left: 17px;
}

#insight-industry-content #first-main-content .main-info .info ul li:before {
  content: "";
  display: block;
  background: url(../../images/root/svg/insight/check-circle-solid.svg) no-repeat;
  width: 15px;
  height: 12px;
  position: absolute;
  top: 5px;
  left: 0;
}

#insight-industry-content #first-main-content .image {
  margin: 0 auto;
  text-align: center;
  padding-bottom: 60px;
}

#insight-industry-content #first-main-content .image img {
  max-height: 500px;
  width: 100%;
  object-fit: cover;
}

#insight-industry-content #first-main-content .box-wrapper {
  padding-top: 30px;
}

#insight-industry-content #first-main-content .box-wrapper .box-information {
  margin-bottom: 30px;
  padding: 15px 30px;
  border: 2px solid #e9f0f6;
}

#insight-industry-content #first-main-content .box-wrapper .box-information .box-header {
  font-size: 28px;
  font-weight: bold;
}

#insight-industry-content #first-main-content .box-wrapper .box-information .box-body {
  font-size: 16px;
}

#insight-industry-content #first-main-content .button {
  text-align: center;
  padding-bottom: 60px;
}

#insight-industry-content #first-main-content .button a {
  padding: 12px 55px;
  font-size: 16px;
  border-radius: 2px;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  color: #29f2b1;
  border: 2px solid #29f2b1;
}

#insight-industry-content #portfolio {
  padding: 50px 0;
  color: #18293e;
}

#insight-industry-content #portfolio h2 {
  font-size: 30px;
  font-weight: bold;
  text-align: center;
}

#insight-industry-content #portfolio p {
  text-align: center;
  font-size: 20px;
}

#insight-industry-content #portfolio .works-listing-results {
  margin-left: -10px;
  margin-right: -10px;
}

#insight-industry-content #portfolio .works-listing-results .portfolio-wrapper {
  float: left;
  width: 20%;
  padding: 0 10px 20px 10px;
}

#insight-industry-content #portfolio .works-listing-results .portfolio-wrapper .portfolio {
  font-family: "roboto";
  text-decoration: none;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
  margin-bottom: 20px;
  border-radius: 5px;
}

#insight-industry-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .portfolio-ajax-link {
  cursor: pointer;
}

#insight-industry-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .portfolio-wrapper-heading {
  padding-bottom: 75%;
  background-size: cover;
  background-position: 0 0;
  position: relative;
}

#insight-industry-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .portfolio-wrapper-heading .portfolio-wrapper-heading-title {
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  z-index: 10;
  color: #fff;
  font-size: 18px;
  line-height: 26px;
  width: 75%;
}

#insight-industry-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .lazy {
  position: relative;
  border-radius: 4px;
}

#insight-industry-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .lazy::after {
  content: '';
  display: block;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("../../images/root/work/grid-grey-layer.png");
  background-repeat: repeat;
  background-size: 1px 190px;
}

#insight-industry-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .lazy .location {
  z-index: 3;
  position: absolute;
  bottom: 8px;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
}

#insight-industry-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .lazy .location .fa {
  color: #ffffff;
  padding-top: 5px;
  font-size: 24px;
}

#insight-industry-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .lazy .location span {
  color: white;
  padding-left: 5px;
  font-size: 14px;
}

#insight-industry-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .bookmark {
  top: 0px;
}

#insight-industry-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .bookmark .review .fa-bookmark {
  z-index: 3;
  color: #f5c343;
  position: absolute;
  font-size: 50px;
  transform: scaleX(0.8);
  top: -2%;
  right: 5%;
}

#insight-industry-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .bookmark .review .fa-star {
  z-index: 3;
  color: #ffffff;
  position: absolute;
  font-size: 20px;
  right: 8.5%;
  top: 2%;
}

#insight-industry-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .bookmark .feature {
  position: absolute;
  top: -5px;
  right: 10%;
  z-index: 3;
}

#insight-industry-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .content {
  padding: 0 10px 15px 15px;
}

#insight-industry-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .content .title {
  color: #18293E;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
  line-height: 1.3;
  letter-spacing: 0;
  padding-bottom: 10px;
}

#insight-industry-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .content .rate .fa {
  color: #f5c343;
}

#insight-industry-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .content .services {
  margin-left: -4px;
  margin-top: 4px;
}

#insight-industry-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .content .services .service {
  margin-left: 4px;
  margin-bottom: 4px;
  background-color: #505961;
  display: inline-block;
  padding: 3px 8px;
  border-radius: 2px;
  color: rgba(255, 255, 255, 0.75);
}

#insight-industry-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .content .services .service.hidden {
  display: none;
}

#insight-industry-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .content .divider {
  height: 1px;
  background-color: #edf1f3;
  margin-top: 10px;
  margin-bottom: 10px;
}

#insight-industry-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .content .divider-short {
  height: 1px;
  width: 10px;
  background-color: #868686;
  margin-top: 10px;
  margin-bottom: 10px;
}

#insight-industry-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .content .description {
  color: #737373;
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  line-height: 1.3;
}

#insight-industry-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .content .details .tags {
  color: #8b8b8b;
  font-size: 14px;
  padding-top: 10px;
  font-weight: 300;
}

#insight-industry-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .content .details .item-client-name {
  width: 65%;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
}

#insight-industry-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .content .details .premium {
  width: 35%;
  text-align: right;
}

#insight-industry-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .content .details .premium span {
  color: #ffffff;
  background-color: #ee7781;
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 2px;
}

#insight-industry-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .content .details .pro {
  width: 35%;
  text-align: right;
}

#insight-industry-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .content .details .pro span {
  color: #ffffff;
  background-color: #071c2f;
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 2px;
}

#insight-industry-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .footer {
  background-color: #f9fafb;
  padding: 10px 50px 10px 15px;
  min-height: 46px;
  position: relative;
  border-radius: 4px;
}

#insight-industry-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .footer .services {
  display: block;
}

#insight-industry-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .footer .services .service {
  margin-right: 4px;
  margin-bottom: 4px;
  background-color: #97a1b0;
  display: inline-block;
  padding: 3px 8px;
  border-radius: 2px;
  color: rgba(255, 255, 255, 0.75);
}

#insight-industry-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .footer .services .service.hidden {
  display: none;
}

#insight-industry-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .footer .col-favourite {
  position: absolute;
  right: 10px;
  top: 10px;
  display: block;
  text-decoration: none;
  cursor: pointer;
}

#insight-industry-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .footer .col-favourite .fa {
  color: #28f2b2;
}

#insight-industry-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .footer .col-favourite .fa-heart-o {
  display: block;
}

#insight-industry-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .footer .col-favourite .fa-heart {
  display: none;
}

#insight-industry-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .footer .col-favourite.un-favourite .fa-heart-o {
  display: none;
}

#insight-industry-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .footer .col-favourite.un-favourite .fa-heart {
  display: block;
}

#insight-industry-content #portfolio .works-listing-results:after {
  content: '';
  display: table;
  clear: both;
}

#insight-industry-content #portfolio .button {
  text-align: center;
  padding-bottom: 60px;
}

#insight-industry-content #portfolio .button a {
  padding: 12px 55px;
  font-size: 16px;
  border-radius: 2px;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  color: #29f2b1;
  border: 2px solid #29f2b1;
}

#insight-industry-content #bottom-links {
  display: block;
  background-color: #e9f0f6;
  padding: 45px 0;
}

#insight-industry-content #bottom-links .quick-links {
  float: left;
  width: calc(33.3333% - 20px);
  margin-right: 20px;
}

#insight-industry-content #bottom-links .quick-links .box .box-title {
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 20px;
}

#insight-industry-content #bottom-links .quick-links .box .box-content .category {
  text-decoration: none;
  color: #18293e;
  cursor: pointer;
}

#insight-industry-content #bottom-links .quick-links .box .box-content .category .category-name {
  position: relative;
  padding: 10px 0 10px 55px;
  line-height: 2;
}

#insight-industry-content #bottom-links .quick-links .box .box-content .category .category-name:hover {
  background-color: #eaf0f7;
}

#insight-industry-content #bottom-links .quick-links .box .box-content .category .category-name .svg-menu {
  position: absolute;
  left: 0;
}

#insight-industry-content #bottom-links .quick-links .box .box-content .category .category-name .svg-menu img {
  width: 30px;
  height: 30px;
  margin-left: 10px;
}

#insight-industry-content #bottom-links .quick-links .box .box-content .category .category-name .svg-menu img svg {
  fill: #8c9baf;
}

#insight-industry-content #bottom-links .quick-links .box .box-content .category .types {
  display: none;
  padding-left: 15px;
}

#insight-industry-content #bottom-links .quick-links .box .box-content .category .types.active {
  display: block;
}

#insight-industry-content #bottom-links .quick-links .box .box-content .category .types .type {
  padding: 5px 0;
}

#insight-industry-content #bottom-links .quick-links .box .box-content .category .types .type .fa {
  color: #28f1b1;
  padding-right: 5px;
  font-size: 16px;
  font-weight: bold;
}

#insight-industry-content #bottom-links .quick-links .box .box-content .industry {
  color: #8c9baf;
  display: block;
  text-decoration: none;
  padding: 5px 0;
}

#insight-industry-content #bottom-links .quick-links .industry-box .industry-title {
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 20px;
}

#insight-industry-content #bottom-links .quick-links .industry-box .industry-content .industry {
  color: #8c9baf;
  display: block;
  text-decoration: none;
  padding: 5px 0;
  cursor: pointer;
}

#insight-industry-content #bottom-links .popular-links {
  float: left;
  width: calc(33.3333% - 20px);
  margin-right: 20px;
}

#insight-industry-content #bottom-links .popular-links .popular-box .popular-title {
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 20px;
}

#insight-industry-content #bottom-links .popular-links .popular-box .popular-content .popular-insight {
  width: 100%;
  margin-right: 2%;
  text-decoration: none;
  color: #18293e;
  cursor: pointer;
}

#insight-industry-content #bottom-links .popular-links .popular-box .popular-content .popular-insight:nth-child(even) {
  margin-right: 0;
  margin-left: 2%;
}

#insight-industry-content #bottom-links .popular-links .popular-box .popular-content .popular-insight:nth-child(1), #insight-industry-content #bottom-links .popular-links .popular-box .popular-content .popular-insight:nth-child(2) {
  padding-top: 0;
}

#insight-industry-content #bottom-links .popular-links .popular-box .popular-content .popular-insight .popular-insight-title {
  position: relative;
  padding: 10px 0px 10px 45px;
  font-size: 18px;
  line-height: 1.2;
}

#insight-industry-content #bottom-links .popular-links .popular-box .popular-content .popular-insight .popular-insight-title:hover {
  background-color: #eaf0f7;
}

#insight-industry-content #bottom-links .popular-links .popular-box .popular-content .popular-insight .popular-insight-title .popular-insight-writer-name, #insight-industry-content #bottom-links .popular-links .popular-box .popular-content .popular-insight .popular-insight-title .popular-insight-writer-date {
  font-size: 14px;
  color: #8c9baf;
}

#insight-industry-content #bottom-links .popular-links .popular-box .popular-content .popular-insight .popular-insight-title .popular-insight-writer-name {
  padding-top: 5px;
}

#insight-industry-content #bottom-links .popular-links .popular-box .popular-content .popular-insight .popular-insight-title .number {
  position: absolute;
  left: 0;
  color: #8c9baf;
  font-size: 30px;
  top: 50%;
  transform: translateY(-50%);
}

#insight-industry-content #bottom-links .price-guide-links {
  float: left;
  width: calc(33.3333% - 20px);
  margin-right: 20px;
}

#insight-industry-content #bottom-links .price-guide-links .price-guide-box .price-guide-title {
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 20px;
}

#insight-industry-content #bottom-links .price-guide-links .price-guide-box .price-guide-content .link {
  text-decoration: none;
  color: #18293e;
  cursor: pointer;
  padding-bottom: 30px;
}

#insight-industry-content #bottom-links .price-guide-links .price-guide-box .price-guide-content .link:hover .link-more {
  color: #28f1b1;
}

#insight-industry-content #bottom-links .price-guide-links .price-guide-box .price-guide-content .link .link-title {
  padding-bottom: 15px;
  font-size: 18px;
}

#insight-industry-content #bottom-links .price-guide-links .price-guide-box .price-guide-content .link .link-more {
  color: #8c9baf;
}

#insight-industry-content #bottom-links .price-guide-links .price-guide-box .price-guide-content .link .link-more .fa {
  padding-right: 5px;
}

#insight-industry-content #second-main-content .client-reviews {
  padding-bottom: 30px;
  background-size: cover;
  background-position: center;
  width: 100%;
  margin-bottom: 60px;
}

#insight-industry-content #second-main-content .client-reviews .review {
  color: #ffffff;
  text-align: center;
  padding: 60px 200px 20px;
}

#insight-industry-content #second-main-content .client-reviews .review .profile {
  width: 90px;
  margin: 0 auto;
  padding-bottom: 15px;
}

#insight-industry-content #second-main-content .client-reviews .review .profile img {
  border-radius: 50%;
  width: 90px;
  height: 90px;
  object-fit: cover;
}

#insight-industry-content #second-main-content .client-reviews .review [class^=row-] {
  padding-bottom: 15px;
}

#insight-industry-content #second-main-content .client-reviews .review .row-1 {
  font-size: 14px;
}

#insight-industry-content #second-main-content .client-reviews .review .row-2 {
  font-size: 28px;
  font-weight: bold;
}

#insight-industry-content #second-main-content .client-reviews .review .row-3 {
  font-size: 16px;
}

#insight-industry-content #second-main-content .client-reviews .slick-dots {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

#insight-industry-content #second-main-content .client-reviews .slick-dots li {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

#insight-industry-content #second-main-content .client-reviews .slick-dots li.slick-active button {
  background-color: #28f1b1;
}

#insight-industry-content #second-main-content .client-reviews .slick-dots li.slick-active button::before {
  opacity: 1;
  color: #28f2b2;
}

#insight-industry-content #second-main-content .client-reviews .slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 10px;
  height: 10px;
  padding: 5px;
  cursor: pointer;
  background-color: #ffffff;
  border: 0;
  outline: none;
}

#insight-industry-content #second-main-content .client-reviews .slick-dots li button::before {
  opacity: 1;
  font-size: 15px;
  font-family: 'slick';
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  content: '•';
  text-align: center;
}

#insight-industry-content #second-main-content h2 {
  text-align: center;
  font-size: 30px;
  font-weight: bold;
}

#insight-industry-content #second-main-content p {
  text-align: center;
  font-size: 20px;
}

#insight-industry-content #second-main-content .content {
  margin: 0 auto;
  padding-top: 50px;
  padding-bottom: 50px;
}

#insight-industry-content #second-main-content .content .content-row {
  padding: 40px 15px;
  border: 2px solid #e9f0f6;
  text-align: center;
  color: #18293e;
  float: left;
  width: calc(33.3333% - 30px);
  margin-right: 30px;
}

#insight-industry-content #second-main-content .content .content-row:last-child {
  margin-right: 0;
  margin-left: 30px;
}

#insight-industry-content #second-main-content .content .content-row .image-wrapper {
  margin-bottom: 30px;
  height: 90px;
}

#insight-industry-content #second-main-content .content .content-row .image-wrapper img {
  width: 90px;
  height: 90px;
  object-fit: cover;
}

#insight-industry-content #second-main-content .content .content-row .legend {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

#insight-industry-content #second-main-content .content .content-row .description {
  font-size: 14px;
  padding-bottom: 15px;
}

#insight-industry-content #second-main-content .content .content-row .link {
  font-size: 16px;
  color: #28f1b1;
  font-weight: bold;
}

#insight-industry-content #second-main-content .content .content-row .link a {
  text-decoration: none;
  color: #28f1b1;
}

#insight-industry-content #second-main-content .content .content-row .link .fa {
  padding-right: 5px;
}

#insight-industry-content #second-main-content .info-wrapper {
  height: 280px;
  margin-bottom: 60px;
}

#insight-industry-content #second-main-content .info-wrapper .col-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../../images/root/price-guide/banner_small.jpg");
  height: 280px;
}

#insight-industry-content #second-main-content .info-wrapper .col-content {
  background-color: #1b283b;
  height: 280px;
  padding: 30px 70px;
}

#insight-industry-content #second-main-content .info-wrapper .col-content .header {
  font-size: 28px;
  font-weight: bold;
  color: #ffffff;
}

#insight-industry-content #second-main-content .info-wrapper .col-content .description {
  font-size: 24px;
  color: #ffffff;
  padding-bottom: 20px;
}

#insight-industry-content #second-main-content .info-wrapper .col-content .button a {
  padding: 13px 40px;
  font-size: 16px;
  border-radius: 2px;
  background-color: #29f2b1;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  color: #18293e;
  border: 1px solid #28f1b1;
}

#insight-industry-content #rating {
  text-align: center;
  padding: 60px 0px 90px;
}

#insight-industry-content #rating .title {
  font-size: 28px;
  font-weight: bold;
  padding-bottom: 30px;
}

#insight-industry-content #rating .title.eng_version {
  font-size: 24px;
}

#insight-industry-content #rating .rating-score .info {
  border: 2px solid #eaf0f7;
  padding: 45px 30px 30px;
  background-color: #ffffff;
  width: 32%;
  float: left;
  margin-right: 15px;
}

#insight-industry-content #rating .rating-score .title {
  font-size: 16px;
  font-weight: inherit;
  color: #70839c;
}

#insight-industry-content #rating .rating-score .title span {
  font-weight: bold;
}

#insight-industry-content #rating .rating-score .description {
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 30px;
}

#insight-industry-content #rating .rating-score .marks {
  padding-bottom: 10px;
}

#insight-industry-content #rating .rating-score .marks .col-left .info-row:after {
  content: '';
  display: block;
  clear: both;
}

#insight-industry-content #rating .rating-score .marks .col-left .info-row .info-label {
  float: left;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.8;
}

#insight-industry-content #rating .rating-score .marks .col-left .info-row .info-value {
  float: right;
  position: relative;
}

#insight-industry-content #rating .rating-score .marks .col-left .info-row .info-value #Isolation_Mode {
  margin: 0 auto;
}

#insight-industry-content #rating .rating-score .marks .col-right {
  text-align: center;
}

#insight-industry-content #rating .rating-score .marks .col-right .circles-text {
  font-size: 24px !important;
  color: #18293e !important;
}

#insight-industry-content #rating .rating-score .marks .col-right .circles-text b {
  color: #18293e !important;
}

#insight-industry-content #rating .rating-score .slick-dots {
  position: absolute;
  bottom: -55px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

#insight-industry-content #rating .rating-score .slick-dots li {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
  left: -30px;
}

#insight-industry-content #rating .rating-score .slick-dots li.slick-active button {
  background-color: #28f1b1;
}

#insight-industry-content #rating .rating-score .slick-dots li.slick-active button::before {
  opacity: 1;
  color: #28f2b2;
}

#insight-industry-content #rating .rating-score .slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 10px;
  height: 10px;
  padding: 5px;
  cursor: pointer;
  background-color: #71839c;
  border: 0;
  outline: none;
}

#insight-industry-content #rating .rating-score .slick-dots li button::before {
  opacity: 1;
  font-size: 10px;
  font-family: 'slick';
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  content: '•';
  text-align: center;
}

@media only screen and (max-width: 991px) {
  #industry-listing-page .listing {
    padding-right: 0px;
  }
  #industry-listing-page .listing .listing-title {
    font-size: 20px;
  }
  #industry-listing-page .listing-box {
    padding: 40px 0 15px;
  }
  #industry-listing-page .listing-box .main-industry {
    padding-bottom: 30px;
  }
  #industry-listing-page .listing-box .main-industry .main-insight-content {
    padding-left: 0px;
  }
  #industry-listing-page .listing-box .main-industry .main-insight-content .main-industry-title {
    font-size: 20px;
  }
  #industry-listing-page .listing-box .main-industry .main-industry-image img {
    width: 100%;
    max-height: 250px;
  }
  #industry-listing-page .listing-box .other-industries .other-industry-wrapper {
    width: 100%;
  }
  #industry-listing-page .listing-box .other-industries .other-industry-wrapper .other-industry-content {
    width: 45%;
    margin-right: 5%;
    padding-bottom: 25px;
  }
  #industry-listing-page .insight-list {
    padding-right: 0;
  }
  #industry-listing-page .insight-list .col-image {
    height: 200px;
  }
  #industry-listing-page .insight-list .col-content {
    padding: 30px;
    height: auto;
  }
  #industry-listing-page .insight-list .col-content .header {
    font-size: 22px;
  }
  #industry-listing-page .insight-list .col-content .description {
    padding-bottom: 15px;
  }
  #industry-listing-page .insight-list .col-content .button {
    text-align: center;
  }
  #insight-industry-banner .inner .insight-industry-detail {
    text-align: center;
    width: 100%;
  }
  #insight-industry-content #first-col {
    padding: 20px 0;
  }
  #insight-industry-content #first-col p {
    font-size: 14px;
  }
  #insight-industry-content #first-col .content {
    padding-top: 20px;
  }
  #insight-industry-content #first-col .content .content-row {
    padding: 20px 15px;
    width: calc(33.3333% - 15px);
    margin-right: 15px;
  }
  #insight-industry-content #first-col .content .content-row:last-child {
    margin-left: 15px;
  }
  #insight-industry-content #first-col .content .content-row .description {
    font-size: 14px;
    word-break: break-all;
  }
  #insight-industry-content #second-col {
    padding: 40px 0;
    background-color: #e9f0f6;
  }
  #insight-industry-content #second-col .info-wrapper-top .col-right h2 {
    font-size: 24px;
  }
  #insight-industry-content #second-col .info-wrapper-top .col-right .button a {
    font-size: 12px;
  }
  #insight-industry-content #first-main-content {
    padding-top: 20px;
  }
  #insight-industry-content #first-main-content p {
    font-size: 14px;
  }
  #insight-industry-content #first-main-content .with-image {
    padding: 20px 30px;
  }
  #insight-industry-content #first-main-content .with-image .content-info .box {
    padding: 20px 0 20px 110px;
  }
  #insight-industry-content #first-main-content .main-info .info ul {
    padding: 0 0 0 15px;
  }
  #insight-industry-content #first-main-content .image {
    padding-bottom: 30px;
  }
  #insight-industry-content #first-main-content .image img {
    max-height: 450px;
  }
  #insight-industry-content #first-main-content .box-wrapper .box-information .box-header {
    font-size: 24px;
  }
  #insight-industry-content #first-main-content .box-wrapper .box-information .box-body {
    font-size: 14px;
  }
  #insight-industry-content #portfolio {
    padding: 40px 0;
    color: #18293e;
  }
  #insight-industry-content #portfolio p {
    text-align: center;
    font-size: 14px;
  }
  #insight-industry-content #portfolio .works-listing-results .portfolio-wrapper {
    width: 33.333333%;
  }
  #insight-industry-content #portfolio .button {
    text-align: center;
  }
  #insight-industry-content #portfolio .button a {
    padding: 15px 40px;
    font-size: 16px;
    border-radius: 2px;
    background-color: #ffffff;
    border: 1px solid #02cd8d;
    color: #02cd8d;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
  }
  #insight-industry-content #second-main-content .client-reviews .review {
    padding: 60px 60px 20px;
  }
  #insight-industry-content #second-main-content .client-reviews .review .row-2 {
    font-size: 24px;
    font-weight: bold;
  }
  #insight-industry-content #second-main-content .client-reviews .slick-dots {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
  }
  #insight-industry-content #second-main-content .client-reviews .slick-dots li {
    position: relative;
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
  }
  #insight-industry-content #second-main-content .client-reviews .slick-dots li.slick-active button {
    background-color: #28f1b1;
  }
  #insight-industry-content #second-main-content .client-reviews .slick-dots li.slick-active button::before {
    opacity: 1;
    color: #28f2b2;
  }
  #insight-industry-content #second-main-content .client-reviews .slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 10px;
    height: 10px;
    padding: 5px;
    cursor: pointer;
    background-color: #ffffff;
    border: 0;
    outline: none;
  }
  #insight-industry-content #second-main-content .client-reviews .slick-dots li button::before {
    opacity: 1;
    font-size: 15px;
    font-family: 'slick';
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 10px;
    height: 10px;
    content: '•';
    text-align: center;
  }
  #insight-industry-content #second-main-content p {
    font-size: 14px;
  }
  #insight-industry-content #second-main-content .content {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  #insight-industry-content #second-main-content .content .content-row {
    padding: 20px 15px;
    width: calc(33.3333% - 15px);
    margin-right: 15px;
  }
  #insight-industry-content #second-main-content .content .content-row:last-child {
    margin-right: 0;
    margin-left: 15px;
  }
  #insight-industry-content #second-main-content .info-wrapper {
    height: 280px;
    margin-bottom: 60px;
  }
  #insight-industry-content #second-main-content .info-wrapper .col-content {
    padding: 30px;
  }
  #insight-industry-content #second-main-content .info-wrapper .col-content .header {
    font-size: 24px;
  }
  #insight-industry-content #second-main-content .info-wrapper .col-content .description {
    font-size: 20px;
  }
  #insight-industry-content #second-main-content .info-wrapper .col-content .button a {
    font-size: 14px;
  }
  #insight-industry-content #portfolio {
    padding-bottom: 60px;
  }
  #insight-industry-content #portfolio .title {
    text-align: center;
    font-size: 28px;
    font-weight: bold;
    padding-bottom: 30px;
  }
  #insight-industry-content #portfolio .button {
    text-align: center;
  }
  #insight-industry-content #portfolio .button a {
    padding: 15px 40px;
    font-size: 16px;
    border-radius: 2px;
    background-color: #ffffff;
    border: 1px solid #02cd8d;
    color: #02cd8d;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
  }
  #insight-industry-content #portfolio .works-listing-results .portfolio-wrapper {
    width: 33.333333%;
  }
  #insight-industry-content #rating {
    padding: 20px 0px 70px;
  }
  #insight-industry-content #rating .rating-score .info {
    padding: 20px;
  }
  #insight-industry-content #rating .rating-score .title {
    padding-bottom: 15px;
  }
  #insight-industry-content #rating .rating-score .description {
    padding-bottom: 15px;
  }
  #insight-industry-content #rating .rating-score .marks .col-left {
    padding-right: 0;
  }
  #insight-industry-content #rating .rating-score .marks .col-left .info-row .info-label {
    font-size: 10px;
  }
}

@media only screen and (max-width: 767px) {
  #industry-listing-page {
    padding: 30px 0 50px;
  }
  #industry-listing-page .container {
    padding-left: 15px;
    padding-right: 15px;
  }
  #industry-listing-page .listing {
    padding-top: 0;
  }
  #industry-listing-page .listing .listing-title {
    font-size: 20px;
  }
  #industry-listing-page .listing-box {
    padding-top: 30px;
  }
  #industry-listing-page .listing-box .main-top .listing-category {
    padding-left: 35px;
    font-size: 18px;
  }
  #industry-listing-page .listing-box .main-top .listing-category .svg-menu {
    width: 25px;
  }
  #industry-listing-page .listing-box .main-industry {
    padding-bottom: 30px;
  }
  #industry-listing-page .listing-box .main-industry .row {
    flex-direction: column-reverse;
    display: flex;
  }
  #industry-listing-page .listing-box .main-industry .main-industry-content {
    padding-top: 15px;
    padding-left: 0px;
  }
  #industry-listing-page .listing-box .main-industry .main-industry-content .main-industry-title {
    font-size: 18px;
    padding-bottom: 5px;
  }
  #industry-listing-page .listing-box .main-industry .main-industry-content .main-industry-description {
    padding-bottom: 5px;
  }
  #industry-listing-page .listing-box .main-industry .main-industry-content .button {
    text-align: center;
  }
  #industry-listing-page .listing-box .main-industry .main-industry-image img {
    max-height: 200px;
  }
  #industry-listing-page .listing-box .other-industries .other-industry-wrapper .other-industry-content .other-industry-content-image .more span {
    font-size: 12px;
  }
  #industry-listing-page .insight-list {
    padding-bottom: 20px;
    text-align: center;
    height: auto;
  }
  #insight-industry-content #first-col h2 {
    font-size: 24px;
  }
  #insight-industry-content #first-col .content .content-row {
    padding: 20px 15px;
    width: 100%;
    margin-right: 0px;
  }
  #insight-industry-content #first-col .content .content-row:last-child {
    margin-left: 0px;
  }
  #insight-industry-content #second-col {
    padding: 40px 0;
    background-color: #e9f0f6;
  }
  #insight-industry-content #second-col .info-wrapper-top .col-right {
    text-align: center;
  }
  #insight-industry-content #second-col .info-wrapper-top .col-right h2 {
    padding-top: 10px;
    font-size: 24px;
  }
  #insight-industry-content #first-main-content {
    padding-top: 20px;
  }
  #insight-industry-content #first-main-content p {
    font-size: 14px;
  }
  #insight-industry-content #first-main-content .with-image {
    padding: 20px 0;
  }
  #insight-industry-content #first-main-content .with-image .content-info {
    width: 100%;
  }
  #insight-industry-content #first-main-content .with-image .content-info .image-wrapper {
    padding: 20px 0;
    top: 0%;
    left: 50%;
    transform: translateX(-50%);
  }
  #insight-industry-content #first-main-content .with-image .content-info .box {
    padding: 120px 0 20px;
    text-align: center;
  }
  #insight-industry-content #first-main-content .main-info .info {
    text-align: center;
    width: 100%;
    margin-right: 0;
  }
  #insight-industry-content #first-main-content .main-info .info:nth-child(2) {
    margin-left: 0%;
  }
  #insight-industry-content #first-main-content .main-info .info ul {
    padding: 0 0 0 15px;
  }
  #insight-industry-content #first-main-content .image {
    padding-bottom: 30px;
  }
  #insight-industry-content #first-main-content .image img {
    max-height: 300px;
    width: 100%;
  }
  #insight-industry-content #first-main-content .box-wrapper .box-information {
    padding: 15px;
  }
  #insight-industry-content #first-main-content .box-wrapper .box-information .box-header {
    font-size: 20px;
  }
  #insight-industry-content #portfolio {
    padding: 20px 0;
    color: #18293e;
  }
  #insight-industry-content #portfolio p {
    text-align: center;
    font-size: 14px;
  }
  #insight-industry-content #portfolio .works-listing-results .portfolio-wrapper {
    width: 100%;
    padding: 15px 20px 20px;
    border-bottom: 1px solid #dbdbdb;
  }
  #insight-industry-content #portfolio .works-listing-results .portfolio-wrapper .portfolio {
    box-shadow: none;
  }
  #insight-industry-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .portfolio-wrapper-heading {
    padding-bottom: 50%;
  }
  #insight-industry-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .portfolio-wrapper-heading .portfolio-wrapper-heading-title {
    width: 65%;
    font-size: 14px;
    line-height: 14px;
  }
  #insight-industry-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .lazy {
    width: 45%;
    display: block;
    float: left;
  }
  #insight-industry-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .lazy .location {
    padding-left: 5px;
    padding-right: 0px;
  }
  #insight-industry-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .lazy .location .pull-left {
    width: 65%;
  }
  #insight-industry-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .lazy .location .fa {
    padding-top: 0;
  }
  #insight-industry-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .lazy .location .fa.fa-map-marker {
    display: none;
  }
  #insight-industry-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .lazy .location .fa.fa-youtube-play {
    padding-right: 5px;
  }
  #insight-industry-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .lazy .location span {
    padding-left: 0px;
    font-size: 12px;
  }
  #insight-industry-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .bookmark .feature {
    right: 3%;
  }
  #insight-industry-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .bookmark .feature img {
    width: 20px;
  }
  #insight-industry-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .content {
    width: 55%;
    display: block;
    float: left;
    font-size: 12px;
    padding: 0 0 0 10px;
  }
  #insight-industry-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .content .title {
    margin-top: 0;
    font-size: 12px;
    line-height: 1.2;
  }
  #insight-industry-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .content .details .item-client-name {
    font-size: 12px;
    line-height: 1.2;
  }
  #insight-industry-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .content .details .tags {
    font-size: 12px;
    line-height: 1.2;
  }
  #insight-industry-content #portfolio .works-listing-results .portfolio-wrapper .portfolio .footer {
    padding: 10px 50px 10px 0px;
    background-color: initial;
    display: block;
    float: left;
    width: 100%;
  }
  #insight-industry-content #portfolio .button {
    padding-top: 20px;
  }
  #insight-industry-content #portfolio .button a {
    padding: 10px 20px;
    font-size: 14px;
  }
  #insight-industry-content #second-main-content .client-reviews .review {
    padding: 40px 20px 20px;
  }
  #insight-industry-content #second-main-content .client-reviews .review .row-2 {
    font-size: 20px;
    font-weight: bold;
  }
  #insight-industry-content #second-main-content .client-reviews .review .row-3 {
    font-size: 14px;
  }
  #insight-industry-content #second-main-content .content {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  #insight-industry-content #second-main-content .content .content-row {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 15px;
  }
  #insight-industry-content #second-main-content .content .content-row:last-child {
    margin-right: 0;
    margin-left: 0px;
  }
  #insight-industry-content #second-main-content .info-wrapper {
    height: auto;
    margin-bottom: 60px;
  }
  #insight-industry-content #second-main-content .info-wrapper .col-content .header {
    font-size: 22px;
  }
  #insight-industry-content #second-main-content .info-wrapper .col-content .description {
    font-size: 18px;
  }
  #insight-industry-content #rating .title {
    font-size: 28px;
    font-weight: bold;
    padding-bottom: 30px;
  }
  #insight-industry-content #rating .rating-score .info {
    margin-right: 5px;
  }
  #insight-industry-content #rating .rating-score .title {
    font-size: 14px;
  }
  #insight-industry-content #rating .rating-score .description {
    font-size: 16px;
  }
  #insight-industry-content #rating .rating-score .marks {
    font-size: 14px;
  }
  #insight-industry-content #rating .rating-score .marks .col-left {
    padding-bottom: 25px;
  }
  #insight-industry-content #rating .rating-score .slick-dots li {
    left: 0;
  }
  #insight-industry-content #bottom-links .quick-links, #insight-industry-content #bottom-links .popular-links, #insight-industry-content #bottom-links .price-guide-links {
    width: 100%;
    margin-right: 0;
    padding-bottom: 25px;
  }
}

#vendor-2easy-badges .wrapper .banner {
  height: 350px;
  background-image: url("../../images/root/client/business-profile/2easy-badges/banner.jpg");
  z-index: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  margin-top: 30px;
}

#vendor-2easy-badges .wrapper .banner .inner {
  width: 50%;
  position: absolute;
  font-size: 16px;
  color: #ffffff;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
}

#vendor-2easy-badges .wrapper .banner .inner .svg-menu {
  fill: #29f2b1;
  padding-bottom: 10px;
}

#vendor-2easy-badges .wrapper .banner .inner .svg-menu img {
  width: 24px;
  fill: #29f2b1;
}

#vendor-2easy-badges .wrapper .banner .inner .title {
  font-size: 30px;
  font-weight: bold;
  padding-bottom: 10px;
}

#vendor-2easy-badges .wrapper .banner .inner .description {
  font-size: 16px;
  padding-bottom: 30px;
}

#vendor-2easy-badges .wrapper .banner .inner .button a {
  font-size: 14px;
  font-weight: bold;
  display: inline-block;
  padding: 12px 50px;
  background-color: #29f2b1;
  color: #18293e;
  border-radius: 2px;
  text-decoration: none;
  cursor: pointer;
}

#vendor-2easy-badges .wrapper .status-row {
  width: 100%;
  padding: 40px 0;
}

#vendor-2easy-badges .wrapper .status-row .status-content {
  padding: 40px 15px;
  text-align: center;
  color: #18293e;
  float: left;
  width: 32%;
  margin-right: 2%;
}

#vendor-2easy-badges .wrapper .status-row .status-content.achieved .svg-menu {
  filter: grayscale(0);
  opacity: 1;
}

#vendor-2easy-badges .wrapper .status-row .status-content.achieved .status {
  color: #29f1b1;
}

#vendor-2easy-badges .wrapper .status-row .status-content:last-child {
  margin-right: 0;
  margin-left: 0;
}

#vendor-2easy-badges .wrapper .status-row .status-content .svg-menu {
  width: 100px;
  height: 100px;
  margin: 0 auto;
  filter: grayscale(1);
  opacity: 0.5;
}

#vendor-2easy-badges .wrapper .status-row .status-content .svg-menu.special {
  width: 150px;
}

#vendor-2easy-badges .wrapper .status-row .status-content .svg-menu.special img {
  width: 150px;
}

#vendor-2easy-badges .wrapper .status-row .status-content .svg-menu img {
  width: 100px;
  height: 100px;
}

#vendor-2easy-badges .wrapper .status-row .status-content .legend {
  font-size: 24px;
  font-weight: bold;
  padding-bottom: 5px;
}

#vendor-2easy-badges .wrapper .status-row .status-content .description {
  font-size: 16px;
  padding-bottom: 10px;
}

#vendor-2easy-badges .wrapper .status-row .status-content .status {
  font-size: 16px;
  font-weight: bold;
  color: #9ab3cc;
}

#vendor-2easy-badges .wrapper .badges-boxes .badges-boxes-title {
  font-size: 30px;
  font-weight: bold;
  padding-bottom: 10px;
}

#vendor-2easy-badges .wrapper .badges-boxes .badges-boxes-description {
  padding-bottom: 30px;
  font-size: 18px;
}

#vendor-2easy-badges .wrapper .badges-boxes .badges-box {
  padding: 45px;
  border: 2px solid #9ab3cc;
  margin-bottom: 30px;
}

#vendor-2easy-badges .wrapper .badges-boxes .badges-box.lock {
  background-color: #f0f4f7;
}

#vendor-2easy-badges .wrapper .badges-boxes .badges-box.lock .top-wrapper .title .fa {
  color: #9ab3cc;
}

#vendor-2easy-badges .wrapper .badges-boxes .badges-box.lock .top-wrapper .status {
  color: #9ab3cc;
}

#vendor-2easy-badges .wrapper .badges-boxes .badges-box.lock .badges-wrapper {
  padding-top: 20px;
}

#vendor-2easy-badges .wrapper .badges-boxes .badges-box.lock .badges-wrapper .svg-menu {
  pointer-events: none;
  filter: grayscale(1);
  opacity: 0.5;
  float: left;
  width: 250px;
  margin-right: 20px;
}

#vendor-2easy-badges .wrapper .badges-boxes .badges-box.lock .badges-wrapper .svg-menu.selected {
  border: 2px solid #28f1b1;
}

#vendor-2easy-badges .wrapper .badges-boxes .badges-box.lock .badges-wrapper .svg-menu.toprated img {
  width: 130px;
}

#vendor-2easy-badges .wrapper .badges-boxes .badges-box.lock .badges-wrapper .svg-menu img {
  padding: 10px;
  width: 250px;
}

#vendor-2easy-badges .wrapper .badges-boxes .badges-box .top-wrapper {
  display: inline;
  font-weight: bold;
}

#vendor-2easy-badges .wrapper .badges-boxes .badges-box .top-wrapper .title {
  float: left;
  text-align: left;
  font-size: 24px;
}

#vendor-2easy-badges .wrapper .badges-boxes .badges-box .top-wrapper .title .fa {
  padding-right: 5px;
  color: #29f1b1;
}

#vendor-2easy-badges .wrapper .badges-boxes .badges-box .top-wrapper .status {
  float: right;
  font-size: 16px;
  text-align: right;
  color: #29f1b1;
}

#vendor-2easy-badges .wrapper .badges-boxes .badges-box .action-wrapper .step-1 {
  padding-bottom: 50px;
}

#vendor-2easy-badges .wrapper .badges-boxes .badges-box .action-wrapper .step-1 .title {
  font-size: 18px;
  padding: 15px 0;
}

#vendor-2easy-badges .wrapper .badges-boxes .badges-box .action-wrapper .step-1 .content .badges-wrapper .badges-wrapper-title {
  font-size: 18px;
  font-weight: bold;
}

#vendor-2easy-badges .wrapper .badges-boxes .badges-box .action-wrapper .step-1 .content .badges-wrapper .badges {
  padding: 20px 0;
}

#vendor-2easy-badges .wrapper .badges-boxes .badges-box .action-wrapper .step-1 .content .badges-wrapper .badges .svg-menu {
  opacity: 0.5;
  float: left;
  width: 250px;
  margin-right: 20px;
  cursor: pointer;
}

#vendor-2easy-badges .wrapper .badges-boxes .badges-box .action-wrapper .step-1 .content .badges-wrapper .badges .svg-menu.selected {
  opacity: 1;
}

#vendor-2easy-badges .wrapper .badges-boxes .badges-box .action-wrapper .step-1 .content .badges-wrapper .badges .svg-menu.toprated img {
  width: 130px;
}

#vendor-2easy-badges .wrapper .badges-boxes .badges-box .action-wrapper .step-1 .content .badges-wrapper .badges .svg-menu img {
  padding: 10px;
  width: 250px;
}

#vendor-2easy-badges .wrapper .badges-boxes .badges-box .action-wrapper .step-1 .category {
  font-size: 18px;
  font-weight: bold;
  width: 23%;
  margin: 0 1% 20px;
  border: 2px solid #eff3f6;
  text-align: center;
  float: left;
  padding: 10px 0px 10px;
  cursor: pointer;
}

#vendor-2easy-badges .wrapper .badges-boxes .badges-box .action-wrapper .step-1 .category.active, #vendor-2easy-badges .wrapper .badges-boxes .badges-box .action-wrapper .step-1 .category:hover {
  border-color: #28f1b1;
}

#vendor-2easy-badges .wrapper .badges-boxes .badges-box .action-wrapper .step-1 .category .text {
  text-align: center;
  display: inline-block;
}

#vendor-2easy-badges .wrapper .badges-boxes .badges-box .action-wrapper .step-1 .category .svg-menu {
  float: left;
  padding-right: 15px;
}

#vendor-2easy-badges .wrapper .badges-boxes .badges-box .action-wrapper .step-1 .category .svg-menu img {
  width: 20px;
  height: 20px;
}

#vendor-2easy-badges .wrapper .badges-boxes .badges-box .action-wrapper .step-1 .category .name {
  float: left;
  padding-top: 3px;
}

#vendor-2easy-badges .wrapper .badges-boxes .badges-box .action-wrapper .step-2 {
  font-size: 18px;
  color: #4d4d4d;
}

#vendor-2easy-badges .wrapper .badges-boxes .badges-box .action-wrapper .step-2 .title {
  font-size: 18px;
  padding: 15px 0;
}

#vendor-2easy-badges .wrapper .badges-boxes .badges-box .action-wrapper .step-2 .content {
  background-color: #f0f4f7;
}

#vendor-2easy-badges .wrapper .badges-boxes .badges-box .action-wrapper .step-2 .content .code {
  padding: 20px;
}

#vendor-2easy-badges .wrapper .badges-boxes .badges-box .action-wrapper .step-2 .content .copy {
  padding: 0 20px 20px;
  font-size: 16px;
  font-weight: bold;
  display: block;
  color: #29f1b1;
  cursor: pointer;
}

@media only screen and (max-width: 991px) {
  #vendor-2easy-badges .wrapper .banner .inner {
    width: 80%;
  }
  #vendor-2easy-badges .wrapper .banner .inner .title {
    font-size: 24px;
  }
  #vendor-2easy-badges .wrapper .banner .inner .description {
    font-size: 14px;
  }
  #vendor-2easy-badges .wrapper .status-row {
    padding: 20px 0;
  }
  #vendor-2easy-badges .wrapper .status-row .status-content {
    padding: 40px 15px;
  }
  #vendor-2easy-badges .wrapper .status-row .status-content .svg-menu {
    margin-bottom: 5px;
    width: 80px;
    height: 80px;
  }
  #vendor-2easy-badges .wrapper .status-row .status-content .svg-menu.special {
    width: 130px;
  }
  #vendor-2easy-badges .wrapper .status-row .status-content .svg-menu.special img {
    width: 130px;
  }
  #vendor-2easy-badges .wrapper .status-row .status-content .svg-menu img {
    width: 80px;
    height: 80px;
  }
  #vendor-2easy-badges .wrapper .status-row .status-content .legend {
    font-size: 20px;
  }
  #vendor-2easy-badges .wrapper .status-row .status-content .description {
    font-size: 14px;
  }
  #vendor-2easy-badges .wrapper .badges-boxes .badges-boxes-title {
    font-size: 24px;
  }
  #vendor-2easy-badges .wrapper .badges-boxes .badges-boxes-description {
    font-size: 16px;
  }
  #vendor-2easy-badges .wrapper .badges-boxes .badges-box {
    padding: 25px;
  }
  #vendor-2easy-badges .wrapper .badges-boxes .badges-box.lock {
    background-color: #f0f4f7;
  }
  #vendor-2easy-badges .wrapper .badges-boxes .badges-box.lock .top-wrapper .title .fa {
    color: #9ab3cc;
  }
  #vendor-2easy-badges .wrapper .badges-boxes .badges-box.lock .top-wrapper .status {
    color: #9ab3cc;
  }
  #vendor-2easy-badges .wrapper .badges-boxes .badges-box.lock .badges-wrapper {
    padding-top: 20px;
  }
  #vendor-2easy-badges .wrapper .badges-boxes .badges-box.lock .badges-wrapper .svg-menu {
    pointer-events: none;
    filter: grayscale(1);
    opacity: 0.5;
    float: left;
    width: 250px;
    margin-right: 20px;
  }
  #vendor-2easy-badges .wrapper .badges-boxes .badges-box.lock .badges-wrapper .svg-menu.selected {
    border: 2px solid #28f1b1;
  }
  #vendor-2easy-badges .wrapper .badges-boxes .badges-box.lock .badges-wrapper .svg-menu.toprated img {
    width: 130px;
  }
  #vendor-2easy-badges .wrapper .badges-boxes .badges-box.lock .badges-wrapper .svg-menu img {
    padding: 10px;
    width: 250px;
  }
  #vendor-2easy-badges .wrapper .badges-boxes .badges-box .top-wrapper .title {
    font-size: 20px;
  }
  #vendor-2easy-badges .wrapper .badges-boxes .badges-box .top-wrapper .status {
    font-size: 14px;
  }
  #vendor-2easy-badges .wrapper .badges-boxes .badges-box .action-wrapper .step-1 .title {
    font-size: 16px;
  }
  #vendor-2easy-badges .wrapper .badges-boxes .badges-box .action-wrapper .step-1 .content .badges-wrapper .badges .svg-menu {
    width: 30%;
    margin-right: 5%;
    cursor: pointer;
  }
  #vendor-2easy-badges .wrapper .badges-boxes .badges-box .action-wrapper .step-1 .content .badges-wrapper .badges .svg-menu:last-child {
    margin-right: 0;
  }
  #vendor-2easy-badges .wrapper .badges-boxes .badges-box .action-wrapper .step-1 .content .badges-wrapper .badges .svg-menu.toprated {
    width: 30%;
  }
  #vendor-2easy-badges .wrapper .badges-boxes .badges-box .action-wrapper .step-1 .content .badges-wrapper .badges .svg-menu.toprated img {
    width: 70%;
  }
  #vendor-2easy-badges .wrapper .badges-boxes .badges-box .action-wrapper .step-1 .content .badges-wrapper .badges .svg-menu img {
    width: 100%;
  }
  #vendor-2easy-badges .wrapper .badges-boxes .badges-box .action-wrapper .step-1 .category {
    width: 31%;
    margin: 0 1% 20px;
  }
  #vendor-2easy-badges .wrapper .badges-boxes .badges-box .action-wrapper .step-1 .category .name {
    font-size: 16px;
  }
  #vendor-2easy-badges .wrapper .badges-boxes .badges-box .action-wrapper .step-2 .title {
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  #vendor-2easy-badges .wrapper .container {
    padding: 0 15px;
  }
  #vendor-2easy-badges .wrapper .banner {
    height: 500px;
  }
  #vendor-2easy-badges .wrapper .status-row {
    width: 100%;
  }
  #vendor-2easy-badges .wrapper .status-row .status-content {
    width: 100%;
    margin-right: 0;
    padding: 20px 15px;
  }
  #vendor-2easy-badges .wrapper .badges-boxes .badges-box {
    padding: 15px;
  }
  #vendor-2easy-badges .wrapper .badges-boxes .badges-box.lock {
    background-color: #f0f4f7;
  }
  #vendor-2easy-badges .wrapper .badges-boxes .badges-box.lock .top-wrapper .title .fa {
    color: #9ab3cc;
  }
  #vendor-2easy-badges .wrapper .badges-boxes .badges-box.lock .top-wrapper .status {
    color: #9ab3cc;
  }
  #vendor-2easy-badges .wrapper .badges-boxes .badges-box.lock .badges-wrapper {
    padding-top: 20px;
  }
  #vendor-2easy-badges .wrapper .badges-boxes .badges-box.lock .badges-wrapper .svg-menu {
    pointer-events: none;
    filter: grayscale(1);
    opacity: 0.5;
    float: left;
    width: 250px;
    margin-right: 20px;
  }
  #vendor-2easy-badges .wrapper .badges-boxes .badges-box.lock .badges-wrapper .svg-menu.selected {
    border: 2px solid #28f1b1;
  }
  #vendor-2easy-badges .wrapper .badges-boxes .badges-box.lock .badges-wrapper .svg-menu.toprated img {
    width: 130px;
  }
  #vendor-2easy-badges .wrapper .badges-boxes .badges-box.lock .badges-wrapper .svg-menu img {
    padding: 10px;
    width: 250px;
  }
  #vendor-2easy-badges .wrapper .badges-boxes .badges-box .top-wrapper .title {
    font-size: 18px;
    width: 100%;
  }
  #vendor-2easy-badges .wrapper .badges-boxes .badges-box .top-wrapper .status {
    float: none;
    font-size: 14px;
    width: 100%;
    text-align: left;
  }
  #vendor-2easy-badges .wrapper .badges-boxes .badges-box .action-wrapper .step-1 .title {
    font-size: 16px;
  }
  #vendor-2easy-badges .wrapper .badges-boxes .badges-box .action-wrapper .step-1 .content .badges-wrapper .badges .svg-menu {
    width: 100%;
    margin-right: 0;
  }
  #vendor-2easy-badges .wrapper .badges-boxes .badges-box .action-wrapper .step-1 .content .badges-wrapper .badges .svg-menu:last-child {
    margin-right: 0;
  }
  #vendor-2easy-badges .wrapper .badges-boxes .badges-box .action-wrapper .step-1 .content .badges-wrapper .badges .svg-menu.toprated {
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }
  #vendor-2easy-badges .wrapper .badges-boxes .badges-box .action-wrapper .step-1 .content .badges-wrapper .badges .svg-menu.toprated img {
    width: 70%;
  }
  #vendor-2easy-badges .wrapper .badges-boxes .badges-box .action-wrapper .step-1 .category {
    width: 100%;
    margin: 0 0 20px;
  }
  #vendor-2easy-badges .wrapper .badges-boxes .badges-box .action-wrapper .step-1 .category .name {
    font-size: 16px;
  }
  #vendor-2easy-badges .wrapper .badges-boxes .badges-box .action-wrapper .step-2 {
    font-size: 14px;
  }
  #vendor-2easy-badges .wrapper .badges-boxes .badges-box .action-wrapper .step-2 .title {
    font-size: 16px;
  }
}

/*# sourceMappingURL=app.css.map */
